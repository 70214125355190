import { invokeApi } from "src/utils";
export const _list_menu = async (id) => {
  const requestObj = {
    path: `api/menu/list_menu?page=0&limit=9999`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _delete_menu = async (id) => {
  const requestObj = {
    path: `api/menu/delete_menu/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_menu = async (data) => {
 
  const requestObj = {
    path: `api/menu/add_menu`,
    method: "POST",
    headers: {

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_menu = async (id) => {
  const requestObj = {
    path: `api/menu/detail_menu/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_menu = async (id, data) => {
  const requestObj = {
    path: `api/menu/edit_menu/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};


export const _active_menus = async () => {
  const requestObj = {
    path: `api/menu/active_menus`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};