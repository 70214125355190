import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  TableHead,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { _detail_enquiry_student } from "src/DAL/enquirystudent";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import { formatDateWithDay, formatTime24Hours } from "src/utils/formatTime";
import { _detail_announcement } from "src/DAL/announcement";
import { defaltAnnouncement } from "src/assets";
const AnnouncementDetailCard = ({
  selectedRow,
  students,
  onStudentsUpdate,
  announcementCount,
  setAnnouncementCount,
}) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRowStudent, setSelectedRowStudent] = useState(selectedRow);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);

  const paymentMethodLabels = {
    by_hand: "Pay by Hand",
    online_cash: "Pay Online in Cash",
    cheque: "Pay by Cheque",
  };

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    fontSize: "1.3rem", // Adjust the font size as needed
    color: "#fff!important",
  };

  const myStyleHeader3 = {
    backgroundColor: "#0c2e60",
    color: "#fff",
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    course: [],
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: {
      _id: "0",
      type: "",
      start_time: "00:00",
      end_time: "00:00",
    },

    discount: 0,
    fee_status: "all_paid",
    batch_name: "",
    student_batches: [],
  });

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  useEffect(() => {
    // fetchStudentDetail();
    console.log("selectedRow _test ___selectedRow", selectedRow);
  }, []);

  const fetchAnnouncementDetail = async () => {
    setIsLoading(true);
    const response = await _detail_announcement(selectedRow.id);
    console.log("response _detail_announcement", response);
    if (response.code === 200) {
      setSelectedRowStudent(response.announcement);
      setAnnouncementCount(response.unread_announcement_count);

      const updatedStudents = students.map((student) => {
        if (student.id === selectedRow.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return { ...student, read_status: true };
        } else {
          // For other objects, return them as they are
          return student;
        }
      });

      onStudentsUpdate(updatedStudents);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAnnouncementDetail();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <div className="container ">
          {selectedRowStudent.attachment ? (
            <Stack
              position="relative"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: "20px" }}
            >
              {" "}
              <label htmlFor="image" className="form-label">
                {/* Image: */}
              </label>
              <Box
                position="relative"
                display="inline-block"
                // border="2px solid #ccc"
                // p={1}
              >
                <a
                  href={s3baseUrl + selectedRowStudent.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="image"
                    src={s3baseUrl + selectedRowStudent.attachment}
                    width="500"
                    height="300"
                    style={{ maxWidth: "100%" }}
                  />
                </a>
                {/* <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton> */}
              </Box>
            </Stack>
          ) : (
            <Stack
              position="relative"
              alignItems="center"
              justifyContent="center"
              sx={{ marginTop: "20px" }}
            >
              {" "}
              <label htmlFor="image" className="form-label">
                {/* Image: */}
              </label>
              <Box
                position="relative"
                display="inline-block"
                // border="2px solid #ccc"
                // p={1}
              >
                <a
                  href={defaltAnnouncement}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="image"
                    src={defaltAnnouncement}
                    width="500"
                    height="300"
                    style={{ maxWidth: "100%" }}
                  />
                </a>
              </Box>
            </Stack>
          )}

          <div className="row p-3">
            <div className="col-12">
              <h4>{capitalizeFirstLetter(selectedRowStudent.title)}</h4>
            </div>
            <div className="col-12">
              {capitalizeFirstLetter(selectedRowStudent.description)}
            </div>
          </div>
          {/* <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Title
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.title)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
 
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Description
                  </TableCell>
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      selectedRowStudent.description.length > 100
                        ? capitalizeFirstLetter(selectedRowStudent.description)
                        : ""
                    }
                    arrow
                  >
                    <TableCell align="left" className="cell_onclick_effect">
                      {capitalizeFirstLetter(
                        truncateString(selectedRowStudent.description, 100)
                      )}
                    </TableCell>
                  </Tooltip>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>

                  <TableCell align="left">
                    <Label
                      variant="outlined"
                      color={
                        (selectedRowStudent.status === true && "success") ||
                        (selectedRowStudent.status === false && "error")
                      }
                    >
                      {selectedRowStudent.status === true && "Active"}
                      {selectedRowStudent.status === false && "Inactive"}
                    </Label>
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                 
 

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Date
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateWithDay(selectedRowStudent.date)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Notify To
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {selectedRowStudent.announcement_for === 1
                      ? "All"
                      : selectedRowStudent.announcement_for === 2
                      ? "Managements"
                      : selectedRowStudent.announcement_for === 3
                      ? capitalizeFirstLetter(selectedRowStudent.role.title)
                      : selectedRowStudent.announcement_for === 4
                      ? "Students"
                      : "All"}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

             
 
              </TableBody>
            </Table>
          </TableContainer> */}
        </div>
      </Grid>
    </Page>
  );
};

export default AnnouncementDetailCard;
