import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_brand } from "src/DAL/brand";
const Add = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    active_status: true,
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");

    // if (name === 'course' && value.includes('other')) {
    //   setFormInputs({
    //     ...formInputs,
    //     [name]: value.filter((option) => option !== 'other'),
    //     other: ''
    //   });
    // } else {
    //   setFormInputs({
    //     ...formInputs,
    //     [name]: value
    //   });
    // }

    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };
  const handleChange2 = (event, newValue) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      course: newValue ? newValue._id : "",
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);

    let postData = {
      title: formInputs.title,
      description: formInputs.description,
      active_status: formInputs.active_status,
    };
    console.log("postData", postData);


    setIsLoading(true);
    const result = await _add_brand(postData);
    console.log("result _add_brand", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      navigate("/brand");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }

  };

  // useEffect(() => {
  //   if (courses.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       courses: [courses[0]._id],
  //     }));
  //   }
  // }, [courses]);
  // useEffect(() => {
  //   if (batches.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       batches: [batches[0].id],
  //     }));
  //   }
  // }, [batches]);

  const fetchActiveBatches = async () => {
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      setCourses(batches[0].courses);
      setBatches(batches);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveCourse = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response", response);
    if (response.code === 200) {
      // const batches = response.batches.map((batch) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   student_count: batch.student_count,
      //   status: batch.status,
      //   courses: batch.courses,
      // }));
      // Filter and set active courses
      const activeCourses = response.courses.filter(
        (course) => course.status === true
      );
      setCourses(activeCourses);
      // setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetchActiveCourse();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Brand">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Brand </h4>
        </Grid>


        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Status"
                  select
                  // required
                  value={formInputs.active_status}
                  onChange={handleChange}
                  name="active_status"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </TextField>
              </Stack>

              <TextField
                fullWidth
                label="Description"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.description}
                onChange={handleChange}
                name="description"
                required
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
