import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Box,
  LinearProgress,
  Tooltip,
  Zoom,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import {
  _move_batch_students,
  _move_student_from_batch,
  _update_batch_status,
} from "src/DAL/batch";
import {
  _approve_fee_voucher,
  _detail_fee_voucher,
  _edit_fee_voucher,
  _generate_invoice,
} from "src/DAL/voucher";
import { s3baseUrl } from "src/config/config";
import { capitalizeFirstLetter } from "src/utils/formatText";

const CreateInvoice = ({
  Row,
  handleCloseModal,
}) => {
  console.log("Row  __row", Row);
  const [isLoading, setIsLoading] = useState(true);
  const [invoiceLink, setInvoiceLink] = useState("");
  const [row, setRow] = useState(Row);
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "true",
    batch: "",
    courses: [],
    voucher_status: 2,
    paid_amount: "",
    paid_amount_all: "",
  });


  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleDownloadClick = () => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = invoiceLink;
    link.download = "downloaded-invoice"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  }
  
  const fetchDetailBatch = async () => {
    //console.log(row);
    setIsLoading(true);
    const result = await _generate_invoice(Row.id);
    console.log("result  _generate_invoice", result);
    if (result.code === 200) {
      setInvoiceLink(`${s3baseUrl}${result.fee_voucher}`);

      // const updatedStudents = students.filter(
      //   (students) => students.id !== row.id
      // );
      // onStudentsUpdate(updatedStudents);
      // enqueueSnackbar("Deleted Successfully!", { variant: "success" });

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailBatch();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Create Invoice">
     <div className="container-fluid col-11" style={{ marginTop: 90 }}>


     <Grid item xs={12} sm={10} md={8} lg={7} xl={7} sx={{marginBottom:"20px"}}>
          <form
            autoComplete="off"
            // onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3} sx={{ padding: "5px" }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  disabled
                  fullWidth
                  label="Student Name"
                  value={capitalizeFirstLetter(row.student.name)}
                  onChange={handleChange}
                  name="name"
                />
                <TextField
                  disabled
                  fullWidth
                  label="Batch Name"
                  value={capitalizeFirstLetter(row.batch.name)}
                  onChange={handleChange}
                  name="name"
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  disabled
                  fullWidth
                  label="Student Contact"
                  value={"+92" + row.student.contact_number}
                  onChange={handleChange}
                  name="name"
                />
                <TextField
                  disabled
                  fullWidth
                  label="Fee Title"
                  value={capitalizeFirstLetter(row.voucher_type===2?row.fee_title:"Registration")}
                  onChange={handleChange}
                  name="name"
                />
              </Stack>
 

              {/* <TextField
                fullWidth
                label="Voucher Status"
                select
                value={formInputs.voucher_status}
                onChange={handleChange}
                name="voucher_status"
                required
              > 
    
                <MenuItem value={2}>Paid</MenuItem>
 
              </TextField> */}
 
            </Stack>
          </form>
        </Grid>


      <div className="">
        <div>
          <p>Click the button to download:</p>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GetAppIcon />}
            onClick={handleDownloadClick}
          >
            Download File
          </Button>
        </div>
      </div>
    </div>
    </Page>
  );
};

export default CreateInvoice;
