import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
  Grid,
  MenuItem,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent, listStudentSearch } from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import { _list_attendance } from "src/DAL/attendance";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useSnackbar } from "notistack";
import { _filter_assignments, _list_assignment } from "src/DAL/assignment";
import { formatDateWithDay } from "src/utils/formatTime";
import AssignmentDetail from "./AssignmentDetail";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import { listBatch } from "src/DAL/batch";
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "batch", label: "Batch", alignRight: false },

  { id: "assigned_date", label: "Assigned Date", alignRight: false },
  { id: "dead_line", label: "Dead Line", alignRight: false },
  { id: "total_marks", label: "Total Marks", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "status", label: "Status", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AssignmentList() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const moment = require("moment");
  const [order, setOrder] = useState("asc");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: "true",
    registration_fee: 0,
    batch_discount: 0,
    batch_fee_id: "",
    additional_discount: 0,
    additional_discount_reason: "",

    voucher_type: 0,
    voucher_status: 0,
    batch_id: [],
    batch_id_changes: [],
    batch_students_ids: [],
    // batch_students_ids_changes: [],

    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
  });

  const [trashStudentCount, setTrashStudentCount] = useState(0);
  const [batches, setBatches] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/assignments/add-assignment");
  };
  const handleUploadAttendance = () => {
    navigate("/attendance/upload-attendance");
  };
  const handleMoveToTrash = () => {
    navigate("/trash/");
  };

  function formatTime(Time) {
    // Parse the time strings using moment
    const startMoment = moment(Time, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime}`;

    return formattedTime;
  }

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _list_assignment({
      text: "",
    });

    console.log("response _list_assignment", response);
    if (response.code === 200) {
      //   const combinedAttendance = response.attendance.map(item => {
      //     const studentName = item.student.name;
      //     const studentID = item.student._id;
      //     return item.attendance.map(attendanceRecord => ({
      //         ...attendanceRecord,
      //         name: studentName,
      //         student_id: studentID,
      //     }));

      // }).reduce((accumulator, currentAttendance) => accumulator.concat(currentAttendance), []);

      // console.log('combinedAttendance ___combinedAttendance',combinedAttendance);

      setStudents(response.assignments);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    // const response = await listStudentSearch(searchText);
    const response = await _list_assignment({
      text: searchText,
    });
    if (response.code === 200) {
      // Use map to transform the response data
      // const updatedResponse = response.attendance.map(item => {
      //   const studentName = item.student.name;

      // const combinedAttendance = response.attendance
      //   .map((item) => {
      //     const studentName = item.student.name;
      //     const studentID = item.student._id;

      //     return item.attendance.map((attendanceRecord) => ({
      //       ...attendanceRecord,
      //       name: studentName,
      //       student_id: studentID,
      //     }));
      //   })
      //   .reduce(
      //     (accumulator, currentAttendance) =>
      //       accumulator.concat(currentAttendance),
      //     []
      //   );

      // Now, combinedAttendance contains all attendance records with student names in one array
      // console.log(combinedAttendance);

      // Now, updatedResponse contains the updated attendance records with student names
      // console.log(
      //   "combinedAttendance ___combinedAttendance",
      //   combinedAttendance
      // );

      // setTrashStudentCount(response.trash_student_count);

      setStudents(response.assignments);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchBatches = async () => {
    // setIsLoading(true);
    const response = await listBatch();
    console.log("response listBatch _listBatch _test", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              students: batch.students,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              batch_fee: batch.batch_fee ? batch.batch_fee : [],
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      console.log("batches  _test", batches);
      console.log("batches[0]  _test", batches[0]);
      console.log("batches[0].batch_fee  _test", batches[0].batch_fee);
      setBatches(batches);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    setIsLoading(true);
    const response = await _filter_assignments({
      "batches":formInputs.batch_id,
  });

    console.log("response _filter_assignments", response);
    if (response.code === 200) {
      setStudents(response.assignments);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }

    // let postData = {
    //   batch: formInputs.batch_id,
    //   student: formInputs.batch_students_ids,
    //   voucher_type:
    //     formInputs.voucher_type === 0 ? [] : [formInputs.voucher_type],
    //   voucher_status:
    //     formInputs.voucher_status === 0 ? [] : [formInputs.voucher_status],
    // };

    // console.log("postData", postData);

    // fetchStudents(postData);

    // let feeDiscount = formInputs.additional_discount;
    // let feeDiscountReason = formInputs.additional_discount_reason;

    // if (typeof feeDiscount === "string") {
    //   feeDiscount = parseInt(feeDiscount, 10); // Use 10 as the radix for decimal numbers
    // }

    // if (!feeDiscountChecked) {
    //   feeDiscount = 0;
    //   feeDiscountReason = "";
    // }

    // let postData = {
    //   batch_id: formInputs.batch_id,
    //   batch_fee_id: formInputs.batch_fee_id,
    //   due_date: formattedStartDate,
    //   additional_discount: feeDiscount,
    //   additional_discount_reason: feeDiscountReason,
    // };
    // console.log("postData _test", postData);

    // setIsLoading(true);
    // const result = await _add_fee_voucher(postData);
    // if (result.code === 200) {
    //   setIsLoading(false);
    //   navigate("/vouchers");
    //   enqueueSnackbar("Added Successfully!", { variant: "success" });
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf", typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();

    setFormInputs({
      ...formInputs,
      voucher_type: 0,
      voucher_status: 0,
      batch_id: [],
      batch_id_changes: [],
      batch_students_ids: [],
    });

    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };
  const handleBatchClose = () => {
    if (
      formInputs.batch_id.length <= 0 &&
      formInputs.batch_id_changes.length > 0
    ) {
      console.log("formInputs.batch_id", typeof formInputs.batch_id);
      console.log(
        "formInputs.batch_id_changes",
        typeof formInputs.batch_id_changes
      );
      setFormInputs({
        ...formInputs,
        // [target.name]: target.value,
        //   batch_students_ids: [foundObject.students[0]._id],
        // batch_students_ids: [],
        // batch_id_changes: formInputs.batch_id,
        batch_id_changes: [],
      });
    } else if (formInputs.batch_id.length > 0) {
      console.log("formInputs.batch_id", typeof formInputs.batch_id);
      console.log(
        "formInputs.batch_id_changes",
        typeof formInputs.batch_id_changes
      );

      if (arraysAreEqual(formInputs.batch_id, formInputs.batch_id_changes)) {
        console.log("The arrays have the same contents.");
      } else {
        console.log("The arrays have different contents.");
        setFormInputs({
          ...formInputs,
          // [target.name]: target.value,
          //   batch_students_ids: [foundObject.students[0]._id],
          // batch_students_ids: [],
          batch_id_changes: formInputs.batch_id,
        });
      }
    }
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    console.log("e __e", e);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      //   batch_students_ids: [foundObject.students[0]._id],
      // batch_students_ids: [],
    });
  };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Tue, Sep 05, 2023"
    const formattedDate = parsedDate.format("ddd, MMM DD, YYYY");

    return formattedDate;
  }
  useEffect(() => {
    fetchStudents();
    fetchBatches();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Assignments">
      <Container maxWidth="xl">
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "assignment",
          "view"
        ) ? (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <Typography variant="h4">Assignments</Typography>
              <div>
                {/* <Button
              onClick={handleMoveToTrash}
              variant="contained"
              color="error" // Set the color to "secondary" for red color
              startIcon={<Iconify icon="bi:trash" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              ( {trashStudentCount} ) Trash
            </Button> */}
                {privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "assignment",
                  "edit"
                ) ? (
                  //   <Button
                  //     onClick={handleUploadAttendance}
                  //     variant="contained"
                  //     startIcon={<Iconify icon="solar:upload-bold" />}
                  //     style={{ marginLeft: "10px", marginRight: "10px" }}
                  //   >
                  //     Upload Attendance
                  //   </Button>
                  <></>
                ) : (
                  ""
                )}
                {privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "assignment",
                  "add"
                ) ? (
                  <Button
                    onClick={handleNav}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    New Assignment
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Stack>

            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={2}>
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                  
                  </Stack> */}

                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ paddingBottom: "20px" }}
                  >
                       <TextField
                      fullWidth
                      label="Batch"
                      select
                      value={formInputs.batch_id}
                      onChange={handleChange2}
                      name="batch_id"
                      //   SelectProps={{ multiple: true }}
                      // style={myStyle} // Apply the style here
                      SelectProps={{
                        multiple: true,
                        // onClose: handleBatchClose, // Call your function here
                      }}
                      //   required
                    >
                      {batches.map((batch) => (
                        <MenuItem key={batch.id} value={batch.id}>
                          {capitalizeFirstLetter(batch.name)}
                        </MenuItem>
                      ))}
                    </TextField>

                    {/* <TextField
                      label="Search"
                      value={searchText}
                      onChange={handleInputChange}
                    /> */}

                    <CustomResetButton handleReset={handleReset} />

                    <Button type="submit" variant="contained">
                      Filter
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Grid>

            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

              <Scrollbar>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                  />
                  {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                  {/* <CustomResetButton handleReset={handleReset} /> */}

                  {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
                </Stack>

                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <StudentListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={students.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            _id,
                            title,
                            batch,
                            assigned_date,
                            dead_line,
                            total_marks,
                            status,
                            assignment_type,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;
                          // let number = index + 1;

                          return (
                            <TableRow
                              // hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {index + 1}
                              </TableCell>

                              {/* <TableCell align="left">
                            <div
                              className="on_click_text"
                              // style={{
                              //   cursor: "pointer",
                              // }}
                              onClick={() => handleOpenModal(row)}
                            >
                              {capitalizeFirstLetter(title)}
                            </div>
                          </TableCell>  */}
                              <TableCell align="left">
                                <RouterLink
                                  to={`/assignments/assignment-students/${row._id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                  // className="on_click_text" // Add the class name you want here
                                >
                                  <div className="on_click_text">
                                    {capitalizeFirstLetter(title)}
                                  </div>
                                </RouterLink>
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                                <RouterLink
                                  to={`/attendance/detail/${row._id}`}
                                  // to={`/attendance/calendar/${row.student._id}`}
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                  }}
                                  // className="on_click_text" // Add the class name you want here
                                >
                                  <div className="on_click_text">
                                    {capitalizeFirstLetter(title)}
                                  </div>
                                </RouterLink>
                              </TableCell> */}

                              <TableCell align="left">
                                {capitalizeFirstLetter(batch.name)}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {formatDateWithDay(assigned_date)}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {formatDateWithDay(dead_line)}
                              </TableCell>

                              <TableCell align="left">{total_marks}</TableCell>

                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (assignment_type === 1 && "info") ||
                                    "primary"
                                  }
                                >
                                  {assignment_type === 1
                                    ? "Under Review"
                                    : "Direct"}
                                </Label>
                              </TableCell>

                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (status === false && "error") || "success"
                                  }
                                >
                                  {!status ? "Inactive" : "Active"}
                                </Label>
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                            {formatDate(date)}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {check_in===""?" _ _ ":formatTime(check_in)}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {check_out===""?" _ _ ":formatTime(check_out)}
                          </TableCell> */}

                              {/* <TableCell align="left" style={myStyle}>
                            {absent}
                          </TableCell> */}

                              <TableCell align="right">
                                <StudentMoreMenu
                                  row={row}
                                  students={students}
                                  onStudentsUpdate={handleStudentsUpdate}
                                  trashStudentCount={trashStudentCount}
                                  setTrashStudentCount={setTrashStudentCount}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={10} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: students.length, label: "All" },
                ]}
                component="div"
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : (
          <NoAccessData />
        )}
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Attendance Detail"
        componentToPassDown={
          //   <>
          //     <h1>hello</h1>
          //     <h1>hello</h1>
          //     <h1>hello</h1>
          //     <h1>hello</h1>
          //   </>
          <AssignmentDetail selectedRowStudent={selectedRowStudent} />
        }
      />
    </Page>
  );
}
