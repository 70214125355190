import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { _student_move_to_trash, delStudent } from "src/DAL/student";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import moment from "moment";
// ----------------------------------------------------------------------

export default function StudentMoreMenu({
  row,
  students,
  onStudentsUpdate,
  trashStudentCount,
  setTrashStudentCount,
  currentDate,
}) {
  const [openDelete, setOpenDelete] = useState(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    //console.log(row);
    // const result = await delStudent(row.id);
    const result = await _student_move_to_trash(row.id);
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students.id !== row.id
      );
      onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Move To Trash Successfully!", { variant: "success" });
      setTrashStudentCount(trashStudentCount + 1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to={`/attendance/detail/${row.student._id}?date=${moment(
            currentDate.start_date
          ).format("YYYY-MM-DD")}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify
              icon="icon-park-outline:view-grid-detail"
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "attendance",
          "delete"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/attendance/delete/${row.student._id}?date=${moment(
              currentDate.start_date
            ).format("YYYY-MM-DD")}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {/* <MenuItem sx={{ color: "text.secondary" }} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Move To Trash"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/update-student-fee/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:phone-update-checkmark-20-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Update Fee"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to Move This Student to Trash?"
        onOk={handleDeleteOk}
      />
    </>
  );
}
