// @mui
import PropTypes from 'prop-types';
import { alpha, styled ,useTheme} from '@mui/material/styles';
import { Box, Card, Paper, Typography } from '@mui/material';
// utils
import { fShortenNumber } from 'src/utils';
// components
import Iconify from '../../../components/Iconify';
// import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

SummaryCard4.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title1: PropTypes.string.isRequired,
  count1: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function SummaryCard4({ 
    title1, 
    count1, 
    cardBGColor, 
    cardTextColor,

    iconBGColor,
    iconTextColor,


    icon, color = 'primary', sx, ...other }) {
        const theme = useTheme(); // Use useTheme hook to access the theme

  return (
<div className="shadowBox2-0">
{/* <Paper elevation={1}  > */}
  <Card
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between', // To push the IconWrapperStyle to the right
      py: 1,
      boxShadow: 0,
      textAlign: 'center',
      color: cardTextColor,
      bgcolor: cardBGColor,
      borderRadius:0,
      ...sx,
    }}
    {...other}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
    
      <div style={{ marginLeft: '10px' }}>
      <Typography variant='h4' sx={{ textAlign:'start',fontWeight: 600, textDecoration: 'none !important' }}>
          {fShortenNumber(count1)}
        </Typography>

        <Typography variant='subtitle2' sx={{ textAlign:'start',opacity: 0.92, textDecoration: 'none' }}>
          {title1}
        </Typography>
       
      </div>
    </div>
    
    <div className='px-2' style={{ display: 'flex', alignItems: 'end' }}>
    <div
  style={{
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(5),
    height: theme.spacing(5),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    marginTop: '15px',
    color: iconTextColor,
    backgroundImage: `linear-gradient(135deg, ${iconBGColor} 0%, ${iconBGColor} 100%)`,

 
  }}
>
  <Iconify icon={icon} width={24} height={24} />
</div>
</div>
  </Card>
  {/* </Paper> */}
</div>

  );
}
