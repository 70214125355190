import { invokeApi } from "src/utils";

export const _website_page_course_benefits = async (id) => {
  const requestObj = {
    path: `api/website_course_benefits/website_page_course_benefits/` + id +`?page=0&limit=9999`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _delete_website_course_benefits = async (id) => {
  const requestObj = {
    path: `api/website_course_benefits/delete_website_course_benefits/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_website_course_benefits = async (data) => {
 
  const requestObj = {
    path: `api/website_course_benefits/add_website_course_benefits`,
    method: "POST",
    headers: {
   

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_website_course_benefits = async (id) => {
  const requestObj = {
    path: `api/website_course_benefits/detail_website_course_benefits/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_website_course_benefits = async (id, data) => {
  const requestObj = {
    path: `api/website_course_benefits/update_website_course_benefits/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
