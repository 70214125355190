import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _update_course_outline_details,
  _update_course_overview_details,
  detailCourse,
  listCourse,
} from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import TinyEditor from "src/components/Ckeditor";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
const CourseOverview = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([
    { number: "", type: "personal" },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);

  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: 1,
    courses: [],
    contact_number: "",
    status: 1,
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",

    discount: 0,
    fee_status: "all_paid",
    note: "note",

    registration_fee: "",
    course_id: "",

    show_advanced_settings: true,
    overview_details: "",
    secondary_image: "",
    enquire_button_text: "",
    course_outline_button_text: "",
    course_outline_button_link: "",
  });

  const myStyle = {
    textTransform: "capitalize",
  };

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;

    // Check if the first digit is 3
    if (name === "contact_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", {
        variant: "error",
      });
      return; // Do not update state if the condition is not met
    }

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    // if (value.length > 0 && value[0] !== "3") {
    //   enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
    //   return; // Do not update state if the condition is not met
    // }
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    if (!detailDescriptionEditor || detailDescriptionEditor === "") {
      error = "Overview Details is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    let postData = {
      show_advanced_settings: formInputs.show_advanced_settings,
      overview_details: detailDescriptionEditor,
      secondary_image:  pathOfUploadedFile,
      enquire_button_text: formInputs.enquire_button_text,
      course_outline_button_text: formInputs.course_outline_button_text,
      course_outline_button_link: formInputs.course_outline_button_link,
    };

    setIsLoading(true);
    const result = await _update_course_overview_details(id, postData);
    if (result.code === 200) {
      navigate(-1);
      // navigate("/students");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      // setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
    }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchDetailCourse() {
    setIsLoading(true);
    const response = await detailCourse(id);
    console.log("detailCourse __detailCourse", response);
    if (response.code === 200) {
      setFormInputs({
        ...formInputs,
        ...response.course,

        // pages: pageIds,
      });
      setDetailDescriptionEditor(response.course.overview_details);
      setPathOfUploadedFile(response.course.secondary_image);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchDetailCourse();
  }, []);

  useEffect(() => {
    console.log("additionalFields   ____additionalFields", additionalFields);

    const additionalFieldsArray = additionalFields.map((field) => field.number);

    // console.log(additionalFieldsArray);
    console.log(
      "additionalFieldsArray   ____additionalFields",
      additionalFieldsArray
    );
    // setAdditionalFields
  }, [additionalFields]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Course Overview">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Course Overview{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Enquire Button Text"
                  value={formInputs.enquire_button_text}
                  onChange={handleChange}
                  name="enquire_button_text"
                  required
                />

                <TextField
                  fullWidth
                  label="Show Advanced Settings"
                  select
                  value={formInputs.show_advanced_settings}
                  onChange={handleChange}
                  name="show_advanced_settings"
                  required
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Course Outline Button Text"
                  value={formInputs.course_outline_button_text}
                  onChange={handleChange}
                  name="course_outline_button_text"
                  required
                />

                <TextField
                  fullWidth
                  label="Course Outline Button Link"
                  value={formInputs.course_outline_button_link}
                  onChange={handleChange}
                  name="course_outline_button_link"
                  // required
                />
              </Stack>

              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <div className="col-12">
                <p className="assignment_sub_heading_in_add_edit">
                  Overview Details *
                </p>

                <TinyEditor
                  detailDescriptionCk={detailDescriptionEditor}
                  setDetailDescription={setDetailDescriptionEditor}
                />
              </div>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default CourseOverview;
