import { invokeApi } from "src/utils";

export const _active_time_preference = async () => {
  const requestObj = {
    path: `api/time_preference/active_time_preference`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_time_preference = async (data) => {
  const requestObj = {
    path: `api/time_preference/add_time_preference`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};

export const _delete_time_preference = async (id) => {
const requestObj = {
  path: `api/time_preference/delete_time_preference/` + id,
  method: "DELETE",
  headers: {
    "x-sh-auth": localStorage.getItem("token"),
  },
};

return invokeApi(requestObj);
};


export const _list_time_preference = async (data) => {
  const requestObj = {
    path: `api/time_preference/list_time_preference`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data, 
  };
  return invokeApi(requestObj);
};

export const _edit_time_preference = async (id, data) => {
const requestObj = {
  path: `api/time_preference/edit_time_preference/` + id,
  method: "PUT",
  headers: {
    "x-sh-auth": localStorage.getItem("token"),
  },
  postData: data,
};
return invokeApi(requestObj);
};

export const _detail_time_preference = async (id) => {
const requestObj = {
  path: `api/time_preference/detail_time_preference/` + id,
  method: "GET",
  headers: {
    "x-sh-auth": localStorage.getItem("token"),
  },
};
return invokeApi(requestObj);
};
