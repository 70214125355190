import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { delStudent } from "src/DAL/student";
import { _delete_brand } from "src/DAL/brand";
import { _delete_asset_category } from "src/DAL/asset_category";
import { _delete_vendor } from "src/DAL/vendor";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import ApproveFeeVoucher from "../ApproveFeeVoucher";
import EditVoucher from "../../Edit/EditVoucher";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { _delete_pending_voucher, _generate_invoice } from "src/DAL/voucher";
import CreateInvoice from "../../CreateInvoice/CreateInvoice";
import RefundFeeVoucher from "../RefundFeeVoucher";
import { capitalizeFirstLetter } from "src/utils/formatText";
// ----------------------------------------------------------------------

export default function StudentMoreMenu({ row, students, onStudentsUpdate }) {
  const [openDelete, setOpenDelete] = useState(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleCloseModal = () => setOpenModal(false);
  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleCloseRefundModal = () => setOpenRefundModal(false);
  const handleCloseInvoiceModal = () => setOpenInvoiceModal(false);

  function handleOpenModal() {
    setOpenModal(true);
    setIsOpen(false);
    setSelectedRow(row);
  }

  function handleRefundOpenModal() {
    setOpenRefundModal(true);
    setIsOpen(false);
    setSelectedRow(row);
  }

  function handleOpenEditModal() {
    setOpenEditModal(true);
    setIsOpen(false);
    setSelectedRow(row);
  }

  function handleOpenInvoiceModal() {
    setOpenInvoiceModal(true);
    setIsOpen(false);
    setSelectedRow(row);
  }

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    // console.log("row   _____row",row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };

  async function handleClick(row) {
    //console.log(row);
    const result = await _delete_pending_voucher(row.id);
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students.id !== row.id
      );
      onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "fee_voucher",
          "edit"
        ) ? (
          <>
            {row.voucher_status !== 4 ? (
              <>
                <MenuItem
                    sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
                  onClick={handleOpenEditModal}
                >
                  <ListItemIcon>
                    <Iconify icon="eva:edit-fill" width={24} height={24} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Edit"
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </MenuItem>

                <MenuItem
                    sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
                  onClick={handleOpenModal}
                >
                  <ListItemIcon>
                    <Iconify
                      icon="material-symbols:order-approve-outline"
                      width={24}
                      height={24}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Approve Voucher"
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </MenuItem>
              </>
            ) : (
              ""
            )}

            {/* <MenuItem
          component={RouterLink}
          to={`/vouchers/generate-invoice/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="mdi:invoice" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Generate Invoice"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

            <MenuItem
                sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
              onClick={handleOpenInvoiceModal}
            >
              <ListItemIcon>
                <Iconify
                  icon="iconamoon:invoice-light"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary="Create Invoice"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>

            {/* <MenuItem sx={{ color: "text.secondary" }} onClick={handleInvoiceClick}>
          <ListItemIcon>
            <Iconify icon="mdi:invoice-arrow-right-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Create Invoice"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
          </>
        ) : (
          ""
        )}

{privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "fee_voucher",
          "delete"
        ) && row.voucher_status === 1 ? (

          <MenuItem
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {row.voucher_status === 2 ? (
          <MenuItem
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleRefundOpenModal}
          >
            <ListItemIcon>
              <Iconify icon="ri:refund-2-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Refund Voucher"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        // pageTitle="Approve Voucher"
        pageTitle={`${capitalizeFirstLetter(row.fee_title)} ( Approve Voucher )`}
        componentToPassDown={
          <ApproveFeeVoucher
            Row={row}
            Students={students}
            onStudentsUpdate={onStudentsUpdate}
            handleCloseModal={handleCloseModal}
          />
        }
      />

      <CustomModal
        isOpenModal={openEditModal}
        handleCloseModal={handleCloseEditModal}
        // pageTitle="Edit Voucher"
        pageTitle={`${capitalizeFirstLetter(row.fee_title)} ( Edit Voucher )`}
        componentToPassDown={
          <EditVoucher
            Row={row}
            Students={students}
            onStudentsUpdate={onStudentsUpdate}
            handleCloseModal={handleCloseEditModal}
          />
        }
      />

      <CustomModal
        isOpenModal={openInvoiceModal}
        handleCloseModal={handleCloseInvoiceModal}
        // pageTitle="Create Invoice"
        pageTitle={`${capitalizeFirstLetter(row.fee_title)} ( Create Invoice )`}
        componentToPassDown={
          <>
            <h1></h1>

            <CreateInvoice
              Row={row}
              // Students={students}
              // onStudentsUpdate={onStudentsUpdate}
              handleCloseModal={handleCloseEditModal}
            />
          </>
          // <EditVoucher
          //   Row={row}
          //   Students={students}
          //   onStudentsUpdate={onStudentsUpdate}
          //   handleCloseModal={handleCloseEditModal}
          // />
        }
      />

      <CustomModal
        isOpenModal={openRefundModal}
        handleCloseModal={handleCloseRefundModal}
        pageTitle={`${capitalizeFirstLetter(row.fee_title)} ( Refund Voucher )`}
        componentToPassDown={
          <RefundFeeVoucher
            Row={row}
            Students={students}
            onStudentsUpdate={onStudentsUpdate}
            handleCloseModal={handleCloseRefundModal}
          />
        }
      />
    </>
  );
}
