import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

DataNotFoundAll.propTypes = {
  searchQuery: PropTypes.string,
};

export default function DataNotFoundAll({ searchQuery = "", ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
      <strong> Data Not Found </strong> 
      </Typography>
      <Typography variant="body2" align="center">
        Sorry, no results were found. Please try again. 
      </Typography>
    </Paper>
  );
}
