import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { _filter_student, addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _filter_enquiry_student } from "src/DAL/enquirystudent";
// const FilterStudent = () => {

export default function FilterEnquiryStudent({
  // task_id,
  onCloseDrawer,
  isOpenDrawer,
  // formType,
  students,
  setStudents,
}) {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    courses: ['all'],
    phone: "",
    fee_status: "registration_fee",
    status: "true",
    education: "",
    image: "",
    reg_type: "regular",
    course_fee: "",
    registration_fee: "",
    decided_fee: "",
    actual_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    batches: [],
    enquiry_status: 5,
    gender: "all",
  });

  const handleCourseChange = (e) => {
    const { name, value } = e.target;

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY/MM/DD");
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY/MM/DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let startDate = dayjs(formInputs2.duration).format("YYYY/MM/DD");
    let endDate = dayjs(formInputs2.duration2).format("YYYY/MM/DD");

    let enquiryStatus = formInputs.enquiry_status;
    let genderStudent = [formInputs.gender];
    let courses = formInputs.courses;

    console.log('courses ____',courses);
    if (formInputs.enquiry_status === "all") {
      enquiryStatus = [];
    }
    if (formInputs.gender === "all") {
      genderStudent = [];
    }
    if (formInputs.courses.length >0 &&formInputs.courses[0]  === 'all') {
      courses = "";
    }
    console.log('courses ____',courses);
  
    console.log("formInputs", formInputs);
    console.log("formInputs2", formInputs2);
    console.log("startDate", startDate);
    console.log("endDate", endDate);
    console.log("enquiryStatus", enquiryStatus);
    console.log("genderStudent", genderStudent);

    console.log("formInputs.courses  courses", courses);

    // let batch_id;

    // if (Array.isArray(formInputs.batches)) {
    //   batch_id = formInputs.batches;
    // } else {
    //   batch_id = [formInputs.batches];
    // }

    let postData = {
      start_date: startDate,
      end_date: endDate,
      enquiry_status: enquiryStatus,
      gender: genderStudent,
      course: courses===""?[]:[courses],
    };

    console.log("postData", postData);

    setIsLoading(true);
    const result = await _filter_enquiry_student(postData);
    console.log("result", result);

    if (result.code === 200) {
      const students = result.enquiry_forms.map((student, index) => ({
        id: student._id,
        name: student.name,
        referred_by: student.referred_by,
        enquiry_status: student.enquiry_status,
        // father_name: student.father_name,
        // date_of_birth: student.date_of_birth,
        // enquiry_time: student.date_of_birth,
        enquiry_time: formatDate(student.createdAt),
        email: student.email,
        mobile_number: student.mobile_number,
        gender: student.gender,
        courses: student.course ? student.course : "",
        others: student.others ? student.others : "",
        number: index + 1,
        // others: student.others ? student.others : "",
      }));

      setStudents(students);
      setIsLoading(false);
      enqueueSnackbar("Filter Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchCourses = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response listCourse listCourse listCourse", response);
    if (response.code === 200) {
      const courses = response.courses
        .filter((course) => course.status === true)
        .map((course) => ({
          id: course._id,
          fee: course.fee,
          title: course.title,
          description: course.description,
          duration: course.duration,
          // image: course.image,
          status: course.status,
        }));
      console.log("courses courses courses courses", courses);
      setCourses(courses);

      // Set the first course as the default value for formInputs.courses
      
      // if (courses.length > 0) {
      //   setFormInputs((prevInputs) => ({
      //     ...prevInputs,
      //     courses: courses[0].id,
      //   }));
      // }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Filter Enquiry">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px", paddingX: "20px" }}
      >
        <Typography variant="h4" align="center" sx={{ flex: 1 }}>
          Filter Enquiry
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        sx={{ marginBottom: "40px", marginTop: "20px", paddingX: "20px" }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <Stack spacing={3}>
            {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ marginTop: 2 }}
            >
              {/* <div className="col-md-12 col-xs-12"> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // disabled
                  label="Start Date"
                  format="YYYY-MM-DD"
                  // value={formInputs?.date_of_birth}
                  value={dayjs(formInputs2.duration)} // Convert the default date to a Day.js object
                  onChange={handleDateChange}
                  // maxDate={dayjs()} // Set the maximum date to today's date
                  // renderInput={(params) => <TextField {...params} />}
                  sx={{
                    width: "100%", // Set the width to 100% to take up the full available width
                    // marginTop: "20px",
                  }}
                />
              </LocalizationProvider>
              {/* </div> */}

              {/* <div className="col-md-12 col-xs-12"> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // disabled
                  label="End Date"
                  format="YYYY-MM-DD"
                  // value={formInputs?.date_of_birth}
                  value={dayjs(formInputs2.duration2)} // Convert the default date to a Day.js object
                  onChange={handleDateChange2}
                  // maxDate={dayjs()} // Set the maximum date to today's date
                  // renderInput={(params) => <TextField {...params} />}
                  sx={{
                    width: "100%", // Set the width to 100% to take up the full available width
                    // marginTop: "20px",
                  }}
                />
              </LocalizationProvider>
              {/* </div> */}
            </Stack>

            <TextField
              fullWidth
              label="Expire / Do Not Call"
              select
              value={formInputs.enquiry_status}
              onChange={handleChange}
              name="enquiry_status"
            >
               <MenuItem value={1}>Do Not Distrub</MenuItem>
                <MenuItem value={2}>Wrong Number </MenuItem>
                <MenuItem value={3}>Number Close</MenuItem>
                <MenuItem value={4}>Expire</MenuItem>
                <MenuItem value={5}>Active</MenuItem>
            </TextField>

            <TextField
              fullWidth
              label="Gender"
              select
              // required
              value={formInputs.gender}
              onChange={handleChange}
              name="gender"
            >
               <MenuItem value="all">All</MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </TextField>

            <TextField
              fullWidth
              label="Courses"
              select
              value={formInputs.courses}
              onChange={handleCourseChange}
              name="courses"
              // required
              SelectProps={{ multiple: false }}
            >
               <MenuItem value="all">All</MenuItem>
              {courses.map((course) => (
                <MenuItem key={course.id} value={course.id}>
                  {course.title}
                </MenuItem>
              ))}
            </TextField>

            <Stack alignItems="end">
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "120px" }}
              >
                Filter
              </LoadingButton>
            </Stack>
          </Stack>
          {/* </Stack> */}
        </form>
      </Grid>
      {/* </Grid> */}
    </Page>
  );
}
