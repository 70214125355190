import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { _active_time_preference } from "src/DAL/timepreference";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  InputLabel,
  Tooltip,
  Button,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _addEnquiryStudent, _delEnquiryStudent } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomPasswordField from "src/components/GeneralComponents/CustomPasswordField";
const Add = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const [timePreferences, setTimePreferences] = useState([]);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [students, setStudents] = useState([]);
  const [selected, setSelected] = useState([]);
  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [password, setPassword] = useState("ddit_tech");
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    gender: 1,
    courses: [],
    phone: "",
    fee_status: "paid",
    status: 1,
    education: "",
    image: "",
    reg_type: "regular",
    course_fee: 0,
    registration_fee: 0,
    decided_fee: 0,
    actual_fee: 0,
    cnic: "",
    referred_by: "",
    address: "",
    batches: [],
    dob: null,
    date_of_birth: dayjs(),
    student_id: null,
    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    wallet_amount: 0,
    time_preferences: "",

    discount: 0,
    fee_status: "all_paid",

    registration_fee: "",
    course_id: "",
  });
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const myStyle = {
    textTransform: "capitalize",
  };

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    
    if (value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }

    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleCourseChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    // Find the course object with the specified _id
    const course = courses.find((item) => item.id === value);

    // const course = courses.find((item) => {
    //   console.log('item', item);
    //   return item._id === '64a45cd7e0ad1892dd66cd1c';
    // });

    const fee = course ? course.fee : null;

    console.log("course", course);
    console.log("fee", fee); // Output: 24000

    if (fee !== null) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        actual_fee: fee,
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "phone_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);
      let fee = null;

      if (selectedBatch.courses) {
        if (selectedBatch.courses.length > 0) {
          fee = selectedBatch.courses[0].fee;
        }
      }
      // let fee = course ? course.fee : null;

      // const fee = selectedBatch ? selectedBatch.fee : null;
      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      if (fee !== null) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
          actual_fee: fee,
        }));
      } else {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
        }));
      }
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    console.log("date", date);
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
    // setFormInputs((values) => ({ ...values, date_of_birth: formattedDate }));
  };

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("formInputs", formInputs);
    let error = "";

    if (!formInputs.first_name) {
      error = "Name is Required";
      enqueueSnackbar(error, { variant: "error" });
    }
    // else if (!parseInt(formInputs.phone_number.trim(), 10)) {
    //   error = "Phone Number is Required";
    //   enqueueSnackbar(error, { variant: "error" });
    // }
    // else if (!parseInt(formInputs.course_fee.trim(), 10)) {
    //   error = "Invalid Registration Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (
    //   !parseInt(formInputs.registration_fee.trim(), 10) &&
    //   formInputs.registration_fee.trim() !== ""
    // ) {
    //   error = "Invalid Decided Registration Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    // decided_fee
    // else if (
    //   parseInt(formInputs.registration_fee, 10) >
    //   parseInt(formInputs.course_fee, 10)
    // ) {
    //   const error =
    //     "Invalid Registration Fee. The decided fee cannot be greater than the actual fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }
    //  else if (!parseInt(formInputs.actual_fee, 10)) {
    //   error = "Invalid Actual Course Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (
    //   !parseInt(formInputs.decided_fee, 10) &&
    //   formInputs.decided_fee !== ""
    // ) {
    //   error = "Invalid Decided Course Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    // else if (
    //   parseInt(formInputs.decided_fee, 10) >
    //   parseInt(formInputs.actual_fee, 10)
    // ) {
    //   const error =
    //     "Invalid Decided Course Fee. The decided fee cannot be greater than the actual fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    console.log("formInputs", formInputs);
    if (formInputs.first_name === null || formInputs.first_name === "") {
      enqueueSnackbar("Please! Select a student from enquiry", {
        variant: "error",
      });
    } else {
      if (error === "") {
        const data = new FormData();
        data.append("name", formInputs.first_name.trim());
        data.append("father_name", formInputs.father_name.trim());
        // data.append("profile_image", image);
        // data.append("date_of_birth", formInputs.dob);
        data.append("email", formInputs.email.trim());
        data.append("contact_number", formInputs.phone_number);
        data.append("gender", formInputs.gender);

        if (formInputs.dob) {
          data.append("date_of_birth", formInputs.dob);
        }

        data.append("course_id", formInputs.course_id);
        data.append("registration_fee", parseInt(formInputs.registration_fee));

        // data.append(
        //   "course_id",
        //   JSON.stringify(
        //     Array.isArray(formInputs.courses)
        //       ? formInputs.courses
        //       : [formInputs.courses]
        //   )
        // );

        // data.append(
        //   "batch_id",
        //   JSON.stringify(
        //     Array.isArray(formInputs.batches)
        //       ? formInputs.batches
        //       : [formInputs.batches]
        //   )
        // );
        // data.append("batch_id", formInputs.batches);
        data.append("status", formInputs.status);
        // data.append("reg_type", formInputs.reg_type);

        // data.append("course_fee", formInputs.course_fee);
        // data.append("registration_fee", formInputs.registration_fee);
        // data.append("actual_fee", formInputs.actual_fee);
        // data.append("decided_fee", formInputs.decided_fee);

        data.append("latest_degree", formInputs.latest_degree);
        data.append("degree_status", formInputs.degree_status);
        data.append(
          "completion_year",
          formInputs.degree_status === "complete" ? selectedYear : ""
        );
        data.append("institution_name", formInputs.institution_name);
        data.append("semester", formInputs.semester);
        data.append("time_preference_id", formInputs.time_preferences);

        data.append("additional_numbers", JSON.stringify(additionalFields));
        // data.append("decided_fee", JSON.stringify(additionalFieldsCourse));

        // data.append("wallet_amount", formInputs.wallet_amount);

        // data.append("discount", formInputs.discount);
        // data.append("fee_status", formInputs.fee_status);

        // data.append("student_id", formInputs.student_id);
        // data.append("course_fee", parseInt(formInputs.course_fee, 10));
        // data.append(
        //   "registration_fee",
        //   parseInt(formInputs.registration_fee, 10)
        // );
        // data.append("actual_fee", parseInt(formInputs.actual_fee, 10));
        // data.append("decided_fee", parseInt(formInputs.decided_fee, 10));

        data.append("cnic", formInputs.cnic);
        data.append("referred_by", formInputs.referred_by);
        data.append("address", formInputs.address);
        data.append("note", formInputs.note);
        data.append("password", password);
        
        if (formInputs.phone_number.length !== 10) {
          error = "Please enter a valid 10-digit Phone Number";
          enqueueSnackbar(error, { variant: "error" });
        } else {
          // console.log(formDataArray);
          setIsLoading(true);
          const result = await addStudent(data);
          if (result.code === 200) {
            // formInputs._id
            // _id

            const result = await _delEnquiryStudent(formInputs._id);
            if (result.code === 200) {
              setIsLoading(false);
              navigate("/students");
              enqueueSnackbar("Added Successfully!", { variant: "success" });
            } else {
              setIsLoading(false);
              enqueueSnackbar(result.message, { variant: "error" });
            }
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   if (courses.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       courses: [courses[0]._id],
  //     }));
  //   }
  // }, [courses]);

  useEffect(() => {
    if (batches.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        batches: [batches[0].id],
      }));
      console.log("batches __test", batches);
      // const additionalFieldsFromBatch = batches[0].accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);
    }
  }, [batches]);

  const fetchActiveBatches = async () => {
    setIsLoading(true);
    const response = await listActiveBatch();
    console.log("response __test", response);
    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
        // accumulative_revenue: batch.accumulative_revenue,
      }));
      // setCourses(batches[0].courses);
      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  // const fetchStudents = async () => {
  //   const response = await listStudent();
  //   console.log("response response response", response);
  //   if (response.code === 200) {
  //     setIsLoading(false);

  //     const students = response.students.map((student) => ({
  //       id: student._id,
  //       reg_type: student.reg_type,
  //       name: student.name,
  //       first_name: student.name,
  //       father_name: student.father_name,
  //       profile_image: student.profile_image,
  //       date_of_birth: student.date_of_birth,
  //       email: student.email,
  //       image: student.profile_image,
  //       status: student.status,
  //       contact_number: student.contact_number,
  //       gender: student.gender,
  //       course_fee: student.course_fee,
  //       actual_fee: student.actual_fee,
  //       decided_fee: student.decided_fee,

  //       registration_fee: student.registration_fee,
  //       batch: student.batch_id ? student.batch_id : "",
  //       courses: student.courses ? student.courses : "",

  //       paid_fee: student.paid_fee,
  //       due_fee: student.due_fee,

  //     }));

  //     setStudents(students);
  //     console.log("students students students", students);
  //   } else {
  //     setIsLoading(false);
  //     enqueueSnackbar(response.message, { variant: "error" });
  //   }
  // };

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _addEnquiryStudent();
    console.log("response _ EnquiryStudent List", response);
    if (response.code === 200) {
      const students = response.enquiry_form.map((student) => {
        // let gender;
        // if (student.gender === "Male" || student.gender === "male" || student.gender === 1) {
        //   gender = "male";
        // } else if (student.gender === "Female" || student.gender === "female") {
        //   gender = "female";
        // } else {
        //   gender = "male";
        // }

        // let DOB;
        // if (student.date_of_birth === "") {
        //   // DOB = "2022-11-01";
        //   // const initialDate = new Date('01/01/1970');
        //   // DOB = initialDate;
        //   DOB = '01/01/1970';
        //   console.log('DOB',DOB);
        // }  else {
        //   // const dob = "2022-11-01"; // Replace with your date string
        //   const dob = student.date_of_birth; // Replace with your date string
        //   const parts = dob.split("-");
        //   const formattedDob = `${parts[1]}/${parts[2]}/${parts[0]}`;
        //   DOB = formattedDob;
        //   console.log('DOB',DOB);
        // }
        console.log("student.contact_number", student.contact_number);

        return {
          ...response.enquiry_form,
          id: student._id,
          name: capitalizeFirstLetter(student.name),
          father_name: capitalizeFirstLetter(student.father_name),
          // email: student.email,
          mobile_number: student.contact_number,
          gender: student.gender,
          note: student.note,
          referred_by: student.referred_by,
          course: student.course ? student.course : [],
          others: student.others ? student.others : [],
          registration_fee: student.decided_registration_fee
            ? student.decided_registration_fee
            : 0,
          decided_fee: student.decided_course_fee
            ? student.decided_course_fee
            : 0,
          latest_degree: student.latest_degree,
          degree_status: student.degree_status,
          completion_year: student.completion_year,
          institution_name: student.institution_name,
          semester: student.semester,
          note: capitalizeFirstLetter(student.note),
          time_preferences: student.time_preference
            ? student.time_preference._id
            : "",

          // date_of_birth: student.date_of_birth ? student.date_of_birth : "",
          // date_of_birth: DOB,
        };
      });

      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const updateFormInputs = (selectedValues) => {
    const updatedFormInputs = { ...formInputs };
    console.log("selectedValues  ______ selectedValues", selectedValues);
    // let course_id = selectedValues.course.map((course) => course._id);
    let course_fee = [];
    let course_format_fee = 0;

    // course_fee = selectedValues.course.map((course) => course.fee);

    if (course_fee.length > 0) {
      course_format_fee = course_fee[0];
    }

    // console.log("courses id ______ ", course_id);
    // console.log("course_fee   ______ ", course_fee);
    // console.log("course_format_fee   ______ ", course_format_fee);

    // let courseIdString = course_id.map((id) => id).join("");
    // const course = courses.find((item) => item.id === courseIdString);

    // const fee = course ? course.fee : null;

    // if (fee !== null) {
    //   updatedFormInputs.actual_fee = fee;
    // }

    setSelectedYear(selectedValues.completion_year);

    console.log(
      "selectedValues.mobile_number   ______ ",
      selectedValues.mobile_number
    );

    updatedFormInputs.actual_fee = course_format_fee;
    updatedFormInputs.courses = selectedValues.course._id;
    updatedFormInputs._id = selectedValues.id;
    updatedFormInputs.first_name = selectedValues.name;
    updatedFormInputs.father_name = selectedValues.father_name;
    // updatedFormInputs.email = selectedValues.email;
    updatedFormInputs.mobile_number = selectedValues.mobile_number;
    updatedFormInputs.referred_by = selectedValues.referred_by;
    updatedFormInputs.gender = selectedValues.gender;

    updatedFormInputs.contact_number = selectedValues.mobile_number;
    // updatedFormInputs.phone_number = selectedValues.contact_number.toString();
    // updatedFormInputs.phone_number = selectedValues.contact_number.toString();
    updatedFormInputs.phone_number = selectedValues.mobile_number;

    updatedFormInputs.registration_fee = selectedValues.registration_fee;
    updatedFormInputs.decided_fee = selectedValues.decided_fee;

    updatedFormInputs.latest_degree = selectedValues.latest_degree;
    updatedFormInputs.degree_status = selectedValues.degree_status;
    updatedFormInputs.completion_year = selectedValues.completion_year;
    updatedFormInputs.institution_name = selectedValues.institution_name;
    updatedFormInputs.semester = selectedValues.semester;
    updatedFormInputs.time_preferences = selectedValues.time_preferences;
    updatedFormInputs.note = selectedValues.note;

    updatedFormInputs.course_id = selectedValues.course
      ? selectedValues.course._id
      : "";

    // Set the updated formInputs state

    // updatedFormInputs.dob = selectedValues.date_of_birth;

    // const dateStr = selectedValues.date_of_birth; // Replace with your date string

    // if(dateStr ==="" || dateStr ===null){
    //   console.log('not date',dateStr);
    //   updatedFormInputs.date_of_birth = dayjs();
    // }else{

    //   const parts = dateStr.split("/");
    // const formattedDateStr = `${parts[0]}/${parts[1]}/${parts[2]}`;
    // const dateObj = new Date(formattedDateStr);
    // const _date = dayjs(dateObj);

    //   console.log('date',dateStr);
    // //   onsole.log('dateStr',dateStr);
    // // console.log('parts',parts);
    // // console.log('formattedDateStr',formattedDateStr);
    // // console.log('dateObj',dateObj);
    // // console.log('_date',_date);
    // updatedFormInputs.date_of_birth = _date;
    // }
    console.log("updatedFormInputs ___updatedFormInputs", updatedFormInputs);
    setFormInputs(updatedFormInputs);
  };

  const handleSelectChange = (event, newValue) => {
    if (newValue === null) {
      console.log("newValue", newValue);
      setSelected([]);
      updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setSelected(newValue);
      updateFormInputs(newValue);
    }
  };
  const fetchTimePreference = async () => {
    // setIsLoading(true);
    const response = await _active_time_preference();
    console.log("response  _active_time_preference __test", response);

    //

    if (response.code === 200) {
      // const courses = response.courses
      //   .filter((course) => course.status === true)
      //   .map((course) => ({
      //     id: course._id,
      //     fee: course.fee,
      //     title: course.title,
      //     description: course.description,
      //     duration: course.duration,
      //     // image: course.image,
      //     status: course.status,
      //   }));
      // console.log("courses courses courses courses", courses);
      // setCourses(courses);
      setTimePreferences(response.time_preferences);

      if (response.time_preferences.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          time_preferences: response.time_preferences[0]._id,
        }));
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((student) => {
      const nameMatch = student.name;
      // .toLowerCase()
      // .includes(inputValue.toLowerCase());
      const numberMatch = student.mobile_number;
      // .toLowerCase()
      // .includes(inputValue.toLowerCase());
      return nameMatch || numberMatch;
    });
  };

  const fetchActiveCourse = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response listCourse ___listCourse", response);
    if (response.code === 200) {
      // const batches = response.batches.map((batch) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   student_count: batch.student_count,
      //   status: batch.status,
      //   courses: batch.courses,
      // }));
      // Filter and set active courses
      const activeCourses = response.courses.filter(
        (course) => course.status === true
      );
      setCourses(activeCourses);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
    // fetchActiveBatches();
    fetchTimePreference();
    fetchActiveCourse();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Student">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Add Student From Enquiry{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack spacing={2} sx={{ marginBottom: 2 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={students}
              getOptionLabel={(student) => (student.name ? student.name : "")}
              value={selected}
              onChange={handleSelectChange}
              filterOptions={filterOptions} // Apply the custom filter function
              renderInput={(params) => (
                <TextField {...params} label="Student" />
              )}
              renderOption={(props, student) => (
                <Tooltip
                  title={
                    // `Name: ${student.name}\nFather's Name: ${student.father_name}\nMobile Number: ${student.mobile_number}\nReferred By: ${student.referred_by}`
                    <div>
                      Course = {student.course.title}
                      {/* {student.course.length > 0
                        ? student.course
                            .map((course, index) => {
                              return course !== null ? course.title : "";
                            })
                            .join(", ")
                        : ""} */}
                      <br />
                      Father Name = {student.father_name} <br />
                      Phone Number = +92{student.mobile_number} <br />
                      Referred By = {student.referred_by} <br />
                      Gender = {student.gender == 1 ? "Male" : "Female"} <br />
                      Note = {student.note} <br />
                      {/* Email = {email} <br />
                                Address = {address} <br />
                                Registration Type = {reg_type} <br />
                                Actual Registration Fee = {course_fee} <br />
                                Actual Course Fee= {actual_fee} <br /> */}
                    </div>
                  }
                >
                  <li {...props}>{student.name}</li>
                </Tooltip>
              )}
            />
          </Stack>
          {typeof selected.id !== "undefined" ? (
            <>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3}>
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={formInputs.first_name}
                      onChange={handleChange}
                      name="first_name"
                      required
                      // inputProps={{
                      //   pattern: "[A-Za-z]{3,}",
                      //   title:
                      //     "Please enter at least 3 letters with only alphabetical characters.",
                      // }}
                    />

                    <TextField
                      fullWidth
                      label="Father name"
                      value={formInputs.father_name}
                      onChange={handleChange}
                      name="father_name"
                      // required
                      // inputProps={{
                      //   pattern: "[A-Za-z]{3,}",
                      //   title:
                      //     "Please enter at least 3 letters with only alphabetical characters.",
                      // }}
                    />
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {/* <div className="col-12"> */}
                    {/* <InputLabel htmlFor="Number">Phone Number *</InputLabel> */}
                    <TextField
                      // disabled
                      fullWidth
                      label="Phone Number"
                      value={formInputs.phone_number}
                      onChange={handleChangePhone}
                      name="phone_number"
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+92</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        pattern: "[0-9]*", // Only allow numeric characters
                        maxLength: 10, // Limit the input to 10 characters
                        title: "Please enter a valid 10-digit phone number",
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />

                    <Button
                      size="medium"
                      // type="submit"
                      // variant="contained"
                      // loading={isLoading}
                      // sx={{ width: "100px" }}
                      variant="outlined"
                      onClick={handleAddField}
                    >
                      +
                    </Button>
                    {/* </div> */}
                  </Stack>
                  {/* </Stack> */}

                  {additionalFields.map((field, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* Conditionally render the Remove button */}
                      {showRemoveButtons[index] && (
                        <IconButton
                          aria-label="Delete Field"
                          onClick={() => handleDeleteField(index)}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <TextField
                        fullWidth
                        label={`Phone ${index + 1}`}
                        name="number"
                        value={field.number}
                        onChange={(e) => handleFieldChange(e, index)}
                        sx={{ marginRight: "10px" }}
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +92
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          maxLength: 10, // Limit the input to 10 characters
                          title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />

                      <TextField
                        fullWidth
                        label="Phone Type"
                        select
                        name="type"
                        value={field.type}
                        onChange={(e) => handleFieldChange(e, index)}
                      >
                        <MenuItem value="personal">Personal</MenuItem>
                        <MenuItem value="family">Family</MenuItem>
                        <MenuItem value="friend">Friend</MenuItem>
                        <MenuItem value="whatsapp">Whatsapp</MenuItem>
                        <MenuItem value="work">Work</MenuItem>
                        <MenuItem value="emergency">Emergency</MenuItem>
                        <MenuItem value="institute">Institute</MenuItem>
                        <MenuItem value="other">other</MenuItem>
                      </TextField>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleAddField} // Your function to add a new field
                        sx={{ marginLeft: "10px" }}
                      >
                        Add
                      </Button>
                    </div>
                  ))}

{/* Password */}
<CustomPasswordField
                  passwordVariable={password}
                  setPasswordVariable={setPassword}
                  passwordLabel="Password"
                  Required={true}
                  show={true}
                />

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      type="email"
                      label="Email address"
                      value={formInputs.email}
                      onChange={handleChange}
                      name="email"
                      autoComplete="off"
                      // required
                      // inputProps={{
                      //   pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                      //   title: "Please enter a valid email address",
                      // }}
                    />

                    <TextField
                      fullWidth
                      label="Gender"
                      select
                      // required
                      value={formInputs.gender}
                      onChange={handleChange}
                      name="gender"
                    >
                      <MenuItem value={1}>Male</MenuItem>
                      <MenuItem value={2}>Female</MenuItem>
                    </TextField>
                  </Stack>
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <div className="col-6">
                  <InputLabel htmlFor="emailaddress">Email address</InputLabel>
                  <TextField
                    fullWidth
                    type="email"
                    // disabled
                    // label="Email address"
                    value={formInputs.email}
                    onChange={handleChange}
                    name="email"
                    autoComplete="off"
                    // required
                    // inputProps={{
                    //   pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                    //   title: "Please enter a valid email address",
                    // }}
                  />
                </div>

                <div className="col-6">
                  <InputLabel htmlFor="gender">Gender</InputLabel>
                  <TextField
                    fullWidth
                    // label="Gender"
                    select
                    // required
                    value={formInputs.gender}
                    onChange={handleChange}
                    name="gender"
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </TextField>
                </div>
              </Stack> */}

                  {/* <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                  required
                >
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="due">Due</MenuItem>
                  <MenuItem value="free">Full Fee Scholarship</MenuItem>
                </TextField> */}
                  {/* </Stack> */}

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {/* <TextField
                      fullWidth
                      label="CNIC"
                      // required
                      value={formInputs.cnic}
                      onChange={handleChange}
                      name="cnic"
                    /> */}

                    <TextField
                      fullWidth
                      label="Registration Fee"
                      value={formInputs.registration_fee}
                      onChange={handleChange}
                      name="registration_fee"
                      sx={{ marginRight: "10px" }}
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">RS</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        pattern: "[0-9]*", // Only allow numeric characters
                        // maxLength: 10, // Limit the input to 10 characters
                        // title: "Please enter a valid 10-digit phone number",
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />

                    {/* <TextField
                  fullWidth
                  label="Student ID (Unique)"
                  value={formInputs.student_id}
                  required
                  onChange={handleChange}
                  name="student_id"
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                /> */}

                    {/* <div className="col-12"> */}

                    {/* <InputLabel htmlFor="status">Status</InputLabel> */}
                    <TextField
                      fullWidth
                      label="Status"
                      select
                      value={formInputs.status}
                      onChange={handleChange}
                      name="status"
                      // required
                    >
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={2}>Inactive</MenuItem>
                    </TextField>
                    {/* </div> */}
                  </Stack>

                  <TextField
                    fullWidth
                    label="CNIC Number"
                    value={formInputs.cnic}
                    onChange={handleCNICChange}
                    name="cnic"
                    autoComplete="off"
                    inputProps={{
                      pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                      title:
                        "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
                    }}
                  />

                  {/* <TextField
                    fullWidth
                    label="Batch"
                    select
                    value={formInputs.batches}
                    onChange={handleChange}
                    name="batches"
                    required
                    // SelectProps={{ multiple: true }}
                  >
                    {batches.map((batch) => (
                      <MenuItem key={batch.id} value={batch.id}>
                        {batch.name}
                      </MenuItem>
                    ))}
                  </TextField> */}

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Course"
                      select
                      value={formInputs.course_id}
                      onChange={handleChange}
                      name="course_id"
                      required
                      // SelectProps={{ multiple: true }}
                    >
                      {courses.map((course) => (
                        <MenuItem key={course._id} value={course._id}>
                          {course.title}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      fullWidth
                      label="Time Preferences"
                      select
                      value={formInputs.time_preferences}
                      onChange={handleChange}
                      name="time_preferences"
                      // required
                      // style={myStyle} // Apply the style here
                      SelectProps={{ multiple: false }}
                    >
                      {timePreferences.map((time_preferences) => (
                        <MenuItem
                          key={time_preferences._id}
                          value={time_preferences._id}
                          // style={myStyle} // Apply the style here
                        >
                          {capitalizeFirstLetter(time_preferences.type)} (
                          {formatTime(time_preferences.start_time)} to{" "}
                          {formatTime(time_preferences.end_time)} )
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
                  {/* <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  required
                  onChange={handleChange}
                  name="phone_number"
                  inputProps={{
                    pattern: "[0-9]{11}",
                    title:
                      "Please enter a valid phone number in the format XXXXXXXXXXX",
                  }}
                /> */}

                  {/* <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  onChange={handleChange}
                  name="phone_number"
                  required
                  inputProps={{
                    pattern: "[0-9]{11}",
                    title:
                      "Please enter a valid phone number in the format XXXXXXXXXXX",
                  }}
                /> */}

                  {/* <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField> */}
                  {/* </Stack> */}
                  {/* <TextField
                fullWidth
                label="Education"
                required
                value={formInputs.education}
                onChange={handleChange}
                name="education"
              /> */}
                  {/* <TextField
                fullWidth
                label="Address"
                required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
              /> */}

                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
                  {/* <TextField
                      fullWidth
                      label="Reg type"
                      select
                      value={formInputs.reg_type}
                      onChange={handleChange}
                      name="reg_type"
                      // required
                    >
                      <MenuItem value="regular">Regular</MenuItem>
                      <MenuItem value="scholarship">Scholarship</MenuItem>
                    </TextField> */}
                  {/* <TextField
                      fullWidth
                      // type="number"
                      label="Wallet Amount"
                      value={formInputs.wallet_amount}
                      onChange={handleChange}
                      name="wallet_amount"
                      // required
                      // inputProps={{
                      //   // pattern: "[0-9]{11}",
                      //   pattern: "[0-9]",
                      //   title:
                      //     "Please enter a valid Course fee",
                      // }}
                    /> */}
                  {/* </Stack> */}
                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
                  {/* <TextField
                      fullWidth
                      // type="number"
                      label="Registration fee"
                      value={formInputs.course_fee}
                      required
                      onChange={handleChange}
                      name="course_fee"
                      // inputProps={{
                      //   // pattern: "[0-9]{11}",
                      //   pattern: "[0-9]",
                      //   title:
                      //     "Please enter a valid Course fee",
                      // }}
                    /> */}

                  {/* <TextField
                      fullWidth
                      // type="number"
                      label="Decided Registration fee"
                      value={formInputs.registration_fee}
                      // required
                      onChange={handleChange}
                      name="registration_fee"
                      // inputProps={{
                      //   // pattern: "[0-9]{11}",
                      //   pattern: "[0-9]",
                      //   title:
                      //     "Please enter a valid Course fee",
                      // }}
                    /> */}
                  {/* </Stack> */}

                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
                  {/* <TextField
                      fullWidth
                      // type="number"
                      label="Course Actual fee (Over all)"
                      value={formInputs.actual_fee}
                      required
                      onChange={handleChange}
                      name="actual_fee"
                      // inputProps={{
                      //   // pattern: "[0-9]{11}",
                      //   pattern: "[0-9]",
                      //   title:
                      //     "Please enter a valid Course fee",
                      // }}
                    /> */}

                  {/* <TextField
                  fullWidth
                  // type="number"
                  label="Course Decided fee (Monthly)"
                  value={formInputs.decided_fee}
                  // required
                  onChange={handleChange}
                  name="decided_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                /> */}
                  {/* </Stack> */}

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Latest Degree"
                      value={formInputs.latest_degree}
                      onChange={handleChange}
                      name="latest_degree"
                    />

                    <TextField
                      fullWidth
                      label="Degree Status"
                      select
                      value={formInputs.degree_status}
                      onChange={handleChange}
                      name="degree_status"
                      required
                    >
                      <MenuItem value="complete">Complete</MenuItem>
                      <MenuItem value="inprogress">Inprogress</MenuItem>
                    </TextField>
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {formInputs.degree_status === "complete" ? (
                      <TextField
                        fullWidth
                        label="Completion Year"
                        select
                        value={selectedYear}
                        onChange={handleYearChange}
                        name="degree_status"
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      // <TextField
                      //   fullWidth
                      //   label="Completion Year"
                      //   value={formInputs.completion_year}
                      //   onChange={handleChange}
                      //   name="completion_year"
                      // />
                      ""
                    )}

                    <TextField
                      fullWidth
                      label="Institution Name"
                      value={formInputs.institution_name}
                      onChange={handleChange}
                      name="institution_name"
                    />

                    {formInputs.degree_status === "inprogress" ? (
                      <TextField
                        fullWidth
                        label="Semester"
                        value={formInputs.semester}
                        onChange={handleChange}
                        name="semester"
                      />
                    ) : (
                      ""
                    )}
                  </Stack>

                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
                  {/* <TextField
                  fullWidth
                  label="Discount"
                  value={formInputs.discount}
                  onChange={handleChange}
                  name="discount"
                /> */}

                  {/* <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                > 
                  <MenuItem value="is_free">Is Free</MenuItem>
                  <MenuItem value="installments">Installments</MenuItem>
                  <MenuItem value="all_paid">All Paid</MenuItem>
                </TextField> */}
                  {/* </Stack> */}

                  <div className="col-md-12 col-xs-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of Birth *"
                        // format="YYYY-MM-DD"
                        format="MM/DD/YYYY"
                        value={formInputs?.date_of_birth}
                        onChange={handleDateChange}
                        maxDate={dayjs()} // Set the maximum date to today's date
                        // renderInput={(params) => <TextField {...params} />}
                        sx={{
                          width: "100%", // Set the width to 100% to take up the full available width
                        }}
                      />
                    </LocalizationProvider>
                  </div>

                  <TextField
                    fullWidth
                    label="Referred By"
                    // required
                    value={formInputs.referred_by}
                    onChange={handleChange}
                    name="referred_by"
                  />

                  <TextField
                    fullWidth
                    label="Address"
                    // required
                    value={formInputs.address}
                    onChange={handleChange}
                    name="address"
                  />

                  {show === false
                    ? // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      //   <label htmlFor="image" className="form-label">
                      //     Image:
                      //   </label>
                      //   <Input
                      //     type="file"
                      //     id="image"
                      //     name="image"
                      //     accept="image/*"
                      //     onChange={handleFile}
                      //     required
                      //   />
                      //   {/* <Avatar alt={"image"} src={previewUrl} /> */}
                      // </Stack>
                      ""
                    : null}

                  {show === true
                    ? // <Stack
                      //   position="relative"
                      //   alignItems="center"
                      //   justifyContent="center"
                      // >
                      //   {" "}
                      //   <label htmlFor="image" className="form-label">
                      //     Image:
                      //   </label>
                      //   <Box
                      //     position="relative"
                      //     display="inline-block"
                      //     border="2px solid #ccc"
                      //     p={1}
                      //   >
                      //     <img
                      //       alt="image"
                      //       src={previewUrl}
                      //       width="150"
                      //       style={{ maxWidth: "100%" }}
                      //     />
                      //     <IconButton
                      //       aria-label="delete"
                      //       onClick={() => handleDeleteImage()}
                      //       style={{ position: "absolute", top: 0, right: 0 }}
                      //     >
                      //       <ClearIcon />
                      //     </IconButton>
                      //   </Box>
                      // </Stack>
                      ""
                    : null}

                  <TextField
                    fullWidth
                    label="Note"
                    multiline // Enable multiline
                    rows={3} // Set the number of rows to 3
                    value={formInputs.note}
                    onChange={handleChange}
                    name="note"
                    // inputProps={{
                    //   pattern: "[A-Za-z]{3,}",
                    //   title:
                    //     "Please enter at least 3 letters with only alphabetical characters.",
                    // }}
                  />

                  <Stack alignItems="end">
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      sx={{ width: "120px" }}
                    >
                      Add
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
