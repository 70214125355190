import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import {
  _move_batch_students,
  _move_student_from_batch,
  _update_batch_status,
  addBatch,
  detailBatch,
  listBatch,
} from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { capitalizeFirstLetter } from "src/utils/formatText";

const BatchUpdateStatus = ({
  row,
  Batches,
  onBatchesUpdate,
  handleCloseModal,
}) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchTitle, setBatchTitle] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "true",
    batch: "",
    courses: [],
    batch_status: 1,
  });

  const { id } = useParams();

  let _ID = id;

  console.log("_ID _ID _ID", _ID);

  const urlParams = new URLSearchParams(window.location.search);

  let batch_id = "batch_id";
  let student_id = _ID;

  if (urlParams.get("batch_id") != null) {
    batch_id = urlParams.get("batch_id");
  } else {
    // navigate("/batches/");
  }
  console.log("batch_id", batch_id);
  console.log("student_id", student_id);

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      //   courses: [value],
    }));

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    setIsLoading(true);
    const result = await _update_batch_status(row.id, {
      batch_status: formInputs.batch_status,
    });

    if (result.code === 200) {
      setIsLoading(false);
      navigate("/batches");
      //   navigate(-1);
      handleCloseModal();

      const updatedBatches = Batches.map((batch) => {
        if (batch.id === row.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return { ...batch, batch_status: formInputs.batch_status };
        } else {
          // For other objects, return them as they are
          return batch;
        }
      });

      onBatchesUpdate(updatedBatches);

      // const updatedBatches = Batches.filter((batch) => batch.id === row.id);
      // onBatchesUpdate(updatedBatches);

      // enqueueSnackbar(`${type} Successfully!`, { variant: "success" });

      enqueueSnackbar("Update Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //   const fetchActiveCourses = async () => {
  //     const response = await listActiveCourse();

  //     if (response.code === 200) {
  //       const courses = response.courses.map((course) => ({
  //         id: course._id,
  //         title: course.title,
  //         description: course.description,
  //         duration: course.duration,
  //         image: course.image,
  //         status: course.status,
  //       }));
  //       setCourses(courses);
  //     } else {
  //       enqueueSnackbar(response.message, { variant: "error" });
  //     }
  //   };

  //   useEffect(() => {
  //     fetchActiveCourses();
  //   }, []);

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await listBatch();
    console.log("response __listBatch", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if batch._id is equal to the _ID you want to skip
          if (batch._id === row.id) {
            setBatchTitle(batch.name);
            return null; // Skip this iteration and move to the next element
          }

          setFormInputs((prevInputs) => ({
            ...prevInputs,
            batch_status: batch.batch_status,
          }));

          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              courses: batch.courses ? batch.courses : "",
              batch_status: batch.batch_status ? batch.batch_status : 1,
              // other properties...
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await detailBatch(row.id);
    console.log("response detailBatch __detailBatch", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      setFormInputs({
        name: capitalizeFirstLetter(response.batch.name),
        batch_status: response.batch.batch_status
          ? response.batch.batch_status
          : 1,

        // total_seats: response.batch.total_seats,
        // status: response.batch.status,
        // order: response.batch.order,
        // instructors: instructorIds,
        // registration_fee: response.batch.registration_fee,
        // batch_discount: response.batch.batch_discount,
        // batch_discount: response.batch.batch_discount,

        // start_time_class: startTime,
        // end_time_class: endTime,

        // start_date_batch: defaultStartDate.toISOString().split("T")[0]
        //   ? defaultStartDate.toISOString().split("T")[0]
        //   : null,
        // start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
        //   ? defaultStartDate.toISOString().split("T")[0]
        //   : null,

        // end_date_batch: defaultEndDate.toISOString().split("T")[0]
        //   ? defaultEndDate.toISOString().split("T")[0]
        //   : null,
        // end_date_batch_select: defaultEndDate.toISOString().split("T")[0]
        //   ? defaultEndDate.toISOString().split("T")[0]
        //   : null,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // fetchBatches();
    fetchDetailBatch();
  }, []);

  //   useEffect(() => {
  //     if (courses.length > 0) {
  //       setFormInputs((prevInputs) => ({
  //         ...prevInputs,
  //         courses: [courses[0].id],
  //       }));
  //     }
  //   }, [courses]);

  return (
    <Page title="Move Batch Students">
      <div className="container mb-2">
        <div className="row">
          <div className="col-6 overflow-hidden">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChangeValue}
                    aria-label="lab API tabs example"
                  >
     
                    <Tab
                      label="Pending"
                      value="1"
                      // sx={{ color: (theme) => theme.palette.warning.main }}
                    />
                    <Tab
                      label="Confirm"
                      value="2"
                      // sx={{ color: (theme) => theme.palette.secondary.main }}
                    />
                      <Tab
                      label="Started"
                      value="3"
                      // sx={{ color: (theme) => theme.palette.secondary.main }}
                    />
                      <Tab
                      label="Complete"
                      value="4"
                      sx={{ color: (theme) => theme.palette.success.main }}
                    />
                        <Tab
                      label="Freeze"
                      value="5"
                      sx={{ color: (theme) => theme.palette.info.main }}
                    />
 
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Typography
                    variant="body1"
                    component="p"
                    // sx={{ color: "warning.main" }}
                  >
                    In Pending Status you can update all batch fields.
                  </Typography>
                </TabPanel>
                <TabPanel value="2">
                  <Typography
                    variant="body1"
                    component="p"
                    // sx={{ color: "secondary.main" }}
                  >
                    In Confirm Status you can only update 
                    Name, Status, Course, Total Seats, Start Date Batch, End Date Batch,
                    Start Time Class, End Time Class, Instructors Only.
                  </Typography>
                </TabPanel>
                <TabPanel value="3">
                  <Typography
                    variant="body1"
                    component="p"
                    // sx={{ color: "secondary.main" }}
                  >
                    In Started Status you can only update 
                    Name, Status, Course, Total Seats, Start Date Batch, End Date Batch,
                    Start Time Class, End Time Class, Instructors Only.
                  </Typography>
                </TabPanel>
                <TabPanel value="4">
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ color: "success.main" }}
                  >
                    Please note that if you modify the batch status, the fee
                    cannot be modified
                  </Typography>
                </TabPanel>
                <TabPanel value="5">
                  <Typography
                    variant="body1"
                    component="p"
                    sx={{ color: "info.main" }}
                  >
                    Please note that if you modify the batch status, the fee
                    cannot be modified
                  </Typography>
                </TabPanel>
              </TabContext>
            </Box>
          </div>
          <div className="col-6">
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={7}
              xl={7}
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}

              <Typography variant="h4" align="center" sx={{ flex: 1 }}>
                Update Batch Status
              </Typography>
            </Grid>

            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3} sx={{ padding: "5px" }}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      disabled
                      fullWidth
                      label="Batch Name"
                      value={formInputs.name}
                      onChange={handleChange}
                      name="name"

                      // inputProps={{
                      //   pattern: "[A-Za-z]{3,}",
                      //   title:
                      //     "Please enter at least 3 letters with only alphabetical characters.",
                      // }}
                    />
                  </Stack>

                  <TextField
                    fullWidth
                    label="Batch Status"
                    select
                    value={formInputs.batch_status}
                    onChange={handleChange}
                    name="batch_status"
                    required
                  >
                    <MenuItem value={1} disabled>
                      Pending Confirmation
                    </MenuItem>
                    <MenuItem value={2}>Confirm </MenuItem>
                    <MenuItem value={3}>Started</MenuItem>
                    <MenuItem value={4}>Completed</MenuItem>
                    <MenuItem value={5}>Freeze</MenuItem>
                  </TextField>
                  {/* <Stack alignItems="left">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "warning.main" }}
                >
                  Please note that if you modify the batch status, the fee
                  cannot be modified.
                </Typography>
              </Stack> */}

                  <Stack alignItems="end">
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      sx={{ width: "120px" }}
                    >
                      submit
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </Grid>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default BatchUpdateStatus;
