import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  Typography,
  Grid,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page, CircularLoader } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";
import TinyEditor from "src/components/Ckeditor";
import { detailTestimonial, editTestimonial } from "src/DAL/testimonial";
import { UpdatePageDetail, detailPage, listActivePage } from "src/DAL/page";
import { detailTemplate } from "src/DAL/template";
import { addImage, addImageWithSize } from "src/DAL/uploadfiles";
import { handleImageExtensions } from "src/constant";
import CustomImageUploader1 from "src/components/GeneralComponents/CustomImageUploader1";
import CustomImageUploaderInPageContent from "src/components/GeneralComponents/CustomImageUploaderInPageContent";

const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [file, setProfileImage] = useState();
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shortDescriptionCk, setShortDescriptionCk] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();

  let _ID = id;

  const urlParams = new URLSearchParams(window.location.search);
  let page_id = "page_id";

  if (urlParams.get("page_id") != null) {
    page_id = urlParams.get("page_id");
  }

  const location = useLocation();
  const rowData = location.state;
  const [inputs, setInputs] = useState({});
  const [templateFieldsData, setTemplateFieldsData] = useState([]);

  const [buttonLoader, setButtonLoader] = useState(() =>
    Array.from({ length: 25 }, () => false)
  );

  const [formInputs, setFormInputs] = useState({
    template_name: "",
    template_status: "",
    attributes: [],
  });

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const fileChangedHandler = async (e, i, index) => {
    // setIsLoading(true);
    console.log(e, i, "formData");

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("width", i.width);
    formData.append("height", i.height);
    let allowedImageExtension = i.image_extension;
    let uploadedImageExtension = e.target.files[0]?.name?.split(".")[1];
    let extension_matched = allowedImageExtension.includes(
      "." + uploadedImageExtension
    );
    console.log(...formData, "formData");
    // if (extension_matched) {

    const updatedButtonLoader = [...buttonLoader]; // Create a copy of the original array
    updatedButtonLoader[index] = true; // Set the value at the specified index to true
    setButtonLoader(updatedButtonLoader); // Update the state with the new array

    const imageUpload = await addImageWithSize(formData);

    // const data = new FormData();
    // data.append("file", e.target.files[0]);
    // const imageUpload = await addImage(data);
    console.log("imageUpload    ______imageUpload", imageUpload);
    if (imageUpload.code == 200) {
      setInputs({
        ...inputs,
        [e.target.name]: imageUpload.data.path,
      });
      setIsLoading(false);
      enqueueSnackbar(imageUpload.message, { variant: "success" });

      const updatedButtonLoader = [...buttonLoader]; // Create a copy of the original array
      updatedButtonLoader[index] = false; // Set the value at the specified index to true
      setButtonLoader(updatedButtonLoader); // Update the state with the new array
    } else {
      enqueueSnackbar(imageUpload.message, { variant: "error" });
      setIsLoading(false);

      const updatedButtonLoader = [...buttonLoader]; // Create a copy of the original array
      updatedButtonLoader[index] = false; // Set the value at the specified index to true
      setButtonLoader(updatedButtonLoader); // Update the state with the new array
    }
    // }
    // else {
    //   enqueueSnackbar(
    //     `Upload images with mentioned ${handleImageExtensions(
    //       allowedImageExtension
    //     )} extension`,
    //     {
    //       variant: "error",
    //     }
    //   );
    // }
  };
  const handleChange = (event, i, index) => {
    console.log(event, "eveet");
    const name = event.target.name;
    const value = event.target.value;
    setTemplateFieldsData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSet = (event, i) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeEditor = (event, i) => {
    console.log(event, "eventttttt");
    console.log(i, "valueeeee");
    const name = event;
    const value = i;
    setTemplateFieldsData((values) => ({ ...values, [name]: value }));
  };

  const handleChangeSetEditor = (event, i) => {
    const name = event;
    const value = i;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const fileChangedHandlerUpdate = async (e, i, index) => {
    // setIsLoading(true);

    const name = e.target.name;
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("width", i.width);
    formData.append("height", i.height);
    let allowedImageExtension = i.image_extension;
    let uploadedImageExtension = e.target.files[0]?.name?.split(".")[1];
    let extension_matched = allowedImageExtension.includes(
      "." + uploadedImageExtension
    );
    console.log(
      extension_matched,
      uploadedImageExtension,
      e.target.files[0],
      "extension_matched"
    );
    // if (extension_matched == true) {

    const updatedButtonLoader = [...buttonLoader]; // Create a copy of the original array
    updatedButtonLoader[index] = true; // Set the value at the specified index to true
    setButtonLoader(updatedButtonLoader); // Update the state with the new array

    const imageUpload = await addImageWithSize(formData);
    console.log("imageUpload 2    ______imageUpload", imageUpload);
    if (imageUpload.code == 200) {
      setTemplateFieldsData((prevState) => ({
        ...prevState,
        [name]: imageUpload.data.path,
      }));
      setIsLoading(false);

      const updatedButtonLoader = [...buttonLoader]; // Create a copy of the original array
      updatedButtonLoader[index] = false; // Set the value at the specified index to true
      setButtonLoader(updatedButtonLoader); // Update the state with the new array

      enqueueSnackbar(imageUpload.message, { variant: "success" });
    } else {
      enqueueSnackbar(imageUpload.message, { variant: "error" });
      setIsLoading(false);

      const updatedButtonLoader = [...buttonLoader]; // Create a copy of the original array
      updatedButtonLoader[index] = false; // Set the value at the specified index to true
      setButtonLoader(updatedButtonLoader); // Update the state with the new array
    }
    // } else {
    //   enqueueSnackbar(
    //     `Upload images with mentioned ${handleImageExtensions(
    //       allowedImageExtension
    //     )} extension`,
    //     {
    //       variant: "error",
    //     }
    //   );
    // }
  };
  const handleFormSubmit = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  // const handleChange = (e) => {
  //   const { target } = e;
  //   setFormInputs({ ...formInputs, [target.name]: target.value });
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputs, "adddddddddd");
    console.log(templateFieldsData, "edittttttt");
    console.log(formInputs, "fieldssss");
    const data = {
      page_detail:
        templateFieldsData !== undefined ? templateFieldsData : inputs,
    };

    console.log("inputs   ____PostData", inputs);
    console.log("templateFieldsData   ____PostData", templateFieldsData);
    console.log("formInputs   ____PostData", formInputs);
    console.log("data   ____PostData", data);

    // formInputs.attributes.map((field, index) => {

    // }

    // if (shortDescriptionCk.length < 1) {
    //   return enqueueSnackbar("Discription can not be empty !", {
    //     variant: "error",
    //   });
    // }
    setIsLoading(true);

    const result = await UpdatePageDetail(
      location.state.rowData.page_title_slug,
      data
    );
    if (result.code === 200) {
      setIsLoading(false);
      navigate(-1);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const imageDelete = async (img, e, i, type) => {
    console.log("img", img);
    const data = new FormData();
    data.append("path", img);
    // setIsLoading(true);

    // if(type===1){

    //   setInputs({
    //     ...inputs,
    //     [i.attribute_db_name]: "",
    //   });

    // }else if(type===2){
    //   setTemplateFieldsData((prevState) => ({
    //     ...prevState,
    //     [i.attribute_db_name]: "",
    //   }));
    // }

    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");

      console.log("e.target img", e.target);
      console.log("i img", i);

      if (type === 1) {
        setInputs({
          ...inputs,
          [i.attribute_db_name]: "",
        });
      } else if (type === 2) {
        setTemplateFieldsData((prevState) => ({
          ...prevState,
          [i.attribute_db_name]: "",
        }));
      }
    } else {
      // setIsLoadingButton(false);
      console.log(`Error: ${result.status}`);
    }
  };
  function handleDeleteImage(pathOfUploadedFile, event, field, type) {
    imageDelete(pathOfUploadedFile, event, field, type);
  }

  async function getDetailPage() {
    // setIsLoading(true);
    const response = await detailPage(page_id);
    console.log("response  _detail", response);
    if (response.code === 200) {
      if (response.page?.page_detail) {
        setTemplateFieldsData(response.page?.page_detail);
      } else {
        setTemplateFieldsData(location.state.rowData.page_detail);
      }

      // const templateIds = response.page.choose_template.map(
      //   (choose_template) => choose_template._id
      // );

      // setFormInputs({
      //   ...formInputs,
      //   ...response.page,
      //   page_title: response.page.page_title,
      //   meta_title: response.page.meta_title,
      //   meta_keywords: response.page.meta_keywords,
      //   meta_description: response.page.meta_description,
      //   choose_template: templateIds,
      //   page_status: response.page.page_status,

      //   faqs: response.page.is_faqs,

      //   is_slider: response.page.is_slider,
      //   is_website_course_benefits: response.page.is_website_course_benefits,

      //   is_tabs: response.page.is_tabs,
      //   is_course_rating: response.page.is_course_rating,

      // setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  const getTemplateDetail = async () => {
    setIsLoading(true);
    const response = await detailTemplate(id);
    console.log("response detailTemplate ___detailTemplate", response);
    if (response.code === 200) {
      setIsLoading(false);
      // console.log(data.instructor.first_name)
      console.log("location.state ___location.state", location.state);
      // setTemplateFieldsData(location.state.rowData.page_detail);
      // setTemplateFieldsData(response?.Template?.template_attributes_info);
      setFormInputs({
        template_name: response.templete.template_name,
        template_status: response.templete.template_status,
        attributes: response.templete.template_attributes_info,
      });

      setButtonLoader(() =>
        Array.from(
          { length: response.templete.template_attributes_info.length },
          () => false
        )
      );

      // setPreviews(
      //   s3baseUrl + response.Templete.template_attributes_info.attribute_db_name
      // );
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  console.log(inputs, "inputs");
  console.log("i am herere", location.state.rowData.page_detail);
  useEffect(() => {
    getDetailPage();
    getTemplateDetail();
  }, []);

  useEffect(() => {
    console.log("inputs   __templateFieldsData__inputs", inputs);
    console.log(
      "templateFieldsData   __templateFieldsData__inputs",
      templateFieldsData
    );
  }, [inputs, templateFieldsData]);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Update Page">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Update {location.state.rowData.title}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <div className="row">
                {formInputs.attributes.map((field, index) => {
                  if (field.attribute_type === "file") {
                    return (
                      <div key={index} className="mb-2">
                        <CustomImageUploaderInPageContent
                          pathOfUploadedFile={
                            templateFieldsData[field?.attribute_db_name]
                          }
                          setPathOfUploadedFile={()=>{}}
                          // imageFileHeading={"Image File"}
                          imageFileHeading={`${field.attribute_label} *`}
                          // imageFileSubHeading={"Image Size: 1MB or below :"}
                          imageFileLimitSizeUpload={5}
                          imageFileButtonText={"Upload"}
                          imageID={index}
                          field={field}
                          setInputs={setInputs}
                          setTemplateFieldsData={setTemplateFieldsData}
                        />
                      </div>
                      // <div className="col-md-12 col-lg-6  mt-3" key={index}>
                      //   <div className="row w-100 mb-3 mt-4">
                      //     <p>
                      //       {field.attribute_label}
                      //       {field.required == true ? " *" : ""}
                      //     </p>

                      //     <div className="col-md-6 mt-2 mb-2 d-flex">
                      //       {templateFieldsData !== undefined &&
                      //       templateFieldsData[field?.attribute_db_name] !==
                      //         "" ? (
                      //         <Stack
                      //           position="relative"
                      //           alignItems="flex-start"
                      //           justifyContent="flex-start"
                      //         >
                      //           <Box
                      //             position="relative"
                      //             display="inline-block"
                      //             border="2px solid #ccc"
                      //             p={1}
                      //           >
                      //             {/* <img
                      //               alt="image"
                      //               src={previewUrl}
                      //               width="150"
                      //               style={{ maxWidth: "100%" }}
                      //             /> */}

                      //             <img
                      //               src={
                      //                 templateFieldsData[
                      //                   field?.attribute_db_name
                      //                 ] == "" ||
                      //                 templateFieldsData[
                      //                   field?.attribute_db_name
                      //                 ] == null
                      //                   ? ""
                      //                   : s3baseUrl +
                      //                     templateFieldsData[
                      //                       field?.attribute_db_name
                      //                     ]
                      //               }
                      //               height="50"
                      //               style={{ maxWidth: "100%" }}
                      //             />
                      //             <IconButton
                      //               aria-label="delete"
                      //               onClick={(event) =>
                      //                 handleDeleteImage(
                      //                   templateFieldsData[
                      //                     field?.attribute_db_name
                      //                   ] == "" ||
                      //                     templateFieldsData[
                      //                       field?.attribute_db_name
                      //                     ] == null
                      //                     ? ""
                      //                     : templateFieldsData[
                      //                         field?.attribute_db_name
                      //                       ],
                      //                   event,
                      //                   field,
                      //                   2
                      //                 )
                      //               }
                      //               style={{
                      //                 position: "absolute",
                      //                 top: 0,
                      //                 right: 0,
                      //               }}
                      //             >
                      //               <ClearIcon />
                      //             </IconButton>
                      //           </Box>
                      //         </Stack>
                      //       ) : inputs[field.attribute_db_name] !== undefined &&
                      //         inputs[field.attribute_db_name] !== "" ? (
                      //         <Stack
                      //           position="relative"
                      //           alignItems="flex-start"
                      //           justifyContent="flex-start"
                      //         >
                      //           <Box
                      //             position="relative"
                      //             display="inline-block"
                      //             border="2px solid #ccc"
                      //             p={1}
                      //           >
                      //             {/* <img
                      //               alt="image"
                      //               src={previewUrl}
                      //               width="150"
                      //               style={{ maxWidth: "100%" }}
                      //             /> */}

                      //             <img
                      //               src={
                      //                 s3baseUrl +
                      //                 inputs[field.attribute_db_name]
                      //               }
                      //               height="50"
                      //               // width="150"
                      //               style={{ maxWidth: "100%" }}
                      //             />
                      //             <IconButton
                      //               aria-label="delete"
                      //               onClick={(event) =>
                      //                 handleDeleteImage(
                      //                   inputs[field.attribute_db_name],
                      //                   event,
                      //                   field,
                      //                   1
                      //                 )
                      //               }
                      //               style={{
                      //                 position: "absolute",
                      //                 top: 0,
                      //                 right: 0,
                      //               }}
                      //             >
                      //               <ClearIcon />
                      //             </IconButton>
                      //           </Box>
                      //         </Stack>
                      //       ) : (
                      //         ""
                      //       )}

                      //       <span className="upload-button mt-2 ms-4">
                      //         <input
                      //           color="primary"
                      //           accept="image/*"
                      //           type="file"
                      //           // required={field.required == true ? true : false}
                      //           name={field.attribute_db_name}
                      //           id={field.attribute_db_name}
                      //           style={{ display: "none" }}
                      //           onChange={
                      //             templateFieldsData == undefined
                      //               ? (event) =>
                      //                   fileChangedHandler(event, field, index)
                      //               : (event) =>
                      //                   fileChangedHandlerUpdate(
                      //                     event,
                      //                     field,
                      //                     index
                      //                   )
                      //           }
                      //           disabled={
                      //             templateFieldsData[
                      //               field?.attribute_db_name
                      //             ] === ""
                      //               ? false
                      //               : inputs[field.attribute_db_name] === ""
                      //               ? false
                      //               : true
                      //           }
                      //         />
                      //         <label htmlFor={field.attribute_db_name}>
                      //           {/* <CloudUploadIcon /> */}
                      //           <Button
                      //             className="small-contained-button"
                      //             // startIcon={<FileUploadIcon />}
                      //             startIcon={
                      //               buttonLoader[index] ? (
                      //                 <CircularProgress
                      //                   size={"1rem"}
                      //                   style={{ color: "default" }}
                      //                 />
                      //               ) : (
                      //                 <FileUploadIcon />
                      //               )
                      //             }
                      //             component="span"
                      //             variant="outlined"
                      //             size="small"
                      //             disabled={
                      //               templateFieldsData[
                      //                 field?.attribute_db_name
                      //               ] === ""
                      //                 ? false
                      //                 : inputs[field.attribute_db_name] === ""
                      //                 ? false
                      //                 : true
                      //             }
                      //           >
                      //             Upload
                      //           </Button>
                      //         </label>
                      //       </span>
                      //     </div>

                      //     <hr />
                      //     <FormHelperText className="pt-0">
                      //       (Recommended Size {field.width}*{field.height})
                      //       ("JPG", "JPEG", "PNG","WEBP", GIF)
                      //     </FormHelperText>
                      //   </div>
                      // </div>
                    );
                  } else if (field.attribute_type === "input") {
                    return (
                      // <Grid container spacing={2} key={index}>
                      //   <Grid item xs={12} sm={6}>
                      <div className="col-sm-12 col-md-6  mt-3">
                        <TextField
                          id="outlined-basic"
                          label={field?.attribute_label}
                          variant="outlined"
                          fullWidth
                          required={field.required == true ? true : false}
                          name={field?.attribute_db_name}
                          value={
                            templateFieldsData !== undefined
                              ? templateFieldsData[field?.attribute_db_name]
                              : inputs.field?.attribute_db_name
                          }
                          onChange={
                            templateFieldsData == undefined
                              ? (e) => handleChangeSet(e, field)
                              : (e) => handleChange(e, field)
                          }
                        />
                      </div>

                      //   </Grid>
                      // </Grid>
                    );
                  } else if (field.attribute_type === "editor") {
                    return (
                      <div className="col-12 mt-4">
                        <h6>
                          {field.attribute_label}
                          {field.required == true ? " *" : ""}
                        </h6>
                        <TinyEditor
                          setDetailDescription={
                            templateFieldsData == undefined
                              ? (value) =>
                                  handleChangeSetEditor(
                                    field?.attribute_db_name,
                                    value
                                  )
                              : (value) =>
                                  handleChangeEditor(
                                    field?.attribute_db_name,
                                    value
                                  )
                          }
                          detailDescriptionCk={
                            templateFieldsData !== undefined
                              ? templateFieldsData[field?.attribute_db_name]
                              : inputs.field?.attribute_db_name
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
