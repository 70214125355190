import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

// component
import { Page } from "src/components";

import { Iconify } from "src/components";

import { addTestimonial } from "src/DAL/testimonial";
import { listActivePage } from "src/DAL/page";
const Add = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [pages, setPages] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",
    title: "",

    description: "",
    created_for: "",
    pages: [],
    button_text: "",
    button_link: "",
    status: "true",
    image: "",
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(formInputs.gender);
    const data = new FormData();
    data.append("name", formInputs.name);
    data.append("title", formInputs.title);

    data.append("description", formInputs.description);
    data.append("created_for", formInputs.created_for);
    data.append(
      "page_id",
      JSON.stringify(
        Array.isArray(formInputs.pages) ? formInputs.pages : [formInputs.pages]
      )
    );
    data.append("button_text", formInputs.button_text);
    data.append("button_link", formInputs.button_link);
    data.append("image", image);
    data.append("status", formInputs.status);
    // const formDataArray = Array.from(data.entries());

    // console.log(formDataArray);
    // console.log(data);
    const result = await addTestimonial(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate(-1);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActivePages = async () => {
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActivePages();
  }, []);
  useEffect(() => {
    if (pages.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        pages: [pages[0].id],
      }));
    }
  }, [pages]);

  return (
    <Page title="Add Testimonial">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Add Testimonial
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                />

                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Created For"
                  value={formInputs.created_for}
                  onChange={handleChange}
                  name="created_for"
                  autoComplete="off"
                  required
                />
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>
              <TextField
                fullWidth
                label="Description"
                value={formInputs.description}
                onChange={handleChange}
                name="description"
                autoComplete="off"
                required
                multiline
                rows={2}
              />

              <TextField
                fullWidth
                label="Page"
                select
                value={formInputs.pages}
                onChange={handleChange}
                name="pages"
                required
                // SelectProps={{ multiple: true }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.title}
                  </MenuItem>
                ))}
              </TextField>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Button Text"
                  value={formInputs.button_text}
                  onChange={handleChange}
                  name="button_text"
                  autoComplete="off"
                  // required
                />
                <TextField
                  fullWidth
                  label="Button Link"
                  value={formInputs.button_link}
                  onChange={handleChange}
                  name="button_link"
                  autoComplete="off"
                  // required
                />
              </Stack>

              {show === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFile}
                    required
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    <img
                      alt="image"
                      src={previewUrl}
                      width="150"
                      style={{ maxWidth: "100%" }}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Add;
