// here we add all base urls and keys

// Base URLS
// export const baseUri = "https://192.168.1.159:1200/";
export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const studentPortalUrl = "https://portal.dynamitedigitalinstitute.com/";
// export const studentPortalUrl = "https://studentdev.dynamitedigitalinstitute.com/";
// export const WebsiteLink = "https://dev.dynamitedigitalinstitute.com/";
export const WebsiteLink = "https://dynamitedigitalinstitute.com/";

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;