import { invokeApi } from "src/utils";
// export const listActiveTemplate = async () => {
//   const requestObj = {
//     path: `api/templet_configration/active_list_templete`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };

export const listFaq = async (id) => {
  const requestObj = {
    path: `api/faq/list_faq/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const delFaq = async (id) => {
  const requestObj = {
    path: `api/faq/delete_faq/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const addFaq = async (data) => {
  // const formDataArray = Array.from(data.entries());

  // console.log(formDataArray);
  const requestObj = {
    path: `api/faq/add_faq`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailFaq = async (id) => {
  const requestObj = {
    path: `api/faq/detail_faq/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editFaq = async (id, data) => {
  const requestObj = {
    path: `api/faq/edit_faq/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
