import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { _student_move_to_trash, delStudent } from "src/DAL/student";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import AssignmentDetail from "../AssignmentDetail";
import { _delete_assignment_from_admin } from "src/DAL/assignment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import AssignmentGiveMarks from "../AssignmentGiveMarks";
// ----------------------------------------------------------------------

export default function AssignmentStudentsTableMoreMenu({
  row,
  students,
  onStudentsUpdate,
  assignment_position,
  batchID,
  batch_id,
  assignmentData,
}) {
  const [openDelete, setOpenDelete] = useState(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => {
    setIsOpen(false);
    setOpenModal(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  const getStudentsForAssignmentPosition = () => {
    switch (assignment_position) {
      case 1:
        return students.unsubmitted_assignment_students;
      case 2:
        return students.submitted_assignment_students;
      case 3:
        return students.approved_assignment_students;
      default:
        return [];
    }
  };

  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    console.log("_______Test_______ row", row);
    const updatedStudents = getStudentsForAssignmentPosition().filter(
      (students) => students._id !== row._id
    );

    console.log("_______Test_______ updatedStudents", updatedStudents);

    if (assignment_position === 1) {
      onStudentsUpdate((prevStudents) => ({
        ...prevStudents,
        unsubmitted_assignment_students: updatedStudents,
        approved_assignment_students: [
          ...prevStudents.approved_assignment_students,
          row,
        ],
      }));
    } else if (assignment_position === 2) {
      onStudentsUpdate((prevStudents) => ({
        ...prevStudents,
        submitted_assignment_students: updatedStudents,
        approved_assignment_students: [
          ...prevStudents.approved_assignment_students,
          row,
        ],
      }));
    }

    // const result = await _delete_assignment_from_admin(row._id);
    // if (result.code === 200) {
    //   const updatedStudents = students.filter(
    //     (students) => students._id !== row._id
    //   );
    //   onStudentsUpdate(updatedStudents);
    //   enqueueSnackbar("Delete Successfully!", { variant: "success" });
    //   // setTrashStudentCount(trashStudentCount + 1);
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  }

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  //   console.log("row  __row",row);
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* <MenuItem
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          onClick={handleDeleteClick}
        >
          <ListItemIcon>
            <Iconify
              icon="material-symbols:order-approve-outline"
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Approved"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        <MenuItem
          key="Status Update"
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          onClick={handleOpenModal}
        >
          <ListItemIcon>
            <Iconify
              icon="material-symbols:order-approve-outline"
            //   vFlip={true}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Status Update"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        {/* <MenuItem
          component={RouterLink}
          to={`/attendance/detail/${row._id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="icon-park-outline:view-grid-detail" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
        {/* <MenuItem
          component={RouterLink}
          to={`/assignments/edit-assignment/${row._id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
              key="students"
              component={RouterLink}
              to={`/assignments/assignment-students/${row._id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify
                  icon="fluent:people-team-16-regular"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary="Students"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem> */}

        {/* <MenuItem
          key="Detail"
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          onClick={handleOpenModal}
        >
          <ListItemIcon>
            <Iconify
              icon="icon-park-outline:view-grid-detail"
              vFlip={true}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem sx={{ color: "text.secondary" }} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Move To Trash"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/update-student-fee/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:phone-update-checkmark-20-regular" width={24} height={24} />
          </ListItemIcon>Deletetr
          <ListItemText
            primary="Update Fee"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Approved"
        message="Are you sure you want to approve this assignment for the student?"
        onOk={handleDeleteOk}
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        // pageTitle="Batch Student Detail"
        // pageTitle={`Batch Student ( ${selectedRowStudent.name} ) Detail `}
        pageTitle={`${capitalizeFirstLetter(row.name)} ( Give Marks ) `}
        componentToPassDown={
          <AssignmentGiveMarks
            studentID={row._id}
            selectedRowStudent={row}
            batchID={batchID}
            handleCloseModal={handleCloseModal}
            assignmentData={assignmentData}
            students={students}
            setStudents={onStudentsUpdate}
          />
        }
      />
    </>
  );
}
