import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { _get_student_fee, addBatch, listBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";
import { _add_fee_voucher, _create_custom_voucher } from "src/DAL/voucher";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { _post_assignment_from_admin } from "src/DAL/assignment";
import {
  _get_student_batches,
  _student_batches_for_admin,
  listStudent,
} from "src/DAL/student";
import { _add_leave_request_for_admin } from "src/DAL/leave_requests";
const AddLeaveRequest = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);
  const [instructors, setInstructors] = useState([]);
  const [batches, setBatches] = useState([]);
  const [students, setStudents] = useState([]);
  const [batcheFees, setbatcheFees] = useState([]);
  const [batcheStudents, setBatcheStudents] = useState([]);
  const [registrationFee, setRegistrationFee] = useState("");
  const [isLoadingStudent, setIsLoadingStudent] = useState(false);
  const [feeDiscount, setFeeDiscount] = useState("");
  const [selected, setSelected] = useState([]);
  const [studentID, setStudentID] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: true,
    registration_fee: 0,
    batch_discount: 0,
    batch_id: [],
    student_id: "",
    additional_discount: 0,
    additional_discount_reason: "",
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array

    batch_student_id: [],
    batch_fee_id: "",
    voucher_type: 2,
    registration_fee: "",
    isStudentFree: false,
    registration_fee_discount: "",
    voucher_to: 2,
    fee_title: "",
    leave_reason: "",
    fee_amount: "",
    leave_type: 2,
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [feeDiscountChecked, setFeeDiscountChecked] = useState(false);

  const myStyle = {
    textTransform: "capitalize",
  };

  const handleCheckboxFeeChange = (event) => {
    setFeeDiscountChecked(event.target.checked);
  };

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleStudentChange = (e) => {
    const { target } = e;
    console.log("target __test", target);

    // Use Array.prototype.find to locate the desired object
    let foundObject = batcheStudents.find((obj) => obj._id === target.value);
    // let FreeStudent = false;

    if (foundObject) {
      if (foundObject.fee_status === 3) {
        setFormInputs({
          ...formInputs,
          [target.name]: target.value,
          voucher_type: 1,
        });
      } else {
        setFormInputs({
          ...formInputs,
          [target.name]: target.value,
          // isStudentFree:false,
        });
      }
    } else {
      setFormInputs({
        ...formInputs,
        [target.name]: target.value,
        // isStudentFree:false,
      });
    }
  };

  const handleSelectChange = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setStudentID("");
      setSelected([]);
      // updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setStudentID(newValue.id);
      setSelected(newValue);
      // updateFormInputs(newValue);
    }
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      batch_student_id: [],
      batch_fee_id: "",
    });

    // Use Array.prototype.find to locate the desired object
    let foundObject = batches.find((obj) => obj.id === target.value);

    if (foundObject) {
      console.log("foundObject ___foundObject", foundObject);

      let filteredData = foundObject.students
        .filter((student) => student.student_status === 1)
        .map((student) => ({
          ...student,
          id: student._id,
          name: student.name,
          fatherName: student.father_name,
          contactNumber: student.contact_number,
        }));

      console.log("filteredData  ___filteredData ___foundObject", filteredData);

      setbatcheFees(foundObject.batch_fee);
      setBatcheStudents(filteredData);
      // setBatcheStudents(foundObject.students);
      setRegistrationFee(foundObject.registration_fee);
    } else {
      setbatcheFees([]);
      setBatcheStudents([]);
      setRegistrationFee("");
    }

    // const { name, value } = e.target;
    // console.log("name", name);
    // console.log("value", value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   // [name]: value,
    //   courses: value,
    // }));

    // if(value){

    // }
    // fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    if (studentID === "") {
      error = "student is Required";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("startDate", startDate);
    if (!startDate) {
      error = formInputs.leave_type===1?"From Date is required.":"Leave Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

   // Validate the selected date format
   const formattedStartDate = startDate.format("YYYY-MM-DD");
   console.log("formattedStartDate", formattedStartDate);
   if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
     error = formInputs.leave_type===1?"Invalid From Date Format.":"Invalid Leave Date Format.";
     enqueueSnackbar(error, { variant: "error" });
     return;
   }

   let postData = {
    // student_id: formInputs.student_id,
    student_id: studentID,
    leave_type: formInputs.leave_type,
    leave_date_from: formattedStartDate,
    leave_date_to: formattedStartDate,
    leave_reason: formInputs.leave_reason,
    batches: formInputs.batch_id,
  };

if(formInputs.leave_type===1){
  console.log("endDate", endDate);
  if (!endDate) {
    error = "To Date is required.";
    enqueueSnackbar(error, { variant: "error" });
    return;
  }
    // Validate the selected date format
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    console.log("formattedEndDate", formattedEndDate);
    if (!moment(formattedEndDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid To Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    postData = {
      // student_id: formInputs.student_id,
      student_id: studentID,
      leave_type: formInputs.leave_type,
      leave_date_from: formattedStartDate,
      leave_date_to: formattedEndDate,
      leave_reason: formInputs.leave_reason,
      batches: formInputs.batch_id,
    };
}
    console.log("postData _test", postData);

    setIsLoading(true);
    const result = await _add_leave_request_for_admin(postData);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/leave-requests");
      //   navigate(-1);
      enqueueSnackbar("Leave Requests Successfully!", { variant: "success" });
      setStartDate("");
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await listStudent();
    console.log("response ___listStudent", response);
    if (response.code === 200) {
      const students = response.students
        .map((student, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (student.status === 1) {
            return {
              ...student,
              id: student._id,
              id: student._id,
              name: student.name,
              // last_name: student.last_name,
              email: student.email,

              referred_by: student.referred_by,
              course_fee: student.course_fee,
              actual_fee: student.actual_fee,
              father_name: capitalizeFirstLetter(student.father_name),
              address: student.address,
              reg_type: student.reg_type ? student.reg_type : "",
              contact_number: student.contact_number,
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchBatches = async (id) => {
    setIsLoadingStudent(true);
    const response = await _student_batches_for_admin(id);
    console.log("response _student_batches_for_admin", response);
    if (response.code === 200) {
      const batches = response.student_batches
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              ...batch,
              id: batch._id,
              name: batch.name,
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      //   console.log("batches  _test", batches);
      //   console.log("batches[0]  _test", batches[0]);
      //   console.log("batches[0].batch_fee  _test", batches[0].batch_fee);
      setBatches(batches);
      setIsLoadingStudent(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoadingStudent(false);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
    // if (formInputs.student_id !== "") {
    if (studentID !== "") {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        // [name]: value,
        batch_id: [], //reset id
      }));
      // fetchBatches(formInputs.student_id);
      fetchBatches(studentID);
    } else {
      setBatches([]);
    }
  }, [formInputs.student_id, studentID]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Leave Request">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Add Leave Request{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={1}> */}
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={students}
                getOptionLabel={(student) =>
                  student.name ? capitalizeFirstLetter(student.name) : ""
                }
                value={selected}
                onChange={handleSelectChange}
                // filterOptions={filterOptions} // Apply the custom filter function
                renderInput={(params) => (
                  <TextField {...params} label="Student" required />
                )}
                renderOption={(props, student) => (
                  // <Tooltip
                  //   title={
                  //     // `Name: ${student.name}\nFather's Name: ${student.father_name}\nMobile Number: ${student.mobile_number}\nReferred By: ${student.referred_by}`
                  //     <div>
                  //       Course ={" "}
                  //       {student.course.length > 0
                  //         ? student.course
                  //             .map((course, index) => {
                  //               return course !== null ? course.title : "";
                  //             })
                  //             .join(", ")
                  //         : ""}
                  //       <br />
                  //       Father Name = {student.father_name} <br />
                  //       Mobile Number = {student.mobile_number} <br />
                  //       Referred By = {student.referred_by} <br />
                  //       Gender = {student.gender} <br />
                  //       Note = {student.note} <br />
                  //       {/* Email = {email} <br />
                  //                 Address = {address} <br />
                  //                 Registration Type = {reg_type} <br />
                  //                 Actual Registration Fee = {course_fee} <br />
                  //                 Actual Course Fee= {actual_fee} <br /> */}
                  //     </div>
                  //   }
                  // >
                  <li {...props}>{capitalizeFirstLetter(student.name)}</li>
                  // </Tooltip>
                )}
              />

              {/* <TextField
                  fullWidth
                  label="Student"
                  select
                  value={formInputs.student_id}
                  onChange={handleChange2}
                  name="student_id"
                  SelectProps={{ multiple: false }}
                  required
                  style={myStyle}
                >
                  {students.map((student) => (
                    <MenuItem
                      key={student.id}
                      value={student.id}
                      style={myStyle}
                    >
                      {student.name}
                    </MenuItem>
                  ))}
                </TextField> */}

              {/* </Stack> */}

              <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>

                <TextField
                  fullWidth
                  label="Leave Type"
                  select
                  required
                  value={formInputs.leave_type}
                  onChange={handleChange}
                  name="leave_type"
                >
                  <MenuItem value={2}>Full Day</MenuItem>
                  <MenuItem value={1}>Range</MenuItem>
                </TextField>

                <div style={{ position: "relative" }}>
                  {isLoadingStudent && (
                    <CircularProgress
                      style={{
                        position: "absolute",
                        top: "15%",
                        left: "150%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  )}
                </div>

                <TextField
                  fullWidth
                  label="Batch"
                  select
                  value={formInputs.batch_id}
                  onChange={handleChange}
                  name="batch_id"
                  // SelectProps={{ multiple: false }}
                  SelectProps={{ multiple: true }}
                  required
                  style={myStyle}
                  disabled={isLoadingStudent}
                >
                  {batches.map((batch) => (
                    <MenuItem key={batch.id} value={batch.id} style={myStyle}>
                      {capitalizeFirstLetter(batch.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    label={formInputs.leave_type===1?"From *":"Leave Date *"}
                    // format="YYYY-MM-DD"
                    format="MM-DD-YYYY"
                    // value={dayjs(formInputs2.duration2)}
                    // minDate={dayjs()} // Set the minimum date to today
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>

                {formInputs.leave_type===1?( 
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    label="To *"
                    // format="YYYY-MM-DD"
                    format="MM-DD-YYYY"
                    // value={dayjs(formInputs2.duration2)}
                    // minDate={dayjs()} // Set the minimum date to today
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
                ):("")}

              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label="Leave Reason"
                  value={formInputs.leave_reason}
                  onChange={handleChange}
                  name="leave_reason"
                  required
                ></TextField>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AddLeaveRequest;
