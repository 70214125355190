import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { delPage } from "src/DAL/page";
// ----------------------------------------------------------------------

export default function PageMoreMenu({ row, pages, onPagesUpdate }) {
  console.log(row, "kkkkkkkk");
  const [openDelete, setOpenDelete] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    const result = await delPage(row.id);
    if (result.code === 200) {
      const updatedPages = pages.filter((page) => page.id !== row.id);
      onPagesUpdate(updatedPages);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 230, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to={`/pages/edit-page/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        {/* {row.is_testimonials === true ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            component={RouterLink}
            to={`/testimonials/${row.id}`}
          >
            <ListItemIcon>
              <Iconify
                icon="grommet-icons:document-update"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Testimonial"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null} */}
        {row.is_faqs === true ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            component={RouterLink}
            to={`/faqs/${row.id}`}
          >
            <ListItemIcon>
              <Iconify
                icon="mdi:frequently-asked-questions"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="FAQS"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null}

        {row.is_slider === true ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            component={RouterLink}
            to={`/sliders/${row.id}`}
          >
            <ListItemIcon>
              <Iconify
                icon="solar:music-note-slider-2-outline"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Sliders"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null}

        {row.is_tabs === true ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            component={RouterLink}
            to={`/tabs/${row.id}`}
          >
            <ListItemIcon>
              <Iconify
                icon="material-symbols:tabs-sharp"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Tabs"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null}

        {row.is_course_rating === true ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            component={RouterLink}
            to={`/course-rating/${row.id}`}
          >
            <ListItemIcon>
              <Iconify
                icon="material-symbols-light:rate-review-outline"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Course Rating"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null}

        {row.is_website_course_benefits === true ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            component={RouterLink}
            to={`/website-course-benefits/${row.id}`}
          >
            <ListItemIcon>
              <Iconify icon="carbon:course" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Course Benefits"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null}

        {row.import_status ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            component={RouterLink}
            to={`/updatepage/${row.template}?page_id=${row.id}`}
            state={{ rowData: row }}
          >
            <ListItemIcon>
              <Iconify
                icon="grommet-icons:document-update"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Update Page Content"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null}

        {row.type !== 1 ? (
          <MenuItem
            sx={{ color: "text.secondary" }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : null}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
    </>
  );
}
