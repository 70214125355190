import { invokeApi } from "src/utils";
export const _list_course_rating = async (id) => {
  const requestObj = {
    path: `api/course_rating/list_course_rating/` + id +`?page=0&limit=9999`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _delete_course_rating = async (id) => {
  const requestObj = {
    path: `api/course_rating/delete_course_rating/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_course_rating = async (data) => {
 
  const requestObj = {
    path: `api/course_rating/add_course_rating`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_course_rating = async (id) => {
  const requestObj = {
    path: `api/course_rating/detail_course_rating/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_course_rating = async (id, data) => {
  const requestObj = {
    path: `api/course_rating/edit_course_rating/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
