import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  LinearProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { detailInstructor, editInstructor } from "src/DAL/instructor";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";
import { formatDateTimeWithOutZone } from "src/utils";
import { textEmptyToReturnHyphen } from "src/utils/formatText";
const InstuctorDetailComponent =  ({ instructorID }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: 1,
    identity_card_number: "",
    phone_number: "",
    status: "",
    education: "",
    image: "",
    role:{},
  });
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    deleteImage(image);
    setImage("");
    // console.log(image);
    setShow(false);
  }
  const deleteImage = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

 
   

  async function fetchDetailInstructor() {
    setIsLoading(true);
    const response = await detailInstructor(instructorID);
    console.log("response detailInstructor _detailInstructor", response);
    if (response.code === 200) {
      setFormInputs({
        ...response.instructor,
        first_name: response.instructor.first_name,
        last_name: response.instructor.last_name,
        email: response.instructor.email,
        gender: response.instructor.gender,
        identity_card_number: response.instructor.identity_card_number,
        phone_number: response.instructor.contact_number,
        status: response.instructor.status,
        education: response.instructor.education,
        image: response.instructor.image,
        createdAt: response.instructor.createdAt,
        updatedAt: response.instructor.updatedAt,
      });
      if (response.instructor.image) {
        setImage(response.instructor.image);
        setShow(true);
      }
      setIsLoading(false);
    } else {
        setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }
  useEffect(() => {
    fetchDetailInstructor();
  }, []);

//   if (isLoading) {
//     return <CircularLoader />;
//   }
  
  if (isLoading) {
    return (
      <div className="mt-5">
       <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
      </div>
    );
  }

  return (
    <Page title="Detail Instructor">
       
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "70px" }}
      >
        {/* <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Detail Instructor
          </Typography>
        </Grid> */}

        <div className="container col-11">
              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    {/* Image: */}
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrl ? (
                      <img
                        alt="image"
                        src={previewUrl}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.image}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    {/* <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton> */}
                  </Box>
                </Stack>
              ) : null}

        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: "100%",
              paddingBottom: "30px",
              marginTop: "20px",
              marginBottom: "20px",
              border: "1px solid #f1f3f4", // Set the border style here
            }}
            aria-label="simple table"
          >
           
            <TableBody>
              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  First Name
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {formInputs.first_name}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Last Name
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {formInputs.last_name}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Eamil
                </TableCell>
                <TableCell align="left"  >
                  {formInputs.email}
                </TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Role
                </TableCell>
                {/* <TableCell align="left"  >
                  {formInputs.email}
                </TableCell> */}
                <TableCell align="left">{formInputs.role?textEmptyToReturnHyphen(formInputs.role.title):""}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Gender
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {formInputs.gender===1?"Male":"Female"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  CNIC
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {textEmptyToReturnHyphen(formInputs.identity_card_number)}
                </TableCell> 
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Phone Number
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  +92{formInputs.phone_number}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Status
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {formInputs.status ? "Active" : "Inactive"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Education
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {textEmptyToReturnHyphen(formInputs.education)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Created At
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {formatDateTimeWithOutZone(formInputs.createdAt)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={myStyleHeader}>
                  Updated At
                </TableCell>
                <TableCell align="left" style={myStyle}>
                  {formatDateTimeWithOutZone(formInputs.updatedAt)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        </div>
      </Grid>
    </Page>
  );
};

export default InstuctorDetailComponent;
