import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  InputAdornment,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import {
  _detail_brand,
  _edit_brand,
  _list_active_brand,
  _list_brand,
} from "src/DAL/brand";
import {
  _detail_asset_category,
  _edit_asset_category,
  _list_active_asset_category,
  _list_asset_category,
} from "src/DAL/asset_category";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import {
  _detail_company_asset,
  _edit_company_asset,
} from "src/DAL/company_asset";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";

const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    active_status: true,
    assigned_status: true,
    brand_id: "",
    category_id: "",
    vendor_id: "",
    device_id: "",
    quantity: "",
    price: "",
    link: "",
    details: "",
    start_date_batch_select: "",
    end_date_batch_select: "",
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      title: formInputs.title,
      device_id: formInputs.device_id,
      details: formInputs.details,
      image: "image",
      price: formInputs.price,
      quantity: formInputs.quantity,
      details: formInputs.details,
      details: formInputs.details,
      // active_status: formInputs.active_status,
      // assigned_status: formInputs.assigned_status,

      purchase_date: formInputs.start_date_batch_select,
      warranty_expire_date: formInputs.end_date_batch_select,
      // purchase_date: formattedStartDate ,
      // warranty_expire_date: formattedEndDate,

      warranty_card_image: "warranty_card_image",
      receipt_image: "receipt_image",
      link: formInputs.link,
      vendor_id: formInputs.vendor_id,
      brand_id: formInputs.brand_id,
      asset_category_id: formInputs.category_id,
    };
    console.log("postData", postData);

    setIsLoading(true);

    // const result = await _add_company_asset(postData);
    const result = await _edit_company_asset(id, postData);
    console.log("result _edit_company_asset", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/company-asset");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_company_asset(id);
    console.log("_detail_company_asset _detail_company_asset", response);
    // enquiry_form

    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.company_asset.purchase_date) {
        defaultStartDate = new Date(response.company_asset.purchase_date);
        console.log("defaultStartDate", defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultStartDate", defaultStartDate);

      let defaultEndDate = new Date();
      console.log("defaultEndDate", defaultEndDate);
      if (response.company_asset.warranty_expire_date) {
        defaultEndDate = new Date(response.company_asset.warranty_expire_date);
        console.log("defaultEndDate", defaultEndDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultEndDate", defaultEndDate);

      setFormInputs({
        title: capitalizeFirstLetter(response.company_asset.title),
        // active_status: response.company_asset.active_status,
        assigned_status: response.company_asset.assigned_status,
        brand_id: response.company_asset.brand._id,
        category_id: response.company_asset.asset_category._id,
        vendor_id: response.company_asset.vendor._id,
        device_id: response.company_asset.device_id,
        quantity: response.company_asset.quantity,
        price: response.company_asset.price,
        link: response.company_asset.link,
        details: capitalizeFirstLetter(response.company_asset.details),

        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,

        end_date_batch: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,
        end_date_batch_select: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,
      });
      // setFormInputs(response.company_asset);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  const fetchActiveCategory = async () => {
    setIsLoading(true);
    const response = await _list_active_asset_category();

    // list_asset_category

    console.log("response _list_asset_category", response);
    if (response.code === 200) {
      const students = response.list_active_asset_category.map(
        (student, index) => ({
          id: student._id,
          title: student.title,
          description: student.description,
          active_status: student.active_status,
          assignable_status: student.assignable_status,
          number: index + 1,
        })
      );
      // setStudents(students.reverse());
      setCategory(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveBrand = async () => {
    setIsLoading(true);
    const response = await _list_active_brand();

    // list_brand

    console.log("response _list_active_brand", response);
    if (response.code === 200) {
      const students = response.list_brand.map((student, index) => ({
        id: student._id,
        title: student.title,
        description: student.description,
        active_status: student.active_status,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setBrands(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveVendor = async () => {
    setIsLoading(true);
    const response = await _list_active_vendor();

    // list_asset_category

    console.log("response _list_active_vendor", response);
    if (response.code === 200) {
      const students = response.list_vendor.map((student, index) => ({
        id: student._id,
        name: student.name,
        email: student.email,
        contact_number: student.contact_number,
        address: student.address,
        active_status: student.active_status,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setVendor(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchActiveCategory();
    fetchActiveBrand();
    fetchActiveVendor();
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Asset">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Asset </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Device Name/ID"
                  value={formInputs.device_id}
                  onChange={handleChange}
                  name="device_id"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Quantity"
                  value={formInputs.quantity}
                  onChange={handleChange}
                  name="quantity"
                  required
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Price"
                  value={formInputs.price}
                  onChange={handleChange}
                  name="price"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                {/* <div className="col-md-12 col-xs-12"> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Purchase Date *"
                    // format="YYYY-MM-DD"
                    // value={formInputs?.start_date_batch}
                    value={dayjs(formInputs.start_date_batch)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>

                {/* </div> */}

                {/* <div className="col-md-12 col-xs-12"> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // disabled
                    label="Warranty Expire Date *"
                    // format="YYYY-MM-DD"
                    // value={formInputs?.start_date_batch}
                    value={dayjs(formInputs.end_date_batch)} // Convert the default date to a Day.js object
                    onChange={handleDateChange2}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
                {/* </div> */}
              </Stack>

              <Divider sx={{ mb: 4 }}> o </Divider>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Category"
                  select
                  value={formInputs.category_id}
                  onChange={handleChange}
                  name="category_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {category.map((categoryObj) => (
                    <MenuItem key={categoryObj.id} value={categoryObj.id}>
                      {categoryObj.title}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Vendor"
                  select
                  value={formInputs.vendor_id}
                  onChange={handleChange}
                  name="vendor_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {vendor.map((vendorObj) => (
                    <MenuItem key={vendorObj.id} value={vendorObj.id}>
                      {capitalizeFirstLetter(vendorObj.name)}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Brand"
                  select
                  value={formInputs.brand_id}
                  onChange={handleChange}
                  name="brand_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {brands.map((brand) => (
                    <MenuItem key={brand.id} value={brand.id}>
                      {brand.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Link"
                  value={formInputs.link}
                  onChange={handleChange}
                  name="link"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
                {/* <TextField
                fullWidth
                label="Status"
                select
                // required
                value={formInputs.active_status}
                onChange={handleChange}
                name="active_status"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </TextField> */}
              </Stack>

              <Divider sx={{ mb: 4 }}> o </Divider>

              <TextField
                fullWidth
                label="Details"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.details}
                onChange={handleChange}
                name="details"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
