import { filter } from "lodash";

import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
  Box,
  TableHead,
  IconButton,
  Collapse,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
// import StudentListHead from "./StudentListHead";

// mock
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
//
import { useNavigate } from "react-router-dom";

import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomModal from "src/components/GeneralComponents/CustomModal";

import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useSnackbar } from "notistack";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import UserListHead from "./UserListHead";
import { formatDateTimeWithOutZone } from "src/utils";
import { formatDateWithDay } from "src/utils/formatTime";
import DataNotFountForDashboardjs from "src/components/GeneralComponents/DataNotFountForDashboard";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Student", alignRight: false },
  { id: "batch", label: "Batch", alignRight: false },
  { id: "contact_number", label: "Phone", alignRight: false },
  { id: "date", label: "Leave Date", alignRight: false },
  // { id: "absent", label: "Absent", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];
const TABLE_HEAD2 = [
  { id: "number", label: "#", alignRight: false },
  { id: "student_name", label: "Student Name", alignRight: false },
  { id: "contact_number", label: "Phone", alignRight: false },
  { id: "leave_date", label: "Leave Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },

  //   { id: "batch", label: "Batch", alignRight: false },
  //   { id: "total_marks", label: "Total Marks", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {

  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  // console.log("array    _______applySortFilter",array);
  // console.log("query    _______applySortFilter",query);

  if (query) {
    return filter(
      array,
      (_user) =>
        _user.student.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      //  || _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

export default function LeaveDetailTable({
  attendanceDataFromAPI,
  LeaveDataFromAPI,
  isLoading,
  setIsLoading,
  currentDate,
}) {
  const myStyle = {
    textTransform: "capitalize",
  };

  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };
  const myStyle5 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
    textAlign: "center",
  };
  const myStyle3 = {
    textTransform: "capitalize",
    textAlign: "center",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const moment = require("moment");
  const [order, setOrder] = useState("asc");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState(attendanceDataFromAPI);
  const [openTable, setOpenTable] = useState(
    Array(attendanceDataFromAPI.length).fill(false)
  );
  //   const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [trashStudentCount, setTrashStudentCount] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  //   const { row } = props;
  const [open, setOpen] = React.useState(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/attendance/add-attendance");
  };
  const handleUploadAttendance = () => {
    navigate("/attendance/upload-attendance");
  };
  const handleMoveToTrash = () => {
    navigate("/trash/");
  };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  function formatTime(Time) {
    // Parse the time strings using moment
    const startMoment = moment(Time, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime}`;

    return formattedTime;
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Tue, Sep 05, 2023"
    const formattedDate = parsedDate.format("ddd, MMM DD, YYYY");

    return formattedDate;
  }

  function setArrayValue(array, index, newValue) {
    console.log("___test array", array);
    console.log("___test index", index);
    console.log("___test newValue", newValue);
    if (index >= 0 && index < array.length) {
      const newArray = [...array]; // Create a copy to avoid mutating the original array
      //   newArray[index] = newValue;
      newArray[index] = !newArray[index];
      setOpenTable(newArray);
      //   return newArray;
    } else {
      console.error("Invalid index");
      setOpenTable(array);
      //   return array; // Return the original array if the index is invalid
    }
  }

  useEffect(() => {
    // fetchStudents();
    setStudents(attendanceDataFromAPI);
  }, [attendanceDataFromAPI, students]);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Dashboard">

        <>
        <Box sx={{ marginBottom: "25px" }}>
        <h5 className="p-3" style={{ textAlign: 'center' }}>Today Leave Requests</h5>
          {students.length > 0 ? (
        <Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {/* <Stack
                  direction="row"
                  // spacing={2}
                  alignItems="center"
                  justifyContent="flex-start"
                  sx={{ marginTop: "-15px",marginBottom:"-20px" }}
                > */}
                  {/* <StudentListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                  />  */}

                  {/* </Stack> */}

                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={students.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {
                        //   filteredStudents
                        //     .slice(
                        //       page * rowsPerPage,
                        //       page * rowsPerPage + rowsPerPage
                        //     )
                        students.map((row, index) => {
                          const {
                            student,
                            status,
                            leave_date,
                            batches,
                            leave_reason,
                            rejection_reason,
                          } = row;
                          const isItemSelected =
                            selected.indexOf(student.name) !== -1;
                          // let number = index + 1;

                          return (
                            <>
                              <TableRow
                                // hover
                                key={row._id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                                sx={{ "& > *": { borderBottom: "unset" } }}
                              >
                                <TableCell padding="checkbox"></TableCell>
                                <TableCell align="left" style={myStyle}>
                                  {index + 1}
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  {capitalizeFirstLetter(row.student.name)}
                                </TableCell>

                                {batches.length > 0 ? (
                                  <TableCell align="left" style={myStyle}>
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() =>
                                        setArrayValue(
                                          openTable,
                                          index,
                                          openTable[index]
                                        )
                                      }
                                    >
                                      {openTable[index] ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )}
                                    </IconButton>
                                  </TableCell>
                                ) : (
                                  <TableCell align="left" style={myStyle}>
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                    ></IconButton>
                                  </TableCell>
                                )}

                                <TableCell align="left">
                                  +92{row.student.contact_number}
                                </TableCell>

                                <TableCell align="left">
                                  {formatDateWithDay(row.leave_date)}
                                </TableCell> 

                                <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (row.status === 1 && "warning") ||
                                    (row.status === 2 && "success") ||
                                    (row.status === 3 && "error")
                                  }
                                >
                                  {row.status === 1 && "Pending"}
                                  {row.status === 2 && "Approved"}
                                  {row.status === 3 && "Rejected"}
                                </Label>
                              </TableCell>

                                <TableCell align="right">
                                  {/* <StudentMoreMenu
                                  row={row}
                                  students={students}
                                  onStudentsUpdate={handleStudentsUpdate}
                                  trashStudentCount={trashStudentCount}
                                  setTrashStudentCount={setTrashStudentCount}
                                  currentDate={currentDate}
                                /> */}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={6}
                                >
                                  <Collapse
                                    in={openTable[index]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        // alignItems: "center",
                                        // justifyContent: "center",
                                        flexDirection: "column",
                                        // height: '100vh', // Adjust the height as needed
                                        margin: 1,
                                        paddingLeft: 5,
                                        paddingRight: 40,
                                      }}
                                    >
                                      <Table
                                        size="small"
                                        aria-label="purchases"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell style={myStyle2}>
                                              #
                                            </TableCell>
                                            <TableCell style={myStyle5}>
                                              Name
                                            </TableCell>
                                            {/* <TableCell align="right">Amount</TableCell>
                                          <TableCell align="right">
                                            Total price ($)
                                          </TableCell> */}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {batches.map((_batch, _index) => (
                                            <TableRow key={_batch._id}>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                                style={myStyle}
                                              >
                                                     {_index + 1}
                                              </TableCell>
                                              <TableCell style={myStyle3}>
                                                {capitalizeFirstLetter(
                                                  _batch.name
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      }
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={8} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              {/* <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: students.length, label: "All" },
                ]}
                component="div"
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </Box>
          ) : (
            <Box
            sx={{
              minHeight: "200px",
              maxHeight: "200px",
              paddingTop: "70px",
            }}
          >
            <DataNotFountForDashboardjs
              title="No Leave Request Found!"
              //  subTitle="Sorry, no results were found. Please try again."
              subTitle=""
            />
          </Box>
          )}

</Box>
        </>


      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <h1>hello</h1>
          //   <FilterStudent
          //     onCloseDrawer={handleCloseEditDrawer}
          //     isOpenDrawer={drawerState}
          //     students={students}
          //     setStudents={setStudents}

          //     // filterState={filterState}
          //     // setFilterState={setFilterState}
          //     // taskListData={taskListData}
          //     // setTaskListData={setTaskListData}
          //     // messageText={messageText}
          //     // setMessageText={setMessageText}
          //     // setDrawerState={setDrawerState}
          //   />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Attendance Detail"
        componentToPassDown={
          <>
            <h1>hello</h1>
            <h1>hello</h1>
            <h1>hello</h1>
            <h1>hello</h1>
          </>
          //   <AttendanceSingleModalDetail
          //     selectedRowStudent={selectedRowStudent}
          //   />
        }
      />
    </Page>
  );
}
