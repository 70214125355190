import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  InputAdornment,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";
import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_brand, _list_active_brand, _list_brand } from "src/DAL/brand";
import {
  _add_asset_category,
  _list_active_asset_category,
  _list_asset_category,
} from "src/DAL/asset_category";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import moment from "moment";
import { _add_company_asset, _add_repair_company_asset } from "src/DAL/company_asset";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";

const AddCompanyAssetRepair = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1); 
  };
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    active_status: true,
    assigned_status: true,
    brand_id: "",
    category_id: "",
    vendor_id: "",
    device_id: "",
    quantity: 1,
    price: "",
    link: "",
    details: "",
    service_type: "battery",
    service_description: "",
    service_cost: "",
    service_duration: "",
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");

    // if (name === 'course' && value.includes('other')) {
    //   setFormInputs({
    //     ...formInputs,
    //     [name]: value.filter((option) => option !== 'other'),
    //     other: ''
    //   });
    // } else {
    //   setFormInputs({
    //     ...formInputs,
    //     [name]: value
    //   });
    // }

    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };
  const handleChange2 = (event, newValue) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      course: newValue ? newValue._id : "",
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Repair Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Repair Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    let postData = {
      company_asset_id: id,
      vendor_id: formInputs.vendor_id,
      repair_date: formattedStartDate,
      service_type: formInputs.service_type,
      service_description: formInputs.service_description,
      service_cost: formInputs.service_cost,
      service_duration: formInputs.service_duration,
      service_receipt_pic: pathOfUploadedFile,
    };
    console.log("postData  ___postData", postData);

    setIsLoading(true);
    const result = await _add_repair_company_asset(postData);
    console.log("result _add_repair_company_asset", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      navigate("/company-asset/repair-details-asset/"+id);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActiveCategory = async () => {
    setIsLoading(true);
    const response = await _list_active_asset_category();

    // list_active_asset_category

    console.log("response _list_active_asset_category", response);
    if (response.code === 200) {
      const students = response.list_active_asset_category.map(
        (student, index) => ({
          id: student._id,
          title: student.title,
          description: student.description,
          active_status: student.active_status,
          assignable_status: student.assignable_status,
          number: index + 1,
        })
      );
      // setStudents(students.reverse());
      setCategory(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveBrand = async () => {
    setIsLoading(true);
    const response = await _list_active_brand();

    // list_brand

    console.log("response _list_active_brand", response);
    if (response.code === 200) {
      const students = response.list_brand.map((student, index) => ({
        id: student._id,
        title: student.title,
        description: student.description,
        active_status: student.active_status,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setBrands(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveVendor = async () => {
    setIsLoading(true);
    const response = await _list_active_vendor();

    // _list_active_vendor

    console.log("response _list_active_vendor", response);
    if (response.code === 200) {
      const students = response.list_vendor.map((student, index) => ({
        id: student._id,
        name: student.name,
        email: student.email,
        contact_number: student.contact_number,
        address: student.address,
        active_status: student.active_status,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setVendor(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActiveCategory();
    fetchActiveBrand();
    fetchActiveVendor();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Asset Repair">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Add Asset Repair{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Select Service Type"
                  select
                  value={formInputs.service_type}
                  onChange={handleChange}
                  name="service_type"
                  required
                >
                  <MenuItem value="battery">Battery</MenuItem>
                  <MenuItem value="charger">Charger</MenuItem>
                  <MenuItem value="body_damage">Body Damage</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    label="Repair Date *"
                    // format="YYYY-MM-DD"
                    // value={dayjs(formInputs2.duration2)}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Charges"
                  value={formInputs.service_cost}
                  onChange={handleChange}
                  name="service_cost"
                  required
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Service Duration (Days)"
                  value={formInputs.service_duration}
                  onChange={handleChange}
                  name="service_duration"
                  required
                  //   InputProps={{
                  //     startAdornment: (
                  //       <InputAdornment position="start">R.S</InputAdornment>
                  //     ),
                  //   }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Vendor"
                  select
                  value={formInputs.vendor_id}
                  onChange={handleChange}
                  name="vendor_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {vendor.map((vendorObj) => (
                    <MenuItem key={vendorObj.id} value={vendorObj.id}>
                      {capitalizeFirstLetter(vendorObj.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                // imageFileHeading={"Image File"}
                imageFileHeading={"Upload service receipt Image"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <TextField
                fullWidth
                label="Description"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.service_description}
                onChange={handleChange}
                name="service_description"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AddCompanyAssetRepair;
