import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale

import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Accordion,
  AccordionSummary,
  Button,
  FormGroup,
  Checkbox,
  Divider,
  AccordionDetails,
  LinearProgress,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  Tooltip,
  Zoom,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_bank } from "src/DAL/bank";
import { useContentSetting } from "src/hooks/ContentSettingState";
import { _add_role, _detail_role, _edit_role } from "src/DAL/role";
import { capitalizeFirstLetter, truncateString } from "src/utils/formatText";
import {
  formatDateTimeWithOutZone,
  privilegesNameCheckAndUpperCase,
} from "src/utils";
import {
  getStaticPrivilegesObj,
  privilegesArrayConvertToObj,
  privilegesObjConvertToArray,
} from "src/utils/FormatPrivileges";
const CustomerDetail = ({ selectedRowStudent }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const { id } = useParams();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  // const { userInfo, privilegeAccess } = useContentSetting();

  const [allPrevillagesObj, setAllPrevillagesObj] = useState(
    getStaticPrivilegesObj
  );

  const [Previvlages, setPrevivlages] = useState([]);

  const [buttonText, setButtonText] = useState(() =>
    Array.from({ length: Previvlages.length }, () => "Select All")
  );

  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    title: "",
    status: true,
    privilege: {},
  });

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSelectAll = (ParentIndex) => {
    const newPrevivlages = [...Previvlages];
    const allChecked = newPrevivlages[ParentIndex].privilege_items.every(
      (item) => item.privilege_access
    );
    newPrevivlages[ParentIndex].privilege_items.forEach(
      (item) => (item.privilege_access = !allChecked)
    );
    setPrevivlages(newPrevivlages);
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    }
  };

  const handleCheckboxChange = (ParentIndex, childIndex) => {
    const newPrevivlages = [...Previvlages];

    console.log("newPrevivlages", newPrevivlages);
    console.log("ParentIndex", ParentIndex);
    console.log("childIndex", childIndex);

    newPrevivlages[ParentIndex].privileges[childIndex].value =
      !newPrevivlages[ParentIndex].privileges[childIndex].value;
    setPrevivlages(newPrevivlages);
    const allChecked = newPrevivlages[ParentIndex].privileges.every(
      (item) => item.value
    );
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    }
  };

  async function fetchCustomerDetail() {
    // setIsLoading(true);
    // const response = await _detail_role(selectedRowStudent.id);
    console.log("selectedRowStudent _selectedRowStudent", selectedRowStudent);

    // const response = await _detail_customer_without_token();
    // console.log('response _detail_customer_without_token',response);

    // if (response.code === 200) {
    //   setFormInputs({
    //     ...formInputs,
    //     title: capitalizeFirstLetter(response.role.title),
    //     status: response.role.status,
    //   });

    //   setAllPrevillagesObj(response.role.privilege);

    //   setIsLoading(false);
    // } else {
    //   enqueueSnackbar(response.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  }

  useEffect(() => {
    fetchCustomerDetail();
  }, []);

  // if (isLoading) {
  //   return <CircularLoader />;
  // }

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Edit Role">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "15px" }}
      >
        <div className="container  mt-3 mb-3">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    First Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.first_name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Last Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.last_name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Phone
                  </TableCell>
                  <TableCell align="left">
                    +92{selectedRowStudent.contact_number}
                  </TableCell>
                  <TableCell align="left" style={myStyle}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Email
                  </TableCell>
                  <TableCell align="left">
                    {selectedRowStudent.user_id.email}
                  </TableCell>
                  <TableCell align="left" style={myStyle}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Role
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.role.title)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    <Label
                      variant="outlined"
                      color={
                        (selectedRowStudent.status === false && "error") ||
                        "success"
                      }
                    >
                      {selectedRowStudent.status ? "Active" : "Inactive"}
                    </Label>
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                  </TableCell>
                </TableRow>


                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Address
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        selectedRowStudent.post_code.length > 100
                          ? capitalizeFirstLetter(selectedRowStudent.post_code)
                          : ""
                      }
                      arrow
                    >
                      {capitalizeFirstLetter(
                        truncateString(selectedRowStudent.post_code, 100)
                      )}
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left" style={myStyle}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(selectedRowStudent.createdAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(selectedRowStudent.updatedAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
};

export default CustomerDetail;
