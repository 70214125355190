import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _detail_brand, _edit_brand, _list_brand } from "src/DAL/brand";
import {
  _detail_asset_category,
  _edit_asset_category,
  _list_asset_category,
} from "src/DAL/asset_category";
import { _list_vendor } from "src/DAL/vendor";
import {
  _assign_company_asset,
  _detail_company_asset,
  _edit_company_asset,
  _return_company_asset,
  _update_company_asset_status,
} from "src/DAL/company_asset";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomDateInput from "src/components/GeneralComponents/CustomDateInput";
import HistoryDataTable from "../List/components/HistoryDataTable";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";

const AssetStatusUpdate = ({
  selectedRowStudent,
  ID,
  handleCloseEditDrawer,
  assignAssets,
  setAssignAssets,
}) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isClick, setIsClick] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [students, setStudents] = useState([]);
  const [datePost, setDatePost] = useState("");
  const [assignedHistory, setAssignedHistory] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [studentID, setStudentID] = useState("");
  const [statusImage, setStatusImage] = useState("");

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    active_status: 1,
    assigned_status: true,
    brand_id: "",
    category_id: "",
    vendor_id: "",
    device_id: "",
    quantity: "",
    price: "",
    link: "",
    details: "",
    start_date_batch_select: "",
    end_date_batch_select: "",
    student_id: "",
    memberName: "",
    student_name: "",
    status_note: "",
    asset_status: 1,
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleSelectChange = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setStudentID("");
      setSelected([]);
      // updateFormInputs([]);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        memberName: "",
        // memberName: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      console.log("newValue", newValue);
      setStudentID(newValue.id);
      setSelected(newValue);

      const selectedStudent = students.find(
        (student) => student.id === newValue.id
      );

      console.log("selectedStudent   __selectedStudent", selectedStudent);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        memberName: selectedStudent?.name,
        // memberName: selectedBatch.courses.map((course) => course._id),
      }));

      // updateFormInputs(newValue);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const selectedStudent = students.find((student) => student.id === value);

    console.log("selectedStudent   __selectedStudent", selectedStudent);
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      memberName: selectedStudent?.name,
      // memberName: selectedBatch.courses.map((course) => course._id),
    }));
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };

  const handleClick = () => {
    // Toggle the isLoading state
    setIsClick((prevClick) => !prevClick);

    // Add any other logic you want to execute on button click
  };

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      company_asset_id: selectedRowStudent.id,
      asset_status: formInputs.asset_status,
      status_note: formInputs.status_note,
      // status_image: "",
    };

    if (formInputs.asset_status===1) {
      postData = {
        company_asset_id: selectedRowStudent.id,
        asset_status: formInputs.asset_status,
        // status_note: formInputs.status_note,
        // status_image: "",
      };
    }
    if (formInputs.asset_status===4) {
      postData = {
        company_asset_id: selectedRowStudent.id,
        asset_status: formInputs.asset_status,
        status_note: formInputs.status_note,
        status_image: statusImage,
      };
    }

    console.log("postData   ____postData", postData);

    setIsLoading(true);

    // const result = await _add_company_asset(postData);
    const result = await _update_company_asset_status(postData);
    console.log("result _update_company_asset_status", result);

    if (result.code === 200) {
      const updatedAssignAssets = assignAssets.map((assign_asset) => {
        if (assign_asset.id === selectedRowStudent.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return {
            ...assign_asset,
            asset_status: formInputs.asset_status,
          };
        } else {
          // For other objects, return them as they are
          return assign_asset;
        }
      });

      setAssignAssets(updatedAssignAssets);

      setIsLoading(false);
      handleCloseEditDrawer();
      enqueueSnackbar("Status Update Successfully!", { variant: "success" });
      navigate("/company-asset");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      company_asset_id: selectedRowStudent.id,
      returned_date: datePost,
    };

    console.log("postData", postData);

    setIsLoading(true);

    // const result = await _add_company_asset(postData);
    const result = await _return_company_asset(postData);
    console.log("result _return_company_asset", result);

    if (result.code === 200) {
      const updatedAssignAssets = assignAssets.map((assign_asset) => {
        if (assign_asset.id === selectedRowStudent.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return {
            ...assign_asset,
            assigned_status: false,

            assigned_history: [],
          };
        } else {
          // For other objects, return them as they are
          return assign_asset;
        }
      });

      setAssignAssets(updatedAssignAssets);

      setIsLoading(false);
      handleCloseEditDrawer();
      enqueueSnackbar("Assign Successfully!", { variant: "success" });
      navigate("/company-asset");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_company_asset(selectedRowStudent.id);
    console.log("_detail_company_asset _detail_company_asset", response);
    // enquiry_form

    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.company_asset.purchase_date) {
        defaultStartDate = new Date(response.company_asset.purchase_date);
        console.log("defaultStartDate", defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultStartDate", defaultStartDate);

      let defaultEndDate = new Date();
      console.log("defaultEndDate", defaultEndDate);
      if (response.company_asset.warranty_expire_date) {
        defaultEndDate = new Date(response.company_asset.warranty_expire_date);
        console.log("defaultEndDate", defaultEndDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultEndDate", defaultEndDate);

      let studentID = "";
      let studentName = "";
      if (response.company_asset.assigned_history) {
        if (response.company_asset.assigned_history.length > 0) {
          let newObj = response.company_asset.assigned_history.reverse();
          studentID = newObj[0].person?._id;
          studentName = newObj[0].person?.name;
        }
      }
      setStatusImage(response.company_asset.status_image);
      setAssignedHistory(response.company_asset.assigned_history);
      setFormInputs({
        ...response.company_asset,
        asset_status: response.company_asset.asset_status,
        title: response.company_asset.title,

        student_id: studentID,
        student_name: studentName,
      });
      // setFormInputs(response.company_asset);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchStudentDetail();
    // fetchStudents();
    console.log(
      "selectedRowStudent  ___selectedRowStudent",
      selectedRowStudent
    );
  }, []);

  useEffect(() => {
    console.log("formInputs  ___formInputs", formInputs);
  }, [formInputs]);

  // if (isLoading) {
  //   return <CircularLoader />;
  // }

  return (
    <Page title="Assign Asset">
      <div className=" mt-3 px-4 mb-5 ">
        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Status"
                  select
                  // required
                  value={formInputs.asset_status}
                  onChange={handleChange}
                  name="asset_status"
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={2}>Inactive</MenuItem>
                  <MenuItem value={3}>Under Maintenance</MenuItem>
                  <MenuItem value={4}>Sold</MenuItem>
                </TextField>
              </Stack>

              {formInputs.asset_status !== 1 ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Status Note"
                    multiline // Enable multiline
                    rows={3} // Set the number of rows to 3
                    value={capitalizeFirstLetter(formInputs.status_note)}
                    onChange={handleChange}
                    name="status_note"
                    required
                    // inputProps={{
                    //   pattern: "[A-Za-z]{3,}",
                    //   title:
                    //     "Please enter at least 3 letters with only alphabetical characters.",
                    // }}
                  />
                </Stack>
              ) : (
                ""
              )}
    {formInputs.asset_status === 4 ? (
<CustomImageUploader
                pathOfUploadedFile={statusImage}
                setPathOfUploadedFile={setStatusImage}
                // imageFileHeading={"Image File"}
                imageFileHeading={"Status image"}
                imageFileSubHeading={""}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />
    ):("")}
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AssetStatusUpdate;
