import { invokeApi } from "src/utils";
export const _page_tabs = async (id) => {
  const requestObj = {
    path: `api/tab/page_tabs/` + id +`?page=0&limit=9999`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _delete_tab = async (id) => {
  const requestObj = {
    path: `api/tab/delete_tab/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_tab = async (data) => {
 
  const requestObj = {
    path: `api/tab/add_tab`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_tab = async (id) => {
  const requestObj = {
    path: `api/tab/detail_tab/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_tab = async (id, data) => {
  const requestObj = {
    path: `api/tab/update_tab/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
