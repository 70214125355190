import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Zoom,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import CourseListHead from "./components/CourseListHead";
import CourseListToolbar from "./components/CourseListToolbar";
import CourseMoreMenu from "./components/CourseMoreMenu";

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { listCourse } from "src/DAL/course";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import CourseDetailComponent from "./CourseDetailComponent";
import { capitalizeFirstLetter, truncateString } from "src/utils/formatText";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "short_title", label: "Short Title", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "duration", label: "Duration", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Courses() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRowData) {
    setOpenModal(true);
    setSelectedRow(selectedRowData);
  }

  function handleCoursesUpdate(updatedCourses) {
    setCourses(updatedCourses);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = courses.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(courses.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - courses.length) : 0;

  const filteredCourses = applySortFilter(
    courses,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredCourses.length === 0;

  const handleNav = () => {
    navigate("/courses/add-course");
  };

  function truncateDescription(description, maxLength) {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    } else {
      return description;
    }
  }

  const fetchCourses = async () => {
    const response = await listCourse();
    if (response.code === 200) {
      setIsLoading(false);

      const courses = response.courses.map((course) => ({
        ...course,
        id: course._id,
        title: course.title,
        short_title: course.short_title ? course.short_title : " _ _ ",
        description: course.description
          ? truncateDescription(course.description, 50)
          : " _ _ ",
        description_full: course.description ? course.description : " _ _ ",
        duration: course.duration,
        // image: course.image,
        status: course.status,
      }));

      setCourses(courses);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Course">
 
      <Container maxWidth="xl">
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "course",
          "view"
        ) ? (
          <>
          
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <Typography variant="h4">Courses</Typography>

              {privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "course",
                "add"
              ) ? (
                <Button
                  onClick={handleNav}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Course
                </Button>
              ) : (
                ""
              )}
            </Stack>
 
            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            > 
              <Scrollbar> 
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  // sx={{ paddingTop: "10px" }}
                >
                  <CourseListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                  />
                </Stack>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <CourseListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={courses.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredCourses
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            id,
                            title,
                            short_title,
                            description,
                            description_full,
                            duration,
                            // image,
                            status,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                               
                                 {index +1}
                              </TableCell> */}

                              <TableCell align="left" style={myStyle}>
                                {index + 1}
                              </TableCell>

                              <Tooltip
                                TransitionComponent={Zoom}
                                title={
                                  title.length > 25
                                    ? capitalizeFirstLetter(title)
                                    : ""
                                }
                                arrow
                              >
                                <TableCell align="left">
                                  <div
                                    className="on_click_text"
                                    // style={{
                                    //   cursor: "pointer",
                                    // }}
                                    onClick={() => handleOpenModal(row)}
                                  >
                                    {capitalizeFirstLetter(
                                      truncateString(title, 25)
                                    )}
                                  </div>
                                </TableCell>
                              </Tooltip>

                              <Tooltip
                                TransitionComponent={Zoom}
                                title={
                                  short_title.length > 25
                                    ? capitalizeFirstLetter(short_title)
                                    : ""
                                }
                                arrow
                              >
                                <TableCell align="left">
                                  {capitalizeFirstLetter(
                                    truncateString(short_title, 25)
                                  )}
                                </TableCell>
                              </Tooltip>

                              {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}

                              <Tooltip
                                TransitionComponent={Zoom}
                                title={
                                  description_full.length > 25
                                    ? capitalizeFirstLetter(description_full)
                                    : ""
                                }
                                arrow
                              >
                                <TableCell align="left">
                                  {capitalizeFirstLetter(
                                    truncateString(description_full, 25)
                                  )}
                                </TableCell>
                              </Tooltip>

                              {/* <TableCell align="left" style={myStyle}>
                            {description}
                          </TableCell> */}

                              <TableCell align="left">
                                {duration} Months
                              </TableCell>
                              {/* <TableCell align="left">
                            {" "}
                            <Avatar alt={title} src={s3baseUrl + image} />
                          </TableCell> */}
                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (status === false && "error") || "success"
                                  }
                                >
                                  {status ? "Active" : "Inactive"}
                                  {/* {/ {sentenceCase(status)} /} */}
                                </Label>
                              </TableCell>

                              {privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "course",
                                "edit"
                              ) ||
                              privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "course",
                                "delete"
                              ) ? (
                                <TableCell align="right">
                                  <CourseMoreMenu
                                    row={row}
                                    courses={courses}
                                    onCoursesUpdate={handleCoursesUpdate}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell align="right"></TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: courses.length, label: "All" },
                ]}
                component="div"
                count={courses.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : (
          // <NoAccessData />
          <NoAccessData />
        )}
      </Container>

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Course Detail"
        componentToPassDown={<CourseDetailComponent _ID={selectedRow.id} />}
      />
    </Page>
  );
}
