import { invokeApi } from "src/utils";
export const listSettings = async () => {
  const requestObj = {
    path: `api/website_setting/detail_website_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const editSettings = async (id, data) => {
  const requestObj = {
    path: `api/website_setting/edit_website_setting/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
