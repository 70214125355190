import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  TableHead,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { _detail_enquiry_student } from "src/DAL/enquirystudent";
import { formatDateTimeWithOutZone } from "src/utils";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateWithDay, formatTime24Hours } from "src/utils/formatTime";
import {
  _add_leave_request_for_admin,
  _change_leave_request_status,
} from "src/DAL/leave_requests";
const ChangeLeaveRequestStatus = ({
  selectedRowStudent,
  students,
  onStudentsUpdate,
  handleCloseModal,
  isLoading,
  setIsLoading,
}) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [courses, setCourses] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);
  const [instructors, setInstructors] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batcheFees, setbatcheFees] = useState([]);
  const [batcheStudents, setBatcheStudents] = useState([]);
  const [registrationFee, setRegistrationFee] = useState("");

  const [feeDiscount, setFeeDiscount] = useState("");

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: true,
    registration_fee: 0,
    batch_discount: 0,
    batch_id: "",
    student_id: "",
    additional_discount: 0,
    additional_discount_reason: "",
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array

    batch_student_id: [],
    batch_fee_id: "",
    voucher_type: 2,
    registration_fee: "",
    isStudentFree: false,
    registration_fee_discount: "",
    voucher_to: 2,
    fee_title: "",
    leave_reason: "",
    fee_amount: "",
    leave_request_status: 1,
    rejection_reason: "",
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [feeDiscountChecked, setFeeDiscountChecked] = useState(false);

  const myStyle = {
    textTransform: "capitalize",
  };

  const handleCheckboxFeeChange = (event) => {
    setFeeDiscountChecked(event.target.checked);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleStudentChange = (e) => {
    const { target } = e;
    console.log("target __test", target);

    // Use Array.prototype.find to locate the desired object
    let foundObject = batcheStudents.find((obj) => obj._id === target.value);
    // let FreeStudent = false;

    if (foundObject) {
      if (foundObject.fee_status === 3) {
        setFormInputs({
          ...formInputs,
          [target.name]: target.value,
          voucher_type: 1,
        });
      } else {
        setFormInputs({
          ...formInputs,
          [target.name]: target.value,
          // isStudentFree:false,
        });
      }
    } else {
      setFormInputs({
        ...formInputs,
        [target.name]: target.value,
        // isStudentFree:false,
      });
    }
  };

  const handleChangeNumeric = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: parseInt(value, 10), // Parse value as an integer
    }));
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      batch_student_id: [],
      batch_fee_id: "",
    });

    // Use Array.prototype.find to locate the desired object
    let foundObject = batches.find((obj) => obj.id === target.value);

    if (foundObject) {
      console.log("foundObject ___foundObject", foundObject);

      let filteredData = foundObject.students
        .filter((student) => student.student_status === 1)
        .map((student) => ({
          ...student,
          id: student._id,
          name: student.name,
          fatherName: student.father_name,
          contactNumber: student.contact_number,
        }));

      console.log("filteredData  ___filteredData ___foundObject", filteredData);

      setbatcheFees(foundObject.batch_fee);
      setBatcheStudents(filteredData);
      // setBatcheStudents(foundObject.students);
      setRegistrationFee(foundObject.registration_fee);
    } else {
      setbatcheFees([]);
      setBatcheStudents([]);
      setRegistrationFee("");
    }

    // const { name, value } = e.target;
    // console.log("name", name);
    // console.log("value", value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   // [name]: value,
    //   courses: value,
    // }));

    // if(value){

    // }
    // fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    let postData = {
      leave_request_id: selectedRowStudent._id,
      leave_request_status: formInputs.leave_request_status,
      rejection_reason:
        formInputs.leave_request_status === 3
          ? formInputs.rejection_reason
          : "",
    };

    if (formInputs.leave_request_status !== 3) {
      postData = {
        leave_request_id: selectedRowStudent._id,
        leave_request_status: formInputs.leave_request_status,
      };
    }

    console.log("postData _test", postData);

    setIsLoading(true);
    const result = await _change_leave_request_status(postData);

    if (result.code === 200) {
      setIsLoading(false);
      navigate("/leave-requests");
      //   navigate(-1);
      handleCloseModal();

      const updatedStudents = students.map((student) => {
        if (student._id === selectedRowStudent._id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return {
            ...student,
            status: formInputs.leave_request_status,
            rejection_reason:
              formInputs.leave_request_status === 3
                ? formInputs.rejection_reason
                : "",
          };
        } else {
          // For other objects, return them as they are
          return student;
        }
      });

      onStudentsUpdate(updatedStudents);

      //   navigate(-1);
      enqueueSnackbar("Status Updated Successfully!", { variant: "success" });

      setStartDate("");
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setFormInputs({
      ...formInputs,
      leave_request_status: selectedRowStudent.status,
      rejection_reason: capitalizeFirstLetter(
        selectedRowStudent.rejection_reason
      ),
    });
  }, []);

  useEffect(() => {
    console.log(
      " formInputs.leave_request_status   ________formInputs.leave_request_status",
      formInputs.leave_request_status
    );
    console.log(
      " formInputs.leave_request_status typeof  ________formInputs.leave_request_status",
      typeof formInputs.leave_request_status
    );
  }, [formInputs.leave_request_status]);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Status Change">
      <div>
        <div className="container col-12 ">
          <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Stack spacing={3}>
                <Stack direction="row" spacing={2}>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Status
                    </FormLabel>
                    <RadioGroup
                      row
                      // aria-labelledby="demo-row-radio-buttons-group-label"
                      // name="row-radio-buttons-group"
                      aria-label="leave_request_status"
                      name="leave_request_status"
                      value={formInputs.leave_request_status}
                      onChange={handleChangeNumeric}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Pending"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="Approved"
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        label="Rejected"
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* <RadioGroup
    aria-label="leave_request_status"
    name="leave_request_status"
    value={formInputs.leave_request_status}
    onChange={handleChangeNumeric}
  >
    <FormControlLabel
      value={1}
      control={<Radio />}
      label="Pending"
    />
    <FormControlLabel
      value={2}
      control={<Radio />}
      label="Approved"
    />
    <FormControlLabel
      value={3}
      control={<Radio />}
      label="Rejected"
    />
  </RadioGroup> */}
                </Stack>

                {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> 
                  <TextField
                    fullWidth
                    label="Leave Request Status"
                    select
                    value={formInputs.leave_request_status}
                    onChange={handleChange2}
                    name="leave_request_status"
                    SelectProps={{ multiple: false }}
                    required
                    style={myStyle}
                  >
                    <MenuItem value={1} style={myStyle}>
                      Pending
                    </MenuItem>

                    <MenuItem value={2} style={myStyle}>
                      Approved
                    </MenuItem>

                    <MenuItem value={3} style={myStyle}>
                      Rejected
                    </MenuItem>
                  </TextField>
                </Stack> */}

                {formInputs.leave_request_status === 3 ? (
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      label="Rejection Reason"
                      value={formInputs.rejection_reason}
                      onChange={handleChange}
                      name="rejection_reason"
                      required
                    ></TextField>
                  </Stack>
                ) : (
                  ""
                )}

                <Stack alignItems="end">
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{ width: "120px" }}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </Stack>
            </form>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

export default ChangeLeaveRequestStatus;
