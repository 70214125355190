import { invokeApi } from "src/utils";



export const _list_person = async (data) => {

    const requestObj = {
      path: `api/person/list_person`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _add_person = async (data) => {
    const requestObj = {
      path: `api/person/add_person`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data, 
    };
    return invokeApi(requestObj);
  };

export const _delete_person = async (id) => {
  const requestObj = {
    path: `api/person/delete_person/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _detail_person = async (id) => {
  const requestObj = {
    path: `api/person/detail_person/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};


export const _edit_person = async (id, data) => {
  const requestObj = {
    path: `api/person/edit_person/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

 
 
export const _active_person_list = async () => {
  const requestObj = {
    path: `api/person/active_person_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
