import React from "react";
import { no_access } from "../../assets";

export default function NoAccessDataSimple({ title }) {
  return (
    <div className="d-flex justify-content-center align-items-center"  >
      {/* <img className="mx-auto" src={no_access} /> */}
      <p className="mt-3 ">{`${title ? title : "You have no access to view this data"
        }`}</p>
    </div>
  );
}
