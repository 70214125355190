import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale
import moment from "moment"; 
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { capitalizeFirstLetter } from "src/utils/formatText";
const Add = () => {

  const myStyle = {
    textTransform: "capitalize",
  };
  
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [timePreferences, setTimePreferences] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    // last_name: "",
    email: "",
    gender: 1,
    course: "",
    others: [],
    mobile_number: "",
    fee_status: "paid",
    enquiry_status: "false",
    enquiry_status_reason: "",
    note: "",
    education: "",
    image: "",
    address: "",
    other: "",
    reference: "",
    decided_registration_fee: 0,
    decided_course_fee: 0,
    course_type: "existing_course",
    batches: [],

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    time_preferences: "",
  });

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);
  
  
    const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }
  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "mobile_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");

    // if (name === 'course' && value.includes('other')) {
    //   setFormInputs({
    //     ...formInputs,
    //     [name]: value.filter((option) => option !== 'other'),
    //     other: ''
    //   });
    // } else {
    //   setFormInputs({
    //     ...formInputs,
    //     [name]: value
    //   });
    // }

    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };
  const handleChange2 = (event, newValue) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      course: newValue ? newValue._id : "",
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);

  

    let postData = [];

    if (
      formInputs.course_type === "new_course" &&
      formInputs.other.trim() !== "" &&
      formInputs.other.length > 0
    ) {
      postData = {
        name: formInputs.name,
        father_name: formInputs.father_name,
        // date_of_birth: formInputs.dob,
        // email: formInputs.email,

        // enquiry_status: formInputs.enquiry_status,
        // enquiry_status_reason: formInputs.enquiry_status_reason,
        note: formInputs.note,
        decided_registration_fee: formInputs.decided_registration_fee,
        decided_course_fee: formInputs.decided_course_fee,

        mobile_number: formInputs.mobile_number,
        referred_by: formInputs.reference,
        gender: formInputs.gender,
        others: [formInputs.other],

        latest_degree: formInputs.latest_degree,
        degree_status: formInputs.degree_status,
        // completion_year: formInputs.degree_status ==="complete"?selectedYear:"",
        completion_year: formInputs.degree_status === "complete" ? (typeof selectedYear === 'number' ? selectedYear.toString() : selectedYear) : "",

        institution_name: formInputs.institution_name,
        semester: formInputs.semester,
        time_preference_id: formInputs.time_preferences,
      };
      setIsLoading(true);
      const result = await _add_enquiry_form(postData);

      console.log("result _add_enquiry_form", result);

      if (result.code === 200) {
        setIsLoading(false);
        enqueueSnackbar("Added Successfully!", { variant: "success" });
        navigate("/enquiry-students");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else if (
      formInputs.course_type === "existing_course" &&
      formInputs.course.length > 0
    ) {
      postData = {
        name: formInputs.name,
        father_name: formInputs.father_name,
        contact_number: formInputs.mobile_number,
        gender: formInputs.gender,
        course: formInputs.course,
        
        referred_by: formInputs.reference,
        // enquiry_status: formInputs.enquiry_status,
        // enquiry_status_reason: formInputs.enquiry_status_reason,
        note: formInputs.note,

        // decided_registration_fee: formInputs.decided_registration_fee,
        // decided_course_fee: formInputs.decided_course_fee,

        latest_degree: formInputs.latest_degree,
        degree_status: formInputs.degree_status,
        completion_year: formInputs.degree_status === "complete" ? (typeof selectedYear === 'number' ? selectedYear.toString() : selectedYear) : "",

        institution_name: formInputs.institution_name,
        semester: formInputs.semester,
        time_preference_id: formInputs.time_preferences,
      };
      setIsLoading(true);
      const result = await _add_enquiry_form(postData);

      console.log("result _add_enquiry_form", result);

      if (result.code === 200) {
        setIsLoading(false);
        enqueueSnackbar("Added Successfully!", { variant: "success" });
        navigate("/enquiry-students");
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar("Please! Select A Course Or Add New One.", {
        variant: "error",
      });
    }

    // if (formInputs.other.trim() !== null && formInputs.other.trim() !== "") {
    //    postData = {
    //     name: formInputs.name,
    //     father_name: formInputs.father_name,
    //     date_of_birth: formInputs.dob,
    //     email: formInputs.email,
    //     mobile_number: formInputs.mobile_number,
    //     gender: formInputs.gender,
    //     others: [formInputs.other],
    //   };
    // }
    // body: JSON.stringify(postData)

    console.log("postData postData", postData);
    console.log("postData.length", postData.length);
    console.log("Array.isArray(postData)", Array.isArray(postData));

    console.log("typeof postData", typeof postData);
  };

  useEffect(() => {
    if (courses.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        courses: [courses[0]._id],
      }));
    }
  }, [courses]);
  useEffect(() => {
    if (batches.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        batches: [batches[0].id],
      }));
    }
  }, [batches]);

  const fetchActiveBatches = async () => {
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      setCourses(batches[0].courses);
      setBatches(batches);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveCourse = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response", response);
    if (response.code === 200) {
      // const batches = response.batches.map((batch) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   student_count: batch.student_count,
      //   status: batch.status,
      //   courses: batch.courses,
      // }));
      // Filter and set active courses
      const activeCourses = response.courses.filter(
        (course) => course.status === true
      );
      setCourses(activeCourses);
      // setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchTimePreference = async () => {
    // setIsLoading(true);
    const response = await _active_time_preference();
    console.log("response  _active_time_preference __test", response);

    //

    if (response.code === 200) {
      // const courses = response.courses
      //   .filter((course) => course.status === true)
      //   .map((course) => ({
      //     id: course._id,
      //     fee: course.fee,
      //     title: course.title,
      //     description: course.description,
      //     duration: course.duration,
      //     // image: course.image,
      //     status: course.status,
      //   }));
      // console.log("courses courses courses courses", courses);
      // setCourses(courses);
      setTimePreferences(response.time_preferences);

      if (response.time_preferences.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          time_preferences: response.time_preferences[0]._id,
        }));
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveCourse();
    fetchTimePreference();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Student">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Enquiry Student </h4>
        </Grid>


        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Father name"
                  value={formInputs.last_name}
                  onChange={handleChange}
                  name="father_name"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                {/* <TextField
                  fullWidth
                  label="Last name"
                  value={formInputs.last_name}
                  onChange={handleChange}
                  name="last_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                /> */}
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {/* <TextField
                  fullWidth
                  type="email"
                  label="Email address"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  autoComplete="off"
                  // required
                  inputProps={{
                    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                    title: "Please enter a valid email address",
                  }}
                /> */}

                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.mobile_number}
                  required
                  onChange={handleChangePhone}
                  name="mobile_number"
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Gender"
                  select
                  // required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </TextField>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Reference By"
                  value={formInputs.reference}
                  onChange={handleChange}
                  name="reference"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                {/* <TextField
                  fullWidth
                  label="Expire / Do Not Call"
                  select
                  value={formInputs.enquiry_status}
                  onChange={handleChange}
                  name="enquiry_status"
                  required
                >
                  <MenuItem value="false">Not Expire</MenuItem>
                  <MenuItem value="true">Expire / Do Not Call</MenuItem>
                </TextField> */}
              </Stack>

              {/* <TextField
                  fullWidth
                  label="Courses"
                  select
                  value={formInputs.course}
                  onChange={handleChange}
                  name="course"
                  // required
                  SelectProps={{ multiple: false }}
                  required={formInputs.other.trim() === ''}
                >
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course._id}>
                      {course.title}
                    </MenuItem>
                  ))}
                </TextField> */}

             
              <div>
                {formInputs.course_type === "existing_course" ? (
                  <Autocomplete
                    fullWidth
                    label="Courses"
                    options={courses}
                    getOptionLabel={(course) => capitalizeFirstLetter(course.title)}
                    value={
                      courses.find(
                        (course) => course._id === formInputs.course
                      ) || null
                    }
                    onChange={handleChange2}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="course"
                        required
                        label="Courses"
                        placeholder="Courses"
                      />
                    )}
                  />
                ) : (
                  <TextField
                    fullWidth
                    label="Add New Course"
                    value={formInputs.other}
                    onChange={handleChange}
                    name="other"
                    required
                  />
                )}
              </div>
              {/* <Autocomplete
      fullWidth
      label="Courses"
      options={courses}
      getOptionLabel={(course) => course.title}
      value={courses.find((course) => course._id === formInputs.course) || null}
      onChange={handleChange2}
      renderInput={(params) => (
        <TextField
          {...params}
          name="course"
          // required
        />
      )}
    /> */}

              {/* <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                  required
                >
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="due">Due</MenuItem>
                  <MenuItem value="free">Full Fee Scholarship</MenuItem>
                </TextField> */}

              {/* <TextField
                fullWidth
                label="Other"
                value={formInputs.other}
                onChange={handleChange}
                name="other"
              /> */}

              {/* <TextField
                fullWidth
                label="Batch"
                select
                value={formInputs.batches}
                onChange={handleChange}
                name="batches"
                required
                // SelectProps={{ multiple: true }}
              >
                {batches.map((batch) => (
                  <MenuItem key={batch.id} value={batch.id}>
                    {batch.name}
                  </MenuItem>
                ))}
              </TextField> */}

              {/* <div className="col-md-12 col-xs-12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    format="YYYY-MM-DD"
                    value={formInputs?.date_of_birth}
                    onChange={handleDateChange}
                    maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>
              </div> */}

              {/* <TextField
                fullWidth
                label="Education"
                required
                value={formInputs.education}
                onChange={handleChange}
                name="education"
              /> */}
              {/* <TextField
                fullWidth
                label="Address"
                required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
              /> */}
              {show === false
                ? // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Input
                  //     type="file"
                  //     id="image"
                  //     name="image"
                  //     accept="image/*"
                  //     onChange={handleFile}
                  //     required
                  //   />
                  //   {/* <Avatar alt={"image"} src={previewUrl} /> */}
                  // </Stack>
                  ""
                : null}

              {show === true
                ? // <Stack
                  //   position="relative"
                  //   alignItems="center"
                  //   justifyContent="center"
                  // >
                  //   {" "}
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Box
                  //     position="relative"
                  //     display="inline-block"
                  //     border="2px solid #ccc"
                  //     p={1}
                  //   >
                  //     <img
                  //       alt="image"
                  //       src={previewUrl}
                  //       width="150"
                  //       style={{ maxWidth: "100%" }}
                  //     />
                  //     <IconButton
                  //       aria-label="delete"
                  //       onClick={() => handleDeleteImage()}
                  //       style={{ position: "absolute", top: 0, right: 0 }}
                  //     >
                  //       <ClearIcon />
                  //     </IconButton>
                  //   </Box>
                  // </Stack>
                  ""
                : null}

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  // type="number"
                  label="Decided Registration fee"
                  value={formInputs.decided_registration_fee}
                  // required
                  onChange={handleChange}
                  name="decided_registration_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                />

                <TextField
                  fullWidth
                  // type="number"
                  label="Decided Course fee (Monthly)"
                  value={formInputs.decided_course_fee}
                  // required
                  onChange={handleChange}
                  name="decided_course_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                />
              </Stack> */}


 
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Latest Degree"
                  value={formInputs.latest_degree}
                  onChange={handleChange}
                  name="latest_degree"
                />

                <TextField
                  fullWidth
                  label="Degree Status"
                  select
                  value={formInputs.degree_status}
                  onChange={handleChange}
                  name="degree_status"
                  required
                >
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="inprogress">Inprogress</MenuItem>
                </TextField>
              </Stack>
              
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {formInputs.degree_status ==="complete"?
              //   <TextField
              //   fullWidth
              //   label="Completion Year"
              //   value={formInputs.completion_year}
              //   onChange={handleChange}
              //   name="completion_year"
              // />
              <TextField
              fullWidth
              label="Completion Year"
              select
              value={selectedYear}
              onChange={handleYearChange}
              name="degree_status"
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
              :""
              }
                 

              <TextField
                  fullWidth
                  label="Institution Name"
                  value={formInputs.institution_name}
                  onChange={handleChange}
                  name="institution_name"
                />

{formInputs.degree_status ==="inprogress"?
              <TextField
                  fullWidth
                  label="Semester"
                  value={formInputs.semester}
                  onChange={handleChange}
                  name="semester"
                />:""
              }
              </Stack>
              <TextField
                fullWidth
                label="Time Preferences"
                select
                value={formInputs.time_preferences}
                onChange={handleChange}
                name="time_preferences"
                // required
                // style={myStyle} // Apply the style here
                SelectProps={{ multiple: false }}
              >
                {timePreferences.map((time_preferences) => (
                  <MenuItem
                    key={time_preferences._id}
                    value={time_preferences._id}
                    // style={myStyle} // Apply the style here
                  >
                    {capitalizeFirstLetter(time_preferences.type)} ({formatTime(time_preferences.start_time)} to{" "}
                    {formatTime(time_preferences.end_time)} )
                  </MenuItem>
                ))}
              </TextField>
 {/*  */}

              <TextField
                fullWidth
                label="Note"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.note}
                onChange={handleChange}
                name="note"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <div>
                {/* {formInputs.enquiry_status === false ? ( */}
                {formInputs.enquiry_status === "true"
                  ? // <TextField
                    //   fullWidth
                    //   label="Expire Reason"
                    //   value={formInputs.enquiry_status_reason}
                    //   onChange={handleChange}
                    //   name="enquiry_status_reason"
                    //   // inputProps={{
                    //   //   pattern: "[A-Za-z]{3,}",
                    //   //   title:
                    //   //     "Please enter at least 3 letters with only alphabetical characters.",
                    //   // }}
                    // />
                    ""
                  : ""}
              </div>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
