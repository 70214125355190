import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import {
  _remove_student_from_batch,
  _student_move_to_trash,
  delStudent,
} from "src/DAL/student";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import BatchStudentDetail from "../BatchStudentDetail";
import BatchStudentStatusUpdate from "../BatchStudentStatusUpdate";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useAppContext } from "src/hooks";
// ----------------------------------------------------------------------

export default function StudentMoreMenu({
  row,
  students,
  onStudentsUpdate,
  trashStudentCount,
  setTrashStudentCount,
  batch_id,
}) {
  const [openDelete, setOpenDelete] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setIsOpen(false);
    setselectedRowStudent(selectedRow);
  }
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    //console.log(row);

    const result = await _remove_student_from_batch({
      student_id: row.id,
      batch_id: batch_id,
    });
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students.id !== row.id
      );
      onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Remove Student Successfully!", { variant: "success" });
      // setTrashStudentCount(trashStudentCount + 1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "student",
          "edit"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/students/edit-student/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "update_status"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={() => handleOpenModal(row)}
          >
            <ListItemIcon>
              <Iconify
                icon="ic:sharp-system-security-update"
                vFlip={true}
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Update Status"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "admin"
        ) ? (
          <>
            <MenuItem
              component={RouterLink}
              to={`/batches/move-batch/${row.id}?batch_id=${batch_id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify icon="ph:student" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Move to Batch"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>

            <MenuItem
              component={RouterLink}
              to={`/batches/edit-fee/${row.id}?batch_id=${batch_id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify icon="tabler:pencil-dollar" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Edit Fee"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </>
        ) : (
          ""
        )}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "delete"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify
                icon="mingcute:user-remove-line"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Remove Student"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {/* <MenuItem sx={{ color: "text.secondary" }} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Move To Trash"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/freeze-student/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="mdi:freeze-advisory" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Freeze Student"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
        {/* <MenuItem
          component={RouterLink}
          to={`/batches/move-student-to-batch/${row.id}?batch_id=${batch_id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="ph:student" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Move to Batch"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/transaction/student-transaction/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:history-24-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Payment History"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/update-student-fee/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:phone-update-checkmark-20-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Update Fee"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to Remove this Student?"
        onOk={handleDeleteOk}
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle={`${selectedRowStudent.name} ( Student ) Status Update`}
        componentToPassDown={
          <BatchStudentStatusUpdate
            studentID={selectedRowStudent.id}
            batchID={batch_id}
            handleCloseModal={handleCloseModal}
            Students={students}
            onStudentsUpdate={onStudentsUpdate}
          />
        }
      />
    </>
  );
}
