import { invokeApi } from "src/utils";

export const _add_transaction = async (data) => {
 
  const requestObj = {
    path: `api/transaction/add_transaction`,
    method: "POST", 
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_transaction = async (id, data) => {
  const requestObj = {
    path: `api/transaction/update_transaction/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _pay_registration_fee = async (data) => {
 
  const requestObj = {
    path: `api/invoice/pay_registration_fee`,
    method: "POST", 
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _refund_registration_fee = async (data) => {
 
  const requestObj = {
    path: `api/invoice/refund_registration_fee`,
    method: "POST", 
    headers: {
      // "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _refund_tution_fee = async (data) => {
 
  const requestObj = {
    path: `api/invoice/refund_tution_fee`,
    method: "POST", 
    headers: {
      // "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
//  
export const _create_payment = async (data) => {
 
  const requestObj = {
    path: `api/payment/create_payment`,
    method: "POST", 
    headers: {
      // "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _pay_tuition_fee = async (data) => {
 
  const requestObj = {
    path: `api/invoice/pay_tuition_fee`,
    method: "POST", 
    headers: {
      // "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_collect_fee = async (data) => {
 
    const requestObj = {
      path: `api/collect_fee/add_collect_fee`,
      method: "POST", 
      headers: {
        // "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_refund_fee = async (data) => {
 
    const requestObj = {
      path: `api/collect_fee/add_refund_fee`,
      method: "POST", 
      headers: {
        // "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_payment = async (id) => {
    const requestObj = {
      path: `api/payment/delete_payment/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  }
  export const _delete_transaction = async (id) => {
    const requestObj = {
      path: `api/transaction/delete_transaction/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  }

  export const _list_transaction = async (data) => {
 
    const requestObj = {
      path: `api/transaction/list_transaction`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
  export const _student_transactions = async (id) => {
    const requestObj = {
      path: `api/transaction/student_transactions/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _list_payments = async (data) => {
 
    const requestObj = {
      path: `api/payment/list_payments`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _list_invoice = async (data) => {
 
    const requestObj = {
      path: `api/invoice/list_invoice`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _list_collect_fee = async (data) => {
 
    const requestObj = {
      path: `api/collect_fee/list_collect_fee`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _edit_collect_fee = async (id, data) => {
    const requestObj = {
      path: `api/collect_fee/edit_collect_fee/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
  

  export const _refund_transaction = async (id, data) => {
    const requestObj = {
      path: `api/transaction/refund_transaction/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _detail_invoice= async (id) => {
    const requestObj = {
      // path: `api/collect_fee/detail_collect_fee/${id}`,
      path: `api/invoice/detail_invoice/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };
  export const _detail_transaction= async (id) => {
    const requestObj = {
      // path: `api/collect_fee/detail_collect_fee/${id}`,
      path: `api/transaction/detail_transaction/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };
 