import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { _filter_student, addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { capitalizeFirstLetter } from "src/utils/formatText";
// const FilterStudent = () => {

export default function FilterStudent({
  // task_id,
  onCloseDrawer,
  isOpenDrawer,
  // formType,
  students,
  setStudents,
}) {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    gender: "male",
    courses: [],
    phone: "",
    fee_status: "paid",
    fee_type: "all",
    status: "true",
    education: "",
    image: "",
    reg_type: "regular",
    course_fee: "",
    registration_fee: "",
    decided_fee: "",
    actual_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    batches: [],
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange2 = (event) => {
    const selectedCourses = event.target.value;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      courses: selectedCourses,
    }));
  };

  const handleCourseChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    // Find the course object with the specified _id
    const course = courses.find((item) => item.id === value);

    // const course = courses.find((item) => {
    //   console.log('item', item);
    //   return item._id === '64a45cd7e0ad1892dd66cd1c';
    // });

    const fee = course ? course.fee : null;

    console.log("course", course);
    console.log("fee", fee); // Output: 24000

    if (fee !== null) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        actual_fee: fee,
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("formInputs", formInputs);

    let batch_id;

    if (Array.isArray(formInputs.batches)) {
      batch_id = formInputs.batches;
    } else {
      batch_id = [formInputs.batches];
    }

    let new_batch_id = "";
    if (batch_id.length > 0) {
      new_batch_id = batch_id[0];
    }

    console.log("new_batch_id new_batch_id __batch_id", new_batch_id);
    let postData = {
      // batch_id: batch_id,
      batch_id: new_batch_id,
      fee_type: formInputs.fee_type, // registration_fee, registration_and_tution
      fee_status: formInputs.fee_status, // registration_fee, registration_and_tution
    };

    if (formInputs.fee_type === "all") {
      postData = {
        // batch_id: batch_id,
        batch_id: new_batch_id,
        fee_type: formInputs.fee_type, // registration_fee, registration_and_tution
        // fee_status: formInputs.fee_status, // registration_fee, registration_and_tution
      };
    }
    console.log("postData", postData);
    setIsLoading(true);
    const result = await _filter_student(postData);
    console.log("result", result);

    if (result.code === 200) {
      const students = result.students.map((student, index) => ({
        // id: student._id,
        // name: student.name,
        // // last_name: student.last_name,
        // email: student.email,
        // // image: student.profile_image,
        // status: student.status,
        // gender: student.gender,
        // decided_fee: student.decided_fee,
        // reg_type: student.reg_type,
        // cnic: student.cnic,
        // registration_fee: student.registration_fee,
        // batch: student.batch_id ? student.batch_id : "",
        // courses: student.courses ? student.courses : "",
        // number: index + 1,

        // 
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: student.father_name,
        address: student.address,
        reg_type: student.reg_type,
        contact_number:  student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        number: index + 1,

      }));

      setStudents(students);
      setIsLoading(false);
      enqueueSnackbar("Filter Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }

    // setIsLoading(true);
    // console.log("formInputs", formInputs);

    // const data = new FormData();
    // data.append("name", formInputs.first_name.trim());
    // data.append("father_name", formInputs.father_name.trim());
    // // data.append("profile_image", image);
    // data.append("date_of_birth", formInputs.dob);
    // data.append("email", formInputs.email.trim());
    // data.append("contact_number", formInputs.phone_number);
    // data.append("gender", formInputs.gender);
    // // data.append("courses", JSON.stringify(formInputs.courses));
    // data.append(
    //   "courses",
    //   JSON.stringify(
    //     Array.isArray(formInputs.courses)
    //       ? formInputs.courses
    //       : [formInputs.courses]
    //   )
    // );
    // data.append(
    //   "batch_id",
    //   JSON.stringify(
    //     Array.isArray(formInputs.batches)
    //       ? formInputs.batches
    //       : [formInputs.batches]
    //   )
    // );
    // data.append("status", formInputs.status);
    // data.append("reg_type", formInputs.reg_type);

    // // data.append("course_fee", parseInt(formInputs.course_fee));
    // data.append("course_fee", formInputs.course_fee);
    // data.append("registration_fee", formInputs.registration_fee);
    // data.append("actual_fee", formInputs.actual_fee);
    // data.append("decided_fee", formInputs.decided_fee);

    // // data.append("registration_fee", parseInt(formInputs.registration_fee, 10));
    // // data.append("actual_fee", parseInt(formInputs.actual_fee, 10));
    // // data.append("decided_fee", parseInt(formInputs.decided_fee, 10));
    // data.append("cnic", formInputs.cnic);
    // data.append("referred_by", formInputs.referred_by);
    // data.append("address", formInputs.address);

    // // data.append("course_fee", formInputs.course_fee);
    // // data.append("registration_fee", formInputs.registration_fee);
    // // data.append("actual_fee ", formInputs.actual_fee);
    // // data.append("decided_fee ", formInputs.decided_fee);

    // // data.append("actual_fee", parseFloat(formInputs.actual_fee));
    // // data.append("decided_fee", parseFloat(formInputs.decided_fee));

    // const result = await addStudent(data);
    // if (result.code === 200) {
    //   setIsLoading(false);
    //   navigate("/students");
    //   enqueueSnackbar("Added Successfully!", { variant: "success" });
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  };

  // useEffect(() => {
  //   if (courses.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       courses: [courses[0]._id],
  //     }));
  //   }
  // }, [courses]);

  useEffect(() => {
    if (batches.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        batches: [batches[0].id],
      }));
    }
  }, [batches]);

  const fetchActiveBatches = async () => {
    setIsLoading(true);
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      // setCourses(batches[0].courses);
      // console.log("batches[0].courses batches[0].courses", batches[0].courses);
      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //   const fetchCourses = async () => {
  //     const response = await listCourse();
  //     console.log("response listCourse listCourse listCourse", response);
  //     if (response.code === 200) {
  //       setIsLoading(false);

  //       const courses = response.courses
  //         .filter((course) => course.status === true)
  //         .map((course) => ({
  //           id: course._id,
  //           fee: course.fee,
  //           title: course.title,
  //           description: course.description,
  //           duration: course.duration,
  //           // image: course.image,
  //           status: course.status,
  //         }));
  //       console.log("courses courses courses courses", courses);
  //       setCourses(courses);
  //     } else {
  //       enqueueSnackbar(response.message, { variant: "error" });
  //     }
  //   };

  useEffect(() => {
    fetchActiveBatches();
    // fetchCourses();
  }, []);

  return (
    <Page title="Filter Student">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px", paddingX: "20px" }}
      >
        {/* <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        > */}
        {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}

        <Typography variant="h4" align="center" sx={{ flex: 1 }}>
          Filter Student
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        sx={{ marginBottom: "40px", marginTop: "20px", paddingX: "20px" }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <Stack spacing={3}>
            {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}

            <TextField
              fullWidth
              label="Batch"
              select
              value={formInputs.batches}
              onChange={handleChange}
              name="batches"
              required
              // SelectProps={{ multiple: true }}
            >
              {batches.map((batch) => (
                <MenuItem key={batch.id} value={batch.id}>
                  {capitalizeFirstLetter(batch.name)}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              label="Fee Type"
              select
              // required
              value={formInputs.fee_type}
              onChange={handleChange}
              name="fee_type"
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="tuition">Tuition</MenuItem>
              <MenuItem value="registration">Registration</MenuItem>
            </TextField>

            {formInputs.fee_type !== "all" ? (
              <TextField
                fullWidth
                label="Fee Status"
                select
                // required
                value={formInputs.fee_status}
                onChange={handleChange}
                name="fee_status"
              >
                <MenuItem value="paid">paid</MenuItem>
                <MenuItem value="pending">pending</MenuItem>
              </TextField>
            ) : (
              ""
            )}
            <Stack alignItems="end">
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "120px" }}
              >
                Filter
              </LoadingButton>
            </Stack>
          </Stack>
          {/* </Stack> */}
        </form>
      </Grid>
      {/* </Grid> */}
    </Page>
  );
}
