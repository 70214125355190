import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { _student_update_freeze_status, detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";

const FreezeFromStudent = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    // last_name: "",
    email: "",
    gender: "male",
    course: [],
    others: [],
    mobile_number: "",
    fee_status: "paid",
    enquiry_status: "active",
    enquiry_status_reason: "",
    education: "",
    image: "",
    address: "",
    reference: "",
    batches: [],
    dob: "",

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    reason: "",

    // date_of_birth: null, // Set initial value to null
    // date_of_birth : dayjs("2001-03-03").toDate(),
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
 
    console.log("formInputs", formInputs);

    const postData = {
        freeze_status: true,
        freeze_status_reason: formInputs.reason,
    };
    console.log("postData postData", postData);
    // const result = await _add_enquiry_form(postData);

    const result = await _student_update_freeze_status(id, postData);
    if (result.code === 200) {
      setIsLoading(false);
      // navigate("/students");
      navigate(-1);
      enqueueSnackbar("Freeze Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }

  
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_enquiry_student(id);
    console.log("_detail_enquiry_student _detail_enquiry_student", response);
    // enquiry_form

    if (response.code === 200) {
      // setCourses(response.enquiry_form.course);
      let courseIds = [];
      if (response.enquiry_form.course.length > 0) {
        courseIds = response.enquiry_form.course.map((_course) => _course._id);
      } else if (response.enquiry_form.others.length > 0) {
        courseIds = response.enquiry_form.others.map((_others) => _others._id);
      }
      console.log("courseIds courseIds courseIds", courseIds);
      console.log(
        "response.enquiry_form.others.length response.enquiry_form.others.length > 0",
        response.enquiry_form.others.length > 0
      );
      // const batchIds = response.student.batch_id.map((batch) => batch._id);
      // console.log(batchIds, "batchIdsbatchIds");
      // console.log(data.student.first_name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];

      // let defaultDate = new Date();
      // if(response.enquiry_form.date_of_birth!==null && response.enquiry_form.date_of_birth!==""){
      //  defaultDate = new Date(response.enquiry_form.date_of_birth);
      // }

      let response_gender = response.enquiry_form.gender;
      let gender_set = "male";

      if (response_gender === "female") {
        gender_set = "female";
      } else if (response_gender === "Female") {
        gender_set = "female";
      }

      // enquiry_status
// console.log('response.enquiry_form.enquiry_status.toString(),',response.enquiry_form.enquiry_status.toString());
      let response_enquiry_status= response.enquiry_form.enquiry_status;
  

      if (response_enquiry_status === "false" || response_enquiry_status === false) {
        response_enquiry_status = "active";
      } else if (response_enquiry_status === "true" || response_enquiry_status === true) {
        response_enquiry_status = "expire";
      }

      setFormInputs({
        name: response.enquiry_form.name,
        father_name: response.enquiry_form.father_name,


        decided_registration_fee: response.enquiry_form.decided_registration_fee,
        decided_course_fee: response.enquiry_form.decided_course_fee,
        note: response.enquiry_form.note,
        enquiry_status_reason: response.enquiry_form.enquiry_status_reason,
        // email: response.enquiry_form.email,
        // enquiry_status: response.enquiry_form.enquiry_status.toString(),
        enquiry_status: response_enquiry_status,

        gender: gender_set,

        mobile_number: response.enquiry_form.mobile_number,
        reference: response.enquiry_form.referred_by,
        // date_of_birth: response.enquiry_form.date_of_birth,
        // date_of_birth: defaultDate.toISOString().split("T")[0],
        // dob: defaultDate.toISOString().split("T")[0],
 
        latest_degree: response.enquiry_form.latest_degree,
        degree_status: response.enquiry_form.degree_status,
        completion_year: response.enquiry_form.completion_year,
        institution_name: response.enquiry_form.institution_name,
        semester: response.enquiry_form.semester,

         


        course: courseIds,
        others: [],
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  // const fetchActiveBatches = async (batchIds) => {
  //   const response = await listActiveBatch();

  //   if (response.code === 200) {
  //     const batchesList = response.batches.map((batch) => ({
  //       id: batch._id,
  //       name: batch.name,
  //       student_count: batch.student_count,
  //       status: batch.status,
  //       courses: batch.courses,
  //     }));
  //     // console.log(batchesList, "jjjjj");

  //     // console.log(batchIds[0], "kkkkkkkkk");

  //     const selectedBatchIndex = batchesList.findIndex(
  //       (batch) => batch.id === batchIds[0]
  //     );

  //     // console.log(selectedBatchIndex, "selectedBatchselectedBatch");
  //     // console.log("cccc");
  //     setCourses(batchesList[selectedBatchIndex].courses);

  //     setBatches(batchesList);
  //   } else {
  //     enqueueSnackbar(response.message, { variant: "error" });
  //   }
  // };
  const fetchActiveCourse = async () => {
    const response = await listCourse();
    console.log("response", response);
    if (response.code === 200) {
      // const batches = response.batches.map((batch) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   student_count: batch.student_count,
      //   status: batch.status,
      //   courses: batch.courses,
      // }));
      setCourses(response.courses);
      // setBatches(batches);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    // fetchActiveCourse();
  }, []);
  useEffect(() => {
    // fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Freeze Student">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
             Freeze Student
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
               
  
              <TextField
                fullWidth
                label="Reason"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.reason}
                onChange={handleChange}
                name="reason"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

         

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default FreezeFromStudent;
