import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Toolbar,
  InputAdornment,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import { _addEnquiryStudent } from "src/DAL/enquirystudent";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterEnquiryStudent from "./components/FilterEnquiryStudent";
import { useSnackbar } from "notistack";
import { _list_asset_category } from "src/DAL/asset_category";
import {
  _filter_company_assets,
  _list_company_asset,
} from "src/DAL/company_asset";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import CompanyAssetDetail from "./CompanyAssetDetail";
import { formatNormalDate } from "src/utils/formatTime";
import AssignCompanyAsset from "../AssignCompanyAsset/AssignCompanyAsset";
import FilterCompanyAsset from "./components/FilterCompanyAsset";
import AssetStatusUpdate from "./AssetStatusUpdate";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "title", label: "Name", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  { id: "quantity", label: "Quantity", alignRight: false },
  { id: "purchase_date", label: "Purchase Date", alignRight: false },
  { id: "asset_category_title", label: "Category", alignRight: false },
  { id: "vendor_name", label: "Vendor", alignRight: false },
  { id: "assign_to", label: "Assign To", alignRight: false },
  { id: "assigned_status", label: "Assign Status", alignRight: false },
  { id: "asset_status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Students() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [drawerState2, setDrawerState2] = useState(false);
  const [formType, setFormType] = useState("");

  const [search, setSearch] = useState("");
  const [assignableStatus, setAssignableStatus] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [assignedStatus, setAssignedStatus] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const myElementRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/company-asset/add-asset");
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const fetchStudents = async () => {
    setIsLoading(true);
    // const response = await _list_company_asset({
    //   text: "",
    // });
    const response = await _filter_company_assets({
      search: "",
      assignable_status: "",
      asset_status: null,
      assigned_status: "",

      // "search": search,
      // "assignable_status": assignableStatus,
      // "active_status": activeStatus,
      // "assigned_status": assignedStatus
    });

    console.log("response _filter_company_assets", response);
    if (response.code === 200) {
      const students = response.filter_company_assets.map((asset, index) => ({
        ...asset,
        id: asset._id,
        title: asset.title,
        device_id: asset.device_id,
        details: asset.details,
        image: asset.image,
        price: asset.price,
        quantity: asset.quantity,
        asset_status: asset.asset_status,
        assigned_status: asset.assigned_status,
        purchase_date: asset.purchase_date,
        warranty_expire_date: asset.warranty_expire_date,
        warranty_card_image: asset.warranty_card_image,
        receipt_image: asset.receipt_image,
        link: asset.link,
        vendor: asset.vendor,
        vendor_name: asset.vendor.name,
        brand: asset.brand,
        brand_title: asset.brand.title,
        asset_category: asset.asset_category,
        asset_category_title: asset.asset_category.title,
        assigned_history: asset.assigned_history,
        // student_name: asset.assigned_history.student.name,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const SearchStudents = async (searchText) => {
    let response = [];
    // setIsLoading(true);
    console.log("searchText 0 ___here", searchText);
    let search_text = searchText ? searchText : "";

    if (!search_text || search_text === "" || search_text === null) {
      search_text = "";

      response = await _filter_company_assets({
        search: "",
        assignable_status: assignableStatus,
        asset_status: activeStatus?activeStatus:null,
        // asset_status: activeStatus,
        assigned_status: assignedStatus,
      });
      console.log("response 1 ___here", response);
    } else {
      response = await _filter_company_assets({
        search: search_text,
        assignable_status: assignableStatus,
        asset_status: activeStatus?activeStatus:null,
        assigned_status: assignedStatus,
      });
      console.log("response 2 ___here", response);
    }

    // const response = await listStudentSearch(searchText);

    console.log("response filter_company_assets search", response);

    if (response.code === 200) {
      const students = response.filter_company_assets.map((asset, index) => ({
        ...asset,
        id: asset._id,
        title: asset.title,
        device_id: asset.device_id,
        details: asset.details,
        image: asset.image,
        price: asset.price,
        quantity: asset.quantity,
        asset_status: asset.asset_status,
        assigned_status: asset.assigned_status,
        purchase_date: asset.purchase_date,
        warranty_expire_date: asset.warranty_expire_date,
        warranty_card_image: asset.warranty_card_image,
        receipt_image: asset.receipt_image,
        link: asset.link,
        vendor: asset.vendor,
        vendor_name: asset.vendor.name,
        brand: asset.brand,
        brand_title: asset.brand.title,
        asset_category: asset.asset_category,
        asset_category_title: asset.asset_category.title,
        assigned_history: asset.assigned_history,
        // student_name: asset.assigned_history.student.name,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      setIsLoading(true);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  useEffect(() => {
    console.log("students", students);
  }, [students]);

  useEffect(() => {
    debouncedSearchStudents(searchText); // Call the debounced version of SearchStudents
  }, [searchText]);

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = (selectedRow) => {
    setselectedRowStudent(selectedRow);

    setDrawerState(true);
    setFormType("Assign Asset");
  };
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleOpenEditDrawer2 = (selectedRow) => {
    setselectedRowStudent(selectedRow);

    setDrawerState2(true);
    setFormType("Assign Asset");
  };
  const handleCloseEditDrawer2 = () => {
    setDrawerState2(false);
  };

  // useEffect(() => {
  //   fetchStudents();
  // }, [search,assignableStatus,activeStatus,assignedStatus]);

  useEffect(() => {
    fetchStudents();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="All Assets">
      <Container maxWidth="xl">
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "company_asset",
          "view"
        ) ? (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <Typography variant="h4">All Assets</Typography>
              {privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "company_asset",
                "add"
              ) ? (
                <Button
                  onClick={handleNav}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Asset
                </Button>
              ) : (
                ""
              )}
            </Stack>

            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

              <Scrollbar>
                <div className="container">
                  <div className="row">
                    <FilterCompanyAsset
                      search={search}
                      assignableStatus={assignableStatus}
                      activeStatus={activeStatus}
                      assignedStatus={assignedStatus}
                      setSearch={setSearch}
                      setAssignableStatus={setAssignableStatus}
                      setActiveStatus={setActiveStatus}
                      setAssignedStatus={setAssignedStatus}
                      fetchStudents={fetchStudents}
                      SearchStudents={SearchStudents}
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  </div>
                </div>
                {/* <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ padding: "10px" }}
                > */}
                {/* Filter Start*/}

                {/* Filter End*/}

                {/* <TextField
                    label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                  /> */}
                {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                {/* <CustomResetButton handleReset={handleReset} /> */}

                {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
                {/* </Stack> */}
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <StudentListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={students.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            id,
                            title,
                            device_id,
                            active_status,
                            assigned_status,
                            details,
                            image,
                            price,
                            quantity,
                            purchase_date,
                            warranty_expire_date,
                            warranty_card_image,
                            receipt_image,
                            link,
                            vendor,
                            vendor_name,
                            brand,
                            brand_title,
                            asset_category,
                            asset_category_title,
                            assigned_history,
                            asset_status ,
                            // student_name,
                            number,
                          } = row;
                          const isItemSelected = selected.indexOf(title) !== -1;
                          // let number = index + 1;
                          // let { course, others } = row;
                          // let titleToShow = others.length > 0 ? others[0].course_title : course[0].title;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, name)
                              }
                            /> */}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {number}
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                <div
                                  className="on_click_text"
                                  // style={{
                                  //   cursor: "pointer",
                                  // }}
                                  onClick={() => handleOpenModal(row)}
                                >
                                  {capitalizeFirstLetter(title)}
                                </div>
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {price}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {quantity}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {formatNormalDate(purchase_date)}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {asset_category_title}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {vendor_name}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {assigned_history.length > 0
                                  ? [...assigned_history]
                                      .reverse()
                                      .map((historyItem, index) => (
                                        <span key={historyItem._id}>
                                          {index === 0
                                            ? `${historyItem.person?.name}`
                                            : ""}
                                        </span>
                                      ))
                                  : "Not Assigned"}
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={assigned_status ? "warning" : "info"}
                                >
                                  {assigned_status ? "Assigned" : "Free"}
                                </Label>
                              </TableCell>

                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    asset_status === 1
                                      ? "success"
                                      : asset_status === 2
                                      ? "error"
                                      : asset_status === 3
                                      ? "warning"
                                      : asset_status === 4
                                      ? "info"
                                      : "default"
                                  }
                                >
                                  {asset_status === 1
                                    ? "Active"
                                    : asset_status === 2
                                    ? "Inactive"
                                    : asset_status === 3
                                    ? "Maintenance"
                                    : asset_status === 4
                                    ? "Sold"
                                    : "Unknown"}
                                </Label>
                              </TableCell>

                              {privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "company_asset",
                                "edit"
                              ) ||
                              privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "company_asset",
                                "delete"
                              ) ? (
                                <TableCell align="right">
                                  <StudentMoreMenu
                                    row={row}
                                    students={students}
                                    onStudentsUpdate={handleStudentsUpdate}
                                    handleOpenEditDrawer={handleOpenEditDrawer}
                                    handleCloseEditDrawer={
                                      handleCloseEditDrawer
                                    }
                                    handleOpenEditDrawer2={
                                      handleOpenEditDrawer2
                                    }
                                    handleCloseEditDrawer2={
                                      handleCloseEditDrawer2
                                    }
                                  />
                                </TableCell>
                              ) : (
                                <TableCell align="right"></TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={12} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: students.length, label: "All" },
                ]}
                component="div"
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : (
          <NoAccessData />
        )}
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Asset Assigning"
        componentToPassDown={
          <AssignCompanyAsset
            selectedRowStudent={selectedRowStudent}
            handleCloseEditDrawer={handleCloseEditDrawer}
            assignAssets={students}
            setAssignAssets={setStudents}
          />
          // <h1>hello</h1>
          // <FilterEnquiryStudent
          //   onCloseDrawer={handleCloseEditDrawer}
          //   isOpenDrawer={drawerState}
          //   students={students}
          //   setStudents={setStudents}
          // />
        }
      />

      <CustomDrawer
        isOpenDrawer={drawerState2}
        onOpenDrawer={handleOpenEditDrawer2}
        onCloseDrawer={handleCloseEditDrawer2}
        pageTitle="Status Update"
        componentToPassDown={
          <AssetStatusUpdate
            selectedRowStudent={selectedRowStudent}
            handleCloseEditDrawer={handleCloseEditDrawer2}
            assignAssets={students}
            setAssignAssets={setStudents}
          />
          // <h1>hello</h1>
          // <FilterEnquiryStudent
          //   onCloseDrawer={handleCloseEditDrawer}
          //   isOpenDrawer={drawerState}
          //   students={students}
          //   setStudents={setStudents}
          // />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Asset Detail"
        componentToPassDown={
          <div className="mt-5">
            <CompanyAssetDetail selectedRow={selectedRowStudent} />
          </div>
        }
      />
    </Page>
  );
}
