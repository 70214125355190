import * as React from "react";
// import { PieChart } from "@mui/x-charts/PieChart";
import {
  PieChart,
  SparkLineChart,
  LineChart,
  ChartContainer,
  LinePlot,
  MarkPlot,
  ChartsReferenceLine,
  ChartsXAxis,
  ChartsYAxis,
} from "@mui/x-charts";
import { useState } from "react";
import CustomModal from "./CustomModal";
export default function CustomLineChart({ income_graph, expense_graph, type  }) {
  console.log("income_graph   __income_graph", income_graph);
  console.log("income_graph   __income_graph typeof", typeof income_graph);

  const income_amount = income_graph.map((item) => item.amount);
  const percentage = income_graph.map((item) => item.percentage);
  const expense_amount = expense_graph.map(item => item.amount);


 

//   const expense_amount = expense_graph.map(item => {
//     if (item.amount <= 0) {
//         return null; // return null if amount is less than or equal to 0
//     } else {
//         return item.amount; // return the amount if it's greater than 0
//     }
// });
// let lastNonZeroIndex = -1;
// let foundNonZero = false;

// // Find the last non-zero index
// for (let i = expense_graph.length - 1; i >= 0; i--) {
//     if (expense_graph[i].amount > 0) {
//         lastNonZeroIndex = i;
//         foundNonZero = true;
//         break;
//     }
// }

// // Set values according to the condition
// const expense_amount = expense_graph.map((item, index) => {
//     if (foundNonZero && index > lastNonZeroIndex) {
//         return null; // Set to null if found a non-zero value and it's after the last non-zero value
//     } else if (foundNonZero && index <= lastNonZeroIndex && item.amount <= 0) {
//         return null; // Set to null if found a zero value after the last non-zero value
//     } else {
//         return item.amount; // Otherwise, return the original amount
//     }
// });

  

  const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  const xLabels = [
    "Page A",
    "Page B",
    "Page C",
    "Page D",
    "Page E",
    "Page F",
    "Page G",
  ];
  const years = [
    new Date(1990, 0, 1),
    new Date(1991, 0, 1),
    new Date(1992, 0, 1),
    new Date(1993, 0, 1),
    new Date(1994, 0, 1),
    new Date(1995, 0, 1),
    new Date(1996, 0, 1),
    new Date(1997, 0, 1),
    new Date(1998, 0, 1),
    new Date(1999, 0, 1),
    new Date(2000, 0, 1),
    new Date(2001, 0, 1),
    new Date(2002, 0, 1),
    new Date(2003, 0, 1),
    new Date(2004, 0, 1),
    new Date(2005, 0, 1),
    new Date(2006, 0, 1),
    new Date(2007, 0, 1),
    new Date(2008, 0, 1),
    new Date(2009, 0, 1),
    new Date(2010, 0, 1),
    new Date(2011, 0, 1),
    new Date(2012, 0, 1),
    new Date(2013, 0, 1),
    new Date(2014, 0, 1),
    new Date(2015, 0, 1),
    new Date(2016, 0, 1),
    new Date(2017, 0, 1),
    new Date(2018, 0, 1),
    new Date(2019, 0, 1),
    new Date(2020, 0, 1),
    new Date(2021, 0, 1),
    new Date(2022, 0, 1),
    new Date(2023, 0, 1),
    // new Date(2024, 0, 1),
  ];

  const FranceGDPperCapita = [
    28129, 28294.264, 28619.805, 28336.16, 28907.977, 29418.863, 29736.645,
    30341.807, 31323.078, 32284.611, 33409.68, 33920.098, 34152.773, 34292.03,
    35093.824, 35495.465, 36166.16, 36845.684, 36761.793, 35534.926, 36086.727,
    36691, 36571, 36632, 36527, 36827, 37124, 37895, 38515.918, 39100, 39200,
    39300, 39400, 39500,
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const months2 = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear(); // Get the current year

  // Create an array of dates with months from January to December of the current year
  const monthsInCurrentYear = Array.from(Array(12).keys()).map(
    (monthIndex) => new Date(currentYear, monthIndex, 1)
  );

  return (
    <> 
      <LineChart
   
        // xAxis={[
        //   {
        //     id: 'Years',
        //     data: years,
        //     scaleType: 'time',
        //     valueFormatter: (date) => date.getFullYear().toString(),
        //   },
        // ]}
        xAxis={[
          {
            id: "Months",
            data: monthsInCurrentYear,
            scaleType: "time",
            valueFormatter: (date) => months[date.getMonth()],
          },
        ]}
        // xAxis={[{ scaleType: 'point', data: xLabels }]}
        series={[
          {
            id: "Income",
            label: "Income",
            data: income_amount,
            // stack: 'total',
            area: false,
            showMark: false,
            // color: "Green",
            // color: "#00ff00",
            color: "#07ff00",
          },
          {
            id: "Expense",
            label: "Expense",
            data: expense_amount,
            // stack: 'total',
            area: false,
            showMark: false,
            // color: "orange",
            // color: "#0c2e60",
            color: "red",
          },
        ]}
        width={type===1?1000:1000}
        height={type===1?465:300}
        margin={{ left: 70 }}
      />

      {/* <LineChart
    //   xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      series={[
        {
          data: [2, 5.5, 2, 8.5, 1.5, 5],
          area: true,
        },
      ]}
      area
      width={500}
      height={300}
    /> */}

      {/* <ChartContainer
      width={500}
      height={300}
      series={[
        { data: pData, label: 'pv', type: 'line' },
        { data: uData, label: 'uv', type: 'line' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
    >

        
      <LinePlot />
      <MarkPlot />
      <ChartsReferenceLine
        x="Page C"
        label="Max PV PAGE"
        lineStyle={{ stroke: 'red' }}
      />
      <ChartsReferenceLine y={9800} label="Max" lineStyle={{ stroke: 'red' }} />
      <ChartsXAxis />
      <ChartsYAxis />
    </ChartContainer> */}
 
    </>
  );
}
