import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  Zoom,
  Tooltip,
  TableHead,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndtextEmptyToReturnHyphen,
  textEmptyToReturnHyphen,
  truncateString,
} from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import { _detail_contact } from "src/DAL/contact";
import { useAppContext } from "src/hooks";

const ContactDetail = ({
  studentID,
  contact,
  setContact,
  newContactCount,
  setNewContactCount,
  type,
}) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [currency, setCurrency] = useState("Rs");

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const {
    _set_user_privilege_access,
    _get_user_profile,
    _get_user_privilege_access,
    _set_user_profile,
    _get_contact_count,
    _set_contact_count,
  } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();
  const [getContactCount, setGetContactCount] = useState(_get_contact_count());


  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };
  const myStyleHeaderCenter = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    textAlign: "center", // Align text in the center
  };
  const myStyle7 = {
    textTransform: "capitalize",
    textAlign: "center", // Align text in the center
  };
  const myStyleHeader2 = {
    // textTransform: "capitalize",
    fontWeight: 800,
    fontSize: "1.3rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
    textAlign: "center", // Align text in the center
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",
    student_status: "",
    education: "",
    photo: "",
    batches: [],
    batch_fee: [],
    student_vouchers: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",
    discount: 0,

    registration_fee_discount: 0,
    fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount_reason: "",

    fee_status: "all_paid",
    batch_name: "",
    registration_voucher: null,

    name: "",
    phone: "",
    course: {
      _id: "",
      title: "",
    },
    message: "",
    read_status: true,
    createdAt: "",
    updatedAt: "",
  });

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_contact(studentID);
    console.log(
      "response response _detail_contact detail _detail_contact",
      response
    );
    if (response.code === 200) {
      setFormInputs({
        ...response.contact,
        name: response.contact.name,
        phone: response.contact.phone,
        course: response.contact.course,
        message: response.contact.message,
        read_status: response.contact.read_status,
        createdAt: response.contact.createdAt,
        updatedAt: response.contact.updatedAt,
      });

      if ( type === 2) {

        const updatedContact = contact.filter(
          (contact) => contact._id !== studentID
        );
        setContact(updatedContact);

        let newCount = newContactCount;
        newCount = newContactCount - 1;
        setNewContactCount(newCount); 
        _set_contact_count(newCount);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh', // Optional to center vertically in the viewport
  };
  return (
    <Page title="Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <div className="container ">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Phone
                  </TableCell>
                  <TableCell align="left">0{formInputs.phone}</TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Course
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.course.title)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Message
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.message)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.createdAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.updatedAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
};

export default ContactDetail;
