import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Toolbar,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  MenuItem,
  CircularProgress,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import { _addEnquiryStudent } from "src/DAL/enquirystudent";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterEnquiryStudent from "./components/FilterEnquiryStudent";
import { useSnackbar } from "notistack";
import { _list_vendor } from "src/DAL/vendor";
import {
  _add_fee_voucher,
  _filter_voucher,
  _list_fee_voucher,
  _student_fee_listing,
} from "src/DAL/voucher";
import moment from "moment";
import { _batch_students, listBatch } from "src/DAL/batch";
import { LoadingButton } from "@mui/lab";
import DataNotFound from "src/components/GeneralComponents/DataNotFound";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import BatchStudentDetail from "src/pages/Batches/List/BatchStudentDetail";
import VoucherDetail from "./VoucherDetail";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "student", label: "Student Name", alignRight: false },
  { id: "contact_number", label: "Phone Number", alignRight: false },
  { id: "batch", label: "Batch", alignRight: false },
  { id: "batch_title", label: "Title", alignRight: false },
  { id: "batch_fee", label: "Fee", alignRight: false },
  { id: "generated_by", label: "Gen By", alignRight: false },
  { id: "generation_time", label: "Gen Time", alignRight: false },
  { id: "due_date", label: "Due Date", alignRight: false },
  // { id: "address", label: "Address", alignRight: false },
  // { id: "active_status", label: "Status", alignRight: false },
  { id: "voucher_type", label: "Voucher Type", alignRight: false },
  { id: "voucher_status", label: "Voucher Status", alignRight: false },

  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FilterVouchers() {
  const myStyle = {
    textTransform: "capitalize",
  };

  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingStudent, setIsLoadingStudent] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [batches, setBatches] = useState([]);
  const [batcheFees, setbatcheFees] = useState([]);
  const [batcheStudents, setBatcheStudents] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const myElementRef = useRef(null);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: "true",
    registration_fee: 0,
    batch_discount: 0,
    batch_fee_id: "",
    additional_discount: 0,
    additional_discount_reason: "",

    voucher_type: 0,
    voucher_status: 0,
    batch_id: [],
    batch_id_changes: [],
    batch_students_ids: [],
    // batch_students_ids_changes: [],

    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
  });

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRowObj) {
    setOpenModal(true);
    setSelectedRow(selectedRowObj);
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
        if (!arraysAreEqual(arr1[i], arr2[i])) {
          return false;
        }
      } else if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  const handleBatchClose = () => {
    if (
      formInputs.batch_id.length <= 0 &&
      formInputs.batch_id_changes.length > 0
    ) {
      console.log("formInputs.batch_id", typeof formInputs.batch_id);
      console.log(
        "formInputs.batch_id_changes",
        typeof formInputs.batch_id_changes
      );
      setFormInputs({
        ...formInputs,
        // [target.name]: target.value,
        //   batch_students_ids: [foundObject.students[0]._id],
        // batch_students_ids: [],
        // batch_id_changes: formInputs.batch_id,
        batch_id_changes: [],
      });
    } else if (formInputs.batch_id.length > 0) {
      console.log("formInputs.batch_id", typeof formInputs.batch_id);
      console.log(
        "formInputs.batch_id_changes",
        typeof formInputs.batch_id_changes
      );

      if (arraysAreEqual(formInputs.batch_id, formInputs.batch_id_changes)) {
        console.log("The arrays have the same contents.");
      } else {
        console.log("The arrays have different contents.");
        setFormInputs({
          ...formInputs,
          // [target.name]: target.value,
          //   batch_students_ids: [foundObject.students[0]._id],
          // batch_students_ids: [],
          batch_id_changes: formInputs.batch_id,
        });
      }
    }

    // fetchBatchStudents(formInputs.batch_id);
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    console.log("e __e", e);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      //   batch_students_ids: [foundObject.students[0]._id],
      batch_students_ids: [],
    });
    // const textFieldRef = target.closest('.MuiTextField-root');
    // if (textFieldRef) {
    //   textFieldRef.click();
    //   fetchBatchStudents(target.value);
    // }

    // fetchBatchStudents(target.value);

    // Use Array.prototype.find to locate the desired object
    // let foundObject = batches.find((obj) => obj.id === target.value);

    // if (foundObject) {
    //   setBatcheStudents(foundObject.students);
    //   if (foundObject.students.length > 0) {
    //     setFormInputs({
    //       ...formInputs,
    //       [target.name]: target.value,
    //       batch_students_ids: [foundObject.students[0]._id],
    //     });
    //   }
    // } else {
    //   setbatcheFees([]);
    //   setFormInputs({
    //     ...formInputs,
    //     [target.name]: target.value,
    //     batch_students_ids: [],
    //   });
    // }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/vouchers/add-voucher");
  };

  function formatDate(dateStr) {
    if (dateStr !== null) {
      const date = new Date(dateStr);

      const options = { day: "2-digit", month: "long", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);

      return formattedDate;
    } else {
      const date = new Date();

      const options = { day: "2-digit", month: "long", year: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);

      return formattedDate;
    }
  }
  function formatTime(timestamp) {
    const formattedTime = moment(timestamp).format("hh:mm A");
    return formattedTime;
  }
  // function formatTime(startTime) {
  //   // Parse the time strings using moment
  //   const startMoment = moment(startTime, "HH:mm");
  //   // Format the start time and end time with AM/PM
  //   const formattedStartTime = startMoment.format("hh:mm A");
  //   const formattedTime = `${formattedStartTime}`;
  //   return formattedTime;
  // }
  const fetchStudents = async (postDataFilter) => {
    setIsLoading(true);
    const response = await _filter_voucher(postDataFilter);

    console.log("response _list_fee_voucher", response);
    if (response.code === 200) {
      const vouchers = response.vouchers.map((voucher, index) => {
        let feeTitle; // Initialize feeTitle variable

        if (voucher.voucher_type === 1) {
          feeTitle = "Registration";
        } else if (voucher.fee_title) {
          feeTitle = voucher.fee_title;
        } else {
          feeTitle = " _ _ ";
        }

        return {
          ...voucher, // Spread the original student object
          id: voucher._id, // Override the id property
          fee_title: feeTitle, // Set the fee_title based on the condition
          number: index + 1, // Override the number property
        };
      });

      // const students = response.list_fee_voucher.map((student, index) => ({
      //   id: student._id,
      //   name: student.name,
      //   email: student.email,
      //   contact_number: student.contact_number,
      //   address: student.address,
      //   active_status: student.active_status,

      //   number: index + 1,
      // }));
      // setStudents(students.reverse());
      setStudents(vouchers);
      setIsLoading(false);
      setSearchText("");
      if (vouchers.length > 0) {
        setNotFound(false);
      } else {
        setNotFound(true);
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
      setNotFound(true);
    }
  };

  const SearchStudents = async (searchText) => {
    let response = [];
    // setIsLoading(true);
    let search_text = searchText;

    if (!search_text || search_text === "" || search_text === null) {
      search_text = "";
      response = await _list_fee_voucher();
    } else {
      response = await _list_fee_voucher({
        text: search_text,
      });
    }

    // const response = await listStudentSearch(searchText);

    console.log("response _addEnquiryStudent", response);

    if (response.code === 200) {
      // const students = response.list_fee_voucher.map((student, index) => ({
      //   id: student._id,
      //   name: student.name,
      //   email: student.email,
      //   contact_number: student.contact_number,
      //   address: student.address,
      //   active_status: student.active_status,
      //   number: index + 1,
      // }));
      const vouchers = response.list_fee_voucher.map((voucher, index) => ({
        ...voucher, // Spread the original student object
        id: voucher._id, // Override the id property
        number: index + 1, // Override the number property
      }));
      // setStudents(students.reverse());
      setStudents(vouchers);
      if (vouchers.length > 0) {
        setNotFound(false);
      } else {
        setNotFound(true);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
      setNotFound(true);
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      setIsLoading(true);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    setFormInputs({
      ...formInputs,
      voucher_type: 0,
      voucher_status: 0,
      batch_id: [],
      batch_id_changes: [],
      batch_students_ids: [],
    });
    setStudents([]);
    fetchDefaultStudents();
    setNotFound(false);

    fetchStudents({
      batch: [],
      student: [],
      voucher_type: [],
      voucher_status: [],
    });

    console.log("Reset");
  };
  // const handleReset = () => {
  //   setSearchText("");
  //   // SearchStudents("");
  //   fetchStudents();
  //   // Reset any search results or filters
  //   console.log("Reset");
  // };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  useEffect(() => {
    console.log("students", students);
  }, [students]);

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    let postData = {
      batch: formInputs.batch_id,
      student: formInputs.batch_students_ids,
      voucher_type:
        formInputs.voucher_type === 0 ? [] : [formInputs.voucher_type],
      voucher_status:
        formInputs.voucher_status === 0 ? [] : [formInputs.voucher_status],
    };

    console.log("postData", postData);

    fetchStudents(postData);
    // let feeDiscount = formInputs.additional_discount;
    // let feeDiscountReason = formInputs.additional_discount_reason;

    // if (typeof feeDiscount === "string") {
    //   feeDiscount = parseInt(feeDiscount, 10); // Use 10 as the radix for decimal numbers
    // }

    // if (!feeDiscountChecked) {
    //   feeDiscount = 0;
    //   feeDiscountReason = "";
    // }

    // let postData = {
    //   batch_id: formInputs.batch_id,
    //   batch_fee_id: formInputs.batch_fee_id,
    //   due_date: formattedStartDate,
    //   additional_discount: feeDiscount,
    //   additional_discount_reason: feeDiscountReason,
    // };
    // console.log("postData _test", postData);

    // setIsLoading(true);
    // const result = await _add_fee_voucher(postData);
    // if (result.code === 200) {
    //   setIsLoading(false);
    //   navigate("/vouchers");
    //   enqueueSnackbar("Added Successfully!", { variant: "success" });
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(result.message, { variant: "error" });
    // }
  };

  const fetchBatchStudents = async (batchesData) => {
    // setIsLoading(true);
    setIsLoadingStudent(true);
    const response = await _batch_students({
      batches: batchesData,
    });

    console.log("response ___listStudent", response);
    if (response.code === 200) {
      const students = response.students.map((student, index) => ({
        id: student._id,
        _id: student._id,
        name: student.name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: student.father_name,
        address: student.address,
        reg_type: student.reg_type ? student.reg_type : "",
        contact_number: student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        total_fee: student.total_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        user_id: student.user_id ? student.user_id : "0",
        number: index + 1,
        // time: formatTime(
        //   student.time_preference.start_time,
        //   student.time_preference.end_time
        // ),
      }));

      setBatcheStudents(students);
      setIsLoadingStudent(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoadingStudent(false);
    }
  };

  const fetchBatches = async () => {
    // setIsLoading(true);
    const response = await listBatch();
    console.log("response listBatch _listBatch _test", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              students: batch.students,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              batch_fee: batch.batch_fee ? batch.batch_fee : [],
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      console.log("batches  _test", batches);
      console.log("batches[0]  _test", batches[0]);
      console.log("batches[0].batch_fee  _test", batches[0].batch_fee);
      setBatches(batches);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const fetchDefaultStudents = async () => {
    setIsLoadingStudent(true);
    const response = await listStudent();

    console.log("response  _student_fee_listing _test", response);

    if (response.code === 200) {
      const students = response.students
        .map((student, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (student.status === 1) {
            return {
              ...student,
              id: student._id,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }

      setBatcheStudents(students);
      setIsLoadingStudent(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoadingStudent(false);
    }
  };

  useEffect(() => {
    fetchBatches();
    fetchStudents({
      batch: [],
      student: [],
      voucher_type: [],
      voucher_status: [],
    });
    fetchDefaultStudents();
  }, []);

  useEffect(() => {
    console.log("formInputs ___formInputs", formInputs);
    // if(formInputs.batch_id !==formInputs.batch_id_changes){
    // }
    if (formInputs.batch_id.length > 0) {
      fetchBatchStudents(formInputs.batch_id);
    } else {
      fetchDefaultStudents();
    }
  }, [formInputs.batch_id_changes]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Vouchers">
      <Container maxWidth="xl">
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "fee_voucher",
          "view"
        ) ? (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <Typography variant="h4">Vouchers</Typography>
              {/* <h4 className="title_heading_in_all_pages_top_left"> Vouchers</h4> */}
              {/* <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Voucher
          </Button> */}
            </Stack>

            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                    <TextField
                      fullWidth
                      label="Batch"
                      select
                      value={formInputs.batch_id}
                      onChange={handleChange2}
                      name="batch_id"
                      //   SelectProps={{ multiple: true }}
                      // style={myStyle} // Apply the style here
                      SelectProps={{
                        multiple: true,
                        onClose: handleBatchClose, // Call your function here
                      }}
                      //   required
                    >
                      {batches.map((batch) => (
                        <MenuItem key={batch.id} value={batch.id}>
                          {capitalizeFirstLetter(batch.name)}
                        </MenuItem>
                      ))}
                    </TextField>

                    {/* <TextField
                  fullWidth
                  label="Batch Student"
                  select
                  value={formInputs.batch_students_ids}
                  onChange={handleChange}
                  name="batch_students_ids"
                  SelectProps={{ multiple: true }}
                  // style={myStyle}
                  //   required
                >
                  {batcheStudents.map((batchestudent) => (
                    <MenuItem key={batchestudent._id} value={batchestudent._id}>
                      {capitalizeFirstLetter(batchestudent.name)} (+92{batchestudent.contact_number})
                    </MenuItem>
                  ))}
                </TextField> 
                <CircularProgress /> */}
                    <div style={{ position: "relative" }}>
                      {isLoadingStudent && (
                        <CircularProgress
                          style={{
                            position: "absolute",
                            top: "15%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      )}
                    </div>
                    <TextField
                      fullWidth
                      label="Batch Student"
                      select
                      value={formInputs.batch_students_ids}
                      onChange={handleChange}
                      name="batch_students_ids"
                      SelectProps={{ multiple: true }}
                      // style={myStyle}
                      //   required
                      disabled={isLoadingStudent}
                    >
                      {batcheStudents.map((batchestudent) => (
                        <MenuItem
                          key={batchestudent._id}
                          value={batchestudent._id}
                        >
                          {capitalizeFirstLetter(batchestudent.name)}
                          {/* { ( capitalizeFirstLetter(batchestudent.father_name) )}  */}
                          {/* (+92 {batchestudent.contact_number}) */}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
                    <TextField
                      fullWidth
                      label="Voucher Type"
                      select
                      // required
                      value={formInputs.voucher_type}
                      onChange={handleChange}
                      name="voucher_type"
                    >
                      <MenuItem value={0}>All</MenuItem>
                      <MenuItem value={1}>Registration</MenuItem>
                      <MenuItem value={2}>Monthly</MenuItem>
                      <MenuItem value={3}>Custom</MenuItem>
                    </TextField>
                    <div></div>

                    <TextField
                      fullWidth
                      label="Voucher Status"
                      select
                      // required
                      value={formInputs.voucher_status}
                      onChange={handleChange}
                      name="voucher_status"
                    >
                      <MenuItem value={0}>All</MenuItem>
                      <MenuItem value={1}>Pending</MenuItem>
                      <MenuItem value={2}>Paid</MenuItem>
                      <MenuItem value={3}>Due</MenuItem>
                      <MenuItem value={4}>Refund</MenuItem>
                    </TextField>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    // sx={{ padding: "10px" }}
                  >
                    <TextField
                      label="Search"
                      value={searchText}
                      onChange={handleInputChange}
                    />

                    <CustomResetButton handleReset={handleReset} />

                    <Button type="submit" variant="contained">
                      Filter
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Grid>

            {students.length > 0 ? (
              <Card
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  mb: 6,
                  mt: 3,
                }}
              >
                {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

                <Scrollbar>
                  {/* <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ padding: "10px" }}
            >
              <TextField
                label="Search"
                value={searchText}
                onChange={handleInputChange}
              />
              <Button variant="contained" onClick={handleSearch}>
                Search
              </Button>
              <Button variant="contained" onClick={handleReset}>
                Reset
              </Button>

              <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button>
            </Stack> */}
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <StudentListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={students.length}
                        numSelected={selected.length}
                        // onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />

                      <TableBody>
                        {filteredStudents
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            // const {
                            //   id,
                            //   name,
                            //   email,
                            //   contact_number,
                            //   address,
                            //   active_status,
                            //   number,
                            // } = row;
                            // const isItemSelected = selected.indexOf(name) !== -1;
                            // let number = index + 1;
                            // let { course, others } = row;
                            // let titleToShow = others.length > 0 ? others[0].course_title : course[0].title;

                            return (
                              <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                // selected={isItemSelected}
                                // aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, name)
                              }
                            /> */}
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  {row.number}
                                  {/* {index+1} */}
                                </TableCell>

                                {/* <TableCell align="left" style={myStyle}>
                              {row.student.name}
                            </TableCell> */}

                                {/* <TableCell
                              pref={myElementRef}
                              align="left"
                              style={myStyle}
                            >
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={() => handleOpenModal(row)}
                              >
                                {row.student.name}
                              </button>
                            </TableCell> */}

                                <TableCell align="left">
                                  <div
                                    // style={{
                                    //   cursor: "pointer",
                                    // }}
                                    className="on_click_text"
                                    onClick={() => handleOpenModal(row)}
                                  >
                                    {capitalizeFirstLetter(row.student.name)}
                                  </div>
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  +92{row.student.contact_number}
                                </TableCell>

                                <TableCell align="left">
                                  {capitalizeFirstLetter(row.batch.name)}
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  {row.fee_title}
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  {row.fee}
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  {row.generated_by.first_name}{" "}
                                  {row.generated_by.last_name}
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  {formatTime(row.generation_time)}
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  {formatDate(row.due_date)}
                                </TableCell>

                                {/* <TableCell align="left">
                                  <Label
                                    variant="outlined"
                                    color={
                                      (row.status === false && "error") ||
                                      "success"
                                    }
                                  >
                                    {row.status ? "Active" : "Inactive"}
                                  </Label>
                                </TableCell> */}

                                <TableCell align="left" style={myStyle}>
                                  <Label
                                    variant="outlined"
                                    color={
                                      ((row.voucher_type === 1 ||
                                        row.voucher_type === 2) &&
                                        "info") ||
                                      "warning"
                                    }
                                  >
                                    {row.voucher_type === 1
                                      ? "Registration"
                                      : row.voucher_type === 2
                                      ? "Monthly"
                                      : "Custom"}
                                  </Label>
                                </TableCell>

                                <TableCell align="left">
                                  <Label
                                    variant="outlined"
                                    color={
                                      ((row.voucher_status === 1 ||
                                        row.voucher_status === 3) &&
                                        "warning") ||
                                      (row.voucher_status === 4 && "info") ||
                                      "success"
                                    }
                                  >
                                    {row.voucher_status === 1
                                      ? "Pending"
                                      : row.voucher_status === 3
                                      ? "Due"
                                      : row.voucher_status === 4
                                      ? "Refund"
                                      : "Paid"}
                                  </Label>
                                </TableCell>

                                {privilegesTypeCheckAndShowOrNot(
                                  get_user_profile,
                                  get_user_privilege_access,
                                  "fee_voucher",
                                  "edit"
                                ) || (privilegesTypeCheckAndShowOrNot(
                                  get_user_profile,
                                  get_user_privilege_access,
                                  "fee_voucher",
                                  "delete" 
                                ) && row.voucher_status === 1 )? (
                                  <TableCell align="right">
                                    <StudentMoreMenu
                                      row={row}
                                      students={students}
                                      onStudentsUpdate={handleStudentsUpdate}
                                    />
                                  </TableCell>
                                ) : (
                                  <TableCell align="right"></TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[
                    50,
                    100,
                    150,
                    { value: students.length, label: "All" },
                  ]}
                  component="div"
                  count={students.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            ) : notFound === true ? (
              <Card
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  mb: 6,
                  mt: 3,
                  p: 3,
                  textAlign: "center", // Add this line to center the text
                }}
              >
                <Scrollbar>
                  {/* <h4>Data Not Found!</h4> */}
                  <DataNotFound
                    searchQuery={searchText === "" ? "Voucher!" : searchText}
                  />
                </Scrollbar>
              </Card>
            ) : (
              ""
            )}
          </>
        ) : (
          <NoAccessData />
        )}
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterEnquiryStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}
          />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Voucher Detail"
        componentToPassDown={<VoucherDetail studentID={selectedRow.id} />}
      />
    </Page>
  );
}
