import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
  Zoom,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent, listStudentSearch } from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import { _list_attendance } from "src/DAL/attendance";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndtextEmptyToReturnHyphen,
  truncateString,
} from "src/utils/formatText";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useSnackbar } from "notistack";
import { _list_assignment } from "src/DAL/assignment";
import {
  formatDateTimeWithZone,
  formatDateWithDay,
  formatDateWithDayInCSV,
} from "src/utils/formatTime";
import { _list_leave_request_for_admin } from "src/DAL/leave_requests";

import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import { _list_expense_category } from "src/DAL/expense_category";
import { _list_expense } from "src/DAL/expense";
import ExpenseTopUpDetail from "./ExpenseTopUpDetail";
import { _list_top_up } from "src/DAL/expense_top_up";
import CustomAmountCard from "src/components/GeneralComponents/CustomAmountCard";
import FilterExpenseTopUp from "../FilterExpenseTopUp/FilterExpenseTopUp";
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "paid_by", label: "Paid By", alignRight: false },
  { id: "paid_to", label: "Paid To", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "addedby", label: "Added By", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },

  { id: "action", label: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ExpenseTopUpList() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const moment = require("moment");
  const [order, setOrder] = useState("asc");
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [subTotalCash, setSubTotalCash] = useState(0);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [trashStudentCount, setTrashStudentCount] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  const [filterExpenseCategory, setFilterExpenseCategory] = useState("");
  const [filterPaymentMethod, setFilterPaymentMethod] = useState("");
  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const paymentMethodLabels = {
    cash: "Cash",
    cheque: "Cheque Deposit",
    online_transfer: "Online Transfer",
  };

  // function handleOpenModal(selectedRow) {
  //   setOpenModal(true);
  //   setselectedRowStudent(selectedRow);
  // }

  function handleOpenModal(selectedRow, event) {
    if (event.target.classList.contains("last-cell-class")) {
      // Handle click for the last cell separately
      // Add your logic for the last cell click here
    } else if (event.target.classList.contains("cell_onclick_effect")) {
      setOpenModal(true);
      setselectedRowStudent(selectedRow);
    }
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;


  const generateCSV = () => {
    let csvContent = 'Number,Date,Title,Amount,Paid By,Paid To,Payment Method,Added By\n';
  
    students.forEach((row, index) => {
      const rowData = [
        index + 1,
        formatDateWithDayInCSV(row.date),
        row.title,
        row.amount,
        capitalizeFirstLetter(row.transfer_by.name),
        capitalizeFirstLetter(row.transfer_to.name),
        paymentMethodLabels[row.payment_method],
        capitalizeFirstLetter(row.added_by.name)
      ];
      csvContent += rowData.join(',') + '\n';
    });
  
    return csvContent;
  };
  
  
  const handleDownloadCSV = () => {
    const csvContent = generateCSV(); // Function to generate CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  
    // Create a temporary anchor element
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'expense_top_up.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  
  const handleNav = () => {
    navigate("/top-up/add-top-up");
  };

  function formatTime(Time) {
    // Parse the time strings using moment
    const startMoment = moment(Time, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime}`;

    return formattedTime;
  }

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _list_top_up({
      added_by: filterExpenseCategory,
      transfer_by: filterPaymentMethod,
      date_from: filterDateFrom,
      date_to: filterDateTo,
    });

    console.log("response _list_top_up", response);
    if (response.code === 200) {
      setSubTotalCash(response.top_up_balance);
      setStudents(response.list_top_up);
      setIsLoading(false);
    } else {
      setSubTotalCash(0);
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const SearchStudents = async (searchText) => {
    // setIsLoading(true);
    const response = await _list_top_up({
      // text: searchText,
      added_by: "",
      transfer_by: "",
      date_from: "",
      date_to: "",
    });

    if (response.code === 200) {
      setSubTotalCash(response.top_up_balance);
      setStudents(response.list_top_up);
      setIsLoading(false);
    } else {
      setSubTotalCash(0);
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchStudentsFilter = async () => {
    setIsLoadingButton(true);
    const response = await _list_top_up({
      added_by: filterExpenseCategory,
      transfer_by: filterPaymentMethod,
      date_from: filterDateFrom,
      date_to: filterDateTo,
    });

    console.log("response _list_top_up", response);
    if (response.code === 200) {
      setSubTotalCash(response.top_up_balance);
      setStudents(response.list_top_up);
      setIsLoadingButton(false);
    } else {
      setSubTotalCash(0);
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoadingButton(false);
    }
  };
  const handleFilter = () => {
    fetchStudentsFilter();
  };
  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf", typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Tue, Sep 05, 2023"
    const formattedDate = parsedDate.format("ddd, MMM DD, YYYY");

    return formattedDate;
  }
  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Top Up">
      <Container maxWidth="xl">
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "expense",
          "view"
        ) ? (
          <>
            <div
              className="row  "
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="container  ">
                <div className="row">
                  <div className="col-12 col-lg-4 mt-4">
                    <Typography variant="h4">Top Up</Typography>
                  </div>
                  <div className="col-12 col-lg-4"></div>
                  <div className="col-12 col-lg-4">
                    <CustomAmountCard
                      Title={`Rs. ${subTotalCash} /-`}
                      Subtitle={"Sub Total Cash"}
                      className="all-amount-cards"
                      // handleCardClick={handleCardClick}
                      // onClick={(event) => handleCardClick(event, voucher)}
                      // Icon={getIcon("mdi:cash")}
                      // Icon={"mdi:cash"}
                      Icon={"noto:dollar-banknote"}
                      // arcticons:everydollar
                      colorType={2}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Filter Start*/}
            <div className="row">
              <div className="container">
                <FilterExpenseTopUp
                  filterExpenseCategory={filterExpenseCategory}
                  setFilterExpenseCategory={setFilterExpenseCategory}
                  filterPaymentMethod={filterPaymentMethod}
                  setFilterPaymentMethod={setFilterPaymentMethod}
                  filterDateFrom={filterDateFrom}
                  setFilterDateFrom={setFilterDateFrom}
                  filterDateTo={filterDateTo}
                  setFilterDateTo={setFilterDateTo}
                  fetchStudents={SearchStudents}
                />
              </div>
            </div>
            {/* Filter End*/}

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={1.5}
              mt={0}
            >
              {/* <Typography variant="h4">Top Up</Typography> */}
              <div></div>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                {/* <Button
              onClick={handleMoveToTrash}
              variant="contained"
              color="error" // Set the color to "secondary" for red color
              startIcon={<Iconify icon="bi:trash" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              ( {trashStudentCount} ) Trash
            </Button> */}

                <LoadingButton
                  // size="medium"
                  variant="contained"
                  loading={isLoadingButton}
                  // sx={{ width: "120px" }}
                  endIcon={<Iconify icon="ri:filter-fill" />}
                  onClick={handleFilter}
                >
                  Filter
                </LoadingButton>

                {privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "expense",
                  "add"
                ) ? (
                  <>
                    <Button
                      onClick={handleNav}
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      New Top Up
                    </Button>
                  </>
                ) : (
                  ""
                )}

                <Button
                  onClick={handleDownloadCSV}
                  variant="contained"
                  startIcon={<Iconify icon="material-symbols:download" />}
                >
                  Download CSV
                </Button>
              </Stack>
            </Stack>

            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

              <Scrollbar>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ padding: "10px" }}
                >
                  {/* <TextField
                    label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                  /> */}
                  {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                  {/* <CustomResetButton handleReset={handleReset} /> */}

                  {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
                </Stack>

                <TableContainer sx={{ minWidth: 1000 }}>
                  <Table>
                    <StudentListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={students.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          // const {
                          //   _id,
                          //   title,
                          //   amount,
                          //   payment_method,
                          //   date,
                          //   attachment,
                          //   vendor,
                          //   student,
                          //   tax_type,
                          //   tax_amount,
                          //   expense_category,
                          //   link,
                          //   other_link,
                          //   status,
                          //   notes,
                          //   added_by,
                          // } = row;
                          // const isItemSelected = selected.indexOf(title) !== -1;
                          // let number = index + 1;

                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              // selected={isItemSelected}
                              // aria-checked={isItemSelected}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(event) => handleOpenModal(row, event)}
                            >
                              <TableCell
                                padding="checkbox"
                                className="cell_onclick_effect"
                              >
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={myStyle}
                                className="cell_onclick_effect"
                              >
                                {index + 1}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={myStyle}
                                className="cell_onclick_effect"
                              >
                                {formatDateWithDay(row.date)}
                              </TableCell>

                              <Tooltip
                                TransitionComponent={Zoom}
                                title={
                                  row.title.length > 17
                                    ? capitalizeFirstLetter(row.title)
                                    : ""
                                }
                                arrow
                              >
                                <TableCell
                                  align="left"
                                  className="cell_onclick_effect"
                                >
                                  {capitalizeFirstLetter(
                                    truncateString(row.title, 17)
                                  )}
                                </TableCell>
                              </Tooltip>

                              <TableCell
                                align="left"
                                style={myStyle}
                                className="cell_onclick_effect"
                              >
                                {row.amount}
                              </TableCell>

                              <TableCell
                                align="left"
                                className="cell_onclick_effect"
                              >
                                {capitalizeFirstLetter(row.transfer_by.name)}
                              </TableCell>

                              <TableCell
                                align="left"
                                className="cell_onclick_effect"
                              >
                                {capitalizeFirstLetter(row.transfer_to.name)}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={myStyle}
                                className="cell_onclick_effect"
                              >
                                {paymentMethodLabels[row.payment_method]}
                                {/* {row.payment_method} */}
                              </TableCell>

                              <TableCell
                                align="left"
                                className="cell_onclick_effect"
                              >
                                {capitalizeFirstLetter(row.added_by.name)}
                              </TableCell>

                              {/* <TableCell
                                align="left"
                                className="cell_onclick_effect"
                              >
                                <Label
                                  variant="outlined"
                                  color={
                                    (status === true && "success") ||
                                    (status === false && "error")
                                  }
                                >
                                  {status === true && "Active"}
                                  {status === false && "Inactive"}
                                </Label>
                              </TableCell> */}

                              <TableCell
                                align="right"
                                className="last-cell-class"
                              >
                                {privilegesTypeCheckAndShowOrNot(
                                  get_user_profile,
                                  get_user_privilege_access,
                                  "expense",
                                  "edit"
                                ) ||
                                privilegesTypeCheckAndShowOrNot(
                                  get_user_profile,
                                  get_user_privilege_access,
                                  "expense",
                                  "delete"
                                ) ? (
                                  <StudentMoreMenu
                                    row={row}
                                    students={students}
                                    onStudentsUpdate={handleStudentsUpdate}
                                    trashStudentCount={trashStudentCount}
                                    setTrashStudentCount={setTrashStudentCount}
                                    subTotalCash={subTotalCash}
                                    setSubTotalCash={setSubTotalCash}
                                  />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={13} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={13} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: students.length, label: "All" },
                ]}
                component="div"
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : (
          <NoAccessData />
        )}
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Top Up Detail"
        componentToPassDown={
          <div className="mt-5">
            {/* <h1>hello</h1>
            <h1>hello</h1>
            <h1>hello</h1> */}
            <ExpenseTopUpDetail selectedRowStudent={selectedRowStudent} />
          </div>
        }
      />
    </Page>
  );
}
