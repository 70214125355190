import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Card,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _detail_brand, _edit_brand } from "src/DAL/brand";
import { capitalizeFirstLetter } from "src/utils/formatText";
import {
  _add_support_ticket_comment,
  _detail_support_ticket,
} from "src/DAL/support_tickets";
import { formatNormalDate, onlyTime } from "src/utils/formatTime";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import CustomCardForSupportTicket from "src/components/GeneralComponents/CustomCardForSupportTicket";
import SupportTicketMoreMenu from "../List/components/SupportTicketMoreMenu";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useAppContext } from "src/hooks";

import DataNotFoundCustomMessage from "src/components/GeneralComponents/DataNotFoundCustomMessage";

const EditSupportTicket = ({ supportCount, setSupportCount }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [supportTicketComments, setSupportTicketComments] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();
  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    // last_name: "",
    email: "",
    gender: "male",
    course: [],
    others: [],
    mobile_number: "",
    fee_status: "paid",
    enquiry_status: "active",
    enquiry_status_reason: "",
    education: "",
    image: "",
    address: "",
    reference: "",
    batches: [],
    dob: "",

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    subject: "",
    last_action_date: "",
    last_action_date: "",
    description: "",
    message: "",

    // date_of_birth: null, // Set initial value to null
    // date_of_birth : dayjs("2001-03-03").toDate(),
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleMessageChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);

    let postData = {
      title: formInputs.title,
      description: formInputs.description,
      active_status: formInputs.active_status,
    };
    console.log("postData", postData);

    setIsLoading(true);
    const result = await _add_support_ticket_comment({
      support_ticket_id: id,
      message: message,
      image: pathOfUploadedFile,
    });
    console.log("result _add_support_ticket_comment", result);

    if (result.code === 200) {
      setSupportCount(
        result.support_ticket_comment.waiting_support_ticket_count
      );

      setMessage("");
      setPathOfUploadedFile("");
      fetchStudentDetail();
      setIsLoading(false);
      enqueueSnackbar("Send Successfully!", { variant: "success" });
      // navigate("/support-tickets");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_support_ticket(id);
    console.log("_detail_support_ticket _detail_support_ticket", response);
    // enquiry_form

    if (response.code === 200) {
      setFormInputs({
        ...response.support_ticket,
        // title: capitalizeFirstLetter(response.brand.title),
        // description: capitalizeFirstLetter(response.brand.description),
        // active_status: response.brand.active_status,
      });
      setSupportCount(response.waiting_support_ticket_count);
      setSupportTicketComments(response.support_ticket_comments);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Support Ticket Detail">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Support Ticket Detail{" "}
          </h4>
        </Grid>

        {/* <Box sx={{ marginTop: "50px" }}>
          <h5>{capitalizeFirstLetter(formInputs.subject)}</h5>
          <p>
            <span className="fw-bold">Date:</span>{" "}
            {formatNormalDate(formInputs.last_action_date)}
          </p>
          <p>
            <span className="fw-bold">Time:</span>{" "}
            {onlyTime(formInputs.last_action_date)}
          </p>
          <p>
            <span className="fw-bold">Desctription:</span>{" "}
            {formInputs.description}
          </p>
        </Box> */}

        <Box sx={{ display: "flex", marginTop: "50px" }}>
          {/* Left side content */}
          <Box sx={{ flex: 1 }}>
            <div className="row">
              <div className="col-6">
                <h5>{capitalizeFirstLetter(formInputs.subject)}</h5>
              </div>
              <div className="col-6">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {privilegesTypeCheckAndShowOrNot(
                    get_user_profile,
                    get_user_privilege_access,
                    "support_ticket",
                    "delete"
                  ) ? (
                    <SupportTicketMoreMenu
                      row={formInputs}
                      students={[]}
                      onStudentsUpdate={() => {}}
                      type={1}
                      // students={students}
                      // onStudentsUpdate={handleStudentsUpdate}
                      // type={type}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <p>
              <span className="fw-bold">Date:</span>{" "}
              {formatNormalDate(formInputs.last_action_date)}
            </p>
            <p>
              <span className="fw-bold">Time:</span>{" "}
              {onlyTime(formInputs.last_action_date)}
            </p>
            <p>
              <span className="fw-bold">Description:</span>{" "}
              {formInputs.description}
            </p>

            {formInputs.image !== "" && (
              <Box sx={{ marginTop: "10px" }}>
                <a
                  href={s3baseUrl + formInputs.image}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="image"
                    src={s3baseUrl + formInputs.image}
                    width="50"
                    height="50"
                    style={{
                      maxWidth: "100%",
                      borderRadius: "5px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  />
                </a>
              </Box>
            )}
          </Box>
        </Box>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <div className="mt-5">
            <hr />
          </div>

          <div className="mb-5">
            {supportTicketComments.map((row, index) => {
              return (
                <div className="mt-3 mb-3" key={index}>
                  <CustomCardForSupportTicket
                    row={row}
                    fetchCommentsDetail={fetchStudentDetail}
                  />
                </div>
              );
            })}
          </div>
          {formInputs.ticket_status !== 1 || get_user_profile.type === 0 ? (
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Stack spacing={3}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Message"
                    multiline // Enable multiline
                    rows={3} // Set the number of rows to 3
                    value={message}
                    onChange={handleMessageChange}
                    name="message"
                    required
                  />
                </Stack>

                <CustomImageUploader
                  pathOfUploadedFile={pathOfUploadedFile}
                  setPathOfUploadedFile={setPathOfUploadedFile}
                  imageFileHeading={"Image File"}
                  imageFileSubHeading={"Image Size: 1MB or below :"}
                  imageFileLimitSizeUpload={1}
                  imageFileButtonText={"Upload"}
                />

                <Stack alignItems="end">
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{ width: "120px" }}
                  >
                    Send
                  </LoadingButton>
                </Stack>
              </Stack>
            </form>
          ) : (
            <DataNotFoundCustomMessage
              Heading="Ticket has been closed"
              SubHeading=""
            />
            // <h1> Ticket has been closed </h1>
          )}
        </Grid>
      </div>
    </Page>
  );
};

export default EditSupportTicket;
