import React from "react";
import { CircularLoader } from "src/components";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";

const DataNotFoundInTable = ({ isLoading, setIsLoading }) => {
  if (isLoading) {
    return <CircularLoader />;
  }
  return <DataNotFoundAll />;
};

export default DataNotFoundInTable;
