// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  TextField,
  MenuItem,
  Box,
  Card,
  Select,
  Stack,
  CircularProgress,
} from "@mui/material";
// components
import SummaryCard from "./components/SummaryCard";
import { CircularLoader, Iconify, Page } from "src/components";
//hooks
// import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import {
  _admin_dashboard,
  _get_assignment_for_specific_date_for_dashboard,
  _get_batch_monthly_attendance,
  _get_dashboard_monthly_attendance,
  dashboard,
} from "src/DAL/dashboard";
import { Link } from "react-router-dom";
import CustomBasicPie from "src/components/GeneralComponents/CustomBasicPie";
import CustomLineChart from "src/components/GeneralComponents/CustomLineChart";
import SummaryCard2 from "./components/SummaryCard2";
import SummaryCard3 from "./components/SummaryCard3";
import LeaveDetailTable from "./components/LeaveDetailTable";
import AssignmentsDetailTable from "./components/AssignmentsDetailTable";
import CustomBarChart from "src/components/GeneralComponents/CustomBarChart ";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomDateInput from "src/components/GeneralComponents/CustomDateInput";
import { useSnackbar } from "notistack";
import CustomBarChartVertically from "src/components/GeneralComponents/CustomBarChartVertically";
import { listBatch } from "src/DAL/batch";
import { capitalizeFirstLetter } from "src/utils/formatText";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import SummaryCard4 from "./components/SummaryCard4";
import CircularProgressWithLabel from "src/components/loaders/CircularProgressWithLabel";
import BatchCardsInDashboard from "./components/BatchCardsInDashboard";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import NoAccessDataSimple from "src/components/GeneralComponents/NoAccessDataSimple";

// ----------------------------------------------------------------------

export default function Dashboard2() {
  const theme = useTheme();
  const isAboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [dashboardData, setDashboardData] = useState("");
  const [assignments, setAssignments] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(event) {
    setOpenModal(true);
    // setselectedRowStudent(selectedRow);
  }

  const moment = require("moment");
  const formattedDate = moment().format("YYYY-MM-DD");
  const currentYear = moment().year();

  // Get the current date in "YYYY-MM-DD" format
  const currentDate = moment().format("YYYY-MM-DD");

  // Get the first day of the current month
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");

  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(currentDate);

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    // last_name: "",
    email: "",
    gender: 2024,
    course: "",
    others: [],
    mobile_number: "",
    fee_status: "paid",
    enquiry_status: "false",
    enquiry_status_reason: "",
    note: "",
    education: "",
    image: "",
    address: "",
    other: "",
    reference: "",
    decided_registration_fee: 0,
    decided_course_fee: 0,
    course_type: "existing_course",
    batches: [],

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    time_preferences: "",
    batch_id: "all",
    batch_id_changes: [],
    batch_students_ids: [],
  });
  const currentMonthNow = new Date().getMonth() + 1; // Adding 1 because getMonth() returns zero-based index (0 for January, 1 for February, etc.)
  const currentYearNow = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonthNow);
  const [selectedYear, setSelectedYear] = useState(currentYearNow);

  // const monthNames = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const otherCardsAccess = privilegesTypeCheckAndShowOrNot(
    get_user_profile,
    get_user_privilege_access,
    "dashboard",
    "other_cards"
  );
  const attendanceGraphAccess = privilegesTypeCheckAndShowOrNot(
    get_user_profile,
    get_user_privilege_access,
    "dashboard",
    "attendance_graph"
  );

  const months = monthNames.map((name, index) => ({
    value: index + 1, // Adding 1 to index to match month numbers (1 to 12)
    name: name,
  }));

  const years = Array.from(
    { length: 21 },
    (_, index) => currentYearNow - index
  );

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const fetchDashboard = async () => {
    const response = await _admin_dashboard({
      // year: currentYear,
      year: selectedYear,
    });
    console.log("response  ____response", response);
    if (response.code === 200) {
      // setIsLoading(false);
      setDashboardData(response.dashboard);
      fetchAssignment();
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleChange2 = (e) => {
    const { target } = e;

    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      //   batch_students_ids: [foundObject.students[0]._id],
      batch_students_ids: [],
    });
  };

  function checkAndSwapDates(startDate, endDate) {
    // Convert the dates to JavaScript Date objects for comparison
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if start date is greater than end date
    if (start > end) {
      // Swap the dates if start date is greater
      const temp = startDate;
      startDate = endDate;
      endDate = temp;
    }

    // Return an object with the corrected start and end dates
    return {
      start_date: startDate,
      end_date: endDate,
    };
  }

  const fetchBatches = async () => {
    // setIsLoading(true);
    const response = await listBatch();
    console.log("response listBatch _listBatch _test", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              ...batch,
              id: batch._id,
              name: batch.name,

              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      setBatches(batches);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const fetchBatchMonthlyAttendance = async () => {
    // setIsLoading(true);

    // const response = await _get_batch_monthly_attendance(checkAndSwapDates(startDate,endDate)
    const response = await _get_dashboard_monthly_attendance(
      {
        year: selectedYear,
        month: selectedMonth,
        batch_id: formInputs.batch_id === "all" ? null : formInputs.batch_id,
      }
      //   {
      //     "year": 2024,
      //     "month": 1,
      //     "batch_id": null
      // }
    );
    console.log(
      "startDate  ____response   _get_dashboard_monthly_attendance",
      startDate
    );
    console.log(
      "endDate  ____response   _get_dashboard_monthly_attendance",
      endDate
    );
    console.log(
      "response  ____response   _get_dashboard_monthly_attendance",
      response
    );
    if (response.code === 200) {
      const modifiedData = response.data.map((item) => {
        return {
          ...item,
          date: item.date.split("-")[2], // Extracting the day part from the full date string
          total:
            item.present_count > 0 || item.absent_count > 0 ? item.total : 0,
        };
      });

      console.log("modifiedData   ___modifiedData", modifiedData);

      setAttendance(modifiedData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchDashboardChangeYear = async () => {
    const response = await _admin_dashboard({
      // year: currentYear,
      year: selectedYear,
    });
    console.log("response  ____response", response);
    if (response.code === 200) {
      setIsLoading(false);
      setDashboardData(response.dashboard);
      // fetchAssignment();
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchAssignment = async () => {
    setIsLoading(true);
    const response = await _get_assignment_for_specific_date_for_dashboard({
      date: formattedDate,
    });
    console.log(
      "response  _get_assignment_for_specific_date_for_dashboard",
      response
    );
    if (response.code === 200) {
      setIsLoading(false);
      setAssignments(response.assignments);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  // useEffect(() => {
  //   fetchDashboard();
  // }, []);

  useEffect(() => {
    fetchBatches();
  }, []);

  useEffect(() => {
    if (selectedYear !== currentYear) {
      fetchDashboardChangeYear();
    } else if (selectedYear === currentYear) {
      fetchDashboard();
    }
  }, [selectedYear]);

  // useEffect(() => {
  //   fetchBatchMonthlyAttendance();
  // }, [startDate,endDate]);
  useEffect(() => {
    fetchBatchMonthlyAttendance();
    // }, [selectedMonth, selectedYear, formInputs.batch_id]);
  }, [selectedMonth, formInputs.batch_id]);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl" sx={{ mb: 2 }}>
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "dashboard",
          "view"
        ) ? (
          <>
            <div className="row mt-3  ">
              <div className="col-12  ">
                {/* <h3 style={{marginLeft:"-5px"}}>Dashboard</h3> */}
                <Typography variant="h4" sx={{ marginLeft: "-5px" }}>
                  Dashboard
                </Typography>
              </div>
            </div>

            <div className="   mb-2 p-2">
              <>
                {(otherCardsAccess || attendanceGraphAccess) && (
                  <div className="row">
                    {otherCardsAccess && (
                      <div
                        className={`col-12 col-md-${
                          attendanceGraphAccess ? "8" : "12"
                        } shadowBox pt-4 pb-2`}
                      >
                        <h6 className="pb-2 fw-bold">Overview</h6>
                        <hr />
                        {otherCardsAccess && (
                          <Grid
                            container
                            spacing={0}
                            sx={{ bgcolor: "#f6f6fa" }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              sx={{
                                paddingBottom: { xs: "5px", sm: "5px" },
                              }}
                            >
                              <SummaryCard4
                                color={"warning"}
                                title1={"Total Students"}
                                count1={dashboardData?.student_count}
                                icon={"fe:users"}
                                cardBGColor={"#fff"}
                                cardTextColor={"#000"}
                                iconBGColor={"#efefff"}
                                iconTextColor={"#343aff"}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              sx={{
                                paddingBottom: { xs: "5px", sm: "5px" },
                                paddingLeft: { xs: "0px", sm: "5px" },
                              }}
                            >
                              <SummaryCard4
                                color={"info"}
                                title1={"New Contact"}
                                count1={dashboardData?.new_contact_count}
                                icon={"ri:phone-fill"}
                                cardBGColor={"#fff"}
                                cardTextColor={"#000"}
                                iconBGColor={"#f3fff3"}
                                iconTextColor={"#32e94a"}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              sx={{
                                paddingBottom: { xs: "5px", sm: "0px" },
                              }}
                            >
                              <SummaryCard4
                                color={"secondary"}
                                title1={"Total Start Batch"}
                                count1={dashboardData?.active_batches}
                                icon={"ph:student-bold"}
                                cardBGColor={"#fff"}
                                cardTextColor={"#000"}
                                iconBGColor={"#fff3f5"}
                                iconTextColor={"#e9324a"}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              sx={{
                                paddingLeft: { xs: "0px", sm: "5px" },
                              }}
                            >
                              <SummaryCard4
                                color={"info"}
                                title1={"Course"}
                                count1={dashboardData?.course_count}
                                icon={"fe:book"}
                                cardBGColor={"#fff"}
                                cardTextColor={"#000"}
                                iconBGColor={"#fefaea"}
                                iconTextColor={"#d9d93a"}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </div>
                    )}
                    {attendanceGraphAccess && (
                      <div
                        className={`col-12 col-md-${
                          otherCardsAccess ? "4" : "12"
                        } shadowBox pt-4 pb-2`}
                      >
                        <>
                          <h6 className="pb-2 fw-bold">All Active Batches</h6>
                          <hr />
                          <div className="d-flex align-items-center justify-content-center m-0 p-0">
                            <Box
                              sx={{
                                minHeight: "200px",
                                maxHeight: "200px",
                                overflowY: "auto",
                                minWidth: "100%",
                                maxWidth: "100%",
                                marginTop: "-5px",
                                "&::-webkit-scrollbar": {
                                  width: "7px",
                                  backgroundColor: "#eceff3",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#343aff",
                                  borderRadius: "10px",
                                },
                              }}
                            >
                              <BatchCardsInDashboard
                                attendanceDataFromAPI={batches}
                                LeaveDataFromAPI={[]}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                              />
                            </Box>
                          </div>
                        </>
                      </div>
                    )}
                  </div>
                )}
              </>

              {/* Finance */}
              {(privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "dashboard",
                "expense_cards"
              ) ||
                privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "dashboard",
                  "expense_graph"
                )) && (
                <div className="row mt-3">
                  {privilegesTypeCheckAndShowOrNot(
                    get_user_profile,
                    get_user_privilege_access,
                    "dashboard",
                    "expense_cards"
                  ) && (
                    <div
                      className={`col-12 col-md-${
                        privilegesTypeCheckAndShowOrNot(
                          get_user_profile,
                          get_user_privilege_access,
                          "dashboard",
                          "expense_graph"
                        )
                          ? "6"
                          : "12"
                      } shadowBox pt-4 pb-4`}
                    >
                      <h6 className="pb-2 fw-bold">Finance</h6>
                      <hr />
                      {privilegesTypeCheckAndShowOrNot(
                        get_user_profile,
                        get_user_privilege_access,
                        "dashboard",
                        "expense_cards"
                      ) ? (
                        <Grid
                          container
                          spacing={0}
                          sx={{ bgcolor: "#f6f6fa", marginTop: "0px" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            sx={{
                              paddingBottom: { xs: "5px", sm: "5px" }, // For example, no padding above sm
                            }}
                          >
                            <SummaryCard4
                              color={"warning"}
                              title1={"Total Income"}
                              count1={dashboardData?.total_collected_income}
                              icon={"nimbus:money"}
                              cardBGColor={"#fff"}
                              cardTextColor={"#000"}
                              iconBGColor={"#f3fff3"}
                              iconTextColor={"#32e94a"}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            sx={{
                              paddingBottom: { xs: "5px", sm: "5px" },
                              paddingLeft: { xs: "0px", sm: "5px" },
                            }}
                          >
                            <SummaryCard4
                              color={"info"}
                              title1={"This Month Income"}
                              count1={dashboardData?.current_month_income}
                              icon={"nimbus:money"}
                              cardBGColor={"#fff"}
                              cardTextColor={"#000"}
                              iconBGColor={"#f3fff3"}
                              iconTextColor={"#32e94a"}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            sx={{
                              paddingBottom: { xs: "5px", sm: "0px" },
                            }}
                          >
                            <SummaryCard4
                              color={"secondary"}
                              title1={"Total Expense"}
                              count1={dashboardData?.total_collected_expense}
                              icon={"arcticons:expense-register"}
                              cardBGColor={"#fff"}
                              cardTextColor={"#000"}
                              iconBGColor={"#fff3f5"}
                              iconTextColor={"#e9324a"}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            sx={{
                              paddingLeft: { xs: "0px", sm: "5px" },
                            }}
                          >
                            <SummaryCard4
                              color={"info"}
                              title1={"This Month Expense"}
                              count1={dashboardData?.current_month_expense}
                              icon={"arcticons:expense-register"}
                              cardBGColor={"#fff"}
                              cardTextColor={"#000"}
                              iconBGColor={"#fff3f5"}
                              iconTextColor={"#e9324a"}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <NoAccessDataSimple />
                      )}
                    </div>
                  )}
                  {privilegesTypeCheckAndShowOrNot(
                    get_user_profile,
                    get_user_privilege_access,
                    "dashboard",
                    "expense_graph"
                  ) && (
                    <div
                      className={`col-12 col-md-${
                        privilegesTypeCheckAndShowOrNot(
                          get_user_profile,
                          get_user_privilege_access,
                          "dashboard",
                          "expense_cards"
                        )
                          ? "6"
                          : "12"
                      } shadowBox d-flex align-items-center justify-content-center`}
                    >
                      {privilegesTypeCheckAndShowOrNot(
                        get_user_profile,
                        get_user_privilege_access,
                        "dashboard",
                        "expense_graph"
                      ) ? (
                        <Grid
                          container
                          // spacing={2}
                          // justifyContent="center"
                          // alignItems="center"
                          // sx={{ mb: 3 }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "-50px",
                                // cursor: "pointer",
                              }}
                              // className="mt-5"
                              // sx={{ marginTop:"30px" }}

                              // onClick={(event) => handleOpenModal(  event)}
                            >
                              {/* Your CustomLineChart component */}
                              <CustomLineChart
                                income_graph={dashboardData.income_graph}
                                expense_graph={dashboardData.expense_graph}
                                type={0}
                              />
                            </div>
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                zIndex: 1,
                                cursor: "pointer",
                                color: "#888",
                              }} // Ensure dropdown is above other content
                              onClick={(event) => handleOpenModal(event)}
                            >
                              <Iconify
                                icon={"game-icons:expand"}
                                width={24}
                                height={24}
                              />
                            </Box>
                            {/* Dropdown component */}
                            <TextField
                              select
                              value={selectedYear}
                              onChange={handleYearChange}
                              name="selectedOption"
                              // fullWidth
                              // label="Year" // Add a label for clarity
                              sx={{
                                position: "absolute",
                                top: 20,
                                right: 0,
                                zIndex: 1,
                              }} // Ensure dropdown is above other content
                            >
                              {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      ) : (
                        <NoAccessDataSimple />
                      )}
                    </div>
                  )}
                </div>
              )}
              {/* Finance End*/}
            </div>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{ mb: 1 }}
            >
              {privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "dashboard",
                "today_assignments"
              ) ? (
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    sx={{
                      minHeight: "300px",
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                    className="shadowBox"
                  >
                    <AssignmentsDetailTable
                      attendanceDataFromAPI={assignments}
                      LeaveDataFromAPI={[]}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </Box>
                </Grid>
              ) : (
                ""
              )}

              {privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "dashboard",
                "today_leave_requests"
              ) ? (
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    sx={{
                      minHeight: "300px",
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                    className="shadowBox"
                  >
                    <LeaveDetailTable
                      attendanceDataFromAPI={dashboardData.today_leave_requests}
                      LeaveDataFromAPI={[]}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </Box>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </>
        ) : (
          <NoAccessData />
        )}
      </Container>
      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Detail"
        componentToPassDown={
          <div className="container" style={{ marginTop: "40px" }}>
            <div className="row">
              <Grid
                container
                // spacing={2}
                justifyContent="center"
                alignItems="center"
                // sx={{ mb: 5 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginBottom: "-10px",
                      // paddingBottom: "-20px",
                    }}
                    // className="mt-5"
                    // sx={{ marginTop:"30px" }}
                  >
                    {/* Your CustomLineChart component */}
                    <CustomLineChart
                      income_graph={dashboardData.income_graph}
                      expense_graph={dashboardData.expense_graph}
                      type={1}
                    />
                  </div>

                  {/* Dropdown component */}
                  <TextField
                    select
                    value={selectedYear}
                    onChange={handleYearChange}
                    name="selectedOption"
                    // fullWidth
                    // label="Year" // Add a label for clarity
                    sx={{
                      position: "absolute",
                      top: 20,
                      right: 0,
                      zIndex: 1,
                    }} // Ensure dropdown is above other content
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </div>
          </div>
        }
      />
    </Page>
  );
}
