import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  InputAdornment,
  Chip,
  Divider,
  Box,
  FormHelperText,
  Icon,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "src/utils/formatText";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import { listStudent } from "src/DAL/student";
import {
  _list_active_expense_category,
  _list_expense_category,
} from "src/DAL/expense_category";
import { _add_expense } from "src/DAL/expense";
import { useTheme } from "@mui/material/styles";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";
import { s3baseUrl } from "src/config/config";

const Input = styled("input")({
  display: "none",
});

// ----------------------------------------------------------------------

export default function CustomDateInputWithOnlyPath({
  date,
  setDate,
  labet,
  width,
  ID,
  setAdditionalFields,
}) {
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [previews, setPreviews] = useState();
  const [acceptableFile, setAcceptableFile] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");
  const [datePost, setDatePost] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(dayjs());

 
 
  const handleDateChange = (date) => {
    setStartDate(date);

    const formattedStartDate = date.format("YYYY-MM-DD");
    setDatePost(formattedStartDate);
    setAdditionalFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[ID]["date"] = formattedStartDate;
        return updatedFields;
      });
  };
 

//   if (!startDate) {
//     error = "Leave Date is required.";
//     enqueueSnackbar(error, { variant: "error" });
//     return;
//   }

//   const formattedStartDate = startDate.format("YYYY-MM-DD");



  useEffect(() => {
    if (
        date &&
        date !== null &&
        date !== ""
    ) {
  
        // const leaveDate = response.leave_request.leave_date;
        // const defaultStartDate = leaveDate ? dayjs(leaveDate) : dayjs();
        // const batches_id = response.leave_request.batches.map((item) => item._id);
        setStartDate(dayjs(date));
        const formattedStartDate = dayjs(date).format("YYYY-MM-DD");
        setDatePost(formattedStartDate);
        setAdditionalFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[ID]["date"] = formattedStartDate;
            return updatedFields;
          });
    }else{
        setStartDate(dayjs());
        const formattedStartDate = dayjs().format("YYYY-MM-DD");
        setDatePost(formattedStartDate);
        setAdditionalFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[ID]["date"] = formattedStartDate;
            return updatedFields;
          });
    }

    console.log(
      "date     _____date",
      date
    );
  }, [date]);
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={startDate}
          onChange={handleDateChange}
          label={labet?labet:"Date *"}
          format="MM-DD-YYYY"
          // minDate={dayjs()}
          sx={{
            width: width?width:"100%",
          }}
        />
      </LocalizationProvider>
    </>
  );
}
