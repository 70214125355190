import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";

import { detailBatch, editBatch } from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import {
  _detail_time_preference,
  _edit_time_preference,
} from "src/DAL/timepreference";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { capitalizeFirstLetter } from "src/utils/formatText";

const Edit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");

  const [startTime, setStartTime] = useState(dayjs()); // Initialize with an empty value
  const [endTime, setEndTime] = useState(dayjs()); // Initialize with an empty value

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_end_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    type: "",
    total_seats: "",
    status: "true",
    order: "",
    courses: [],
    start_date_batch_select: "",
    end_date_batch_select: "",

    start_time_class: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),

    end_time_class: dayjs(new Date()),
    format_end_time: dayjs(dayjs(new Date())).format("HH:mm"),
  });

  const myStyle = {
    textTransform: "capitalize",
  };

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  // const handleTimeChange = (time) => {
  //   const formattedTime = dayjs(time).format("HH:mm");
  //   setFormInputs((values) => ({
  //     ...values,
  //     format_time: formattedTime,
  //     time: time,
  //   }));
  // };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const handleEndTimeChange = (time) => {
    console.log("time", time);
    const formattedTime = dayjs(time).format("HH:mm");
    console.log("time formattedTime", formattedTime);
    setInputs((values) => ({
      ...values,
      format_end_time: formattedTime,
      end_time: time,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      courses: [value],
    }));
    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  // const handleEndTimeChange = (time) => {
  //   setEndTime(time);
  // };

  // const handleTimeChange = (time) => {
  //   setStartTime(time); // Update the startTime state when the time changes
  // };

  // const handleEndTimeChange = (time) => {
  //   setEndTime(time); // Update the endTime state when the time changes
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formInputs", formInputs);
    console.log("formInputs2", formInputs2);
    console.log("inputs", inputs);

    let postData = {
      type: formInputs.type, // unique
      start_time: inputs.format_time,
      end_time: inputs.format_end_time,
      status: formInputs.status,
    };

    setIsLoading(true);
    const result = await _edit_time_preference(id, postData);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/time-preference");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await _detail_time_preference(id);
    console.log("response _detail_time_preference", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      let startTime = dayjs(
        new Date(dayjs(response.time_preference.start_time, "HH:mm").toDate())
      );
      console.log("startTime", startTime);

      let endTime = dayjs(
        new Date(dayjs(response.time_preference.end_time, "HH:mm").toDate())
      );
      console.log("endTime", endTime);

      setInputs((values) => ({
        ...values,
        time: startTime,
        format_time: response.time_preference.start_time,

        end_time: endTime,
        format_end_time: response.time_preference.end_time,
      }));

      console.log(
        "response.time_preference.type",
        response.time_preference.type
      );
      setFormInputs({
        // type: response.time_preference.type,
        type:  capitalizeFirstLetter(response.time_preference.type),
        // total_seats: response.batch.total_seats,
        status: response.time_preference.status,

        start_time_class: startTime,
        end_time_class: endTime,
        // time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),
        // format_time: formattedTime,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
 
  useEffect(() => {
    fetchDetailBatch();
    // fetchActiveCourses();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Batch">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Time Preference </h4>
        </Grid>


        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Type (Unique) "
                  value={formInputs.type}
                  onChange={handleChange}
                  name="type"
                  required 
                  style={myStyle}
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                {/* <TextField
                  fullWidth
                  label="Type"
                  select
                  value={formInputs.type}
                  onChange={handleChange}
                  name="type"
                  // required
                >
                  <MenuItem value="Morning">Morning</MenuItem>
                  <MenuItem value="Afternoon">Afternoon</MenuItem>
                  <MenuItem value="Evening">Evening</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                  <MenuItem value="Midnight">Midnight</MenuItem>
                  <MenuItem value="Dawn">Dawn</MenuItem>
                </TextField> */}

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  // required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DemoContainer components={['MobileTimePicker', 'MobileTimePicker']}> */}

                  {/* <MobileTimePicker
                    label={"Class Start Time *"}
                    openTo="hours"
                    // selected={startTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={startTime}
                    value={inputs?.time}
                    onChange={handleTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  /> */}

                  <TimePicker
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label={"Start Time *"}
                    openTo="hours"
                    views={["hours", "minutes"]}
                    value={inputs?.time}
                    onChange={handleTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  <TimePicker
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label={"End Time *"}
                    openTo="hours"
                    views={["hours", "minutes"]}
                    value={inputs?.end_time}
                    onChange={handleEndTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  {/* <MobileTimePicker 
                    label={"Class End Time *"}
                    openTo="hours"

                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={formInputs?.end_time_class}
                    value={inputs?.end_time}
                    onChange={handleEndTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%",
                      // marginTop: "20px",
                    }}
                  /> */}

                  {/* </DemoContainer> */}
                </LocalizationProvider>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
