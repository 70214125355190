import { filter } from "lodash";

import { useState, useEffect } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

import TableHead from "@mui/material/TableHead";

import Paper from "@mui/material/Paper";

//
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import {
  _detail_collect_fee,
  _detail_invoice,
  _list_collect_fee,
} from "src/DAL/collect_fee";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Students() {
  const myStyle = {
    textTransform: "capitalize",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    fontSize: "8pt",
    padding: "0.75rem",
    textAlign: "center", // Add text alignment to center
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [firstRowData, setFirstRowData] = useState([]);
  const [lastRowData, setLastRowData] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [totalPaidFee, setTotalPaidFee] = useState("0");
  const [dueFee, setDueFee] = useState("0");

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  console.log("_ID _ID _ID", id);

  const [Inputs, setInputs] = useState({
    total_fee: 0,
    total_paid_fee: 0,
    total_pending_fee: 0,
    total_registration_fee: 0,
    total_paid_registration_fee: 0,
    total_pending_registration_fee: 0,
  });

  const handleNav = () => {
    navigate(-1);
  };

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  // const handleNav = () => {
  //   navigate("/invoice-fee/add-invoice");
  // };

  const fetchStudents = async () => {
    // const response = await listStudent();
    // setIsLoading(true);
    // const response = await _list_collect_fee({
    //   text: "",
    // });
    // console.log("response _list_collect_fee list_collect_fee    ", response);
    // let collect_fee = response.list_collect_fee;
    // if (response.code === 200) {
    //   setIsLoading(false);

    //   // console.log("response.list_collect_fee[2]", response.list_collect_fee[2]);
    //   // console.log(
    //   //   "response.list_collect_fee[2].student.actual_fee",
    //   //   response.list_collect_fee[2].student.actual_fee
    //   // );

    //   const students = response.list_collect_fee.map((collect_fee, index) => ({
    //     // id: collect_fee._id,
    //     id: collect_fee.student._id,

    //     name: collect_fee.student.name ? collect_fee.student.name : "",
    //     student_cnic: collect_fee.student.student_cnic
    //       ? collect_fee.student.student_cnic
    //       : "",

    //     mobile: collect_fee.student.contact_number
    //       ? "0" + collect_fee.student.contact_number
    //       : "",

    //     courses: collect_fee.student.courses,
    //     batch: collect_fee.student.batch,
    //     Course_Fee: collect_fee.student.actual_fee,
    //     student_agreed_fee: collect_fee.student.decided_fee,
    //     // paid_fee: collect_fee.paid_fee[0]?collect_fee.paid_fee[0]:"",
    //     paid_fee: collect_fee.paid_fee,
    //     due_fee: collect_fee.due_fee,
    //     number: index + 1,
    //     // last_name: student.last_name,
    //     // email: student.email,
    //     // // image: student.profile_image,
    //     // status: student.status,
    //     // batch: student.batch_id ? student.batch_id : "",
    //     // courses: student.courses ? student.courses : "",
    //   }));
    //   // setStudents([{
    //   //   name: collect_fee.name?collect_fee.name:"Undefined",
    //   //   mobile: "mobile",
    //   //   courses: "courses",
    //   //   batch: "batch",
    //   //   Course_Fee: "Course_Fee",
    //   //   student_agreed_fee: "student_agreed_fee",
    //   //   paid_fee: "paid_fee",
    //   //   due_fee: "due_fee",
    //   // }]);
    //   setStudents(students);
    // } else {
    //   setIsLoading(false);
    //   enqueueSnackbar(response.message, { variant: "error" });
    // }

    setIsLoading(false);
    // let print_invoice = [
    //   {
    //     _id: "64a3a771e0ad1892dd66bb83",

    //     name: "Imran Ali",
    //     father_name: "Kamran Ali",
    //     invoice_today_date: "2023-07-07",
    //     Instructor_name: "Instructors Name",
    //     Instructor_number: "03001234567",
    //     total_paid: 200,
    //     Pending: 4000,

    //     courses: [
    //       {
    //         _id: "644f46d3f65283213c8a9abd",
    //         title: "Basic To Advance Programming (C++)",
    //         image: "images/565b63a0-13f1-11ee-ae6f-9dbaeb2e67db.jpeg",
    //         course_duration: "3",
    //       },
    //     ],
    //     batch: [
    //       {
    //         _id: "6411a95bf826ae7b1c8faebe",
    //         name: "Morning",
    //       },
    //     ],
    //     decided_fee: 12312,

    //     print_invoice: [
    //       {
    //         payment_id: "SIFSD01395",
    //         mode: "Cash",
    //         month: "June",
    //         date: "2023-06-27",
    //         paid_fee: [1],
    //         due_fee: 3122,
    //       },

    //       {
    //         payment_id: "SIFSD01395AAA",
    //         mode: "Cash",
    //         month: "july",
    //         date: "2023-07-27",
    //         paid_fee: [1],
    //         due_fee: 3122,
    //       },

    //       {
    //         payment_id: "SIFSD01395",
    //         mode: "Cash",
    //         month: "Aug",
    //         date: "2023-08-27",
    //         paid_fee: [1],
    //         due_fee: 3122,
    //       },
    //     ],
    //   },
    // ];

    // console.log("print_invoice", print_invoice);
    // console.log("print_invoice.print_invoice", print_invoice[0].print_invoice);

    // const students = print_invoice[0].print_invoice.map(
    //   (collect_fee, index) => {
    //     return {
    //       // courses: collect_fee.courses,
    //       // batch: collect_fee.batch,
    //       // total_fees: collect_fee.decided_fee,

    //       courses: [],
    //       batch: [],
    //       total_fees: "",

    //       payment_id: collect_fee.payment_id,
    //       mode: collect_fee.mode,
    //       month: collect_fee.month,
    //       date: collect_fee.date,
    //       paid_fee: collect_fee.paid_fee,
    //       due_fee: collect_fee.due_fee,
    //     };
    //   }
    // );

    // const first_row = print_invoice.map((collect_fee, index) => {
    //   return {
    //     courses: collect_fee.courses,
    //     batch: collect_fee.batch,
    //     total_fees: collect_fee.decided_fee,
    //     payment_id: "",
    //     mode: "",
    //     month: "",
    //     date: "",
    //     paid_fee: [],
    //     due_fee: "",
    //   };
    // });

    // const student_info = print_invoice.map((collect_fee, index) => {
    //   return {
    //     name: collect_fee.name,
    //     father_name: collect_fee.father_name,
    //     invoice_today_date: collect_fee.invoice_today_date,
    //     Instructor_name: collect_fee.Instructor_name,
    //     Instructor_number: collect_fee.Instructor_number,
    //     total_paid: collect_fee.total_paid,
    //     Pending: collect_fee.Pending,
    //   };
    // });

    // setStudents(students);
    // setFirstRowData(first_row);
    // setStudentInfo(student_info);
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }

  const fetchDetailFee = async () => {
    // setIsLoading(true);

    const response = await _detail_invoice(id);

    if (response.code === 200) {
      // setDueFee(response.invoice_detail.print_invoice);
      console.log("response _detail_collect_fee _detail_collect_fee", response);
      // console.log("response response.invoice_detail.print_invoice ", response.invoice_detail.print_invoice);

      console.log(
        "response.invoice_detail.print_invoice",
        response.invoice_detail.print_invoice
      );

      let print_invoice_array = response.invoice_detail.print_invoice;
      let print_invoice_other_info = response.invoice_detail.student;
      console.log("print_invoice_array", print_invoice_array);
      console.log("print_invoice_other_info", print_invoice_other_info);

      setDueFee(response.invoice_detail.student.total_due_fee);
      setTotalPaidFee(response.invoice_detail.student.total_paid_fee);

      // const dateStr = "2023-07-07T12:54:34.278Z";
      // const date = new Date(dateStr);
      // const formattedDate = date.toLocaleDateString("en-US", {
      //   day: "2-digit",
      //   month: "long",
      //   year: "numeric",
      // });
      if (
        typeof print_invoice_other_info === "object" &&
        !Array.isArray(print_invoice_other_info)
      ) {
        print_invoice_other_info = [print_invoice_other_info];
      }

      const student_info = print_invoice_other_info.map(
        (collect_fee, index) => {
          return {
            name: collect_fee.name,
            father_name: collect_fee.father_name,
            invoice_today_date: new Date(response.invoice_detail.createdAt).toLocaleDateString("en-US", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }),
            Instructor_name: "",
            Instructor_number: "",
            total_paid: "0",
            Pending: "0",
          };
        }
      );

      console.log(
        "__________print_invoice_other_info",
        print_invoice_other_info
      );
      const first_row = print_invoice_other_info.map((collect_fee, index) => {
        // console.log('print_invoice_other_info',collect_fee);
        setInputs({
          total_fee: collect_fee.total_fee,
          total_paid_fee: collect_fee.total_paid_fee,
          total_pending_fee: collect_fee.total_pending_fee,
          total_registration_fee: collect_fee.total_registration_fee,
          total_paid_registration_fee: collect_fee.total_paid_registration_fee,
          total_pending_registration_fee: collect_fee.total_pending_registration_fee,
        });

        return {
          courses: collect_fee.courses,
          batch: collect_fee.batch,

          // total_fees: collect_fee.decided_fee + collect_fee.registration_fee,
          total_fees: collect_fee.total_fee,
          payment_id: "",
          mode: "",
          month: "",
          date: "",
          paid_fee: [],
          due_fee: "",
          refund_fee: "",
          fee_type: "",
          // discount_fee: "",
        };
      });
      console.log("first_row", first_row);

      const students = print_invoice_array.map((collect_fee, index) => {
        return {
          // courses: collect_fee.courses,
          // batch: collect_fee.batch,
          // total_fees: collect_fee.decided_fee,

          courses: [],
          batch: "",
          total_fees: "",

          payment_id: collect_fee.payment_id,
          // mode: collect_fee.mode,
          mode: "Cash",
          // month: collect_fee.month,
          date: formatDate(collect_fee.date),
          paid_fee: collect_fee.paid_fee,
          due_fee: collect_fee.pending_fee,
          refund_fee: collect_fee.refund_fee,
          fee_type: collect_fee.fee_type,
        };
      });
      setFirstRowData(first_row);
      setStudents(students);
      setStudentInfo(student_info);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });

      // setFirstRowData([]);
      // setLastRowData([]);
      // setStudentInfo([]);
      // setFirstRowData([]);
      // setStudents([]);
      // setTotalPaidFee("0");
      // setDueFee("0");

      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetchStudents();
    fetchDetailFee();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Student">
      <Container maxWidth="xl">
        <div style={{ padding: "0 15px" }}>
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
            mt={2}
          >
            <div style={{ textAlign: "center", padding: "0 15px" }}>
              <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                DDIT
              </Typography>
              {/* <Typography variant="span">{studentInfo.length > 0 && studentInfo[0].Instructor_name}</Typography> <br />
              <Typography variant="span"> {studentInfo.length > 0 && studentInfo[0].Instructor_number}</Typography> <br /> */}
            </div>

            {/* <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Invoice
          </Button> */}
          </Stack>
        </div>

        <div style={{ padding: "0 15px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
            mt={2}
          >
            <div style={{ textAlign: "center" }}>
              <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                {studentInfo.length > 0 && studentInfo[0].name}
              </Typography>
              <Typography variant="span">
                Father Name:{" "}
                {studentInfo.length > 0 && studentInfo[0].father_name}
              </Typography>{" "}
              <br />
            </div>
            <div style={{ marginLeft: "auto", padding: "0 5px" }}>
              <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                Date:{" "}
                {studentInfo.length > 0 && studentInfo[0].invoice_today_date}
              </Typography>{" "}
              <br />
            </div>
          </Stack>
        </div>

        <hr />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell style={myStyle}>Course</TableCell> */}
                <TableCell style={myStyle}>Batch</TableCell>
                <TableCell style={myStyle}>Total Fees</TableCell>
                <TableCell style={myStyle}>Payment Id</TableCell>
                <TableCell style={myStyle}>Mode</TableCell>
                <TableCell style={myStyle}>Fee Type</TableCell>
                {/* <TableCell style={myStyle}>Date</TableCell> */}
                <TableCell style={myStyle}>Paid fee</TableCell>
                <TableCell style={myStyle}>Due Fee</TableCell>
                <TableCell style={myStyle}>Refund Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {firstRowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    id,
                    name,
                    mobile,
                    email,
                    Course_Fee,
                    student_agreed_fee,
                    paid_fee,
                    due_fee,
                    batch,
                    courses,
                    student_cnic,
                    number,
                    total_fees,
                    // date,
                    mode,
                    payment_id,
                    month,
                    refund_fee,
                    fee_type,
                  } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;
                  // let number = index + 1;
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      {/* <TableCell align="left" style={myStyle}> */}
                      {/* {courses.length > 0
                          ? courses
                              .map((course, index) => {
                                return course !== null ? course.title : "";
                              })
                              .join(", ")
                          : ""} */}
                      {/* </TableCell> */}

                      <TableCell align="left" style={myStyle}>
                        {/* {batch.length > 0
                          ? batch
                              .map((_batch, index) => {
                                return _batch !== null ? _batch.name : "";
                              })
                              .join(", ")
                          : ""} */}
                        {batch.name}
                      </TableCell>

                      {/* <TableCell align="left" style={myStyle}>
                            {total_fees}
                          </TableCell> */}
                      <TableCell align="left" style={myStyle}>
                        {total_fees === "" ? (
                          <span>
                            <Iconify
                              icon="clarity:child-arrow-line"
                              width={24}
                              height={24}
                            />
                          </span>
                        ) : (
                          <span>{total_fees}</span>
                        )}
                      </TableCell>

                      <TableCell align="left" style={myStyle}>
                        {payment_id}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {mode}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {fee_type === "refund_registration_fee"
                          ? "Refund Registration Fee"
                          : fee_type === "refund_fee"
                          ? "Refund Fee"
                          : fee_type}
                      </TableCell>
                      {/* <TableCell align="left" style={myStyle}>
                        {date}
                      </TableCell> */}

                      <TableCell align="left" style={myStyle}>
                        {/* {paid_fee.length > 0
                          ? paid_fee
                              .map((_paid_fee, index) => {
                                return _paid_fee !== null ? _paid_fee : 0;
                              })
                              .reduce((acc, curr) => acc + curr, 0)
                          : 0} */}
                      </TableCell>

                      <TableCell align="left" style={myStyle}>
                        {due_fee}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {refund_fee}
                      </TableCell>
                    </TableRow>
                  );
                })}

              {filteredStudents
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const {
                    id,
                    name,
                    mobile,
                    email,
                    Course_Fee,
                    student_agreed_fee,
                    paid_fee,
                    due_fee,
                    batch,
                    courses,
                    student_cnic,
                    number,
                    total_fees,
                    // date,
                    mode,
                    payment_id,
                    month,
                    refund_fee,
                    fee_type,
                  } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;
                  // let number = index + 1;
                  return (
                    <TableRow
                      hover
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      {/* <TableCell align="left" style={myStyle}> */}
                      {/* {courses.length > 0
                          ? courses
                              .map((course, index) => {
                                return course !== null ? course.title : "";
                              })
                              .join(", ")
                          : ""} */}
                      {/* </TableCell> */}

                      <TableCell align="left" style={myStyle}>
                        {/* {batch.length > 0
                          ? batch
                              .map((_batch, index) => {
                                return _batch !== null ? _batch.name : "";
                              })
                              .join(", ")
                          : ""} */}
                        {batch.name}
                      </TableCell>

                      {/* <TableCell align="left" style={myStyle}>
                            {total_fees}
                          </TableCell> */}
                      <TableCell align="left" style={myStyle}>
                        {total_fees === "" ? (
                          <span>
                            <Iconify
                              icon="clarity:child-arrow-line"
                              width={24}
                              height={24}
                            />
                          </span>
                        ) : (
                          <span>{total_fees}</span>
                        )}
                      </TableCell>

                      <TableCell align="left" style={myStyle}>
                        {payment_id}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {mode}
                      </TableCell>

                     
                      <TableCell align="left" style={myStyle}>
                        {fee_type === "refund_registration_fee"
                          ? "Refund Registration Fee"
                          : fee_type === "refund_fee"
                          ? "Refund Fee"
                          : fee_type}
                      </TableCell>

                      {/* <TableCell align="left" style={myStyle}>
                        {date}
                      </TableCell> */}

                      {/* <TableCell align="left" style={myStyle}>
                        {paid_fee.length > 0
                          ? paid_fee
                              .map((_paid_fee, index) => {
                                return _paid_fee !== null ? _paid_fee : 0;
                              })
                              .reduce((acc, curr) => acc + curr, 0)
                          : 0}
                      </TableCell> */}

                      <TableCell align="left" style={myStyle}>
                        {paid_fee}
                      </TableCell>

                      <TableCell align="left" style={myStyle}>
                        {due_fee}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {refund_fee}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  {/* <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell> */}
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <div style={{ padding: "0 15px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
            mt={2}
          >
            <div style={{ marginLeft: "auto", padding: "0 5px" }}></div>
            <div style={{ marginLeft: "auto", padding: "0 5px" }}> </div>
            <div style={{ marginLeft: "auto", padding: "0 5px" }}> </div>
            <div style={{ marginLeft: "auto", padding: "0 5px" }}>
              <Typography variant="span">
                {/* Total Paid = {studentInfo.length > 0 && studentInfo[0].total_paid} <br /> */}
                Total Registration Fee &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; = {Inputs.total_registration_fee} <br />
                Total Paid Registration Fee &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ={" "}
                {Inputs.total_paid_registration_fee} <br />
                Total Pending Registration Fee ={" "}
                {Inputs.total_pending_registration_fee} <br />
                {/* Pending &nbsp;&nbsp;= {dueFee} <br /> */}
                {/* Pending &nbsp;&nbsp;= {studentInfo.length > 0 && studentInfo[0].Pending} <br /> */}
              </Typography>{" "}
              <br />
            </div>
            <div style={{ marginLeft: "auto", padding: "0 5px" }}>
              <Typography variant="span">
                {/* Total Paid = {studentInfo.length > 0 && studentInfo[0].total_paid} <br /> */}
                Total Fee = {Inputs.total_fee} <br />
                Total Paid Fee = {Inputs.total_paid_fee} <br />
                Total Pending Fee = {Inputs.total_pending_fee} <br />
                {/* Pending &nbsp;&nbsp;= {dueFee} <br /> */}
                {/* Pending &nbsp;&nbsp;= {studentInfo.length > 0 && studentInfo[0].Pending} <br /> */}
              </Typography>{" "}
              <br />
            </div>
          </Stack>
        </div>
      </Container>
    </Page>
  );
}
