// @mui
import PropTypes from "prop-types";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { Box, Card, Paper, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "src/utils";
// components
import Iconify from "../../../components/Iconify";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateWithOutDay, formatTime24Hours } from "src/utils/formatTime";
// import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

SummaryCard5.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title1: PropTypes.string.isRequired,
  count1: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function SummaryCard5({
  title1,
  count1,
  cardBGColor,
  cardTextColor,
  row,
  index,
  iconBGColor,
  iconTextColor,

  icon,
  color = "primary",
  sx,
  ...other
}) {
  const theme = useTheme(); // Use useTheme hook to access the theme

  console.log("row   _____row_____", row);
  return (
    <div className="shadowBox2-0">
      {/* <Paper elevation={1}  > */}
      <Card
        sx={{
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'space-between', // To push the IconWrapperStyle to the right
          //   py: 1,
          //   boxShadow: 1,
          textAlign: "center",
          color: cardTextColor,
          bgcolor: cardBGColor,
          borderRadius: 0,
          mb: 0.5,
          ...sx,
        }}
        {...other}
        className="shadowBox-0"
      >
        <div class="flex-container mt-3" style={{marginBottom:"-5px"}}>
          <div class="left-div">
            {" "}
            <Typography
              variant="h5"
              sx={{
                textAlign: "start",
                fontWeight: 600,
                textDecoration: "none !important",
                color: "#666",
              }}
            >
              {fShortenNumber(index + 1)}
            </Typography>
          </div>
          <div class="center-container">
            <div class="center-div">
              {" "}
              <h6
                className=""
                style={{ marginTop: "5px", fontWeight: 600, color: "#666" }}
              >
                {" "}
                {capitalizeFirstLetter(row?.name)}
              </h6>
            </div>
            {/* <div class="center-div">Center Content 2</div> */}
          </div>
        </div>
        <div className="px-5">
          <hr />
        </div>
        <div className="row" style={{ marginBottom: "-4px" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <div className="dash-details">
              <div
                className="lesson-activity"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <div className="lesson-imgs">
                  {/* <img src="./Preskool - Dashboard_files/lesson-icon-01.svg" alt=""> */}
                </div>
                <div className="views-lesson">
                  <div
                    className="batch_info_heading"
                    style={{ fontWeight: 600, color: "#666" }}
                  >
                    Total Student
                  </div>
                  <div className="batch_info_sub_text">
                    {/* {capitalizeFirstLetter(batchInfo.batch_name)} */}
                    {row.student_count}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-6">
                <div className="lesson-activity">
                  <div className="lesson-imgs">
                    {/* <img src="./Preskool - Dashboard_files/lesson-icon-04.svg" alt=""> */}
                  </div>
                  <div className="views-lesson">
                    <div
                      className="batch_info_heading"
                      style={{ fontWeight: 600, color: "#666" }}
                    >
                      Start Time
                    </div>
                    <div className="batch_info_sub_text">
                      {formatTime24Hours(row.start_time_class)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="lesson-activity">
                  <div className="lesson-imgs">
                    {/* <img src="./Preskool - Dashboard_files/lesson-icon-04.svg" alt=""> */}
                  </div>
                  <div className="views-lesson">
                    <div
                      className="batch_info_heading"
                      style={{ fontWeight: 600, color: "#666" }}
                    >
                      End Time
                    </div>
                    <div className="batch_info_sub_text">
                      {formatTime24Hours(row.end_time_class)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="lesson-activity">
                  <div className="lesson-imgs">
                    {/* <img src="./Preskool - Dashboard_files/lesson-icon-04.svg" alt=""> */}
                  </div>
                  <div className="views-lesson">
                    <div
                      className="batch_info_heading"
                      style={{ fontWeight: 600, color: "#666" }}
                    >
                      Start Date
                    </div>
                    <div className="batch_info_sub_text">
                      {formatDateWithOutDay(row.start_date_batch)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="lesson-activity">
                  <div className="lesson-imgs">
                    {/* <img src="./Preskool - Dashboard_files/lesson-icon-04.svg" alt=""> */}
                  </div>
                  <div className="views-lesson">
                    <div
                      className="batch_info_heading"
                      style={{ fontWeight: 600, color: "#666" }}
                    >
                      End Date
                    </div>
                    <div className="batch_info_sub_text">
                      {formatDateWithOutDay(row.end_date_batch)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* col-8 End */}
        </div>{" "}
        {/* row End */}
      </Card>
      {/* </Paper> */}
    </div>
  );
}
