import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import moment from "moment";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  InputLabel,
  Tooltip,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _addEnquiryStudent, _delEnquiryStudent } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_attendance } from "src/DAL/attendance";
import { renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";
import { capitalizeFirstLetter } from "src/utils/formatText";
const Add = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [students, setStudents] = useState([]);
  const [selected, setSelected] = useState([]);
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    gender: "male",
    courses: [],
    phone: "",
    fee_status: "paid",
    status: "true",
    education: "",
    image: "",
    reg_type: "regular",
    course_fee: 0,
    registration_fee: 0,
    decided_fee: 0,
    actual_fee: 0,
    cnic: "",
    referred_by: "",
    address: "",
    batches: [],
    dob: null,
    date_of_birth: dayjs(),
    student_id: null,
    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    absent: false,
  });
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  // const [absent, setAbsent] = useState(false);
  const [studentID, setStudentID] = useState("");

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (studentID === "") {
      error = "student is Required";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (formInputs.absent !== true) {
      console.log("startTime", startTime);
      if (!startTime && formInputs.absent !== true) {
        error = "Check In Time is required.";
        enqueueSnackbar(error, { variant: "error" });
        return;
      }

      console.log("endTime", endTime);
      if (!endTime && formInputs.absent !== true) {
        error = "Check Out is required.";
        enqueueSnackbar(error, { variant: "error" });
        return;
      }

      const formattedStartTime = startTime.format("HH:mm");
      console.log("formattedStartTime", formattedStartTime);

      if (
        !moment(formattedStartTime, "HH:mm", true).isValid() &&
        formInputs.absent !== true
      ) {
        error = "Invalid Start Time Format.";
        enqueueSnackbar(error, { variant: "error" });
        return;
      }

      const formattedEndTime = endTime.format("HH:mm");
      console.log("formattedEndTime", formattedEndTime);

      if (
        !moment(formattedEndTime, "HH:mm", true).isValid() &&
        formInputs.absent !== true
      ) {
        error = "Invalid End Time Format.";
        enqueueSnackbar(error, { variant: "error" });
        return;
      }

      let data = {
        student_id: studentID,
        date: formattedStartDate,
        check_in: formattedStartTime,
        check_out: formattedEndTime,
        // absent: formInputs.absent,
      };


      setIsLoading(true);
      const result = await _add_attendance(data);
      if (result.code === 200) {
        setIsLoading(false);
        navigate("/attendance");
        enqueueSnackbar("Added Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      let data = {
        student_id: studentID,
        date: formattedStartDate,
        // check_in: formattedStartTime,
        // check_out: formattedEndTime,
        absent: formInputs.absent,
      };

      setIsLoading(true);
      const result = await _add_attendance(data);
      if (result.code === 200) {
        setIsLoading(false);
        navigate("/attendance");
        enqueueSnackbar("Added Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    // const response = await _addEnquiryStudent();
    const response = await listStudent();
    console.log("response _ listStudent List __test", response);
    if (response.code === 200) {
      const students = response.students.map((student) => {
        let gender;
        if (student.gender === "Male" || student.gender === "male") {
          gender = "male";
        } else if (student.gender === "Female" || student.gender === "female") {
          gender = "female";
        } else {
          gender = "male";
        }

        return {
          id: student._id,
          name: capitalizeFirstLetter(student.name),
          father_name: student.father_name,
          // email: student.email,
          mobile_number: student.mobile_number,
          gender: gender,
          note: student.note,
          referred_by: student.referred_by,
          course: student.course ? student.course : [],
          others: student.others ? student.others : [],
          registration_fee: student.decided_registration_fee
            ? student.decided_registration_fee
            : 0,
          decided_fee: student.decided_course_fee
            ? student.decided_course_fee
            : 0,
          latest_degree: student.latest_degree,
          degree_status: student.degree_status,
          completion_year: student.completion_year,
          institution_name: student.institution_name,
          semester: student.semester,
          // date_of_birth: student.date_of_birth ? student.date_of_birth : "",
          // date_of_birth: DOB,
        };
      });

      console.log("students obj __test", students);
      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const updateFormInputs = (selectedValues) => {
    const updatedFormInputs = { ...formInputs };
    console.log("selectedValues  ______ selectedValues", selectedValues);

    updatedFormInputs._id = selectedValues.id;
    updatedFormInputs.first_name = selectedValues.name;
    updatedFormInputs.father_name = selectedValues.father_name;
    // updatedFormInputs.email = selectedValues.email;
    updatedFormInputs.mobile_number = selectedValues.mobile_number;
    updatedFormInputs.referred_by = selectedValues.referred_by;
    updatedFormInputs.gender = selectedValues.gender;

    updatedFormInputs.contact_number = selectedValues.mobile_number;
    // updatedFormInputs.phone_number = selectedValues.mobile_number.toString();
    // updatedFormInputs.phone_number = selectedValues.mobile_number.toString();

    updatedFormInputs.registration_fee = selectedValues.registration_fee;
    updatedFormInputs.decided_fee = selectedValues.decided_fee;

    updatedFormInputs.latest_degree = selectedValues.latest_degree;
    updatedFormInputs.degree_status = selectedValues.degree_status;
    updatedFormInputs.completion_year = selectedValues.completion_year;
    updatedFormInputs.institution_name = selectedValues.institution_name;
    updatedFormInputs.semester = selectedValues.semester;

     
    console.log("updatedFormInputs", updatedFormInputs);
    setFormInputs(updatedFormInputs);
  };

  const handleSelectChange = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setStudentID("");
      setSelected([]);
      updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setStudentID(newValue.id);
      setSelected(newValue);
      updateFormInputs(newValue);
    }
  };

  const fetchCourses = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response listCourse listCourse listCourse", response);
    if (response.code === 200) {
      const courses = response.courses
        .filter((course) => course.status === true)
        .map((course) => ({
          id: course._id,
          fee: course.fee,
          title: course.title,
          description: course.description,
          duration: course.duration,
          // image: course.image,
          status: course.status,
        }));
      console.log("courses courses courses courses", courses);
      setCourses(courses);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
    // fetchActiveBatches();
    // fetchCourses();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Student">
       <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Attendance </h4>
        </Grid>
		 

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack spacing={2} sx={{ marginBottom: 2 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={students}
              getOptionLabel={(student) => (student.name ? capitalizeFirstLetter(student.name) : "")}
              value={selected}
              onChange={handleSelectChange}
              // filterOptions={filterOptions} // Apply the custom filter function
              renderInput={(params) => (
                <TextField {...params} label="Student" />
              )}
              renderOption={(props, student) => (
                // <Tooltip
                //   title={
                //     // `Name: ${student.name}\nFather's Name: ${student.father_name}\nMobile Number: ${student.mobile_number}\nReferred By: ${student.referred_by}`
                //     <div>
                //       Course ={" "}
                //       {student.course.length > 0
                //         ? student.course
                //             .map((course, index) => {
                //               return course !== null ? course.title : "";
                //             })
                //             .join(", ")
                //         : ""}
                //       <br />
                //       Father Name = {student.father_name} <br />
                //       Mobile Number = {student.mobile_number} <br />
                //       Referred By = {student.referred_by} <br />
                //       Gender = {student.gender} <br />
                //       Note = {student.note} <br />
                //       {/* Email = {email} <br />
                //                 Address = {address} <br />
                //                 Registration Type = {reg_type} <br />
                //                 Actual Registration Fee = {course_fee} <br />
                //                 Actual Course Fee= {actual_fee} <br /> */}
                //     </div>
                //   }
                // >
                <li {...props}>{capitalizeFirstLetter(student.name)}</li>
                // </Tooltip>
              )}
            />
          </Stack>
          {typeof selected.id !== "undefined" ? (
            <>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    sx={{ marginTop: 2 }}
                  >
                    {/* <div className="col-md-12 col-xs-12"> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        label="Date *"
                        // format="YYYY-MM-DD"
                        format="MM-DD-YYYY"
                        // value={dayjs(formInputs2.duration2)}
                        sx={{
                          width: "100%", // Set the width to 100% to take up the full available width
                          // marginTop: "20px",
                        }}
                        maxDate={dayjs()} // Set the maximum date to today's date
                      /> 
                    </LocalizationProvider>

                    {/* <TextField
                      fullWidth
                      label="Absent"
                      select
                      value={formInputs.absent}
                      onChange={handleChange}
                      name="absent"
                      required
                    >
                      <MenuItem value={false}>Present</MenuItem>
                      <MenuItem value={true}>Absent</MenuItem>
                    </TextField> */}

                    {/* </div> */}
                  </Stack>
                  {formInputs.absent === false ? (
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      sx={{ marginTop: 2 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {/* <DemoContainer components={['MobileTimePicker', 'MobileTimePicker']}> */}


                        <TimePicker
                   label={"Check In Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    openTo="hours"
                    selected={startTime}
                    views={["hours", "minutes"]}
                    onChange={handleStartTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  <TimePicker
                   label={"Check Out Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    selected={endTime}
                    views={["hours", "minutes"]}
                    onChange={handleEndTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                        {/* <MobileTimePicker
                          label={"Check In Time *"}
                          openTo="hours"
                          selected={startTime}
                          // ampm={false} // Use 24-hour clock format
                          views={["hours", "minutes"]}
                          // value={endTime}
                          onChange={handleStartTimeChange}
                          format="HH:mm" // Use 24-hour clock format
                          sx={{
                            width: "100%", // Set the width to 100% to take up the full available width
                            // marginTop: "20px",
                          }}
                        /> */}

                        {/* <MobileTimePicker
                          label={"Check Out Time *"}
                          openTo="hours"
                          selected={endTime}
                          // ampm={false} // Use 24-hour clock format
                          views={["hours", "minutes"]}
                          // value={endTime}
                          onChange={handleEndTimeChange}
                          format="HH:mm" // Use 24-hour clock format
                          sx={{
                            width: "100%",
                            // marginTop: "20px",
                          }}
                        /> */}

                        {/* </DemoContainer> */}
                      </LocalizationProvider>
                    </Stack>
                  ) : (
                    ""
                  )}

                  <Stack alignItems="end">
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      sx={{ width: "120px" }}
                    >
                      Add
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
