import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";
import { delInstructor } from "src/DAL/instructor";
import { useSnackbar } from "notistack";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
// ----------------------------------------------------------------------

export default function InstructorMoreMenu({
  row,
  instructors,
  onInstructorsUpdate,
}) {
  const [openDelete, setOpenDelete] = useState(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    //console.log(row);
    const result = await delInstructor(row.id);
    if (result.code === 200) {
      const updatedInstructors = instructors.filter(
        (instructor) => instructor.id !== row.id
      );
      onInstructorsUpdate(updatedInstructors);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {privilegesTypeCheckAndShowOrNot( 
          get_user_profile,
          get_user_privilege_access,
          "instructor",
          "edit"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/instructors/edit-instructor/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {/* <MenuItem
          component={RouterLink}
          to={`/instructors/detail-instructor/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="bx:detail" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "instructor",
          "delete"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

{privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "instructor",
          "admin"
        ) ? (
          <MenuItem
          component={RouterLink}
          to={`/reset-password/${row.user_id}`}
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
        >
          <ListItemIcon>
            <Iconify icon="mdi:password-reset" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Reset Password"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        ) : (
          ""
        )}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
    </>
  );
}
