import { format, formatDistanceToNow } from "date-fns";
import moment from "moment";

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function formatDateTimeWithZone(dateTime) {
  // Parse the date and time using moment
  const momentObj = moment(dateTime);
  // Format the date and time with time zone
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm : ss ) A Z");
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm A ) Z");
  const formattedDateTime = momentObj.format("MMMM D, YYYY ( hh : mm A ) Z");
  return formattedDateTime;
}
export function formatDateTimeWithOutZone(dateTime) {
  // Parse the date and time using moment
  const momentObj = moment(dateTime);
  // Format the date and time with time zone
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm : ss ) A Z");
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm A )");
  const formattedDateTime = momentObj.format("MMMM D, YYYY (hh:mm A)");
  // const formattedDate = momentObj.format("MMMM D, YYYY");
  // const formattedTime = momentObj.format("hh:mm A");

  return formattedDateTime;
}

export function formatDateWithDay(inputDate) {
  // Parse the input date string
  const parsedDate = moment(inputDate);

  // Format the date as "Tue, Sep 05, 2023"
  const formattedDate = parsedDate.format("ddd, MMM DD, YYYY");

  return formattedDate;
}

export function formatDateWithDayInCSV(inputDate) {
  // Parse the input date string
  const parsedDate = moment(inputDate);

  // Format the date as "Tue, Sep 05, 2023"
  const formattedDate = parsedDate.format("ddd - MMM DD YYYY");

  return formattedDate;
}

export function formatTime24Hours(startTime) {
  // Parse the time strings using moment
  const startMoment = moment(startTime, "HH:mm");
  // Format the start time and end time with AM/PM
  const formattedStartTime = startMoment.format("hh : mm A");
  const formattedTime = `${formattedStartTime}`;
  return formattedTime;
}

export function formatDateWithOutDay(inputDate) {
  // Parse the input date string
  const parsedDate = moment(inputDate);

  // Format the date as "Tue, Sep 05, 2023"
  const formattedDate = parsedDate.format("MMM DD, YYYY");

  return formattedDate;
}

export function formatNormalDate(dateTime) {
  const momentObj = moment(dateTime);
  const formattedDateTime = momentObj.format("DD/MM/yyyy");
  return formattedDateTime;
}

export function isValidDate(dateString) {
  const date = moment(dateString, "YYYY-MM-DD", true);

  if (date.isValid()) {
    return date.format("YYYY-MM-DD");
  } else {
    return null; // or false, or any other value indicating an invalid date
  }
}

export function getTimeDifference(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  const timeDifference = now.getTime() - date.getTime();
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const monthsDifference = Math.floor(daysDifference / 30);
  const yearsDifference = Math.floor(daysDifference / 365);

  if (secondsDifference < 60) {
    return "a few seconds ago";
  } else if (minutesDifference === 1) {
    return "a minute ago";
  } else if (minutesDifference < 60) {
    return `${minutesDifference} minutes ago`;
  } else if (hoursDifference === 1) {
    return "an hour ago";
  } else if (hoursDifference < 24) {
    return `${hoursDifference} hours ago`;
  } else if (daysDifference === 1) {
    return "a day ago";
  } else if (daysDifference < 7) {
    return `${daysDifference} days ago`;
  } else if (daysDifference === 7) {
    return "a week ago";
  } else if (daysDifference < 30) {
    return `${Math.floor(daysDifference / 7)} weeks ago`;
  } else if (monthsDifference === 1) {
    return "a month ago";
  } else if (monthsDifference < 12) {
    return `${monthsDifference} months ago`;
  } else if (yearsDifference === 1) {
    return "a year ago";
  } else {
    return `${yearsDifference} years ago`;
  }
}

export function formatDateTimeWithOutZoneForSupportTicket(dateTime) {
  // Parse the date and time using moment
  const momentObj = moment(dateTime);
  // Format the date and time with time zone
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm : ss ) A Z");
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm A )");
  const formattedDateTime = momentObj.format("MMM D, YYYY hh:mm A");
  // const formattedDate = momentObj.format("MMMM D, YYYY");
  // const formattedTime = momentObj.format("hh:mm A");
  return formattedDateTime;
}
export function onlyTime(dateTime) {
  // Parse the date and time using moment
  const momentObj = moment(dateTime);
  // Format the date and time with time zone
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm : ss ) A Z");
  // const formattedDateTime = momentObj.format("DD-MM-YYYY ( hh : mm A )");
  const formattedDateTime = momentObj.format("hh:mm A");
  // const formattedDate = momentObj.format("MMMM D, YYYY");
  // const formattedTime = momentObj.format("hh:mm A");

  return formattedDateTime;
}