import { filter } from "lodash";

import { useState, useEffect } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  ListItemIcon,
  Tooltip,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import {
  _list_collect_fee,
  _list_invoice,
  _list_payments,
  _list_transaction,
} from "src/DAL/collect_fee";
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  // { id: "last_name", label: "Last Name", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "father_name", label: "Father Name", alignRight: false },
  { id: "mobile", label: "Mobile", alignRight: false },
  // { id: "student_cnic", label: "CNIC", alignRight: false },
  // { id: "courses", label: "Courses", alignRight: false },
  { id: "gender", label: "Gender", alignRight: false },
  { id: "batch", label: "Batch", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "paid_fee", label: "Paid Fee", alignRight: false },
  { id: "paid_fee_type", label: "Source", alignRight: false },
  { id: "transaction_status", label: "Status", alignRight: false },
  { id: "refund_fee_reason", label: "", alignRight: false },

  // {
  //   id: "total_paid_registration_fee",
  //   label: "Paid Registration Fee",
  //   alignRight: false,
  // },
  // {
  //   id: "total_pending_registration_fee",
  //   label: "Pending Registration Fee",
  //   alignRight: false,
  // },
  // { id: "total_paid_fee", label: "Paid Course Fee", alignRight: false },
  // { id: "total_pending_fee", label: "Pending Course Fee", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Students() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/transaction/add-transaction");
  };

  const fetchStudents = async () => {
    // {{host}}api/invoice/list_invoice
    // const response = await listStudent();
    setIsLoading(true);
    const response = await _list_transaction({
      text: "",
    });
    console.log("response _list_transaction _list_transaction    ", response);

    if (response.code === 200) {
      setIsLoading(false);

      const students = response.transactions.map((payment, index) => ({
        // id: collect_fee._id,
        id: payment._id,
        name: payment.student.name ? payment.student.name : "",
        student_cnic: payment.student.cnic ? payment.student.cnic : "",
        mobile: payment.student.contact_number
          ? payment.student.contact_number
          : "",
        // courses: payment.student.courses,
        batch: payment.student.batch,
        // student_agreed_fee: payment.student.registration_fee,
        father_name: payment.student.father_name,
        // total_registration_fee: payment.student.total_registration_fee,
        // total_paid_registration_fee: payment.student.total_paid_registration_fee,
        // total_pending_registration_fee: payment.student.total_pending_registration_fee,
        // total_fee: payment.student.total_fee,
        // total_paid_fee: payment.student.total_paid_fee,
        // total_pending_fee: payment.student.total_pending_fee,
        gender: payment.student.gender,
        number: index + 1,

        type: payment.type,
        transaction_status: payment.transaction_status,
        paid_fee: payment.paid_fee,
        paid_fee_type: payment.bank
          ? payment.bank.name
          : payment.person
          ? payment.person.name
          : "Wallet",
        refund_fee_reason: payment.refund_fee_reason
          ? payment.refund_fee_reason
          : "",
      }));

      setStudents(students);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    const response = await _list_transaction({
      text: searchText,
    });
    console.log("response _list_transaction _list_transaction    ", response);

    if (response.code === 200) {
      const students = response.transactions.map((payment, index) => ({
        // id: collect_fee._id,
        id: payment._id,
        name: payment.student.name ? payment.student.name : "",
        student_cnic: payment.student.cnic ? payment.student.cnic : "",
        mobile: payment.student.contact_number
          ? payment.student.contact_number
          : "",
        // courses: payment.student.courses,
        batch: payment.student.batch,
        // student_agreed_fee: payment.student.registration_fee,
        father_name: payment.student.father_name,
        // total_registration_fee: payment.student.total_registration_fee,
        // total_paid_registration_fee: payment.student.total_paid_registration_fee,
        // total_pending_registration_fee: payment.student.total_pending_registration_fee,
        // total_fee: payment.student.total_fee,
        // total_paid_fee: payment.student.total_paid_fee,
        // total_pending_fee: payment.student.total_pending_fee,
        gender: payment.student.gender,
        number: index + 1,

        type: payment.type,
        transaction_status: payment.transaction_status,
        paid_fee: payment.paid_fee,
        paid_fee_type: payment.bank
          ? payment.bank.name
          : payment.person
          ? payment.person.name
          : "Wallet",
        refund_fee_reason: payment.refund_fee_reason
          ? payment.refund_fee_reason
          : "",
      }));
      setIsLoading(false);
      setStudents(students);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    SearchStudents("");
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };
  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Transaction List">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
          mt={2}
        >
          <Typography variant="h4">transaction List</Typography>
          <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New transaction
          </Button>
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
        >
          {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ padding: "10px" }}
            >
              <TextField
                label="Search"
                value={searchText}
                onChange={handleInputChange}
              />
              {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
              <Button variant="contained" onClick={handleReset}>
                Reset
              </Button>
            </Stack>

            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={students.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        name,
                        mobile,
                        // email,
                        // Course_Fee,
                        // student_agreed_fee,
                        // paid_fee,
                        // due_fee,
                        batch,
                        // courses,
                        student_cnic,
                        number,
                        father_name,
                        // total_registration_fee,
                        // total_paid_registration_fee,
                        // total_pending_registration_fee,
                        // total_fee,
                        // total_paid_fee,
                        // total_pending_fee,
                        gender,
                        type,
                        paid_fee,
                        paid_fee_type,
                        transaction_status,
                        refund_fee_reason,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      // let number = index + 1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {number}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {name}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {father_name}
                          </TableCell>

                          <TableCell align="left">{mobile}</TableCell>

                          {/* <TableCell align="left">{student_cnic}</TableCell> */}
                          {/* <TableCell align="left">
                            {" "}
                            <Avatar alt={first_name} src={s3baseUrl + image} />
                          </TableCell> */}

                          {/* <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === false && "error") || "success"}
                            >
                              {status ? "Active" : "Inactive"}
                          
                            </Label>
                          </TableCell> */}

                          <TableCell align="left" style={myStyle}>
                            {gender}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {/* {courses.length > 0
                              ? courses
                                  .map((course, index) => {
                                    return course !== null
                                      ? course.title
                                      : "N/A";
                                  })
                                  .join(", ")
                              : "N/A"} */}
                            {batch.name}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {type}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {paid_fee}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {paid_fee_type}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            <Button
                              variant="outlined"
                              color={
                                transaction_status === "paid"
                                  ? "success"
                                  : "warning"
                              }
                            >
                              {" "}
                              {transaction_status === "paid"
                                ? "Paid"
                                : transaction_status}
                            </Button>
                          </TableCell>

                          {transaction_status === "refund" &&
                          refund_fee_reason.length > 0 ? (
                            <Tooltip
                              title={
                                <div>
                                  Reason <br />
                                  {refund_fee_reason} <br />
                                </div>
                              }
                              arrow
                            >
                              <TableCell align="left" style={myStyle}>
                                <ListItemIcon>
                                  <Iconify
                                    icon="mdi:eye-outline"
                                    width={24}
                                    height={24}
                                  />
                                </ListItemIcon>
                              </TableCell>
                            </Tooltip>
                          ) : (
                            <TableCell align="left" style={myStyle}></TableCell>
                          )}

                          <TableCell align="right">
                            <StudentMoreMenu
                              row={row}
                              students={students}
                              onStudentsUpdate={handleStudentsUpdate}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[
              50,
              100,
              150,
              { value: students.length, label: "All" },
            ]}
            component="div"
            count={students.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
