import { filter } from "lodash";

import { useState, useEffect } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Grid,
  Tooltip,
  ListItemIcon,
  Box,
  Paper,
  TableHead,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import {
  _list_collect_fee,
  _list_invoice,
  _list_payments,
  _list_transaction,
  _student_transactions,
} from "src/DAL/collect_fee";
import { useSnackbar } from "notistack";
import { invoice_background, invoice_thank_you_img } from "src/assets";
import moment from "moment";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  // { id: "last_name", label: "Last Name", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "father_name", label: "Father Name", alignRight: false },
  { id: "mobile", label: "Mobile", alignRight: false },
  // { id: "student_cnic", label: "CNIC", alignRight: false },
  // { id: "courses", label: "Courses", alignRight: false },
  { id: "gender", label: "Gender", alignRight: false },
  { id: "batch", label: "Batch", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "paid_fee", label: "Paid Fee", alignRight: false },
  { id: "paid_fee_type", label: "Source", alignRight: false },
  { id: "transaction_status", label: "Status", alignRight: false },
  { id: "refund_fee_reason", label: "", alignRight: false },
  // {
  //   id: "total_paid_registration_fee",
  //   label: "Paid Registration Fee",
  //   alignRight: false,
  // },
  // {
  //   id: "total_pending_registration_fee",
  //   label: "Pending Registration Fee",
  //   alignRight: false,
  // },
  // { id: "total_paid_fee", label: "Paid Course Fee", alignRight: false },
  // { id: "total_pending_fee", label: "Pending Course Fee", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function StudentTransaction() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);

  const { id } = useParams();
  let studentID = id;

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [transaction, setTransaction] = useState([]);
  const [transactionData, setTransactionData] = useState({
   
    student: {
      batch: {
        _id: "",
        name: "",
      },
      cnic: "",
      contact_number: "",
      total_registration_fee: 0,
      _id: "",
      name: "",
      father_name: "",
      gender: "male",
    },
    type: "tuition",
    month: "October",
    paid_fee: 0,
    transaction_status: "refund",
    date: "2023-10-02T11:23:38.469Z",
    bank: {
      _id: "",
      name: "",
    },
    bank_receipt_number: "",
    wallet_id: null,
    refund_fee_reason:
      "",
    createdAt: "2023-10-02T11:17:07.301Z",
    updatedAt: "2023-10-02T11:23:38.473Z",
  });

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const styles = {
    table: {
      // borderCollapse: "collapse",
      width: "100%",
      border: "1px solid #b7b7b7", // Table border
      // border: "5px solid #b7b7b7", // Table border
      // borderBottom : "1px solid #f00"
    },
    tableCell: {
      border: "1px solid #b7b7b7", // Cell borders
      padding: "8px",
      textAlign: "left",
    },
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedEndTime = endMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

    return formattedTime;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  //   const handleNav = () => {
  //     navigate("/transaction/add-transaction");
  //   };
  const handleNav = () => {
    navigate(-1);
  };

  const fetchStudents = async () => {
    // {{host}}api/invoice/list_invoice
    // const response = await listStudent();
    setIsLoading(true);
    const response = await _student_transactions(studentID);
    console.log(
      "response _student_transactions _student_transactions    ",
      response
    );

    if (response.code === 200) {
      // setTransaction(response.transactions);

      if (
        Array.isArray(response.transactions) &&
        response.transactions.length > 0
      ) {
        setTransaction(response.transactions);
        setTransactionData(response.transactions[0]);
        // Rest of your code to process the response data
      }

      setIsLoading(false);
      const students = response.transactions.map((payment, index) => ({
        // id: collect_fee._id,
        id: payment._id,
        name: payment.student.name ? payment.student.name : "",
        student_cnic: payment.student.cnic ? payment.student.cnic : "",
        mobile: payment.student.contact_number
          ? payment.student.contact_number
          : "",
        // courses: payment.student.courses,
        batch: payment.student.batch,
        // student_agreed_fee: payment.student.registration_fee,
        father_name: payment.student.father_name,
        // total_registration_fee: payment.student.total_registration_fee,
        // total_paid_registration_fee: payment.student.total_paid_registration_fee,
        // total_pending_registration_fee: payment.student.total_pending_registration_fee,
        // total_fee: payment.student.total_fee,
        // total_paid_fee: payment.student.total_paid_fee,
        // total_pending_fee: payment.student.total_pending_fee,
        gender: payment.student.gender,
        number: index + 1,

        type: payment.type,
        transaction_status: payment.transaction_status,
        paid_fee: payment.paid_fee,
        paid_fee_type: payment.bank
          ? payment.bank.name
          : payment.person
          ? payment.person.name
          : "Wallet",
        refund_fee_reason: payment.refund_fee_reason
          ? payment.refund_fee_reason
          : "",
      }));

      setStudents(students);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    const response = await _list_transaction({
      text: searchText,
    });
    console.log("response _list_transaction _list_transaction    ", response);

    if (response.code === 200) {
      const students = response.transactions.map((payment, index) => ({
        // id: collect_fee._id,
        id: payment._id,
        name: payment.student.name ? payment.student.name : "",
        student_cnic: payment.student.cnic ? payment.student.cnic : "",
        mobile: payment.student.contact_number
          ? payment.student.contact_number
          : "",
        // courses: payment.student.courses,
        batch: payment.student.batch,
        // student_agreed_fee: payment.student.registration_fee,
        father_name: payment.student.father_name,
        // total_registration_fee: payment.student.total_registration_fee,
        // total_paid_registration_fee: payment.student.total_paid_registration_fee,
        // total_pending_registration_fee: payment.student.total_pending_registration_fee,
        // total_fee: payment.student.total_fee,
        // total_paid_fee: payment.student.total_paid_fee,
        // total_pending_fee: payment.student.total_pending_fee,
        gender: payment.student.gender,
        number: index + 1,

        type: payment.type,
        transaction_status: payment.transaction_status,
        paid_fee: payment.paid_fee,
        paid_fee_type: payment.bank
          ? payment.bank.name
          : payment.person
          ? payment.person.name
          : "Wallet",
        refund_fee_reason: payment.refund_fee_reason
          ? payment.refund_fee_reason
          : "",
      }));
      setIsLoading(false);
      setStudents(students);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    SearchStudents("");
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };
  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Transaction History">
      <Container maxWidth="xl">
        {transaction.length > 0 ? (
          <div className="row">
            <div className="hide_when_print" style={{ padding: "0 0px" }}>
              <IconButton onClick={handleNav} sx={{ flex: 0 }}>
                <Iconify icon="ep:back" />
              </IconButton>

              {/* <div
            style={{
              textAlign: "center",
              padding: "0 15px",
              paddingTop: "20px",
            }}
          >
            <Typography variant="h5">
              <span style={{ fontWeight: "bolder" }}>Transaction Details </span>{" "}
            
            </Typography>
          </div> */}
            </div>

            {/* <hr /> */}

            <div className="container-fluid  col_setting_and_100_at_print_screen ">
              <div>
                {/* <div style={{ backgroundImage: `url(${invoice_background})`, backgroundSize: 'cover', width: '100%', height: '249px',paddingTop:360 }}> */}
                <Box>
                  <img
                    alt="Background"
                    src={invoice_background}
                    width="100%"
                    height="200px"
                    // height={/* specify your desired height */}
                  />
                </Box>
                <TableContainer component={Paper}>
                  <div>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={4.5}
                      // mt={2}
                      className="px-5"
                    >
                      <div>
                        <h6>{formatDate(transactionData.date)} </h6>
                      </div>

                      <div>
                        <h3>From:</h3>
                        <h6>{transactionData.student.name} </h6>
                        <h6>{transactionData.student.contact_number} </h6>
                        <h6>
                          {transactionData.student.gender === "male"
                            ? "Male"
                            : transactionData.student.gender === "Male"
                            ? "Male"
                            : transactionData.student.gender === "female"
                            ? "Female"
                            : transactionData.student.gender === "Female"
                            ? "Female"
                            : "Male"}{" "}
                        </h6>
                      </div>
                    </Stack>
                  </div>
                  <Table
                  className="student_history_table"
                    //  sx={{ minWidth: 650 }}
                    style={styles.table}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Name</TableCell> */}
                        {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Father</TableCell> */}
                        {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Contact</TableCell> */}
                        {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Gender</TableCell> */}
                        {/* <TableCell>CNIC</TableCell> */}
                        <TableCell className="student_history_table_head_cell">
                          Batch
                        </TableCell>
                        <TableCell className="student_history_table_head_cell">
                          Type
                        </TableCell>
                        <TableCell className="student_history_table_head_cell">
                          Month
                        </TableCell>
                        {/* <TableCell>Date</TableCell> */}
                        <TableCell className="student_history_table_head_cell">
                          Paid Fee
                        </TableCell>
                        <TableCell className="student_history_table_head_cell">
                          Source
                        </TableCell>
                        <TableCell className="student_history_table_head_cell">
                          Status
                        </TableCell>

                        {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transaction.map((row) => (
                        <TableRow
                          //   key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {/* <TableCell component="th" scope="row">
                    {transaction.student.name}
                  </TableCell> */}
                          {/* <TableCell> {transaction.student.father_name}</TableCell>
                  <TableCell> {transaction.student.contact_number}</TableCell>
                  <TableCell> {transaction.student.gender}</TableCell> */}
                          {/* <TableCell> {transaction.student.cnic}</TableCell> */}
                          <TableCell style={styles.tableCell}>
                            {" "}
                            {row.student.batch.name}
                          </TableCell>
                          <TableCell style={styles.tableCell}>
                            {" "}
                            {row.type}
                          </TableCell>
                          <TableCell style={styles.tableCell}>
                            {" "}
                            {row.month}
                          </TableCell>
                          {/* <TableCell> {formatDate(transaction.date)}</TableCell> */}
                          <TableCell style={styles.tableCell}>
                            {" "}
                            {row.paid_fee}
                          </TableCell>
                          <TableCell style={styles.tableCell}>
                            {" "}
                            {row.bank
                              ? row.bank.name
                              : row.person
                              ? row.person.name
                              : "Wallet"}
                          </TableCell>
                          <TableCell style={styles.tableCell}>
                            <Button
                              variant="outlined"
                              color={
                                row.transaction_status === "paid"
                                  ? "success"
                                  : "info"
                              }
                            >
                              {" "}
                              {row.transaction_status}
                            </Button>
                          </TableCell>

                          {/* <TableCell align="right"> {transaction.month}</TableCell> */}
                          {/* <TableCell align="right">0</TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <hr /> */}

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={4.5}
                  // mt={2}
                  className="px-5"
                >
                  {/* <h6>{formatDate(transaction.date)} </h6> */}
                  <div></div>
                  {/* <div> */}
                  <Box>
                    <img
                      alt="Thank you"
                      src={invoice_thank_you_img}
                      // width={/* specify your desired width */}
                      // height={/* specify your desired height */}
                    />
                  </Box>
                  {/* <Avatar alt="Thank you" src={invoice_thank_you_img} /> */}
                  {/* </div> */}
                </Stack>
              </div>
            </div>
            {/* <hr /> */}
          </div>
        ) : (
          <>
          <div className="row">
            <div className="hide_when_print" style={{ padding: "0 0px" }}>
              <IconButton onClick={handleNav} sx={{ flex: 0 }}>
                <Iconify icon="ep:back" />
              </IconButton>
            </div>

            <div className="container-fluid  col_setting_and_100_at_print_screen ">
              <div>
                {/* <div style={{ backgroundImage: `url(${invoice_background})`, backgroundSize: 'cover', width: '100%', height: '249px',paddingTop:360 }}> */}
                <Box>
                  <img
                    alt="Background"
                    src={invoice_background}
                    width="100%"
                    height="200px"
                    // height={/* specify your desired height */}
                  />
                </Box>
                <TableContainer component={Paper}>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <div className="text-center">
                      <h1>Not Found!</h1>
                      <p>Sorry, no results were found for the student you're looking for. Please try a different student's details and try again.</p>
                  </div>
                  {/* <SearchNotFound searchQuery={"student"} /> */}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </TableContainer>
          </div>
          </div>
          </div>
         </>
        )}
      </Container>
    </Page>
  );
}
