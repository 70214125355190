import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";

// hooks
import useResponsive from "../../hooks/useResponsive";
import { useAppContext } from "src/hooks";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import navConfig from "./NavConfig";
import { s3baseUrl } from "src/config/config";
import { _logout } from "src/DAL/auth";
import DelPopup from "src/components/DelPopup";
import { _get_user_roles } from "src/DAL/role";
import { _list_user_notifications } from "src/DAL/notifications";
import { sub } from "date-fns";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
const LogoutContainer = styled("div")(({ theme }) => ({
  paddingInline: theme.spacing(2.5),
  paddingBlock: theme.spacing(1),
  marginTop: 15,
  backgroundColor: "#ffff",
  position: "absolute",
  width: "100%",
  bottom: "0",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  isLoading,
  setIsLoading,
  count,
  setCount,
  supportCount,
  setSupportCount,
  announcementCount,
  setAnnouncementCount,

  notifications, 
  setNotifications,

  countNotifications, 
  setCountNotifications,
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { _get_user_profile } = useAppContext();
  const isDesktop = useResponsive("up", "lg");
  const profile = _get_user_profile();
  const [openDelete, setOpenDelete] = useState(false);

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const LogoutAPI = async () => {
    setIsLoading(true);
    const response = await _logout();

    console.log("response _logout", response);
    if (response.code === 200) {
      localStorage.clear();
      navigate("/login", { replace: true });

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getUserNotifications = async () => {
    setIsLoading(false);
    const response = await _list_user_notifications();
    console.log(
      "response _test _list_user_notifications",
      response,
    );

    if (response.code === 200) {
      // getTimeDifference

      console.log("response 1  ____notifications", response);

      const allNotifications = response.notifications.map(
        (notification, index) => ({
          ...notification,

          id: notification._id,
          title: notification.title,
          description: notification.message,
          avatar: null,
          type: notification.notification_type,
          // createdAt: getTimeDifference(notification.createdAt),
          // createdAt: sub(new Date(), new Date(notification.createdAt)),
          // createdAt : sub(new Date(), new Date('2024-02-29T11:17:08.189Z')),
          createdAt: sub(new Date(notification.createdAt), new Date()),

          isUnRead: !notification.read_status,
        })
      );
      console.log("allNotifications 2 ____notifications", allNotifications);
      setNotifications(allNotifications);
      setCountNotifications(response.count);
      console.log("allNotifications 3 ____notifications", allNotifications);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };


  const fetchUserRoles = async () => {
    // setIsLoading(true);
    const response = await _get_user_roles();
    if (response.code === 200) {
      setCount(response.contact_count);
      setSupportCount(response?.waiting_support_ticket_count);
      setAnnouncementCount(response?.unread_announcement_count);

      // console.log("this is sidebar  ____sidebar");
    } else {
      // enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const handleLogout = () => {
    LogoutAPI();

    // localStorage.clear();
    // navigate("/login", { replace: true });
  };

  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleLogout();
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    fetchUserRoles();
    getUserNotifications();
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 1.5,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo />
      </Box>

      <Box sx={{ mb: 1.5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/profile">
          <AccountStyle>
            {profile.image ? (
              <>
                <Avatar
                  src={s3baseUrl + profile.image}
                  alt={profile.first_name}
                />
              </>
            ) : (
              <>
                <Avatar
                  sx={{ bgcolor: (theme) => theme.palette.primary.main }}
                  alt={profile.first_name}
                >
                  {profile.first_name.charAt(0)}
                </Avatar>
              </>
            )}
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {profile.first_name} {profile.last_name}
              </Typography>
              {/* <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {profile.role?.title}
              </Typography> */}
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "0.6rem", color: "#0171cf" }}
              >
                {profile.role?.title}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection
        count={count}
        setCount={setCount}
        supportCount={supportCount}
        setSupportCount={setSupportCount}
        announcementCount={announcementCount}
        setAnnouncementCount={setAnnouncementCount}
        sx={{ paddingBottom: "50px" }}
        navConfig={navConfig}
      />
      {/* <Box sx={{ flexGrow: 1 }} /> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          <LogoutContainer>
            <Button onClick={handleDeleteClick} variant="contained" fullWidth>
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
          <LogoutContainer>
            {/* <Button onClick={handleLogout} variant="contained" fullWidth> */}
            <Button onClick={handleDeleteClick} variant="contained" fullWidth>
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}

      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirmation"
        message="Are you sure you want to Logout?"
        onOk={handleDeleteOk}
      />
    </RootStyle>
  );
}
