import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"; 
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";
import { useSnackbar } from "notistack";
import { delCourse } from "src/DAL/course";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
// ----------------------------------------------------------------------

export default function CourseMoreMenu({ row, courses, onCoursesUpdate }) {
  const [openDelete, setOpenDelete] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();


  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    //console.log(row);
    const result = await delCourse(row.id);
    if (result.code === 200) {
      const updatedCourses = courses.filter((course) => course.id !== row.id);
      onCoursesUpdate(updatedCourses);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* {get_user_profile.type == 3 &&
        get_user_privilege_access.course.edit === true ? ( */}

          {privilegesTypeCheckAndShowOrNot(
            get_user_profile,
            get_user_privilege_access,
            "course",
            "edit"
          ) ? (

          <MenuItem
            component={RouterLink}
            to={`/courses/edit-course/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

        {/* <MenuItem
          component={RouterLink}
          to={`/courses/detail-course/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="bx:detail" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
       {privilegesTypeCheckAndShowOrNot(
            get_user_profile,
            get_user_privilege_access,
            "course",
            "delete"
          ) ? (
          <MenuItem
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

<MenuItem
            component={RouterLink}
            to={`/courses/overview-course/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="ic:sharp-view-list" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Overview"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
<MenuItem
            component={RouterLink}
            to={`/courses/outline-course/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="humbleicons:view-grid" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Outline"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
<MenuItem
            component={RouterLink}
            to={`/courses/review-course/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="material-symbols-light:rate-review-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Review"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
 

      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
    </>
  );
}
