import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";

import { detailTestimonial, editTestimonial } from "src/DAL/testimonial";
import { listActivePage } from "src/DAL/page";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import { _detail_slider, _edit_slider } from "src/DAL/slider";
import TinyEditor from "src/components/Ckeditor";
const EditSlider = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const [image, setImage] = useState();
  const [pages, setPages] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    title: "",

    description: "",
    created_for: "",
    pages: "",
    button_text: "",
    button_link: "",
    status: "true",
    image: "",
    order: "",
    type: 2,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    handleFormSubmit(image);
    setImage("");
    // console.log(image);
    setShow(false);
  }
  const handleFormSubmit = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let error = "";

    if (!pathOfUploadedFile) {
      error = "Image is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (formInputs.type === 2) {
    if (!detailDescriptionEditor) {
      error = "Description is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }
    }

    let postData = {
      title: formInputs.title,
      type: formInputs.type,
      image: pathOfUploadedFile,
      description: detailDescriptionEditor,
      // description: formInputs.description,
      status: formInputs.status,
      //   page_id: "65e60f718a889a3a74f3402d",
      page_id: Array.isArray(formInputs.pages)
        ? formInputs.pages
        : formInputs.pages,
      //   page_id: Array.isArray(formInputs.pages)
      //     ? formInputs.pages
      //     : [formInputs.pages],
      button_text: formInputs.button_text,
      button_link: formInputs.button_link,
      order: formInputs.order,
    };

    if (formInputs.type === 1) {
      postData = {
        title: formInputs.title,
        type: formInputs.type,
        image: pathOfUploadedFile,
        // description: formInputs.description,
        status: formInputs.status,
        //   page_id: "65e60f718a889a3a74f3402d",
        page_id: Array.isArray(formInputs.pages)
          ? formInputs.pages
          : formInputs.pages,
        //   page_id: Array.isArray(formInputs.pages)
        //     ? formInputs.pages
        //     : [formInputs.pages],
        // button_text: formInputs.button_text,
        // button_link: formInputs.button_link,
        order: formInputs.order,
      };
    }

    console.log("postData ____postData", postData);
    setIsLoading(true);
    const result = await _edit_slider(id, postData);
    if (result.code === 200) {
 
      navigate(-1);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      setTimeout(() => {
        setIsLoading(false);
    }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTestimonialDetail = async () => {
    setIsLoading(true);
    const response = await _detail_slider(id);

    console.log("_detail_slider ____detail_slider", response);

    if (response.code === 200) {
      // const pageIds = response.slider.page_id.map((page) => page._id);
      const pageIds = response.slider.page_id;
      // console.log(data.instructor.first_name);
      // response.slider
      setDetailDescriptionEditor(response.slider.description);
      setFormInputs({
        ...formInputs,
        ...response.slider,

        // name: response.testimonial.name,
        // title: response.testimonial.title,
        // description: response.testimonial.description,
        // created_for: response.testimonial.created_for,
        // pages: pageIds,
        pages: pageIds,
        // button_text: response.testimonial.button_text,
        // status: response.testimonial.status,
        // button_link: response.testimonial.button_link,
        // image: response.testimonial.image,
        // order: response.testimonial.order,

        // "_id": "65e801056a0d19478088dff0",
        // "title": "zzzz",
        // "type": 2,
        // "image": "file/8f723130-db7b-11ee-89df-c78ea2e24dd2",
        // "description": "zzzz",
        // "status": true,
        // "page_id": "65e6cc46608f82a619c16b05",
        // "button_text": "",
        // "button_link": "",
        // "createdAt": "2024-03-06T05:37:09.759Z",
        // "updatedAt": "2024-03-06T05:37:09.759Z"
      });
      if (response.slider.image) {
        setPathOfUploadedFile(response.slider.image);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActivePages = async () => {
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getTestimonialDetail();
    // fetchActivePages();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Slider">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">Edit Slider</h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Type"
                  select
                  value={formInputs.type}
                  onChange={handleChange}
                  name="type"
                  required
                >
                  <MenuItem value={1}>Only Image</MenuItem>
                  <MenuItem value={2}>General</MenuItem>
                </TextField>
              </Stack>

              {formInputs.type === 2 ? (
                
                <div className="col-12">
                <p className="assignment_sub_heading_in_add_edit">
                Description *
                </p>
  
                <TinyEditor
                  detailDescriptionCk={detailDescriptionEditor}
                  setDetailDescription={setDetailDescriptionEditor}
                />
              </div>
              ) : (
                ""
              )}

<Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {/* <TextField
                fullWidth
                label="Page"
                select
                value={formInputs.pages}
                onChange={handleChange}
                name="pages"
                required
                // SelectProps={{ multiple: true }}
                disabled
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.title}
                  </MenuItem>
                ))}
              </TextField> */}

              <TextField
                fullWidth
                label="Order"
                value={formInputs.order}
                onChange={handleChange}
                name="order"
                required
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              </Stack>

              {formInputs.type === 2 ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Button Text"
                    value={formInputs.button_text}
                    onChange={handleChange}
                    name="button_text"
                    autoComplete="off"
                    // required
                  />
                  <TextField
                    fullWidth
                    label="Button Link"
                    value={formInputs.button_link}
                    onChange={handleChange}
                    name="button_link"
                    autoComplete="off"
                    // required
                  />
                </Stack>
              ) : (
                ""
              )}

              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File *"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default EditSlider;
