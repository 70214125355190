import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import moment from "moment"; 
import { _active_time_preference } from "src/DAL/timepreference";
import { capitalizeFirstLetter } from "src/utils/formatText";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [timePreferences, setTimePreferences] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    // last_name: "",
    email: "",
    gender: 1,
    course: "",
    others: [],
    mobile_number: "",
    fee_status: "paid",
    enquiry_status: "active",
    enquiry_status_reason: "",
    education: "",
    image: "",
    address: "",
    reference: "",
    batches: [],
    dob: "",
    

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    time_preferences: "",
    // date_of_birth: null, // Set initial value to null
    // date_of_birth : dayjs("2001-03-03").toDate(),
  });

  const myStyle = {
    textTransform: "capitalize",
  };

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);
  
  
    const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
  

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "mobile_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("iiikkkkkkkkkkkkkkkk", image);
    console.log("formInputs", formInputs);
    // const data = new FormData();

    const postData = {
      name: formInputs.name,
      father_name: formInputs.father_name,
      // "date_of_birth": formInputs.dob,
      // "email":formInputs.email,
      contact_number: formInputs.mobile_number,
      gender: formInputs.gender,

          // "course":formInputs.course
          course: formInputs.course,
          // "course":['6411a93af826ae7b1c8faeac']
          // course.0: 6411a93af826ae7b1c8faeac

      referred_by: formInputs.reference,
      // enquiry_status: formInputs.enquiry_status,

      // enquiry_status_reason: formInputs.enquiry_status_reason.trim(),
      note: formInputs.note,
      // decided_registration_fee: formInputs.decided_registration_fee,
      // decided_course_fee: formInputs.decided_course_fee,


      latest_degree: formInputs.latest_degree,
      degree_status: formInputs.degree_status,
      completion_year: formInputs.degree_status === "complete" ? (typeof selectedYear === 'number' ? selectedYear.toString() : selectedYear) : "",

      institution_name: formInputs.institution_name,
      time_preference_id: formInputs.time_preferences,
      semester: formInputs.semester,

   
    };
    console.log("postData postData", postData);
    // const result = await _add_enquiry_form(postData);
    const result = await _edit_enquiry_student(id, postData);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/enquiry-students");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }

    // data.append("first_name", formInputs.first_name.trim());
    // data.append("last_name", formInputs.last_name.trim());
    // data.append("email", formInputs.email.trim());
    // data.append("gender", formInputs.gender);
    // data.append("courses", JSON.stringify(formInputs.courses));
    // data.append(
    //   "batch_id",
    //   JSON.stringify(
    //     Array.isArray(formInputs.batches)
    //       ? formInputs.batches
    //       : [formInputs.batches]
    //   )
    // );
    // data.append("contact_number", formInputs.contact_number);
    // data.append("education", formInputs.education);
    // data.append("address", formInputs.address);
    // data.append("profile_image", image ? image : "");
    // data.append("fee_status", formInputs.fee_status);
    // data.append("status", formInputs.status);
    // // console.log(data.get("image"), "FroData ImageImageImageImageImage");
    // // console.log(image, "state ImageImageImageImageImage");
    // const result = await editStudent(id, data);
    // if (result.code === 200) {
    //   setIsLoading(false);
    //   navigate("/enquiry-students");
    //   enqueueSnackbar("Edited Successfully!", { variant: "success" });
    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_enquiry_student(id);
    console.log("_detail_enquiry_student _detail_enquiry_student", response);
    // enquiry_form

    if (response.code === 200) {
      // setCourses(response.enquiry_form.course);
      let courseIds = [];
      // if (response.enquiry_form.course.length > 0) {
      //   courseIds = response.enquiry_form.course.map((_course) => _course._id);
      // } 
      // if (response.enquiry_form.course.length > 0) {
      //   courseIds = response.enquiry_form.course.map((_course) => _course._id);
      // } else if (response.enquiry_form.others.length > 0) {
      //   courseIds = response.enquiry_form.others.map((_others) => _others._id);
      // }
      // console.log("courseIds courseIds courseIds", courseIds);
      // console.log(
      //   "response.enquiry_form.others.length response.enquiry_form.others.length > 0",
      //   response.enquiry_form.others.length > 0
      // );
      // const batchIds = response.student.batch_id.map((batch) => batch._id);
      // console.log(batchIds, "batchIdsbatchIds");
      // console.log(data.student.first_name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];

      // let defaultDate = new Date();
      // if(response.enquiry_form.date_of_birth!==null && response.enquiry_form.date_of_birth!==""){
      //  defaultDate = new Date(response.enquiry_form.date_of_birth);
      // }

      let response_gender = response.enquiry_form.gender;
      // let gender_set = "male";

      // if (response_gender === "female") {
      //   gender_set = "female";
      // } else if (response_gender === "Female") {
      //   gender_set = "female";
      // }

      // enquiry_status
// console.log('response.enquiry_form.enquiry_status.toString(),',response.enquiry_form.enquiry_status.toString());
      let response_enquiry_status= response.enquiry_form.enquiry_status?response.enquiry_form.enquiry_status:false;
  

      if (response_enquiry_status === "false" || response_enquiry_status === false) {
        response_enquiry_status = "active";
      } else if (response_enquiry_status === "true" || response_enquiry_status === true) {
        response_enquiry_status = "expire";
      }

      setSelectedYear(response.enquiry_form.completion_year);

      setFormInputs({
        name: capitalizeFirstLetter(response.enquiry_form.name),
        father_name: capitalizeFirstLetter(response.enquiry_form.father_name),


        // decided_registration_fee: response.enquiry_form.decided_registration_fee,
        // decided_course_fee: response.enquiry_form.decided_course_fee,
        note: response.enquiry_form.note,
        // enquiry_status_reason: response.enquiry_form.enquiry_status_reason,
        // email: response.enquiry_form.email,
        // enquiry_status: response.enquiry_form.enquiry_status.toString(),
        // enquiry_status: response_enquiry_status,

        gender: response.enquiry_form.gender,

        mobile_number: response.enquiry_form.contact_number,
        reference: response.enquiry_form.referred_by,
        // date_of_birth: response.enquiry_form.date_of_birth,
        // date_of_birth: defaultDate.toISOString().split("T")[0],
        // dob: defaultDate.toISOString().split("T")[0],
 
        latest_degree: response.enquiry_form.latest_degree,
        degree_status: response.enquiry_form.degree_status,
        completion_year: response.enquiry_form.completion_year,
        institution_name: response.enquiry_form.institution_name,
        semester: response.enquiry_form.semester,
        time_preferences: response.enquiry_form.time_preference?response.enquiry_form.time_preference._id:"",

         

        course: response.enquiry_form.course._id,
        // course: courseIds,
        others: [],
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  // const fetchActiveBatches = async (batchIds) => {
  //   const response = await listActiveBatch();

  //   if (response.code === 200) {
  //     const batchesList = response.batches.map((batch) => ({
  //       id: batch._id,
  //       name: batch.name,
  //       student_count: batch.student_count,
  //       status: batch.status,
  //       courses: batch.courses,
  //     }));
  //     // console.log(batchesList, "jjjjj");

  //     // console.log(batchIds[0], "kkkkkkkkk");

  //     const selectedBatchIndex = batchesList.findIndex(
  //       (batch) => batch.id === batchIds[0]
  //     );

  //     // console.log(selectedBatchIndex, "selectedBatchselectedBatch");
  //     // console.log("cccc");
  //     setCourses(batchesList[selectedBatchIndex].courses);

  //     setBatches(batchesList);
  //   } else {
  //     enqueueSnackbar(response.message, { variant: "error" });
  //   }
  // };
  const fetchActiveCourse = async () => {
    const response = await listCourse();
    console.log("response", response);
    if (response.code === 200) {
      // const batches = response.batches.map((batch) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   student_count: batch.student_count,
      //   status: batch.status,
      //   courses: batch.courses,
      // }));
      setCourses(response.courses);
      // setBatches(batches);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchTimePreference = async () => {
    // setIsLoading(true);
    const response = await _active_time_preference();
    console.log("response  _active_time_preference __test", response);

    //

    if (response.code === 200) {
      // const courses = response.courses
      //   .filter((course) => course.status === true)
      //   .map((course) => ({
      //     id: course._id,
      //     fee: course.fee,
      //     title: course.title,
      //     description: course.description,
      //     duration: course.duration,
      //     // image: course.image,
      //     status: course.status,
      //   }));
      // console.log("courses courses courses courses", courses);
      // setCourses(courses);
      setTimePreferences(response.time_preferences);

      if (response.time_preferences.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          time_preferences: response.time_preferences[0]._id,
        }));
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveCourse();
    fetchTimePreference();
  }, []);
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Student">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Enquiry Student </h4>
        </Grid>
		 

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Father Name"
                  value={formInputs.father_name}
                  onChange={handleChange}
                  name="father_name"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {/* <TextField
                  fullWidth
                  type="email"
                  label="Email address"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  autoComplete="off"
                  // required
                  inputProps={{
                    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                    title: "Please enter a valid email address",
                  }}
                /> */}
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.mobile_number}
                  onChange={handleChangePhone}
                  name="mobile_number"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Gender"
                  select
                  required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Reference By"
                  value={formInputs.reference}
                  onChange={handleChange}
                  name="reference"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                {/* <TextField
                  fullWidth
                  label="Expire / Do Not Call"
                  select
                  value={formInputs.enquiry_status}
                  onChange={handleChange}
                  name="enquiry_status"
                  required
                >
                  <MenuItem value="active">Not Expire</MenuItem>
                  <MenuItem value="expire">Expire / Do Not Call</MenuItem>
                  <MenuItem value="do_not_pick_call">Do Not Pick Call</MenuItem>
                  <MenuItem value="number_close">Number Close</MenuItem>
                  <MenuItem value="wrong_number">Wrong Number</MenuItem>

 
                </TextField> */}
              </Stack>

              <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.course}
                onChange={handleChange}
                name="course"
                required
                SelectProps={{ multiple: false }}
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course._id}>
                    {capitalizeFirstLetter(course.title)}
                  </MenuItem>
                ))}
              </TextField>

              {/* <div className="col-md-12 col-xs-12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth *"
                    format="YYYY-MM-DD"
                    // value={formInputs?.date_of_birth}
                    value={dayjs(formInputs.date_of_birth)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>
              </div> */}

{/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  // type="number"
                  label="Decided Registration fee"
                  value={formInputs.decided_registration_fee}
                  // required
                  onChange={handleChange}
                  name="decided_registration_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                />

                <TextField
                  fullWidth
                  // type="number"
                  label="Decided Course fee (Monthly)"
                  value={formInputs.decided_course_fee}
                  // required
                  onChange={handleChange}
                  name="decided_course_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                />
              </Stack> */}

  
              <TextField
                fullWidth
                label="Time Preferences"
                select
                value={formInputs.time_preferences}
                onChange={handleChange}
                name="time_preferences"
                // required
                // style={myStyle} // Apply the style here
                SelectProps={{ multiple: false }}
              >
                {timePreferences.map((time_preferences) => (
                  <MenuItem
                    key={time_preferences._id}
                    value={time_preferences._id}
                    // style={myStyle} // Apply the style here
                  >
                    {capitalizeFirstLetter(time_preferences.type)} ({formatTime(time_preferences.start_time)} to{" "}
                    {formatTime(time_preferences.end_time)} )
                  </MenuItem>
                ))}
              </TextField>


              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Latest Degree"
                  value={formInputs.latest_degree}
                  onChange={handleChange}
                  name="latest_degree"
                />

                <TextField
                  fullWidth
                  label="Degree Status"
                  select
                  value={formInputs.degree_status}
                  onChange={handleChange}
                  name="degree_status"
                  required
                >
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="inprogress">Inprogress</MenuItem>
                </TextField>
              </Stack>
              
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {formInputs.degree_status ==="complete"?
              //   <TextField
              //   fullWidth
              //   label="Completion Year"
              //   value={formInputs.completion_year}
              //   onChange={handleChange}
              //   name="completion_year"
              // />
              <TextField
              fullWidth
              label="Completion Year"
              select
              value={selectedYear}
              onChange={handleYearChange}
              name="degree_status"
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
              :""
              }
                 

              <TextField
                  fullWidth
                  label="Institution Name"
                  value={formInputs.institution_name}
                  onChange={handleChange}
                  name="institution_name"
                />

{formInputs.degree_status ==="inprogress"?
              <TextField
                  fullWidth
                  label="Semester"
                  value={formInputs.semester}
                  onChange={handleChange}
                  name="semester"
                />:""
              }
              </Stack>
              
 
              <TextField
                fullWidth
                label="Note"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.note}
                onChange={handleChange}
                name="note"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <div>
                {/* {formInputs.enquiry_status === false ? ( */}
                {formInputs.enquiry_status === "expire" ? (
                  <TextField
                    fullWidth
                    label="Expire Reason"
                    value={formInputs.enquiry_status_reason}
                    onChange={handleChange}
                    name="enquiry_status_reason"
                    // inputProps={{
                    //   pattern: "[A-Za-z]{3,}",
                    //   title:
                    //     "Please enter at least 3 letters with only alphabetical characters.",
                    // }}
                  />
                ) : (
                  ""
                )}
              </div>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
