import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { _get_instructor_batches, _get_student_fee, addBatch, listBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";
import { _add_fee_voucher, _create_custom_voucher } from "src/DAL/voucher";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { _post_assignment_from_admin } from "src/DAL/assignment";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Icon } from "@iconify/react";

import { styled } from "@mui/material/styles";
import { addImage } from "src/DAL/uploadfiles";
import TinyEditor from "src/components/Ckeditor";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import CustomImageUploaderMulti from "src/components/GeneralComponents/CustomImageUploaderMulti";
const Input = styled("input")({
  display: "none",
});

const AddAssignment = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");

  const [pathOfUploadedFile, setPathOfUploadedFile] = useState([]);

  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);
  const [instructors, setInstructors] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batcheFees, setbatcheFees] = useState([]);
  const [batcheStudents, setBatcheStudents] = useState([]);
  const [registrationFee, setRegistrationFee] = useState("");

  const [feeDiscount, setFeeDiscount] = useState("");

  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [acceptableFile, setAcceptableFile] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: true,
    registration_fee: 0,
    batch_discount: 0,
    batch_id: "",
    additional_discount: 0,
    additional_discount_reason: "",
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array

    batch_student_id: [],
    batch_fee_id: "",
    voucher_type: 2,
    registration_fee: "",
    isStudentFree: false,
    registration_fee_discount: "",
    voucher_to: 2,
    fee_title: "",
    fee_amount: "",
    note: "",
    assignment_type: 1,
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [feeDiscountChecked, setFeeDiscountChecked] = useState(false);

  const myStyle = {
    textTransform: "capitalize",
  };

  const handleCheckboxFeeChange = (event) => {
    setFeeDiscountChecked(event.target.checked);
  };

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleStudentChange = (e) => {
    const { target } = e;
    console.log("target __test", target);

    // Use Array.prototype.find to locate the desired object
    let foundObject = batcheStudents.find((obj) => obj._id === target.value);
    // let FreeStudent = false;

    if (foundObject) {
      if (foundObject.fee_status === 3) {
        setFormInputs({
          ...formInputs,
          [target.name]: target.value,
          voucher_type: 1,
        });
      } else {
        setFormInputs({
          ...formInputs,
          [target.name]: target.value,
          // isStudentFree:false,
        });
      }
    } else {
      setFormInputs({
        ...formInputs,
        [target.name]: target.value,
        // isStudentFree:false,
      });
    }
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      batch_student_id: [],
      batch_fee_id: "",
    });

    // Use Array.prototype.find to locate the desired object
    let foundObject = batches.find((obj) => obj.id === target.value);

    if (foundObject) {
      console.log("foundObject ___foundObject", foundObject);

      let filteredData = foundObject.students
        .filter((student) => student.student_status === 1)
        .map((student) => ({
          ...student,
          id: student._id,
          name: student.name,
          fatherName: student.father_name,
          contactNumber: student.contact_number,
        }));

      console.log("filteredData  ___filteredData ___foundObject", filteredData);

      setbatcheFees(foundObject.batch_fee);
      setBatcheStudents(filteredData);
      // setBatcheStudents(foundObject.students);
      setRegistrationFee(foundObject.registration_fee);
    } else {
      setbatcheFees([]);
      setBatcheStudents([]);
      setRegistrationFee("");
    }

    // const { name, value } = e.target;
    // console.log("name", name);
    // console.log("value", value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   // [name]: value,
    //   courses: value,
    // }));

    // if(value){

    // }
    // fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const fileChangedHandler = (event) => {
    const selectedFile = event.target.files[0];
    console.log("selectedFile  ___selectedFile", selectedFile);

    if (selectedFile) {
      if (selectedFile.size <= 1 * 1024 * 1024) {
        // If the file is 1MB or smaller
        setImage(selectedFile);
        setPreviews(URL.createObjectURL(selectedFile));
        setAcceptableFile("true");
        setMessage("Acceptable File");
        setColor("success");
      } else {
        // If the file is larger than 5MB
        setAcceptableFile("false");
        setMessage("Error: The file size exceeds the maximum allowed limit.");
        setColor("danger");
      }
    }

    // console.log("event", event);
    // console.log("event.target.files[0]", selectedFile);
    // console.log(
    //   "URL.createObjectURL(event.target.files[0])",
    //   URL.createObjectURL(selectedFile)
    // );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inside");
    let error = "";
    const formData = new FormData();

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Dead Line Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("endDate", endDate);
    if (!endDate) {
      error = "Assigned Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (!detailDescriptionEditor || detailDescriptionEditor === "") {
      error = "Assignment Note is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Dead Line Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }
    // Validate the selected date format
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedEndDate);
    if (!moment(formattedEndDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Assigned Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    let feeAmount = formInputs.fee_amount;

    if (typeof feeAmount === "string") {
      feeAmount = parseInt(feeAmount, 10); // Use 10 as the radix for decimal numbers
    }

    if ( error === "") {
      console.log("inside2");
      // if (image.type.startsWith("image/")) {

      // formData.append("file", image);

      setIsLoading(true);
      // const response = await addImage(formData);

      // console.log("response addImage ___addImage test", response);
      // if (response.code === 200) {
        // Start second api
        let postData = {
          batch_id: formInputs.batch_id,
          title: formInputs.fee_title,
          total_marks: feeAmount,
          dead_line_date: formattedStartDate,
          assigned_date: formattedEndDate,
          status: formInputs.status,
          // question_paper_url: response.path,
          question_paper_url: pathOfUploadedFile,
          // assignment_note: formInputs.note,
          assignment_note: detailDescriptionEditor,
          assignment_type: formInputs.assignment_type,
        };

        console.log("postData _test", postData);

        setIsLoading(true);
        const result = await _post_assignment_from_admin(postData);
        if (result.code === 200) {
          setIsLoading(false);
          navigate("/assignments");
          //   navigate(-1);
          enqueueSnackbar("Generate Successfully!", { variant: "success" });

          setStartDate("");
        // } else {
        //   setIsLoading(false);
        //   enqueueSnackbar(result.message, { variant: "error" });
        // }

        // end second api
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setIsLoading(false);
      }
    } else {
      // Start Second API
      let postData = {
        batch_id: formInputs.batch_id,
        title: formInputs.fee_title,
        total_marks: feeAmount,
        dead_line_date: formattedStartDate,
        status: formInputs.status,
        // question_paper_url: response.path,
        // assignment_note: formInputs.note,
        assigned_date: formattedEndDate,
        assignment_note: detailDescriptionEditor,
        assignment_type: formInputs.assignment_type,
      };

      console.log("postData _test", postData);

      setIsLoading(true);
      const result = await _post_assignment_from_admin(postData);
      if (result.code === 200) {
        setIsLoading(false);
        navigate("/assignments");
        //   navigate(-1);
        enqueueSnackbar("Generate Successfully!", { variant: "success" });

        setStartDate("");
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }

      // end second api
    }
  };

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await listBatch();
    console.log("response listBatch", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              ...batch,
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              students: batch.students,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              batch_fee: batch.batch_fee ? batch.batch_fee : [],
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      //   console.log("batches  _test", batches);
      //   console.log("batches[0]  _test", batches[0]);
      //   console.log("batches[0].batch_fee  _test", batches[0].batch_fee);

      // if (batches.length > 0) {
      //   setFormInputs((prevInputs) => ({
      //     ...prevInputs,
      //     batch_id: batches[0]._id,
      //   }));
      // }

      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fetchInstructorBatches = async () => {
    setIsLoading(true);
    const response = await _get_instructor_batches();
    console.log("response _get_instructor_batches", response);
    if (response.code === 200) {
      const batches = response.instructor_batches
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              ...batch,
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              students: batch.students,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              batch_fee: batch.batch_fee ? batch.batch_fee : [],
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array
 
      if (batches.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          batch_id: batches[0]._id,
        }));
      }

      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchDiscount = async (batch_id, batch_student_id, batch_fee_id) => {
    // setIsLoading(true);
    const response = await _get_student_fee({
      batch_id: batch_id,
      batch_fee_id: batch_fee_id,
      student_id: batch_student_id,
    });
    console.log("response _get_student_fee", response);
    if (response.code === 200) {
      setFeeDiscount(response.student_fee.fee_discount);
      // const batches = response.batch
      //   .map((batch, index) => {
      //     // Check if status is true, include it in the resulting array; otherwise, skip
      //     if (batch.status === true) {
      //       return {
      //         ...batch,
      //         id: batch._id,
      //         name: batch.name,
      //         available_seats:
      //           batch.available_seats <= 0 ? 0 : batch.available_seats,
      //         total_seats: batch.total_seats,
      //         total_male_students: batch.total_male_students,
      //         total_female_students: batch.total_female_students,
      //         student_count: batch.student_count,
      //         status: batch.status,
      //         students: batch.students,
      //         courses: batch.courses ? batch.courses : "",
      //         // other properties...
      //         batch_fee: batch.batch_fee ? batch.batch_fee : [],
      //         number: index + 1,
      //       };
      //     }

      //     return null; // Skip this iteration and move to the next element
      //   })
      //   .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      //   console.log("batches  _test", batches);
      //   console.log("batches[0]  _test", batches[0]);
      //   console.log("batches[0].batch_fee  _test", batches[0].batch_fee);
      // setBatches(batches);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
      setFeeDiscount("");
    }
  };

  useEffect(() => {
    const storedObjectString = localStorage.getItem("user");
    if (storedObjectString) {
      const storedUser = JSON.parse(storedObjectString);
      if (storedUser.type === 0) {
        fetchBatches(); 
      } else if (storedUser.type === 2) {
        fetchInstructorBatches(); 
      }
    }
  }, []);

  useEffect(() => {
    let value = formInputs.batch_student_id;
    let foundObject = batcheStudents.find((obj) => obj._id === value);

    // isStudentFree
    if (foundObject) {
      if (foundObject.fee_status === 3) {
        setFormInputs({
          ...formInputs,
          voucher_type: 1,
          isStudentFree: true,
          registration_fee_discount: foundObject.registration_fee_discount
            ? foundObject.registration_fee_discount
            : "",
        });
      } else {
        if (formInputs.voucher_type === 1) {
          setFormInputs({
            ...formInputs,
            isStudentFree: false,
            registration_fee_discount: foundObject.registration_fee_discount,
          });
        } else {
          setFormInputs({
            ...formInputs,
            isStudentFree: false,
          });
        }
      }
    } else {
      setFormInputs({
        ...formInputs,
        isStudentFree: false,
        registration_fee_discount: "",
      });
    }
  }, [formInputs.batch_student_id, formInputs.voucher_type]);

  useEffect(() => {
    if (formInputs.voucher_type === 2) {
      if (
        formInputs.batch_student_id &&
        formInputs.batch_fee_id &&
        formInputs.batch_student_id !== null &&
        formInputs.batch_fee_id !== null &&
        formInputs.batch_student_id !== "" &&
        formInputs.batch_fee_id !== ""
      ) {
        setFeeDiscount("");
        fetchDiscount(
          formInputs.batch_id,
          formInputs.batch_student_id,
          formInputs.batch_fee_id
        );
      } else {
        setFeeDiscount("");
      }
    }
  }, [
    formInputs.batch_student_id,
    formInputs.batch_fee_id,
    formInputs.voucher_type,
  ]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Assignment">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Add Assignment{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Batch"
                  select
                  value={formInputs.batch_id}
                  onChange={handleChange2}
                  name="batch_id"
                  SelectProps={{ multiple: false }}
                  required
                  style={myStyle}
                >
                  {batches.map((batch) => (
                    <MenuItem key={batch.id} value={batch.id} style={myStyle}>
                      {batch.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  // required
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.fee_title}
                  onChange={handleChange}
                  name="fee_title"
                  required
                ></TextField>

                <TextField
                  fullWidth
                  label="Total Marks"
                  value={formInputs.fee_amount}
                  onChange={handleChange}
                  name="fee_amount"
                  required
                  //   InputProps={{
                  //     startAdornment: (
                  //       <InputAdornment position="start">RS</InputAdornment>
                  //     ),
                  //   }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    label="Assigned Date *"
                    // format="YYYY-MM-DD"
                    format="MM-DD-YYYY"
                    // value={dayjs(formInputs2.duration2)}
                    // minDate={dayjs()} // Set the minimum date to today
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    label="Dead Line Date *"
                    // format="YYYY-MM-DD"
                    format="MM-DD-YYYY"
                    // value={dayjs(formInputs2.duration2)}
                    // minDate={dayjs()} // Set the minimum date to today
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>

                <TextField
                  fullWidth
                  label="Assignment Type"
                  select
                  value={formInputs.assignment_type}
                  onChange={handleChange}
                  name="assignment_type"
                  // required
                >
                  <MenuItem value={1}>Under Review</MenuItem>
                  <MenuItem value={2}>Direct</MenuItem>
                </TextField>
              </Stack>

              <CustomImageUploaderMulti
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Assignment Attachment File"}
                imageFileSubHeading={"Acceptable File Size: 1MB or below Supported Formats:  .rar, .zip, .pdf, .doc, .docx"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              {/* <div className="col-12">
                <div className="row">
                  <div className="col-5">
                    <p className="assignment_sub_heading_in_add_edit">
                      Assignment Attachment File
                    </p>
                   

                    <FormHelperText className="pt-0">
                      Acceptable File Size: 1MB or below Supported Formats:
                      ".rar, .zip, .pdf, .doc, .docx"
                    </FormHelperText>
                  </div>

                  <div className={`col-4 mt-4`}>
         
                    {acceptableFile === "true" ? (
                      <>
                        <Icon 
                          icon={
                            "streamline:interface-validation-check-circle-checkmark-addition-circle-success-check-validation-add-form"
                          }
                          width={40}
                          height={40}
                          style={{ color: "green" }} // Set the text color to green
                        />
                        <span style={{ color: "green", paddingLeft: "5px" }}>
                          {message}
                        </span>
                      </>
                    ) : acceptableFile === "false" ? (
                      <>
                        <Icon
                          icon={"system-uicons:cross-circle"}
                          width={40}
                          height={40}
                          style={{ color: "red" }} // Set the text color to red
                        />
                        <span style={{ color: "red", paddingLeft: "5px" }}>
                          {message}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-3 text-end pt-2">
                    <label htmlFor="contained-button-file">
                      <Input
                        // accept="image/*"
                        accept=".rar,.zip,.pdf,.doc,.docx"
                        //   accept=".csv"
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="image"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        id="contained-button-file"
                        className="small-contained-button"
                        color={
                          acceptableFile === "true"
                            ? "success"
                            : acceptableFile === "false"
                            ? "error"
                            : "info"
                        }
                        startIcon={<FileUploadIcon />}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="col-12">
                <p className="assignment_sub_heading_in_add_edit">
                  Assignment Note *
                </p>
                {/* <FormHelperText className="pt-0">
                   File Size(5 mb ) ("zip", "rar")
                  </FormHelperText> */}

                {/* <FormHelperText className="pt-0">
                      Acceptable File Size: 5MB or below Supported Formats:
                      ".rar, .zip, .pdf, .doc, .docx"
                    </FormHelperText> */}

                <TinyEditor
                  detailDescriptionCk={detailDescriptionEditor}
                  setDetailDescription={setDetailDescriptionEditor}
                />
              </div>

              {/* <TextField
                  fullWidth
                  label="Assignment Note"
                  value={formInputs.note}
                  onChange={handleChange}
                  name="note"
                  multiline
                  rows={3}
                  // required
                ></TextField> */}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                  // disabled={acceptableFile !== "true"}
                >
                  Generate
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AddAssignment;
