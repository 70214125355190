import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: 1,
    courses: [],
    contact_number: "",
    status: 1,
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",

    discount: 0,
    fee_status: "all_paid",
    note: "note",

    registration_fee: "",
    course_id: "",
  });

  const myStyle = {
    textTransform: "capitalize",
  };

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "contact_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    if (value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    console.log("formInputs.batches __batches", formInputs.batches);
    // console.log("iiikkkkkkkkkkkkkkkk", image);
    const data = new FormData();

    data.append("name", formInputs.name.trim());
    data.append("father_name", formInputs.father_name.trim());
    data.append("email", formInputs.email.trim());
    data.append("cnic", formInputs.cnic.trim());

    data.append("gender", formInputs.gender);
    data.append("referred_by", formInputs.referred_by);
    data.append("address", formInputs.address);
    // data.append("student_id", formInputs.student_id);
    // data.append("courses", JSON.stringify(formInputs.courses));
    // data.append(
    //   "course_id",
    //   JSON.stringify(
    //     Array.isArray(formInputs.courses)
    //       ? formInputs.courses
    //       : [formInputs.courses]
    //   )
    // );
    // data.append(
    //   "batch_id",
    //   JSON.stringify(
    //     Array.isArray(formInputs.batches)
    //       ? formInputs.batches
    //       : [formInputs.batches]
    //   )
    // );

    // data.append("batch_id", formInputs.batches);

    data.append("contact_number", formInputs.contact_number);
    // data.append("profile_image", image ? image : "");
    data.append("status", formInputs.status);
    // data.append("reg_type", formInputs.reg_type);
    if (formInputs.dob) {
      data.append("date_of_birth", formInputs.dob);
    }

    data.append("course_id", formInputs.course_id);
    data.append("registration_fee", parseInt(formInputs.registration_fee));

    // data.append("course_fee", formInputs.course_fee);

    // data.append("course_fee", formInputs.course_fee);
    // data.append("registration_fee", parseInt(formInputs.registration_fee, 10));

    // data.append("course_fee", formInputs.course_fee);
    // data.append("registration_fee", formInputs.registration_fee);
    // data.append("actual_fee", formInputs.actual_fee);
    // data.append("decided_fee", formInputs.decided_fee);

    data.append("latest_degree", formInputs.latest_degree);
    data.append("degree_status", formInputs.degree_status);
    data.append(
      "completion_year",
      formInputs.degree_status === "complete" ? selectedYear : ""
    );
    // data.append(
    //   "completion_year",
    //   formInputs.degree_status === "complete" ? formInputs.completion_year : ""
    // );
    data.append("institution_name", formInputs.institution_name);
    data.append("semester", formInputs.semester);

    data.append("additional_numbers", JSON.stringify(additionalFields));
    if (formInputs.time_preferences) {
      data.append("time_preference_id", formInputs.time_preferences);
    }
    data.append("note", formInputs.note);
    // data.append("wallet_amount", formInputs.wallet_amount);
    // data.append("friend_number", formInputs.friend_number);
    // data.append("family_number", formInputs.family_number);
    // data.append("whatsapp_number", formInputs.whatsapp_number);

    // data.append("actual_fee", formInputs.actual_fee);
    // data.append("decided_fee", parseInt(formInputs.decided_fee, 10));
    // data.append("discount", parseInt(formInputs.discount, 10));

    // data.append("decided_fee", JSON.stringify(additionalFieldsCourse));

    // data.append("discount", formInputs.discount);
    // data.append("fee_status", formInputs.fee_status);
    if (!formInputs.name.trim()) {
      error = "Name is Required";
      enqueueSnackbar(error, { variant: "error" });
    } else if (formInputs.contact_number.length !== 10) {
      error = "Please enter a valid 10-digit Phone Number";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      setIsLoading(true);
      const result = await editStudent(id, data);
      if (result.code === 200) {
   
        navigate(-1);
        // navigate("/students");
        enqueueSnackbar("Edited Successfully!", { variant: "success" });
        setIsLoading(false);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(id);
    console.log("response response detailStudent detail __detail", response);
    if (response.code === 200) {
      setAdditionalFields(response.student.additional_numbers);

      // setCourses(response.student.courses);
      // const courseIds = response.student.courses.map((course) => course._id);

      // const batchIds = response.student.batch_id.map((batch) => batch._id);

      let batchIds = "";

      if (response.student.batch) {
        batchIds = response.student.batch._id;
      }

      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];
      let defaultDate = "";

      if (response.student.date_of_birth) {
        defaultDate = new Date(response.student.date_of_birth);
      }
      // console.log('defaultDate',defaultDate);
      //  if(response.student.date_of_birth ==="undefined"){
      //   console.log('defaultDate Date of birth is undefined  ');
      //  }else {

      //   console.log('defaultDate Date of birth is defined');
      //  }

      // const additionalFieldsFromBatch = response.student.decided_fee.map(
      //   (fields, index) => ({
      //     fee: fields.fee,
      //     month: fields.month,
      //     fee_status: fields.fee_status,
      //     // pending_fee: fields.pending_fee?fields.pending_fee:null,
      //     pending_fee: fields.pending_fee ? fields.pending_fee : 0,
      //     // pending_fee: fields.pending_fee,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);
      setSelectedYear(response.student.completion_year);
      setFormInputs({
        ...response.student,
        course_id: response.student.course._id,
        name: capitalizeFirstLetter(response.student.name),
        father_name: capitalizeFirstLetter(response.student.father_name),
        email: response.student.email,
        gender: response.student.gender,
        // student_id: response.student.student_id,
        address: response.student.address,
        contact_number: response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,
        note: response.student.note,
        // image: response.student.profile_image,
        // courses: courseIds,
        // batches: batchIds,
        // reg_type: response.student.reg_type,

        // course_fee: response.student.course_fee,
        // actual_fee: response.student.actual_fee,

        // registration_fee: response.student.total_registration_fee,
        // decided_fee: response.student.decided_fee,
        // discount: response.student.discount ? response.student.discount : 0,

        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        time_preferences: response.student.time_preference
          ? response.student.time_preference._id
          : "",
        // wallet_amount: response.student.wallet_amount
        //   ? response.student.wallet_amount
        //   : 0,

        // friend_number: response.student.friend_number,
        // family_number: response.student.family_number,
        // whatsapp_number: response.student.whatsapp_number,

        // date_of_birth: defaultDate.toISOString().split("T")[0]
        //   ? defaultDate.toISOString().split("T")[0]
        //   : null,
        // dob: defaultDate.toISOString().split("T")[0]
        //   ? defaultDate.toISOString().split("T")[0]
        //   : null,

        date_of_birth: response.student.date_of_birth
          ? defaultDate.toISOString().split("T")[0]
          : null,
        dob: response.student.date_of_birth
          ? defaultDate.toISOString().split("T")[0]
          : null,

        // dob: defaultDate.toISOString().split("T")[0],

        // discount: response.student.discount,
        fee_status:
          response.student.fee_status === 1
            ? "all_paid"
            : response.student.fee_status === 2
            ? "Monthly"
            : "is_free",
      });

      // ["is_free", "installments", "all_paid"]= [3,2,1]

      // if (response.student.profile_image) {
      //   setImage(response.student.profile_image);
      //   setShow(true);
      // }
      if (batchIds && batchIds.length > 0) {
        fetchActiveBatches(batchIds);
      } else {
        fetchActiveBatches();
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  const fetchActiveBatches = async (batchIds) => {
    const response = await listActiveBatch();
    console.log("response listActiveBatch __listActiveBatch", response);
    if (response.code === 200) {
      const batchesList = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.course ? batch.course : "",
        accumulative_revenue: batch.accumulative_revenue
          ? batch.accumulative_revenue
          : [],
      }));
      // console.log(batchesList, "jjjjj");

      // console.log(batchIds[0], "kkkkkkkkk");

      // const selectedBatchIndex = batchesList.findIndex(
      //   (batch) => batch.id === batchIds[0]
      // );

      // console.log(selectedBatchIndex, "selectedBatchselectedBatch");
      // console.log("cccc");
      // setCourses(batchesList[selectedBatchIndex].courses);

      setBatches(batchesList);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchTimePreference = async () => {
    // setIsLoading(true);
    const response = await _active_time_preference();
    console.log("response  _active_time_preference __test", response);

    //

    if (response.code === 200) {
      // const courses = response.courses
      //   .filter((course) => course.status === true)
      //   .map((course) => ({
      //     id: course._id,
      //     fee: course.fee,
      //     title: course.title,
      //     description: course.description,
      //     duration: course.duration,
      //     // image: course.image,
      //     status: course.status,
      //   }));
      // console.log("courses courses courses courses", courses);
      // setCourses(courses);
      setTimePreferences(response.time_preferences);

      if (response.time_preferences.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          time_preferences: response.time_preferences[0]._id,
        }));
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActiveCourse = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response listCourse ___listCourse", response);
    if (response.code === 200) {
      // const batches = response.batches.map((batch) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   student_count: batch.student_count,
      //   status: batch.status,
      //   courses: batch.courses,
      // }));
      // Filter and set active courses
      const activeCourses = response.courses.filter(
        (course) => course.status === true
      );
      setCourses(activeCourses);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentDetail();
    fetchTimePreference();
    fetchActiveCourse();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Student">
                  <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">  Edit Student </h4>
        </Grid>
         

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Father name"
                  value={formInputs.father_name}
                  onChange={handleChange}
                  name="father_name"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.contact_number}
                  onChange={handleChangePhone}
                  name="contact_number"
                  required
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <Button
                  size="medium"
                  // type="submit"
                  // variant="contained"
                  // loading={isLoading}
                  // sx={{ width: "100px" }}
                  variant="outlined"
                  onClick={handleAddField}
                >
                  +
                </Button>
              </Stack>

              {additionalFields.map((field, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* Conditionally render the Remove button */}
                  {/* {showRemoveButtons[index] && ( */}
                  <IconButton
                    aria-label="Delete Field"
                    onClick={() => handleDeleteField(index)}
                  >
                    <ClearIcon />
                  </IconButton>
                  {/* // )} */}
                  <TextField
                    fullWidth
                    label={`Phone ${index + 1}`}
                    name="number"
                    value={field.number}
                    onChange={(e) => handleFieldChange(e, index)}
                    sx={{ marginRight: "10px" }}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+92</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      maxLength: 10, // Limit the input to 10 characters
                      title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <TextField
                    fullWidth
                    label="Phone Type"
                    select
                    name="type"
                    value={field.type}
                    onChange={(e) => handleFieldChange(e, index)}
                  >
                    <MenuItem value="personal">Personal</MenuItem>
                    <MenuItem value="family">Family</MenuItem>
                    <MenuItem value="friend">Friend</MenuItem>
                    <MenuItem value="whatsapp">Whatsapp</MenuItem>
                    <MenuItem value="work">Work</MenuItem>
                    <MenuItem value="emergency">Emergency</MenuItem>
                    <MenuItem value="institute">Institute</MenuItem>
                    <MenuItem value="other">other</MenuItem>
                  </TextField>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddField} // Your function to add a new field
                    sx={{ marginLeft: "10px" }}
                  >
                    Add
                  </Button>
                </div>
              ))}

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {/* <TextField
                  fullWidth
                  label="CNIC"
                  // required
                  value={formInputs.cnic}
                  onChange={handleChange}
                  name="cnic"
                /> */}

                <TextField
                  fullWidth
                  label="Registration Fee"
                  value={formInputs.registration_fee}
                  onChange={handleChange}
                  name="registration_fee"
                  sx={{ marginRight: "10px" }}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">RS</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                {/* <TextField
                  fullWidth
                  label="Student ID (Unique)"
                  value={formInputs.student_id}
                  required
                  onChange={handleChange}
                  name="student_id"
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                /> */}

                <TextField
                  fullWidth
                  label="Gender"
                  select
                  // required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email address"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  autoComplete="off"
                  // required
                  // inputProps={{
                  //   pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  //   title: "Please enter a valid email address",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  // required
                >
                  {/* {formInputs.status !== 3 ? (
                    <> */}
                      <MenuItem value={1}>Active</MenuItem>
                      <MenuItem value={2}>Inactive</MenuItem>
                    {/* </>
                  ) : (
                    <MenuItem value={3}>Trash</MenuItem>
                  )} */}

                  {/* <MenuItem value={3}>Paused</MenuItem> */}
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="CNIC Number"
                  value={formInputs.cnic}
                  onChange={handleCNICChange}
                  name="cnic"
                  autoComplete="off"
                  inputProps={{
                    pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                    title:
                      "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
                  }}
                />

                <TextField
                  fullWidth
                  label="Referred By"
                  // required
                  value={formInputs.referred_by}
                  onChange={handleChange}
                  name="referred_by"
                />
              </Stack>

              <TextField
                fullWidth
                label="Address"
                // required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
              />

              {/* <TextField
                fullWidth
                label="Batch"
                select
                value={formInputs.batches}
                onChange={handleChange}
                name="batches"
                required
                // SelectProps={{ multiple: true }}
              >
                {batches.map((batch) => (
                  <MenuItem key={batch.id} value={batch.id}>
                    {batch.name}
                  </MenuItem>
                ))}
              </TextField> */}

              <TextField
                fullWidth
                label="Course"
                select
                value={formInputs.course_id}
                onChange={handleChange}
                name="course_id"
                required
                // SelectProps={{ multiple: true }}
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course._id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                label="Time Preferences"
                select
                value={formInputs.time_preferences}
                onChange={handleChange}
                name="time_preferences"
                // required
                // style={myStyle} // Apply the style here
                SelectProps={{ multiple: false }}
              >
                {timePreferences.map((time_preferences) => (
                  <MenuItem
                    key={time_preferences._id}
                    value={time_preferences._id}
                    // style={myStyle} // Apply the style here
                  >
                    {capitalizeFirstLetter(time_preferences.type)} (
                    {formatTime(time_preferences.start_time)} to{" "}
                    {formatTime(time_preferences.end_time)} )
                  </MenuItem>
                ))}
              </TextField>
              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
              {/* <TextField
                  fullWidth
                  label="Reg type"
                  select
                  value={formInputs.reg_type}
                  onChange={handleChange}
                  name="reg_type"
                  // required
                >
                  <MenuItem value="regular">Regular</MenuItem>
                  <MenuItem value="scholarship">Scholarship</MenuItem>
                </TextField> */}

              {/* <TextField
                  fullWidth
                  // type="number"
                  label="Wallet Amount"
                  value={formInputs.wallet_amount}
                  onChange={handleChange}
                  name="wallet_amount"
                  // required
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                /> */}
              {/* </Stack> */}
              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
              {/* <TextField
                  fullWidth
                  // type="number"
                  label="Registration fee"
                  value={formInputs.course_fee}
                  onChange={handleChange}
                  name="course_fee"
                  required
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                /> */}

              {/* <TextField
                  fullWidth
                  // type="number"
                  label="Decided Registration fee"
                  value={formInputs.registration_fee}
                  // required
                  onChange={handleChange}
                  name="registration_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                /> */}
              {/* </Stack> */}

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
              {/* <TextField
                  fullWidth
                  // type="number"
                  label="Course Actual fee (Over all)"
                  value={formInputs.actual_fee}
                  required
                  onChange={handleChange}
                  name="actual_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   // pattern: "[0-9]",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                /> */}

              {/* <TextField
                  fullWidth
                  // type="number"
                  label="Course Decided fee (Monthly)"
                  value={formInputs.decided_fee}
                  // required
                  onChange={handleChange}
                  name="decided_fee"
                  disabled={additionalFieldsCourse.length > 0}
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                /> */}
              {/* <Button
                  size="medium"
                  // type="submit"
                  // variant="contained"
                  // loading={isLoading}
                  // sx={{ width: "100px" }}
                  variant="outlined"
                  onClick={handleAddFieldCourse}
                  disabled={additionalFieldsCourse.length > 0}
                >
                  +
                </Button> */}
              {/* </Stack> */}

              {additionalFieldsCourse.map((field, index) => (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  key={index}
                >
                  {/* Conditionally render the Remove button */}
                  {/* {showRemoveButtonsCourse[index] && (
              <IconButton
                aria-label="Delete Field"
                onClick={() => handleDeleteFieldCourse(index)}
              >
                <ClearIcon />
              </IconButton>
            )} */}
                  <TextField
                    fullWidth
                    label={`Fee ${index + 1}`}
                    name="fee"
                    value={field.fee}
                    onChange={(e) => handleFieldChangeCourse(e, index)}
                    disabled={field.fee_status !== "pending"}
                    // sx={{ marginRight: "10px" }}
                  />
                  <TextField
                    fullWidth
                    label={`Pending Fee ${index + 1}`}
                    name="pending_fee"
                    value={field.pending_fee}
                    onChange={(e) => handleFieldChangeCourse(e, index)}
                    disabled={field.fee_status !== "pending"}
                    // sx={{ marginRight: "10px" }}
                  />

                  <TextField
                    fullWidth
                    label="Month"
                    select
                    name="month"
                    value={field.month}
                    onChange={(e) => handleFieldChangeCourse(e, index)}
                    disabled
                  >
                    <MenuItem value="January">January</MenuItem>
                    <MenuItem value="February">February</MenuItem>
                    <MenuItem value="March">March</MenuItem>
                    <MenuItem value="April">April</MenuItem>
                    <MenuItem value="May">May</MenuItem>
                    <MenuItem value="June">June</MenuItem>
                    <MenuItem value="July">July</MenuItem>
                    <MenuItem value="August">August</MenuItem>
                    <MenuItem value="September">September</MenuItem>
                    <MenuItem value="October">October</MenuItem>
                    <MenuItem value="November">November</MenuItem>
                    <MenuItem value="December">December</MenuItem>
                  </TextField>
                  {field.fee_status ? (
                    <button
                      class={`disabled btn btn-${
                        field.fee_status === "pending"
                          ? "warning"
                          : field.fee_status === "paid"
                          ? "success"
                          : "primary"
                      }`}
                    >
                      {field.fee_status === "pending"
                        ? "Pending"
                        : field.fee_status === "paid"
                        ? " Paid "
                        : "Refund"}
                    </button>
                  ) : (
                    ""
                  )}

                  {/* {field.pending_fee !== null ? (
                    <button
                      class={`disabled btn btn-${
                        field.fee_status === "pending"
                          ? "warning"
                          : field.fee_status === "paid"
                          ? "success"
                          : "primary"
                      }`}
                    >
                      {field.pending_fee}
                    </button>
                  ) : (
                    ""
                  )} */}

                  {/* <Button
              variant="outlined"
              color="primary"
              onClick={handleAddFieldCourse} // Your function to add a new field
              sx={{ marginLeft: "10px" }}
            >
              Add
            </Button> */}
                </Stack>
              ))}

              {/* <TextField
                fullWidth
                label="Address"
                value={formInputs.address}
                onChange={handleChange}
                name="address"
                required
              /> */}

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Latest Degree"
                  value={formInputs.latest_degree}
                  onChange={handleChange}
                  name="latest_degree"
                />

                <TextField
                  fullWidth
                  label="Degree Status"
                  select
                  value={formInputs.degree_status}
                  onChange={handleChange}
                  name="degree_status"
                  required
                >
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="inprogress">Inprogress</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {formInputs.degree_status === "complete" ? (
                  <TextField
                    fullWidth
                    label="Completion Year"
                    select
                    value={selectedYear}
                    onChange={handleYearChange}
                    name="degree_status"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  // <TextField
                  //   fullWidth
                  //   label="Completion Year"
                  //   value={formInputs.completion_year}
                  //   onChange={handleChange}
                  //   name="completion_year"
                  // />
                  ""
                )}

                <TextField
                  fullWidth
                  label="Institution Name"
                  value={formInputs.institution_name}
                  onChange={handleChange}
                  name="institution_name"
                />

                {formInputs.degree_status === "inprogress" ? (
                  <TextField
                    fullWidth
                    label="Semester"
                    value={formInputs.semester}
                    onChange={handleChange}
                    name="semester"
                  />
                ) : (
                  ""
                )}
              </Stack>

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>

<TextField
fullWidth
label="Friend Number"
value={formInputs.friend_number}
onChange={handleChange}
name="friend_number"
/> 
 

<TextField
  fullWidth
  label="Family Number"
  value={formInputs.family_number}
  onChange={handleChange}
  name="family_number"
/>

<TextField
  fullWidth
  label="Whatsapp Number"
  value={formInputs.whatsapp_number}
  onChange={handleChange}
  name="whatsapp_number"
/>  
</Stack> */}

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
              {/* <TextField
                  fullWidth
                  label="Discount"
                  value={formInputs.discount}
                  onChange={handleChange}
                  name="discount"
                /> */}

              {/* <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                >
                  <MenuItem value="is_free">Is Free</MenuItem>
                  <MenuItem value="installments">Installments</MenuItem>
                  <MenuItem value="all_paid">All Paid</MenuItem>
                </TextField> */}
              {/* </Stack> */}

              <div className="col-md-12 col-xs-12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    format="MM-DD-YYYY"
                    // value={formInputs?.date_of_birth}
                    // value={dayjs(formInputs?.date_of_birth)} // Convert the default date to a Day.js object
                    value={formInputs?.date_of_birth ? dayjs(formInputs?.date_of_birth) : null}
                    onChange={handleDateChange}
                    maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>
              </div>

              {show === false
                ? // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Input
                  //     type="file"
                  //     id="image"
                  //     name="image"
                  //     accept="image/*"
                  //     onChange={handleFile}
                  //     required
                  //   />
                  //   {/* <Avatar alt={"image"} src={previewUrl} /> */}
                  // </Stack>
                  ""
                : null}

              {show === true
                ? // <Stack
                  //   position="relative"
                  //   alignItems="center"
                  //   justifyContent="center"
                  // >
                  //   {" "}
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Box
                  //     position="relative"
                  //     display="inline-block"
                  //     border="2px solid #ccc"
                  //     p={1}
                  //   >
                  //     {previewUrl ? (
                  //       <img
                  //         alt="image"
                  //         src={previewUrl}
                  //         width="150"
                  //         style={{ maxWidth: "100%" }}
                  //       />
                  //     ) : (
                  //       <img
                  //         alt="image"
                  //         src={s3baseUrl + formInputs.image}
                  //         width="150"
                  //         style={{ maxWidth: "100%" }}
                  //       />
                  //     )}
                  //     <IconButton
                  //       aria-label="delete"
                  //       onClick={() => handleDeleteImage()}
                  //       style={{ position: "absolute", top: 0, right: 0 }}
                  //     >
                  //       <ClearIcon />
                  //     </IconButton>
                  //   </Box>
                  // </Stack>
                  ""
                : null}

              <TextField
                fullWidth
                label="Note"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.note}
                onChange={handleChange}
                name="note"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
