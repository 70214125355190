import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Table,
  Box,
  Chip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import {
  _list_confirm_and_started_batch,
  _move_batch_students,
  addBatch,
  listBatch,
} from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { _assign_batch_from_course } from "src/DAL/course_student";
import { detailStudent } from "src/DAL/student";
import {
  capitalizeAllFirstLetters,
  capitalizeAllLetters,
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import ClearIcon from "@mui/icons-material/Clear";
const AssignBatch = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchesFee, setBatchesFee] = useState([]);
  const [batchTitle, setBatchTitle] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "true",
    batch: "",
    registration_fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount: "0",
    fee_discount_reason: "",
    discount: 0,
    fee_status: 2,
    courses: [],
    registration_fee: "",
  });

  const [studentformInputs, setStudentformInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",

    time: "",
    discount: 0,
    fee_status: "all_paid",
    registration_fee: "",
  });

  const { id } = useParams();

  let _ID = id;

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const [formInputs3, setFormInputs3] = useState([]);
  const [studentBatchIds, setstudentBatchIds] = useState([]);

  const [registrationFeeDiscountChecked, setRegistrationFeeDiscountChecked] =
    useState(false);
  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);

  const [feeDiscountChecked, setFeeDiscountChecked] = useState(false);
  const [registrationFee, setRegistrationFeeChecked] = useState(false);

  const handleCheckboxRegistrationChange = (event) => {
    setRegistrationFeeDiscountChecked(event.target.checked);
  };
  const handleCheckboxFeeChange = (event) => {
    setFeeDiscountChecked(event.target.checked);
  };
  const handleCheckboxRegistrationFeeChange = (event) => {
    setRegistrationFeeChecked(event.target.checked);
  };

  const [additionalFields, setAdditionalFields] = useState([
    // { fee_title: "", fee: "" },
  ]);

  const [discountChecked, setDiscountChecked] = useState(
    additionalFields.map(() => false)
  );


  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeDiscount = (event, batchFeeId) => {
    const { name, value } = event.target;

    // Find the corresponding object in the formInputs array and update its "fee_discount" value
    const updatedFormInputs = formInputs3.map((input) => {
      if (input.batch_fee_id === batchFeeId) {
        return { ...input, [name]: value };
      }
      return input;
    });

    setFormInputs3(updatedFormInputs);
  };

  const handleBatchChange = (e) => {
    const { target } = e;
    console.log("target", target);
   
    // let registrationFee = "";

    const updatedBatches = batches.filter((batch) => batch.id === target.value);
    // console.log('filteredArray',filteredArray);
    if (updatedBatches.length > 0) {
      // registrationFee = updatedBatches[0].registration_fee;

      // console.log("updatedBatches[0]", updatedBatches[0]);
      setBatchesFee(updatedBatches[0].batch_fee);
      // console.log("updatedBatches[0].batch_fee", updatedBatches[0].batch_fee);

      const updatedBatchesFee = updatedBatches[0].batch_fee.map((feeItem) => ({
        ...feeItem,
        // fee: 0,
        fee_discount: feeItem.fee_discount ? feeItem.fee_discount : "0",
        // fee_discount:feeItem.fee_discount,
      }));
      console.log("updatedBatchesFee", updatedBatchesFee);

      setAdditionalFields(updatedBatchesFee);
      setDiscountChecked(updatedBatchesFee.map(() => false));
    }
    setFormInputs({
      ...formInputs,
      // registration_fee: registrationFee,
      [target.name]: target.value,
    });

  };

  const handleChange = (e) => {
    const { target } = e;
    console.log("target", target);
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      //   courses: [value],
    }));

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  function formatTime(startTime, endTime) {
    if (startTime && endTime && startTime !== "") {
      // Parse the time strings using moment
      const startMoment = moment(startTime, "HH:mm");
      const endMoment = moment(endTime, "HH:mm");

      // Format the start time and end time with AM/PM
      const formattedStartTime = startMoment.format("hh:mm A");
      const formattedEndTime = endMoment.format("hh:mm A");

      const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

      return formattedTime;
    } else {
      return " _ _ ";
    }
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: "" }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDiscountCheckboxChange = (index, isChecked) => {
    setDiscountChecked((prevChecked) => {
      const updatedChecked = [...prevChecked];
      updatedChecked[index] = isChecked;
      return updatedChecked;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
 

    if (formInputs.fee_status === 1) {
      //////////////////// Free

      let PostData = {
        student_id: _ID,
        batch_id: formInputs.batch,
        fee_status: 3,
        registration_fee_status: 2,
        student_fee: [],
      };

      if (registrationFee === true) {
        console.log("startDate", startDate);
        if (!startDate) {
          error = "Registration Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        console.log("formattedStartDate", formattedStartDate);
        if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        let registrationFeeDiscount = formInputs.registration_fee_discount;
        let registrationFeeDiscountReason =
          formInputs.registration_fee_discount_reason;

        if (typeof registrationFeeDiscount === "string") {
          registrationFeeDiscount = parseInt(registrationFeeDiscount, 10); // Use 10 as the radix for decimal numbers
        }

        if (!registrationFeeDiscountChecked) {
          registrationFeeDiscount = 0;
          registrationFeeDiscountReason = "";
        }

        PostData = {
          student_id: _ID,
          batch_id: formInputs.batch,
          fee_status: 3,
          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          due_date_registration: formattedStartDate,
          registration_fee_status: 1,
          student_fee: [],
        };
      }

      setIsLoading(true);
      const result = await _assign_batch_from_course(PostData);

      if (result.code === 200) {
        setIsLoading(false);
        //   navigate("/course-students");
        navigate(-1);
        enqueueSnackbar("Assign Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else if(error===""){
      ////////////////////Not Free
    

      let registrationFeeDiscount = formInputs.registration_fee_discount;
      let registrationFeeDiscountReason =
        formInputs.registration_fee_discount_reason;

      let feeDiscount = formInputs.fee_discount;
      let feeDiscountReason = formInputs.fee_discount_reason;

      if (typeof registrationFeeDiscount === "string") {
        registrationFeeDiscount = parseInt(registrationFeeDiscount, 10); // Use 10 as the radix for decimal numbers
      }
      if (typeof feeDiscount === "string") {
        feeDiscount = parseInt(feeDiscount, 10); // Use 10 as the radix for decimal numbers
      }

      if (!registrationFeeDiscountChecked) {
        registrationFeeDiscount = 0;
        registrationFeeDiscountReason = "";
      }

      // const testBatch = additionalFields.map((feeItem) => {
      //   if (feeItem.fee < feeItem.fee_discount) {
      //        error = `Maximum discount for ${feeItem.fee_title} Fee is ${feeItem.fee}.`;
      //     enqueueSnackbar(error, { variant: "error" });
      //     // You might want to handle the error condition accordingly, for example, by returning a default value or null.
      //     return error;
      //   }
      
      // });
      // const validBatches = testBatch.filter((item) => item !== error);
      // if (validBatches) {
      //   return;
      // }


      const updatedBatchesFeeNew = additionalFields.map((feeItem,index) => {
        
        return {
          batch_fee_id: feeItem._id,
          fee_discount: discountChecked[index] ? feeItem.fee_discount : '0',
          fee_discount_reason: discountChecked[index]
            ? feeItem.fee_discount_reason || ""
            : "",
        };
      });

      // const updatedBatchesFeeNew = additionalFields.map((feeItem) => ({
      //   batch_fee_id: feeItem._id,
      //   fee_discount: feeItem.fee_discount,
      //   fee_discount_reason: feeItem.fee_discount_reason
      //     ? feeItem.fee_discount_reason
      //     : "",
      // }));

      console.log("updatedBatchesFeeNew", updatedBatchesFeeNew);

      let PostData = {
        student_id: _ID,
        batch_id: formInputs.batch,

        // registration_fee_discount: registrationFeeDiscount,
        // registration_fee_discount_reason: registrationFeeDiscountReason,
        // fee_discount: feeDiscount,
        // fee_discount_reason: feeDiscountReason,
        // due_date_registration: formattedStartDate,
        fee_status: formInputs.fee_status === 2 ? 2 : 1,
        registration_fee_status: 2,
        student_fee: updatedBatchesFeeNew,
      };

      if (formInputs.fee_status === 3) {
        console.log("endDate", endDate);
        if (!endDate) {
          error = "Fee Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedEndDate = endDate.format("YYYY-MM-DD");
        console.log("formattedEndDate", formattedEndDate);
        if (!moment(formattedEndDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Fee Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        PostData = {
          student_id: _ID,
          batch_id: formInputs.batch,

          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          // fee_discount: feeDiscount,
          // fee_discount_reason: feeDiscountReason,
          // due_date_registration: formattedStartDate,
          fee_status: formInputs.fee_status === 2 ? 2 : 1,
          registration_fee_status: 2,
          student_fee: updatedBatchesFeeNew,
          due_date_fee: formattedEndDate,
        };
      }

      if (formInputs.fee_status === 3 && registrationFee === true) {
        console.log("startDate", startDate);
        if (!startDate) {
          error = "Registration Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        console.log("formattedStartDate", formattedStartDate);
        if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        console.log("endDate", endDate);
        if (!endDate) {
          error = "Fee Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedEndDate = endDate.format("YYYY-MM-DD");
        console.log("formattedEndDate", formattedEndDate);
        if (!moment(formattedEndDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Fee Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        PostData = {
          student_id: _ID,
          batch_id: formInputs.batch,

          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          // fee_discount: feeDiscount,
          // fee_discount_reason: feeDiscountReason,
          due_date_registration: formattedStartDate,
          fee_status: formInputs.fee_status === 2 ? 2 : 1,
          registration_fee_status: 1,
          student_fee: updatedBatchesFeeNew,
          due_date_fee: formattedEndDate,
        };
      }

      if (formInputs.fee_status === 2 && registrationFee === true) {
        console.log("startDate", startDate);
        if (!startDate) {
          error = "Registration Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        console.log("formattedStartDate", formattedStartDate);
        if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

         PostData = {
          student_id: _ID,
          batch_id: formInputs.batch,
  
          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          // fee_discount: feeDiscount,
          // fee_discount_reason: feeDiscountReason,
          due_date_registration: formattedStartDate,
          fee_status: formInputs.fee_status === 2 ? 2 : 1,
          registration_fee_status: 1,
          student_fee: updatedBatchesFeeNew,
        };

      }
      console.log("PostData    ___PostData", PostData);
      setIsLoading(true);
      const result = await _assign_batch_from_course(PostData);

      if (result.code === 200) {
        setIsLoading(false);
        //   navigate("/course-students");
        navigate(-1);
        enqueueSnackbar("Assign Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await _list_confirm_and_started_batch();
    console.log("response _list_confirm_and_started_batch", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if batch._id is equal to the _ID you want to skip
          if (batch._id === _ID) {
            setBatchTitle(batch.name);
            return null; // Skip this iteration and move to the next element
          }

          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              ...batch,
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              number: index + 1,
              time: formatTime(batch.start_time_class, batch.end_time_class),
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(_ID);
    console.log("response response detailStudent detail __detail", response);
    if (response.code === 200) {
      let batchIds = "";

      const allIds = response.student_batch.map(item => item._id);
      setstudentBatchIds(allIds);
      // const [studentBatchIds, setstudentBatchIds] = useState([]);
console.log("allIds ____allIds",allIds);

      if (response.student.batch) {
        batchIds = response.student.batch._id;
      }

      let defaultDate = new Date();
      if (response.student.date_of_birth) {
        defaultDate = new Date(response.student.date_of_birth);
      }

      setStudentformInputs({
        ...response.student,
        name: response.student.name,
        father_name: response.student.father_name,
        email: response.student.email,
        gender: response.student.gender,
        // student_id: response.student.student_id,
        address: response.student.address,
        contact_number: response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,

        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        time_preferences: response.student.time_preference
          ? response.student.time_preference._id
          : "",

        time: formatTime(
          response.student.time_preference.start_time,
          response.student.time_preference.end_time
        ),

        date_of_birth: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        dob: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        // dob: defaultDate.toISOString().split("T")[0],

        discount: response.student.discount,
        fee_status:
          response.student.fee_status === 1
            ? "All Paid"
            : response.student.fee_status === 2
            ? "Monthly"
            : "Free",
        createdAt: response.student.createdAt,
        updatedAt: response.student.updatedAt,


      });

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchBatches();
    fetchStudentDetail();
  }, []);

  //   useEffect(() => {
  //     if (courses.length > 0) {
  //       setFormInputs((prevInputs) => ({
  //         ...prevInputs,
  //         courses: [courses[0].id],
  //       }));
  //     }
  //   }, [courses]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Assign Batch">
      <div className="container mb-5">
        <div className="row">
          <div className=" col-12 col-sm-8">
          <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "-10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Assign Batch </h4>
        </Grid>
		 

            <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3} sx={{ padding: "5px" }}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {/* <TextField
                  disabled
                  fullWidth
                  label="From Batch"
                  value={batchTitle}
                  onChange={handleChange}
                  name="name"
                  
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                /> */}
                  </Stack>

                  <TextField
  fullWidth
  label="To Batch"
  select
  value={formInputs.batch}
  onChange={handleBatchChange}
  name="batch"
  SelectProps={{ multiple: false }}
  required
>
  {batches.map((batch) => (
    <MenuItem
      key={batch.id}
      value={batch.id}
      disabled={studentBatchIds.includes(batch.id)}
    >
      {studentBatchIds.includes(batch.id) ? (
        `${capitalizeFirstLetter(batch.name)} ( Already in Batch )`
      ) : (
        `${capitalizeFirstLetter(batch.name)} (${batch.time})`
      )}
    </MenuItem>
     
  ))}
</TextField>


                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Fee Status"
                      select
                      value={formInputs.fee_status}
                      onChange={handleChange}
                      name="fee_status"
                    >
                      <MenuItem value={1}>Is Free</MenuItem>
                      {/* <MenuItem value={2}>Installments</MenuItem> */}
                      <MenuItem value={2}>Monthly</MenuItem>
                      <MenuItem value={3}>All Paid</MenuItem>
                    </TextField>
                  </Stack>

                  {formInputs.fee_status !== 1 &&
                    additionalFields.map((field, index) => (
                     
                      <div key={index}>
                      <Box>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            fullWidth
                            label={`Fee Title ${index + 1}`}
                            name="fee_title"
                            value={capitalizeFirstLetter(field.fee_title)}
                            onChange={(e) => handleFieldChange(e, index)}
                            sx={{ marginRight: "10px" }}
                            disabled
                          />
                  
                          <TextField
                            fullWidth
                            label={`Fee ${index + 1}`}
                            name="fee"
                            value={"R.S " + field.fee}
                            onChange={(e) => handleFieldChange(e, index)}
                            sx={{ marginRight: "10px" }}
                            disabled
                            inputProps={{
                              pattern: "[0-9]*",
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                  
                  <div className="col-3"> 
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={discountChecked[index]}
                                  onChange={() =>
                                    handleDiscountCheckboxChange(index, !discountChecked[index])
                                  }
                                />
                              }
                              label={`Fee Discount ${index + 1}`}
                            />
                          </FormGroup>
                          </div>
                        </div>
                  
                        {discountChecked[index] && (
                          <div className="mt-3" style={{ display: "flex", alignItems: "center" }}>
                               <TextField
                                fullWidth
                                label={`Fee Discount ${index + 1}`}
                                name="fee_discount"
                                value={field.fee_discount}
                                onChange={(e) => handleFieldChange(e, index)}
                                sx={{ marginRight: "10px" }}
                                required
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      R.S
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  pattern: "[0-9]*", // Only allow numeric characters
                                  // maxLength: 10, // Limit the input to 10 characters
                                  // title: "Please enter a valid 10-digit phone number",
                                }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              
                            <TextField
                              fullWidth
                              label={`Fee Discount Reason ${index + 1}`}
                              name="fee_discount_reason"
                              value={field.fee_discount_reason}
                              onChange={(e) => handleFieldChange(e, index)}
                              sx={{ marginRight: "10px" }}
                              required
                            />
                          </div>
                        )}
                      </Box>
                    </div>
                       
                    ))}

                  {/*  */}
    
                  {formInputs.fee_status === 1 && registrationFee === true ? (
                    // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    //   <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //     <DatePicker
                    //       selected={startDate}
                    //       onChange={handleStartDateChange}
                    //       label="Registration Due Date *"
                    //       // format="YYYY-MM-DD"
                    //       // value={dayjs(formInputs2.duration2)}
                    //       minDate={dayjs()} // Set the minimum date to today
                    //       sx={{
                    //         width: "100%", // Set the width to 100% to take up the full available width
                    //         // marginTop: "20px",
                    //       }}
                    //     />
                    //   </LocalizationProvider>
                    //   <TextField
                    //     fullWidth
                    //     label="Registration Fee"
                    //     value={formInputs.registration_fee}
                    //     onChange={handleChange}
                    //     name="registration_fee"
                    //     disabled
                    //     InputProps={{
                    //       startAdornment: (
                    //         <InputAdornment position="start">
                    //           R.S
                    //         </InputAdornment>
                    //       ),
                    //     }}
                    //     inputProps={{
                    //       pattern: "[0-9]*", // Only allow numeric characters
                    //       // maxLength: 10, // Limit the input to 10 characters
                    //       // title: "Please enter a valid 10-digit phone number",
                    //     }}
                    //     onKeyPress={(event) => {
                    //       if (!/[0-9]/.test(event.key)) {
                    //         event.preventDefault();
                    //       }
                    //     }}
                    //   />

                    // </Stack>
                    <></>
                  ) : (
                    ""
                  )}
                  
                  {formInputs.fee_status === 3 ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            label="Fee Due Date *"
                            // format="YYYY-MM-DD"
                            // value={dayjs(formInputs2.duration2)}
                            sx={{
                              width: "100%", // Set the width to 100% to take up the full available width
                              // marginTop: "20px",
                            }}
                          />
                        </LocalizationProvider>
                      ) : (
                        ""
                      )}
                      
   <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {/* {formInputs.fee_status === 1 ? ( */}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={registrationFee}
                              onChange={handleCheckboxRegistrationFeeChange}
                            />
                          }
                          label="Registration Fee"
                        />
                      </FormGroup>
                    {/* ) : (
                      ""
                    )} */}

                    {formInputs.fee_status === 1 && registrationFee === true ? (
                      // <FormGroup>
                      //   <FormControlLabel
                      //     control={
                      //       <Checkbox
                      //         checked={registrationFeeDiscountChecked}
                      //         onChange={handleCheckboxRegistrationChange}
                      //       />
                      //     }
                      //     label="Registration Fee Discount"
                      //   />
                      // </FormGroup>
                      <></>
                    ) : (
                      ""
                    )}

                    {formInputs.fee_status !== 1 ? (
                      // <FormGroup>
                      //   <FormControlLabel
                      //     control={
                      //       <Checkbox
                      //         checked={registrationFeeDiscountChecked}
                      //         onChange={handleCheckboxRegistrationChange}
                      //       />
                      //     }
                      //     label="Registration Fee Discount"
                      //   />
                      // </FormGroup>
                      <></>
                    ) : (
                      ""
                    )}
                    {/* {formInputs.fee_status !== 1 ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={feeDiscountChecked}
                              onChange={handleCheckboxFeeChange}
                            />
                          }
                          label="Fee Discount"
                        />
                      </FormGroup>
                    ) : (
                      ""
                    )} */}
                  </Stack>

                             
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {registrationFee? (
                      <>
                      <TextField
                        fullWidth
                        label="Registration Fee"
                        value={studentformInputs.registration_fee}
                        onChange={handleChange}
                        name="registration_fee"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                     
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateChange}
                          label="Registration Due Date *"
                          // format="YYYY-MM-DD"
                          // value={dayjs(formInputs2.duration2)}
                          // minDate={dayjs()} // Set the minimum date to today
                          sx={{
                            width: "100%", // Set the width to 100% to take up the full available width
                            // marginTop: "20px",
                          }}
                        />
                      </LocalizationProvider>
                      </>
  ) : (
    ""
  )}
                      
                    </Stack>

                    {registrationFee? (
                     ""
                    ):("")}

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {registrationFeeDiscountChecked &&
                    formInputs.fee_status === 1 &&
                    registrationFee === true ? (
                      <>
                      <TextField
                        fullWidth
                        label="Registration Fee Discount"
                        value={formInputs.registration_fee_discount}
                        onChange={handleChange}
                        name="registration_fee_discount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      </>
                    ) : (
                      ""
                    )}
                    {registrationFeeDiscountChecked &&
                    formInputs.fee_status !== 1 ? (

                      <>
                        
                        <TextField
                        fullWidth
                        label="Registration Fee"
                        value={formInputs.registration_fee}
                        onChange={handleChange}
                        name="registration_fee"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Registration Fee Discount"
                        value={formInputs.registration_fee_discount}
                        onChange={handleChange}
                        name="registration_fee_discount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      </>
                    ) : (
                      ""
                    )}
                    {/* {feeDiscountChecked && formInputs.fee_status !== 1 ? (
                      <TextField
                        fullWidth
                        label="Fee Discount"
                        value={formInputs.fee_discount}
                        onChange={handleChange}
                        name="fee_discount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    ) : (
                      ""
                    )} */}
                  </Stack>

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {registrationFeeDiscountChecked &&
                    formInputs.fee_status === 1 &&
                    registrationFee === true ? (
                      <TextField
                        fullWidth
                        label="Registration Fee Discount Reason"
                        // multiline // Enable multiline
                        // rows={3} // Set the number of rows to 3
                        value={formInputs.registration_fee_discount_reason}
                        onChange={handleChange}
                        name="registration_fee_discount_reason"
                      />
                    ) : (
                      ""
                    )}

                    {registrationFeeDiscountChecked &&
                    formInputs.fee_status !== 1 ? (
                      <TextField
                        fullWidth
                        label="Registration Fee Discount Reason"
                        // multiline // Enable multiline
                        // rows={3} // Set the number of rows to 3
                        value={formInputs.registration_fee_discount_reason}
                        onChange={handleChange}
                        name="registration_fee_discount_reason"
                      />
                    ) : (
                      ""
                    )}

                    {formInputs.fee_status !== 1 ? (
                      // <TextField
                      //   fullWidth
                      //   label="Fee Discount Reason"
                      //   multiline // Enable multiline
                      //   rows={3} // Set the number of rows to 3
                      //   value={formInputs.fee_discount_reason}
                      //   onChange={handleChange}
                      //   name="fee_discount_reason"
                      //   required
                      // />
                      <></>
                    ) : (
                      ""
                    )}
                  </Stack>

                  <Stack alignItems="end">
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      sx={{ width: "120px" }}
                    >
                      submit
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </Grid>
          </div>

          <div className="col-12 col-sm-4">
            <Card sx={{ minWidth: 275, marginTop: { xs: 3, sm: 13 } }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  Student Detail
                </Typography>

                <Typography variant="h6" component="div">
                  {capitalizeFirstLetter(studentformInputs.name)}
                </Typography>

                <Typography color="text.secondary">
                  Father Name :{" "}
                  {textEmptyToReturnHyphen(capitalizeFirstLetter(studentformInputs.father_name))}
                </Typography>

                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Contact : +92{studentformInputs.contact_number}
                </Typography>

                <Typography variant="body2">
                  Time Preference : {studentformInputs.time}
                  <br />
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default AssignBatch;
