import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";

import { addTestimonial } from "src/DAL/testimonial";
import { listActivePage } from "src/DAL/page";
import { _add_slider } from "src/DAL/slider";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import TinyEditor from "src/components/Ckeditor";
import { _add_tab } from "src/DAL/tabs";
import { _add_website_course_benefits } from "src/DAL/course_benefits";
import BenefitsList from "../List/components/BenefitsList";
const AddWebsiteCourseBenefits = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [pages, setPages] = useState([]);
  const { id } = useParams();  

  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const [benefits, setBenefits] = useState([
    {
      title: "",
      description: "",
      icon: "",
    },
  ]);
 
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    benefit_title: "",

    description: "",
    created_for: "",
    pages: "",
    button_text: "",
    button_link: "",
    status: "true",
    image: "",
    type: 2,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
 

    let postData = {
      benefit_title: formInputs.benefit_title,

      image: pathOfUploadedFile,

      status: formInputs.status,
      
      // page_id: Array.isArray(formInputs.pages)
      // ? formInputs.pages
      // : formInputs.pages,
      page_id: id,
      benefits: benefits,
    };
  
  
    console.log("postData ____postData", postData);
    setIsLoading(true);
    const result = await _add_website_course_benefits(postData);
    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActivePages = async () => {
    setIsLoading(true);
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetchActivePages();
  }, []);

  //   useEffect(() => {
  //     if (pages.length > 0) {
  //       setFormInputs((prevInputs) => ({
  //         ...prevInputs,
  //         pages: [pages[0].id],
  //       }));
  //     }
  //   }, [pages]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Website Course Benefits">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            Add Website Course Benefits
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Benefit Title"
                  value={formInputs.benefit_title}
                  onChange={handleChange}
                  name="benefit_title"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>

                {/* <TextField
                  fullWidth
                  label="Page"
                  select
                  value={formInputs.pages}
                  onChange={handleChange}
                  name="pages"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.id} value={page.id}>
                      {page.title}
                    </MenuItem>
                  ))}
                </TextField> */}
              </Stack>

              {/* <div className="col-12"> */}
              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File *"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />
              {/* </div> */}
<div className="px-5">
<hr />

<BenefitsList
              additionalFields={benefits}
              setAdditionalFields={setBenefits}

       
              />
</div>
               
 

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AddWebsiteCourseBenefits;
