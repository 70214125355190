import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import {
  _update_batch_status,
  _update_student_status_from_batch,
  listActiveBatch,
} from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";

const BatchStudentStatusUpdate = ({
  studentID,
  batchID,
  handleCloseModal,
  Students,
  onStudentsUpdate,
}) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };
  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 800,
    fontSize: "1.3rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    batch_fee: [],
    student_vouchers: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",
    discount: 0,

    registration_fee_discount: 0,
    fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount_reason: "",

    fee_status: "all_paid",
    batch_name: "",
    freeze_status_reason: "",
    student_status: 1,
  });
  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";
    // student_status

    setIsLoading(true);

    let postData = {
      batch_id: batchID,
      student_id: studentID,
      student_status: formInputs.student_status,
    };

    if(formInputs.student_status===2){
      postData = {
        batch_id: batchID,
        student_id: studentID,
        student_status: formInputs.student_status,
        freeze_status_reason: formInputs.freeze_status_reason,
      };
    }

    const result = await _update_student_status_from_batch(postData);

    if (result.code === 200) {
      setIsLoading(false);
      //   navigate("/batches");
      //   navigate(-1);
      handleCloseModal();

      const updatedStudents = Students.map((student) => {
        if (student.id === studentID) {
          // If the batch id matches, create a new object with batch_status set to 1
          return {
            ...student,
            student_status: formInputs.student_status,
            freeze_status_reason: formInputs.freeze_status_reason,
          };
        } else {
          // For other objects, return them as they are
          return student;
        }
      });

      onStudentsUpdate(updatedStudents);

      enqueueSnackbar("Update Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(studentID);
    console.log("response response detailStudent detail __detail", response);
    if (response.code === 200) {
      setAdditionalFields(response.student.additional_numbers);

      // setCourses(response.student.courses);
      // const courseIds = response.student.courses.map((course) => course._id);

      // const batchIds = response.student.batch_id.map((batch) => batch._id);

      let batchIds = "";

      if (response.student.batch) {
        batchIds = response.student.batch._id;
      }

      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];
      let defaultDate = new Date();
      if (response.student.date_of_birth) {
        defaultDate = new Date(response.student.date_of_birth);
      }
      // console.log('defaultDate',defaultDate);
      //  if(response.student.date_of_birth ==="undefined"){
      //   console.log('defaultDate Date of birth is undefined  ');
      //  }else {

      //   console.log('defaultDate Date of birth is defined');
      //  }

      // const additionalFieldsFromBatch = response.student.decided_fee.map(
      //   (fields, index) => ({
      //     fee: fields.fee,
      //     month: fields.month,
      //     fee_status: fields.fee_status,
      //     // pending_fee: fields.pending_fee?fields.pending_fee:null,
      //     pending_fee: fields.pending_fee ? fields.pending_fee : 0,
      //     // pending_fee: fields.pending_fee,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      console.log("batchID _test", batchID);
      const filteredVoucherArray = response.student_vouchers.filter(
        (item) => item.batch?._id === batchID
      );
      const filteredBatchArray = response.student_batch.filter(
        (item) => item._id === batchID
      );
      let filteredBatchObj =
        filteredBatchArray.length > 0 ? filteredBatchArray[0] : [];
      console.log("filteredBatchArray _test", filteredBatchArray);
      console.log("filteredBatchObj _test", filteredBatchArray);

      let StudentStatus = 1;
      if (filteredBatchObj) {
        if (filteredBatchObj.student_info.student_status) {
          StudentStatus = filteredBatchObj.student_info.student_status;
        }
      }

      setFormInputs((prevInputs) => ({
        ...prevInputs,

        name: response.student.name,
        father_name: response.student.father_name,
        email: response.student.email,
        gender: response.student.gender,
        // student_id: response.student.student_id,
        address: response.student.address,
        contact_number: response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,

        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        time_preferences: response.student.time_preference
          ? response.student.time_preference._id
          : "",

        date_of_birth: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        dob: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        // dob: defaultDate.toISOString().split("T")[0],

        discount: response.student.discount,
        fee_status:
          response.student_batch.fee_status === 1
            ? "All Paid"
            : response.student_batch.fee_status === 2
            ? "Monthly"
            : response.student_batch.fee_status === 3
            ? "Free"
            : "Pending",

        batch_fee: filteredBatchObj
          ? filteredBatchObj.student_info.batch_fee
          : [],
        batch_name: filteredBatchObj ? filteredBatchObj.name : "",

        // student_status: filteredBatchObj ? filteredBatchObj.student_info.student_status : 1,
        student_status: StudentStatus,

        // batch_fee: response.student_batch.batch_fee,
        // student_vouchers: response.student_vouchers,
        student_vouchers: filteredVoucherArray,

        registration_fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount
          : 0,
        registration_fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount_reason
          : " _ _ ",
        fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount
          : 0,
        fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount_reason
          : " _ _ ",

          freeze_status_reason: filteredBatchObj
          ? filteredBatchObj.student_info.freeze_status_reason
          : "",

        createdAt: response.student.createdAt,
        updatedAt: response.student.updatedAt,
      }));

      // ["is_free", "installments", "all_paid"]= [3,2,1]

      // if (response.student.profile_image) {
      //   setImage(response.student.profile_image);
      //   setShow(true);
      // }
      //   if (batchIds && batchIds.length > 0) {
      //     fetchActiveBatches(batchIds);
      //   } else {
      //     fetchActiveBatches();
      //   }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh', // Optional to center vertically in the viewport
  };
  return (
    <Page title="Batch Student Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <div className="container ">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Student Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Batch Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.batch_name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Contact Number
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    +92{formInputs.contact_number}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Gender
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.gender == 1 ? "Male" : "Female"}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>

      <div className="container col-10 mt-3">
        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={1} sx={{ padding: "0px" }}>
              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                      disabled
                      fullWidth
                      label="Student Name"
                      value={formInputs.name}
                      onChange={handleChange}
                      name="name"

                      // inputProps={{
                      //   pattern: "[A-Za-z]{3,}",
                      //   title:
                      //     "Please enter at least 3 letters with only alphabetical characters.",
                      // }}
                    />
                    <TextField
                      disabled
                      fullWidth
                      label="Batch Name"
                      value={formInputs.batch_name}
                      onChange={handleChange}
                      name="name"

                      // inputProps={{
                      //   pattern: "[A-Za-z]{3,}",
                      //   title:
                      //     "Please enter at least 3 letters with only alphabetical characters.",
                      // }}
                    />
                    
                  </Stack> */}

              <TextField
                fullWidth
                label="Student Status"
                select
                value={formInputs.student_status}
                onChange={handleChange}
                name="student_status"
                required
                sx={{ paddingBottom: "5px" }}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Freeze</MenuItem>
              </TextField>

              {formInputs.student_status === 2 ? (
                <TextField
                  fullWidth
                  label="Freeze Status Reason"
                  multiline // Enable multiline
                  rows={3} // Set the number of rows to 3
                  value={formInputs.freeze_status_reason}
                  onChange={handleChange}
                  name="freeze_status_reason"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              ) : null}

              <Stack alignItems="left">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "info.main" }}
                >
                  Please note that if you modify the student's status, its
                  effect is only within that batch.
                </Typography>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default BatchStudentStatusUpdate;
