import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";

import { detailTestimonial, editTestimonial } from "src/DAL/testimonial";
import { listActivePage } from "src/DAL/page";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import { _detail_slider, _edit_slider } from "src/DAL/slider";
import TinyEditor from "src/components/Ckeditor";
import { _detail_tab, _update_tab } from "src/DAL/tabs";
import MenuItems from "../List/components/MenuItems";
import { _detail_menu, _edit_menu } from "src/DAL/menu";
const EditMenu = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const [image, setImage] = useState();
  const [pages, setPages] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");

  const [benefits, setBenefits] = useState([
    {
      title: "",
      item_url: "",
      is_new_tab: true,
      status: true,
    },
  ]);

  const [formInputs, setFormInputs] = useState({
    name: "",
    menu_title: "",

    description: "",
    created_for: "",
    pages: "",
    button_text: "",
    button_link: "",
    status: "true",
    image: "",
    type: 2,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    handleFormSubmit(image);
    setImage("");
    // console.log(image);
    setShow(false);
  }
  const handleFormSubmit = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = "";

   
    let postData = {
      menu_title: formInputs.menu_title,

      menu_items: benefits,
    };

    

    console.log("postData ____postData", postData);
    setIsLoading(true);
    const result = await _edit_menu(id, postData);
    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTestimonialDetail = async () => {
    setIsLoading(true);
    const response = await _detail_menu(id);

    console.log("_detail_menu ____detail_menu", response);

    if (response.code === 200) {
      // const pageIds = response.slider.page_id.map((page) => page._id);
      // const pageIds = response.menus.page_id;
      // console.log(data.instructor.first_name);
      // response.slider

      if (response.menu?.menu_items.length > 0) {
        const newData = response.menu.menu_items.map(({ _id, ...rest }) => rest);
        setBenefits(newData);
      }

 

      // setDetailDescriptionEditor(response.menus.description);
      setFormInputs({
        ...formInputs,
        ...response.menu,

        // name: response.testimonial.name,
        // title: response.testimonial.title,
        // description: response.testimonial.description,
        // created_for: response.testimonial.created_for,
        // pages: pageIds,
        // pages: pageIds,
        // button_text: response.testimonial.button_text,
        // status: response.testimonial.status,
        // button_link: response.testimonial.button_link,
        // image: response.testimonial.image,
        // order: response.testimonial.order,

        // "_id": "65e801056a0d19478088dff0",
        // "title": "zzzz",
        // "type": 2,
        // "image": "file/8f723130-db7b-11ee-89df-c78ea2e24dd2",
        // "description": "zzzz",
        // "status": true,
        // "page_id": "65e6cc46608f82a619c16b05",
        // "button_text": "",
        // "button_link": "",
        // "createdAt": "2024-03-06T05:37:09.759Z",
        // "updatedAt": "2024-03-06T05:37:09.759Z"
      });
      
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActivePages = async () => {
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getTestimonialDetail();
    // fetchActivePages();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
<Page title="Edit Menu">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">Edit Menu</h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={2}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Menu Title"
                  value={formInputs.menu_title}
                  onChange={handleChange}
                  name="menu_title"
                  required
                />
              </Stack>

              <div className="px-4">
                <hr />

                <MenuItems
                  additionalFields={benefits}
                  setAdditionalFields={setBenefits}
                />
              </div>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default EditMenu;
