import React from "react";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

// material
import {
  Stack,
  TextField,
  Avatar,
  Input,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/material/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";
import { detailAdmin, editAdmin } from "src/DAL/auth";
import { addImage } from "src/DAL/uploadfiles";
import { useAppContext } from "src/hooks";
import { Box } from "@mui/system";
import { getStaticPrivilegesObj } from "src/utils/FormatPrivileges";
import {
  _detail_customer_admin,
  _detail_customer_without_token,
  _instructor_detail_with_token,
  _update_customer,
  _update_instructor,
} from "src/DAL/customer";
import { capitalizeFirstLetter } from "src/utils/formatText";

const Edit = () => {
  const theme = useTheme();
  const { _set_user_profile } = useAppContext();
  const [image, setImage] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [show, setShow] = useState(false);
  const [admin, setAdmin] = useState();
  const [path, setPath] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [storedUserType, setStoredUserType] = useState(-1);

  const { enqueueSnackbar } = useSnackbar();

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    profile_image: "",
    last_name: "",
    contact_number: "",
    email: "",
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);

    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
    uploadImage(file);
  }

  const uploadImage = async (img) => {
    // console.log("hello");
    // console.log(img);
    const data = new FormData();
    data.append("file", img);
    setIsLoadingButton(true);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      //handleLoading(index, true);

      // console.log(res,"--data")
      // console.log("path", result.path);
      setPath(result.path);
      setIsLoadingButton(false);
    } else {
      console.log(`Error: ${result.status}`);
      setIsLoadingButton(false);
    }
  };
  function handleDeleteImage() {
    console.log("image  ___image", image);
    console.log("path  ___image", path);
    console.log("formInputs.profile_image  ___image", formInputs.profile_image);

    if (!path || path === null || path === "") {
      imageDelete(formInputs.profile_image);
    } else {
      imageDelete(path);
    }

    setImage("");
    setPreviewUrl("");
    setFormInputs({ ...formInputs, profile_image: "" });
    setShow(false);

    // setPath("");
  }

  const imageDelete = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    setIsLoadingButton(true);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      setPath("");
      //handleLoading(index, true);
      setIsLoadingButton(false);
      // console.log(res,"--data")
    } else {
      setIsLoadingButton(false);
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;

    // Check if the first digit is 3
    if (name === "contact_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", {
        variant: "error",
      });
      return; // Do not update state if the condition is not met
    }

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const storedObjectString = localStorage.getItem("user");
    if (storedObjectString) {
      const storedUser = JSON.parse(storedObjectString);

      const data = new FormData();
      data.append("first_name", formInputs.first_name);
      data.append("last_name", formInputs.last_name);
      // data.append("email", "dynamiclogix@gmail.com");

      if (path) {
        data.append("profile_image", path);
      } else {
        data.append("profile_image", image);
      }

      if (storedUser.type === 0) {
        data.append("email", formInputs.email);
        data.append("contact_number", formInputs.contact_number);
        setIsLoadingButton(true);
        const result = await editAdmin(data);
        console.log("result   ___result", result);
        if (result.code === 200) {
          const token = localStorage.getItem("user");
          const payload = JSON.parse(token);
          // console.log(payload.email);
          const user = {
            ...result.adminUser,
            first_name: result.adminUser.first_name,
            last_name: result.adminUser.last_name,
            image: result.adminUser.profile_image,
            email: result.adminUser.user_id.email,
            contact_number: result.adminUser.contact_number,
            // email: payload.email,
            type: 0,
            privilege: getStaticPrivilegesObj(),
            // privilege:result.user.role.privilege,
          };

          // console.log(JSON.stringify(user));
          localStorage.setItem("user", JSON.stringify(user));
          const storedObjectString = localStorage.getItem("user");
          const storedObject = JSON.parse(storedObjectString);
          _set_user_profile(storedObject);
          setIsLoadingButton(false);
          navigate("/dashboard");
          enqueueSnackbar("Edited Successfully!", { variant: "success" });
        } else {
          setIsLoadingButton(false);
          enqueueSnackbar(result.message, { variant: "error" });
        }
      } else if (storedUser.type === 3) {
        setIsLoadingButton(true);
        let postData = {
          first_name: formInputs.first_name,
          last_name: formInputs.last_name,
          profile_image: path ? path : image,
          contact_number: formInputs.contact_number,
          post_code: formInputs.post_code,
        };

        console.log("postData  __postData", postData);
        console.log("path  __postData", path);
        console.log("image  __postData", image);

        const result = await _update_customer(postData);
        if (result.code === 200) {
          const token = localStorage.getItem("user");
          const payload = JSON.parse(token);
          // console.log(payload.email);
          const user = {
            ...result.customer,
            first_name: result.customer.first_name,
            last_name: result.customer.last_name,
            image: result.customer.profile_image,
            email: payload.email,
            type: 3,
            privilege: formInputs.role.privilege,
          };

          // console.log(JSON.stringify(user));
          localStorage.setItem("user", JSON.stringify(user));
          const storedObjectString = localStorage.getItem("user");
          const storedObject = JSON.parse(storedObjectString);
          _set_user_profile(storedObject);
          setIsLoadingButton(false);
          navigate("/dashboard");
          enqueueSnackbar("Edited Successfully!", { variant: "success" });
        } else {
          setIsLoadingButton(false);
          enqueueSnackbar(result.message, { variant: "error" });
        }
      } else if (storedUser.type === 2) {
        // data.append("email", formInputs.email);
        // data.append("contact_number", formInputs.contact_number);

        setIsLoadingButton(true);
        let postData = {
          first_name: formInputs.first_name,
          last_name: formInputs.last_name,
          image: path ? path : image,
          email: formInputs.email,
          // contact_number: formInputs.contact_number,
          // post_code: formInputs.post_code,
        };
        console.log("result __postData postData", postData);
        console.log("result __postData path", path);
        console.log("result __postData image", image);
        console.log("result __postData previewUrl", previewUrl);

        const result = await _update_instructor(postData);
        console.log("result __update_instructor ____update_instructor", result);
        if (result.code === 200) {
          const token = localStorage.getItem("user");
          const payload = JSON.parse(token);
          // console.log(payload.email);
          const user = {
            ...result.instructor,
            first_name: result.instructor.first_name,
            last_name: result.instructor.last_name,
            image: result.instructor.image,
            email: result.instructor.email,
            // email: payload.email,
            type: 2,
            privilege: formInputs.role.privilege,
          };

          localStorage.setItem("user", JSON.stringify(user));
          const storedObjectString = localStorage.getItem("user");
          const storedObject = JSON.parse(storedObjectString);
          _set_user_profile(storedObject);
          setIsLoadingButton(false);
          navigate("/dashboard");
          enqueueSnackbar("Edited Successfully!", { variant: "success" });
        } else {
          setIsLoadingButton(false);
          enqueueSnackbar(result.message, { variant: "error" });
        }
      }
    }
  };

  const fetchProfile = async () => {
    // Check if "user" is in localStorage and parse it
    const storedObjectString = localStorage.getItem("user");
    if (storedObjectString) {
      const storedUser = JSON.parse(storedObjectString);

      if (storedUser.type === 0) {
        // Type 0: Make API call using detailAdmin()
        const response = await detailAdmin();
        console.log("response ___response", response);
        if (response.code === 200) {
          setStoredUserType(0);
          setIsLoading(false);
          setAdmin(response.admin);
          setFormInputs({
            ...response.admin,
            first_name: capitalizeFirstLetter(response.admin.first_name),
            last_name: capitalizeFirstLetter(response.admin.last_name),
            profile_image: response.admin.profile_image
              ? response.admin.profile_image
              : "",
            _id: response.admin.user_id._id,
            contact_number: response.admin.contact_number,
            email: response.admin.email,
          });
          if (response.admin.profile_image) {
            setImage(response.admin.profile_image);
          }
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else if (storedUser.type === 3) {
        // Type 3: Make API call using detailAdmin()
        const response = await _detail_customer_without_token();
        console.log("response _detail_customer_without_token", response);
        if (response.code === 200) {
          setStoredUserType(3);
          setIsLoading(false);
          setAdmin(response.customer);
          setFormInputs({
            ...response.customer,
            first_name: capitalizeFirstLetter(response.customer.first_name),
            last_name: capitalizeFirstLetter(response.customer.last_name),
            // profile_image: response.customer.profile_image,
            profile_image: response.customer.profile_image
              ? response.customer.profile_image
              : "",
            _id: response.customer.user_id._id,
            contact_number: response.customer.contact_number,
            email: response.customer.email,
            // post_code: response.customer.post_code,
            // role_id: response.customer.role._id,
          });
          if (response.customer.profile_image) {
            setImage(response.customer.profile_image);
          }
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else if (storedUser.type === 2) {
        // Type 3: Make API call using detailAdmin()
        const response = await _instructor_detail_with_token();
        console.log("response _instructor_detail_with_token", response);
        if (response.code === 200) {
          setStoredUserType(2);
          setIsLoading(false);
          setAdmin(response.instructor);
          setFormInputs({
            ...response.instructor,
            first_name: capitalizeFirstLetter(response.instructor.first_name),
            last_name: capitalizeFirstLetter(response.instructor.last_name),
            // profile_image: response.instructor.image,
            _id: response.instructor.user_id,
            profile_image: response.instructor.image
              ? response.instructor.image
              : "",
            // contact_number: response.customer.contact_number,
            // post_code: response.customer.post_code,
            // role_id: response.customer.role._id,
          });
          if (response.instructor.image) {
            setImage(response.instructor.image);
          }
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Profile">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Edit Profile{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Card sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
            <CardContent>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3}>
                  <Stack alignItems="center">
                    <Box sx={{ position: "relative" }}>
                      {previewUrl ? (
                        <>
                          <Avatar
                            alt={"DDIT"}
                            src={previewUrl}
                            sx={{ width: "120px", height: "120px" }}
                          />

                          {previewUrl !== "" ? (
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteImage()}
                              style={{
                                position: "absolute",
                                top: -10,
                                right: -10,
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <Avatar
                            alt={"DDIT"}
                            src={s3baseUrl + formInputs.profile_image}
                            sx={{ width: "120px", height: "120px" }}
                          />
                          {formInputs.profile_image !== "" ? (
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteImage()}
                              style={{
                                position: "absolute",
                                top: -10,
                                right: -10,
                              }}
                            >
                              <ClearIcon />
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                      <IconButton
                        component="label"
                        sx={{
                          position: "absolute",
                          right: "-10px",
                          top: "70px",
                          backgroundColor: "transparent",
                          borderRadius: "50%",
                          zIndex: "1",
                          color: theme.palette.primary.main,
                        }}
                      >
                        <PhotoCamera />
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          hidden
                          onChange={handleFile}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="First Name"
                      value={formInputs.first_name}
                      onChange={handleChange}
                      name="first_name"
                      required
                      inputProps={{
                        pattern: "[A-Za-z]{3,}",
                        title:
                          "Please enter at least 3 letters with only alphabetical characters.",
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Last Name"
                      value={formInputs.last_name}
                      onChange={handleChange}
                      name="last_name"
                      inputProps={{
                        pattern: "[A-Za-z]{3,}",
                        title:
                          "Please enter at least 3 letters with only alphabetical characters.",
                      }}
                    />
                  </Stack>
                  {storedUserType === 0 ? (
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <TextField
                        fullWidth
                        type="email"
                        label="Email address"
                        value={formInputs.email}
                        onChange={handleChange}
                        name="email"
                        autoComplete="off"
                        required
                        inputProps={{
                          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                          title: "Please enter a valid email address",
                        }}
                      />

                      <TextField
                        fullWidth
                        label="Phone Number"
                        value={formInputs.contact_number}
                        required
                        onChange={handleChangePhone}
                        name="contact_number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +92
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          maxLength: 10, // Limit the input to 10 characters
                          title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Stack>
                  ) : (
                    ""
                  )}

                  {storedUserType === 2 ? (
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <TextField
                        fullWidth
                        type="email"
                        label="Email address"
                        value={formInputs.email}
                        onChange={handleChange}
                        name="email"
                        autoComplete="off"
                        required
                        inputProps={{
                          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                          title: "Please enter a valid email address",
                        }}
                      />
                    </Stack>
                  ) : (
                    ""
                  )}

                  {storedUserType === 3 ? (
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        value={formInputs.contact_number}
                        required
                        onChange={handleChangePhone}
                        name="contact_number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +92
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          maxLength: 10, // Limit the input to 10 characters
                          title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Stack>
                  ) : (
                    ""
                  )}

                  {/* {show === false ? (
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <label htmlFor="image" className="form-label">
                        Image:
                      </label>
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleFile}
                      />
                    </Stack>
                  ) : null}
                  {show === true ? (
                    <Stack
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <label htmlFor="image" className="form-label">
                        Image:
                      </label>
                      <img
                        alt="image"
                        src={previewUrl}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteImage()}
                        style={{ position: "absolute", top: 0, right: 0 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Stack>
                  ) : null} */}

                  <Stack alignItems="end">
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isLoadingButton}
                      sx={{ width: "120px" }}
                      disabled={isLoadingButton}
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
