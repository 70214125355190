import * as React from "react";
// import { PieChart } from "@mui/x-charts/PieChart";
import { PieChart } from '@mui/x-charts';
export default function CustomBasicPie({

    firstLabelValue,
    firstLabelText,

    secondLabelValue,
    secondLabelText,

    thirdLabelValue,
    thirdLabelText,

}) {

  console.log("firstLabelValue   __firstLabelValue",firstLabelValue);
  console.log("firstLabelValue   __firstLabelValue typeof", typeof firstLabelValue);
  return (
    <PieChart
      // series={[
      //   {
      //     data: [
      //       { id: 0, value: {firstLabelValue}, label: {firstLabelText} },
      //       { id: 1, value: {secondLabelValue}, label: {secondLabelText} },
      //       { id: 2, value: {thirdLabelValue}, label: {thirdLabelText} },
      //     ],
      //   },
      // ]}
      // colors={['yellow', 'orange', 'green']} // Use palette
      // colors={['#e7dc14', '#e5b910', '#4fb202']} // Use palette
      colors={['#ddd300', '#d2a700', '#01b32b']} // Use palette
      series={[
        {
          data: [
            { id: 0, value: firstLabelValue, label: firstLabelText },
            { id: 1, value: secondLabelValue, label: secondLabelText },
            { id: 2, value: thirdLabelValue, label: thirdLabelText },
          ],
        },
      ]}
      width={480}
      height={200}
    />
  );
}
