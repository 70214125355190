import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { detailCourse, _update_course_outline_details } from "src/DAL/course";
import moment from "moment";

const CourseOutline = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [additionalFields, setAdditionalFields] = useState([
    { outline: "", type: [""] }, // Modify type to be an array
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  // Function to handle changes in the child nested array
  const handleFieldTypeChange = (index, typeIndex, newValue) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].type[typeIndex] = newValue;
    setAdditionalFields(updatedFields);
  };

  // Function to add a new element to the child nested array
  const handleAddType = (index) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].type.push(""); // Add an empty string initially
    setAdditionalFields(updatedFields);
  };

  // Function to remove an element from the child nested array
  const handleRemoveType = (index, typeIndex) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].type.splice(typeIndex, 1);
    setAdditionalFields(updatedFields);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    // if (value.length > 0 && value[0] !== "3") {
    //   enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
    //   return; // Do not update state if the condition is not met
    // }
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { outline: "", type: [""] }, // Modify type to be an array
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    // const additionalFieldsArray = additionalFields.map((field) => field.outline);

    let postData = {
      outline_details: additionalFields,
    };

    setIsLoading(true);
    const result = await _update_course_outline_details(id, postData);
    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      // setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchDetailCourse() {
    setIsLoading(true);
    const response = await detailCourse(id);
    console.log("response  __response", response);
    if (response.code === 200) {
      if (response.course.outline_details?.length > 0) {
        setAdditionalFields(response.course.outline_details);
      }

      // setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
    }, 500);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchDetailCourse();
  }, []);

  useEffect(() => {
    console.log("additionalFields __additionalFields", additionalFields);
  }, [additionalFields]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Course Outline">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Course Outline{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              {additionalFields.map((field, index) => (
                <div key={index} className="mb-2">
                  <div
                    className="mb-3"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* Conditionally render the Remove button */}
                    {/* {showRemoveButtons[index] && ( */}

                    {index === 0 ? null : ( // If it's the first field, don't render the delete button
                      <IconButton
                        aria-label="Delete Field"
                        onClick={() => handleDeleteField(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <TextField
                      fullWidth
                      label={`Outline ${index + 1}`}
                      name="outline"
                      value={field.outline}
                      // onChange={(e) =>
                      //   handleFieldTypeChange(index, 0, e.target.value)
                      // }
                      onChange={(e) => handleFieldChange(e, index)}
                      required
                    />

                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddField} // Your function to add a new field
                      sx={{ marginLeft: "10px" }}
                    >
                      Add Field
                    </Button>
                  </div>

                  {field.type.map((typeValue, typeIndex) => (
                    <div
                      className="mb-3"
                      key={typeIndex}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {field.type.length > 1 && (
                        <IconButton
                          aria-label="Remove Type"
                          onClick={() => handleRemoveType(index, typeIndex)}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      <TextField
                        fullWidth
                        label={`Outline Detail ${typeIndex + 1}`}
                        value={typeValue}
                        onChange={(e) =>
                          handleFieldTypeChange(
                            index,
                            typeIndex,
                            e.target.value
                          )
                        }
                        required
                      />
                    </div>
                  ))}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleAddType(index)}
                  >
                    Add Type
                  </Button>
                </div>
              ))}
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default CourseOutline;
