import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import Autocomplete from "@mui/material/Autocomplete";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _add_collect_fee,
  _add_refund_fee,
  _pay_registration_fee,
  _pay_tuition_fee,
  _refund_registration_fee,
  _refund_tution_fee,
} from "src/DAL/collect_fee";
import PrintInvoiceByID from "../PrintInvoice/PrintInvoiceByID";
import { _active_person_list, _list_person } from "src/DAL/person";
import { _active_bank_list } from "src/DAL/bank";
const Edit = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState("disabled");
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [students, setStudents] = useState([]);
  const [persons, setPersons] = useState([]);
  const [banks, setBanks] = useState([]);

  const [additionalFieldsFee, setAdditionalFieldsFee] = useState([]);

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs().add(1, "month"), // Add 1 month to the current date
  });

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    gender: "male",
    courses: [],
    phone: "",
    fee_status: "registration_fee",
    status: "true",
    education: "",
    image: "",
    address: "",
    reg_type: "",
    course_fee: "",
    registration_fee: "",
    decided_fee: [],
    actual_fee: "",
    paid_fee: "0",
    refund_fee: "0",
    due_fee: "",
    duration: "1",
    fee_type: "paid",
    mode: "person",
    person_id: "",
    bank_id: "",
    batches: [],
    wallet_fee: "0",
    wallet_amount: "0",
    receipts: "",
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   const formattedDate = dayjs(date).format("MM/DD/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleFieldChangeFee = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsFee((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = "";

    if (typeof selected.id === "undefined") {
      error = "Please! Select A Student";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      let paidFee = parseInt(formInputs.paid_fee, 10);
      let fee_status = formInputs.fee_status;
      let fee_type = formInputs.fee_type;
      let start_date = dayjs(formInputs2.duration).format("YYYY-MM-DD");
      let end_date = dayjs(formInputs2.duration2).format("YYYY-MM-DD");
      let student_id = selected.id;
      let mode = formInputs2.mode;
      let refund_fee = parseInt(formInputs.refund_fee, 10);

      // let decided_fee = parseInt(formInputs.decided_fee, 10);

      if (formInputs.fee_type === "refund") {
        if (fee_status === "registration_fee") {
          let postData = {
            student_id: student_id,
            refund_fee: parseInt(formInputs.refund_fee, 10),
            mode: mode,
          };

          console.log("postData", postData);
          setIsLoading(true);
          setIsButtonLoading(true);
          const result = await _refund_registration_fee(postData);
          console.log("result result result _pay_registration_fee", result);
          if (result.code === 200) {
            navigate("/invoice-fee");
            enqueueSnackbar("Refund Registration Fee Successfully!", {
              variant: "success",
            });
            setIsLoading(false);
            setIsButtonLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
            setIsButtonLoading(false);
          }
        }

        if (fee_status === "registration_and_tution") {
          let postData = {
            student_id: student_id,
            refund_fee: parseInt(formInputs.refund_fee, 10),
            mode: mode,
          };

          console.log("postData", postData);
          setIsLoading(true);
          setIsButtonLoading(true);
          const result = await _refund_tution_fee(postData);
          console.log("result result result _pay_registration_fee", result);
          if (result.code === 200) {
            navigate("/invoice-fee");
            enqueueSnackbar("Refund Tution Fee Successfully!", {
              variant: "success",
            });
            setIsLoading(false);
            setIsButtonLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
            setIsButtonLoading(false);
          }
        }
      } else {
        if (
          !isNaN(paidFee) &&
          paidFee > 0 &&
          fee_status === "registration_fee"
        ) {
          ///////////////////////////////// Registration Fee

          let postData = [];
          let personID = formInputs.person_id;
          let bankID = formInputs.bank_id;

          if (Array.isArray(personID)) {
            personID = personID.join(", "); // You can choose any separator you like
          }
          if (Array.isArray(bankID)) {
            bankID = bankID.join(", "); // You can choose any separator you like
          }

          if (formInputs.mode === "person") {
            postData = {
              student_id: student_id,
              paid_fee: parseInt(formInputs.paid_fee, 10),
              person: personID,
            };
          } else if (formInputs.mode === "bank") {
            postData = {
              student_id: student_id,
              paid_fee: parseInt(formInputs.paid_fee, 10),
              bank: bankID,
              bank_recepit: formInputs.receipts,
            };
          } else if (formInputs.mode === "wallet") {
            postData = {
              student_id: student_id,
              paid_fee: parseInt(formInputs.paid_fee, 10),
              wallet: student_id,
              // wallet_amount:formInputs.wallet_amount,
            };
          }

          console.log("postData", postData);
          setIsLoading(true);
          setIsButtonLoading(true);
          const result = await _pay_registration_fee(postData);
          console.log("result result result _pay_registration_fee", result);
          if (result.code === 200) {
            navigate("/invoice-fee");
            enqueueSnackbar("Pay Registration Fee Successfully!", {
              variant: "success",
            });
            setIsLoading(false);
            setIsButtonLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
            setIsButtonLoading(false);
          }
        }
        ///////////////////////////////// Tution Fee
        else if (fee_status === "registration_and_tution") {
          console.log("additionalFieldsFee", additionalFieldsFee);

          const transformedArray = additionalFieldsFee.map((item) => ({
            month: item.month,
            fee: item.fee,
            fee_status: item.fee_status,
          }));

          let postData = [];
          let personID = formInputs.person_id;
          let bankID = formInputs.bank_id;

          if (Array.isArray(personID)) {
            personID = personID.join(", "); // You can choose any separator you like
          }
          if (Array.isArray(bankID)) {
            bankID = bankID.join(", "); // You can choose any separator you like
          }

          if (formInputs.mode === "person") {
            postData = {
              student_id: student_id,
              // paid_fee: parseInt(formInputs.paid_fee, 10),
              decided_fee: transformedArray,
              person: personID,
            };
          } else if (formInputs.mode === "bank") {
            postData = {
              student_id: student_id,
              // paid_fee: parseInt(formInputs.paid_fee, 10),
              decided_fee: transformedArray,
              bank: bankID,
              bank_recepit: formInputs.receipts,
            };
          } else if (formInputs.mode === "wallet") {
            postData = {
              student_id: student_id,
              // paid_fee: parseInt(formInputs.paid_fee, 10),
              decided_fee: transformedArray,
              wallet: student_id,
              wallet_amount: formInputs.wallet_amount,
            };
          }

          console.log("postData", postData);
          setIsLoading(true);
          setIsButtonLoading(true);
          const result = await _pay_tuition_fee(postData);
          console.log("result result result _pay_tuition_fee", result);
          if (result.code === 200) {
            navigate("/invoice-fee");
            enqueueSnackbar("Pay Tuition Fee Successfully!", {
              variant: "success",
            });
            setIsLoading(false);
            setIsButtonLoading(false);
          } else {
            enqueueSnackbar(result.message, { variant: "error" });
            setIsLoading(false);
            setIsButtonLoading(false);
          }
        } else {
          // Handle the case when paidFee is not a valid number
          enqueueSnackbar("Invalid paid fee value", { variant: "error" });
        }
      }
    }
  };

  // useEffect(() => {
  //   if (courses.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       courses: [courses[0]._id],
  //     }));
  //   }
  // }, [courses]);
  useEffect(() => {
    if (batches.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        batches: [batches[0].id],
      }));
    }
  }, [batches]);

  const fetchActiveBatches = async () => {
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      setCourses(batches[0].courses);
      setBatches(batches);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await listStudent();
    console.log("response response response _listStudent", response);
    if (response.code === 200) {
      const students = response.students.map((student) => ({
        id: student._id,
        reg_type: student.reg_type,
        name: student.name,
        first_name: student.name,
        father_name: student.father_name,
        profile_image: student.profile_image,
        date_of_birth: student.date_of_birth,
        email: student.email,
        image: student.profile_image,
        status: student.status,
        contact_number: student.contact_number,
        gender: student.gender,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        // decided_fee: student.decided_fee,
        decided_fee: student.decided_fee ? student.decided_fee : [],

        registration_fee: student.total_registration_fee,
        batch: student.batch_id ? student.batch_id : "",
        courses: student.courses ? student.courses : "",

        paid_fee: student.paid_fee,
        due_fee: student.due_fee,
        wallet_fee: student.wallet_amount ? student.wallet_amount : "0",
      }));

      setStudents(students);
      console.log("students students students", students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActivePersons = async () => {
    setIsLoading(true);
    const response = await _active_person_list();
    console.log("response _active_person_list", response);
    if (response.code === 200) {
      const personsData = response.persons.map((person, index) => ({
        id: person._id,
        name: person.name,
        phone_number: person.phone_number,
        email: person.email,
        address: person.address,
        status: person.status,
        number: index + 1,
      }));
      setPersons(personsData);
      if (personsData.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          person_id: [personsData[0].id],
        }));
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveBanks = async () => {
    setIsLoading(true);
    const response = await _active_bank_list();
    console.log("response _active_bank_list", response);
    if (response.code === 200) {
      const banksData = response.banks.map((bank, index) => ({
        id: bank._id,
        name: bank.name,
        branch_code: bank.branch_code,
        address: bank.address,
        telephone_number: bank.telephone_number,
        status: bank.status,
        number: index + 1,
      }));
      setBanks(banksData);
      if (banksData.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          bank_id: [banksData[0].id],
        }));
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const updateFormInputs = (selectedValues) => {
    // Create a copy of the existing formInputs state
    const updatedFormInputs = { ...formInputs };

    // Update the desired properties with the selected values
    updatedFormInputs.first_name = selectedValues.first_name;
    updatedFormInputs.father_name = selectedValues.father_name;
    updatedFormInputs.email = selectedValues.email;

    updatedFormInputs.gender = selectedValues.gender;
    updatedFormInputs.courses = selectedValues.courses;
    updatedFormInputs.phone = selectedValues.phone;
    // updatedFormInputs.fee_status = selectedValues.fee_status;
    updatedFormInputs.status = selectedValues.status;
    updatedFormInputs.reg_type = selectedValues.reg_type;
    updatedFormInputs.course_fee = selectedValues.course_fee;
    updatedFormInputs.registration_fee = selectedValues.registration_fee;
    updatedFormInputs.decided_fee = selectedValues.decided_fee;
    updatedFormInputs.actual_fee = selectedValues.actual_fee;

    updatedFormInputs.wallet_fee = selectedValues.wallet_fee;

    // updatedFormInputs.paid_fee = selectedValues.paid_fee;
    updatedFormInputs.batches = selectedValues.batches;
    // updatedFormInputs.batch = selectedValues.batch;
    // updatedFormInputs.date_of_birth = selectedValues.date_of_birth;
    updatedFormInputs.contact_number = "0" + selectedValues.contact_number;
    // updatedFormInputs.phone_number = selectedValues.contact_number;
    updatedFormInputs.phone_number =
      "0" + selectedValues.contact_number.toString();
    // ... Update other properties as needed

    // Set the updated formInputs state
    setAdditionalFieldsFee(selectedValues.decided_fee);
    setFormInputs(updatedFormInputs);
  };

  const handleSelectChange = (event, newValue) => {
    if (newValue === null) {
      console.log("newValue", newValue);
      setSelected([]);
      updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setSelected(newValue);
      updateFormInputs(newValue);
    }
  };

  useEffect(() => {
    fetchActiveBatches();
  }, []);

  useEffect(() => {
    fetchStudents();
    fetchActivePersons();
    fetchActiveBanks();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Invoice">
      <Grid
        // container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px", padding: "0 35px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Create Invoice
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack spacing={2} sx={{ marginBottom: 2 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={students}
              getOptionLabel={(student) =>
                student.first_name ? student.first_name : ""
              }
              value={selected}
              onChange={handleSelectChange}
              renderInput={(params) => (
                <TextField {...params} label="Students *" />
              )}
            />
          </Stack>

          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            {/* <Stack sx={{ marginBottom: 2 }}> */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ marginBottom: 2 }}
            >
              {/* <TextField
                fullWidth
                label="Fee Type"
                select
                // required
                value={formInputs.fee_type}
                onChange={handleChange}
                name="fee_type"
              >
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="refund">Refund Fee</MenuItem>
              </TextField> */}

              <TextField
                // sx={{ marginBottom: 2 }}
                fullWidth
                label="Fee Status"
                select
                // required
                value={formInputs.fee_status}
                onChange={handleChange}
                name="fee_status"
              >
                <MenuItem value="registration_fee">Registration Fee</MenuItem>
                <MenuItem value="registration_and_tution">Tution Fee</MenuItem>
              </TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              // sx={{ marginBottom: 2 }}
            >
              {formInputs.mode === "person" ? (
                <TextField
                  fullWidth
                  label="Person"
                  select
                  value={formInputs.person_id}
                  onChange={handleChange}
                  name="person_id"
                  // required
                  // SelectProps={{ multiple: true }}
                >
                  {persons.map((person) => (
                    <MenuItem key={person.id} value={person.id}>
                      {person.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : formInputs.mode === "wallet" ? (
                <TextField
                  fullWidth
                  label="Wallet"
                  value={formInputs.wallet_fee}
                  onChange={handleChange}
                  name="wallet_fee"
                  disabled
                />
              ) : formInputs.mode === "bank" ? (
                <TextField
                  fullWidth
                  label="Bank"
                  select
                  value={formInputs.bank_id}
                  onChange={handleChange}
                  name="bank_id"
                  // required
                  // SelectProps={{ multiple: true }}
                >
                  {banks.map((bank) => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                ""
              )}

              <TextField
                // sx={{ marginBottom: 2 }}
                fullWidth
                label="Mode"
                select
                // required
                value={formInputs.mode}
                onChange={handleChange}
                name="mode"
              >
                <MenuItem value="person">Person</MenuItem>
                <MenuItem value="wallet">Wallet</MenuItem>
                <MenuItem value="bank">Bank</MenuItem>
              </TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ marginTop: 2 }}
            >
              {formInputs.fee_type === "paid" &&
              formInputs.fee_status !== "registration_and_tution" ? (
                <TextField
                  key="paid-fee-input"
                  fullWidth
                  label="Paid fee"
                  value={formInputs.paid_fee}
                  onChange={handleChange}
                  name="paid_fee"
                  required
                />
              ) : formInputs.fee_status !== "registration_and_tution" ? (
                <TextField
                  // sx={{ marginBottom: 2 }}
                  key="refund-fee-input"
                  fullWidth
                  label="Refund fee"
                  value={formInputs.refund_fee}
                  onChange={handleChange}
                  name="refund_fee"
                  // required
                />
              ) : (
                ""
              )}

              {formInputs.mode === "bank" ? (
                <TextField
                  fullWidth
                  label="Receipts"
                  value={formInputs.receipts}
                  onChange={handleChange}
                  name="receipts"
                  required
                />
              ) : formInputs.mode === "wallet" &&
                formInputs.fee_status === "registration_and_tution" ? (
                <TextField
                  fullWidth
                  label="Wallet Amount"
                  value={formInputs.wallet_amount}
                  onChange={handleChange}
                  name="wallet_amount"
                  required
                />
              ) : (
                ""
              )}
            </Stack>
            {formInputs.fee_status === "registration_and_tution" ? (
              // <Stack
              //   direction={{ xs: "column", sm: "row" }}
              //   spacing={2}
              //   sx={{ marginTop: 2 }}
              // >
              //   {/* <div className="col-md-12 col-xs-12"> */}
              //   <LocalizationProvider dateAdapter={AdapterDayjs}>
              //     <DatePicker
              //       // disabled
              //       label="Start Date"
              //       format="YYYY-MM-DD"
              //       // value={formInputs?.date_of_birth}
              //       value={dayjs(formInputs2.duration)} // Convert the default date to a Day.js object
              //       onChange={handleDateChange}
              //       // maxDate={dayjs()} // Set the maximum date to today's date
              //       // renderInput={(params) => <TextField {...params} />}
              //       sx={{
              //         width: "100%", // Set the width to 100% to take up the full available width
              //         // marginTop: "20px",
              //       }}
              //     />
              //   </LocalizationProvider>
              //   {/* </div> */}

              //   {/* <div className="col-md-12 col-xs-12"> */}
              //   <LocalizationProvider dateAdapter={AdapterDayjs}>
              //     <DatePicker
              //       // disabled
              //       label="End Date"
              //       format="YYYY-MM-DD"
              //       // value={formInputs?.date_of_birth}
              //       value={dayjs(formInputs2.duration2)} // Convert the default date to a Day.js object
              //       onChange={handleDateChange2}
              //       // maxDate={dayjs()} // Set the maximum date to today's date
              //       // renderInput={(params) => <TextField {...params} />}
              //       sx={{
              //         width: "100%", // Set the width to 100% to take up the full available width
              //         // marginTop: "20px",
              //       }}
              //     />
              //   </LocalizationProvider>
              //   {/* </div> */}
              // </Stack>
              <></>
            ) : (
              ""
            )}

            {formInputs.fee_status === "registration_and_tution" &&
              additionalFieldsFee.map((field, index) => (
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  key={index}
                  sx={{ marginTop: 2 }}
                >
                  {field && (
                    <>
                      <TextField
                        fullWidth
                        label={`Fee ${index + 1}`}
                        name="fee"
                        value={field.fee}
                        onChange={(e) => handleFieldChangeFee(e, index)}
                        // sx={{ marginRight: "10px" }}
                        disabled
                      />

                      <TextField
                        fullWidth
                        label="Month"
                        select
                        name="month"
                        value={field.month}
                        onChange={(e) => handleFieldChangeFee(e, index)}
                        disabled
                      >
                        <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>
                      </TextField>

                      <TextField
                        fullWidth
                        label="Fee Status"
                        select
                        name="fee_status"
                        value={field.fee_status}
                        onChange={(e) => handleFieldChangeFee(e, index)}
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="paid">Paid</MenuItem>
                        <MenuItem value="refund">Refund</MenuItem>
                      </TextField>
                    </>
                  )}
                </Stack>
              ))}

            <Stack alignItems="end" sx={{ marginBottom: 2, marginTop: 2 }}>
              <LoadingButton
                key="add-button"
                size="medium"
                type="submit"
                variant="contained"
                loading={isButtonLoading}
                sx={{ width: "120px" }}
                disabled={typeof selected.id === "undefined" || isButtonLoading}
              >
                Add
              </LoadingButton>
            </Stack>
          </form>

          {typeof selected.id !== "undefined" ? (
            <>
              {/* <PrintInvoiceByID
                // ID={setStudents}
                ID={selected.id}
                // ID='64aba79670f8060debb5d19a'
              /> */}

              <Stack spacing={3} sx={{ marginY: "20px" }}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    disabled
                    fullWidth
                    // type="number"
                    label="Registration fee"
                    value={formInputs.course_fee}
                    required
                    onChange={handleChange}
                    name="course_fee"
                    // inputProps={{
                    //   // pattern: "[0-9]{11}",
                    //   pattern: "[0-9]",
                    //   title:
                    //     "Please enter a valid Course fee",
                    // }}
                  />

                  <TextField
                    fullWidth
                    disabled
                    // type="number"
                    label="Decided Registration fee"
                    value={formInputs.registration_fee}
                    required
                    onChange={handleChange}
                    name="registration_fee"
                    // inputProps={{
                    //   // pattern: "[0-9]{11}",
                    //   pattern: "[0-9]",
                    //   title:
                    //     "Please enter a valid Course fee",
                    // }}
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    disabled
                    fullWidth
                    // type="number"
                    label="Course Actual fee"
                    value={formInputs.actual_fee}
                    required
                    onChange={handleChange}
                    name="actual_fee"
                    // inputProps={{
                    //   // pattern: "[0-9]{11}",
                    //   pattern: "[0-9]",
                    //   title:
                    //     "Please enter a valid Course fee",
                    // }}
                  />

                  {/* <TextField
                    disabled
                    fullWidth
                    // type="number"
                    label="Course Decided fee"
                    value={formInputs.decided_fee}
                    required
                    onChange={handleChange}
                    name="decided_fee"
                    // inputProps={{
                    //   // pattern: "[0-9]{11}",
                    //   pattern: "[0-9]",
                    //   title:
                    //     "Please enter a valid Course fee",
                    // }}
                  /> */}
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    disabled
                    fullWidth
                    label="Name"
                    value={formInputs.first_name}
                    onChange={handleChange}
                    name="first_name"
                    required
                    inputProps={{
                      pattern: "[A-Za-z]{3,}",
                      title:
                        "Please enter at least 3 letters with only alphabetical characters.",
                    }}
                  />

                  <TextField
                    disabled
                    fullWidth
                    label="Father name"
                    value={formInputs.father_name}
                    onChange={handleChange}
                    name="father_name"
                    required
                    inputProps={{
                      pattern: "[A-Za-z]{3,}",
                      title:
                        "Please enter at least 3 letters with only alphabetical characters.",
                    }}
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    disabled
                    fullWidth
                    type="email"
                    label="Email address"
                    value={formInputs.email}
                    onChange={handleChange}
                    name="email"
                    autoComplete="off"
                    required
                    inputProps={{
                      pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                      title: "Please enter a valid email address",
                    }}
                  />

                  <TextField
                    disabled
                    fullWidth
                    label="Gender"
                    select
                    required
                    value={formInputs.gender}
                    onChange={handleChange}
                    name="gender"
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </TextField>

                  {/* <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                  required
                >
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="due">Due</MenuItem>
                  <MenuItem value="free">Full Fee Scholarship</MenuItem>
                </TextField> */}
                </Stack>

                {/* <TextField
              fullWidth
              disabled
              label="Batch"
              select
              value={formInputs.batches}
              onChange={handleChange}
              name="batches"
              required
              // SelectProps={{ multiple: true }}
            >
              {batches.map((batch) => (
                <MenuItem key={batch.id} value={batch.id}>
                  {batch.name}
                </MenuItem>
              ))}
            </TextField> */}
                {/* <TextField
              disabled
              fullWidth
              label="Courses"
              select
              value={formInputs.courses}
              onChange={handleChange}
              name="courses"
              required
              SelectProps={{ multiple: true }}
            >
              {courses.map((course) => (
                <MenuItem key={course._id} value={course._id}>
                  {course.title}
                </MenuItem>
              ))}
            </TextField> */}
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  {/* <TextField
                disabled
                fullWidth
                label="Phone Number"
                value={formInputs.phone_number}
                required
                onChange={handleChange}
                name="phone_number"
                // inputProps={{
                //   pattern: "[0-9]{11}",
                //   title:
                //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                // }}
              /> */}

                  {/* <TextField
                disabled
                fullWidth
                label="Phone"
                value={formInputs.phone_number}
                onChange={handleChange}
                name="phone_number"
                // required
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              /> */}
                  <TextField
                    disabled
                    fullWidth
                    // label="Phone"
                    defaultValue={formInputs.phone_number}
                    value={formInputs.phone_number}
                    onChange={handleChange}
                    name="phone_number"
                  />
                  <TextField
                    disabled
                    fullWidth
                    label="Status"
                    select
                    value={formInputs.status}
                    onChange={handleChange}
                    name="status"
                    required
                  >
                    <MenuItem value="true">Active</MenuItem>
                    <MenuItem value="false">Inactive</MenuItem>
                  </TextField>
                </Stack>
                {/* <TextField
                fullWidth
                label="Education"
                required
                value={formInputs.education}
                onChange={handleChange}
                name="education"
              /> */}
                {/* <TextField
                fullWidth
                label="Address"
                required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
              /> */}

                <TextField
                  disabled
                  fullWidth
                  label="Reg type"
                  required
                  value={formInputs.reg_type}
                  onChange={handleChange}
                  name="reg_type"
                />

                {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                disabled
                fullWidth
                // type="number"
                label="Paid fee"
                value={formInputs.paid_fee}
                required
                onChange={handleChange}
                name="paid_fee"
              
              />

              <TextField
                disabled
                fullWidth
                label="Due fee"
                value={formInputs.due_fee}
                required
                onChange={handleChange}
                name="due_fee"
             
              />
            </Stack> */}

                {/* <div className="col-md-12 col-xs-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled
                  label="Date of Birth *"
                  format="YYYY-MM-DD"
                  value={formInputs?.date_of_birth}
                  onChange={handleDateChange}
                  maxDate={dayjs()} // Set the maximum date to today's date
                  // renderInput={(params) => <TextField {...params} />}
                  sx={{
                    width: "100%", // Set the width to 100% to take up the full available width
                  }}
                />
              </LocalizationProvider>
            </div> */}

                {/* <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack> */}
              </Stack>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
