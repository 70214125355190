import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { delStudent } from "src/DAL/student";
import { _delete_brand } from "src/DAL/brand";
import { _delete_asset_category } from "src/DAL/asset_category";
import { _delete_company_asset } from "src/DAL/company_asset";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
// ----------------------------------------------------------------------

export default function StudentMoreMenu({
  row,
  students,

  onStudentsUpdate,
  handleOpenEditDrawer,
  handleCloseEditDrawer,
  handleOpenEditDrawer2,
  handleCloseEditDrawer2,
}) {
  const [openDelete, setOpenDelete] = useState(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    //console.log(row);
    const result = await _delete_company_asset(row.id);
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students.id !== row.id
      );
      onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "company_asset",
          "edit"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/company-asset/edit-asset/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "company_asset",
          "delete"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "company_asset",
          "edit"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/company-asset/repair-details-asset/${row.id}`}
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          >
            <ListItemIcon>
              <Iconify
                icon="pajamas:issue-type-maintenance"
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="Maintenance"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "company_asset",
          "edit"
        ) && row.asset_category.assignable_status === true 
        && row.asset_status !==4 && row.asset_status !==3 && row.asset_status !==2
        
         ? (
          // <MenuItem
          //   component={RouterLink}
          //   to={`/company-asset/assign-asset/${row.id}`}
          //   sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          // >
          //   <ListItemIcon>
          //     <Iconify icon="clarity:assign-user-line" width={24} height={24} />
          //   </ListItemIcon>
          //   <ListItemText
          //     primary="Assign Asset"
          //     primaryTypographyProps={{ variant: "body2" }}
          //   />
          // </MenuItem>

          <MenuItem
            key="assign-asset"
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={() => {
              handleOpenEditDrawer(row);
              setIsOpen(false);
            }}
          >
            <ListItemIcon>
              <Iconify icon="clarity:assign-user-line" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Assign History"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
          {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "company_asset",
          "edit"
        ) ? (
          // <MenuItem
          //   component={RouterLink}
          //   to={`/company-asset/assign-asset/${row.id}`}
          //   sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          // >
          //   <ListItemIcon>
          //     <Iconify icon="clarity:assign-user-line" width={24} height={24} />
          //   </ListItemIcon>
          //   <ListItemText
          //     primary="Assign Asset"
          //     primaryTypographyProps={{ variant: "body2" }}
          //   />
          // </MenuItem>

          <MenuItem
            key="update-status"
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={() => {
              handleOpenEditDrawer2(row);
              setIsOpen(false);
            }}
          >
            <ListItemIcon>
              <Iconify icon="ic:sharp-system-security-update" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Update Status"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
    </>
  );
}
