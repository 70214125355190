import { invokeApi } from "src/utils";



export const _list_bank = async (data) => {

    const requestObj = {
      path: `api/bank/list_bank`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
  
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _add_bank = async (data) => {
    const requestObj = {
      path: `api/bank/add_bank`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data, 
    };
    return invokeApi(requestObj);
  };

export const _delete_bank = async (id) => {
  const requestObj = {
    path: `api/bank/delete_bank/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _detail_bank = async (id) => {
  const requestObj = {
    path: `api/bank/detail_bank/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};


export const _edit_bank = async (id, data) => {
  const requestObj = {
    path: `api/bank/edit_bank/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

 
 
export const _active_bank_list = async () => {
  const requestObj = {
    path: `api/bank/active_bank_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
