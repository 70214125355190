import * as React from "react";
// import { PieChart } from "@mui/x-charts/PieChart";
import {
  PieChart,
  BarChart,
  SparkLineChart,
  LineChart,
  ChartContainer,
  LinePlot,
  MarkPlot,
  ChartsReferenceLine,
  ChartsXAxis,
  ChartsYAxis,
} from "@mui/x-charts";
import { Avatar, Box, Card, Stack, Typography } from "@mui/material";
import {
  formatDateTimeWithOutZoneForSupportTicket,
  getTimeDifference,
} from "src/utils/formatTime";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { s3baseUrl } from "src/config/config";
import SupportTicketMoreMenu from "src/pages/SupportTickets/List/components/SupportTicketMoreMenu";
import SupportTicketCommentMoreMenu from "src/pages/SupportTickets/List/components/SupportTicketCommentMoreMenu";
import { useAppContext } from "src/hooks";
export default function CustomCardForSupportTicket({
  row,
  fetchCommentsDetail,
}) {
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();
  console.log("get_user_profile ___get_user_profile", get_user_profile);
  return (
    <>
      <Card>
        <div className="row"> 
          <div className="col-6"> 
        <Box sx={{ display: "flex", alignItems: "center", padding: "10px" }}>
           
          <Avatar
            alt={row.user.name}
            src={s3baseUrl + row.user.profile_image}
          />
          <Box sx={{ marginLeft: "10px" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {row.user.name}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {getTimeDifference(row.createdAt)}
            </Typography>
          </Box>
          
        </Box>
        </div>
          <div className="col-6"> 
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {  get_user_profile.type === 0 ? (
            <SupportTicketCommentMoreMenu
              row={row}
              students={[]}
              onStudentsUpdate={() => {}}
              type={1}
              fetchCommentsDetail={fetchCommentsDetail}

            />
          ) :
          get_user_profile.type === 1 && row.user.type === "1" ? (
            <SupportTicketCommentMoreMenu
              row={row}
              students={[]}
              onStudentsUpdate={() => {}}
              type={1}
              fetchCommentsDetail={fetchCommentsDetail}

            />
          ):
          get_user_profile.type === 2 && row.user.type === "2" ? (
            <SupportTicketCommentMoreMenu
              row={row}
              students={[]}
              onStudentsUpdate={() => {}}
              type={1}
              fetchCommentsDetail={fetchCommentsDetail}

            />
          ):
          get_user_profile.type === 3 && row.user.type === "3" ? (
            <SupportTicketCommentMoreMenu
              row={row}
              students={[]}
              onStudentsUpdate={() => {}}
              type={1}
              fetchCommentsDetail={fetchCommentsDetail}

            />
          ):
          (
            ""
          )}
        </div>
          </div>
          </div>
        <Box
          sx={{
            marginLeft: "50px",
            marginRight: "40px",
            padding: "10px",
            paddingTop: "0px",
          }}
        >
          {/* <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Message:
          </Typography> */}
          <Typography variant="body2">
            {capitalizeFirstLetter(row.message)}
          </Typography>
          {row.image !== "" && (
            <Box sx={{ marginTop: "10px" }}>
              <a
                href={s3baseUrl + row.image}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="image"
                  src={s3baseUrl + row.image}
                  width="50"
                  height="50"
                  style={{
                    maxWidth: "100%",
                    borderRadius: "5px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    cursor: "pointer", // Optional: add pointer cursor to indicate clickability
                  }}
                />
              </a>
            </Box>
          )}
        </Box>
      </Card>
    </>
  );
}
