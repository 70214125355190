import { invokeApi } from "src/utils";

export const _list_assignment = async (data) => {
    const requestObj = {
      path: `api/assignment/list_assignment`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _filter_assignments = async (data) => {
    const requestObj = {
      path: `api/assignment/filter_assignments`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

export const _post_assignment_from_admin = async (data) => {
    const requestObj = {
      path: `api/assignment/post_assignment`, 
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_assignment_from_admin = async (id) => {
    const requestObj = {
      path: `api/assignment/delete_assignment/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _list_approved_submitted_unsubmitted_assignments = async (id) => {
    const requestObj = {
      path: `api/assignment/list_approved_submitted_unsubmitted_assignments/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  export const _assignment_students = async (id) => {
    const requestObj = {
      path: `api/assignment/assignment_students/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };


  export const _change_student_assignment_status = async ( data) => {
    const requestObj = {
      path: `api/assignment/change_student_assignment_status/`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _update_student_give_marks = async ( data) => {
    const requestObj = {
      path: `api/assignment/give_marks/`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _detail_assignment = async (id) => {
    const requestObj = {
      path: `api/assignment/detail_assignment/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _update_assignment = async (id, data) => {
    const requestObj = {
      path: `api/assignment/update_assignment/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
