import { invokeApi } from "src/utils";

export const _list_expense_category = async (data) => {
    const requestObj = {
      path: `api/expense_category/list_expense_category`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_expense_category = async (data) => {

    const requestObj = {
      path: `api/expense_category/add_expense_category`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_expense_category = async (id) => {
    const requestObj = {
      path: `api/expense_category/delete_expense_category/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _detail_expense_category = async (id) => {
    const requestObj = {
      path: `api/expense_category/detail_expense_category/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _update_expense_category = async (id, data) => {
    const requestObj = {
      path: `api/expense_category/update_expense_category/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _list_active_expense_category = async () => {
    const requestObj = {
      path: `api/expense_category/list_active_expense_category`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

// export const _add_leave_request_for_admin = async (data) => {
//     const requestObj = {
//       path: `api/leave_request/add_leave_request_for_admin`, 
//       method: "POST",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       postData: data,
//     };
//     return invokeApi(requestObj);
//   };

//   export const _change_leave_request_status = async (data) => {
//     const requestObj = {
//       path: `api/leave_request/change_leave_request_status`, 
//       method: "POST",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       postData: data,
//     };
//     return invokeApi(requestObj);
//   };

//   export const _delete_leave_request_for_admin = async (id) => {
//     const requestObj = {
//       path: `api/leave_request/delete_leave_request_for_admin/` + id,
//       method: "DELETE",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
  
//     return invokeApi(requestObj);
//   };

//   export const _detail_leave_request_for_admin = async (id) => {
//     const requestObj = {
//       // path: `api/collect_fee/detail_collect_fee/${id}`,
//       path: `api/leave_request/detail_leave_request_for_admin/${id}`,
//       method: "GET",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//     };
  
//     return invokeApi(requestObj);
//   };

//   export const _update_leave_request_for_admin = async (id, data) => {
//     const requestObj = {
//       path: `api/leave_request/update_leave_request_for_admin/` + id,
//       method: "PUT",
//       headers: {
//         "x-sh-auth": localStorage.getItem("token"),
//       },
//       postData: data,
//     };
//     return invokeApi(requestObj);
//   };