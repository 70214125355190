export function privilegesNameCheckAndUpperCase(str) {
  if (str && str !== null && str !== "") {
    if (str === "time_preference") {
      return "Time Preference";
    } else if (str === "fee_voucher") {
      return "Fee Voucher";
    } else if (str === "asset_category") {
      return "Assets Categories";
    } else if (str === "company_asset") {
      return "All Assets";
    } else if (str === "templet_configration") {
      return "Templet Configration";
    } else if (str === "leave_request") {
      return "Leave Request";
    } else if (str === "expense_category") {
      return "Expense Category";
    } else if (str === "support_ticket") {
      return "Support Ticket";
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  } else {
    return str;
  }
}

export function privilegesObjConvertToArray(allPrevillages) {
  let privilegesArray = Object.entries(allPrevillages).map(
    ([name, privileges]) => ({
      name,
      privileges: Object.entries(privileges).map(([action, value]) => ({
        action,
        value,
      })),
    })
  );
  return privilegesArray;
}

export function privilegesArrayConvertToObj(Previvlages) {
  let all_previllages_obj = {};
  if (Previvlages) {
    for (const privilege of Previvlages) {
      const { name, privileges } = privilege;
      all_previllages_obj[name] = {};
      for (const actionObj of privileges) {
        const { action, value } = actionObj;
        all_previllages_obj[name][action] = value;
      }
    }
    return all_previllages_obj;
  } else {
    return Previvlages;
  }
}

export function privilegesNavSectionNameCheck(
  str,
  get_user_profile,
  getUserPrivilegeAccess
) {
  if (
    str &&
    str !== null &&
    str !== "" &&
    get_user_profile &&
    getUserPrivilegeAccess
  ) {
    let get_user_privilege_access = {};

    // Check if it's an object
    if (typeof getUserPrivilegeAccess === "object") {
      // Check if it's an empty object
      if (Object.keys(getUserPrivilegeAccess).length === 0) {
        // It's an empty object, do something
        // console.log('It is an empty object');
        get_user_privilege_access = get_user_profile.privilege;
        // get_user_privilege_access = fetchUserRoles();
      } else {
        // It's not empty, do something else
        // console.log('It is not an empty object');
        get_user_privilege_access = getUserPrivilegeAccess;
      }
    } else {
      get_user_privilege_access = get_user_profile.privilege;
      // get_user_privilege_access = fetchUserRoles();
      // It's not an object
      // console.log('It is not an object');
    }

    // console.log("str   ____str", str);
    // console.log("get_user_profile   ____str", get_user_profile);
    // console.log(
    //   "get_user_privilege_access   ____str",
    //   get_user_privilege_access
    // );

    // For Admin
    if (get_user_profile.type == 0) {
      return true;
    } else if (str.title === "Dashboard") {
      return true;
    } else if (str.title === "Batches") {
      if (
        (get_user_profile.type == 3 &&
          get_user_privilege_access.batch.view === true) ||
        (get_user_profile.type == 2 &&
          get_user_privilege_access.batch.view === true)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Courses") {
      if (
        (get_user_profile.type == 3 &&
          get_user_privilege_access.course.view === true) ||
        (get_user_profile.type == 2 &&
          get_user_privilege_access.course.view === true)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Instructors") {
      if (
        (get_user_profile.type == 3 &&
          get_user_privilege_access.instructor.view === true) ||
        (get_user_profile.type == 2 &&
          get_user_privilege_access.instructor.view === true)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Enquiry Students") {
      if (
        (get_user_profile.type == 3 &&
          get_user_privilege_access.enquiry.view === true) ||
        (get_user_profile.type == 2 &&
          get_user_privilege_access.enquiry.view === true)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Attendance") {
      if (
        (get_user_profile.type == 3 &&
          (get_user_privilege_access.attendance.view === true ||
            get_user_privilege_access.attendance.calendar === true)) ||
        (get_user_profile.type == 2 &&
          (get_user_privilege_access.attendance.view === true ||
            get_user_privilege_access.attendance.calendar === true))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Events") {
      if (
        (get_user_profile.type == 3 &&
          get_user_privilege_access.event.view === true) ||
        (get_user_profile.type == 2 &&
          get_user_privilege_access.event.view === true)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Role") {
      if (get_user_profile.type == 0) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Managements") {
      if (get_user_profile.type == 0) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Manage Website") {
      if (get_user_profile.type == 0) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "") {
      if (get_user_profile.type == 0) {
        return false;
      } else {
        return false;
      }
    } else if (str.title === "Contact Us") {
      if (get_user_profile.type == 0) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Fee Vouchers") {
      if (
        (get_user_profile.type == 3 &&
          (get_user_privilege_access.fee_voucher.view === true ||
            get_user_privilege_access.fee_voucher.add === true)) ||
        (get_user_profile.type == 2 &&
          (get_user_privilege_access.fee_voucher.view === true ||
            get_user_privilege_access.fee_voucher.add === true))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Assets") {
      if (
        (get_user_profile.type == 3 &&
          (get_user_privilege_access.asset_category.view === true ||
            get_user_privilege_access.brand.view === true ||
            get_user_privilege_access.vendor.view === true ||
            get_user_privilege_access.company_asset.view === true)) ||
        (get_user_profile.type == 2 &&
          (get_user_privilege_access.asset_category.view === true ||
            get_user_privilege_access.brand.view === true ||
            get_user_privilege_access.vendor.view === true ||
            get_user_privilege_access.company_asset.view === true))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Students") {
      if (
        (get_user_profile.type == 3 &&
          (get_user_privilege_access.student.view === true ||
            get_user_privilege_access.time_preference.view === true)) ||
        (get_user_profile.type == 2 &&
          (get_user_privilege_access.student.view === true ||
            get_user_privilege_access.time_preference.view === true))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Expenses") {
      if (
        (get_user_profile.type == 3 &&
          (get_user_privilege_access.expense_category.view === true ||
            get_user_privilege_access.expense.view === true)) ||
        (get_user_profile.type == 2 &&
          (get_user_privilege_access.expense_category.view === true ||
            get_user_privilege_access.expense.view === true))
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Support Tickets") {
      if (get_user_privilege_access?.support_ticket) {
        if (
          (get_user_profile.type == 3 &&
            (get_user_privilege_access.support_ticket.view === true ||
              get_user_privilege_access.support_ticket.view === true)) ||
          (get_user_profile.type == 2 &&
            (get_user_privilege_access.support_ticket.view === true ||
              get_user_privilege_access.support_ticket.view === true))
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (str.title === "Announcements") {
      if (get_user_privilege_access?.announcement) {
        if (
          (get_user_profile.type == 3 &&
            (get_user_privilege_access.announcement.view === true ||
              get_user_privilege_access.announcement.view === true)) ||
          (get_user_profile.type == 2 &&
            (get_user_privilege_access.announcement.view === true ||
              get_user_privilege_access.announcement.view === true))
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (str.title === "Leave Requests") {
      if (
        (get_user_profile.type == 3 &&
          get_user_privilege_access.leave_request.view === true) ||
        (get_user_profile.type == 2 &&
          get_user_privilege_access.leave_request.view === true)
      ) {
        return true;
      } else {
        return false;
      }
    } else if (str.title === "Assignments") {
      if (
        (get_user_profile.type == 3 &&
          get_user_privilege_access.assignment.view === true) ||
        (get_user_profile.type == 2 &&
          get_user_privilege_access.assignment.view === true)
      ) {
        return true;
      } else {
        return false;
      }
    }

    // Instructors
    // Enquiry Students
    // Fee Vouchers
    // Attendance
    // Events
    // Role
    // Managements
    // Manage Website
    // Contact Us
    // Assignments
    // Leave Requests
    // support_ticket
    //   title: "Expenses",
    //   icon: getIcon("arcticons:expense-register"),
    //   children: [
    //     {
    //       title: "Expense Category",
    //       path: "/expense-category",
    //     },
    //     {
    //       title: "Expenses",
    //       path: "/expenses",
    //     },
    //   ],
    // },

    // return true;
  } else {
    return false;
  }
}

export function privilegesNavSectionNameCheckAsChildOptions(
  str,
  get_user_profile,
  getUserPrivilegeAccess
) {
  if (
    str &&
    str !== null &&
    str !== "" &&
    get_user_profile &&
    getUserPrivilegeAccess
  ) {
    // console.log("str   ____str", str);

    let get_user_privilege_access = {};

    // Check if it's an object
    if (typeof getUserPrivilegeAccess === "object") {
      // Check if it's an empty object
      if (Object.keys(getUserPrivilegeAccess).length === 0) {
        // It's an empty object, do something
        // console.log('It is an empty object');
        get_user_privilege_access = get_user_profile.privilege;
        // get_user_privilege_access = fetchUserRoles();
      } else {
        // It's not empty, do something else
        // console.log('It is not an empty object');
        get_user_privilege_access = getUserPrivilegeAccess;
      }
    } else {
      get_user_privilege_access = get_user_profile.privilege;
      // get_user_privilege_access = fetchUserRoles();
      // It's not an object
      // console.log('It is not an object');
    }

    // For Admin
    if (get_user_profile.type == 0) {
      return true;
    }

    console.log("get_user_privilege_access   _____", get_user_privilege_access);

    if (str.title === "Students") {
      if (get_user_privilege_access?.student) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.student.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.student.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Time Preference") {
      if (get_user_privilege_access?.time_preference) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.time_preference.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.time_preference.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Assets Categories") {
      if (get_user_privilege_access?.asset_category) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.asset_category.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.asset_category.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Brand") {
      if (get_user_privilege_access?.brand) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.brand.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.brand.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Vendor") {
      if (get_user_privilege_access?.vendor) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.vendor.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.vendor.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "All Assets") {
      if (get_user_privilege_access?.company_asset) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.company_asset.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.company_asset.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Vouchers") {
      if (get_user_privilege_access?.fee_voucher) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.fee_voucher.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.fee_voucher.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (str.title === "Generate Voucher") {
      if (get_user_privilege_access?.fee_voucher) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.fee_voucher.add === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.fee_voucher.add === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (str.title === "Custom Voucher") {
      if (get_user_privilege_access?.fee_voucher) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.fee_voucher.add === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.fee_voucher.add === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Expense Category") {
      if (get_user_privilege_access?.expense_category) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.expense_category.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.expense_category.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Expenses") {
      if (get_user_privilege_access?.expense) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.expense.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.expense.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Attendance") {
      if (get_user_privilege_access?.attendance) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.attendance.view === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.attendance.view === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    if (str.title === "Calendar") {
      if (get_user_privilege_access?.attendance) {
        if (
          (get_user_profile.type == 3 &&
            get_user_privilege_access.attendance.calendar === true) ||
          (get_user_profile.type == 2 &&
            get_user_privilege_access.attendance.calendar === true)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  } else {
    return false;
  }
}

export function privilegesTypeCheckAndShowOrNot(
  get_user_profile,
  get_user_privilege_access,
  title,
  type
) {
  const privilegeMapping = {
    batch: "batch",
    course: "course",
    instructor: "instructor",
    student: "student",
    time_preference: "time_preference",
    enquiry: "enquiry",
    fee_voucher: "fee_voucher",
    attendance: "attendance",
    asset_category: "asset_category",
    brand: "brand",
    vendor: "vendor",
    company_asset: "company_asset",
    event: "event",
    assignment: "assignment",
    leave_request: "leave_request",
    expense_category: "expense_category",
    expense: "expense",
    support_ticket: "support_ticket",
    announcement: "announcement",
    dashboard: "dashboard",
    // Add more mappings as needed
  };

  if (type === "notAdmin") {
    if (get_user_profile.type === 0) {
      return false;
    } else {
      return true;
    }
  }

  if (get_user_profile.type === 0) {
    return true;
  }

  if (type === "admin") {
    return get_user_profile.type === 0;
  }

  const accessKey = privilegeMapping[title];

  if (
    get_user_profile.type === 3 &&
    get_user_privilege_access[accessKey]?.[type] === true
  ) {
    return true;
  } else if (
    get_user_profile.type === 2 &&
    get_user_privilege_access[accessKey]?.[type] === true
  ) {
    return true;
  }

  return false;
}

export function getStaticPrivilegesObj() {
  let privilegesArray = {
    instructor: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    course: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    batch: {
      add: true,
      edit: true,
      delete: true,
      view: true,
      revenue: true,
      update_status: true,
      move_students: true,
      other_options: true,
    },
    time_preference: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    enquiry: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    student: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    fee_voucher: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    attendance: {
      add: true,
      edit: true,
      delete: true,
      view: true,
      calendar: true,
    },
    asset_category: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    brand: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    vendor: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    company_asset: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    event: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    templet_configration: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    page: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    faq: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    testimonial: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    assignment: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    leave_request: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    expense_category: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    expense: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    support_ticket: {
      add: true,
      detail: true,
      delete: true,
      view: true,
    },
    announcement: {
      add: true,
      edit: true,
      delete: true,
      view: true,
    },
    dashboard: {
      expense_graph: true,
      expense_cards: true,
      other_cards: true,
      view: true,
      attendance_graph: true,
      today_assignments: true,
      today_leave_requests: true,
    },
  };
  return privilegesArray;
}
