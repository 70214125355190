import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// material
import {
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailCourse, editCourse } from "src/DAL/course";
import { addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";

import { listActiveInstructor } from "src/DAL/instructor";
import { capitalizeFirstLetter } from "src/utils/formatText";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [instructors, setInstructors] = useState([]);
  const [image, setImage] = useState();
  const [file, setFile] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [previewUrl1, setPreviewUrl1] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [material, setMaterail] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    title: "",
    short_title: "",
    status: "",
    description: "",
    duration: "",
    fee: "",
    instructors: [],
    outline: "",
    material: "",
    image: "",
    order: "",
    lectures: "",
    level: "",
  });

  const handleDeleteMaterial = (index) => {
    // delMaterial(material[index]);
    const newFile = [...file];

    // Remove the image at the given index from the "newFile" array:
    newFile.splice(index, 1);

    // Update the "file" state with the new array:
    setFile(newFile);

    const newMaterail = [...material];
    newMaterail.splice(index, 1);
    setMaterail(newMaterail);
    if (newFile.length === 0) {
      setShow1(false);
    }
  };
  // const delMaterial = async (img) => {
  //   console.log(img, "imgimgigmg");
  //   const data = new FormData();
  //   data.append("path", img);
  //   const result = await delImage(data);
  //   if (result.code === 200) {
  //     console.log("Deleted");
  //     //handleLoading(index, true);

  //     // console.log(res,"--data")
  //   } else {
  //     console.log(`Error: ${result.status}`);
  //   }
  // };
  function getFileExtensionIcon(fileType) {
    // console.log(fileType, "typeeee");
    const fileExtension = fileType.split("/")[1];
    switch (fileExtension) {
      case "pdf":
        return <Iconify icon={"bxs:file-pdf"} />;
      case "doc":
      case "docx":
      case "msword":
        return <Iconify icon={"mdi:file-word"} />;
      case "ppt":
      case "pptx":
        return <Iconify icon={"simple-icons:microsoftpowerpoint"} />;
      case "xls":
      case "xlsx":
        return <Iconify icon={"ri:file-excel-2-fill"} />;
      default:
        return <Iconify icon={"material-symbols:file-copy-outline-rounded"} />;
    }
  }

  const handleMaterial = async (e) => {
    // console.log(Array.from(e.target.files));
    const images = Array.from(e.target.files);
    setFile(images);
    const previewUrls = [];

    for (let i = 0; i < images.length; i++) {
      const previewUrl = URL.createObjectURL(images[i]);
      previewUrls.push(previewUrl);
    }
    setPreviewUrl1(previewUrls);

    setShow1(true);
    const results = [];
    for (let i = 0; i < images.length; i++) {
      const result = await uploadeMaterial(images[i]);
      results.push(result);
    }

    setMaterail(results);
  };
  const uploadeMaterial = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      //handleLoading(index, true);

      // console.log(res,"--data")
      // console.log(result.path);

      return result.path;
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  function handleFileClick(e, link) {
    e.preventDefault();
    window.open(link, "_blank");
  }
  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);

    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    handleForm(image);
    setImage("");

    setShow(false);
  }
  const handleForm = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(formInputs.gender);
    const data = new FormData();
    data.append("title", formInputs.title);
    data.append("short_title", formInputs.short_title);
    data.append("description", formInputs.description);
    data.append("duration", formInputs.duration);
    data.append("fee", formInputs.fee);
    data.append("lectures", formInputs.lectures);
    data.append("level", formInputs.level);
    data.append(
      "instructor",
      JSON.stringify(
        Array.isArray(formInputs.instructors)
          ? formInputs.instructors
          : [formInputs.instructors]
      )
    );
    // data.append("instructors", JSON.stringify(formInputs.instructors));
    // data.append("outline", formInputs.outline);
    // data.append("material", JSON.stringify(material));
    data.append("image", image ? image : "");
    data.append("status", formInputs.status);
    data.append("order", formInputs.order);
    // console.log(data.get("image"), "FroData ImageImageImageImageImage");
    // console.log(image, "state ImageImageImageImageImage");
    const result = await editCourse(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/courses");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchDetailCourse() {
    setIsLoading(true);
    const response = await detailCourse(id);
    if (response.code === 200) {
      const instructorIds = response.course.instructor.map(
        (instructor) => instructor._id
      );
      console.log("instructorIds _instructorIds", instructorIds);
      setFormInputs({
        title: capitalizeFirstLetter(response.course.title),
        short_title: capitalizeFirstLetter(response.course.short_title),
        status: response.course.status,
        description: capitalizeFirstLetter(response.course.description),
        duration: response.course.duration,
        fee: response.course.fee,
        // outline: response.course.outline,
        status: response.course.status,
        // material: response.course.material,
        image: response.course.image,
        order: response.course.order,
        instructors: instructorIds,
        lectures: response.course.lectures,
        level: response.course.level,
      });
      if (response.course.image) {
        setImage(response.course.image);
        setShow(true);
      }
      // console.log(response.course.material);
      const arr = response.course.material;
      if (arr.length > 0) {
        setMaterail(arr);
        setFile(arr);
        setShow1(true);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  const fetchActiveInstructors = async () => {
    setIsLoading(true);
    const response = await listActiveInstructor();
    console.log(
      "response listActiveInstructor _listActiveInstructor",
      response
    );

    if (response.code === 200) {
      const instructors = response.instructors.map((instructor) => ({
        id: instructor._id,
        first_name: instructor.first_name,
        last_name: instructor.last_name,
        email: instructor.email,
        image: instructor.image,
        status: instructor.status,
      }));
      setInstructors(instructors);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailCourse();
    fetchActiveInstructors();
  }, []);

  useEffect(() => {
    console.log("formInputs __formInputs", formInputs);
  }, [formInputs]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Course">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Course </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
                <TextField
                  fullWidth
                  label="Short Title"
                  value={formInputs.short_title}
                  onChange={handleChange}
                  name="short_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>

              <TextField
                fullWidth
                type="description"
                label="Description"
                value={formInputs.description}
                onChange={handleChange}
                name="description"
                autoComplete="off"
                required
                multiline
                rows={2}
              />
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Over all Fee"
                  value={formInputs.fee}
                  onChange={handleChange}
                  name="fee"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[.,0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                ></TextField>

                <TextField
                  fullWidth
                  label="Duration"
                  value={formInputs.duration}
                  onChange={handleChange}
                  name="duration"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Months</InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (
                      event.target.selectionStart === 0 &&
                      event.key === "0"
                    ) {
                      event.preventDefault();
                    } else if (
                      !/^[1-9]\d*$/.test(event.target.value + event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Level"
                  select
                  required
                  value={formInputs.level}
                  onChange={handleChange}
                  name="level"
                >
                  <MenuItem value="beginner">Beginner</MenuItem>
                  <MenuItem value="intermediate">Intermediate</MenuItem>
                  <MenuItem value="advanced">Advanced</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Lectures"
                  value={formInputs.lectures}
                  onChange={handleChange}
                  name="lectures"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Lectures</InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (
                      event.target.selectionStart === 0 &&
                      event.key === "0"
                    ) {
                      event.preventDefault();
                    } else if (
                      !/^[1-9]\d*$/.test(event.target.value + event.key)
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Instructors"
                  select
                  value={formInputs.instructors}
                  onChange={handleChange}
                  name="instructors"
                  required
                  SelectProps={{ multiple: true }}
                >
                  {instructors.map((instructor) => (
                    <MenuItem key={instructor.id} value={instructor.id}>
                      {capitalizeFirstLetter(instructor.first_name)}{" "}
                      {capitalizeFirstLetter(instructor.last_name)}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              {/* <TextField
                fullWidth
                type="outline"
                label="Outline"
                value={formInputs.outline}
                onChange={handleChange}
                name="outline"
                autoComplete="off"
                multiline
                rows={2}
              /> */}
              <TextField
                fullWidth
                label="Order"
                value={formInputs.order}
                onChange={handleChange}
                name="order"
                // required
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {show === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={5}>
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFile}
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : // ""
              null}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>{" "}
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrl ? (
                      <img
                        alt="image"
                        src={previewUrl}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.image}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : // ""
              null}
              {show1 === false
                ? // <Stack direction={{ xs: "column", sm: "row" }} spacing={3.5}>
                  //   <label htmlFor="image" className="form-label">
                  //     Material:
                  //   </label>
                  //   <Input
                  //     type="file"
                  //     id="material"
                  //     name="material"
                  //     inputProps={{ multiple: true }}
                  //     onChange={handleMaterial}
                  //   />
                  // </Stack>
                  ""
                : null}
              {show1 === true
                ? // <Stack direction="row" spacing={3.5}>
                  //   <label htmlFor="image" className="form-label">
                  //     Material:
                  //   </label>
                  //   {file.map((img, index) => (
                  //     <Stack
                  //       key={index}
                  //       position="relative"
                  //       alignItems="center"
                  //       justifyContent="center"
                  //     >
                  //       {previewUrl1.length > 0 ? (
                  //         img.type.includes("image") ? (
                  //           <img
                  //             alt={`image-${index}`}
                  //             src={URL.createObjectURL(img)}
                  //             width="150"
                  //             style={{ maxWidth: "100%" }}
                  //           />
                  //         ) : (
                  //           <div style={{ padding: "30px" }}>
                  //             {getFileExtensionIcon(img.type)}
                  //             {img.name}
                  //           </div>
                  //         )
                  //       ) : (
                  //         <div style={{ padding: "30px" }}>
                  //           <a
                  //             onClick={(e) => handleFileClick(e, s3baseUrl + img)}
                  //             style={{ cursor: "pointer" }}
                  //           >
                  //             <Iconify
                  //               icon={
                  //                 "material-symbols:file-copy-outline-rounded"
                  //               }
                  //               width={30}
                  //               height={30}
                  //             />
                  //             <div>{img.name}</div>
                  //           </a>
                  //         </div>
                  //       )}
                  //       <IconButton
                  //         aria-label="delete"
                  //         onClick={() => handleDeleteMaterial(index)}
                  //         style={{ position: "absolute", top: 0, right: 0 }}
                  //       >
                  //         <ClearIcon />
                  //       </IconButton>
                  //     </Stack>
                  //   ))}
                  // </Stack>
                  ""
                : null}
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
