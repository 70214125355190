import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// material
import {
  Stack,
  TextField,
  InputAdornment,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailCourse, editCourse } from "src/DAL/course";
import { addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";

import { listActiveInstructor } from "src/DAL/instructor";
import { formatDateTimeWithOutZone } from "src/utils";
const Detail = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [instructors, setInstructors] = useState([]);
  const [image, setImage] = useState();
  const [file, setFile] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [previewUrl1, setPreviewUrl1] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [material, setMaterail] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    title: "",
    status: "",
    description: "",
    duration: "",
    fee: "",
    instructors: [],
    outline: "",
    material: "",
    image: "",
    order: "",
    lectures: "",
    level: "",
  });

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const handleDeleteMaterial = (index) => {
    // delMaterial(material[index]);
    const newFile = [...file];

    // Remove the image at the given index from the "newFile" array:
    newFile.splice(index, 1);

    // Update the "file" state with the new array:
    setFile(newFile);

    const newMaterail = [...material];
    newMaterail.splice(index, 1);
    setMaterail(newMaterail);
    if (newFile.length === 0) {
      setShow1(false);
    }
  };
  // const delMaterial = async (img) => {
  //   console.log(img, "imgimgigmg");
  //   const data = new FormData();
  //   data.append("path", img);
  //   const result = await delImage(data);
  //   if (result.code === 200) {
  //     console.log("Deleted");
  //     //handleLoading(index, true);

  //     // console.log(res,"--data")
  //   } else {
  //     console.log(`Error: ${result.status}`);
  //   }
  // };
  function getFileExtensionIcon(fileType) {
    // console.log(fileType, "typeeee");
    const fileExtension = fileType.split("/")[1];
    switch (fileExtension) {
      case "pdf":
        return <Iconify icon={"bxs:file-pdf"} />;
      case "doc":
      case "docx":
      case "msword":
        return <Iconify icon={"mdi:file-word"} />;
      case "ppt":
      case "pptx":
        return <Iconify icon={"simple-icons:microsoftpowerpoint"} />;
      case "xls":
      case "xlsx":
        return <Iconify icon={"ri:file-excel-2-fill"} />;
      default:
        return <Iconify icon={"material-symbols:file-copy-outline-rounded"} />;
    }
  }

  const handleMaterial = async (e) => {
    // console.log(Array.from(e.target.files));
    const images = Array.from(e.target.files);
    setFile(images);
    const previewUrls = [];

    for (let i = 0; i < images.length; i++) {
      const previewUrl = URL.createObjectURL(images[i]);
      previewUrls.push(previewUrl);
    }
    setPreviewUrl1(previewUrls);

    setShow1(true);
    const results = [];
    for (let i = 0; i < images.length; i++) {
      const result = await uploadeMaterial(images[i]);
      results.push(result);
    }

    setMaterail(results);
  };
  const uploadeMaterial = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      //handleLoading(index, true);

      // console.log(res,"--data")
      // console.log(result.path);

      return result.path;
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  function handleFileClick(e, link) {
    e.preventDefault();
    window.open(link, "_blank");
  }
  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);

    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    handleForm(image);
    setImage("");

    setShow(false);
  }
  const handleForm = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  async function fetchDetailCourse() {
    setIsLoading(true);
    const response = await detailCourse(id);
    console.log("response detailCourse _detailCourse", response);
    if (response.code === 200) {
      const instructorIds = response.course.instructor.map(
        (instructor) => instructor._id
      );
      // console.log(data.instructor.first_name);
      setFormInputs({
        title: response.course.title,
        status: response.course.status,
        description: response.course.description,
        duration: response.course.duration,
        fee: response.course.fee,
        // outline: response.course.outline,

        // material: response.course.material,
        image: response.course.image,
        order: response.course.order,
        instructors: response.course.instructor,
        lectures: response.course.lectures,
        level: response.course.level,

        course_slug: response.course.course_slug,
        createdAt: response.course.createdAt,
        updatedAt: response.course.updatedAt,
      });
      if (response.course.image) {
        setImage(response.course.image);
        setShow(true);
      }
      // console.log(response.course.material);
      const arr = response.course.material;
      if (arr.length > 0) {
        setMaterail(arr);
        setFile(arr);
        setShow1(true);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  useEffect(() => {
    fetchDetailCourse();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Detail Course">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Detail Course
          </Typography>
        </Grid>

        <div className="container col-10">
          {show === true ? (
            <Stack
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <label htmlFor="image" className="form-label">
                {/* Image: */}
              </label>
              <Box
                position="relative"
                display="inline-block"
                border="2px solid #ccc"
                p={1}
              >
                {previewUrl ? (
                  <img
                    alt="image"
                    src={previewUrl}
                    width="150"
                    style={{ maxWidth: "100%" }}
                  />
                ) : (
                  <img
                    alt="image"
                    src={s3baseUrl + formInputs.image}
                    width="150"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                {/* <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton> */}
              </Box>
            </Stack>
          ) : null}

          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: 600,
                paddingBottom: "30px",
                marginTop: "30px",
                marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    title
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.title}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Description
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.description}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Order
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.order}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Fee
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.fee}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Duration
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.duration}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Level
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.level}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.status ? "Active" : "Inactive"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Lectures
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.lectures}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Course Slug
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.course_slug}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.createdAt)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.updatedAt)}
                  </TableCell>
                </TableRow>

                {formInputs.instructors.map((instructor, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={myStyleHeader}>
                    ({index+1}) Instructor Name
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {instructor.name}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
};

export default Detail;
