import { invokeApi } from "src/utils";


export const _list_active_customers = async () => {
    const requestObj = {
        path: `api/customer/list_active_customers`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };


export const _get_customers= async (data) => {

    const requestObj = {
      path: `api/customer/get_customers`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _signup_customer = async (data) => {

    const requestObj = {
      path: `api/customer/signup_customer`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

    export const _edit_customer = async (id, data) => {
    const requestObj = {
      path: `api/customer/edit_customer/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  
  export const _update_customer = async (data) => {
    const requestObj = {
      path: `api/customer/update_customer`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
  export const _update_instructor = async (data) => {
    const requestObj = {
      path: `api/instructor/update_instructor`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _detail_customer_admin = async (id) => {
    const requestObj = {
      path: `api/customer/detail_customer_admin/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _detail_customer_without_token = async () => {
    const requestObj = {
      path: `api/customer/detail_customer`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _instructor_detail_with_token = async () => {
    const requestObj = {
      path: `api/instructor/instructor_detail`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _delete_customer = async (id) => {
    const requestObj = {
      path: `api/customer/delete_customer/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

 

 