import { invokeApi } from "src/utils";


export const _list_vendor = async (data) => {

    const requestObj = {
      path: `api/vendor/list_vendor`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
 
  export const _delete_vendor = async (id) => {
    const requestObj = {
      path: `api/vendor/delete_vendor/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  
  export const _add_vendor = async (data) => {

    const requestObj = {
      path: `api/vendor/add_vendor`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

    export const _edit_vendor = async (id, data) => {
    const requestObj = {
      path: `api/vendor/edit_vendor/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

 export const _detail_vendor = async (id) => {
    const requestObj = {
      path: `api/vendor/detail_vendor/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
 
  export const _list_active_vendor = async () => {
    const requestObj = {
      path: `api/vendor/list_active_vendor/`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };


 

  
