import PropTypes from "prop-types";
import { set, sub } from "date-fns";
import { noCase } from "change-case";
import { faker } from "@faker-js/faker";
import { useState, useRef, useEffect } from "react";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from "@mui/material";
import { styled, lighten } from "@mui/material/styles";
// utils
import { fToNow } from "src/utils/formatTime";
// components
import { Iconify, Scrollbar, MenuPopover } from "src/components/";
import notification_chat from "src/assets/media/notification_chat.svg";
import {
  _count_updated,
  _list_user_notifications,
  _mark_all_as_read,
  _read_notification,
  _update_notification_count,
} from "src/DAL/notifications";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

const IconButtonStyle = styled(IconButton)(({ theme, custom_prop }) => {
  // console.log(custom_prop);
  return {
    width: 40,
    height: 40,
    backgroundColor: lighten(theme.palette.primary.lighter, 0.3),
    "&:hover": {
      backgroundColor: theme.palette.primary.lighter,
    },
  };
});

// const NOTIFICATIONS = [
//   {
//     id: faker.datatype.uuid(),
//     title: 'Your order is placed',
//     description: 'waiting for shipping',
//     avatar: null,
//     type: 'order_placed',
//     createdAt: set(new Date(), { hours: 10, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: faker.name.findName(),
//     description: 'answered to your comment on the Minimal',
//     avatar: '/static/mock-images/avatars/avatar_2.jpg',
//     type: 'friend_interactive',
//     createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
//     isUnRead: true,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new message',
//     description: '5 unread messages',
//     avatar: null,
//     type: 'chat_message',
//     createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'You have new mail',
//     description: 'sent from Guido Padberg',
//     avatar: null,
//     type: 'mail',
//     createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
//   {
//     id: faker.datatype.uuid(),
//     title: 'Delivery processing',
//     description: 'Your order is being shipped',
//     avatar: null,
//     type: 'order_shipped',
//     createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
//     isUnRead: false,
//   },
// ];

export default function NotificationsPopover({
  notifications,
  setNotifications,
  countNotifications,
  setCountNotifications,
}) {
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  // const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const totalUnRead = notifications.filter(
    (item) => item.isUnRead === true
  ).length;

  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);



  const getCountUpdated = async () => {
 
    setIsLoading(false);
    const response = await _update_notification_count();
   console.log("_update_notification_count count  ____notifications _____count", response);
    if (response.code === 200) {
      setCountNotifications(response.count);
     
     

      // console.log("allNotifications  ____notifications", allNotifications);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
    // console.log("open");
    getCountUpdated();
  };


 

  const handleClose = () => {
    setOpen(null);
  };

  const getReadAllNotifications = async () => {
    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     isUnRead: false,
    //   }))
    // );
    setIsLoading(false);
    const response = await _mark_all_as_read();

    if (response.code === 200) {
      // getTimeDifference

      setNotifications(
        notifications.map((notification) => ({
          ...notification,
          isUnRead: false,
        }))
      );

      // console.log("allNotifications  ____notifications", allNotifications);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleMarkAllAsRead = () => {
    getReadAllNotifications();
  };

  console.log("notifications  ____notifications", notifications);

  return (
    <>
      <IconButtonStyle
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        custom_prop={"custom-value"}
        // sx={{ width: 40, height: 40, backgroundColor: '#c8facdc5' }}
      >
        <Badge badgeContent={countNotifications} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonStyle>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar
          sx={{
            height: {
              xs: 340,
              sm: 400,
              // sm: "auto"
            },
          }}
        >
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     New
            //   </ListSubheader>
            // }
          >
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                notifications={notifications}
                setNotifications={setNotifications}
                handleClose={handleClose}
              />
            ))}
          </List>
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth>View All</Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, notifications, setNotifications, handleClose }) {
  const { avatar, title } = renderContent(notification);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  
  const getReadNotification = async (id) => {
    const response = await _read_notification(id);

    if (response.code === 200) {
      // getTimeDifference

      const AllNewNotifications = notifications.map((_notification) => {
        if (_notification.id === notification.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return { ..._notification, isUnRead: false };
        } else {
          // For other objects, return them as they are
          return _notification;
        }
      });
      setNotifications(AllNewNotifications);
      // setNotifications(
      //   notifications.map((notification) => ({
      //     ...notification,
      //     isUnRead: false,
      //   }))
      // );

      // console.log("allNotifications  ____notifications", allNotifications);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleMarkAsRead = (_notifications) => {
    getReadNotification(_notifications.id);
    if (_notifications.type === 4) {
      navigate("/support-tickets");
    } 
    else if (_notifications.type === 1) {
      navigate("/assignments");
    } 
 
    else if (_notifications.type === 2) {
      if(_notifications.fee_voucher_id.voucher_status===2){
        navigate("/list-voucher?type=2");
      }else if(_notifications.fee_voucher_id.voucher_status===3){
        navigate("/list-voucher?type=3");
      }else if(_notifications.fee_voucher_id.voucher_status===4){
        navigate("/list-voucher?type=4");
      }else{
        navigate("/list-voucher");
      }
    } else if (_notifications.type === 3) {
      navigate("/announcements");
    }
    handleClose();
    console.log("notifications  ID ____notifications", _notifications);
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={() => handleMarkAsRead(notification)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );
  return {
    avatar: <img src={notification_chat} alt="notification-logo" />,
    title,
  };
  //---------------
  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_package.svg' />,
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_shipping.svg' />,
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_mail.svg' />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt={notification.title} src='/static/icons/ic_notification_chat.svg' />,
  //     title,
  //   };
  // }
  // return {
  //   avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
  //   title,
  // };
}
