import { filter } from "lodash";
import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import TemplateListHead from "./components/TemplateListHead";
import TemplateListToolbar from "./components/TemplateListToolbar";
import TemplateMoreMenu from "./components/TemplateMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listTestimonial } from "src/DAL/testimonial";
import { _website_page_slider } from "src/DAL/slider";
import { _page_tabs } from "src/DAL/tabs";
import { _list_course_rating } from "src/DAL/course_rating";
import { capitalizeFirstLetter } from "src/utils/formatText";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "percentage", label: "Percentage", alignRight: false },

  { id: "color", label: "Color", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ListCourseRating() {
  const { id } = useParams();
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  function handleTemplatesUpdate(updatedTemplates) {
    setTemplates(updatedTemplates);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = templates.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - templates.length) : 0;

  const filteredTemplates = applySortFilter(
    templates,
    getComparator(order, orderBy),
    filterName
  );

  const isTemplateNotFound = filteredTemplates.length === 0;

  const handleNav = () => {
    navigate("/course-rating/add-course-rating/"+id);
  };
  const handleNavBack = () => {
    navigate(-1);
  };
  const fetchTestimonilas = async () => {
    setIsLoading(true);
    const response = await _list_course_rating(id);
    console.log("response   _____response", response);
    if (response.code === 200) {
      const courseRating = response.course_rating.map((_course_rating) => ({
        ..._course_rating,
        id: _course_rating._id,
        title: _course_rating.title,
        status: _course_rating.status,
      }));

      setTemplates(courseRating);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimonilas();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Course Rating">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2.5}
          mt={2}
        >
          <Stack direction="row" spacing={2}>
            <IconButton onClick={handleNavBack}>
              <Iconify icon="ep:back" />
            </IconButton>
            <Typography variant="h4">Course Rating</Typography>
          </Stack>
          <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Course Rating
          </Button>
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
        >
          <TemplateListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TemplateListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={templates.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredTemplates
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, percentage, title, color, type, status } =
                        row;
                      const isItemSelected = selected.indexOf(title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                          </TableCell>

                          {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}
                          <TableCell align="left" style={myStyle}>
                            {capitalizeFirstLetter(title)}
                          </TableCell>

                          <TableCell align="left">{percentage}</TableCell>

                          <TableCell align="left">{color}</TableCell>

                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === false && "error") || "success"}
                            >
                              {status ? "Active" : "Inactive"}
                              {/* {/ {sentenceCase(status)} /} */}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <TemplateMoreMenu
                              row={row}
                              templates={templates}
                              onTemplatesUpdate={handleTemplatesUpdate}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isTemplateNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
