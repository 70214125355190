import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { _add_time_preference } from "src/DAL/timepreference";
import { renderTimeViewClock, TimePicker } from "@mui/x-date-pickers";

const Add = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [formInputs, setFormInputs] = useState({
    type: "",
    total_seats: 100,
    status: "true",
    courses: [],
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      courses: [value],
    }));

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("startTime", startTime);
    if (!startTime) {
      error = "Start Time is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("endTime", endTime);
    if (!endTime) {
      error = "End Time is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("startTime startTime startTime", startTime);
    console.log("endTime endTime endTime", endTime);

    // Validate the selected End Time format
    // Assuming endTime is a Moment object representing the selected end time
    const formattedStartTime = startTime.format("HH:mm");
    console.log("formattedStartTime", formattedStartTime);

    // Validate the selected End Time format
    if (!moment(formattedStartTime, "HH:mm", true).isValid()) {
      error = "Invalid Start Time Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
      // Handle the error, e.g., display an error message to the user
    }

    // Validate the selected End Time format
    // Assuming endTime is a Moment object representing the selected end time
    const formattedEndTime = endTime.format("HH:mm");
    console.log("formattedEndTime", formattedEndTime);

    // Validate the selected End Time format
    if (!moment(formattedEndTime, "HH:mm", true).isValid()) {
      error = "Invalid End Time Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
      // Handle the error, e.g., display an error message to the user
    }

    // const data = new FormData();
    // data.append("type", formInputs.type);

    // data.append("status", formInputs.status);

    // data.append("start_time_class", formattedStartTime);
    // data.append("end_time_class", formattedEndTime);

    // console.log(data.get("courses"));
    let postData = {
      type: formInputs.type, // unique
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      status: formInputs.status,
    };
    setIsLoading(true);
    const result = await _add_time_preference(postData);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/time-preference");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchActiveCourses = async () => {
    const response = await listActiveCourse();

    if (response.code === 200) {
      const courses = response.courses.map((course) => ({
        id: course._id,
        title: course.title,
        description: course.description,
        duration: course.duration,
        image: course.image,
        status: course.status,
      }));
      setCourses(courses);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    // fetchActiveCourses();
  }, []);
  // useEffect(() => {
  //   if (courses.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       courses: [courses[0].id],
  //     }));
  //   }
  // }, [courses]);

  return (
    <Page title="Add Time Preference">
    <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Time Preference </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Type (Unique) "
                  value={formInputs.type}
                  onChange={handleChange}
                  name="type"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

{/* <TextField
                  fullWidth
                  label="Type"
                  select
                  value={formInputs.type}
                  onChange={handleChange}
                  name="type"
                  // required
                >
                  <MenuItem value="Morning">Morning</MenuItem>
                  <MenuItem value="Afternoon">Afternoon</MenuItem>
                  <MenuItem value="Evening">Evening</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                  <MenuItem value="Midnight">Midnight</MenuItem>
                  <MenuItem value="Dawn">Dawn</MenuItem>
                </TextField> */}
       
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  // required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DemoContainer components={['MobileTimePicker', 'MobileTimePicker']}> */}

                  {/* <MobileTimePicker
                    label={"Start Time *"}
                    openTo="hours"
                    selected={startTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={endTime}
                    onChange={handleStartTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />  */}

<TimePicker
                   label={"Start Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    openTo="hours"
                    selected={startTime}
                    views={["hours", "minutes"]}
                    onChange={handleStartTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  <TimePicker
                    label={"End Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    selected={endTime}
                    views={["hours", "minutes"]}
                    onChange={handleEndTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  {/* <MobileTimePicker
                    label={"End Time *"}
                    openTo="hours"
                    selected={endTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={endTime}
                    onChange={handleEndTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%",
                      // marginTop: "20px",
                    }}
                  /> */}

                  {/* </DemoContainer> */}
                </LocalizationProvider>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
