import { filter } from "lodash";

import { useState, useEffect } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";

// mock

import TableHead from "@mui/material/TableHead";

import Paper from "@mui/material/Paper";

//
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import {
  _detail_collect_fee,
  _detail_transaction,
  _list_collect_fee,
} from "src/DAL/collect_fee";
import { detailBatch } from "src/DAL/batch";

// import BatchListHead from "./components/BatchListHead";
// import BatchListToolbar from "./components/BatchListToolbar";
// import BatchMoreMenu from "./components/BatchMoreMenu";
// import StudentMoreMenu from "./components/StudentMoreMenu";
import moment from "moment";
import { useSnackbar } from "notistack";
import { invoice_background, invoice_thank_you_img } from "src/assets";
import { Box } from "@mui/system";
import { _generate_invoice } from "src/DAL/voucher";

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function GenerateInvoice() {
  const myStyle = {
    textTransform: "capitalize",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "left", // Add text alignment to center
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [firstRowData, setFirstRowData] = useState([]);
  const [lastRowData, setLastRowData] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [totalPaidFee, setTotalPaidFee] = useState("0");
  const [dueFee, setDueFee] = useState("0");

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "",
    order: "",
    courses: [],
  });

  const { id } = useParams();
  console.log("_ID _ID _ID", id);
  let transaction_ID = id;
  const handleNav = () => {
    navigate(-1);
  };

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
    fetchDetailBatch();
  }

  const scaleX = 1; // Adjust the scaling factor as needed for the x-axis
  const scaleY = 0.6; // Adjust the scaling factor as needed for the y-axis

  const divStyle = {
    // backgroundImage: `url(${invoice_background})`,
    // backgroundSize: `${scaleX * 100}% ${scaleY * 100}%`, // Scale the image
    // backgroundRepeat: "no-repeat", // Set to not repeat
    // width: "100%",
    // height: "249px",
    // paddingTop: 150,
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedEndTime = endMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

    return formattedTime;
  }

  const fetchDetailTransaction = async (transactionID) => {
    setIsLoading(true);

    const response = await _generate_invoice(transactionID);
    console.log("response _generate_invoice", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      setTransaction(response.transaction);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }; 

  useEffect(() => {
    fetchDetailTransaction(transaction_ID);
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Detail Transaction">
      <Container maxWidth="xl">
        <div className="row"> 
        <div className="hide_when_print" style={{ padding: "0 0px" }}>
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          {/* <div
            style={{
              textAlign: "center",
              padding: "0 15px",
              paddingTop: "20px",
            }}
          >
            <Typography variant="h5">
              <span style={{ fontWeight: "bolder" }}>Transaction Details </span>{" "}
            
            </Typography>
          </div> */}
        </div>

        {/* <hr /> */}

        <div className="container-fluid  col_setting_and_100_at_print_screen ">
          <div style={divStyle}>
            {/* <div style={{ backgroundImage: `url(${invoice_background})`, backgroundSize: 'cover', width: '100%', height: '249px',paddingTop:360 }}> */}
            <Box>
              <img
                alt="Background"
                src={invoice_background}
                width="100%"
                // height={/* specify your desired height */}
              />
            </Box>
            <TableContainer component={Paper}>
              <div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={4.5}
                  // mt={2}
                  className="px-5"
                >
                  <div>
                    {" "}
                    {/* <h6>{formatDate(transaction.date)} </h6> */}
                  </div>

                  <div>
                    <h3>From:</h3>
                    {/* <h6>{transaction.student.name} </h6> */}
                    {/* <h6>{transaction.student.contact_number} </h6> */}
                    <h6>
                      {/* {transaction.student.gender === "male"
                        ? "Male"
                        : transaction.student.gender === "Male"
                        ? "Male"
                        : transaction.student.gender === "female"
                        ? "Female"
                        : transaction.student.gender === "Female"
                        ? "Female"
                        : "Male"}{" "} */}
                    </h6>

                    {/* <h6>{formatDate(transaction.date)} </h6> */}
                  </div>
                  {/* <h6 sx={{marginTop:200}}>{formatDate(transaction.date)} </h6> */}
                </Stack>
                {/* <hr /> */}
              </div>
              <Table
                //  sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Name</TableCell> */}
                    {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Father</TableCell> */}
                    {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Contact</TableCell> */}
                    {/* <TableCell sx={{ backgroundColor: '#012149', color:'#fff!important' }}>Gender</TableCell> */}
                    {/* <TableCell>CNIC</TableCell> */}
                    <TableCell
                      sx={{
                        backgroundColor: "#012149",
                        color: "#fff!important",
                      }}
                    >
                      Batch
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#012149",
                        color: "#fff!important",
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#012149",
                        color: "#fff!important",
                      }}
                    >
                      Month
                    </TableCell>
                    {/* <TableCell>Date</TableCell> */}
                    <TableCell
                      sx={{
                        backgroundColor: "#012149",
                        color: "#fff!important",
                      }}
                    >
                      Paid Fee
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#012149",
                        color: "#fff!important",
                      }}
                    >
                      Source
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#012149",
                        color: "#fff!important",
                      }}
                    >
                      Status
                    </TableCell>

                    {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {rows.map((row) => ( */}
                  <TableRow
                    //   key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">
                    {transaction.student.name}
                  </TableCell> */}
                    {/* <TableCell> {transaction.student.father_name}</TableCell>
                  <TableCell> {transaction.student.contact_number}</TableCell>
                  <TableCell> {transaction.student.gender}</TableCell> */}
                    {/* <TableCell> {transaction.student.cnic}</TableCell> */}
                    {/* <TableCell> {transaction.student.batch.name}</TableCell>
                    <TableCell> {transaction.type}</TableCell>
                    <TableCell> {transaction.month}</TableCell> */}
                    {/* <TableCell> {formatDate(transaction.date)}</TableCell> */}
                    {/* <TableCell> {transaction.paid_fee}</TableCell>
                    <TableCell>
                      {" "}
                      {transaction.bank
                        ? transaction.bank.name
                        : transaction.person
                        ? transaction.person.name
                        : "Wallet"} */}
                    {/* </TableCell> */}
                    <TableCell>
                      <Button
                        variant="outlined"
                        // color={
                        //   transaction.transaction_status === "paid"
                        //     ? "success"
                        //     : "warning"
                        // }
                      >
                        {" "}
                        {/* {transaction.transaction_status} */}
                      </Button>
                    </TableCell>

                    {/* <TableCell align="right"> {transaction.month}</TableCell> */}
                    {/* <TableCell align="right">0</TableCell> */}
                  </TableRow>
                  {/* ))} */}
                </TableBody>
              </Table>
            </TableContainer>
            <hr />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              // mt={2}
              className="px-5"
            >
              {/* <h6>{formatDate(transaction.date)} </h6> */}
              <div></div> 
              {/* <div> */}
              <Box>
                <img
                  alt="Thank you"
                  src={invoice_thank_you_img}
                  // width={/* specify your desired width */}
                  // height={/* specify your desired height */}
                />
              </Box>
              {/* <Avatar alt="Thank you" src={invoice_thank_you_img} /> */}
              {/* </div> */}
            </Stack>
          </div>
        </div>
        {/* <hr /> */}
        </div>
      </Container>
    </Page>
  );
}
