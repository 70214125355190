import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  InputAdornment,
  Chip,
  Divider,
  Box,
  FormHelperText,
  Icon,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "src/utils/formatText";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import { listStudent } from "src/DAL/student";
import {
  _list_active_expense_category,
  _list_expense_category,
} from "src/DAL/expense_category";
import { _add_expense } from "src/DAL/expense";
import { useTheme } from "@mui/material/styles";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
const Input = styled("input")({
  display: "none",
});

const FilterCompanyAsset = ({
  filterExpenseCategory,
  setFilterExpenseCategory,
  filterPaymentMethod,
  setFilterPaymentMethod,
  filterDateFrom,
  setFilterDateFrom,
  filterDateTo,
  setFilterDateTo,
  fetchStudents,
  SearchStudents,

  search,
  assignableStatus,
  activeStatus,
  assignedStatus,

  setSearch,
  setAssignableStatus,
  setActiveStatus,
  setAssignedStatus,

  searchText,
  setSearchText,
}) => {
  const theme = useTheme();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [vendor, setVendor] = useState([]);
  const [students, setStudents] = useState([]);
  const [expenseCategory, ExpenseCategory] = useState([]);
  // const [searchText, setSearchText] = useState("");
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: "" },
  ]);
  const [value, setValue] = useState("no"); // 'yes' or 'no'

  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");

  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);
  const [instructors, setInstructors] = useState([]);
  const [selected, setSelected] = useState([]);
  const [studentID, setStudentID] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: "true",
    registration_fee: 0,
    batch_discount: 0,

    title: "",
    amount: "",
    payment_method: "",
    attachment: "",
    notes: "",
    vendor_id: "",
    student_id: "",
    tax_type: "with_holding_tax",
    tax_amount: "",
    expense_category_id: "",
    link: "",
    other_link: "",
    search: "",
    assignable_status: "",
    asset_status: null,
    assigned_status: "",
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectChange = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setStudentID("");
      setSelected([]);
      // updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setStudentID(newValue.id);
      setSelected(newValue);
      // updateFormInputs(newValue);
    }
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  // Create the debounced version of Search outside the component
  const debouncedSearchStudents = debounce(fetchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearch(event.target.value);
    // debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };

  const handleReset = () => {
    setSearch("");
    setSearchText("");
    setAssignableStatus("");
    setActiveStatus(null);
    setAssignedStatus("");
    setFormInputs({
      ...formInputs,
      search: "",
      assignable_status: "",
      active_status: null,
      assigned_status: "",
    });

    fetchStudents();
    console.log("Reset");
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
    console.log("time", time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: "" }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    let taxType = formInputs.tax_type;
    let taxAmount = formInputs.tax_amount;
    if (value === "no") {
      taxType = "with_holding_tax";
      taxAmount = 0;
    }

    let PostData = {
      title: formInputs.title,
      amount: formInputs.amount,
      payment_method: formInputs.payment_method,
      date: formattedStartDate,
      attachment: pathOfUploadedFile,
      notes: formInputs.notes,
      vendor_id: formInputs.vendor_id,
      // student_id: formInputs.student_id,
      student_id: studentID,
      tax_type: taxType,
      tax_amount: taxAmount,
      expense_category_id: formInputs.expense_category_id,
      link: formInputs.link,
      other_link: formInputs.other_link,
      status: formInputs.status,
    };

    console.log("PostData ___PostData", PostData);
    setIsLoading(true);
    const result = await _add_expense(PostData);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/expenses");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchActiveVendor = async () => {
    setIsLoading(true);
    const response = await _list_active_vendor();

    // list_asset_category

    console.log("response _list_vendor", response);
    if (response.code === 200) {
      const students = response.list_vendor.map((student, index) => ({
        id: student._id,
        name: student.name,
        email: student.email,
        contact_number: student.contact_number,
        address: student.address,
        active_status: student.active_status,
        number: index + 1,
      }));
      setVendor(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveExpenseCategory = async () => {
    setIsLoading(true);
    const response = await _list_active_expense_category();

    console.log("response _list_expense_category", response);
    if (response.code === 200) {
      ExpenseCategory(response.expense_categories);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchDefaultStudents = async () => {
    setIsLoading(true);
    const response = await listStudent();

    console.log("response  _student_fee_listing _test", response);

    if (response.code === 200) {
      const students = response.students
        .map((student, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (student.status === 1) {
            return {
              ...student,
              id: student._id,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSearch(formInputs.search);
    setAssignableStatus(formInputs.assignable_status);
    setActiveStatus(formInputs.asset_status);
    setAssignedStatus(formInputs.assigned_status);

    setSearchText(formInputs.search);

    //   }, [startDate,endDate,formInputs]);
  }, [formInputs]);

  //   if (isLoading) {
  //     return <CircularLoader />;
  //   }

  return (
    <Page title="All Assets">
      <div className="mt-1">
        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack
            sx={{ mt: 1, mb: 2 }}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Search"
              value={formInputs.search}
              onChange={handleChange}
              name="search"
              //   required
            />

            <TextField
              fullWidth
              label="Assignable Status"
              select
              value={formInputs.assignable_status}
              onChange={handleChange}
              name="assignable_status"
              //   required
            >
              <MenuItem value="">Reset</MenuItem>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Asset Status"
              select
              value={formInputs.asset_status}
              onChange={handleChange}
              name="asset_status"
              //   required
            >
              <MenuItem value={null}>Reset</MenuItem>
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={2}>Inactive</MenuItem>
              <MenuItem value={3}>Maintenance</MenuItem>
              <MenuItem value={4}>Sold</MenuItem>
            </TextField>

            <TextField
              fullWidth
              label="Assigned Status"
              select
              value={formInputs.assigned_status}
              onChange={handleChange}
              name="assigned_status"
              //   required
            >
              <MenuItem value="">Reset</MenuItem>
              <MenuItem value="true">Assigned</MenuItem>
              <MenuItem value="false">Free</MenuItem>
            </TextField>
            {/* <CustomResetButton handleReset={handleReset} /> */}
            <Stack
              alignItems="center"
              justifyContent="end"
              sx={{ mt: 1, mb: 2 }}
              direction={{ xs: "row", sm: "row" }}
              spacing={2}
            >
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                // loading={isLoadingButton}
                sx={{ width: "80px" }}
                onClick={() => SearchStudents(formInputs.search)}
              >
                Filter
              </LoadingButton>
              <CustomResetButton handleReset={handleReset} />
            </Stack>
          </Stack>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            {/* <Stack spacing={2}>
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack> */}
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default FilterCompanyAsset;
