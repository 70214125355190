import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Grid,
  InputAdornment,
  Divider,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Iconify } from "src/components";
import { CircularLoader, Page } from "src/components";
import { detailBatch, editBatch } from "src/DAL/batch";
import { _course_instructors, listActiveCourse } from "src/DAL/course";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "src/utils/formatText";
import { listStudent } from "src/DAL/student";
import {
  _list_active_expense_category,
  _list_expense_category,
} from "src/DAL/expense_category";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import { _detail_expense, _update_expense } from "src/DAL/expense";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import { _list_active_customers } from "src/DAL/customer";

const EditExpense = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const [instructors, setInstructors] = useState([]);

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [vendor, setVendor] = useState([]);
  const [students, setStudents] = useState([]);
  const [expenseCategory, ExpenseCategory] = useState([]);
  const [value, setValue] = useState("no"); // 'yes' or 'no'
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "",
    order: "",
    courses: "",
    start_date_batch_select: "",
    end_date_batch_select: "",

    start_time_class: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),

    end_time_class: dayjs(new Date()),
    format_end_time: dayjs(dayjs(new Date())).format("HH:mm"),

    registration_fee: 0,
    batch_discount: 0,
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
    batch_status: 1,
 
    title: "",
    amount: "",
    payment_method: "by_hand",
    attachment: "",
    notes: "",
    vendor_id: "",
    student_id: "",
    tax_type: "",
    tax_amount: "",
    expense_category_id: "",
    link: "",
    other_link: "",
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [selected, setSelected] = useState([]);
  const [studentID, setStudentID] = useState("");

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };

  const handleSelectChange = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setStudentID("");
      setSelected([]);
      // updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setStudentID(newValue.id);
      setSelected(newValue);
      // updateFormInputs(newValue);
    }
  };

  // const handleTimeChange = (time) => {
  //   const formattedTime = dayjs(time).format("HH:mm");
  //   setFormInputs((values) => ({
  //     ...values,
  //     format_time: formattedTime,
  //     time: time,
  //   }));
  // };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const handleEndTimeChange = (time) => {
    console.log("time", time);
    const formattedTime = dayjs(time).format("HH:mm");
    console.log("time formattedTime", formattedTime);
    setInputs((values) => ({
      ...values,
      format_end_time: formattedTime,
      end_time: time,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      courses: value,
    }));

    fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_start_date_batch_select: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration: date }));
  // };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   const formattedDate = dayjs(date).format("MM/DD/YYYY");
  //   setFormInputs((values) => ({ ...values, start_date_batch_select: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };

  // const handleDateChange2 = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration2: date }));
  // };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  // const handleEndTimeChange = (time) => {
  //   setEndTime(time);
  // };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log("startDate", start_date_batch_select);
    // if (!start_date_batch_select) {
    //   error = "Date is required.";
    //   enqueueSnackbar(error, { variant: "error" });
    //   return;
    // }

    // // Validate the selected date format
    // const formattedStartDate = startDate.format("YYYY-MM-DD");
    // console.log("formattedStartDate", formattedStartDate);
    // if (!moment(start_date_batch_select , true).isValid()) {
    //   error = "Invalid Date Format.";
    //   enqueueSnackbar(error, { variant: "error" });
    //   return;
    // }

    let taxType = formInputs.tax_type;
    let taxAmount = formInputs.tax_amount;
    if (value === "no") {
      taxType = "with_holding_tax";
      taxAmount = 0;
    }

    let PostData = {
      title: formInputs.title,
      amount: formInputs.amount,
      payment_method: formInputs.payment_method,
      date: formInputs.start_date_batch_select,
      attachment: pathOfUploadedFile,
      notes: formInputs.notes,
      vendor_id: formInputs.vendor_id,
      // student_id: formInputs.student_id,
      // student_id: studentID, 
      // student_id: studentID !== null && studentID !== "" ? studentID : undefined,
      customer_id: studentID !== null && studentID !== "" ? studentID : undefined,
      tax_type: taxType,
      tax_amount: taxAmount,
      expense_category_id: formInputs.expense_category_id,
      link: formInputs.link,
      other_link: formInputs.other_link,
      status: formInputs.status,
    };

    setIsLoading(true);

    const result = await _update_expense(id, PostData);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/expenses");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await _detail_expense(id);
    console.log("response _detail_expense _detail_expense", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.expense.date) {
        defaultStartDate = new Date(response.expense.date);
        console.log("defaultStartDate", defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultStartDate", defaultStartDate);

      if (response.expense.tax_amount > 0) {
        setValue("yes");
      }

      setPathOfUploadedFile(response.expense.attachment);
 

      // setStudentID(response.expense.student?response.expense.student._id:null);
      // setSelected(response.expense.student?response.expense.student:null);
      setStudentID(response.expense.customer?response.expense.customer._id:null);
      // setSelected(response.expense.customer?response.expense.customer:null);
      setSelected(response.expense.customer_object?response.expense.customer_object:null);

      console.log("Test____Test  response.expense.customer",response.expense.customer);

      setFormInputs({
        ...formInputs,
        ...response.expense,

        // title: response.expense.title,
        // amount: "",
        // payment_method: "",
        // attachment: "",
        // notes: "",

        // tax_type: "",
        // tax_amount: "",
        // link: "",
        // other_link: "",

        // name: capitalizeFirstLetter(response.batch.name),
        // total_seats: response.batch.total_seats,
        // status: response.batch.status,

        // time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),
        // format_time: formattedTime,

        title: capitalizeFirstLetter(response.expense.title),
        vendor_id: response.expense.vendor._id,
        // student_id: response.expense.student._id,
        expense_category_id: response.expense.expense_category._id,

        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActiveVendor = async () => {
    setIsLoading(true);
    const response = await _list_active_vendor();

    // list_asset_category

    console.log("response _list_vendor", response);
    if (response.code === 200) {
      const students = response.list_vendor.map((student, index) => ({
        id: student._id,
        name: student.name,
        email: student.email,
        contact_number: student.contact_number,
        address: student.address,
        active_status: student.active_status,
        number: index + 1,
      }));
      setVendor(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveExpenseCategory = async () => {
    setIsLoading(true);
    const response = await _list_active_expense_category();

    console.log("response _list_expense_category", response);
    if (response.code === 200) {
      ExpenseCategory(response.expense_categories);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchDefaultStudents = async () => {
    setIsLoading(true);
    const response = await listStudent();

    console.log("response  _student_fee_listing _test", response);

    if (response.code === 200) {
      const students = response.students
        .map((student, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (student.status === 1) {
            return {
              ...student,
              id: student._id,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (instructors.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       instructors: [instructors[0]._id],
  //     }));
  //   }
  // }, [instructors]);
  const fetchActiveCustomers = async () => {
    setIsLoading(true);
    const response = await _list_active_customers();

    // list_asset_category

    console.log("response _list_active_customers", response);
    if (response.code === 200) {
      const customers = response.active_customers.map((customer, index) => ({
        ...customer,
        id: customer._id,
        _id: customer._id,
        number: index + 1,
      }));

      if (customers.length > 0) {
        // setSelected(customers[0]);
        console.log("Test____Test   setSelected customers[0]", customers[0]);

      }
    //   setSelected({
    //     "_id": "657936ed8c52387c9a5cd08a",
 
    //     "first_name": "Adeel",
    //     "last_name": "Nazeer",
     
       
        
 
       
    // });
      setStudents(customers);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };


  useEffect(() => {
    fetchDetailBatch();
  }, []);

  useEffect(() => {
    fetchActiveVendor();
    // fetchDefaultStudents();
    fetchActiveCustomers();
    fetchActiveExpenseCategory();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Expense">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            Edit Expense{" "}
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Divider sx={{ mt: 4, mb: 4 }}> Expense Details </Divider>

          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row ">
              <div className="col-12 col-lg-4 mb-4">
                <TextField
                  fullWidth
                  label="Expense Category"
                  select
                  value={formInputs.expense_category_id}
                  onChange={handleChange}
                  name="expense_category_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {expenseCategory.map((expense_category) => (
                    <MenuItem
                      key={expense_category._id}
                      value={expense_category._id}
                    >
                      {capitalizeFirstLetter(expense_category.title)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-12 col-lg-4 mb-4">
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </div>
              <div className="col-12 col-lg-4 mb-4">
                {" "}
                <TextField
                  fullWidth
                  label="Amount"
                  value={formInputs.amount}
                  onChange={handleChange}
                  name="amount"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}

                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-4 mb-4">
                <TextField
                  fullWidth
                  label="Payment Method"
                  select
                  value={formInputs.payment_method}
                  onChange={handleChange}
                  name="payment_method"
                  required
                >
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="petty_cash">Petty Cash</MenuItem>
                  <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
                  <MenuItem value="online_payment">Online Payment</MenuItem>
                  <MenuItem value="cheque_payment">Cheque Payment</MenuItem>
                </TextField>
              </div>
              <div className="col-12 col-lg-4 mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date *"
                    // format="YYYY-MM-DD"
                    // format="YYYY-MM-DD"
                    // value={formInputs?.start_date_batch}
                    value={dayjs(formInputs.start_date_batch)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12 col-lg-4"></div>
            </div>

            <div className=" mb-4">
              <p>Do You want to apply Tax for this Expense ?</p>
              <div
                className="btn-group button_tax"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  autoComplete="off"
                  checked={value === "yes"}
                  onChange={() =>
                    handleChangeValue({ target: { value: "yes" } })
                  }
                />
                <label
                  className={`btn btn-outline-primary ${
                    value === "yes" ? "active" : ""
                  }`}
                  htmlFor="btnradio1"
                >
                  Yes
                </label>

                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  autoComplete="off"
                  checked={value === "no"}
                  onChange={() =>
                    handleChangeValue({ target: { value: "no" } })
                  }
                />
                <label
                  className={`btn btn-outline-primary ${
                    value === "no" ? "active" : ""
                  }`}
                  htmlFor="btnradio2"
                >
                  No
                </label>
              </div>
            </div>

            {value === "yes" ? (
              <div className="row">
                <div className="col-12 col-lg-6 mb-4">
                  <TextField
                    fullWidth
                    label="Tax Amount"
                    value={formInputs.tax_amount}
                    onChange={handleChange}
                    name="tax_amount"
                    required
                    // inputProps={{
                    //   pattern: "[A-Za-z]{3,}",
                    //   title:
                    //     "Please enter at least 3 letters with only alphabetical characters.",
                    // }}

                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      // maxLength: 10, // Limit the input to 10 characters
                      // title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-4">
                  <TextField
                    fullWidth
                    label="Tax Type"
                    select
                    value={formInputs.tax_type}
                    onChange={handleChange}
                    name="tax_type"
                    required
                  >
                    <MenuItem value="with_holding_tax">
                      With-Holding Tax
                    </MenuItem>
                    <MenuItem value="income_tax">Income Tax</MenuItem>
                    <MenuItem value="property_tax">Property Tax</MenuItem>
                    <MenuItem value="sale_tax">Sale Tax</MenuItem>
                    <MenuItem value="good_and_services_tax">
                      Good and Services Tax (GST)
                    </MenuItem>
                  </TextField>
                </div>
              </div>
            ) : (
              ""
            )}

            <Divider sx={{ mb: 4 }}> Additional Details </Divider>

            <div className="row ">
              <div className="col-12 col-lg-4 mb-4">
                <TextField
                  fullWidth
                  label="Vendor"
                  select
                  value={formInputs.vendor_id}
                  onChange={handleChange}
                  name="vendor_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {vendor.map((vendorObj) => (
                    <MenuItem key={vendorObj.id} value={vendorObj.id}>
                      {capitalizeFirstLetter(vendorObj.name)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-12 col-lg-4 mb-4">
              <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={students}
                  getOptionLabel={(student) =>
                    // student.name ? capitalizeFirstLetter(student.name) : ""
                    student.first_name ? capitalizeFirstLetter(student.first_name)+" "+capitalizeFirstLetter(student.last_name) : ""
                  }
                  value={selected}
                  onChange={handleSelectChange}
                  // filterOptions={filterOptions} // Apply the custom filter function
                  renderInput={(params) => (
                    <TextField {...params} label="Management" />
                  )}
                  renderOption={(props, student) => (
                    // <li {...props}>{capitalizeFirstLetter(student.name)}</li>
                    <li {...props}>{capitalizeFirstLetter(student.first_name)} {" "}{capitalizeFirstLetter(student.last_name)}</li>
                  )}
                />
                {/* <TextField
                  fullWidth
                  label="Student"
                  select
                  value={formInputs.student_id}
                  onChange={handleChange}
                  name="student_id"
                  // SelectProps={{ multiple: true }}
                  // style={myStyle}
                  required
                  // disabled={isLoadingStudent}
                >
                  {students.map((batchestudent) => (
                    <MenuItem key={batchestudent._id} value={batchestudent._id}>
                      {capitalizeFirstLetter(batchestudent.name)}
                    </MenuItem>
                  ))}
                </TextField> */}
              </div>
              <div className="col-12 col-lg-4 mb-4">
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  // required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </div>
            </div>

            <div className="col-12 mb-4">
              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />
            </div>

            <div className="row ">
              <div className="col-12 col-lg-6 mb-4">
                <TextField
                  fullWidth
                  label="Link"
                  value={formInputs.link}
                  onChange={handleChange}
                  name="link"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </div>
              <div className="col-12 col-lg-6 mb-4">
                <TextField
                  fullWidth
                  label="Other Link"
                  value={formInputs.other_link}
                  onChange={handleChange}
                  name="other_link"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </div>
            </div>

            <div className="row ">
              <div className="col-12 mb-4">
                <TextField
                  fullWidth
                  label="Description"
                  multiline
                  rows={6} // Specify the number of rows you want
                  value={formInputs.notes}
                  onChange={handleChange}
                  name="notes"
                />
              </div>
            </div>

            <Stack spacing={2}>
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default EditExpense;
