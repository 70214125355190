import React from "react";
import { styled } from "@mui/material/styles";
import "../../assets/css/style.css";
import { Card, Typography, Grid, Badge, Chip } from "@mui/material";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  compareDateWithTodayDate,
  formatDateWithDay,
} from "src/utils/formatTime";
import Label from "../Label";
import Iconify from "../Iconify";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(0, 0),
  borderRadius: "15px",
  padding: "1px 1px",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  marginTop: "0px",
  display: "flex",
  borderRadius: "25%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
//   marginBottom: theme.spacing(3),
  //   backgroundColor: "#FFFFFF",
  backgroundColor: "#f2f3f5",
//   marginTop: "-3px",
}));

export default function CustomAmountCard({
  Title,
  Subtitle,
  className,
  Icon,
  colorType,
}) {
  return (
    <>
      <div className={`col-12 md-6 ${className} mt-2 mb-3`}>
        <div className="row"> 
        <div className="col-2"></div>
        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="views-lesson">
            <div className="batch_info_heading">
              {capitalizeFirstLetter(Title)}
            </div>
            <div className="batch_info_sub_text">
              {capitalizeFirstLetter(Subtitle)}
            </div>
          </div>
        
        </div>

        <div className="col-4">
          <IconWrapperStyle
            Color={
              colorType == 1
                ? "#ffc107" // yellow
                : colorType == 2 || colorType == 4
                ? "Green"
                : "#1890ff" //blue
            }
          >
            <Iconify icon={Icon} width={24} height={24} />
          </IconWrapperStyle>
        </div>
        </div>
      </div>
    </>
  );
}
