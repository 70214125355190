import React from "react";
import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { _update_freeze_status, delBatch } from "src/DAL/batch";
import Modal from "@mui/material/Modal";
import BatchUpdateStatus from "../../BatchUpdateStatus/BatchUpdateStatus";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import BatchAllDetail from "../BatchAllDetail";
// ----------------------------------------------------------------------
const style = {
  width: "70%",
  minWidth: "70%",
  maxHeight: "80vh", // Set a maximum height for the modal
  overflowY: "auto", // Enable vertical scrolling
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 1,
};
export default function BatchMoreMenu({ row, batches, onBatchesUpdate }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [openFreeze, setOpenFreeze] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => {
    setIsOpen(false);
    setOpenModal(true);
  };

  const handleCloseDetailModal = () => setOpenDetailModal(false);
  const handleOpenDetailModal = () => {
    setIsOpen(false);
    setOpenDetailModal(true);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleFreezeClick = () => {
    setIsOpen(false);
    setOpenFreeze(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };

  const handleFreezeOk = () => {
    handleClickOnFreeze(row);
    setOpenFreeze(false); // Call the onDelete function when the user confirms the delete action
  };

  async function handleClickOnFreeze(row) {
    const result = await _update_freeze_status(row.id);
    if (result.code === 200) {
      const updatedBatches = batches.filter((batch) => batch.id !== row.id);
      onBatchesUpdate(updatedBatches);
      enqueueSnackbar(`Update Successfully!`, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  async function handleClick(row) {
    const result = await delBatch(row.id);
    if (result.code === 200) {
      const updatedBatches = batches.filter((batch) => batch.id !== row.id);
      onBatchesUpdate(updatedBatches);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "edit"
        ) ? (
          <MenuItem
            key="edit"
            component={RouterLink}
            to={`/batches/edit-batch/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "delete"
        ) ? (
          <MenuItem
            key="delete"
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

{privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "update_status"
        ) ? (
          <MenuItem
              key="update-status"
              sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
              onClick={handleOpenModal}
            >
              <ListItemIcon>
                <Iconify
                  icon="ic:sharp-system-security-update"
                  vFlip={true}
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary="Update Status"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
        ) : (
          ""
        )}

{privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "move_students"
        ) ? (
          <MenuItem
              key="move-multi-students"
              component={RouterLink}
              to={`/batches/move-batch-students/${row.id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify
                  icon="ph:student"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary="Move Students"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
        ) : (
          ""
        )}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "batch",
          "other_options"
        ) ? (
          <>
             

            <MenuItem
              key="batch-detail"
              sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
              onClick={handleOpenDetailModal}
            >
              <ListItemIcon>
                <Iconify
                  icon="icon-park-outline:doc-detail"
                  vFlip={true}
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary="Batch Detail"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>

            <MenuItem
              key="students"
              component={RouterLink}
              to={`/batches/batch-students/${row.id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify
                  icon="fluent:people-team-16-regular"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary="Batch Students"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>

            <MenuItem
              key="students"
              component={RouterLink}
              to={`/attendance/calendar/${row.id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify
                  icon="material-symbols:assignment-outline"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              <ListItemText
                primary="Attendance"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </>
        ) : (
          ""
        )}

        {/* <MenuItem sx={{ color: "text.secondary" }} onClick={handleFreezeClick}>
          <ListItemIcon>
            <Iconify icon="mdi:freeze-advisory" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={type}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>

      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
      <DelPopup
        open={openFreeze}
        setOpen={setOpenFreeze}
        title={`Confirm Freeze`}
        message={`Are you sure you want to Freeze`}
        onOk={handleFreezeOk}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        TransitionComponent={Transition}
      > 
        <Box sx={style} className="custom-scrollbar">
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Update Status
              </Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseModal}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <BatchUpdateStatus
            row={row}
            Batches={batches}
            onBatchesUpdate={onBatchesUpdate}
            handleCloseModal={handleCloseModal}
          />
        </Box>
      </Modal>


      <CustomModal
        isOpenModal={openDetailModal}
        handleCloseModal={handleCloseDetailModal}
        pageTitle="Batch Detail"
        componentToPassDown={
          <BatchAllDetail studentID={row.id} />
        }
      />

    </>
  );
}
