import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  TextField,
  Accordion,
  Typography,
  IconButton,
  Card,
  FormControlLabel,
  Checkbox,
  Divider,
  FormGroup,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Avatar,
  Badge,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DateTimePicker } from "@mui/x-date-pickers";
import { _upload_attendance } from "src/DAL/attendance";
import { CircularLoader, Iconify } from "src/components";

const Input = styled("input")({
  display: "none",
});
function UploadAttendance() {
  const navigate = useNavigate();
  const moment = require("moment");
  const [image, setImage] = useState();
  const [previews, setPreviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allGames, setAllGames] = useState([]);
  const [allClubs, setAllClubs] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [acceptableFile, setAcceptableFile] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");

  const param = useParams();
  let { state } = useLocation();
  const _ID = param.id;

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    image: {},
  });

  const [oldImage, setOldImage] = useState("");

  const [inputs, setInputs] = useState({
    name: "",
    game_id: "",
    game: "",
    club_id: "",
    country: "Ireland",
    status: true,

    first_name: "",
    last_name: "",
    gender: "male",
    age: "",
    // dob: "",
    // height: "",
    // weight: "",
    // disability: "No",
    // skill: "",
  });

  const myStyleA = {
    textTransform: "capitalize",
    fontWeight: 600, // Add this line for bold font weight
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "center", // Add text alignment to center
  };

  const myStyle = {
    textTransform: "capitalize",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "center", // Add text alignment to center
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Month Day, Year"
    const formattedDate = parsedDate.format("MMMM DD, YYYY");

    return formattedDate;
  }

  const fileChangedHandler = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && selectedFile.size <= 1 * 1024 * 1024) {
      // If the file is 5MB or smaller
      setImage(selectedFile);
      setPreviews(URL.createObjectURL(selectedFile));
      setAcceptableFile("true");
      setMessage("Acceptable File");
      setColor("success");
    } else {
      // If the file is larger than 5MB
      setAcceptableFile("false");
      setMessage("Error: The file size exceeds the maximum allowed limit.");
      setColor("danger");
    }

    console.log("event", event);
    console.log("event.target.files[0]", selectedFile);
    console.log(
      "URL.createObjectURL(event.target.files[0])",
      URL.createObjectURL(selectedFile)
    );
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handle_format_data = (data) => {
    // const defaultDate = new Date(data.dob);
    console.log("handle_format_data", data);
    let clubID = "";
    let Gender = "male";
    if (data.club) {
      clubID = data.club._id;
    }

    if (data.gender === "Female" || data.gender === "female") {
      Gender = "female";
    }

    setInputs({
      ...data,
      game_id: data.game._id,
      club_id: clubID,
      gender: Gender,
    });

    setPreviews(data.image.src);
  };

  const get_detail_player = async () => {
    setIsLoading(true);
    const response = await _detail_student_assignment(_ID);

    console.log("response _detail_student_assignment", response);
    if (response.code === 200) {
      let AssignmentData = response.assignment;

      let newAssignmentData = {
        ...AssignmentData,
        title: AssignmentData.title,
        batch_name: AssignmentData.batch.name,
        // student: AssignmentData.student.name,
        student_name: AssignmentData.student.name,
        date: formatDate(AssignmentData.date),
        dead_line: formatDate(AssignmentData.dead_line),
        assignment_status: AssignmentData.assignment_status,
        total_marks: AssignmentData.total_marks,
        obtained_marks:
          AssignmentData.obtained_marks === null
            ? ""
            : assignment.obtained_marks,
      };

      console.log(
        "response newAssignmentData _detail_student_assignment",
        newAssignmentData
      );
      setAssignment(newAssignmentData);

      //   handle_format_data(newGameData);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inside");
    let error = "";
    const formData = new FormData();

    if (image && error === "") {
      console.log("inside2");
      // if (image.type.startsWith("image/")) {

      formData.append("file", image);

      setIsLoading(true);
      const response = await _upload_attendance(formData);

      console.log("response _upload_attendance test", response);
      if (response.code === 200) {
        // const response = await _upload_assignment(
        //   {
        //     assignment_url: result.path,
        //   },
        //   _ID
        // );
        // console.log("response _upload_assignment test", response);
        // if (response.code === 200) {
        //   enqueueSnackbar(response.message, { variant: "success" });
        //   navigate(-1);
        //   setIsLoading(false);
        // } else {
        //   enqueueSnackbar(response.message, { variant: "error" });
        //   setIsLoading(false);
        // }

        enqueueSnackbar(response.message, { variant: "success" });
        navigate(-1);
        setIsLoading(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // setIsLoading(true);
    // getAllClubs();
    // getAllgames();
  }, []);

  useEffect(() => {
    // if (!state) {
    //   get_detail_player();
    // } else {
    //   handle_format_data(state);
    //   window.history.replaceState({}, document.title);
    // }
    // get_detail_player();
  }, []);

  //===================================================================
  if (isLoading) {
    return <CircularLoader color="green" />;
  }

  return (
    <div className="container px-4">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={handleBack} sx={{ flex: 0 }}>
          <Iconify icon="ep:back" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h4 className="title_heading_in_all_pages_top_left">
          {" "}
          Upload Attendance{" "}
        </h4>
      </Grid>

      {/*     
      <div className="row">
        <div className="col-12">
          <IconButton onClick={handleBack} className="icon-button">
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
        <div className="col-12">
          <h3 className="my-3">Upload Attendance</h3>
        </div>
      </div> */}

      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mb: 2,
          width: "100%",
          p: 4,
        }}
      >
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="row table-form">
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <p className="">Attendance File *</p>
                  {/* <FormHelperText className="pt-0">
                   File Size(5 mb ) ("zip", "rar")
                  </FormHelperText> */}

                  <FormHelperText className="pt-0">
                    Acceptable File Size: 1MB or below Supported Formats: "csv",
                  </FormHelperText>
                </div>

                <div className={`col-5 mt-4`}>
                  {/* <img src={previews} height={50} /> */}
                  {acceptableFile === "true" ? (
                    <>
                      <Icon
                        icon={
                          "streamline:interface-validation-check-circle-checkmark-addition-circle-success-check-validation-add-form"
                        }
                        width={40}
                        height={40}
                        style={{ color: "green" }} // Set the text color to green
                      />
                      <span style={{ color: "green", paddingLeft: "5px" }}>
                        {message}
                      </span>
                    </>
                  ) : acceptableFile === "false" ? (
                    <>
                      <Icon
                        icon={"system-uicons:cross-circle"}
                        width={40}
                        height={40}
                        style={{ color: "red" }} // Set the text color to red
                      />
                      <span style={{ color: "red", paddingLeft: "5px" }}>
                        {message}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-3 text-end pt-2">
                  <label htmlFor="contained-button-file">
                    <Input
                      // accept="image/*"
                      //   accept=".rar,.zip"
                      accept=".csv"
                      id="contained-button-file"
                      multiple
                      type="file"
                      name="image"
                      onChange={fileChangedHandler}
                    />

                    <Button
                      id="contained-button-file"
                      className="small-contained-button"
                      color={
                        acceptableFile === "true"
                          ? "success"
                          : acceptableFile === "false"
                          ? "error"
                          : "info"
                      }
                      startIcon={<FileUploadIcon />}
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 text-end">
              {/* <Button
                disabled={acceptableFile === "true" ? false : true}
                id="contained-button-file"
                className="small-contained-button"
                component="span"
                type="submit"
              >
                Submit
              </Button> */}

              <button
                className={`btn btn-outline-primary ${
                  acceptableFile === "true" ? "" : "disabled"
                } `}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}

export default UploadAttendance;
