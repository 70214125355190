import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _detail_brand, _edit_brand } from "src/DAL/brand";
import {
  _detail_asset_category,
  _edit_asset_category,
} from "src/DAL/asset_category";
import { _detail_vendor, _edit_vendor } from "src/DAL/vendor";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";

const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    email: "",
    contact_number: "",
    address: "",
    image: "",
    active_status: true,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "contact_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);

    let postData = {
      name: formInputs.name,
      email: formInputs.email,
      contact_number: formInputs.contact_number,
      address: formInputs.address,
      image: pathOfUploadedFile,
      active_status: formInputs.active_status,
    };
    console.log("postData", postData);

    setIsLoading(true);
    const result = await _edit_vendor(id, postData);
    console.log("result _edit_vendor", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/vendor");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_vendor(id);
    console.log("_detail_vendor _detail_vendor", response);
    // enquiry_form

    
    if (response.code === 200) {
      setPathOfUploadedFile(response.vendor.image);
      setFormInputs({
        ...formInputs,
        ...response.vendor,
        name: capitalizeFirstLetter(response.vendor.name),
        address: capitalizeFirstLetter(response.vendor.address),
      });
 
      // setFormInputs(response.vendor);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Vendor">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Vendor</h4>
        </Grid>


        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Email"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                  fullWidth
                  label="Contact Number"
                  value={formInputs.contact_number}
                  onChange={handleChangePhone}
                  name="contact_number"
                  // type="number"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Address"
                  value={formInputs.address}
                  onChange={handleChange}
                  name="address"
                  required
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

               
              </Stack>

              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <TextField
                fullWidth
                label="Active Status"
                select
                // required
                value={formInputs.active_status}
                onChange={handleChange}
                name="active_status"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </TextField>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
