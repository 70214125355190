import { filter } from "lodash";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import BatchListHead from "./components/BatchListHead";
import BatchListToolbar from "./components/BatchListToolbar";
import BatchMoreMenu from "./components/BatchMoreMenu";
import moment from "moment";

//

import { _list_freeze_batches, listBatch } from "src/DAL/batch";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterEnquiryStudent from "src/pages/EnquiryStudents/List/components/FilterEnquiryStudent";
import FilterBatch from "./components/FilterBatch";
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "courses", label: "Courses", alignRight: false },
  { id: "student_count", label: "Student Count", alignRight: false },
  { id: "time", label: "Class Time", alignRight: false },
  { id: "start_date_batch", label: "Start Date", alignRight: false },
  { id: "end_date_batch", label: "End Date", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {

  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function FreezeBatch() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const myElementRef = useRef(null);
  function handleBatchesUpdate(updatedBatches) {
    setBatches(updatedBatches);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = batches.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(batches.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - batches.length) : 0;

  const filteredBatches = applySortFilter(
    batches,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredBatches.length === 0;

  const handleNav = () => {
    navigate("/batches/add-batch");
  };

  const handleDetailBatche = (row) => {
    navigate(`/batches/detail-batch/${row.id}`);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedEndTime = endMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

    return formattedTime;
  }


  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await _list_freeze_batches({
        "text":""
    });
    console.log('response _list_freeze_batches __test',response);
    if (response.code === 200) {
       

      const batches = response.batches.map((batch, index) => ({
        id: batch._id,
        name: batch.name,
        // available_seats: batch.available_seats,
        start_date_batch: formatDate(batch.start_date_batch),
        end_date_batch: formatDate(batch.end_date_batch),
        time: formatTime(batch.start_time_class, batch.end_time_class),

        available_seats: batch.available_seats <= 0 ? 0 : batch.available_seats,
        total_seats: batch.total_seats,
        total_male_students: batch.total_male_students,
        total_female_students: batch.total_female_students,

        student_count: batch.student_count,
        status: batch.status,
        course: batch.course ? batch.course.title : "",
        number: index + 1,
      }));
      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    } 
  };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleReset = () => {
    // setSearchText("");
    // SearchStudents("");
    fetchBatches();
    // Reset any search results or filters
    console.log("Reset");
  };

  useEffect(() => {
    fetchBatches();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Freeze Batches">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
          mt={2}
        >
          <Typography variant="h4">Freeze Batches</Typography>
          {/* <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Batch
          </Button> */}
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
        >
          <Scrollbar>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ padding: "10px" }}
            >
              <BatchListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />

              {/* <Button variant="contained" onClick={handleReset}>
                Reset
              </Button>

              <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
            </Stack>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <BatchListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={batches.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredBatches
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        name,
                        student_count,
                        course,
                        status,
                        available_seats,
                        total_seats,
                        start_date_batch,
                        end_date_batch,
                        time,
                        number,
                        total_male_students,
                        total_female_students,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {number}
                          </TableCell>

                          <Tooltip
                            title={
                              <div>
                                Available Seats = {available_seats} <br />
                                Total Seats = {total_seats} <br />
                                Total Male Students = {total_male_students}{" "}
                                <br />
                                Total Female Students = {
                                  total_female_students
                                }{" "}
                                <br />
                              </div>
                            }
                            arrow
                          >
                            <TableCell
                              pref={myElementRef}
                              align="left"
                              style={myStyle}
                            >
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={() => handleDetailBatche(row)}
                              >
                                {name}
                              </button>
                            </TableCell>
                          </Tooltip>
                          {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}

                          <TableCell align="left" style={myStyle}>
                            {/* {courses.length > 0
                              ? courses
                                  .map((course, index) => {
                                    return course !== null ? course.title : "";
                                  })
                                  .join(", ")
                              : ""} */}
                              {course}
                          </TableCell>

                          <TableCell align="left">{student_count}</TableCell>

                          <TableCell align="left" style={myStyle}>
                            {time}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {start_date_batch}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {end_date_batch}
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === false && "error") || "success"}
                            >
                              {status ? "Active" : "Inactive"}
                              {/* {/ {sentenceCase(status)} /} */}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <BatchMoreMenu
                              row={row}
                              batches={batches}
                              onBatchesUpdate={handleBatchesUpdate}
                              type="Un-freeze"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[
              50,
              100,
              150,
              { value: batches.length, label: "All" },
            ]}
            component="div"
            count={batches.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterBatch
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            batches={batches}
            setBatches={setBatches}
          />
        }
      />
    </Page>
  );
}
