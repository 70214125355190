import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarTableHeader from "./components/CalendarTableHeader";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularLoader, Iconify, Page } from "src/components";
import { _get_attendance } from "src/DAL/attendance";
import DataNotFound from "src/components/GeneralComponents/DataNotFound";
import { Grid, IconButton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { capitalizeFirstLetter } from "src/utils/formatText";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSnackbar } from "notistack";
import moment from "moment";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";

const get_view_dates = () => {
   
        let newDate = new Date();
        console.log('newDate __current',newDate);
        let new_object = {
            start_date: moment(newDate)
              .add(0, "month")
              .startOf("month")
              .toDate(),
            end_date: moment(newDate)
              .add(0, "month")
              .endOf("month")
              .toDate(),
          };
    // }

     return new_object;
 
  };

const Calendar = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [batchName, setBatchName] = useState("Batch");
  const { enqueueSnackbar } = useSnackbar();
  const [year, setYear] = useState(2023);
  const [month, setMonth] = useState(10);
  const [day, setDay] = useState(1);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Track the current year
  const [currentDate, setCurrentDate] = useState(get_view_dates());
  const [selectedTool, setSelectedTool] = useState(
    // userInfo.default_caleder_value
    "month"
  );
 
// const [date, setDate] = useState({
//   year: currentDate.getFullYear(),
//   month: currentDate.getMonth() + 1, // Months are zero-based, so add 1
//   day: currentDate.getDate(),
// });

//   // Format the date as "MMMM, yyyy"
//   let formattedDate = currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });


  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const getCenteredText = () => {
    let text = "";
    if (selectedTool === "month") {
      text = moment(currentDate.start_date).add(0, "month").format("MMM YYYY");
    } else if (selectedTool === "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date)
          .add(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .add(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    } else {
      new_object = {
        start_date: moment(start_date)
          .subtract(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .subtract(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    }

    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    console.log("work");
    console.log("value", value);
    console.log("month", month);

    // if (value == "prev") {
    //   setMonth(month - 1);
    //   fetchAttendance(month - 1);
    // } else {
    //   setMonth(month + 1);
    //   fetchAttendance(month + 1);
    // }

    // if (selectedTool == "month") {
    //   let classToClick = "fc-next-button";
    //   if (value == "prev") {
    //     classToClick = "fc-prev-button";
    //   }
    //   let click_element = document.getElementsByClassName(classToClick)[0];
    //   click_element.click();
    // }
    hanldeChangeDates(value);
  };

  const fetchAttendance = async (currentDateObject) => {

    let parsedStartDate = moment(currentDateObject.start_date);
    let parsedEndDate = moment(currentDateObject.end_date);
    // if (selectedTool !== "month") {
    //   parsedEndDate = moment(currentDateObject.end_date).add(1, "day"); // Add 1 day to the end_date
    // }
    const formattedStartDate = parsedStartDate.format("YYYY-MM-DD");
    const formattedEndDate = parsedEndDate.format("YYYY-MM-DD");

    setIsLoading(true);
    const response = await _get_attendance({
    //   start_date: `2023-${month}-1`,
    //   end_date: "2023-12-28",
      //   batch_id: "6551bd733cb570002298ab69",

      start_date: formattedStartDate,
      end_date: formattedEndDate,
      batch_id: id,
    });

    console.log("response _get_attendance", response);
    if (response.code === 200) {
      setBatchName(response.batch?.name);
      // Transforming the response

      // const transformedResponse = response.attendance[0].attendance.map(({ date }) => { date:date.date, absent: date.absent })

      //   const transformedResponse = response.attendance[0].attendance.map(
      //     (feeItem) => ({
      //       date: feeItem.date,
      //       absent: feeItem.absent,
      //     })
      //   );

      //   const [resu, setResu] = useState([
      //     [
      //       { date: "2023-11-15", absent: true },
      //       { date: "2023-11-16", absent: false },
      //     ],
      //   ]);

      // const updatedBatchesFee = batchesFee.map(feeItem => ({
      //     ...feeItem,
      //     fee: 0
      //   }));

      //   console.log(updatedBatchesFee);

      //   console.log(
      //     "response transformedResponse ___transformedResponse",
      //     transformedResponse
      //   );
      // Assuming you are setting this transformed response to the state

      setAttendanceData(response.attendance);

     
      //   setStudentName(response.attendance.student.name);

      //   const updatedAttendance = response.attendance.attendance.map(attendanceItem => ({
      //     ...attendanceItem,
      //     student: response.attendance.student,
      //     name:response.attendance.student.name,
      //     createdAt:response.attendance.createdAt,
      //     updatedAt:response.attendance.updatedAt,
      //   }));

      //   console.log(updatedAttendance);

      //   setStudents(updatedAttendance);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };


  useEffect(() => {
  
    console.log('currentDate ___currentDate',currentDate);
    fetchAttendance(currentDate);
  }, [currentDate]);

  useEffect(() => {
    // console.log('currentDate ___currentDate',currentDate);
    // fetchAttendance(11);
  }, []);
 

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Batch Attendance">
      <div className="container px-3 mb-2 mt-2 text-center">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
{/* 
          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            {capitalizeFirstLetter(batchName)} (Attendance)
          </Typography> */}

        </Grid>

        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            sx={{
              display: "flex",
              justifyContent: "center", // Change to "center"
              alignItems: "center",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            <div className="col-12 order-2   full-calendar-toolbar">
              <div className="btn-group toollbar-group-buttons">
              <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            {capitalizeFirstLetter(batchName)} (Attendance)
          </Typography>
              </div>
            </div>
            {/* <h2>{formattedDate}</h2> */}
          </Grid>

        {/* <div className="col-12 col-md-4 order-1 order-md-2 mb-4 mb-md-0 text-center full-calendar-toolbar">
          <h2 className="event-title-color">{getCenteredText()}</h2>
        </div> */}

        {/* {attendanceData.length > 0 ? ( */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            container
            sx={{
              display: "flex",
              justifyContent: "center", // Change to "center"
              alignItems: "center",
              marginTop: "15px",
              marginBottom: "0px",
            }}
          >
            <div className="col-12 order-2 col-md-4 full-calendar-toolbar">
              <div className="btn-group toollbar-group-buttons">
                <button
                  className={`small-contained-button`}
                  onClick={() => {
                    handleChangeNextPrev("prev");
                  }}
                >
                  <ArrowBackIosIcon />
                </button>
                <h2 className="event-title-color">{getCenteredText()}</h2>
                <button
                  className={`small-contained-button`}
                  onClick={() => {
                    handleChangeNextPrev("next");
                  }}
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            </div>
            {/* <h2>{formattedDate}</h2> */}
          </Grid>
        {/* ) : (
          ""
        )} */}

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack spacing={2} sx={{ marginBottom: 2 }}>
            <div className="mt-2" style={{ overflow: "auto" }}>
              {attendanceData.length > 0 ? (
                <CalendarTableHeader
                  datarows={attendanceData}
                  year={year}
                  month={month}
                  day={day}
                  dateObj={currentDate.start_date}
                />
              ) : (
                <>
                  <hr />
                  <DataNotFoundAll />
                </>
              )}
            </div>
          </Stack>
        </Grid>
      </div>
    </Page>
  );
};

export default Calendar;
