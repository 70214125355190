import React from "react";
import { no_access } from "../../assets";

export default function NoAccessData({ title }) {
  return (
    <div className="no-access-string">
      {/* <img className="mx-auto" src={no_access} /> */}
      <p className="mt-3">{`${title ? title : "You have no access to view this data"
        }`}</p>
    </div>
  );
}
