import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { _student_move_to_trash, delStudent } from "src/DAL/student";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import CustomModal from "src/components/GeneralComponents/CustomModal";
// import AssignmentDetail from "../AssignmentDetail";
import { _delete_assignment_from_admin } from "src/DAL/assignment";
import { _delete_leave_request_for_admin } from "src/DAL/leave_requests";
import AssignmentDetail from "src/pages/Assignments/List/AssignmentDetail";
 
 
import { capitalizeFirstLetter } from "src/utils/formatText";
import { _delete_expense_category } from "src/DAL/expense_category";
import { _delete_expense } from "src/DAL/expense";
// ----------------------------------------------------------------------

export default function StudentMoreMenu({
  row,
  students,
  onStudentsUpdate,
  totalExpense,
  setTotalExpense,
}) {
  const [openDelete, setOpenDelete] = useState(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => {
    setIsOpen(false);
    setOpenModal(true);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    //console.log(row);
    // const result = await delStudent(row.id);
    const result = await _delete_expense(row._id);
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students._id !== row._id
      );
      onStudentsUpdate(updatedStudents);
      setTotalExpense(totalExpense-row.amount);
      enqueueSnackbar("Delete Successfully!", { variant: "success" });
      // setTrashStudentCount(trashStudentCount + 1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* <MenuItem
          component={RouterLink}
          to={`/attendance/detail/${row._id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="icon-park-outline:view-grid-detail" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        
{privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "expense",
                  "edit"
                )?( 
        <MenuItem
            key="edit"
            component={RouterLink}
            to={`/expenses/edit-expense/${row._id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
):("")}
        {/* <MenuItem
          key="Detail"
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          onClick={handleOpenModal}
        >
          <ListItemIcon>
            <Iconify
              icon="icon-park-outline:view-grid-detail"
              vFlip={true}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "expense",
          "delete"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}

{/* <MenuItem
          key="Status Update"
          sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          onClick={handleOpenModal}
        >
          <ListItemIcon>
            <Iconify
              icon="ic:sharp-system-security-update"
              vFlip={true}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Update Status"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem sx={{ color: "text.secondary" }} onClick={handleDeleteClick}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Move To Trash"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/update-student-fee/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:phone-update-checkmark-20-regular" width={24} height={24} />
          </ListItemIcon>Deletetr
          <ListItemText
            primary="Update Fee"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to Delete this Expense?"
        onOk={handleDeleteOk}
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        // pageTitle="Change Leave Request Status"
        pageTitle={`${capitalizeFirstLetter(row.title)} ( Expense )`}
        componentToPassDown={
          <div className="mt-5">
            <h1>hello</h1>
            <h1>hello</h1>
            <h1>hello</h1>

            {/* <ChangeLeaveRequestStatus 
            selectedRowStudent={row} 
            students={students}
            onStudentsUpdate={onStudentsUpdate}
            handleCloseModal={handleCloseModal}
            
            /> */}
          </div>
        }
      />
    </>
  );
}
