import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { _batch_expected_revenue, listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { formatDateTimeWithOutZone, formatDateTimeWithZone } from "src/utils";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { useAppContext } from "src/hooks";
import {
  privilegesNameCheckAndUpperCase,
  privilegesTypeCheckAndShowOrNot,
} from "src/utils/FormatPrivileges";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BatchAllDetail = ({ studentID }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [currency, setCurrency] = useState("Rs");

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };
  const myStyleHeader2 = {
    // textTransform: "capitalize",
    fontWeight: 800,
    fontSize: "1.5rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
  };

  const myStyleHeader3 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    textAlign: "center",
  };
  const myStyleHeader4 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    textAlign: "center",
  };
  const myStyle3 = {
    textTransform: "capitalize",
    textAlign: "center",
  };

  const myStyle4 = {
    textTransform: "capitalize",
    textAlign: "center",
    fontWeight: 600,
  };
  const myStyle5 = {
    textTransform: "capitalize",
    fontWeight: 600,
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    batch_fee: [],
    student_vouchers: [],
    dob: "",
    course_fee: "",
    course: {
      _id: "",
      title: "",
    },
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",
    discount: 0,

    registration_fee_discount: 0,
    fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount_reason: "",

    fee_status: "all_paid",

    instructors: [],
    batch_fee: [],

    batch_total_paid_fee_revenue: 0,
    collected_registration_revenue: 0,
    total_monthly_fee_discount: 0,

    pending_registration_revenue: 0,
    batch_total_pending_fee_revenue: 0,

    expected_registration_revenue_without_discount: 0,
    batch_expected_registration_revenue_after_discount: 0,
    total_registration_discount: 0,

    total_registration_additional_discount: 0,

    paid_registration_revenue: 0,
    pending_registration_revenue: 0,
    expected_monthly_fee_revenue_without_discount: 0,
    expected_monthly_fee_revenue_after_discounts: 0,
    total_monthly_fee_discount: 0,
    generated_voucher_revenue: 0,

    generated_fee_voucher_additional_discount: 0,
    generated_fee_voucher_fee_discount: 0,

    batch_total_paid_fee_revenue: 0,
    batch_total_pending_fee_revenue: 0,

    predicted_revenue: [],
    collected_revenue: [],

    total_paid_registration_fee: 0,
    total_registration_fee_after_discount: 0,
    total_registration_fee_before_discount: 0,
    total_registration_voucher_additional_discount: 0,
    total_registration_voucher_discount: 0,
    total_collected_revenue: 0,
    batch_assignment_count: "",

    pending_assignments_count: 0,
    batch_assignment_count: 0,
    // "expected_registration_revenue_without_discount": 100,
    // "batch_expected_registration_revenue_after_discount": 91,
    // "total_registration_discount": 9,

    // "total_registration_additional_discount": 0,

    // "paid_registration_revenue": 0,
    // "pending_registration_revenue": 91,
    // "expected_monthly_fee_revenue_without_discount": 40,
    // "expected_monthly_fee_revenue_after_discounts": 36,
    // "generated_voucher_revenue": 16,

    // "generated_fee_voucher_additional_discount": 2,
    // "generated_fee_voucher_fee_discount": 2,

    // "batch_total_paid_fee_revenue": 0,
    // "batch_total_pending_fee_revenue": 16,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await _batch_expected_revenue(studentID);
    console.log("response _batch_expected_revenue", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      // console.log("sss", data);
      // console.log(
      //   response.batch.courses.map((course) => course.title),
      //   "log"
      // );

      let courseIds = "";
      let courseTitles = "";

      if (response.batch.courses) {
        courseIds = response.batch.courses.map((course) => course._id);
        courseTitles = response.batch.courses.map((course) => course.title);
      }
      if (response.batch.course) {
        courseIds = response.batch.course._id;
        courseTitles = response.batch.course.title;
      }

      setFormInputs({
        ...response.batch,
        order: response.batch.order,
        courses: courseIds,
        courseTitles: courseTitles,

        status: response.batch.status,

        student_count: response.batch.student_count,
        total_seats: response.batch.total_seats,
        available_seats: response.batch.available_seats,
        start_date_batch: formatDate(response.batch.start_date_batch),
        end_date_batch: formatDate(response.batch.end_date_batch),
        start_time_class: formatTime(response.batch.start_time_class),
        end_time_class: formatTime(response.batch.end_time_class),

        total_male_students: response.batch.total_male_students,
        total_female_students: response.batch.total_female_students,
        createdAt: formatDateTimeWithOutZone(response.batch.createdAt),
        updatedAt: formatDateTimeWithOutZone(response.batch.updatedAt),

        time: formatTime(
          response.batch.start_time_class,
          response.batch.end_time_class
        ),
        //
        name: response.batch.name,
        total_seats: response.batch.total_seats,
        avaliable_seats: response.batch.avaliable_seats,

        batch_duration_months: response.batch.batch_duration_months,
        batch_status: response.batch.batch_status,
        status: response.batch.status,
        course: response.batch.course,
        instructors: response.batch.instructors,
        batch_fee: response.batch.batch_fee,

        batch_expected_registration_revenue:
          response.batch.batch_expected_registration_revenue,
        generated_monthly_fee_voucher_revenue:
          response.batch.generated_monthly_fee_voucher_revenue,
        batch_expected_monthly_fee_revenue:
          response.batch.batch_expected_monthly_fee_revenue,
        monthly_fee_discount_voucher_revenue:
          response.batch.monthly_fee_discount_voucher_revenue,

        student_count: response.batch.student_count,
        registration_fee: response.batch.registration_fee,

        start_date_batch: formatDate(response.batch.start_date_batch),
        end_date_batch: formatDate(response.batch.end_date_batch),

        // collected_registration_revenue: response.batch
        //   .collected_registration_revenue
        //   ? response.batch.collected_registration_revenue
        //   : 0,

        expected_registration_revenue_without_discount: response.batch
          .expected_registration_revenue_without_discount
          ? response.batch.expected_registration_revenue_without_discount
          : 0,

        batch_expected_registration_revenue_after_discount: response.batch
          .batch_expected_registration_revenue_after_discount
          ? response.batch.batch_expected_registration_revenue_after_discount
          : 0,

        total_registration_discount: response.batch.total_registration_discount
          ? response.batch.total_registration_discount
          : 0,

        paid_registration_revenue: response.batch.paid_registration_revenue
          ? response.batch.paid_registration_revenue
          : 0,

        pending_registration_revenue: response.batch
          .pending_registration_revenue
          ? response.batch.pending_registration_revenue
          : 0,

        expected_monthly_fee_revenue_without_discount: response.batch
          .expected_monthly_fee_revenue_without_discount
          ? response.batch.expected_monthly_fee_revenue_without_discount
          : 0,

        expected_monthly_fee_revenue_after_discounts: response.batch
          .expected_monthly_fee_revenue_after_discounts
          ? response.batch.expected_monthly_fee_revenue_after_discounts
          : 0,

        total_monthly_fee_discount: response.batch.total_monthly_fee_discount
          ? response.batch.total_monthly_fee_discount
          : 0,

        generated_voucher_revenue: response.batch.generated_voucher_revenue
          ? response.batch.generated_voucher_revenue
          : 0,

        batch_total_paid_fee_revenue: response.batch
          .batch_total_paid_fee_revenue
          ? response.batch.batch_total_paid_fee_revenue
          : 0,

        batch_total_pending_fee_revenue: response.batch
          .batch_total_pending_fee_revenue
          ? response.batch.batch_total_pending_fee_revenue
          : 0,

        // new
        total_registration_additional_discount: response.batch
          .total_registration_additional_discount
          ? response.batch.total_registration_additional_discount
          : 0,

        generated_fee_voucher_additional_discount: response.batch
          .generated_fee_voucher_additional_discount
          ? response.batch.generated_fee_voucher_additional_discount
          : 0,

        generated_fee_voucher_fee_discount: response.batch
          .generated_fee_voucher_fee_discount
          ? response.batch.generated_fee_voucher_fee_discount
          : 0,
      });

      const students = response.batch.students.map((student, index) => ({
        ...student, // Spread the original student object
        id: student._id, // Override the id property
        number: index + 1, // Override the number property
        time: formatTime(
          student.time_preference
            ? student.time_preference.start_time
            : "00:00",
          student.time_preference ? student.time_preference.end_time : "00:00"
        ), // Override the time property
        //   fee_status: student.fee_status,
      }));

      //   setStudents(students);
      // setStudents(response.batch.students);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailBatch();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh', // Optional to center vertically in the viewport
  };
  return (
    <Page title="Batch Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <div className="container">
          <TableContainer component={Paper}>
            <div className="container">
              <div className="row mt-3">
                <div className="col-12">
                  <Accordion
                    className="accordian"
                    sx={{
                      boxShadow: "none",
                      borderBottom: "1px solid rgb(229, 229, 229)",
                    }}
                    defaultExpanded={
                      get_user_profile.type === 2 || get_user_profile.type === 3
                        ? true
                        : false
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="subtitle1" fontWeight="bold">
                        Detail
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table
                        sx={{
                          minWidth: "100%",
                          // paddingBottom: "30px",
                          // marginTop: "30px",
                          // marginBottom: "30px",
                          border: "1px solid #f1f3f4", // Set the border style here
                        }}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Name
                            </TableCell>
                            <TableCell align="left">
                              {capitalizeFirstLetter(formInputs.name)}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Course
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.course.title}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Pending Batch Assignments
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.pending_assignments_count}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Total batch Assignments
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.batch_assignment_count}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Total Seats
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.total_seats}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Avaliable Seats
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.avaliable_seats}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Batch Duration Months
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.batch_duration_months}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          {get_user_profile.type !== 2 ? (
                            <>
                              <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Status
                                </TableCell>

                                <TableCell align="left">
                                  <Label
                                    variant="outlined"
                                    color={
                                      (formInputs.status === false &&
                                        "error") ||
                                      "success"
                                    }
                                  >
                                    {formInputs.status ? "Active" : "Inactive"}
                                    {/* {/ {sentenceCase(status)} /} */}
                                  </Label>
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Batch Status
                                </TableCell>

                                <TableCell align="left" style={myStyle}>
                                  <Label
                                    variant="outlined"
                                    color={
                                      (formInputs.batch_status === 1 &&
                                        "warning") ||
                                      (formInputs.batch_status === 4 &&
                                        "success") ||
                                      (formInputs.batch_status === 5 &&
                                        "info") ||
                                      "info"
                                    }
                                  >
                                    {formInputs.batch_status === 1
                                      ? "Pending"
                                      : formInputs.batch_status === 2
                                      ? "Confirm"
                                      : formInputs.batch_status === 3
                                      ? "Started"
                                      : formInputs.batch_status === 4
                                      ? "Completed"
                                      : formInputs.batch_status === 5
                                      ? "Freeze"
                                      : "Not Set"}
                                  </Label>
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              </TableRow>
                            </>
                          ) : null}

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Start Time Class
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.start_time_class}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              End Time Class
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.end_time_class}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Batch Start Date
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.start_date_batch}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell align="left" style={myStyleHeader}>
                              Batch End Date
                            </TableCell>
                            <TableCell align="left" style={myStyle}>
                              {formInputs.end_date_batch}
                            </TableCell>
                            <TableCell align="left" style={myStyle}></TableCell>
                          </TableRow>

                          {privilegesTypeCheckAndShowOrNot(
                            get_user_profile,
                            get_user_privilege_access,
                            "batch",
                            "admin"
                          ) ? (
                            <>
                              <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Created At
                                </TableCell>
                                <TableCell align="left" style={myStyle}>
                                  {formInputs.createdAt}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Updated At
                                </TableCell>
                                <TableCell align="left" style={myStyle}>
                                  {formInputs.updatedAt}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              </TableRow>
                            </>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </div>

                {privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "batch",
                  "admin"
                ) ||
                privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "batch",
                  "revenue"
                ) ? (
                  <>
                    <div className="col-12">
                      <Accordion
                        className="accordian"
                        sx={{
                          boxShadow: "none",
                          borderBottom: "1px solid rgb(229, 229, 229)",
                        }}
                        defaultExpanded={true}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="subtitle1" fontWeight="bold">
                            Revenue
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table
                            sx={{
                              minWidth: "100%",
                              border: "1px solid #f1f3f4", // Set the border style here
                            }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="left" style={myStyleHeader3}>
                                  Fee Title
                                </TableCell>
                                <TableCell align="left" style={myStyleHeader3}>
                                  Fee
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align="left" style={myStyleHeader3}>
                                  Fee Discount
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align="left" style={myStyleHeader3}>
                                  Additional Discount
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell align="left" style={myStyleHeader3}>
                                  {/* Amount After Discount */}
                                  Predicted Revenue
                                </TableCell>

                                <TableCell align="left" style={myStyleHeader3}>
                                  {/* Amount After Discount */}
                                  Collected Revenue
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {/* <TableRow>
            <TableCell rowSpan={4} />
            <TableCell colSpan={3}>Subtotal</TableCell>
            <TableCell align="right">1</TableCell>
          </TableRow> */}
                              {/* <TableRow>
            <TableCell>Tax</TableCell>
            <TableCell align="right">2</TableCell>
            <TableCell align="right">3</TableCell>
          </TableRow> */}
                              {/* <TableRow>
            <TableCell colSpan={3}>Total</TableCell>
            <TableCell align="right">4</TableCell>
          </TableRow> */}

                              {formInputs.predicted_revenue.map(
                                (predicted, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      align="left"
                                      style={myStyleHeader}
                                    >
                                      #{index + 1} {predicted.fee_title}
                                    </TableCell>
                                    <TableCell align="left" style={myStyle3}>
                                      {predicted.amount}
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                                      -
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                                      {predicted.fee_discount}
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                                      -
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                                      {predicted.additional_discount}
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                                      =
                                    </TableCell>

                                    <TableCell align="left" style={myStyle4}>
                                      {predicted.total_amount} {currency}
                                    </TableCell>

                                    <TableCell align="left" style={myStyle4}>
                                      {predicted.paid_amount} {currency}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}

                              <TableRow>
                                <TableCell rowSpan={1} />
                                <TableCell
                                  colSpan={6}
                                  align="left"
                                  style={myStyle4}
                                ></TableCell>
                                <TableCell
                                  colSpan={1}
                                  align="left"
                                  style={myStyleHeader3}
                                >
                                  Total Batch Revenue :
                                </TableCell>
                                {/* <TableCell colSpan={1} align="left" style={myStyle4} > = </TableCell> */}
                                <TableCell align="left" style={myStyleHeader3}>
                                  {" "}
                                  {formInputs.total_collected_revenue}{" "}
                                  {currency}
                                </TableCell>
                                {/* <TableCell align="right">1</TableCell> */}
                              </TableRow>

                              {/* <TableRow> */}
                              {/* <TableCell
                                      align="left"
                                      style={myStyleHeader}
                                    >
                         
                                    </TableCell>
                                    <TableCell align="left" style={myStyle3}>
                                  
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                                  
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                        
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                              
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                             
                                    </TableCell>

                                    <TableCell align="left" style={myStyle3}>
                                  
                                    </TableCell>

                                    <TableCell align="left" style={myStyle4}>
                            
                                    </TableCell> */}

                              {/* <TableCell align="right" style={myStyleHeader4}>
                                    Total Batch Revenue = {formInputs.total_collected_revenue} RS
                                    </TableCell>
                                    Total Batch Revenue = {formInputs.total_collected_revenue} RS
                                  </TableRow> */}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-6">
                      <Accordion
                        className="accordian"
                        sx={{
                          boxShadow: "none",
                          borderBottom: "1px solid rgb(229, 229, 229)",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="subtitle1" fontWeight="bold">
                            {/* {privilegesNameCheckAndUpperCase(
                                "abc"
                              )} */}
                            Fee
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table
                            sx={{
                              minWidth: "100%",
                              // paddingBottom: "30px",
                              // marginTop: "30px",
                              // marginBottom: "30px",
                              border: "1px solid #f1f3f4", // Set the border style here
                            }}
                            aria-label="simple table"
                          >
                            <TableBody>
                              {/* <TableRow>
                              <TableCell align="left" style={myStyleHeader2}>
                                {" "}
                                {capitalizeFirstLetter(formInputs.name)} Fee
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                     
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {" "}
                              </TableCell>
                            </TableRow> */}

                              {/* <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Registration Fee
                                </TableCell>
                                <TableCell align="left" style={myStyle}>
                                  {formInputs.registration_fee} {currency}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              </TableRow> */}

                              <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Course Fee
                                </TableCell>
                                <TableCell align="left" style={myStyle}>
                                  {formInputs.course.fee} {currency}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              </TableRow>

                              {formInputs.batch_fee.map((fee, index) => (
                                <TableRow key={index}>
                                  <TableCell align="left" style={myStyleHeader}>
                                    #{index + 1} {fee.fee_title}
                                  </TableCell>
                                  <TableCell align="left" style={myStyle}>
                                    {fee.fee} {currency}
                                  </TableCell>
                                  <TableCell align="left" style={myStyle}>
                                    {" "}
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Total Batch Fee
                                </TableCell>
                                <TableCell align="left" style={myStyle5}>
                                  {formInputs.total_batch_fee} {currency}
                                </TableCell>
                                <TableCell align="left" style={myStyle}>
                                  {" "}
                                </TableCell>
                              </TableRow>

                              {/* <TableRow>
                                <TableCell align="left" style={myStyleHeader}>
                                  Total Batch Fee + Registration Fee
                                </TableCell>
                                <TableCell align="left" style={myStyle5}>
                                  {formInputs.total_batch_fee +
                                    formInputs.registration_fee}{" "}
                                  {currency}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              </TableRow> */}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className="col-6">
                      <Accordion
                        className="accordian"
                        sx={{
                          boxShadow: "none",
                          borderBottom: "1px solid rgb(229, 229, 229)",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="subtitle1" fontWeight="bold">
                            {/* {privilegesNameCheckAndUpperCase(
         "abc"
       )} */}
                            Instructors
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table
                            sx={{
                              minWidth: "100%",
                              // paddingBottom: "30px",
                              // marginTop: "30px",
                              // marginBottom: "30px",
                              border: "1px solid #f1f3f4", // Set the border style here
                            }}
                            aria-label="simple table"
                          >
                            <TableBody>
                              {/* <TableRow>
                        <TableCell align="left" style={myStyleHeader2}>
                          {" "}
                          {capitalizeFirstLetter(formInputs.name)} Instructors
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                     
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {" "}
                        </TableCell>
                      </TableRow> */}

                              {formInputs.instructors.map(
                                (instructor, index) => (
                                  <TableRow key={index}>
                                    <TableCell
                                      align="left"
                                      style={myStyleHeader}
                                    >
                                      #{index + 1}
                                    </TableCell>
                                    <TableCell align="left" style={myStyle}>
                                      {instructor.first_name}{" "}
                                      {instructor.last_name}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      style={myStyle}
                                    ></TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
};

export default BatchAllDetail;
