import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _detail_brand, _edit_brand, _list_brand } from "src/DAL/brand";
import { _detail_asset_category, _edit_asset_category, _list_asset_category } from "src/DAL/asset_category";
import { _list_vendor } from "src/DAL/vendor";
import { _detail_company_asset, _edit_company_asset } from "src/DAL/company_asset";
import moment from "moment";
import { _detail_event, _edit_event } from "src/DAL/events";
import { capitalizeFirstLetter } from "src/utils/formatText";

const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    event_title: "",
    cost: "",
    organized_by: "",
    event_description: "",
    venue: "",
    start_date_batch_select: "",
    end_date_batch_select: "",
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, start_date_batch_select: formattedDate }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, end_date_batch_select: formattedDate }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      event_title: formInputs.event_title,
      // date: formattedStartDate,
      date: formInputs.start_date_batch_select,
      // purchase_date: formInputs.start_date_batch_select,
      // warranty_expire_date: formInputs.end_date_batch_select,
      cost: formInputs.cost,
      organized_by: formInputs.organized_by,
      event_description: formInputs.event_description,
      venue: formInputs.venue,
    };
    console.log("postData", postData);

    setIsLoading(true);
 
    // const result = await _add_company_asset(postData);
    const result = await _edit_event(id,postData);
    console.log("result _edit_event", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/event");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }

  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_event(id);
    console.log("_detail_event _detail_event", response);
    // enquiry_form

    if (response.code === 200) {


      

      let defaultStartDate = new Date();
      console.log('defaultStartDate',defaultStartDate);
      if(response.event.date){
        defaultStartDate = new Date(response.event.date);
          console.log('defaultStartDate',defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log('defaultStartDate',defaultStartDate);

      setFormInputs({
        event_title: capitalizeFirstLetter(response.event.event_title),
        cost: response.event.cost,
        organized_by: capitalizeFirstLetter(response.event.organized_by),
        event_description: capitalizeFirstLetter(response.event.event_description),
        venue: capitalizeFirstLetter(response.event.venue),
 
          start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
 
      });
      // setFormInputs(response.company_asset);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
   
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Asset">
               <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">Edit Event</h4>
        </Grid>


      <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.event_title}
                  onChange={handleChange}
                  name="event_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Venue"
                  value={formInputs.venue}
                  onChange={handleChange}
                  name="venue"
                   
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>


              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Cost"
                  value={formInputs.cost}
                  onChange={handleChange}
                  name="cost"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Organized By"
                  value={formInputs.organized_by}
                  onChange={handleChange}
                  name="organized_by"
                  
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
 
            <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  sx={{ marginTop: 2 }}
                >
                  {/* <div className="col-md-12 col-xs-12"> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date *"
                    format="YYYY-MM-DD"
                    // value={formInputs?.start_date_batch}
                    value={dayjs(formInputs.start_date_batch)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>

                  {/* </div> */}

                  {/* <div className="col-md-12 col-xs-12"> */}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      // disabled
                      label="Warranty Expire Date *"
                      format="YYYY-MM-DD"
                      // value={formInputs?.start_date_batch}
                      value={dayjs(formInputs.end_date_batch)} // Convert the default date to a Day.js object
                      onChange={handleDateChange2}
                      // maxDate={dayjs()} // Set the maximum date to today's date
                      // renderInput={(params) => <TextField {...params} />}
                      sx={{
                        width: "100%", // Set the width to 100% to take up the full available width
                        // marginTop: "20px",
                      }}
                    />
                  </LocalizationProvider> */}
                  {/* </div> */}
                </Stack>


                <TextField
                fullWidth
                label="Description"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.event_description}
                onChange={handleChange}
                name="event_description"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

            <Stack alignItems="end">
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "120px" }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </div>
  </Page>
  );
};

export default Edit;
