import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  TableHead,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { _detail_fee_voucher } from "src/DAL/voucher";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useAppContext } from "src/hooks";
const VoucherDetail = ({ studentID }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  // const myStyle = {
  //   textTransform: "capitalize",
  // };
  // const myStyleHeader = {
  //   textTransform: "capitalize",
  //   fontWeight: 700, // Set the font weight to 500 for bold text
  // };
  // const myStyleHeader2 = {
  //   textTransform: "capitalize",
  //   fontWeight: 800,
  //   fontSize: "1.5rem", // Adjust the font size as needed
  //   margin: "1rem 0", // Add margin for spacing, adjust as needed
  // };

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };
  const myStyleHeader2 = {
    // textTransform: "capitalize",
    fontWeight: 700,
    fontSize: "1.3rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
  };

  const myStyleHeader3 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
    // textAlign: "center",
  };
  const myStyle3 = {
    textTransform: "capitalize",
    textAlign: "center",
  };

  const myStyle4 = {
    textTransform: "capitalize",
    textAlign: "center",
    fontWeight: 600,
  };
  const myStyle5 = {
    textTransform: "capitalize",
    fontWeight: 600,
  };

  const [formInputs, setFormInputs] = useState({
    _id: "0",
    batch: {
      _id: "0",
      name: "",
    },
    student: {
      _id: "0",
      name: "",
      father_name: "",
      contact_number: "",
      registration_fee_discount: 0,
      fee_discount: 0,
      fee_status: 0,
    },
    paid_amount: [],
    paid_amount_all: "",
    additional_discount: 0,
    additional_discount_reason: "",
    status: true,
    generated_by: {
      _id: "0",
      first_name: "",
      last_name: "",
    },
    generation_time: "2023-10-16T12:31:31.899Z",
    due_date: "2023-10-18T00:00:00.000Z",
    additional_discount_time: null,
    activity_log: [],
    voucher_status: 1,
    fee_id: null,
    fee_title: "",
    fee: 0,
    voucher_type: 1,
    createdAt: "2023-10-16T12:31:31.928Z",
    updatedAt: "2023-10-16T12:31:31.928Z",
  });

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  function formatTime2(startTime) {
    const dateStr = startTime;
    const date = new Date(dateStr);

    const options = { hour: "2-digit", minute: "2-digit" };
    const formattedTime = date.toLocaleTimeString("en-US", options);

    console.log(formattedTime); // Outputs: "12:31 PM"
    return formattedTime;
  }

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await _detail_fee_voucher(studentID);
    console.log("response _detail_fee_voucher __detail_fee_voucher", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.fee_voucher.due_date) {
        defaultStartDate = new Date(response.fee_voucher.due_date);
        console.log("defaultStartDate", defaultStartDate);
      }

      console.log("defaultStartDate", defaultStartDate);

      //   // Use Array.prototype.find to locate the desired object
      //   let foundObject = batches.find(
      //     (obj) => obj.id === response.fee_voucher.batch._id
      //   );

      //   if (foundObject) {
      //     setbatcheFees(foundObject.batch_fee);
      //   } else {
      //     setbatcheFees([]);
      //   }

      //   if(response.fee_voucher.additional_discount > 0){
      //     setFeeDiscountChecked(true)
      //   }

      const calculateTotalPaidAmount = (paidAmountArray) => {
        return paidAmountArray.reduce(
          (total, payment) => total + payment.amount,
          0
        );
      };

      // Assuming response.fee_voucher.paid_amount is the array you provided
      const totalPaidAmount = calculateTotalPaidAmount(
        response.fee_voucher.paid_amount
      );
      // setFormInputs((values) => ({ ...values, paid_amount: response.fee_voucher.paid_amount }));
      setFormInputs((values) => ({
        ...values,
        paid_amount_all: totalPaidAmount,
      }));

      setFormInputs({
        ...response.fee_voucher,
        batch_id: response.fee_voucher.batch._id,
        batch_fee_id: response.fee_voucher.fee_id,

        additional_discount: response.fee_voucher.additional_discount,
        additional_discount_reason:
          response.fee_voucher.additional_discount_reason,

        // time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),
        // format_time: formattedTime,

        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        paid_amount_all: totalPaidAmount,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailBatch();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh', // Optional to center vertically in the viewport
  };
  return (
    <Page title="Batch Student Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <div className="container ">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader2}>
                    Student{" "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Student Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.student.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Father Name
                  </TableCell>
                  <TableCell align="left">
                    {textEmptyToReturnHyphen(
                      capitalizeFirstLetter(formInputs.student.father_name)
                    )}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Contact Number
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    +92{formInputs.student.contact_number}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {formInputs.voucher_type !== 3 ? (
                  <TableRow>
                    <TableCell align="left" style={myStyleHeader}>
                      Student Fee Status
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {formInputs.student.fee_status === 1
                        ? "Paid"
                        : formInputs.student.fee_status === 2
                        ? "Monthly"
                        : formInputs.student.fee_status === 3
                        ? "Free"
                        : "Pending"}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>

              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader2}>
                    Voucher Detail{" "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Batch
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.batch.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {formInputs.voucher_type === 2 ||
                formInputs.voucher_type === 3 ? (
                  <TableRow>
                    <TableCell align="left" style={myStyleHeader}>
                      Fee Title
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {formInputs.fee_title}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Fee
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.fee}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {formInputs.voucher_type === 1 ? (
                  <>
                    {/* <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Registration Fee Discount
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.student.registration_fee_discount}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Registration Fee Discount Reason
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {textEmptyToReturnHyphen(
                          formInputs?.student.registration_fee_discount_reason
                        )}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow> */}
                  </>
                ) : (
                  ""
                )}

                {formInputs.voucher_type === 2 ? (
                  <>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Fee Discount
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.fee_discount}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Fee Discount Reason
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {textEmptyToReturnHyphen(
                          formInputs?.fee_discount_reason
                        )}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  ""
                )}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Additional Discount
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.additional_discount}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Additional Discount Reason
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {textEmptyToReturnHyphen(
                      formInputs.additional_discount_reason
                    )}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Total Fee After Discount
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.fee -
                      (formInputs.voucher_type === 1
                        ? // ? formInputs.student.registration_fee_discount
                          0
                        : formInputs.fee_discount) -
                      formInputs.additional_discount}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    status
                  </TableCell>
          
                  <TableCell align="left">
                    <Label
                      variant="outlined"
                      color={
                        (formInputs.status === false && "error") || "success"
                      }
                    >
                      {formInputs.status ? "Active" : "Inactive"}
                    </Label>
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
   
                  </TableCell>
                </TableRow> */}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Generated By
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.generated_by.first_name}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.generated_by.last_name}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Generated Time
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.generation_time)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Due Date
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDate(formInputs.due_date)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Additional Discount Time
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.additional_discount_time === null
                      ? " _ _ "
                      : formatDateTimeWithOutZone(
                          formInputs.additional_discount_time
                        )}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Voucher Type
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    <Label
                      variant="outlined"
                      color={
                        ((formInputs.voucher_type === 1 ||
                          formInputs.voucher_type === 2) &&
                          "info") ||
                        "warning"
                      }
                    >
                      {formInputs.voucher_type === 1
                        ? "Registration"
                        : formInputs.voucher_type === 2
                        ? "Monthly"
                        : "Custom"}
                    </Label>
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Voucher Status
                  </TableCell>
                  {/* <TableCell align="left" style={myStyle}>
                    {formInputs.voucher_status === 2 ? "Paid" : "Pending"}
                  </TableCell> */}

                  <TableCell align="left">
                    <Label
                      variant="outlined"
                      color={
                        ((formInputs.voucher_status === 1 ||
                          formInputs.voucher_status === 3) &&
                          "warning") ||
                        (formInputs.voucher_status === 4 && "info") ||
                        "success"
                      }
                    >
                      {formInputs.voucher_status === 1
                        ? "Pending"
                        : formInputs.voucher_status === 3
                        ? "Due"
                        : formInputs.voucher_status === 4
                        ? "Refund"
                        : "Paid"}
                    </Label>
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {formInputs.voucher_status === 4 ? (
                  <TableRow>
                    <TableCell align="left" style={myStyleHeader}>
                      Voucher Refund Reason
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {formInputs.voucher_refund_reason}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.createdAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.updatedAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableBody>

              <>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" style={myStyleHeader2}>
                      Paid Amount History{" "}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>

                  {formInputs.paid_amount.map((payHistory, index) => (
                    <TableRow key={index}>
                      <TableCell align="left" style={myStyleHeader}>
                        {/* ({index + 1}){" "} */}#{index + 1}{" "}
                        {formatDateTimeWithOutZone(payHistory.date)}{" "}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        Paid Amount = {payHistory.amount}
                      </TableCell>
                      <TableCell align="left" style={myStyle}></TableCell>
                    </TableRow>
                  ))}

                  {formInputs.voucher_type === 1 ? (
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Total Fee = {formInputs.fee}{" "}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {/* Registration Fee Discount ={" "}
                        {formInputs.student.registration_fee_discount} */}
                        Additional Discount = {formInputs.additional_discount}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {/* Additional Discount = {formInputs.additional_discount} */}
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}

                  {formInputs.voucher_type !== 1 ? (
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Total Fee = {formInputs.fee}{" "}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        Fee Discount = {formInputs.fee_discount}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        Additional Discount = {formInputs.additional_discount}
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}

                  <TableRow>
                    <TableCell align="left" style={myStyleHeader}></TableCell>
                    <TableCell align="left" style={myStyleHeader}>
                      Total Paid = {formInputs.paid_amount_all}{" "}
                    </TableCell>
                    <TableCell align="left" style={myStyleHeader}>
                      Pending = {formInputs.pending_fee}{" "}
                    </TableCell>
                  </TableRow>

{formInputs.voucher_refund_date && formInputs.voucher_refund_date!==null && formInputs.voucher_status === 4?( 
                  <TableRow>
                    <TableCell align="left" style={myStyleHeader}>
                      Refund Date And Time
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {formatDateTimeWithOutZone(formInputs.voucher_refund_date)}
                    </TableCell>
                    <TableCell align="left" style={myStyle}></TableCell>
                  </TableRow>
):("")}
                </TableBody>
              </>
            </Table>
          </TableContainer>

          {privilegesTypeCheckAndShowOrNot(
            get_user_profile,
            get_user_privilege_access,
            "fee_voucher",
            "admin"
          ) ? (
            <>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    minWidth: "100%",
                    border: "1px solid #f1f3f4", // Set the border style here
                  }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader2}>
                        Activity Log{" "}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table
                  sx={{
                    minWidth: "100%",
                    border: "1px solid #f1f3f4", // Set the border style here
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader3}>
                        User
                      </TableCell>

                      <TableCell align="left" style={myStyleHeader3}>
                        Additional Discount
                      </TableCell>

                      <TableCell align="left" style={myStyleHeader3}>
                        Reason
                      </TableCell>

                      <TableCell style={myStyleHeader3}>Time</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {/* <TableRow>
                      <TableCell align="left" style={myStyleHeader2}>
                        Activity Log{" "}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow> */}

                    {formInputs.activity_log.map((log, index) => (
                      <TableRow key={index}>
                        <TableCell align="left" style={myStyle}>
                          #{index + 1}{" "}
                          {capitalizeFirstLetter(log.user.first_name)}{" "}
                          {capitalizeFirstLetter(log.user.last_name)}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {log.additional_discount}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {textEmptyToReturnHyphen(
                            log.additional_discount_reason
                          )}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {log.additional_discount_time !== null
                            ? formatDateTimeWithOutZone(
                                log.additional_discount_time
                              )
                            : " _ _ "}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </Page>
  );
};

export default VoucherDetail;
