import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import InstructorListHead from "./components/InstructorListHead";
import InstructorListToolbar from "./components/InstructorListToolbar";
import InstructorMoreMenu from "./components/InstructorMoreMenu";

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { listInstructor } from "src/DAL/instructor";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import InstuctorDetailComponent from "./InstuctorDetailComponent";
import { useAppContext } from "src/hooks"; 
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { textEmptyToReturnHyphen } from "src/utils/formatText";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "first_name", label: "First Name", alignRight: false },
  { id: "last_name", label: "Last Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "role", label: "Role", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Instructors() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [instructors, setInstructors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowInstructor, setSelectedRowInstructor] = useState({});
  
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setSelectedRowInstructor(selectedRow);
  }


  function handleInstructorsUpdate(updatedInstructors) {
    setInstructors(updatedInstructors);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = instructors.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
       if (value === -1) {
      // Show all rows
      setRowsPerPage(instructors.length);
 
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - instructors.length) : 0;

  const filteredInstructors = applySortFilter(
    instructors,
    getComparator(order, orderBy),
    filterName
  );

  const isInstructorNotFound = filteredInstructors.length === 0;

  const handleNav = () => {
    navigate("/instructors/add-instructor");
  };

  const fetchInstructors = async () => {
    const response = await listInstructor();
    if (response.code === 200) {
      setIsLoading(false);

      const instructors = response.instructors.map((instructor) => ({
        ...instructor,
        id: instructor._id,
        first_name: instructor.first_name,
        last_name: instructor.last_name,
        email: instructor.email,
        image: instructor.image,
        status: instructor.status,
      }));

      setInstructors(instructors);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchInstructors();
  }, []);
  
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Instructor">
      <Container maxWidth="xl">
      {privilegesTypeCheckAndShowOrNot(
              get_user_profile,
              get_user_privilege_access,
              "instructor",
              "view"
            ) ?( <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
          mt={2}
        >
          <Typography variant="h4">Instructors</Typography>

        {privilegesTypeCheckAndShowOrNot(
              get_user_profile,
              get_user_privilege_access,
              "instructor",
              "add"
            ) ?(
          <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Instructor
          </Button>
          ) : (
            ""
          )}
          

        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
        >

          <Scrollbar>
          <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              // sx={{ paddingTop: "10px" }}
            >
               <InstructorListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />
            </Stack>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <InstructorListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={instructors.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredInstructors
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row,index) => {
                      const {
                        id,
                        first_name,
                        last_name,
                        email,
                        image,
                        status,
                        role,
                      } = row;
                      const isItemSelected =
                        selected.indexOf(first_name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox"> */}
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                          {/* </TableCell> */}

                          <TableCell align="left" style={myStyle}>
                            {index + 1}
                          </TableCell>

                          {/* <TableCell align="left" style={myStyle}>
                            {first_name}
                          </TableCell> */}
                          {/* <TableCell
                              pref={myElementRef}
                              align="left"
                              style={myStyle}
                            >
                              <button
                                style={{
                                  background: "none",
                                  border: "none",
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                                onClick={() => handleOpenModal(row)}
                              >
                                {first_name}
                              </button>
                            </TableCell> */}
                              <TableCell align="left" style={myStyle}>
                            <div
                            className="on_click_text"
                              // style={{
                              //   cursor: "pointer",
                              // }}
                              onClick={() => handleOpenModal(row)}
                            >
                              {first_name}
                            </div>
                          </TableCell> 

                          {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}
                          <TableCell align="left" style={myStyle}>
                            {last_name}
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{role?textEmptyToReturnHyphen(role.title):""}</TableCell>
                          <TableCell align="left">
                            {" "}
                            <Avatar alt={first_name} src={s3baseUrl + image} />
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === false && "error") || "success"}
                            >
                              {status ? "Active" : "Inactive"}
                              {/* {/ {sentenceCase(status)} /} */}
                            </Label>
                          </TableCell>
                          {privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "instructor",
                                "edit"
                              ) ||
                              privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "instructor",
                                "delete"
                              ) ? (
                          <TableCell align="right">
                            <InstructorMoreMenu
                              row={row}
                              instructors={instructors}
                              onInstructorsUpdate={handleInstructorsUpdate}
                            />
                          </TableCell>
                           ) : (
                            <TableCell align="right"></TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>

                {isInstructorNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150 ,{ value: instructors.length, label: 'All' }]}
            component="div"
            count={instructors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        </>
        ):
      
        (
          // <NoAccessData />
          <NoAccessData />
        ) }
      </Container>

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Instructor Detail"
        componentToPassDown={
          <InstuctorDetailComponent instructorID={selectedRowInstructor.id} />
        }
        />
    </Page>
  );
}
