import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";

import { detailTestimonial, editTestimonial } from "src/DAL/testimonial";
import { listActivePage } from "src/DAL/page";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import { _detail_slider, _edit_slider } from "src/DAL/slider";
import TinyEditor from "src/components/Ckeditor";
import { _detail_tab, _update_tab } from "src/DAL/tabs";
import { _detail_course_rating, _edit_course_rating } from "src/DAL/course_rating";
const EditCourseRating = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const [image, setImage] = useState();
  const [pages, setPages] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");
  const [formInputs, setFormInputs] = useState({
    name: "",
    title: "",

    description: "",
    created_for: "",
    pages: "",
    button_text: "",
    button_link: "",
    status: "true",
    image: "",
    order: "",
    type: 2,

    percentage: "",
    color: "",
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    handleFormSubmit(image);
    setImage("");
    // console.log(image);
    setShow(false);
  }
  const handleFormSubmit = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = "";
 
    let postData = {
      title: formInputs.title,

      percentage: formInputs.percentage,
      color: formInputs.color,

      status: formInputs.status,
      order: formInputs.order,

      page_id: Array.isArray(formInputs.pages)
        ? formInputs.pages
        : formInputs.pages,
    };

    console.log("postData ____postData", postData);
    setIsLoading(true);
    const result = await _edit_course_rating(id, postData);
    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getTestimonialDetail = async () => {
    setIsLoading(true);
    const response = await _detail_course_rating(id);

    console.log("_detail_course_rating ____detail_course_rating", response);

    if (response.code === 200) {
      // const pageIds = response.slider.page_id.map((page) => page._id);
      const pageIds = response.course_rating.page_id;
      // console.log(data.instructor.first_name);
      // response.slider

      setFormInputs({
        ...formInputs,
        ...response.course_rating,

        pages: pageIds,
      });

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActivePages = async () => {
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getTestimonialDetail();
    fetchActivePages();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Course Rating">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            Edit Course Rating
          </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                />

<TextField
                fullWidth
                label="Order"
                value={formInputs.order}
                onChange={handleChange}
                name="order"
                required
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />

              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Percentage"
                  value={formInputs.percentage}
                  onChange={handleChange}
                  name="percentage"
                  required
                />

                <TextField
                  fullWidth
                  label="Color"
                  value={formInputs.color}
                  onChange={handleChange}
                  name="color"
                  required
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>

                {/* <TextField
                  fullWidth
                  label="Page"
                  select
                  value={formInputs.pages}
                  onChange={handleChange}
                  name="pages"
                  required
                  disabled
                  // SelectProps={{ multiple: true }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.id} value={page.id}>
                      {page.title}
                    </MenuItem>
                  ))}
                </TextField> */}
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default EditCourseRating;
