import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  Zoom,
  Tooltip,
  TableHead,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndtextEmptyToReturnHyphen,
  textEmptyToReturnHyphen,
  truncateString,
} from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import { width } from "@mui/system";

const BatchStudentDetail = ({ studentID, batchID }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [currency, setCurrency] = useState("Rs");

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };
  const myStyleHeaderCenter = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    textAlign: "center", // Align text in the center
  };
  const myStyleHeaderCenter2 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    textAlign: "center", // Align text in the center
    minWidth: "240px",
  };
  const myStyle7 = {
    textTransform: "capitalize",
    textAlign: "center", // Align text in the center
  };
  const myStyleHeader2 = {
    // textTransform: "capitalize",
    fontWeight: 800,
    fontSize: "1.3rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
    textAlign: "center", // Align text in the center
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",
    student_status: "",
    education: "",
    photo: "",
    batches: [],
    batch_fee: [],
    student_vouchers: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",
    discount: 0,

    registration_fee_discount: 0,
    fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount_reason: "",

    fee_status: "all_paid",
    batch_name: "",
    registration_voucher: null,

    freeze_status_reason: "",
  });
  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(studentID);
    console.log("response response detailStudent detail __detail", response);
    if (response.code === 200) {
      setAdditionalFields(response.student.additional_numbers);

      // setCourses(response.student.courses);
      // const courseIds = response.student.courses.map((course) => course._id);

      // const batchIds = response.student.batch_id.map((batch) => batch._id);

      let batchIds = "";

      if (response.student.batch) {
        // batchIds = response.student.batch._id;
      }

      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];
      let defaultDate = new Date();
      if (response.student.date_of_birth) {
        defaultDate = new Date(response.student.date_of_birth);
      }
      // console.log('defaultDate',defaultDate);
      //  if(response.student.date_of_birth ==="undefined"){
      //   console.log('defaultDate Date of birth is undefined  ');
      //  }else {

      //   console.log('defaultDate Date of birth is defined');
      //  }

      // const additionalFieldsFromBatch = response.student.decided_fee.map(
      //   (fields, index) => ({
      //     fee: fields.fee,
      //     month: fields.month,
      //     fee_status: fields.fee_status,
      //     // pending_fee: fields.pending_fee?fields.pending_fee:null,
      //     pending_fee: fields.pending_fee ? fields.pending_fee : 0,
      //     // pending_fee: fields.pending_fee,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      console.log("batchID _test", batchID);
      const filteredVoucherArray = response.student_vouchers.filter(
        (item) => item.batch?._id === batchID
      );
      const filteredBatchArray = response.student_batch.filter(
        (item) => item._id === batchID
      );
      let filteredBatchObj =
        filteredBatchArray.length > 0 ? filteredBatchArray[0] : [];
      console.log("filteredBatchArray _test", filteredBatchArray);
      console.log("filteredBatchObj _test", filteredBatchArray);

      setFormInputs({
        ...response.student,
        name: response.student.name,
        father_name: response.student.father_name,
        email: response.student.email,
        gender: response.student.gender,
        // student_id: response.student.student_id,
        address: response.student.address,
        contact_number: response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,

        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        time_preferences: response.student.time_preference
          ? response.student.time_preference._id
          : "",

        date_of_birth: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        dob: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        // dob: defaultDate.toISOString().split("T")[0],

        discount: response.student.discount,
        // fee_status:
        //   response.student_batch.fee_status === 1
        //     ? "All Paid"
        //     : response.student_batch.fee_status === 2
        //     ? "Monthly"
        //     : response.student_batch.fee_status === 3
        //     ? "Is Free"
        //     : "Pending",
        fee_status: filteredBatchObj
          ? filteredBatchObj.student_info.fee_status
          : 0,

        registration_voucher: filteredBatchObj
          ? filteredBatchObj.student_info.registration_voucher
          : null,

        batch_fee: filteredBatchObj
          ? filteredBatchObj.student_info.batch_fee
          : [],
        batch_name: filteredBatchObj ? filteredBatchObj.name : "",

        // batch_fee: response.student_batch.batch_fee,
        // student_vouchers: response.student_vouchers,
        student_vouchers: filteredVoucherArray,

        registration_fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount
          : 0,
        registration_fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount_reason
          : " _ _ ",
        fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount
          : 0,
        fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount_reason
          : " _ _ ",

        student_status: filteredBatchObj
          ? filteredBatchObj.student_info.student_status
          : 0,

        freeze_status_reason: filteredBatchObj
          ? filteredBatchObj.student_info.freeze_status_reason
          : " _ _ ",

        createdAt: response.student.createdAt,
        updatedAt: response.student.updatedAt,
      });

      // ["is_free", "installments", "all_paid"]= [3,2,1]

      // if (response.student.profile_image) {
      //   setImage(response.student.profile_image);
      //   setShow(true);
      // }
      //   if (batchIds && batchIds.length > 0) {
      //     fetchActiveBatches(batchIds);
      //   } else {
      //     fetchActiveBatches();
      //   }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh', // Optional to center vertically in the viewport
  };
  return (
    <Page title="Batch Student Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <TableContainer component={Paper}>
          <div className="container">
            <div className="row mt-3">
              <div className="col-12">
                <Accordion
                  className="accordian"
                  sx={{
                    boxShadow: "none",
                    borderBottom: "1px solid rgb(229, 229, 229)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      Detail
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table
                      sx={{
                        minWidth: "100%",
                        // paddingBottom: "30px",
                        // marginTop: "30px",
                        // marginBottom: "30px",
                        border: "1px solid #f1f3f4", // Set the border style here
                      }}
                      aria-label="simple table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell align="left" style={myStyleHeader}>
                            Name
                          </TableCell>
                          <TableCell align="left">
                            {capitalizeFirstLetter(formInputs.name)}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {" "}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="left" style={myStyleHeader}>
                            Contact Number
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            +92{formInputs.contact_number}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {" "}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left" style={myStyleHeader}>
                            Gender
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {formInputs.gender == 1 ? "Male" : "Female"}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {" "}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>

          {formInputs.student_vouchers.length ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <Accordion
                      className="accordian"
                      sx={{
                        boxShadow: "none",
                        borderBottom: "1px solid rgb(229, 229, 229)",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="subtitle1" fontWeight="bold">
                          {capitalizeFirstLetter(formInputs.batch_name)}{" "}
                          (Vouchers)
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table
                          sx={{
                            minWidth: "100%",
                            // paddingBottom: "30px",
                            // marginTop: "30px",
                            // marginBottom: "30px",
                            border: "1px solid #f1f3f4", // Set the border style here
                            borderTop: "none",
                            borderBottom: "none",
                          }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell style={myStyleHeader}>Title</TableCell>
                              <TableCell style={myStyleHeader}>Type</TableCell>
                              <TableCell style={myStyleHeader}>Fee</TableCell>
                              <TableCell style={myStyleHeader}>
                                Status
                              </TableCell>
                              <TableCell style={myStyleHeader}>
                                Fee Discount
                              </TableCell>
                              <TableCell style={myStyleHeader}>
                                Additional Discount
                              </TableCell>
                              <TableCell style={myStyleHeader}>
                                Additional Discount Reason{" "}
                              </TableCell>

                              <TableCell style={myStyleHeader}>
                                Fee After Discount
                              </TableCell>

                              <TableCell style={myStyleHeader}>Paid</TableCell>

                              <TableCell style={myStyleHeader}>
                                Pending
                              </TableCell>

                              <TableCell style={myStyleHeader}>
                                Refund Date
                              </TableCell>

                              <TableCell style={myStyleHeader}>
                                Refund reason
                              </TableCell>

                              {/* <TableCell style={myStyle}>Registration Discount</TableCell>
                <TableCell style={myStyle}>Fee Discount</TableCell> */}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {formInputs.student_vouchers.map(
                              (voucher, index) => (
                                <TableRow key={index}>
                                  <TableCell align="left" style={myStyle2}>
                                    #{index + 1} {voucher.fee_title}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle2}>
                                    {/* {voucher.voucher_type == 1 ? " Registration " : " Monthly "}{" "} */}

                                    <Label
                                      variant="outlined"
                                      color={
                                        ((voucher.voucher_type === 1 ||
                                          voucher.voucher_type === 2) &&
                                          "info") ||
                                        "warning"
                                      }
                                    >
                                      {voucher.voucher_type === 1
                                        ? "Registration"
                                        : voucher.voucher_type === 2
                                        ? "Monthly"
                                        : "Custom"}
                                    </Label>
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {voucher.fee} {currency}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    <Label
                                      variant="outlined"
                                      color={
                                        voucher.voucher_status === 1
                                          ? "warning"
                                          : voucher.voucher_status === 3
                                          ? "warning"
                                          : voucher.voucher_status === 2
                                          ? "success"
                                          : "primary"
                                      }
                                    >
                                      {voucher.voucher_status === 1
                                        ? "Pending"
                                        : voucher.voucher_status === 3
                                        ? "Due"
                                        : voucher.voucher_status === 2
                                        ? "Paid"
                                        : voucher.voucher_status === 4
                                        ? "Refund"
                                        : "Not Set"}
                                    </Label>

                                    {/* {voucher.voucher_status == 1 ? "Pending" : "Paid"})  */}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {voucher.fee_discount} {currency}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {voucher.additional_discount} {currency}
                                  </TableCell>
                                  <TableCell align="left" style={myStyle}>
                                    {voucher.additional_discount_reason}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {voucher.fee_after_discount}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {voucher.paid_fee}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {voucher.pending_fee}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {voucher.voucher_refund_date
                                      ? formatDateTimeWithOutZone(
                                          voucher.voucher_refund_date
                                        )
                                      : " _ _ "}
                                  </TableCell>

                                  <TableCell align="left" style={myStyle}>
                                    {textEmptyToReturnHyphen(
                                      voucher.voucher_refund_reason
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            )}

                            {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.status ? "Active" : "Inactive"}
                  </TableCell>
                </TableRow> */}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="container">
            <div className="row">
              <div className="col-12">
                <Accordion
                  className="accordian"
                  sx={{
                    boxShadow: "none",
                    borderBottom: "1px solid rgb(229, 229, 229)",
                  }}
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      {capitalizeFirstLetter(formInputs.batch_name)} (Fee)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {formInputs.fee_status !== 3 ? (
                      <Table
                        sx={{
                          minWidth: "100%",
                          // paddingBottom: "30px",
                          // marginTop: "30px",
                          // marginBottom: "30px",
                          border: "1px solid #f1f3f4", // Set the border style here
                        }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell style={myStyleHeader}>#</TableCell>
                            <TableCell style={myStyleHeaderCenter}>
                              Fee Title
                            </TableCell>
                            <TableCell style={myStyleHeaderCenter}>
                              Fee Amount
                            </TableCell>
                            <TableCell style={myStyleHeaderCenter}>
                              Fee Discount
                            </TableCell>
                            <TableCell style={myStyleHeaderCenter}>
                              Fee After Discount
                            </TableCell>
                            <TableCell style={myStyleHeaderCenter}>
                              Fee Discount Reason
                            </TableCell>

                            <TableCell style={myStyleHeaderCenter2}>
                              Voucher
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formInputs.registration_voucher &&
                          formInputs.registration_voucher !== null ? (
                            <TableRow>
                              <TableCell align="left" style={myStyle}>
                                1
                              </TableCell>
                              <TableCell align="left" style={myStyle7}>
                                Registration
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                {formInputs.registration_voucher?.fee}{" "}
                                {currency}
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                _ _
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                _ _
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                _ _
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                _ _
                              </TableCell>
                            </TableRow>
                          ) : null}

                          {formInputs.batch_fee.map((fee, index) => (
                            <TableRow key={index}>
                              <TableCell align="left" style={myStyle}>
                                {  (formInputs.registration_voucher &&
                                formInputs.registration_voucher !== null)
                                  ? index + 2
                                  : index + 1 }
                              </TableCell>
                              <TableCell align="left" style={myStyle7}>
                                {fee.fee_title}
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                {fee.fee} {currency}
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                {fee.fee_discount}
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                {fee.fee_after_discount}
                              </TableCell>

                              <TableCell align="left" style={myStyle7}>
                                {capitalizeFirstLetterAndtextEmptyToReturnHyphen(
                                  fee.fee_discount_reason
                                )}
                              </TableCell>

                              <TableCell align="right" style={myStyle}>
                                {fee.is_voucher_created === false ? (
                                  // ? " Voucher Not Created"
                                  <RouterLink
                                    // to={`/vouchers/add-voucher/${row.id}`}
                                    to={`/vouchers/add-voucher/?batch_id=${fee.batch._id}&fee_id=${fee.fee_id}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                  >
                                    <Button variant="outlined">
                                      <Iconify
                                        icon="material-symbols:add"
                                        width={18}
                                        height={18}
                                      />
                                      Generate Voucher
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </Button>
                                  </RouterLink>
                                ) : (
                                  <Button variant="outlined" color="success">
                                    Voucher Generated &nbsp;
                                    <Iconify
                                      icon="fluent-mdl2:completed-solid"
                                      width={24}
                                      height={24}
                                    />
                                  </Button>

                                  // <Typography variant="body1" style={{ color: "green" }}>
                                  //   ( Voucher Created )
                                  // </Typography>

                                  // "( Voucher Created )"
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      ""
                    )}
                    <Table
                      sx={{
                        minWidth: "100%",
                        // paddingBottom: "30px",
                        // marginTop: "30px",
                        // marginBottom: "30px",
                        border: "1px solid #f1f3f4", // Set the border style here
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          {/* <TableCell style={myStyleHeader}>#</TableCell> */}
                          <TableCell style={myStyleHeaderCenter}>
                            Fee Status
                          </TableCell>
                          <TableCell style={myStyleHeaderCenter}>
                            Status
                          </TableCell>

                          {formInputs.student_status === 2 &&
                          formInputs.freeze_status_reason !== null ? (
                            <TableCell style={myStyleHeaderCenter}>
                              Freeze Status Reason
                            </TableCell>
                          ) : (
                            ""
                          )}

                          
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell align="left" style={myStyle7}>
                            <Label
                              variant="outlined"
                              color={
                                (formInputs.fee_status === 0 && "warning") ||
                                (formInputs.fee_status === 1 && "success") ||
                                "info"
                              }
                            >
                              {formInputs.fee_status === 0
                                ? "Pending"
                                : formInputs.fee_status === 1
                                ? "All Paid"
                                : formInputs.fee_status === 2
                                ? "Monthly"
                                : formInputs.fee_status === 3
                                ? "Free"
                                : "Not Set"}
                            </Label>
                          </TableCell>

                          <TableCell align="left" style={myStyle7}>
                            <Label
                              variant="outlined"
                              color={
                                (formInputs.student_status === 2 && "info") ||
                                (formInputs.student_status === 3 && "error") ||
                                (formInputs.student_status === 1 &&
                                  "success") ||
                                "info"
                              }
                            >
                              {formInputs.student_status === 1
                                ? "Active"
                                : formInputs.student_status === 3
                                ? "Inactive"
                                : formInputs.student_status === 2
                                ? "Freeze"
                                : "Not Set"}
                            </Label>
                          </TableCell>

                          {formInputs.student_status === 2 &&
                          formInputs.freeze_status_reason !== null ? (
                            <TableCell align="left" style={myStyle7}>
                              {formInputs.freeze_status_reason}
                            </TableCell>
                          ) : (
                            ""
                          )}

                           
                        </TableRow>
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>

        </TableContainer>
      </Grid>
    </Page>
  );
};

export default BatchStudentDetail;
