import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../components/Iconify";
import { useSnackbar } from "notistack";
import { _login_by_contact_number, login } from "src/DAL/auth";

// ----------------------------------------------------------------------
import { useAppContext } from "src/hooks";
import { CircularLoader } from "src/components";

export default function LoginForm() {
  const { _set_user_profile } = useAppContext();

  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    contact_number: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange2 = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    const { target } = event;
    // Your other logic for handling the change...

    // If you want to prevent pasting non-numeric characters
    if (!/^\d*$/.test(value)) {
      // If the input contains non-numeric characters, clear the value
      // setFormInputs({ ...formInputs, [target.name]: target.value });
      event.target.value = value.replace(/\D/g, '');
      event.preventDefault();
    }else{
      setFormInputs({ ...formInputs, [target.name]: value });
    }
 
    // inputProps={{
    //   pattern: "[0-9]*", // Only allow numeric characters
    //   maxLength: 10, // Limit the input to 10 characters
    //   title: "Please enter a valid 10-digit phone number",
    // }}
    // onKeyPress={(event) => {
    //   if (!/[0-9]/.test(event.key)) {
    //     event.preventDefault();
    //   }
    // }}

  };


  const handlePaste = (event) => {
    // Access clipboard data
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text');

    // If the pasted text contains non-numeric characters, prevent the paste
    if (!/^\d*$/.test(pastedText)) {
      event.preventDefault();
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      contact_number: formInputs.contact_number,
      password: formInputs.password.trim(),
      type: 2,
    };

    // const data = new FormData();
    // console.log(formInputs.email.trim());
    // data.append("email", formInputs.email.trim());
    // data.append("password", formInputs.password.trim());
    // data.append("type", 0);
    setIsLoading(true);
    const result = await _login_by_contact_number(obj);
    console.log(
      "result login ___login __test ___login_by_contact_number",
      result
    );
    if (result.code === 200) {
      localStorage.setItem("token", result.token);

      // user.role.privilege
      const user = {
        ...result.user,
        first_name: result.user.first_name,
        last_name: result.user.last_name,
        email: result.user.email,
        // email: result.user.user_id.email,
        image: result.user.image,
        type: result.user.type,
        role: result.user.role,
        privilege: result.user.role.privilege,
      };

      console.log("JSON.stringify(user)", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));
      const storedObjectString = localStorage.getItem("user");
      const storedObject = JSON.parse(storedObjectString);
      _set_user_profile(storedObject);
      navigate("/dashboard");
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  function isNumeric(str) {
    return /^\d+$/.test(str);
  }

  useEffect(() => {
    if (!isNumeric(formInputs.contact_number)) {
      // console.log("The string contains non-numeric characters.");
      setFormInputs({ ...formInputs, contact_number: "" });
    }
  }, []);

  useEffect(() => {
    // const handleAutofill = () => {
    //   // Check for autofill and take appropriate action
    //   // For example, clear the field and set the desired value
    //   setFormInputs({ ...formInputs, contact_number: " " });
    // };
    // document.addEventListener('animationstart', handleAutofill);
    // return () => {
    //   document.removeEventListener('animationstart', handleAutofill);
    // };
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      {" "}
      <Typography variant="h4" gutterBottom>
        Sign in As Instructor
      </Typography>
      <Typography sx={{ color: "text.secondary", mb: 7 }}>
        Enter your details below.
      </Typography>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
        <TextField
          value={formInputs.contact_number}
          onChange={handleChange}
          onPaste={handlePaste}
          name="contact_number"
          required
          fullWidth
          // type="number"
          // autoComplete="username"
          // autoComplete="contact-number"
          // type="email"
          label="Contact Number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+92</InputAdornment>
            ),
          }}
          inputProps={{
            pattern: "[0-9]*", // Only allow numeric characters
            maxLength: 10, // Limit the input to 10 characters
            title: "Please enter a valid 10-digit phone number",
          }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />

        <TextField
          value={formInputs.password}
          onChange={handleChange2}
          fullWidth
          name="password"
          required
          autoComplete="new-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <Link
          component={RouterLink}
          variant="subtitle2"
          to="/forgot-password"
          underline="hover"
        >
          Forgot password?
        </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Login
        </LoadingButton>
      </form>
      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        {/* Don’t have an account?{" "} */}
        Sign in As &nbsp;
        <Link variant="subtitle2" component={RouterLink} to="/login">
          Admin
        </Link>{" "}
        &nbsp;{" "}
        <Link variant="subtitle2" component={RouterLink} to="/login-management">
        Management
        </Link>
      </Typography>
    </>
  );
}
