import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  Tooltip,
  Zoom,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import {
  _update_batch_status,
  _update_student_status_from_batch,
  listActiveBatch,
} from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import {
  capitalizeFirstLetter,
  htmlReturnFromCKEditor,
  truncateString,
} from "src/utils/formatText";
import {
  _change_student_assignment_status,
  _update_student_give_marks,
} from "src/DAL/assignment";
import { formatDateTimeWithOutZone } from "src/utils";
import { formatDateWithDay } from "src/utils/formatTime";
import CustomInputPaperView from "src/components/GeneralComponents/CustomInputPaperView";
import CustomInputPaperViewFullLength from "src/components/GeneralComponents/CustomInputPaperViewFullLength";
import { getFileIcon } from "src/utils/formatOther";

const AssignmentGiveMarks = ({
  studentID,
  batchID,
  handleCloseModal,
  Students,
  onStudentsUpdate,
  assignmentData,
  selectedRowStudent,
  students,
  setStudents,
}) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  // const [invoiceLink, setInvoiceLink] = useState(
  //   selectedRowStudent.assignment_url &&
  //     selectedRowStudent.assignment_url !== ""
  //     ? s3baseUrl + selectedRowStudent.assignment_url
  //     : ""
  // );

  // const modifiedFiles = selectedRowStudent.assignment_url.map(
  //   ({ _id, ...rest }) => rest
  // );
  const [invoiceLink, setInvoiceLink] = useState(
    selectedRowStudent.assignment_url
  );

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };
  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 800,
    fontSize: "1.3rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    batch_fee: [],
    student_vouchers: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",
    discount: 0,

    registration_fee_discount: 0,
    fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount_reason: "",

    fee_status: "all_paid",
    batch_name: "",
    student_status: 1,
    give_marks: selectedRowStudent ? selectedRowStudent.obtained_marks : "",
    title: assignmentData?.title,
    total_marks: assignmentData?.total_marks,
    dead_line: assignmentData?.dead_line,
    assignment_status: selectedRowStudent.assignment_status,
    student_note: selectedRowStudent?.student_note,
    assignment_submission_time: selectedRowStudent?.assignment_submission_time,
    assignment_url: selectedRowStudent?.assignment_url,
  });
  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleDownloadClick = () => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = invoiceLink;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function isValidSubmissionDataShow() {
    // if(formInputs.assignment_submission_time &&
    //   formInputs.assignment_submission_time !== null && formInputs.assignment_submission_time !== ""){
    //     // moment(formInputs.assignment_submission_time, moment.ISO_8601, true).isValid()

    if (
      moment(
        formInputs.assignment_submission_time,
        moment.ISO_8601,
        true
      ).isValid()
    ) {
      return true;
    }
    // selectedRowStudent.assignment_url &&
    // selectedRowStudent.assignment_url !== ""
    // ? s3baseUrl + selectedRowStudent.assignment_url
    // : ""
    // formInputs.dead_line
    // "assignment_url": "",
    // "assignment_status": 3,
    // "obtained_marks": "22",
    // "assignment_submission_time": null,
    // "student_note": ""

    // student_status: 1,
    // give_marks: selectedRowStudent ? selectedRowStudent.obtained_marks : "",
    // title: assignmentData?.title,
    // total_marks: assignmentData?.total_marks,
    // dead_line: assignmentData?.dead_line,
    // assignment_status: selectedRowStudent.assignment_status,
    // student_note: selectedRowStudent?.student_note,
    // assignment_submission_time: selectedRowStudent?.assignment_submission_time,
    // assignment_url: selectedRowStudent?.assignment_url,

    return false;
  }

  const getStudentsForAssignmentPosition = () => {
    switch (selectedRowStudent.assignment_status) {
      case 1:
        return students.unsubmitted_assignment_students;
      case 2:
        return students.submitted_assignment_students;
      case 3:
        return students.approved_assignment_students;
      default:
        return [];
    }
  };

  const percentageMarks = (marks) => {
    let totalMarks = assignmentData?.total_marks;
    let obtainedMarks = marks;

    if (totalMarks >= obtainedMarks) {
      return (obtainedMarks / totalMarks) * 100;
    } else {
      return null;
    }
  };

  const percentageMarksToFailOrPass = (marks) => {
    let totalMarks = assignmentData?.total_marks;
    let obtainedMarks = marks;

    if (totalMarks >= obtainedMarks) {
      if ((obtainedMarks / totalMarks) * 100 >= 33) {
        return 2;
      } else {
        return 3;
      }
    } else {
      return null;
    }
  };

  const handleSubmit = async (e) => {
    console.log("_______Test_______ selectedRowStudent", selectedRowStudent);
    e.preventDefault();
    let error = "";
    let totalMarks = assignmentData?.total_marks;
    // student_status

    let PostData = {
      assignment_id: batchID,
      student_id: studentID,
      assignment_status: formInputs.assignment_status,
      obtained_marks: totalMarks,
      // obtained_marks: formInputs.give_marks,
    };

    if (
      formInputs.assignment_status === 3 &&
      assignmentData.assignment_type !== 2
    ) {
      PostData = {
        assignment_id: batchID,
        student_id: studentID,
        assignment_status: formInputs.assignment_status,
        obtained_marks: formInputs.give_marks,
      };
    }
    setIsLoading(true);
    const result = await _change_student_assignment_status(PostData);

    //   {
    //     "assignment_id": "6582ad827eb5460340476401",
    //     "student_id": "65814278b3f5d12ff4fa7f0e",
    //     "assignment_status": 2
    //     // "obtained_marks": 10
    // }

    if (assignmentData.assignment_type === 2) {
      if (result.code === 200) {
        setIsLoading(false);
        //   navigate("/batches");
        //   navigate(-1);
        handleCloseModal();

        if (
          selectedRowStudent.assignment_status !== formInputs.assignment_status
        ) {
          console.log(
            "_______Test_______ selectedRowStudent",
            selectedRowStudent
          );
          const updatedStudents = getStudentsForAssignmentPosition().filter(
            (students) => students._id !== selectedRowStudent._id
          );

          let updatedRow = {
            ...selectedRowStudent, // Spread the existing values
            assignment_status: formInputs.assignment_status, // Change the assignment_status to a new value (e.g., 1)
            // Add or modify other properties if needed
          };

          // pending
          if (
            selectedRowStudent.assignment_status === 1 &&
            formInputs.assignment_status === 3
          ) {
            updatedRow = {
              ...selectedRowStudent, // Spread the existing values
              assignment_status: formInputs.assignment_status, // Change the assignment_status to a new value (e.g., 1)
              obtained_marks: totalMarks,
              percentage: percentageMarks(totalMarks)
                ? percentageMarks(totalMarks)
                : "",
              result_status: percentageMarksToFailOrPass(totalMarks)
                ? percentageMarksToFailOrPass(totalMarks)
                : "",
            };

            setStudents((prevStudents) => ({
              ...prevStudents,
              unsubmitted_assignment_students: updatedStudents,
              approved_assignment_students: [
                ...prevStudents.approved_assignment_students,
                updatedRow,
              ],
            }));
          } else if (
            selectedRowStudent.assignment_status === 1 &&
            formInputs.assignment_status === 2
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              unsubmitted_assignment_students: updatedStudents,
              submitted_assignment_students: [
                ...prevStudents.submitted_assignment_students,
                updatedRow,
              ],
            }));
          }
          // pending

          // submitted
          if (
            selectedRowStudent.assignment_status === 2 &&
            formInputs.assignment_status === 3
          ) {
            updatedRow = {
              ...selectedRowStudent, // Spread the existing values
              assignment_status: formInputs.assignment_status, // Change the assignment_status to a new value (e.g., 1)
              obtained_marks: totalMarks,
              percentage: percentageMarks(totalMarks)
                ? percentageMarks(totalMarks)
                : "",
              result_status: percentageMarksToFailOrPass(totalMarks)
                ? percentageMarksToFailOrPass(totalMarks)
                : "",
            };

            setStudents((prevStudents) => ({
              ...prevStudents,
              submitted_assignment_students: updatedStudents,
              approved_assignment_students: [
                ...prevStudents.approved_assignment_students,
                updatedRow,
              ],
            }));
          } else if (
            selectedRowStudent.assignment_status === 2 &&
            formInputs.assignment_status === 1
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              submitted_assignment_students: updatedStudents,
              unsubmitted_assignment_students: [
                ...prevStudents.unsubmitted_assignment_students,
                updatedRow,
              ],
            }));
          }
          // submitted

          // Approved
          if (
            selectedRowStudent.assignment_status === 3 &&
            formInputs.assignment_status === 2
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              approved_assignment_students: updatedStudents,
              submitted_assignment_students: [
                ...prevStudents.submitted_assignment_students,
                updatedRow,
              ],
            }));
          } else if (
            selectedRowStudent.assignment_status === 3 &&
            formInputs.assignment_status === 1
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              approved_assignment_students: updatedStudents,
              unsubmitted_assignment_students: [
                ...prevStudents.unsubmitted_assignment_students,
                updatedRow,
              ],
            }));
          }
          // Approved
        }

        if (
          selectedRowStudent.assignment_status === 3 &&
          formInputs.assignment_status === 3
        ) {
          const updatedStudents = getStudentsForAssignmentPosition().map(
            (student) => {
              if (student._id === selectedRowStudent._id) {
                // If the batch id matches, create a new object with batch_status set to 1
                return {
                  ...student,
                  obtained_marks: totalMarks,
                  percentage: percentageMarks(totalMarks)
                    ? percentageMarks(totalMarks)
                    : "",
                  result_status: percentageMarksToFailOrPass(
                    totalMarks
                  )
                    ? percentageMarksToFailOrPass(totalMarks)
                    : "",
                };
              } else {
                // For other objects, return them as they are
                return student;
              }
            }
          );

          setStudents((prevStudents) => ({
            ...prevStudents,
            approved_assignment_students: updatedStudents,
          }));

          // onBatchesUpdate(updatedBatches);
        }

        enqueueSnackbar("Update Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      if (result.code === 200) {
        setIsLoading(false);
        //   navigate("/batches");
        //   navigate(-1);
        handleCloseModal();

        if (
          selectedRowStudent.assignment_status !== formInputs.assignment_status
        ) {
          console.log(
            "_______Test_______ selectedRowStudent",
            selectedRowStudent
          );
          const updatedStudents = getStudentsForAssignmentPosition().filter(
            (students) => students._id !== selectedRowStudent._id
          );

          let updatedRow = {
            ...selectedRowStudent, // Spread the existing values
            assignment_status: formInputs.assignment_status, // Change the assignment_status to a new value (e.g., 1)
            // Add or modify other properties if needed
          };

          // pending
          if (
            selectedRowStudent.assignment_status === 1 &&
            formInputs.assignment_status === 3
          ) {
            updatedRow = {
              ...selectedRowStudent, // Spread the existing values
              assignment_status: formInputs.assignment_status, // Change the assignment_status to a new value (e.g., 1)
              obtained_marks: formInputs.give_marks,
              percentage: percentageMarks(formInputs.give_marks)
                ? percentageMarks(formInputs.give_marks)
                : "",
              result_status: percentageMarksToFailOrPass(formInputs.give_marks)
                ? percentageMarksToFailOrPass(formInputs.give_marks)
                : "",
            };

            setStudents((prevStudents) => ({
              ...prevStudents,
              unsubmitted_assignment_students: updatedStudents,
              approved_assignment_students: [
                ...prevStudents.approved_assignment_students,
                updatedRow,
              ],
            }));
          } else if (
            selectedRowStudent.assignment_status === 1 &&
            formInputs.assignment_status === 2
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              unsubmitted_assignment_students: updatedStudents,
              submitted_assignment_students: [
                ...prevStudents.submitted_assignment_students,
                updatedRow,
              ],
            }));
          }
          // pending

          // submitted
          if (
            selectedRowStudent.assignment_status === 2 &&
            formInputs.assignment_status === 3
          ) {
            updatedRow = {
              ...selectedRowStudent, // Spread the existing values
              assignment_status: formInputs.assignment_status, // Change the assignment_status to a new value (e.g., 1)
              obtained_marks: formInputs.give_marks,
              percentage: percentageMarks(formInputs.give_marks)
                ? percentageMarks(formInputs.give_marks)
                : "",
              result_status: percentageMarksToFailOrPass(formInputs.give_marks)
                ? percentageMarksToFailOrPass(formInputs.give_marks)
                : "",
            };

            setStudents((prevStudents) => ({
              ...prevStudents,
              submitted_assignment_students: updatedStudents,
              approved_assignment_students: [
                ...prevStudents.approved_assignment_students,
                updatedRow,
              ],
            }));
          } else if (
            selectedRowStudent.assignment_status === 2 &&
            formInputs.assignment_status === 1
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              submitted_assignment_students: updatedStudents,
              unsubmitted_assignment_students: [
                ...prevStudents.unsubmitted_assignment_students,
                updatedRow,
              ],
            }));
          }
          // submitted

          // Approved
          if (
            selectedRowStudent.assignment_status === 3 &&
            formInputs.assignment_status === 2
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              approved_assignment_students: updatedStudents,
              submitted_assignment_students: [
                ...prevStudents.submitted_assignment_students,
                updatedRow,
              ],
            }));
          } else if (
            selectedRowStudent.assignment_status === 3 &&
            formInputs.assignment_status === 1
          ) {
            setStudents((prevStudents) => ({
              ...prevStudents,
              approved_assignment_students: updatedStudents,
              unsubmitted_assignment_students: [
                ...prevStudents.unsubmitted_assignment_students,
                updatedRow,
              ],
            }));
          }
          // Approved
        }

        if (
          selectedRowStudent.assignment_status === 3 &&
          formInputs.assignment_status === 3
        ) {
          const updatedStudents = getStudentsForAssignmentPosition().map(
            (student) => {
              if (student._id === selectedRowStudent._id) {
                // If the batch id matches, create a new object with batch_status set to 1
                return {
                  ...student,
                  obtained_marks: formInputs.give_marks,
                  percentage: percentageMarks(formInputs.give_marks)
                    ? percentageMarks(formInputs.give_marks)
                    : "",
                  result_status: percentageMarksToFailOrPass(
                    formInputs.give_marks
                  )
                    ? percentageMarksToFailOrPass(formInputs.give_marks)
                    : "",
                };
              } else {
                // For other objects, return them as they are
                return student;
              }
            }
          );

          setStudents((prevStudents) => ({
            ...prevStudents,
            approved_assignment_students: updatedStudents,
          }));

          // onBatchesUpdate(updatedBatches);
        }

        enqueueSnackbar("Update Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(studentID);
    console.log("response response detailStudent detail __detail", response);
    if (response.code === 200) {
      setAdditionalFields(response.student.additional_numbers);

      // setCourses(response.student.courses);
      // const courseIds = response.student.courses.map((course) => course._id);

      // const batchIds = response.student.batch_id.map((batch) => batch._id);

      let batchIds = "";

      if (response.student.batch) {
        batchIds = response.student.batch._id;
      }

      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];
      let defaultDate = new Date();
      if (response.student.date_of_birth) {
        defaultDate = new Date(response.student.date_of_birth);
      }

      console.log("batchID _test", batchID);
      const filteredVoucherArray = response.student_vouchers.filter(
        (item) => item.batch?._id === batchID
      );
      const filteredBatchArray = response.student_batch.filter(
        (item) => item._id === batchID
      );
      let filteredBatchObj =
        filteredBatchArray.length > 0 ? filteredBatchArray[0] : [];
      console.log("filteredBatchArray _test", filteredBatchArray);
      console.log("filteredBatchObj _test", filteredBatchArray);

      let StudentStatus = 1;
      if (filteredBatchObj) {
        if (filteredBatchObj.student_info.student_status) {
          StudentStatus = filteredBatchObj.student_info.student_status;
        }
      }

      setFormInputs((prevInputs) => ({
        ...prevInputs,

        name: response.student.name,
        father_name: response.student.father_name,
        email: response.student.email,
        gender: response.student.gender,
        // student_id: response.student.student_id,
        address: response.student.address,
        contact_number: response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,

        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        time_preferences: response.student.time_preference
          ? response.student.time_preference._id
          : "",

        date_of_birth: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        dob: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        // dob: defaultDate.toISOString().split("T")[0],

        discount: response.student.discount,
        fee_status:
          response.student_batch.fee_status === 1
            ? "All Paid"
            : response.student_batch.fee_status === 2
            ? "Monthly"
            : response.student_batch.fee_status === 3
            ? "Free"
            : "Pending",

        batch_fee: filteredBatchObj
          ? filteredBatchObj.student_info.batch_fee
          : [],
        batch_name: filteredBatchObj ? filteredBatchObj.name : "",

        // student_status: filteredBatchObj ? filteredBatchObj.student_info.student_status : 1,
        student_status: StudentStatus,

        // batch_fee: response.student_batch.batch_fee,
        // student_vouchers: response.student_vouchers,
        student_vouchers: filteredVoucherArray,

        registration_fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount
          : 0,
        registration_fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount_reason
          : " _ _ ",
        fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount
          : 0,
        fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount_reason
          : " _ _ ",

        createdAt: response.student.createdAt,
        updatedAt: response.student.updatedAt,
      }));

      // ["is_free", "installments", "all_paid"]= [3,2,1]

      // if (response.student.profile_image) {
      //   setImage(response.student.profile_image);
      //   setShow(true);
      // }
      //   if (batchIds && batchIds.length > 0) {
      //     fetchActiveBatches(batchIds);
      //   } else {
      //     fetchActiveBatches();
      //   }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    //   fetchStudentDetail();
    console.log("assignmentData   ___assignmentData", assignmentData);
    console.log("invoiceLink   ___invoiceLink", invoiceLink);
  }, [invoiceLink]);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh', // Optional to center vertically in the viewport
  };
  return (
    <Page title="Assignment Student Marks">
      <div className="container col-10 mt-4">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          sx={{ marginTop: "55px" }}
        >
          <div className="mt-5 mb-4">
            {/* Status :{" "}
            <Label
              variant="outlined"
              color={
                (selectedRowStudent.assignment_status === 1 && "warning") ||
                (selectedRowStudent.assignment_status === 3 && "success") ||
                "info"
              }
            >
              {selectedRowStudent.assignment_status === 1
                ? "Pending"
                : selectedRowStudent.assignment_status === 2
                ? "Submitted"
                : selectedRowStudent.assignment_status === 3
                ? "Approved"
                : "Not Set"}
            </Label> */}
          </div>

          <Grid container spacing={1} sx={{ marginLeft: "-4px" }}>
            <CustomInputPaperViewFullLength
              Label="Title"
              Name={capitalizeFirstLetter(formInputs.title)}
              // IconSet="ri:user-fill"
              IconSet="line-md:text-box"
              marginTopSet="20px"
            />
            <CustomInputPaperView
              Label="Dead Line"
              Name={formatDateWithDay(formInputs.dead_line)}
              // IconSet="ri:user-fill"
              IconSet="fluent-mdl2:date-time"
              marginTopSet="20px"
            />

            <CustomInputPaperView
              Label="Total Marks"
              Name={formInputs.total_marks}
              // IconSet="ri:user-fill"
              IconSet="fluent:book-number-16-filled"
              marginTopSet="20px"
            />
          </Grid>

          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={2} sx={{ paddingTop: "20px" }}>
              {isValidSubmissionDataShow() ? (
                <div className="col-12 mt-1 mb-0">
                  <h5 style={{ fontWeight: 600 }}>Detail</h5>{" "}
                  {/* <h5 style={{ fontWeight: 600 }}>Student Submission Detail</h5>{" "} */}
                  <div className="row">
                    {formInputs.assignment_submission_time &&
                    formInputs.assignment_submission_time !== null ? (
                      <div className="col-6">
                        <div className="views-lesson">
                          <div className="batch_info_heading">
                            Submission Date Time
                          </div>
                          <div className="batch_info_sub_text">
                            <div className=" ">
                              {formatDateTimeWithOutZone(
                                formInputs.assignment_submission_time
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-12 mt-3">
                      {" "}
                      {invoiceLink !== null && invoiceLink !== "" ? (
                        <div className="views-lesson">
                          <div className="batch_info_heading">
                            Attached File
                          </div>
                          <div className="batch_info_sub_text">
                            <div className=" ">
                              <div
                                className={`col-12 col-sm-12 `}
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {invoiceLink.map((filePath, index) => (
                                  <Box
                                    key={index}
                                    position="relative"
                                    display="inline-block"
                                    border="1px solid #cccccc55"
                                    p={1}
                                    //   mb={2} // Add margin bottom for spacing between files
                                    style={{ marginRight: "10px" }} // Adjust spacing between files
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      ({index + 1}) &nbsp;
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginRight: "5px", // Adjust spacing between icon and text
                                        }}
                                      >
                                        <a
                                          href={s3baseUrl + filePath.path}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {getFileIcon(
                                            filePath.name,
                                            filePath.path
                                          )}
                                        </a>
                                      </Box>
                                      <Box>
                                        <a
                                          href={s3baseUrl + filePath.path}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <Tooltip
                                            TransitionComponent={Zoom}
                                            title={
                                              filePath.name.length > 15
                                                ? capitalizeFirstLetter(
                                                    filePath.name
                                                  )
                                                : ""
                                            }
                                            arrow
                                          >
                                            {capitalizeFirstLetter(
                                              truncateString(filePath.name, 15)
                                            )}
                                          </Tooltip>
                                        </a>{" "}
                                        &nbsp;&nbsp;
                                      </Box>
                                    </div>
                                  </Box>
                                ))}
                              </div>

                              {/* {invoiceLink.map((filePath, index) => (
                                <Box
                                  key={index}
                                  position="relative"
                                  display="inline-block"
                                  border="2px solid #ccc"
                                  p={1}
                                  mb={1}
                                  mr={1}
                                >
                                  <Box>
                                    ({index + 1}){" "}
                                    <a
                                      href={s3baseUrl + filePath.path}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {filePath.name}
                                    </a>{" "}
                                    &nbsp;&nbsp;
                                  </Box>
                                </Box>
                              ))} */}

                              {/* <Button
                                variant="outlined"
                                color="info"
                                // startIcon={<GetAppIcon />}
                                onClick={handleDownloadClick}
                                size="small"
                                sx={{
                                  fontSize: "10px", // or any other valid CSS font size value
                                }}
                              >
                                Download
                              </Button> */}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {formInputs.student_note &&
                    formInputs.student_note !== "" &&
                    formInputs.student_note !== null ? (
                      <div className="views-lesson mt-2">
                        <div className="batch_info_heading">Student Note</div>
                        <div className="batch_info_sub_text">
                          <div className=" ">
                            {htmlReturnFromCKEditor(formInputs.student_note)}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {formInputs.assignment_status == 3 &&
                assignmentData.assignment_type !== 2 ? (
                  <TextField
                    fullWidth
                    label="Give Marks"
                    value={formInputs.give_marks}
                    onChange={handleChange}
                    name="give_marks"
                    required
                    // inputProps={{
                    //   pattern: "[A-Za-z]{3,}",
                    //   title:
                    //     "Please enter at least 3 letters with only alphabetical characters.",
                    // }}

                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      // maxLength: 10, // Limit the input to 10 characters
                      // title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                ) : (
                  ""
                )}

                <TextField
                  fullWidth
                  label="Assignment Status"
                  select
                  value={formInputs.assignment_status}
                  onChange={handleChange}
                  name="assignment_status"
                  // required
                >
                  <MenuItem value={1}>Pending</MenuItem>
                  <MenuItem value={2}>Submitted</MenuItem>
                  <MenuItem value={3}>Approved</MenuItem>
                  {/* PENDING = 1 SUBMITTED = 2 APPROVED =3 */}
                </TextField>
              </Stack>

              <Stack alignItems="left">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "info.main" }}
                >
                  {/* Please note that if you modify the student's status, its
                effect is only within that batch. */}
                </Typography>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AssignmentGiveMarks;
