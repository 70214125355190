import { filter } from "lodash";

import { useState, useEffect } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  Box,
  Tabs,
  Tab,
  Badge,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
  ListToolbar,
} from "src/components";
// import StudentListHead from "./components/StudentListHead";
// import StudentListToolbar from "./components/StudentListToolbar";
// import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

// import { listStudent } from "src/DAL/student";
import { listContact } from "src/DAL/contact";
import { UserListToolbar } from "src/pages/users";
import { useSnackbar } from "notistack";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import CustomTabPanel from "src/components/GeneralComponents/CustomTabPanel";
import { styled } from "@mui/material/styles";
import ContactTable from "./components/ContactTable";
// ----------------------------------------------------------------------
const MAX_CHARACTERS = 60;
const TABLE_HEAD = [
  { id: "first_name", label: "First Name", alignRight: false },
  { id: "last_name", label: "Last Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },

  { id: "courses", label: "Courses", alignRight: false },
  { id: "phone", label: "Phone#", alignRight: false },
  { id: "message", label: "Message", alignRight: false },

  { id: "" },
];

// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: -8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, query) {
  if (query) {
    return filter(array, (_user) =>
      (_user.first_name + " " + _user.last_name)
        .toLowerCase()
        .includes(query.toLowerCase())
    );
  } else {
    return [];
  }
}

export default function ContactList() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("first_name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [newContactCount, setNewContactCount] = useState(0);
  const [contact, setContact] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [expandedRows, setExpandedRows] = useState([]);
  // const toggleMessageVisibility = () => {
  //   setShowFullMessage(!showFullMessage);
  // };

  const moment = require("moment");
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleMessageVisibility = (rowId) => {
    console.log(rowId, "idddd");
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(rowId)) {
        return prevExpandedRows.filter((id) => id !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // const truncatedMessage = (message) => {
  //   if (!message) {
  //     return ""; // or any default value you prefer when message is undefined or null
  //   }

  //   return showFullMessage ? message : message.slice(0, MAX_CHARACTERS);
  // };
  const truncatedMessage = (message) => {
    if (!message) {
      return ""; // or any default value you prefer when message is undefined or null
    }

    if (showFullMessage) {
      return message;
    }

    if (message.length > MAX_CHARACTERS) {
      return message.slice(0, MAX_CHARACTERS) + "...";
    }

    return message;
  };
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderMessage = (message) => {
    // if (expanded) {
    //   return message;
    // } else {
    //   // Get the first two lines of the message
    //   const firstTwoLines = message.split("\n").slice(0, 2).join("\n");
    //   console.log(firstTwoLines, "two lines");
    //   return firstTwoLines;
    // }
    const lines = message.split("\n");
    let renderedMessage = "";

    if (lines.length <= 2) {
      renderedMessage = message;
    } else {
      for (let i = 0; i < 2; i++) {
        renderedMessage += lines[i] + "\n";
      }
      renderedMessage = renderedMessage.trim(); // Remove trailing newline character
    }
    console.log(renderedMessage, "two lines");
    return renderedMessage;
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contact.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(contact.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contact.length) : 0;

  const filteredContacts =
    filterName == "" ? [...contact] : applySortFilter(contact, filterName);

  const isUserNotFound = filteredContacts.length === 0;

  const SearchStudents = async (searchText) => {
    let response = [];
    // setIsLoading(true);
    let search_text = searchText;

    if (!search_text || search_text === "" || search_text === null) {
      search_text = "";
      response = await listContact();
    } else {
      response = await listContact({
        subject: search_text,
      });
    }

    // const response = await listStudentSearch(searchText);
    console.log("response  response  response _listContact Search", response);

    if (response.code === 200) {
      // setStudents(students.reverse());
      setNewContactCount(response.new_contact_count);
      setContact(response.contacts);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchContact();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      setIsLoading(true);
      SearchStudents(searchText);
    }
  };
  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchContact();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  function returnFormattedDateOrTimeFromDate(dateFromApi, type) {
    const dateString = dateFromApi;
    const parsedDate = moment(dateString);

    if (type === "time" || type === "Time") {
      // Format time as "02:53 PM"
      return parsedDate.format("hh:mm A");
    } else {
      // Format date as "August 24, 2023"
      return parsedDate.format("MMMM D, YYYY");
    }
  }

  const fetchContact = async () => {
    setIsLoading(true);
    const response = await listContact();
    console.log("response  response  response _listContact", response);
    if (response.code === 200) {
      setNewContactCount(response.new_contact_count);
      setContact(response.contacts);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    // fetchContact();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Contact">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
          mt={2}
        >
          <Typography variant="h4">Contact List</Typography>
          {/* <Button
            // onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Student
          </Button> */}
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
        >
          {/* <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            listName={"Contacts"}
          /> */}

          

          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Contact Us" {...a11yProps(0)} />

                <Tab
                    label={
                      <StyledBadge badgeContent={newContactCount} max={99} color="secondary">
                        New Inquiries
                      </StyledBadge>
                    }
                    {...a11yProps(1)}
                  />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
             <ContactTable newContactCount={newContactCount} setNewContactCount={setNewContactCount} type={1}/>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
            <ContactTable newContactCount={newContactCount} setNewContactCount={setNewContactCount} type={2}/>
            </CustomTabPanel>
          </Box>

 
        
          {/* </Scrollbar> */}

          {/* <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={contact.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>
    </Page>
  );
}
