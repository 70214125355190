import { filter } from "lodash";

import { useState, useEffect } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";

// mock

import TableHead from "@mui/material/TableHead";

import Paper from "@mui/material/Paper";

//
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import { _detail_collect_fee, _list_collect_fee } from "src/DAL/collect_fee";
import { detailBatch } from "src/DAL/batch";

// import BatchListHead from "./components/BatchListHead";
// import BatchListToolbar from "./components/BatchListToolbar";
// import BatchMoreMenu from "./components/BatchMoreMenu";
import StudentMoreMenu from "./components/StudentMoreMenu";
import moment from "moment";
import { useSnackbar } from "notistack";
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function DetailBatch() {
  const myStyle = {
    textTransform: "capitalize",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "left", // Add text alignment to center
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [firstRowData, setFirstRowData] = useState([]);
  const [lastRowData, setLastRowData] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [totalPaidFee, setTotalPaidFee] = useState("0");
  const [dueFee, setDueFee] = useState("0");
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "",
    order: "",
    courses: [],
  });

  const { id } = useParams();
  console.log("_ID _ID _ID", id);
  let batch_ID = id;
  const handleNav = () => {
    navigate(-1);
  };

  const handleNavigate = (_id) => {
    navigate(`/batches/move-student-to-batch/${_id}?batch_id=${id}`);
  };

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
    fetchDetailBatch();
  }

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedEndTime = endMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

    return formattedTime;
  }

  const fetchDetailBatch = async () => {
    setIsLoading(true);

    const response = await detailBatch(id);
    console.log("response detailBatch", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      // console.log("sss", data);
      // console.log(
      //   response.batch.courses.map((course) => course.title),
      //   "log"
      // );

      let courseIds = "";
      let courseTitles = "";

      if (response.batch.courses) {
        courseIds = response.batch.courses.map((course) => course._id);
        courseTitles = response.batch.courses.map((course) => course.title);
      }
      if (response.batch.course) {
        courseIds = response.batch.course._id;
        courseTitles = response.batch.course.title;
      }

 

      setFormInputs({
        name: response.batch.name,
        total_seats: response.batch.total_seats,
        order: response.batch.order,
        courses: courseIds,
        courseTitles: courseTitles,

        status: response.batch.status,

        student_count: response.batch.student_count,
        total_seats: response.batch.total_seats,
        available_seats: response.batch.available_seats,
        start_date_batch: formatDate(response.batch.start_date_batch),
        end_date_batch: formatDate(response.batch.end_date_batch),
        start_time_class: response.batch.start_time_class,
        end_time_class: response.batch.end_time_class,

        total_male_students: response.batch.total_male_students,
        total_female_students: response.batch.total_female_students,
        createdAt: formatDate(response.batch.createdAt),
        updatedAt: formatDate(response.batch.updatedAt),

        time: formatTime(
          response.batch.start_time_class,
          response.batch.end_time_class
        ),

        accumulative_revenue: response.batch.accumulative_revenue?response.batch.accumulative_revenue:[],
        fee_revenue: response.batch.fee_revenue?response.batch.fee_revenue:[],
        batch_revenue: response.batch.batch_revenue,
        total_regular_students: response.batch.total_regular_students,
        total_scholarship_students: response.batch.total_scholarship_students,

        accumulative_fee_revenue: response.batch.accumulative_fee_revenue,
        accumulative_registration_revenue:
          response.batch.accumulative_registration_revenue,

        total_collected_revenue: response.batch.total_collected_revenue,
        total_paid_registration_fee: response.batch.total_paid_registration_fee,
        total_pending_revenue: response.batch.total_pending_revenue,
      });

      // const students = response.batch.students.map((student, index) => ({
      //   id: student._id,
      //   name: student.name,
      //   contact_number: student.contact_number,
      //   number: index + 1,

      //   total_fee: student.total_fee,
      //   total_paid_fee: student.total_paid_fee,
      //   total_pending_fee: student.total_pending_fee,
      //   total_registration_fee: student.total_registration_fee,
      //   total_paid_registration_fee: student.total_paid_registration_fee,
      //   time: formatTime(
      //     student.time_preference?student.time_preference.start_time:"00:00",
      //     student.time_preference?student.time_preference.end_time:"00:00",
      //   ),
      //   // time: formatTime(
      //   //    "00:00",
      //   //    "00:00",
      //   // ),
      // }));

      const students = response.batch.students.map((student, index) => ({
        ...student, // Spread the original student object
        id: student._id, // Override the id property
        number: index + 1, // Override the number property
        time: formatTime(
          student.time_preference ? student.time_preference.start_time : '00:00',
          student.time_preference ? student.time_preference.end_time : '00:00',
        ), // Override the time property
      }));

      setStudents(students);
      // setStudents(response.batch.students);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailBatch();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Detail Batch">
      <Container maxWidth="xl">
        <div style={{ padding: "0 15px" }}>
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <div
            style={{
              textAlign: "center",
              padding: "0 15px",
              paddingTop: "20px",
            }}
          >
            <Typography variant="h5">
              <span style={{ fontWeight: "bolder" }}>Name : </span>{" "}
              {formInputs.name}
            </Typography>

            <Typography variant="h5">
              <span style={{ fontWeight: "bolder" }}>Course : </span>
              {formInputs.courseTitles}
            </Typography>
          </div>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={4.5}
            mt={2}
          >
            <div
              style={{
                textAlign: "left",
                padding: "0 15px",
                paddingTop: "20px",
              }}
            >
              {/* <Typography variant="h5" >
              <span  style={{ fontWeight: "bolder" }} >Name : </span> {formInputs.name}
              </Typography>

              <Typography variant="h5"  >
                <span  style={{ fontWeight: "bolder" }} >Course : </span>{formInputs.courseTitles}
             
              </Typography> */}

              {/* <Typography variant="span">{studentInfo.length > 0 && studentInfo[0].Instructor_name}</Typography> <br />
              <Typography variant="span"> {studentInfo.length > 0 && studentInfo[0].Instructor_number}</Typography> <br /> */}

              <Typography variant="p">
                <span style={{ fontWeight: "bolder" }}>Student Count : </span>{" "}
                {formInputs.student_count} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Total Male Students :{" "}
                </span>{" "}
                {formInputs.total_male_students} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Total Female Students :{" "}
                </span>{" "}
                {formInputs.total_female_students} <br />
                <span style={{ fontWeight: "bolder" }}>Status : </span>{" "}
                {formInputs.status ? "Active" : "Inactive"} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Total Seats :{" "}
                </span>{" "}
                {formInputs.total_seats} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Available Seats :{" "}
                </span>{" "}
                {formInputs.available_seats} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Total Regular Students :{" "}
                </span>{" "}
                {formInputs.total_regular_students} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Total Scholarship Students:{" "}
                </span>{" "}
                {formInputs.total_scholarship_students} <br />
              </Typography>
            </div>

           

            <div>
              <Typography variant="p">
                <span style={{ fontWeight: "bolder" }}>Time : </span>{" "}
                {formInputs.time} <br />
                <span style={{ fontWeight: "bolder" }}>Start Date : </span>{" "}
                {formInputs.start_date_batch} <br />
                <span style={{ fontWeight: "bolder" }}>End Date : </span>{" "}
                {formInputs.end_date_batch} <br />
                <span style={{ fontWeight: "bolder" }}>Created At : </span>{" "}
                {formInputs.createdAt} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Last Updated At :{" "}
                </span>{" "}
                {formInputs.updatedAt} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Collected Revenue :{" "}
                </span>{" "}
                {formInputs.total_collected_revenue} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Total Pending Revenue :{" "}
                </span>{" "}
                {formInputs.total_pending_revenue} <br />
                <span style={{ fontWeight: "bolder" }}>
                  Total Paid Registration Fee :{" "}
                </span>{" "}
                {formInputs.total_paid_registration_fee} <br />
                {/* <span  style={{ fontWeight: "bolder" }} >Current Batch Revenue : </span> {formInputs.batch_revenue} <br />

                <span  style={{ fontWeight: "bolder" }} >Accumulative Fee Revenue : </span> {formInputs.accumulative_fee_revenue} <br />
                <span  style={{ fontWeight: "bolder" }} >Accumulative Registration Revenue : </span> {formInputs.accumulative_registration_revenue} <br /> */}
              </Typography>
            </div>
            {/* <Button
            onClick={handleNav}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Invoice
          </Button> */}
          </Stack>
        </div>
<div className="row">

{/* <div className="col-sm-6 text-center"
            // style={{
            //   textAlign: "left",
            //   padding: "0 15px",
            //   paddingTop: "20px",
            // }}
            >
      <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1,p:2 }}
        > 
              <span
                style={{
                  padding: "10px 0px",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  lineHeight: "1.2",
                }}
               
              >
                Predicted Revenue
              </span>
              <br />

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {formInputs.accumulative_revenue.map((row, index) => (
                        <TableCell key={row.index}>{row.month}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {formInputs.accumulative_revenue.map((row, index) => (
                        <TableCell key={row.index}>{row.fee}</TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </Card>
            </div> */}
            {/* <div className="col-sm-6 text-center"
            // style={{
            //   textAlign: "left",
            //   padding: "0 15px",
            //   paddingTop: "20px",
            // }}
            >
      <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1,p:2 }}
        > 
              <span
                style={{
                  padding: "10px 0px",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  lineHeight: "1.2",
                }}
              >
                Fee Revenue
              </span>
              <br />

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {formInputs.fee_revenue.map((row, index) => (
                        <TableCell key={row.index}>{row.month}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {formInputs.fee_revenue.map((row, index) => (
                        <TableCell key={row.index}>{row.fee}</TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              </Card>
            </div> */}


</div>
        <hr />

        <TableContainer component={Paper}>
          <span style={{ textAlign: "center", fontWeight: "bolder" }}>
            Students{" "}
          </span>
          <Table
            sx={{ minWidth: 650, paddingBottom: "30px", marginBottom: "30px" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={myStyle}>#</TableCell>
                <TableCell style={myStyle}>Name</TableCell>
                <TableCell style={myStyle}>Phone</TableCell>
                <TableCell style={myStyle}>Registration Discount</TableCell>
                <TableCell style={myStyle}>Fee Discount</TableCell>
                {/* <TableCell style={myStyle}>Total Pending Fee</TableCell> */}
                <TableCell style={myStyle}>Time Preference</TableCell>
                <TableCell style={myStyle}>Action</TableCell>
                {/* <TableCell style={myStyle}></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((row, index) => {
                console.log("row ___test", row);
                const {
                  id,
                  name,
                  contact_number,

                  // total_registration_fee,
                  // total_paid_fee,
                  // total_pending_fee,

                  registration_fee_discount,
                  fee_discount,
                  time,
                } = row;
                const isItemSelected = selected.indexOf(name) !== -1;
                let number = index + 1;
                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell align="left" style={myStyle}>
                      {number}
                    </TableCell>

                    <TableCell align="left" style={myStyle}>
                      {name}
                    </TableCell>

                    <TableCell align="left" style={myStyle}>
                      +92{contact_number}
                    </TableCell>

                    <TableCell align="left" style={myStyle}>
                      {registration_fee_discount}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {fee_discount}
                    </TableCell>
                    {/* <TableCell align="left" style={myStyle}>
                      {total_pending_fee}
                    </TableCell> */}

                    <TableCell align="left" style={myStyle}>
                            {time}
                          </TableCell>

                    {/* <TableCell align="left" style={myStyle}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleNavigate(row.id)}
                      >
                        Move to Batch 
                      </Button>
                    </TableCell> */}

                    <TableCell align="left">
                      <StudentMoreMenu
                        row={row}
                        students={students}
                        onStudentsUpdate={handleStudentsUpdate}
                        batch_id={batch_ID}
                      />

                      {/* <BatchMoreMenu
                              row={row}
                              batches={students}
                              onBatchesUpdate={handleStudentsUpdate}
                              type="Freeze"
                            /> */}
                    </TableCell>
                  </TableRow>
                );
              })}

              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}
