import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";

import { detailPage, editPage } from "src/DAL/page";
import { listActiveTemplate } from "src/DAL/template";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [choose_template, setChoose_template] = useState([]);
  const [brand_logo, setBrand_logo] = useState();
  const [brand_favicon, setBrand_favicon] = useState();
  const [footer_logo, setFooter_logo] = useState();
  const [showbrand_logo, setShowbrand_logo] = useState(false);
  const [previewUrlbrand_logo, setPreviewUrlbrand_logo] = useState();
  const [showbrand_favicon, setShowbrand_favicon] = useState(false);
  const [previewUrlbrand_favicon, setPreviewUrlbrand_favicon] = useState();
  const [showfooter_logo, setShowfooter_logo] = useState(false);
  const [previewUrlfooter_logo, setPreviewUrlfooter_logo] = useState();

  const [links, setLinks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    page_title: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    brand_logo: "",
    brand_favicon: "",
    footer_logo: "",
    redirect_user: "",
    choose_template: [],
    show_testimonial: "",

    use_general_social_media_links: "",
    messenger_link: "",
    whatsapp_link: "",
    twitter_link: "",
    instagram_link: "",
    youtube_link: "",
    mailbox_link: "",
    facebook_link: "",
    faqs: "",
    page_status: "",
  });

  function handleFilebrand_logo(e) {
    const file = e.target.files[0];

    setBrand_logo(file);
    setPreviewUrlbrand_logo(URL.createObjectURL(file));
    setShowbrand_logo(true);
  }
  function handleDeleteImagebrand_logo() {
    setBrand_logo("");
    setShowbrand_logo(false);
  }
  function handleFilebrand_favicon(e) {
    const file = e.target.files[0];

    setBrand_favicon(file);
    setPreviewUrlbrand_favicon(URL.createObjectURL(file));
    setShowbrand_favicon(true);
  }
  function handleDeleteImagebrand_favicon() {
    setBrand_favicon("");
    setShowbrand_favicon(false);
  }

  function handleFilefooter_logo(e) {
    const file = e.target.files[0];

    setFooter_logo(file);
    setPreviewUrlfooter_logo(URL.createObjectURL(file));
    setShowfooter_logo(true);
  }
  function handleDeleteImagefooter_logo() {
    setFooter_logo("");
    setShowfooter_logo(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
    if (name == "use_general_social_media_links") {
      if (value === "false") {
        setLinks(false);
        setFormInputs({
          ...formInputs,
          messenger_link: "",
          whatsapp_link: "",
          twitter_link: "",
          instagram_link: "",
          youtube_link: "",
          mailbox_link: "",
          facebook_link: "",
          use_general_social_media_links: "false",
        });
      } else if (value === "true") {
        setLinks(true);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();

    // console.log(brand_logo, "sdjkfhsdjkhsjkfhsjdkfhsdjk");
    data.append("page_title", formInputs.page_title);
    data.append("meta_title", formInputs.meta_title);
    data.append("meta_keywords", formInputs.meta_keywords);
    data.append("meta_description", formInputs.meta_description);
    data.append("brand_logo", brand_logo ? brand_logo : "");
    data.append("brand_favicon", brand_favicon ? brand_favicon : "");
    data.append("footer_logo", footer_logo ? footer_logo : "");
    data.append("redirect_user", formInputs.redirect_user);
    data.append(
      "choose_template",
      JSON.stringify(
        Array.isArray(formInputs.choose_template)
          ? formInputs.choose_template
          : [formInputs.choose_template]
      )
    );
    data.append("show_testimonial", formInputs.show_testimonial);

    data.append(
      "use_general_social_media_links",
      formInputs.use_general_social_media_links
    );
    data.append("messenger_link", formInputs.messenger_link);
    data.append("whatsapp_link", formInputs.whatsapp_link);
    data.append("twitter_link", formInputs.twitter_link);
    data.append("instagram_link", formInputs.instagram_link);
    data.append("youtube_link", formInputs.youtube_link);
    data.append("mailbox_link", formInputs.mailbox_link);
    data.append("facebook_link", formInputs.facebook_link);
    data.append("faqs", formInputs.faqs);
    data.append("page_status", formInputs.page_status);
    const formDataArray = Array.from(data.entries());

    console.log(formDataArray);
    const result = await editPage(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/pages");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  async function getDetailPage() {
    const response = await detailPage(id);
    if (response.code === 200) {
      // setPageslug(response.Page.page_title_slug);
      const templateIds = response.Page.choose_template.map(
        (choose_template) => choose_template._id
      );
      // console.log(data.instructor.first_name);
      setFormInputs({
        page_title: response.Page.page_title,
        meta_title: response.Page.meta_title,
        meta_keywords: response.Page.meta_keywords,
        meta_description: response.Page.meta_description,
        brand_logo: response.Page.brand_logo,
        brand_favicon: response.Page.brand_favicon,
        footer_logo: response.Page.footer_logo,
        redirect_user: response.Page.redirect_user,
        choose_template: templateIds,
        show_testimonial: response.Page.show_testimonial,

        use_general_social_media_links:
          response.Page.use_general_social_media_links,
        messenger_link: response.Page.messenger_link,
        whatsapp_link: response.Page.whatsapp_link,
        twitter_link: response.Page.twitter_link,
        instagram_link: response.Page.instagram_link,
        youtube_link: response.Page.youtube_link,
        mailbox_link: response.Page.mailbox_link,
        facebook_link: response.Page.facebook_link,
        faqs: response.Page.faqs,
        page_status: response.Page.page_status,
      });
      // console.log(
      //   data.Page.use_general_social_media_links,
      //   "data.Page.use_general_social_media_linksdata.Page.use_general_social_media_links"
      // );
      if (response.Page.use_general_social_media_links == true) {
        // console.log("mmmmmmmm");
        setLinks(true);
      }
      if (response.Page.brand_logo) {
        setBrand_logo(response.Page.brand_logo);
        setShowbrand_logo(true);
      }
      if (response.Page.brand_favicon) {
        setBrand_favicon(response.Page.brand_favicon);
        setShowbrand_favicon(true);
      }
      if (response.Page.footer_logo) {
        setFooter_logo(response.Page.footer_logo);
        setShowfooter_logo(true);
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  const fetchActiveTemplates = async () => {
    const response = await listActiveTemplate();

    if (response.code === 200) {
      const templetes = response.templetes.map((templete) => ({
        id: templete._id,
        title: templete.template_name,
      }));
      setChoose_template(templetes);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getDetailPage();
    fetchActiveTemplates();
  }, []);
  return (
    <Page title="Edit Section">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit Section
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Page Title"
                  value={formInputs.page_title}
                  onChange={handleChange}
                  name="page_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Meta Title"
                  value={formInputs.meta_title}
                  onChange={handleChange}
                  name="meta_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Meta Keywords"
                  value={formInputs.meta_keywords}
                  onChange={handleChange}
                  name="meta_keywords"
                  autoComplete="off"
                  required
                />
                <TextField
                  fullWidth
                  label="Redirect User"
                  value={formInputs.redirect_user}
                  onChange={handleChange}
                  name="redirect_user"
                  autoComplete="off"
                  required
                />
              </Stack>
              <TextField
                fullWidth
                label="Meta Description"
                value={formInputs.meta_description}
                onChange={handleChange}
                name="meta_description"
                autoComplete="off"
                required
                multiline
                rows={2}
              />
              <TextField
                fullWidth
                label="Choose Template"
                select
                value={formInputs.choose_template}
                onChange={handleChange}
                name="choose_template"
                required
                // SelectProps={{ multiple: true }}
              >
                {choose_template.map((choose_template) => (
                  <MenuItem key={choose_template.id} value={choose_template.id}>
                    {choose_template.title}
                  </MenuItem>
                ))}
              </TextField>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Show Testimonial"
                  select
                  required
                  value={formInputs.show_testimonial}
                  onChange={handleChange}
                  name="show_testimonial"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Is General Social Media Links"
                  select
                  required
                  value={formInputs.use_general_social_media_links}
                  onChange={handleChange}
                  name="use_general_social_media_links"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Stack>
              {links === true ? (
                <>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Messenger Link"
                      value={formInputs.messenger_link}
                      onChange={handleChange}
                      name="messenger_link"
                      autoComplete="off"
                    />
                    <TextField
                      fullWidth
                      label="WhatsApp Link"
                      value={formInputs.whatsapp_link}
                      onChange={handleChange}
                      name="whatsapp_link"
                      autoComplete="off"
                    />
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Twitter Link"
                      value={formInputs.twitter_link}
                      onChange={handleChange}
                      name="twitter_link"
                      autoComplete="off"
                    />
                    <TextField
                      fullWidth
                      label="Instagram Link"
                      value={formInputs.instagram_link}
                      onChange={handleChange}
                      name="instagram_link"
                      autoComplete="off"
                    />
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Youtube Link"
                      value={formInputs.youtube_link}
                      onChange={handleChange}
                      name="youtube_link"
                      autoComplete="off"
                    />
                    <TextField
                      fullWidth
                      label="MailBox Link"
                      value={formInputs.mailbox_link}
                      onChange={handleChange}
                      name="mailbox_link"
                      autoComplete="off"
                    />
                  </Stack>
                  <TextField
                    fullWidth
                    label="Facebook Link"
                    value={formInputs.facebook_link}
                    onChange={handleChange}
                    name="facebook_link"
                    autoComplete="off"
                  />
                </>
              ) : null}
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="FAQS"
                  select
                  required
                  value={formInputs.faqs}
                  onChange={handleChange}
                  name="faqs"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Page Status"
                  select
                  required
                  value={formInputs.page_status}
                  onChange={handleChange}
                  name="page_status"
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>
              {showbrand_logo === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                  <label htmlFor="image" className="form-label">
                    Header Logo:
                  </label>
                  <Input
                    type="file"
                    id="brand_logo"
                    name="brand_logo"
                    accept="image/*"
                    onChange={handleFilebrand_logo}
                    required
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {showbrand_logo === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Header Logo:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrlbrand_logo ? (
                      <img
                        alt="image"
                        src={previewUrlbrand_logo}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.brand_logo}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImagebrand_logo()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}
              {showbrand_favicon === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={8.5}>
                  <label htmlFor="image" className="form-label">
                    Favicon:
                  </label>
                  <Input
                    type="file"
                    id="brand_favicon"
                    name="brand_favicon"
                    accept="image/*"
                    onChange={handleFilebrand_favicon}
                    required
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {showbrand_favicon === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Favicon:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrlbrand_favicon ? (
                      <img
                        alt="image"
                        src={previewUrlbrand_favicon}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.brand_favicon}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImagebrand_favicon()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              {showfooter_logo === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={4.5}>
                  <label htmlFor="image" className="form-label">
                    Footer Logo:
                  </label>
                  <Input
                    type="file"
                    id="footer_logo"
                    name="footer_logo"
                    accept="image/*"
                    onChange={handleFilefooter_logo}
                    required
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {showfooter_logo === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Footer Logo:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrlfooter_logo ? (
                      <img
                        alt="image"
                        src={previewUrlfooter_logo}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.footer_logo}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImagefooter_logo()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
