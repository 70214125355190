import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { Label } from "src/components";
import DataNotFound from "src/components/GeneralComponents/DataNotFound";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import Paper from "@mui/material/Paper";
import { s3baseUrl } from "src/config/config";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { formatDateWithDay } from "src/utils/formatTime";
const AssignmentTable = ({
  students,
  setStudents,
  assignmentData,
  batchID,
}) => {
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight:"500",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "left", // Add text alignment to center
  };
  const myStyle = {
    textTransform: "capitalize",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "left", // Add text alignment to center
  };
  const {
    _id,
    title,
    batch,
    assigned_date,
    dead_line,
    total_marks,
    status,
    assignment_type,
    question_paper_url,
  } = assignmentData;

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  const handleDownloadClick = (rowData) => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = s3baseUrl + rowData;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };

  return (
    <>
      <TableContainer component={Paper}>
        {/* <span style={{ textAlign: "center", fontWeight: "bolder" }}>
        Students{" "}
      </span> */}

        <Table
          sx={{ minWidth: 650, paddingBottom: "30px", marginBottom: "30px" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {/* <TableCell style={myStyle}>#</TableCell> */}
              <TableCell style={myStyle2}>Title</TableCell>
              <TableCell style={myStyle2}>Batch</TableCell>
              <TableCell style={myStyle2}>Assigned Date</TableCell>
              <TableCell style={myStyle2}>Dead Line</TableCell>
              <TableCell style={myStyle2}>Total Marks</TableCell>
              <TableCell style={myStyle2}>Type</TableCell>
              <TableCell style={myStyle2}>Status</TableCell>
              {/* {question_paper_url !== "" && question_paper_url !== null ? (
                <TableCell style={myStyle2}>Attachment</TableCell>
              ) : (
                ""
              )} */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
            //   hover
            //   key={id}
            //   tabIndex={-1}
            //   role="checkbox"
            //   selected={isItemSelected}
            //   aria-checked={isItemSelected}
            >
              {/* <TableCell align="left" style={myStyle}>
                        {1}
                      </TableCell> */}

              {/* <TableCell align="left" style={myStyle}>
                  {name}
                </TableCell> */}

              <TableCell align="left">
                <div>{capitalizeFirstLetter(title)}</div>
              </TableCell>

              <TableCell align="left" style={myStyle}>
                {capitalizeFirstLetter(batch.name)}
              </TableCell>

              <TableCell align="left" style={myStyle}>
                {formatDateWithDay(assigned_date)}
              </TableCell>

              <TableCell align="left" style={myStyle}>
                {formatDateWithDay(dead_line)}
              </TableCell>

              <TableCell align="left">{total_marks}</TableCell>

              <TableCell align="left">
                <Label
                  variant="outlined"
                  color={(assignment_type === 1 && "info") || "primary"}
                >
                  {assignment_type === 1 ? "Under Review" : "Direct"}
                </Label>
              </TableCell>

              <TableCell align="left">
                <Label
                  variant="outlined"
                  color={(status === false && "error") || "success"}
                >
                  {!status ? "Inactive" : "Active"}
                </Label>
              </TableCell>
              {/* {question_paper_url !== "" && question_paper_url !== null ? (
                <TableCell align="left">
                  <Button
                    variant="outlined"
                    color="info"
                    // startIcon={<GetAppIcon />}
                    onClick={() => handleDownloadClick(question_paper_url)}
                    size="small"
                    sx={{
                      fontSize: "7px", // or any other valid CSS font size value
                    }}
                  >
                    Download
                  </Button>
                </TableCell>
              ) : (
                ""
              )} */}

              {/* <TableCell align="left">
                        {row.assignment_url !== "" &&
                        row.assignment_url !== null ? (
                          <Button
                            variant="outlined"
                            color="info"
                            // startIcon={<GetAppIcon />}
                            onClick={() => handleDownloadClick(row)}
                            size="small"
                            sx={{
                              fontSize: "7px", // or any other valid CSS font size value
                            }}
                          >
                            Download
                          </Button>
                        ) : (
                          ""
                        )}
                      </TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AssignmentTable;
