import React from "react";

import { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// material
import {
  Avatar,
  IconButton,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";

import { detailAdmin } from "src/DAL/auth";
import { s3baseUrl } from "src/config/config";
import {
  _detail_customer_admin,
  _detail_customer_without_token,
  _instructor_detail_with_token,
} from "src/DAL/customer";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { useSnackbar } from "notistack";
import CustomInputPaperView from "src/components/GeneralComponents/CustomInputPaperView";
import CustomInputPaperViewFullLength from "src/components/GeneralComponents/CustomInputPaperViewFullLength";
const Profile = () => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [storedUserType, setStoredUserType] = useState(-1);
  const { enqueueSnackbar } = useSnackbar();
  const handleClick = () => {
    // You can perform any necessary actions here before navigating
    // For example, submitting data, handling loading, etc.
    // After that, navigate to the desired route.
    navigate("/profile/edit-profile");
  };

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    profile_image: "",
    last_name: "",
    contact_number: "",
    email: "",
  });

  const fetchProfile = async () => {
    // Check if "user" is in localStorage and parse it
    const storedObjectString = localStorage.getItem("user");
    if (storedObjectString) {
      const storedUser = JSON.parse(storedObjectString);

      if (storedUser.type === 0) {
        // Type 0: Make API call using detailAdmin()
        const response = await detailAdmin();
        console.log("response ___response", response);
        if (response.code === 200) {
          setStoredUserType(0);
          setIsLoading(false);
          setFormInputs({
            ...response.admin,
            first_name: capitalizeFirstLetter(response.admin.first_name),
            last_name: capitalizeFirstLetter(response.admin.last_name),
            profile_image: response.admin.profile_image,
            _id: response.admin.user_id._id,
            contact_number: response.admin.contact_number,
            email: response.admin.email,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else if (storedUser.type === 3) {
        // Type 3: Make API call using detailAdmin()
        const response = await _detail_customer_without_token();
        console.log("response _detail_customer_without_token", response);
        if (response.code === 200) {
          setIsLoading(false);
          setStoredUserType(3);
          setFormInputs({
            ...response.customer,
            first_name: capitalizeFirstLetter(response.customer.first_name),
            last_name: capitalizeFirstLetter(response.customer.last_name),
            profile_image: response.customer.profile_image,
            _id: response.customer.user_id._id,
            contact_number: response.customer.contact_number,
            email: response.customer.email,
            // post_code: response.customer.post_code,
            // role_id: response.customer.role._id,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else if (storedUser.type === 2) {
        // Type 3: Make API call using detailAdmin()
        const response = await _instructor_detail_with_token();
        console.log("response _instructor_detail_with_token", response);
        if (response.code === 200) {
          setIsLoading(false);
          setStoredUserType(2);
          setFormInputs({
            ...response.instructor,
            first_name: capitalizeFirstLetter(response.instructor.first_name),
            last_name: capitalizeFirstLetter(response.instructor.last_name),
            profile_image: response.instructor.image,
            _id: response.instructor.user_id,
            // contact_number: response.customer.contact_number,
            // post_code: response.customer.post_code,
            // role_id: response.customer.role._id,
          });
        } else {
          setIsLoading(false);
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Profile">
      <div className="container px-4 mb-5 container_in_all_pages">

      <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Profile </h4>
        </Grid>

        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mb: 1,
            mt: 1,
            width: "100%",
            p: 3,
          }}
        >
          <form
            autoComplete="off"
            // onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row table-form">
              <div className="col-12 mt-1 mb-1">
                <div className="row">
                  <div className="col-12 text-center  ">
                  <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  // style={{ display: "flex", justifyContent: "center" }}
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar
                    alt={formInputs.first_name}
                    src={s3baseUrl + formInputs.profile_image}
                    sx={{ width: "120px", height: "120px", mb: 3 }}
                  />
                  <Typography
                    style={{
                      // fontSize: "24px",
                      color: theme.palette.primary.main,
                    }}
                    variant="h4"
                  >
                    {formInputs.first_name} {formInputs.last_name}
                  </Typography>
                </Grid>
                  </div>
                </div>
              </div>
              {/* Here form input code */}

              <Grid container spacing={1} sx={{ marginLeft: "-4px" }}>
                <CustomInputPaperView
                  Label="First Name"
                  Name={formInputs.first_name}
                  // IconSet="ri:user-fill"
                  IconSet="icon-park-outline:edit-name"
                  marginTopSet="20px"
                />
                <CustomInputPaperView
                  Label="Last Name"
                  Name={formInputs.last_name ? formInputs.last_name : "N/A"}
                  // IconSet="subway:admin"
                  IconSet="mdi:rename-box-outline"
                  marginTopSet="20px"
                />
                <CustomInputPaperView
                  Label="Email"
                  Name={formInputs.email ? formInputs.email : "N/A"}
                  IconSet="line-md:email"
                  marginTopSet="20px"
                />

                <CustomInputPaperView
                  Label="Phone"
                  Name={
                    formInputs.contact_number
                      ? `+92${formInputs.contact_number}`
                      : "N/A"
                  }
                  IconSet="ph:phone-fill"
                  marginTopSet="20px"
                />

              
                 
 
  
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "30px",
                }}
              >
                {/* <LoadingButton
                    size="medium"
                    variant="contained"
                    component={RouterLink}
                    to="/profile/edit-profile"
                    sx={{ width: "120px" }}
                  >
                    Edit Profile
                  </LoadingButton>  */}

                <LoadingButton
                  size="medium"
                  variant="contained"
                  sx={{ width: "120px" }}
                  onClick={handleClick}
                >
                  Edit Profile
                </LoadingButton>
              </Grid>
            </div>
          </form>
        </Card>

      </div>
    </Page>
  );
};

export default Profile;
