import { invokeApi } from "src/utils";


export const _list_brand = async (data) => {

    const requestObj = {
      path: `api/brand/list_brand`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_brand = async (data) => {

    const requestObj = {
      path: `api/brand/add_brand`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

 export const _edit_brand = async (id, data) => {
  const requestObj = {
    path: `api/brand/edit_brand/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

  export const _delete_brand = async (id) => {
    const requestObj = {
      path: `api/brand/delete_brand/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _detail_brand = async (id) => {
    const requestObj = {
      path: `api/brand/detail_brand/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _list_active_brand = async () => {
    const requestObj = {
      path: `api/brand/list_active_brand/`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
