import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  TableHead,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { _detail_enquiry_student } from "src/DAL/enquirystudent";
import { formatDateTimeWithOutZone, timePreferencesObjTest } from "src/utils";
import { capitalizeFirstLetter, textEmptyToReturnHyphen } from "src/utils/formatText";
const EnquiryStudentDetail = ({ studentID }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    fontSize: "1.3rem", // Adjust the font size as needed
    color: "#fff!important",
  };

  const myStyleHeader3 = {
    backgroundColor: "#0c2e60",
    color: "#fff",
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    course: {},
    course_name:"",
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: {
      _id: "0",
      type: "",
      start_time: "00:00",
      end_time: "00:00",
    },

    discount: 0,
    fee_status: "all_paid",
    batch_name: "",
    student_batches: [],
  });

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_enquiry_student(studentID);
    console.log("_detail_enquiry_student _detail_enquiry_student", response);
    // enquiry_form

    if (response.code === 200) {
      // setCourses(response.enquiry_form.course);
      let courseIds = [];
      // if (response.enquiry_form.course.length > 0) {
      //   courseIds = response.enquiry_form.course.map((_course) => _course._id);
      // }

      let response_gender = response.enquiry_form.gender;
      let response_enquiry_status = response.enquiry_form.enquiry_status
        ? response.enquiry_form.enquiry_status
        : false;

      if (
        response_enquiry_status === "false" ||
        response_enquiry_status === false
      ) {
        response_enquiry_status = "active";
      } else if (
        response_enquiry_status === "true" ||
        response_enquiry_status === true
      ) {
        response_enquiry_status = "expire";
      }

      //   setSelectedYear(response.enquiry_form.completion_year);

      setFormInputs({
        ...response.enquiry_form,
        name: response.enquiry_form.name,
        father_name: response.enquiry_form.father_name,
        contact_number: response.enquiry_form.contact_number,
        gender: response.enquiry_form.gender,
        latest_degree: response.enquiry_form.latest_degree,
        degree_status: response.enquiry_form.degree_status,
        completion_year: response.enquiry_form.completion_year,
        semester: response.enquiry_form.semester,
        institution_name: response.enquiry_form.institution_name,

        // course_ids: courseIds,
        course: response.enquiry_form.course._id,
        course_name: response.enquiry_form.course.title,

        referred_by: response.enquiry_form.referred_by,
        enquiry_status: response.enquiry_form.enquiry_status,
        enquiry_status_reason: response.enquiry_form.enquiry_status_reason,
        note: response.enquiry_form.note,

        time_preferences: response.enquiry_form.time_preference
          ? timePreferencesObjTest(response.enquiry_form.time_preference)
          : {
              _id: "0",
              type: "",
              start_time: "00:00",
              end_time: "00:00",
            },
        others: [],

        createdAt: response.enquiry_form.createdAt,
        updatedAt: response.enquiry_form.updatedAt,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Student Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        {/* <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Student Detail
          </Typography>
        </Grid> */}

        <div className="container ">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Father Name
                  </TableCell>
                  <TableCell align="left">
                    {formInputs.father_name !== ""
                      ? capitalizeFirstLetter(formInputs.father_name)
                      : " _ _ "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Contact Number
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    +92{formInputs.contact_number}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Gender
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.gender === 1 ? "Male" : "Female"}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {formInputs.latest_degree !== "" ? (
                  <>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Latest Degree
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.latest_degree}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Degree Status
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.degree_status === "Inprogress"
                          ? "In Progress"
                          : formInputs.degree_status}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>

                    {formInputs.degree_status === "complete" ? (
                      <TableRow>
                        <TableCell align="left" style={myStyleHeader}>
                          Completion Year
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {formInputs.completion_year}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {" "}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell align="left" style={myStyleHeader}>
                          Semester
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {textEmptyToReturnHyphen(formInputs.semester)}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {" "}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Institution Name
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {textEmptyToReturnHyphen(formInputs.institution_name)}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                  Course
                  </TableCell>
                  <TableCell align="left" style={myStyle} >
                   
                  </TableCell>
                </TableRow> */}

           
                  <TableRow >
                    <TableCell align="left" style={myStyleHeader}>
                        Course Name
                    </TableCell>
                    <TableCell align="left">
                      {capitalizeFirstLetter(formInputs.course_name)}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>
                

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Time Preferences
                  </TableCell>
                  {/* <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.time_preferences.type)}
                  </TableCell> */}

<TableCell align="left">
                    
                    {capitalizeFirstLetter(formInputs.time_preferences.type) !==
                    ""
                      ? capitalizeFirstLetter(formInputs.time_preferences.type)
                      : " _ _ "}
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {capitalizeFirstLetter(formInputs.time_preferences.type) !==
                    "" ? (
                      <span>
                        {" From "}
                        {formatTime(formInputs.time_preferences.start_time)}
                        {" To "}
                        {formatTime(formInputs.time_preferences.end_time)}
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>

                  {/* <TableCell align="left" style={myStyle}>
                    From {formatTime(formInputs.time_preferences.start_time)} To{" "}
                    {formatTime(formInputs.time_preferences.end_time)}
                  </TableCell> */}
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Referred By
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {textEmptyToReturnHyphen(formInputs.referred_by)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    <Label
                      variant="outlined"
                      color={
                        formInputs.enquiry_status === 5
                          ? "success"
                          : formInputs.enquiry_status === "false"
                          ? "success"
                          : formInputs.enquiry_status === 1
                          ? "info"
                          : formInputs.enquiry_status === 2
                          ? "warning"
                          : formInputs.enquiry_status === 3
                          ? "info"
                          : "error"
                      }
                    >
                      {/* do not distrub -> 1
                              wrong_number -> 2
                              number_close -> 3
                              expire -> 4
                              active -> 5 */}
                      {formInputs.enquiry_status === 5
                        ? "Active"
                        : formInputs.enquiry_status === "false"
                        ? "Active"
                        : formInputs.enquiry_status === 1
                        ? "Do Not Distrub"
                        : formInputs.enquiry_status === 2
                        ? "Wrong Number"
                        : formInputs.enquiry_status === 3
                        ? "Number Close"
                        : "Expire"}
                    </Label>
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Enquiry Status Reason
                  </TableCell>
                  <TableCell align="left">
                    {textEmptyToReturnHyphen(capitalizeFirstLetter(formInputs.enquiry_status_reason))}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Note
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.note}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow> */}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.createdAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.updatedAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {formInputs.note !== "" ? (
              <Table
                sx={{
                  minWidth: "100%",
                  // paddingBottom: "30px",
                  marginTop: "18px",
                  // marginBottom: "30px",
                  border: "1px solid #f1f3f4", // Set the border style here
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={myStyleHeader3}>
                      Note
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/* <TableCell align="left" style={myStyleHeader}>
                    Note
                  </TableCell> */}
                    <TableCell align="left">{formInputs.note}</TableCell>
                    {/* <TableCell align="left" style={myStyle}>
                   
                  </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
};

export default EnquiryStudentDetail;
