import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_brand } from "src/DAL/brand";
import { _add_asset_category } from "src/DAL/asset_category";
import { _add_vendor } from "src/DAL/vendor";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
const Add = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    name: "",
    email: "",
    contact_number: "",
    address: "",
    image: "",
    active_status: true,
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }

  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "contact_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);

    let postData = {
      name: formInputs.name,
      email: formInputs.email,
      contact_number: formInputs.contact_number,
      address: formInputs.address,
      image: pathOfUploadedFile,
      active_status: formInputs.active_status,
    };

    console.log("postData", postData);

    setIsLoading(true);
    const result = await _add_vendor(postData);
    console.log("result _add_vendor", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      navigate("/vendor");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {}, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Vendor">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Vendor</h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Email"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                  fullWidth
                  label="Contact Number"
                  value={formInputs.contact_number}
                  onChange={handleChangePhone}
                  name="contact_number"
                  // type="number"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Address"
                  value={formInputs.address}
                  onChange={handleChange}
                  name="address"
                  required
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                
              </Stack>

              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <TextField
                fullWidth
                label="Active Status"
                select
                // required
                value={formInputs.active_status}
                onChange={handleChange}
                name="active_status"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </TextField>

              {show === false ? (
                // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                //   <label htmlFor="image" className="form-label">
                //     Image:
                //   </label>
                //   <Input
                //     type="file"
                //     id="image"
                //     name="image"
                //     accept="image/*"
                //     onChange={handleFile}
                //   />
                //   {/* <Avatar alt={"image"} src={previewUrl} /> */}
                // </Stack>
                <></>
              ) : null}

              {show === true ? (
                // <Stack
                //   position="relative"
                //   alignItems="center"
                //   justifyContent="center"
                // >
                //   {" "}
                //   <label htmlFor="image" className="form-label">
                //     Image:
                //   </label>
                //   <Box
                //     position="relative"
                //     display="inline-block"
                //     border="2px solid #ccc"
                //     p={1}
                //   >
                //     <img
                //       alt="image"
                //       src={previewUrl}
                //       width="150"
                //       style={{ maxWidth: "100%" }}
                //     />
                //     <IconButton
                //       aria-label="delete"
                //       onClick={() => handleDeleteImage()}
                //       style={{ position: "absolute", top: 0, right: 0 }}
                //     >
                //       <ClearIcon />
                //     </IconButton>
                //   </Box>
                // </Stack>
                <></>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
