import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _update_course_outline_details,
  _update_course_review,
  detailCourse,
  listCourse,
} from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomImageUploader1 from "src/components/GeneralComponents/CustomImageUploader1";
import CustomImageUploaderOnlyWithPath from "src/components/GeneralComponents/CustomImageUploaderOnlyWithPath";
import CustomDateInput from "src/components/GeneralComponents/CustomDateInput";
import CustomDateInputWithOnlyPath from "src/components/GeneralComponents/CustomDateInputWithOnlyPath";
import CustomImageUploaderInBenefits from "src/components/GeneralComponents/CustomImageUploaderInBenefits";
const MenuItems = ({ additionalFields, setAdditionalFields }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: 1,
    courses: [],
    contact_number: "",
    status: 1,
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: "",

    discount: 0,
    fee_status: "all_paid",
    note: "note",

    registration_fee: "",
    course_id: "",
  });

  const myStyle = {
    textTransform: "capitalize",
  };

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;

    // Check if the first digit is 3
    if (name === "contact_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", {
        variant: "error",
      });
      return; // Do not update state if the condition is not met
    }

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      {
        title: "",
        item_url: "",
        is_new_tab: true,
        status: true,
      },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    // if (value.length > 0 && value[0] !== "3") {
    //   enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
    //   return; // Do not update state if the condition is not met
    // }
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeImage = (imagePath, index) => {
    console.log("imagePath", imagePath);
    console.log("index", index);
    // const { name, value } = e.target;
    // if (value.length > 0 && value[0] !== "3") {
    //   enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
    //   return; // Do not update state if the condition is not met
    // }
    // setAdditionalFields((prevFields) => {
    //   const updatedFields = [...prevFields];
    //   updatedFields[index][name] = value;
    //   return updatedFields;
    // });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    // const additionalFieldsArray = additionalFields.map((field) => field.number);

    const newData = additionalFields.map(({ _id, ...rest }) => rest);

    let postData = {
      reviews: newData,
    };

    setIsLoading(true);
    const result = await _update_course_review(id, postData);
    if (result.code === 200) {
      navigate(-1);
      // navigate("/students");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      // setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchDetailCourse() {
    setIsLoading(true);
    const response = await detailCourse(id);
    console.log("detailCourse __detailCourse", response);
    if (response.code === 200) {
      // const newArray = response.course.reviews.map((str) => ({
      //   number: str,
      //   type: "personal",
      // }));

      if (response.course.reviews?.length > 0) {
        setAdditionalFields(response.course.reviews);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // fetchDetailCourse();
  }, []);

  useEffect(() => {
    console.log("additionalFields   ____additionalFields", additionalFields);
  }, [additionalFields]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <div className="">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h4 className="title_heading_in_all_pages_top_left"> Menu Items </h4>
      </Grid>

      <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
        {/* <Stack spacing={3}> */}
        {additionalFields.map((field, index) => (
          <Stack key={index} className="mb-3" spacing={2}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {index === 0 ? null : ( // If it's the first field, don't render the delete button
                <IconButton
                  aria-label="Delete Field"
                  onClick={() => handleDeleteField(index)}
                >
                  <ClearIcon />
                </IconButton>
              )}
              <TextField
                fullWidth
                label={`Title ${index + 1}`}
                value={field.title}
                onChange={(e) => handleFieldChange(e, index)}
                name="title"
                required
              />
              <TextField
                fullWidth
                label={`Item Url ${index + 1}`}
                name="item_url"
                // multiline // Enable multiline
                // rows={3} // Set the number of rows to 3
                value={field.item_url}
                onChange={(e) => handleFieldChange(e, index)}
                //   sx={{ marginRight: "10px" }}
                required
              />

              <TextField
                fullWidth
                label={`Status ${index + 1}`}
                select
                value={field.status}
                onChange={(e) => handleFieldChange(e, index)}
                name="status"
                required
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </TextField>

              <TextField
                fullWidth
                label={`New Tab ${index + 1}`}
                select
                value={field.is_new_tab}
                onChange={(e) => handleFieldChange(e, index)}
                name="is_new_tab"
                required
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </TextField>

              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddField} // Your function to add a new field
                sx={{ marginLeft: "10px" }}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        ))}
        {/* </Stack> */}
      </Grid>
    </div>
  );
};

export default MenuItems;
