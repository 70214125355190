import { invokeApi } from "src/utils";


export const _list_asset_category = async (data) => {

    const requestObj = {
      path: `api/asset_category/list_asset_category`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_asset_category = async (id) => {
    const requestObj = {
      path: `api/asset_category/delete_asset_category/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _add_asset_category = async (data) => {

    const requestObj = {
      path: `api/asset_category/add_asset_category`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _edit_asset_category = async (id, data) => {
    const requestObj = {
      path: `api/asset_category/edit_asset_category/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _detail_asset_category = async (id) => {
    const requestObj = {
      path: `api/asset_category/detail_asset_category/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _list_active_asset_category = async () => {
    const requestObj = {
      path: `api/asset_category/list_active_asset_category/`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
