import { invokeApi } from "src/utils";
export const listActiveTemplate = async () => {
  const requestObj = {
    path: `api/templet_configration/active_template_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const detailTemplate = async (id) => {
  const requestObj = {
    path: `api/templet_configration/detail_template/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// export const listTestimonial = async () => {
//   const requestObj = {
//     path: `api/templet_configration/list_templete`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const delTemplate = async (id) => {
//   const requestObj = {
//     path: `api/templet_configration/delete_templete/` + id,
//     method: "DELETE",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const addStudent = async (data) => {
//   const formDataArray = Array.from(data.entries());

//   console.log(formDataArray);
//   const requestObj = {
//     path: `api/student/add_student`,
//     method: "POST",
//     headers: {
//       "Content-Type": "multipart/form-data",

//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };

// export const editStudent = async (id, data) => {
//   const requestObj = {
//     path: `api/student/edit_student/` + id,
//     method: "PUT",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
