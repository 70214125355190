import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  InputLabel,
  Select,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { _add_student_in_course, addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomPasswordField from "src/components/GeneralComponents/CustomPasswordField";
const Add = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);
  const [password, setPassword] = useState("ddit_tech");
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    gender: 1,
    courses: [],
    phone: "",

    status: 1,
    education: "",
    image: "",
    reg_type: "regular",
    course_fee: 0,
    registration_fee: 0,
    decided_fee: 0,
    actual_fee: 0,
    cnic: "",
    referred_by: "",
    registration_fee: "",
    address: "",
    batches: [],
    // course: [],
    course: "",
    wallet_amount: 0,
    student_id: null,

    friend_number: "",
    family_number: "",
    whatsapp_number: "",

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    time_preferences: "",
    entrance_type: "in_batch",

    discount: 0,
    fee_status: "all_paid",
    course_id: "",
    note: "",
  });

  const myStyle = {
    textTransform: "capitalize",
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  // const handleChange2 = (event) => {
  //   const selectedCourses = event.target.value;
  //   setFormInputs((prevInputs) => ({
  //     ...prevInputs,
  //     courses: selectedCourses,
  //   }));
  // };

  // const handleAddFieldCourse = () => {
  //   // Add a new field as an object with default values

  //     setAdditionalFieldsCourse([
  //       ...additionalFieldsCourse,
  //       { number: "", type: "June" },
  //     ]);

  //     // Update the state for visibility of Remove buttons
  //     setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);

  // };

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  const handleAddFieldCourse = (count) => {
    // Create an array of fields based on the count
    console.log("formInputs.decided_fee __test", formInputs.decided_fee);
    let decided_fee_from_batch = 0;
    if (formInputs.actual_fee > 0) {
      decided_fee_from_batch = formInputs.actual_fee / count;
    }

    const newFields = Array.from({ length: count }, () => ({
      fee: decided_fee_from_batch,
      month: "June",
    }));

    // // Add the new fields to the existing additionalFieldsCourse array
    // setAdditionalFieldsCourse([...additionalFieldsCourse, ...newFields]);

    // Replace the existing additionalFieldsCourse array with the newFields
    setAdditionalFieldsCourse(newFields);
    console.log("newFields", newFields);
    // Update the state for visibility of Remove buttons
    setShowRemoveButtonsCourse(Array(count).fill(true));

    // // Update the state for visibility of Remove buttons
    // setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;

    if (value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }

    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };
 
  
 

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleCourseChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    // Find the course object with the specified _id
    const course = courses.find((item) => item.id === value);

    // const course = courses.find((item) => {
    //   console.log('item', item);
    //   return item._id === '64a45cd7e0ad1892dd66cd1c';
    // });

    const fee = course ? course.fee : null;

    console.log("course", course);
    console.log("fee", fee); // Output: 24000

    if (fee !== null) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        actual_fee: fee,
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleChange2 = (e, newValue) => {
    // const { name, value } = e.target;
    // console.log('name', name);
    // console.log('value', value);
    console.log("newValue", newValue);

    // const selectedCourse = courses.find((course) => course._id === value);
    let fee = null;

    if (newValue.fee) {
      fee = newValue.fee;
    }

    if (fee !== null) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        // [name]: value,
        actual_fee: fee,
        course: newValue ? newValue._id : "",
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        // [name]: value,
        course: newValue ? newValue._id : "",
      }));
    }

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   course: newValue ? newValue._id : "",
    // }));
  };

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "phone_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("name", name);
    console.log("value", value);

    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);
      let fee = null;
      console.log("selectedBatch", selectedBatch);

      const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
        (batch, index) => ({
          // fee: batch.fee,
          fee: 0,
          month: batch.month,
        })
      );

      setAdditionalFieldsCourse(additionalFieldsFromBatch);

      if (fee !== null) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
          actual_fee: fee,
        }));
      } else {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
        }));
      }
    } else if (name === "course") {
      // Find the selected batch object
      const selectedCourse = courses.find((course) => course._id === value);
      let fee = null;

      if (selectedCourse.fee) {
        fee = selectedCourse.fee;
      }

      if (fee !== null) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
          actual_fee: fee,
        }));
      } else {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
        }));
      }
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);
    let error = "";

    if (!formInputs.first_name.trim()) {
      error = "Name is Required";
      enqueueSnackbar(error, { variant: "error" });
    }

    if (formInputs.phone_number.length !== 10) {
      error = "Please enter a valid 10-digit Phone Number";
      enqueueSnackbar(error, { variant: "error" });
    }
    //  else if (!parseInt(formInputs.course_fee.trim(), 10)) {
    //   error = "Invalid Registration Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }
    // else if (
    //   !parseInt(formInputs.registration_fee.trim(), 10) &&
    //   formInputs.registration_fee.trim() !== ""
    // ) {
    //   error = "Invalid Decided Registration Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    // decided_fee
    // else if (
    //   parseInt(formInputs.registration_fee, 10) >
    //   parseInt(formInputs.course_fee, 10)
    // ) {
    //   const error =
    //     "Invalid Registration Fee. The decided fee cannot be greater than the actual fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // } else if (!parseInt(formInputs.actual_fee, 10)) {
    //   error = "Invalid Actual Course Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    // else if (
    //   !parseInt(formInputs.decided_fee.trim(), 10) &&
    //   formInputs.decided_fee.trim() !== ""
    // ) {
    //   error = "Invalid Decided Course Fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    // else if (
    //   parseInt(formInputs.decided_fee, 10) >
    //   parseInt(formInputs.actual_fee, 10)
    // ) {
    //   const error =
    //     "Invalid Decided Course Fee. The decided fee cannot be greater than the actual fee. Please enter a valid number.";
    //   enqueueSnackbar(error, { variant: "error" });
    // }

    console.log("error  msg", error);
    if (error === "") {
      // console.log("submit formInputs", formInputs);
      const data = new FormData();
      data.append("name", formInputs.first_name.trim());
      data.append("father_name", formInputs.father_name.trim());
      // data.append("profile_image", image);
      if (formInputs.dob) {
        data.append("date_of_birth", formInputs.dob);
      }
      data.append("email", formInputs.email.trim());
      data.append("contact_number", formInputs.phone_number);
      data.append("gender", formInputs.gender);

      // data.append("student_id", formInputs.student_id);
      // data.append("courses", JSON.stringify(formInputs.courses));

      // data.append(
      //   "course_id",
      //   JSON.stringify(
      //     Array.isArray(formInputs.courses)
      //       ? formInputs.courses
      //       : [formInputs.courses]
      //   )
      // );

      // data.append(
      //   "batch_id",
      //   JSON.stringify(
      //     Array.isArray(formInputs.batches)
      //       ? formInputs.batches
      //       : [formInputs.batches]
      //   )
      // );

      data.append("status", formInputs.status);
      // data.append("reg_type", formInputs.reg_type);

      // data.append("course_fee", formInputs.course_fee);
      // data.append("registration_fee", formInputs.registration_fee);
      // data.append("actual_fee", formInputs.actual_fee);
      // data.append("decided_fee", formInputs.decided_fee);
      data.append("cnic", formInputs.cnic);
      data.append("referred_by", formInputs.referred_by);

      data.append("course_id", formInputs.course_id);
      data.append("registration_fee", parseInt(formInputs.registration_fee));

      data.append("address", formInputs.address);

      data.append("latest_degree", formInputs.latest_degree);
      data.append("degree_status", formInputs.degree_status);
      data.append(
        "completion_year",
        formInputs.degree_status === "complete" ? selectedYear : ""
      );
      // data.append(
      //   "completion_year",
      //   formInputs.degree_status === "complete"
      //     ? formInputs.completion_year
      //     : ""
      // );
      data.append("institution_name", formInputs.institution_name);
      data.append("semester", formInputs.semester);

      data.append("additional_numbers", JSON.stringify(additionalFields));
      data.append("time_preference_id", formInputs.time_preferences);
      data.append("note", formInputs.note);
      data.append("password", password);
      // data.append("wallet_amount", formInputs.wallet_amount);
      // data.append("additional_numbers", additionalFields);
      // data.append("discount", formInputs.discount);
      // data.append("fee_status", formInputs.fee_status);

      // data.append("course_fee", parseInt(formInputs.course_fee, 10));
      // data.append("registration_fee", parseInt(formInputs.registration_fee, 10));
      // data.append("actual_fee", parseInt(formInputs.actual_fee, 10));
      // data.append("decided_fee", parseInt(formInputs.decided_fee, 10));

      if (formInputs.entrance_type === "existing_course") {
        data.append("course_id", formInputs.course);
        // data.append("decided_fee", formInputs.decided_fee);
        const result = await _add_student_in_course(data);

        if (result.code === 200) {
          setIsLoading(false);
          navigate("/students");
          enqueueSnackbar("Added Successfully!", { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      } else {
        // data.append("batch_id", formInputs.batches);
        // data.append("decided_fee", additionalFieldsCourse);
        // data.append("decided_fee", JSON.stringify(additionalFieldsCourse));

        const result = await addStudent(data);

        if (result.code === 200) {
          setIsLoading(false);
          navigate("/students");
          enqueueSnackbar("Added Successfully!", { variant: "success" });
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
        }
      }
    }
  };

  const fetchActiveBatches = async () => {
    setIsLoading(true);
    const response = await listActiveBatch();
    console.log("response ___listActiveBatch", response);
    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.course ? batch.course : "",
        accumulative_revenue: batch.accumulative_revenue
          ? batch.accumulative_revenue
          : [],
      }));
      // setCourses(batches[0].courses);
      // console.log("batches[0].courses batches[0].courses", batches[0].courses);
      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchTimePreference = async () => {
    // setIsLoading(true);
    const response = await _active_time_preference();
    console.log("response  _active_time_preference __test", response);

    //

    if (response.code === 200) {
      // const courses = response.courses
      //   .filter((course) => course.status === true)
      //   .map((course) => ({
      //     id: course._id,
      //     fee: course.fee,
      //     title: course.title,
      //     description: course.description,
      //     duration: course.duration,
      //     // image: course.image,
      //     status: course.status,
      //   }));
      // console.log("courses courses courses courses", courses);
      // setCourses(courses);
      setTimePreferences(response.time_preferences);

      if (response.time_preferences.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          time_preferences: response.time_preferences[0]._id,
        }));
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActiveCourse = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response listCourse ___listCourse", response);
    if (response.code === 200) {
      // const batches = response.batches.map((batch) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   student_count: batch.student_count,
      //   status: batch.status,
      //   courses: batch.courses,
      // }));
      // Filter and set active courses
      const activeCourses = response.courses.filter(
        (course) => course.status === true
      );
      setCourses(activeCourses);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActiveCourse();
    fetchTimePreference();
  }, []);

  useEffect(() => {
    if (courses.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        course_id: [courses[0]._id],
      }));
    }
  }, [courses]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Student">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Student </h4>
        </Grid>


        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.first_name}
                  onChange={handleChange}
                  name="first_name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Father name"
                  value={formInputs.father_name}
                  onChange={handleChange}
                  name="father_name"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  required
                  onChange={handleChangePhone}
                  name="phone_number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <Button
                  size="medium"
                  // type="submit"
                  // variant="contained"
                  // loading={isLoading}
                  // sx={{ width: "100px" }}
                  variant="outlined"
                  onClick={handleAddField}
                >
                  +
                </Button>
              </Stack>

              {additionalFields.map((field, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* Conditionally render the Remove button */}
                  {showRemoveButtons[index] && (
                    <IconButton
                      aria-label="Delete Field"
                      onClick={() => handleDeleteField(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <TextField
                    fullWidth
                    label={`Phone ${index + 1}`}
                    name="number"
                    value={field.number}
                    onChange={(e) => handleFieldChange(e, index)}
                    sx={{ marginRight: "10px" }}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+92</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      maxLength: 10, // Limit the input to 10 characters
                      title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <TextField
                    fullWidth
                    label="Phone Type"
                    select
                    name="type"
                    value={field.type}
                    onChange={(e) => handleFieldChange(e, index)}
                  >
                    <MenuItem value="personal">Personal</MenuItem>
                    <MenuItem value="family">Family</MenuItem>
                    <MenuItem value="friend">Friend</MenuItem>
                    <MenuItem value="whatsapp">Whatsapp</MenuItem>
                    <MenuItem value="work">Work</MenuItem>
                    <MenuItem value="emergency">Emergency</MenuItem>
                    <MenuItem value="institute">Institute</MenuItem>
                    <MenuItem value="other">other</MenuItem>
                  </TextField>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddField} // Your function to add a new field
                    sx={{ marginLeft: "10px" }}
                  >
                    Add
                  </Button>
                </div>
              ))}

{/* Password */}
<CustomPasswordField
                  passwordVariable={password}
                  setPasswordVariable={setPassword}
                  passwordLabel="Password"
                  Required={true}
                  show={true}
                />

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {/* <TextField
                  fullWidth
                  label="Student ID (Unique)"
                  value={formInputs.student_id}
                  required
                  onChange={handleChange}
                  name="student_id"
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                /> */}
                {/* <TextField
                  fullWidth
                  label="CNIC"
                  // required
                  value={formInputs.cnic}
                  onChange={handleChange}
                  name="cnic"
                /> */}

                <TextField
                  fullWidth
                  label="Registration Fee"
                  value={formInputs.registration_fee}
                  onChange={handleChange}
                  name="registration_fee"
                  sx={{ marginRight: "10px" }}
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">RS</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Gender"
                  select
                  // required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email address"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  autoComplete="off"
                  // required
                  // inputProps={{
                  //   pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  //   title: "Please enter a valid email address",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  // required
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={2}>Inactive</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="CNIC Number"
                  value={formInputs.cnic}
                  onChange={handleCNICChange}
                  name="cnic"
                  autoComplete="off"
                  inputProps={{
                    pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                    title:
                      "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
                  }}
                />

                <TextField
                  fullWidth
                  label="Referred By"
                  // required
                  value={formInputs.referred_by}
                  onChange={handleChange}
                  name="referred_by"
                />
              </Stack>

              <TextField
                fullWidth
                label="Address"
                // required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
              />

              {/* <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Entrance Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  // name="row-radio-buttons-group"
                  // value={selectedValue}
                  // onChange={handleChange}

                  value={formInputs.entrance_type}
                  onChange={handleChange}
                  name="entrance_type"
                >
                  <FormControlLabel
                    value="in_batch"
                    control={<Radio />}
                    label="In Batch"
                  />
                  <FormControlLabel
                    value="existing_course"
                    control={<Radio />}
                    label="In Existing Course"
                  />
                </RadioGroup>
              </FormControl> */}

              {/* <Autocomplete
                  fullWidth
                  label="Courses"
                  options={courses}
                  getOptionLabel={(course) => course.title}
                  value={
                    courses.find(
                      (course) => course._id === formInputs.course
                    ) || null
                  }
                  onChange={handleChange2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="course"
                      required
                      label="Courses"
                      placeholder="Courses"
                    />
                  )}
                /> */}

              <TextField
                fullWidth
                label="Course"
                select
                value={formInputs.course_id}
                onChange={handleChange}
                name="course_id"
                required
                // SelectProps={{ multiple: true }}
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course._id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                label="Time Preferences"
                select
                value={formInputs.time_preferences}
                onChange={handleChange}
                name="time_preferences"
                // required
                // style={myStyle} // Apply the style here
                SelectProps={{ multiple: false }}
              >
                {timePreferences.map((time_preferences) => (
                  <MenuItem
                    key={time_preferences._id}
                    value={time_preferences._id}
                    // style={myStyle} // Apply the style here
                  >
                    {capitalizeFirstLetter(time_preferences.type)} (
                    {formatTime(time_preferences.start_time)} to{" "}
                    {formatTime(time_preferences.end_time)} )
                  </MenuItem>
                ))}
              </TextField>

              {/* <TextField
                fullWidth
                label="Education"
                required
                value={formInputs.education}
                onChange={handleChange}
                name="education"
              /> */}
              {/* <TextField
                fullWidth
                label="Address"
                required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
              /> */}
              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Reg type"
                  select
                  value={formInputs.reg_type}
                  onChange={handleChange}
                  name="reg_type"
                  // required
                >
                  <MenuItem value="regular">Regular</MenuItem>
                  <MenuItem value="scholarship">Scholarship</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  // type="number"
                  label="Wallet Amount"
                  value={formInputs.wallet_amount}
                  onChange={handleChange}
                  name="wallet_amount"
                  // required
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                />
              </Stack> */}

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  // type="number"
                  label="Registration fee"
                  value={formInputs.course_fee}
                  onChange={handleChange}
                  name="course_fee"
                  required
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                />

                <TextField
                  fullWidth
                  // type="number"
                  label="Decided Registration fee"
                  value={formInputs.registration_fee}
                  // required
                  onChange={handleChange}
                  name="registration_fee"
                  // inputProps={{
                  //   // pattern: "[0-9]{11}",
                  //   pattern: "[0-9]",
                  //   title:
                  //     "Please enter a valid Course fee",
                  // }}
                />
              </Stack> */}

              {formInputs.entrance_type !== "existing_course" &&
                additionalFieldsCourse.map((field, index) => (
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    key={index}
                  >
                    {/* Conditionally render the Remove button */}
                    {/* {showRemoveButtonsCourse[index] && (
              <IconButton
                aria-label="Delete Field"
                onClick={() => handleDeleteFieldCourse(index)}
              >
                <ClearIcon />
              </IconButton>
            )} */}
                    <TextField
                      fullWidth
                      label={`Fee ${index + 1}`}
                      name="fee"
                      value={field.fee}
                      onChange={(e) => handleFieldChangeCourse(e, index)}
                      // sx={{ marginRight: "10px" }}
                    />

                    <TextField
                      fullWidth
                      label="Month"
                      select
                      name="month"
                      value={field.month}
                      onChange={(e) => handleFieldChangeCourse(e, index)}
                      disabled
                    >
                      <MenuItem value="January">January</MenuItem>
                      <MenuItem value="February">February</MenuItem>
                      <MenuItem value="March">March</MenuItem>
                      <MenuItem value="April">April</MenuItem>
                      <MenuItem value="May">May</MenuItem>
                      <MenuItem value="June">June</MenuItem>
                      <MenuItem value="July">July</MenuItem>
                      <MenuItem value="August">August</MenuItem>
                      <MenuItem value="September">September</MenuItem>
                      <MenuItem value="October">October</MenuItem>
                      <MenuItem value="November">November</MenuItem>
                      <MenuItem value="December">December</MenuItem>
                    </TextField>

                    {/* <Button
              variant="outlined"
              color="primary"
              onClick={handleAddFieldCourse} // Your function to add a new field
              sx={{ marginLeft: "10px" }}
            >
              Add
            </Button> */}
                  </Stack>
                ))}

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Latest Degree"
                  value={formInputs.latest_degree}
                  onChange={handleChange}
                  name="latest_degree"
                />

                <TextField
                  fullWidth
                  label="Degree Status"
                  select
                  value={formInputs.degree_status}
                  onChange={handleChange}
                  name="degree_status"
                >
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="inprogress">Inprogress</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {formInputs.degree_status === "complete" ? (
                  <TextField
                    fullWidth
                    label="Completion Year"
                    select
                    value={selectedYear}
                    onChange={handleYearChange}
                    name="degree_status"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  ""
                )}

                <TextField
                  fullWidth
                  label="Institution Name"
                  value={formInputs.institution_name}
                  onChange={handleChange}
                  name="institution_name"
                />
                {formInputs.degree_status === "inprogress" ? (
                  <TextField
                    fullWidth
                    label="Semester"
                    value={formInputs.semester}
                    onChange={handleChange}
                    name="semester"
                  />
                ) : (
                  ""
                )}
              </Stack>

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Friend Number"
                  value={formInputs.friend_number}
                  onChange={handleChange}
                  name="friend_number"
                />

                <TextField
                  fullWidth
                  label="Family Number"
                  value={formInputs.family_number}
                  onChange={handleChange}
                  name="family_number"
                />

                <TextField
                  fullWidth
                  label="Whatsapp Number"
                  value={formInputs.whatsapp_number}
                  onChange={handleChange}
                  name="whatsapp_number"
                />
              </Stack> */}

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
              {/* <TextField
                  fullWidth
                  label="Discount"
                  value={formInputs.discount}
                  onChange={handleChange}
                  name="discount"
                /> */}

              {/* <TextField
                  fullWidth
                  label="Fee Status"
                  select
                  value={formInputs.fee_status}
                  onChange={handleChange}
                  name="fee_status"
                >
                  <MenuItem value="is_free">Is Free</MenuItem>
                  <MenuItem value="installments">Installments</MenuItem>
                  <MenuItem value="all_paid">All Paid</MenuItem>
                </TextField> */}
              {/* </Stack> */}

              <div className="col-md-12 col-xs-12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of Birth"
                    format="MM-DD-YYYY"
                    value={formInputs?.date_of_birth}
                    onChange={handleDateChange}
                    maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>
              </div>

              {show === false
                ? // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Input
                  //     type="file"
                  //     id="image"
                  //     name="image"
                  //     accept="image/*"
                  //     onChange={handleFile}
                  //     required
                  //   />
                  //   {/* <Avatar alt={"image"} src={previewUrl} /> */}
                  // </Stack>
                  ""
                : null}

              {show === true
                ? // <Stack
                  //   position="relative"
                  //   alignItems="center"
                  //   justifyContent="center"
                  // >
                  //   {" "}
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Box
                  //     position="relative"
                  //     display="inline-block"
                  //     border="2px solid #ccc"
                  //     p={1}
                  //   >
                  //     <img
                  //       alt="image"
                  //       src={previewUrl}
                  //       width="150"
                  //       style={{ maxWidth: "100%" }}
                  //     />
                  //     <IconButton
                  //       aria-label="delete"
                  //       onClick={() => handleDeleteImage()}
                  //       style={{ position: "absolute", top: 0, right: 0 }}
                  //     >
                  //       <ClearIcon />
                  //     </IconButton>
                  //   </Box>
                  // </Stack>
                  ""
                : null}

              <TextField
                fullWidth
                label="Note"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.note}
                onChange={handleChange}
                name="note"
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
