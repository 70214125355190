import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { _update_student_fee, detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";

const UpdateStudentFee = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    fee_status: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    discount: 0,
    
    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    
    new_fee: 0,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log("iiikkkkkkkkkkkkkkkk", image);
    const data = {
        student_id : id,
        new_fee : formInputs.new_fee,
    };
    
    const result = await _update_student_fee(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/students");
      enqueueSnackbar("Update Student Fee Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(id);
    console.log("response response", response);
    if (response.code === 200) {
      // setCourses(response.student.courses);
      // const courseIds = response.student.courses.map((course) => course._id);

      // const batchIds = response.student.batch_id.map((batch) => batch._id);
    

      const batchIds = response.student.batch._id;
      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];
      let defaultDate = new Date();
      if(response.student.date_of_birth){
          defaultDate = new Date(response.student.date_of_birth);
      }
      // console.log('defaultDate',defaultDate);
      //  if(response.student.date_of_birth ==="undefined"){
      //   console.log('defaultDate Date of birth is undefined  ');
      //  }else {

      //   console.log('defaultDate Date of birth is defined');
      //  }

      setFormInputs({
        name: response.student.name,
        father_name: response.student.father_name,
        email: response.student.email,
        gender: response.student.gender,
        address: response.student.address,
        contact_number:  response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,
        // image: response.student.profile_image,
        // courses: courseIds,
        batches: batchIds,
        reg_type: response.student.reg_type,

        course_fee: response.student.course_fee,
        actual_fee: response.student.actual_fee,

        registration_fee: response.student.total_registration_fee,
        decided_fee: response.student.decided_fee,
        discount: response.student.discount? response.student.discount :0,


        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        date_of_birth: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        dob: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        // dob: defaultDate.toISOString().split("T")[0],
      });

      // if (response.student.profile_image) {
      //   setImage(response.student.profile_image);
      //   setShow(true);
      // }
      if (batchIds && batchIds.length > 0) {
        fetchActiveBatches(batchIds);
      }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  const fetchActiveBatches = async (batchIds) => {
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batchesList = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      // console.log(batchesList, "jjjjj");

      // console.log(batchIds[0], "kkkkkkkkk");

      const selectedBatchIndex = batchesList.findIndex(
        (batch) => batch.id === batchIds[0]
      );

      // console.log(selectedBatchIndex, "selectedBatchselectedBatch");
      // console.log("cccc");
      // setCourses(batchesList[selectedBatchIndex].courses);

      setBatches(batchesList);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchCourses = async () => {
    setIsLoading(true);
    const response = await listCourse();
    console.log("response listCourse listCourse listCourse", response);
    if (response.code === 200) {
      const courses = response.courses
        .filter((course) => course.status === true)
        .map((course) => ({
          id: course._id,
          title: course.title,
          description: course.description,
          duration: course.duration,
          // image: course.image,
          status: course.status,
        }));
      console.log("courses courses courses courses", courses);
      setCourses(courses);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchStudentDetail();
    // fetchCourses();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Student">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
          Update Student Fee
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
            <TextField
                  fullWidth
                  label="New Fee"
                  value={formInputs.new_fee}
                  onChange={handleChange}
                  name="new_fee"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
                 <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px", mb:2}}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
            </form>


              <Stack direction={{ xs: "column", sm: "row" }} 
               sx={{  mb:2}}
               spacing={2}>
                <TextField
                disabled
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                disabled
                  fullWidth
                  label="Phone Number"
                  value={formInputs.contact_number}
                  onChange={handleChange}
                  name="contact_number"
                  required
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} 
               sx={{  mb:2}}spacing={2}>

                <TextField
                disabled
                  fullWidth
                  label="Father name"
                  value={formInputs.father_name}
                  onChange={handleChange}
                  name="father_name"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                disabled
                  fullWidth
                  label="Gender"
                  select
                  // required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} 
               sx={{  mb:2}}spacing={2}>

                <TextField
                disabled
                  fullWidth
                  type="email"
                  label="Email address"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  autoComplete="off"
                  // required
                  // inputProps={{
                  //   pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                  //   title: "Please enter a valid email address",
                  // }}
                />

                <TextField
                disabled
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  // required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              <TextField
              disabled
                fullWidth
                label="CNIC"
                // required
                value={formInputs.cnic}
                onChange={handleChange}
                name="cnic"
                sx={{  mb:2}}
              />

              <TextField
              disabled
                fullWidth
                label="Referred By"
                // required
                value={formInputs.referred_by}
                onChange={handleChange}
                name="referred_by"
                sx={{  mb:2}}
              />

              <TextField
              disabled
                fullWidth
                label="Address"
                // required
                value={formInputs.address}
                onChange={handleChange}
                name="address"
                sx={{  mb:2}}
              />

              {/* <TextField
                fullWidth
                label="Batch"
                select
                value={formInputs.batches}
                onChange={handleChange}
                name="batches"
                // required
                // SelectProps={{ multiple: true }}
              >
                {batches.map((batch) => (
                  <MenuItem key={batch.id} value={batch.id}>
                    {batch.name}
                  </MenuItem>
                ))}
              </TextField> */}
              {/* <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.courses}
                onChange={handleChange}
                name="courses"
                // required
                SelectProps={{ multiple: false }}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {course.title}
                  </MenuItem>
                ))}
              </TextField> */}

              <TextField
              disabled
                fullWidth
                label="Reg type"
                select
                value={formInputs.reg_type}
                onChange={handleChange}
                name="reg_type"
                sx={{  mb:2}}
                // required
              >
                <MenuItem value="regular">Regular</MenuItem>
                <MenuItem value="scholarship">Scholarship</MenuItem>
              </TextField>

              <Stack direction={{ xs: "column", sm: "row" }}  sx={{  mb:2}} spacing={2}>
                <TextField
                disabled
                  fullWidth
                  label="Decided Registration fee"
                  value={formInputs.registration_fee}
                  onChange={handleChange}
                  name="registration_fee"
                  // required
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                  
                />

                {/* <TextField
                  fullWidth
                  label="Course Decided fee"
                  value={formInputs.decided_fee}
                  onChange={handleChange}
                  name="decided_fee"
                  // required
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                /> */}

{/* <TextField
                  fullWidth
                  label="Discount"
                  value={formInputs.discount}
                  onChange={handleChange}
                  name="discount"
                  // required
                  // inputProps={{
                  //   pattern: "[0-9]{11}",
                  //   title:
                  //     "Please enter a valid phone number in the format XXXXXXXXXXX",
                  // }}
                /> */}
              </Stack>

              

              {/* <TextField
                fullWidth
                label="Address"
                value={formInputs.address}
                onChange={handleChange}
                name="address"
                required
              /> */}


<Stack direction={{ xs: "column", sm: "row" }}  sx={{  mb:2}} spacing={2}>
                <TextField
                  fullWidth
                  label="Latest Degree"
                  value={formInputs.latest_degree}
                  onChange={handleChange}
                  name="latest_degree"
                  disabled
                />

                <TextField
                  fullWidth
                  label="Degree Status"
                  select
                  value={formInputs.degree_status}
                  onChange={handleChange}
                  name="degree_status"
                   
                  disabled
                >
                  <MenuItem value="complete">Complete</MenuItem>
                  <MenuItem value="inprogress">Inprogress</MenuItem>
                </TextField>
              </Stack>
              
              <Stack direction={{ xs: "column", sm: "row" }}  sx={{  mb:2}} spacing={2}>
                {formInputs.degree_status ==="complete"?
                <TextField
                disabled
                fullWidth
                label="Completion Year"
                value={formInputs.completion_year}
                onChange={handleChange}
                name="completion_year"
              />:""
              }
                 

              <TextField
              disabled
                  fullWidth
                  label="Institution Name"
                  value={formInputs.institution_name}
                  onChange={handleChange}
                  name="institution_name"
   
                />
              </Stack>
              <TextField
              disabled
                  fullWidth
                  label="Semester"
                  value={formInputs.semester}
                  onChange={handleChange}
                  name="semester"
                   sx={{  mb:2}}
                />

              <div className="col-md-12 col-xs-12">
                <LocalizationProvider  sx={{  mb:2}} dateAdapter={AdapterDayjs}>
                  <DatePicker
                  disabled
                    label="Date of Birth"
                    format="YYYY-MM-DD"
                    // value={formInputs?.date_of_birth}
                    value={dayjs(formInputs.date_of_birth)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>
              </div>

              {show === false
                ? // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Input
                  //     type="file"
                  //     id="image"
                  //     name="image"
                  //     accept="image/*"
                  //     onChange={handleFile}
                  //     required
                  //   />
                  //   {/* <Avatar alt={"image"} src={previewUrl} /> */}
                  // </Stack>
                  ""
                : null}

              {show === true
                ? // <Stack
                  //   position="relative"
                  //   alignItems="center"
                  //   justifyContent="center"
                  // >
                  //   {" "}
                  //   <label htmlFor="image" className="form-label">
                  //     Image:
                  //   </label>
                  //   <Box
                  //     position="relative"
                  //     display="inline-block"
                  //     border="2px solid #ccc"
                  //     p={1}
                  //   >
                  //     {previewUrl ? (
                  //       <img
                  //         alt="image"
                  //         src={previewUrl}
                  //         width="150"
                  //         style={{ maxWidth: "100%" }}
                  //       />
                  //     ) : (
                  //       <img
                  //         alt="image"
                  //         src={s3baseUrl + formInputs.image}
                  //         width="150"
                  //         style={{ maxWidth: "100%" }}
                  //       />
                  //     )}
                  //     <IconButton
                  //       aria-label="delete"
                  //       onClick={() => handleDeleteImage()}
                  //       style={{ position: "absolute", top: 0, right: 0 }}
                  //     >
                  //       <ClearIcon />
                  //     </IconButton>
                  //   </Box>
                  // </Stack>
                  ""
                : null}

              
           
        </Grid>
      </Grid>
    </Page>
  );
};

export default UpdateStudentFee;
