import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { v4 as uuid } from "uuid";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import Scrollbar from "src/components/GeneralComponents/Scrollbar";
import { capitalizeFirstLetter, truncateString } from "src/utils/formatText";

const HeaderTableCell = styled(TableCell)`
  && {
    top: 0;
    position: sticky;
    // border: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    height: 73px;
  }
`;

const HeaderTableCell2 = styled(TableCell)`
  && {
    top: 0;
    position: sticky;
    // border: 1px solid #ccc;
    // border-right:none;
    height: 73px;
    // height:50.16px;
  }
`;
const HeaderTableCell3 = styled(TableCell)`
  && {
    top: 0;
    position: sticky;
    // border: 1px solid #ccc;
    // border-right:none;
    height: 73px;
    // height:50.16px;
    font-weight: bold;
    // font-size: 1.0rem; /* Adjust the font size as needed */
  }
`;
const CustomTableCell = styled(TableCell)`
  && {
    background-color: white;
    color: #092e68;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    height:50px;
    padding: 0%;
    text-align: center;
    padding-top: 6px;
  }
`;

const CustomTableCell2 = styled(TableCell)`
  && {
    background-color: white;
    color: #092e68;
    // border: 1px solid #ccc;
    // border-right: none;
    max-height: 50px;
    min-width: 210px;
    height:50px;
    overflow: hidden;
  }
`;

const CustomTableCell3 = styled(TableCell)`
  && {
    background-color: white;
    color: #092e68;
    border-right: 1px solid #ccc;
    max-height: 50px;
    overflow: hidden;
    height:50px;
  }
`;
// ... (your imports remain unchanged)

const TableComponent = ({ data, title }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{title}</TableCell>
          {/* Add additional header cells as needed */}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.property1}</TableCell>
            <TableCell>{row.property2}</TableCell>
            {/* Add additional cells for other properties */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const CalendarTableHeader = ({ datarows, year, month, day,dateObj }) => {
  const [headers, setHeaders] = useState([]);
  const [attendanceRowsData, setAttendanceRowsData] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [batchName, setBatchName] = useState("Batch Name");


  const data2 = [
    { property1: "Data2-Row1", property2: "Data2-Row1-Property2" },
    { property1: "Data2-Row2", property2: "Data2-Row2-Property2" },
    // Add more rows as needed
  ];
  useEffect(() => {
    // const startDate = new Date();
    // const startDate = new Date(year, month, day);
    const startDate = new Date(dateObj);
    const daysInMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      0
    ).getDate();

    const newHeaders = Array.from({ length: daysInMonth }, (_, index) => {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + index);

      // Updated format: "MM/DD, Weekday"
      const label = currentDate.toLocaleDateString("en-US", {
        month: "numeric",
        day: "numeric",
        weekday: "short",
      });

      const formattedDate = currentDate.toISOString().split("T")[0]; // "YYYY-MM-DD"

      return { date: formattedDate, label };
    });

    setHeaders(newHeaders);
  }, []);

  useEffect(() => {
    console.log("datarows attendanceRowsData ___attendanceRowsData", datarows);

    let newAttendanceArray = datarows.map((value) => {
      return value.attendance;
    });

    let newStudentsArray = datarows.map((value) => {
      return value.student;
    });

    console.log(
      "newArray newAttendanceArray ___attendanceRowsData",
      newAttendanceArray
    );
    console.log(
      "newArray newStudentsArray ___newStudentsArray",
      newStudentsArray
    );
    
     
    setAttendanceRowsData(newAttendanceArray);
    setStudentsData(newStudentsArray);
  }, []);

  function isSaturdayOrSunday(dateString) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();

    if (dayOfWeek === 5 || dayOfWeek === 6) {
        return "- -";
    } else {
        return "";
    }
}

  const isMatchingDate = (headerDate, dataRows) => {
    // console.log('headerDate:', typeof headerDate, headerDate);

    const matchingRows = dataRows.map((value) => {
        // console.log('value:', typeof value, value);

      //   const dateString = "2023-11-10";
      const dateString = value.date;
      const dateObject = new Date(dateString);
      dateObject.setDate(dateObject.getDate() - 1);

      const modifiedDateString = dateObject.toISOString().split("T")[0];

      if (headerDate === modifiedDateString) {
        // return !value.absent?"P":false;
        if (!value.absent) {
          return "P";
        } else {
          console.log("value:  __test", typeof value, value);
          console.log("headerDate: __test", typeof headerDate, headerDate);
          return "A";
        }
      } else {
        console.log("headerDate: __test2", typeof headerDate, headerDate);
        // console.log("headerDate: __test2", typeof modifiedDateString, modifiedDateString);
       
        // let result = isSaturdayOrSunday(headerDate);
        
        // return isSaturdayOrSunday(headerDate);
        return "";
       
      }
    });

    // console.log('matchingRows:', matchingRows);

    // Check if at least one element in matchingRows is true
    // let hasMatch = matchingRows.includes("P");
    // let hasMatch2 = matchingRows.includes("A");
    if (matchingRows.includes("P")) {
      return "P";
    } 
    else if (matchingRows.includes("A")) {
      return "A";
    } 
    else if (matchingRows.includes("Yes")) {
      return "Yes";
    } 
    
    else {
      return "";
    }
    // console.log("Has match:__", hasMatch);
    // console.log("Has 2 match:__", hasMatch2);

    // return hasMatch ? "P" : "A";
  };

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <TableContainer
            component={Paper}
            style={{ border: "1px solid #ccc", borderRadius: 0 }}
          >
            <Table>
              <TableHead>
                <TableRow>
                <HeaderTableCell2> </HeaderTableCell2>
                  <HeaderTableCell3>
                     {/* {capitalizeFirstLetter(batchName)}  */}
                     </HeaderTableCell3>
                  
                </TableRow>
              </TableHead>

              <TableBody>
                {studentsData.map((studentData, index) => (
                  <TableRow key={index}>
                    <CustomTableCell3>{index+1}</CustomTableCell3>

                    <Tooltip
                                TransitionComponent={Zoom}
                                title={
                                  studentData.name.length > 16
                                    ? capitalizeFirstLetter(studentData.name)
                                    : ""
                                }
                                arrow
                              >
                                <CustomTableCell2
                                  align="left"
                                >
                                  {capitalizeFirstLetter(
                                    truncateString(studentData.name, 16)
                                  )}
                                </CustomTableCell2>
                              </Tooltip>

                    {/* <CustomTableCell2>{capitalizeFirstLetter(studentData.name)}</CustomTableCell2> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={9}>
          <Scrollbar>
            <TableContainer
              component={Paper}
              style={{ borderTop: "1px solid #ccc", borderRadius: 0 }}
            >
              <Table>
                <TableHead>
                  <TableRow key={uuid()}>
                    {headers.map((header, index) => (
                      <HeaderTableCell key={index} >
                        {header.label}
                      </HeaderTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {attendanceRowsData.map((value, id) => (
                    <TableRow key={id}>
                      {headers.map((header, index) => (
                        <CustomTableCell key={index}>
                          {isMatchingDate(header.date, value) === "P" ? (
                            <span style={{ color: "green", fontWeight: 500 }}>
                              P
                            </span>
                          ) 
                          : isMatchingDate(header.date, value) === "A" ? (
                            <span style={{ color: "red", fontWeight: 500 }}>
                              A
                            </span>
                          ) 
                          // : isMatchingDate(header.date, value) === "Yes" ? (
                          //   <span style={{ color: "black", fontWeight: 500 }}>
                          //     - -
                          //   </span>
                          // ) 
                          
                          : (
                            isSaturdayOrSunday(header.date)
                            // "-"
                          )}
                        </CustomTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>

        {/* <Grid item xs={3}>
          <TableComponent data={data2} title="Table 3" />
        </Grid> */}
      </Grid>
    </>
  );
};

export default CalendarTableHeader;
