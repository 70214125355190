import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";

import { addTestimonial } from "src/DAL/testimonial";
import { listActivePage } from "src/DAL/page";
import { _add_slider } from "src/DAL/slider";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import TinyEditor from "src/components/Ckeditor";
const AddSlider = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [pages, setPages] = useState([]);
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");
  const [detailDescriptionEditor, setDetailDescriptionEditor] = useState("");
  const { id } = useParams();
  const [formInputs, setFormInputs] = useState({
    name: "",
    title: "",

    description: "",
    created_for: "",
    pages: "",
    button_text: "",
    button_link: "",
    status: "true",
    image: "",
    type: 2,
  });

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("pathOfUploadedFile", pathOfUploadedFile);
    if (!pathOfUploadedFile) {
      error = "Image is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (formInputs.type === 2) {
      if (!detailDescriptionEditor) {
        error = "Description is required.";
        enqueueSnackbar(error, { variant: "error" });
        return;
      }
      }

 
    let postData = {
      title: formInputs.title,
      type: formInputs.type,
      image: pathOfUploadedFile,
      description: detailDescriptionEditor,
      // description: formInputs.description,
      status: formInputs.status,
      //   page_id: "65e60f718a889a3a74f3402d",
      
      page_id: id,
      // page_id: Array.isArray(formInputs.pages)
      //   ? formInputs.pages
      //   : formInputs.pages,
      //   page_id: Array.isArray(formInputs.pages)
      //     ? formInputs.pages
      //     : [formInputs.pages],
      button_text: formInputs.button_text,
      button_link: formInputs.button_link,
    };

    if(formInputs.type===1){
        postData = {
            title: formInputs.title,
            type: formInputs.type,
            image: pathOfUploadedFile,
            // description: formInputs.description,
            status: formInputs.status,
            //   page_id: "65e60f718a889a3a74f3402d",
            page_id: id,
            // page_id: Array.isArray(formInputs.pages)
            //   ? formInputs.pages
            //   : formInputs.pages,
            //   page_id: Array.isArray(formInputs.pages)
            //     ? formInputs.pages
            //     : [formInputs.pages],
            // button_text: formInputs.button_text,
            // button_link: formInputs.button_link,
          };
    }
    // data.append(
    //   "page_id",
    //   JSON.stringify(
    //     Array.isArray(formInputs.pages) ? formInputs.pages : [formInputs.pages]
    //   )
    // ); 
    console.log("postData ____postData", postData);
    setIsLoading(true);
    const result = await _add_slider(postData);
    if (result.code === 200) {
      navigate(-1);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      setTimeout(() => {
        setIsLoading(false);
    }, 1000);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActivePages = async () => {
    setIsLoading(true);
    const response = await listActivePage();

    if (response.code === 200) {
      const pages = response.pages.map((page) => ({
        id: page._id,
        title: page.page_title,
      }));
      setPages(pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActivePages();
  }, []);

  //   useEffect(() => {
  //     if (pages.length > 0) {
  //       setFormInputs((prevInputs) => ({
  //         ...prevInputs,
  //         pages: [pages[0].id],
  //       }));
  //     }
  //   }, [pages]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Slider">
    <div className="container px-4 mb-5 container_in_all_pages">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={handleNav} sx={{ flex: 0 }}>
          <Iconify icon="ep:back" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h4 className="title_heading_in_all_pages_top_left">Add Slider</h4>
      </Grid>
       

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {/* <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                /> */}

                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {/* <TextField
                  fullWidth
                  label="Created For"
                  value={formInputs.created_for}
                  onChange={handleChange}
                  name="created_for"
                  autoComplete="off"
                  required
                /> */}
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Type"
                  select
                  value={formInputs.type}
                  onChange={handleChange}
                  name="type"
                  required
                >
                  <MenuItem value={1}>Only Image</MenuItem>
                  <MenuItem value={2}>General</MenuItem>
                </TextField>
              </Stack>
              
              {formInputs.type===2?( 
              // <TextField
              //   fullWidth
              //   label="Description"
              //   value={formInputs.description}
              //   onChange={handleChange}
              //   name="description"
              //   autoComplete="off"
              //   required
              //   multiline
              //   rows={2}
              // />

              <div className="col-12">
              <p className="assignment_sub_heading_in_add_edit">
              Description *
              </p>

              <TinyEditor
                detailDescriptionCk={detailDescriptionEditor}
                setDetailDescription={setDetailDescriptionEditor}
              />
            </div>

              ):("")}

              {/* <TextField
                fullWidth
                label="Page"
                select
                value={formInputs.pages}
                onChange={handleChange}
                name="pages"
                required
                // SelectProps={{ multiple: true }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} value={page.id}>
                    {page.title}
                  </MenuItem>
                ))}
              </TextField> */}

              {formInputs.type===2?( 
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Button Text"
                  value={formInputs.button_text}
                  onChange={handleChange}
                  name="button_text"
                  autoComplete="off"
                  // required
                />
                <TextField
                  fullWidth
                  label="Button Link"
                  value={formInputs.button_link}
                  onChange={handleChange}
                  name="button_link"
                  autoComplete="off"
                  // required
                />
              </Stack>
      ):("")}
              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File *"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AddSlider;
