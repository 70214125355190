import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Label } from "src/components";
import DataNotFound from "src/components/GeneralComponents/DataNotFound";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import Paper from "@mui/material/Paper";
import { s3baseUrl } from "src/config/config";
import AssignmentGiveMarks from "../AssignmentGiveMarks";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import AssignmentStudentsTableMoreMenu from "./AssignmentStudentsTableMoreMenu";
import { formatDateTimeWithOutZone } from "src/utils";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
const AssignmentStudentsTable = ({
  students,
  setStudents,
  assignmentData,
  batchID,
  assignment_status,
  assignment_position,
  setStudentPendingCount,
  setStudentSubmitCount,
  setStudentApproveCount,
}) => {
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: "500",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "left", // Add text alignment to center
  };
  const myStyle = {
    textTransform: "capitalize",
    // fontSize: '5px',
    // padding: '10px 0px',

    // fontSize: '8px',
    // fontSize: "8pt",
    // padding: "0.75rem",
    // textAlign: "left", // Add text alignment to center
  };

  const [openModal, setOpenModal] = useState(false);
  const [newStudents, setNewStudents] = useState(
    assignment_position == 1
      ? students.unsubmitted_assignment_students
      : assignment_position == 2
      ? students.submitted_assignment_students
      : assignment_position == 3
      ? students.approved_assignment_students
      : []
  );

  const [selectedRowStudent, setselectedRowStudent] = useState("");

  const handleCloseModal = () => setOpenModal(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  const handleDownloadClick = (rowData) => {
    // Use the "click" method to trigger the download
    const link = document.createElement("a");
    link.href = s3baseUrl + rowData.assignment_url;
    link.download = "downloaded-file"; // Set the desired file name
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Recommended for security reasons when using target="_blank"
    link.click();
  };

  const getStudentsForAssignmentPosition = () => {
    switch (assignment_position) {
      case 1:
        return students.unsubmitted_assignment_students;
      case 2:
        return students.submitted_assignment_students;
      case 3:
        return students.approved_assignment_students;
      default:
        return [];
    }
  };

  useEffect(() => {

  console.log(" students approved_assignment_students __approved_assignment_students",students.approved_assignment_students);
  setStudentPendingCount(students.unsubmitted_assignment_students.length);
  setStudentSubmitCount(students.submitted_assignment_students.length);
  setStudentApproveCount(students.approved_assignment_students.length);
  

  }, [ students])
  
  return (
    <>
      <TableContainer component={Paper}>
        {/* <span style={{ textAlign: "center", fontWeight: "bolder" }}>
      Students{" "}
    </span> */}

        {getStudentsForAssignmentPosition().length > 0 ? (
          <Table
            sx={{ minWidth: 650, paddingBottom: "30px", marginBottom: "30px" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={myStyle2}>#</TableCell>
                <TableCell style={myStyle2}>Name</TableCell>
                <TableCell style={myStyle2}>Father Name</TableCell>
                <TableCell style={myStyle2}>Phone</TableCell>
                <TableCell style={myStyle2}>Assignment Status</TableCell>

                {assignment_status === 2 || assignment_status === 3 ? (
                  <TableCell style={myStyle2}>Submission Time</TableCell>
                ) : (
                  ""
                )}
                {assignment_status === 3 ? (
                  <TableCell style={myStyle2}>Obtained Marks</TableCell>
                ) : (
                  ""
                )}
                  {assignment_status === 3 ? (
                  <TableCell style={myStyle2}>Percentage</TableCell>
                ) : (
                  ""
                )}

                  {assignment_status === 3 ? (
                  <TableCell style={myStyle2}>Result</TableCell>
                ) : (
                  ""
                )}
                <TableCell style={myStyle2}> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getStudentsForAssignmentPosition().map((row, index) => {
                // console.log("row ___test", row);
                const {
                  _id,
                  name,
                  father_name,
                  contact_number,
                  assignment_status,
                  obtained_marks,
                  assignment_submission_time,
                  percentage,
                  result_status,
                } = row;
                let number = index + 1;
                return (
                  <TableRow
                    hover
                    key={_id}
                    tabIndex={-1}
                    role="checkbox"
                    //   selected={isItemSelected}
                    //   aria-checked={isItemSelected}
                  >
                    <TableCell align="left" style={myStyle}>
                      {number}
                    </TableCell>

                    <TableCell align="left" style={myStyle}>
                      {capitalizeFirstLetter(name)}
                    </TableCell>

                    {/* <TableCell align="left">
                      <div
                        className="on_click_text"
                        // style={{
                        //   cursor: "pointer",
                        // }}
                        onClick={() => handleOpenModal(row)}
                      >
                        {capitalizeFirstLetter(name)}
                      </div>
                    </TableCell> */}

                    <TableCell align="left" style={myStyle}>
                      {textEmptyToReturnHyphen(father_name)}
                    </TableCell>

                    <TableCell align="left" style={myStyle}>
                      +92{contact_number}
                    </TableCell>

                    <TableCell align="left">
                      <Label
                        variant="outlined"
                        color={
                          (assignment_status === 1 && "warning") ||
                          (assignment_status === 3 && "success") ||
                          "info"
                        }
                      >
                        {assignment_status === 1
                          ? "Pending"
                          : assignment_status === 2
                          ? "Submitted"
                          : assignment_status === 3
                          ? "Approved"
                          : "Not Set"}
                      </Label>
                    </TableCell>

                    {assignment_status === 2 || assignment_status === 3 ? (
                      <TableCell align="left" style={myStyle}>
                        {assignment_submission_time
                          ? formatDateTimeWithOutZone(
                              assignment_submission_time
                            )
                          : " - - "}
                      </TableCell>
                    ) : (
                      ""
                    )}

                    {assignment_status === 3 ? (
                      <TableCell align="left" style={myStyle}>
                        {obtained_marks
                          ? `${obtained_marks} / ${assignmentData?.total_marks} `
                          : " - - "}
                      </TableCell>
                    ) : (
                      ""
                    )}

   {assignment_status === 3 ? (
                      <TableCell align="left" style={myStyle}>
                        {percentage
                          ? `${percentage}% `
                          : " - - "}
                      </TableCell>
                    ) : (
                      ""
                    )}
   {assignment_status === 3 ? (
                      <TableCell align="left" style={myStyle}>
                         <Label
                        variant="outlined"
                        color={
                          (result_status === 1 && "warning") ||
                          (result_status === 2 && "success") ||
                          (result_status === 3 && "error") ||
                          "info"
                        }
                      >
                        {result_status === 1
                          ? "Pending"
                          : result_status === 2
                          ? "Pass"
                          : result_status === 3
                          ? "Fail"
                          : "Not Set"}
                      </Label>

                        {/* {result_status
                          ? `${result_status} `
                          : " - - "} */}
                      </TableCell>
                    ) : (
                      ""
                    )}

                    <TableCell align="left">
                      {privilegesTypeCheckAndShowOrNot(
                        get_user_profile,
                        get_user_privilege_access,
                        "assignment",
                        "edit"
                      ) ? (
                        <AssignmentStudentsTableMoreMenu
                          row={row}
                          students={students}
                          onStudentsUpdate={setStudents}
                          batch_id={batchID}
                          assignment_position={assignment_position}
                          batchID={batchID}
                          assignmentData={assignmentData}
                        />
                      ) : (
                        ""
                      )}
                      {row.assignment_url !== "" &&
                      row.assignment_url !== null ? (
                        // <Button
                        //   variant="outlined"
                        //   color="info"
                        //   // startIcon={<GetAppIcon />}
                        //   onClick={() => handleDownloadClick(row)}
                        //   size="small"
                        //   sx={{
                        //     fontSize: "7px", // or any other valid CSS font size value
                        //   }}
                        // >
                        //   Download
                        // </Button>
                        <></>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Table
            sx={{ minWidth: 650, paddingBottom: "30px", marginBottom: "30px" }}
            aria-label="simple table"
          >
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                  <DataNotFound searchQuery={"Students"} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default AssignmentStudentsTable;
