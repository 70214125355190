import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Grid,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Iconify } from "src/components";
import { CircularLoader, Page } from "src/components";
import { detailBatch, editBatch } from "src/DAL/batch";
import { _course_instructors, listActiveCourse } from "src/DAL/course";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "src/utils/formatText";

const Edit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const [instructors, setInstructors] = useState([]);

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "",
    order: "",
    courses: "",
    start_date_batch_select: "",
    end_date_batch_select: "",

    start_time_class: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),

    end_time_class: dayjs(new Date()),
    format_end_time: dayjs(dayjs(new Date())).format("HH:mm"),

    registration_fee: 0,
    batch_discount: 0,
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
    batch_status: 1,
    batch_duration_months: "",
    batch_assignment_count: "",
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  // const handleTimeChange = (time) => {
  //   const formattedTime = dayjs(time).format("HH:mm");
  //   setFormInputs((values) => ({
  //     ...values,
  //     format_time: formattedTime,
  //     time: time,
  //   }));
  // };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const handleEndTimeChange = (time) => {
    console.log("time", time);
    const formattedTime = dayjs(time).format("HH:mm");
    console.log("time formattedTime", formattedTime);
    setInputs((values) => ({
      ...values,
      format_end_time: formattedTime,
      end_time: time,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      courses: value,
    }));

    fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_start_date_batch_select: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration: date }));
  // };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   const formattedDate = dayjs(date).format("MM/DD/YYYY");
  //   setFormInputs((values) => ({ ...values, start_date_batch_select: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };

  // const handleDateChange2 = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration2: date }));
  // };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  // const handleEndTimeChange = (time) => {
  //   setEndTime(time);
  // };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formInputs", formInputs);
    console.log("formInputs2", formInputs2);
    console.log("inputs", inputs);

    setIsLoading(true);
    // console.log(formInputs.courses);
    const data = new FormData();
    data.append("name", formInputs.name);
    data.append("total_seats", formInputs.total_seats);
    data.append("status", formInputs.status);
    // data.append("order", formInputs.order);

    // data.append("course_id", JSON.stringify(formInputs.courses));

    // data.append("courses", JSON.stringify(formInputs.courses));
    data.append("course", formInputs.courses);

    data.append("start_time_class", inputs.format_time);
    data.append("end_time_class", inputs.format_end_time);
    data.append("start_date_batch", formInputs.start_date_batch_select);
    data.append("end_date_batch", formInputs.end_date_batch_select);

    // data.append("instructors", [formInputs.instructors]);
    data.append("instructors", JSON.stringify(formInputs.instructors));
    // data.append("registration_fee", formInputs.registration_fee);
    // data.append("batch_fee", additionalFields);
    data.append("batch_fee", JSON.stringify(additionalFields));
    // data.append("batch_discount", formInputs.batch_discount);
    data.append("batch_duration_months", formInputs.batch_duration_months);
    data.append("batch_assignment_count", formInputs.batch_assignment_count);
    // console.log(data.get("name"));
    // console.log(data.get("courses"));
    const result = await editBatch(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/batches");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await detailBatch(id);
    console.log("response detailBatch __detailBatch", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.batch.start_date_batch) {
        defaultStartDate = new Date(response.batch.start_date_batch);
        console.log("defaultStartDate", defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultStartDate", defaultStartDate);

      let defaultEndDate = new Date();
      console.log("defaultEndDate", defaultEndDate);
      if (response.batch.end_date_batch) {
        defaultEndDate = new Date(response.batch.end_date_batch);
        console.log("defaultEndDate", defaultEndDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultEndDate", defaultEndDate);

      let startTime = dayjs(
        new Date(dayjs(response.batch.start_time_class, "HH:mm").toDate())
      );
      console.log("startTime", startTime);

      let endTime = dayjs(
        new Date(dayjs(response.batch.end_time_class, "HH:mm").toDate())
      );
      console.log("endTime", endTime);
      // Convert the moment objects back to dayjs objects and format them as strings
      //  let formattedStartDate = start_Date.format("YYYY-MM-DD");
      //  let formattedEndDate = end_Date.format("YYYY-MM-DD");

      //  // Set the formatted dates in the formInputs2 state
      //  setFormInputs2({
      //   duration: formattedStartDate,
      //   duration2: formattedEndDate,
      // });

      // let EndDate = moment();

      // Validate the selected date format
      // const formattedStartDate = startDate.format("YYYY-MM-DD");
      // let testDate = moment(formattedStartDate);

      console.log("startDate from detail", startDate);
      // console.log("formattedStartDate testDate from detail", testDate);

      // if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      //   error = "Invalid Start Date Format.";
      //   enqueueSnackbar(error, { variant: "error" });
      //   return;
      // }

      // console.log("sss", data);
      // console.log(
      //   response.batch.courses.map((course) => course.title),
      //   "log"
      // );

      setInputs((values) => ({
        ...values,
        time: startTime,
        format_time: response.batch.start_time_class,

        end_time: endTime,
        format_end_time: response.batch.end_time_class,
      }));

      // let courseIds = response.batch.courses.map((course) => course._id);
      let courseIds = response.batch.course._id;
      let instructorIds = response.batch.instructors.map(
        (instructor) => instructor._id
      );

      fetchInstructors(courseIds);
      setAdditionalFields(response.batch.batch_fee);
      setFormInputs({
        ...response.batch,
        // name: response.batch.name,
        name: capitalizeFirstLetter(response.batch.name),
        total_seats: response.batch.total_seats,
        status: response.batch.status,
        order: response.batch.order,
        courses: courseIds,
        instructors: instructorIds,
        batch_status: response.batch.batch_status
          ? response.batch.batch_status
          : 1,

        // registration_fee: response.batch.registration_fee,
        batch_discount: response.batch.batch_discount,
        batch_discount: response.batch.batch_discount,

        start_time_class: startTime,
        end_time_class: endTime,
        // time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),
        // format_time: formattedTime,

        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,

        end_date_batch: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,
        end_date_batch_select: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchInstructors = async (data) => {
    setIsLoading(true);
    const response = await _course_instructors({
      courses: [data],
    });
    console.log("response listInstructor _listInstructor", response);
    if (response.code === 200) {
      setIsLoading(false);

      // const instructors = response.instructors.map((instructor) => ({
      //   id: instructor._id,
      //   first_name: instructor.first_name,
      //   last_name: instructor.last_name,
      //   email: instructor.email,
      //   image: instructor.image,
      //   status: instructor.status,
      // }));

      setInstructors(response.instructors);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (instructors.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        instructors: [instructors[0]._id],
      }));
    }
  }, [instructors]);

  const fetchActiveCourses = async () => {
    const response = await listActiveCourse();

    if (response.code === 200) {
      const courses = response.courses.map((course) => ({
        ...course,
        id: course._id,
        title: course.title,
        description: course.description,
        duration: course.duration,
        image: course.image,
        status: course.status,
      }));
      setCourses(courses);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  useEffect(() => {
    fetchDetailBatch();
    fetchActiveCourses();
  }, []);

  useEffect(() => {
    console.log("formInputs _formInputs", formInputs);
  }, [formInputs]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Batch">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Batch </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
                <TextField
                  fullWidth
                  label="Total Seat"
                  value={formInputs.total_seats}
                  onChange={handleChange}
                  name="total_seats"
                  required
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Registration Fee"
                  value={formInputs.registration_fee}
                  onChange={handleChange}
                  name="registration_fee"
                  required
                  disabled={formInputs.batch_status !== 1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                /> */}

              {/* <TextField
                  fullWidth
                  label="Discount"
                  value={formInputs.batch_discount}
                  onChange={handleChange}
                  name="batch_discount"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                /> */}
              {/* </Stack> */}
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Batch Duration Months"
                  value={formInputs.batch_duration_months}
                  onChange={handleChange}
                  name="batch_duration_months"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}

                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>
              {/* <TextField
                fullWidth
                label="Order"
                value={formInputs.order}
                onChange={handleChange}
                name="order"
                required
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              /> */}

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Courses"
                  select
                  value={formInputs.courses}
                  onChange={handleChange2}
                  name="courses"
                  required
                  SelectProps={{ multiple: false }}
                >
                  {courses &&
                    courses.map((course) => (
                      <MenuItem key={course.id} value={course.id}>
                        {capitalizeFirstLetter(course.title)} (Fee: {course.fee}
                        )
                      </MenuItem>
                    ))}
                </TextField>
                <TextField
                  fullWidth
                  label="Batch Assignment Count"
                  value={formInputs.batch_assignment_count}
                  onChange={handleChange}
                  name="batch_assignment_count"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}

                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>
              <TextField
                fullWidth
                label="Instructors"
                select
                value={formInputs.instructors}
                onChange={handleChange3}
                name="instructors"
                required
                SelectProps={{ multiple: true }}
              >
                {instructors.map((instructor) => (
                  <MenuItem key={instructor._id} value={instructor._id}>
                    {capitalizeAllFirstLetters(instructor.name)}
                  </MenuItem>
                ))}
              </TextField>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DemoContainer components={['MobileTimePicker', 'MobileTimePicker']}> */}

                  {/* <MobileTimePicker
                    label={"Batch Start Time *"}
                    openTo="hours"
                    // selected={startTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={startTime}
                    value={inputs?.time}
                    onChange={handleTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  /> */}

                  <TimePicker
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label={"Batch Start Time *"}
                    openTo="hours"
                    views={["hours", "minutes"]}
                    value={inputs?.time}
                    onChange={handleTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  <TimePicker
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label={"Batch End Time *"}
                    openTo="hours"
                    views={["hours", "minutes"]}
                    value={inputs?.end_time}
                    onChange={handleEndTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  {/* <TimePicker
                    
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label={"Batch Start Time *"}
                    openTo="hours"
                    // selected={startTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={startTime}
                    value={inputs?.time}
                    onChange={handleTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
<TimePicker
                    
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    label={"Batch End Time *"}
                    openTo="hours"
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={formInputs?.end_time_class}
                    value={inputs?.end_time}
                    onChange={handleEndTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%",
                      // marginTop: "20px",
                    }}
                  /> */}

                  {/* <MobileTimePicker
                    label={"Batch End Time *"}
                    openTo="hours"
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={formInputs?.end_time_class}
                    value={inputs?.end_time}
                    onChange={handleEndTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%",
                      // marginTop: "20px",
                    }}
                  /> */}

                  {/* </DemoContainer> */}
                </LocalizationProvider>
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                {/* <div className="col-md-12 col-xs-12"> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Batch Start Date"
                    // format="YYYY-MM-DD"
                    // format="YYYY-MM-DD"
                    // value={formInputs?.start_date_batch}
                    value={dayjs(formInputs.start_date_batch)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>

                {/* </div> */}

                {/* <div className="col-md-12 col-xs-12"> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // disabled
                    label="Batch End Date"
                    // format="YYYY-MM-DD"
                    // value={formInputs?.start_date_batch}
                    value={dayjs(formInputs.end_date_batch)} // Convert the default date to a Day.js object
                    onChange={handleDateChange2}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
                {/* </div> */}
              </Stack>

              {additionalFields.map((field, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* Conditionally render the Remove button */}
                  {index !== 0 && (
                    <IconButton
                      aria-label="Delete Field"
                      onClick={() => handleDeleteField(index)}
                      disabled={formInputs.batch_status !== 1}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <TextField
                    fullWidth
                    label={`Fee Title ${index + 1}`}
                    name="fee_title"
                    value={field.fee_title}
                    onChange={(e) => handleFieldChange(e, index)}
                    sx={{ marginRight: "10px" }}
                    required
                    disabled={formInputs.batch_status !== 1}
                  />

                  <TextField
                    fullWidth
                    label={`Fee ${index + 1}`}
                    name="fee"
                    value={field.fee}
                    onChange={(e) => handleFieldChange(e, index)}
                    sx={{ marginRight: "10px" }}
                    required
                    disabled={formInputs.batch_status !== 1}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R.S</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      // maxLength: 10, // Limit the input to 10 characters
                      // title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddField} // Your function to add a new field
                    sx={{ marginLeft: "10px" }}
                    disabled={formInputs.batch_status !== 1}
                  >
                    Add
                  </Button>
                </div>
              ))}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
