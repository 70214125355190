import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  TableHead,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { formatDateTimeWithOutZone, timePreferencesObjTest } from "src/utils";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
const StudentDetail = ({ studentID }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    fontSize: "1.3rem", // Adjust the font size as needed
    color: "#fff!important",
  };

  const myStyleHeader3 = {
    backgroundColor: "#0c2e60",
    color: "#fff",
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    contact_number: "",
    status: "",

    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: {
      _id: "0",
      type: "",
      start_time: "00:00",
      end_time: "00:00",
    },

    discount: 0,
    fee_status: "all_paid",
    batch_name: "",
    student_batches: [],
    additional_numbers: [],
    date_of_birth: null,
    activity_track: [],
    course:{title:" _ _ "},
  });

  function formatTime(startTime) {
    if (startTime && startTime !== "") {
      // Parse the time strings using moment
      const startMoment = moment(startTime, "HH:mm");
      // Format the start time and end time with AM/PM
      const formattedStartTime = startMoment.format("hh:mm A");
      const formattedTime = `${formattedStartTime}`;
      return formattedTime;
    } else {
      return "";
    }
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    let date = new Date();
    if (dateStr !== null) {
      date = new Date(dateStr);
    }

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatDateToMMDDYYYY(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${month}-${day}-${year}`;
  }

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(studentID);
    console.log("response response detailStudent detail __detail", response);
    if (response.code === 200) {
      // setAdditionalFields(response.student.additional_numbers);

      // setCourses(response.student.courses);
      // const courseIds = response.student.courses.map((course) => course._id);

      // const batchIds = response.student.batch_id.map((batch) => batch._id);

      let batchIds = "";

      if (response.student.batch) {
        batchIds = response.student.batch._id;
      }

      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];
      // let defaultDate = new Date();
      // if (response.student.date_of_birth) {
      //   defaultDate = new Date(response.student.date_of_birth);
      //   console.log('defaultDate dob _dob',defaultDate);
      // }
      // console.log('defaultDate',defaultDate);
      //  if(response.student.date_of_birth ==="undefined"){
      //   console.log('defaultDate Date of birth is undefined  ');
      //  }else {

      //   console.log('defaultDate Date of birth is defined');
      //  }

      // const additionalFieldsFromBatch = response.student.decided_fee.map(
      //   (fields, index) => ({
      //     fee: fields.fee,
      //     month: fields.month,
      //     fee_status: fields.fee_status,
      //     // pending_fee: fields.pending_fee?fields.pending_fee:null,
      //     pending_fee: fields.pending_fee ? fields.pending_fee : 0,
      //     // pending_fee: fields.pending_fee,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs({
        ...response.student,
        name: response.student.name,
        father_name: response.student.father_name,
        email: response.student.email,
        gender: response.student.gender,
        // student_id: response.student.student_id,
        address: response.student.address,
        contact_number: response.student.contact_number,
        // fee_status: response.student.fee_status,
        status: response.student.status,

        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,
        // image: response.student.profile_image,
        // courses: courseIds,
        // batches: batchIds,
        // reg_type: response.student.reg_type,

        // course_fee: response.student.course_fee,
        // actual_fee: response.student.actual_fee,

        // registration_fee: response.student.total_registration_fee,
        // decided_fee: response.student.decided_fee,
        // discount: response.student.discount ? response.student.discount : 0,

        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        additional_numbers: response.student.additional_numbers
          ? response.student.additional_numbers
          : [],

        time_preferences: response.student.time_preference
          ? timePreferencesObjTest(response.student.time_preference)
          : {
              _id: "0",
              type: "",
              start_time: "00:00",
              end_time: "00:00",
            },
        // wallet_amount: response.student.wallet_amount
        //   ? response.student.wallet_amount
        //   : 0,

        // friend_number: response.student.friend_number,
        // family_number: response.student.family_number,
        // whatsapp_number: response.student.whatsapp_number,

        date_of_birth: response.student.date_of_birth
          ? response.student.date_of_birth
          : null,

        // dob: defaultDate.toISOString().split("T")[0]
        //   ? defaultDate.toISOString().split("T")[0]
        //   : null,
        // dob: defaultDate.toISOString().split("T")[0],

        // discount: response.student.discount,
        // fee_status:
        //   response.student.fee_status === 1
        //     ? "All Paid"
        //     : response.student.fee_status === 2
        //     ? "Installments"
        //     : "Is Free",
        createdAt: response.student.createdAt,
        updatedAt: response.student.updatedAt,
        activity_track: response.student.activity_track
          ? response.student.activity_track
          : [],

        student_batches: response.student.batch ? response.student.batch : [],
        note: response.student.note ? response.student.note : "",
      });

      // ["is_free", "installments", "all_paid"]= [3,2,1]

      // if (response.student.profile_image) {
      //   setImage(response.student.profile_image);
      //   setShow(true);
      // }
      //   if (batchIds && batchIds.length > 0) {
      //     fetchActiveBatches(batchIds);
      //   } else {
      //     fetchActiveBatches();
      //   }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Student Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        {/* <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Student Detail
          </Typography>
        </Grid> */}

        <div className="container ">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Name
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Father Name
                  </TableCell>
                  <TableCell align="left">
                    {formInputs.father_name !== ""
                      ? capitalizeFirstLetter(formInputs.father_name)
                      : " _ _ "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Date Of Birth
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.date_of_birth
                      ? formatDate(formInputs.date_of_birth)
                      : " _ _ "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Email
                  </TableCell>
                  <TableCell align="left">
                    {formInputs.email !== "" ? formInputs.email : " _ _ "}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Contact Number
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    +92{formInputs.contact_number}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {formInputs.additional_numbers.map((number, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={myStyleHeader}>
                      ({index + 1}) Additional Number
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {number.type}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                      +92{number.number}
                    </TableCell>
                  </TableRow>
                ))}

                {formInputs.student_batches.map((batche, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={myStyleHeader}>
                      ({index + 1}) Batch Name
                    </TableCell>
                    <TableCell align="left">
                      {capitalizeFirstLetter(batche.name)}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Registration Fee
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.registration_fee}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Course
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.course.title}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Time Preferences
                  </TableCell>
                  {/* <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.time_preferences.type) !== ""
                      ? capitalizeFirstLetter(formInputs.time_preferences.type)
                      : " _ _ "}
                  </TableCell> */}

                  <TableCell align="left">
                    {capitalizeFirstLetter(formInputs.time_preferences.type) !==
                    ""
                      ? capitalizeFirstLetter(formInputs.time_preferences.type)
                      : " _ _ "}
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {capitalizeFirstLetter(formInputs.time_preferences.type) !==
                    "" ? (
                      <span>
                        {" From "}
                        {formatTime(formInputs.time_preferences.start_time)}
                        {" To "}
                        {formatTime(formInputs.time_preferences.end_time)}
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Gender
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.gender === 1 ? "Male" : "Female"}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {formInputs.latest_degree !== "" ? (
                  <>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Latest Degree
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.latest_degree}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Degree Status
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.degree_status === "Inprogress"
                          ? "In Progress"
                          : formInputs.degree_status}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>

                    {formInputs.degree_status === "complete" ? (
                      <TableRow>
                        <TableCell align="left" style={myStyleHeader}>
                          Completion Year
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {formInputs.completion_year}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {" "}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell align="left" style={myStyleHeader}>
                          Semester
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {formInputs.semester}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {" "}
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Institution Name
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.institution_name}
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {" "}
                      </TableCell>
                    </TableRow>
                  </>
                ) : null}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    <Label
                      variant="outlined"
                      color={
                        (formInputs.status === 2 && "error") ||
                        (formInputs.status === 1 && "success") ||
                        "error"
                      }
                    >
                      {formInputs.status === 2
                        ? "Inactive"
                        : formInputs.status === 1
                        ? "Active"
                        : formInputs.status === 3
                        ? "Trash"
                        : "Not Set"}
                      {/* {/ {sentenceCase(status)} /} */}
                    </Label>

                    {/* <Label
                      variant="outlined"
                      color={
                        (formInputs.status === false && "error") ||
                        (formInputs.status === true && "success") ||
                        "error"
                      }
                    >
                      {formInputs.status === false
                        ? "Inactive"
                        : formInputs.status === true
                        ? "Active"
                        : "Not Set"}

                    </Label> */}
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Referred By
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {textEmptyToReturnHyphen(formInputs.referred_by)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    CNIC
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {textEmptyToReturnHyphen(formInputs.cnic)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Address
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {textEmptyToReturnHyphen(formInputs.address)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Note
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.note}
                  </TableCell>
                  <TableCell align="left" style={myStyle}> </TableCell>
                </TableRow> */}

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Fee Status
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.fee_status}
                  </TableCell>
                </TableRow> */}

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Education
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.education}
                  </TableCell>
                </TableRow> */}

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Discount
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.discount}
                  </TableCell>
                </TableRow> */}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.createdAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(formInputs.updatedAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableBody>

              {privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "student",
                "admin"
              ) ? (
                <>
                  {formInputs.activity_track.length > 0 ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" style={myStyleHeader2}>
                          Activity Track{" "}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {" "}
                        </TableCell>
                        <TableCell align="left" style={myStyle}>
                          {" "}
                        </TableCell>
                      </TableRow>

                      {formInputs.activity_track.map((log, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" style={myStyleHeader}>
                            ({index + 1}) Moved By User ({" "}
                            {log.moved_by.first_name} {log.moved_by.last_name} ){" "}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            To Batch ( {log.batch.name} )
                          </TableCell>
                          <TableCell align="left" style={myStyle}></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Table>

            {formInputs.note !== "" ? (
              <Table
                sx={{
                  minWidth: "100%",
                  // paddingBottom: "30px",
                  marginTop: "18px",
                  // marginBottom: "30px",
                  border: "1px solid #f1f3f4", // Set the border style here
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={myStyleHeader3}>
                      Note
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/* <TableCell align="left" style={myStyleHeader}>
                    Note
                  </TableCell> */}
                    <TableCell align="left">{formInputs.note}</TableCell>
                    {/* <TableCell align="left" style={myStyle}>
                   
                  </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
              ""
            )}
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
};

export default StudentDetail;
