import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Toolbar,
  InputAdornment,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import { _addEnquiryStudent } from "src/DAL/enquirystudent";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterEnquiryStudent from "./components/FilterEnquiryStudent";
import { useSnackbar } from "notistack";
import DataNotFound from "src/components/GeneralComponents/DataNotFound";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import EnquiryStudentDetail from "./EnquiryStudentDetail";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { formatDateTimeWithOutZone } from "src/utils";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "enquiry_status_reason", label: "", alignRight: false },
  // { id: "father_name", label: "Father Name", alignRight: false },
  // { id: "date_of_birth", label: "Date Of Birth", alignRight: false },
  { id: "enquiry_time", label: "Enquiry Time", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  { id: "mobile_number", label: "Phone", alignRight: false },
  { id: "gender", label: "Gender", alignRight: false },
  { id: "courses", label: "For Course", alignRight: false },
  // { id: "referred_by", label: "Referred By", alignRight: false },
  { id: "enquiry_status", label: "Enquiry Status", alignRight: false },

  // { id: "others", label: "For Others", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },
  // { id: "batch", label: "Batch", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Students() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const myElementRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/enquiry-students/add-student");
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _addEnquiryStudent();
    console.log("response _addEnquiryStudent", response);
    if (response.code === 200) {
      const students = response.enquiry_form.map((student, index) => ({
        ...student,
        id: student._id,
        name: student.name,
        referred_by: student.referred_by,
        enquiry_status: student.enquiry_status,
        enquiry_status_reason: student.enquiry_status_reason,

        decided_course_fee: student.decided_course_fee,
        decided_registration_fee: student.decided_registration_fee,
        note: student.note,

        // father_name: student.father_name,
        // date_of_birth: student.date_of_birth,
        // enquiry_time: student.date_of_birth,
        // enquiry_time: formatDate(student.createdAt),
        enquiry_time:student.createdAt,
        email: student.email,
        mobile_number: student.contact_number,
        gender: student.gender,
        courses: student.course ? student.course : "",
        course: student.course ? student.course : "",
        others: student.others ? student.others : "",
        number: index + 1,
        // others: student.others ? student.others : "",
      }));
      // setStudents(students.reverse());
      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const SearchStudents = async (searchText) => {
    let response = [];
    // setIsLoading(true);
    let search_text = searchText;

    if (!search_text || search_text === "" || search_text === null) {
      search_text = "";
      response = await _addEnquiryStudent();
    } else {
      response = await _addEnquiryStudent({
        text: search_text,
      });
    }

    // const response = await listStudentSearch(searchText);

    console.log("response _addEnquiryStudent", response);

    if (response.code === 200) {
      const students = response.enquiry_form.map((student, index) => ({
        ...student,
        id: student._id,
        name: student.name,
        referred_by: student.referred_by,
        enquiry_status: student.enquiry_status,
        enquiry_status_reason: student.enquiry_status_reason,

        decided_course_fee: student.decided_course_fee,
        decided_registration_fee: student.decided_registration_fee,
        note: student.note,

        // father_name: student.father_name,
        // date_of_birth: student.date_of_birth,
        // enquiry_time: student.date_of_birth,
        // enquiry_time: formatDate(student.createdAt),
        enquiry_time: student.createdAt,
        email: student.email,
        mobile_number: student.contact_number,
        gender: student.gender,
        courses: student.course ? student.course : "",
        course: student.course ? student.course : "",
        others: student.others ? student.others : "",
        number: index + 1,
        // others: student.others ? student.others : "",
      }));
      // setStudents(students.reverse());
      setStudents(students);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      setIsLoading(true);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  useEffect(() => {
    console.log("students", students);
  }, [students]);

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  useEffect(() => {
    fetchStudents();

    // const students =  [
    //   {
    //   id: '123123',
    //   name:  'name',
    //   father_name: 'father name',
    //   date_of_birth: '20-02-2005',
    //   email: 'email',
    //   mobile_number: '03001234567',
    //   gender: 'Male',
    //   courses:  [{title:"title 1"},{title:"title 2"}],
    //   others:  [{title:"others 1"},{title:"others 2"}],

    //   // image: 'student.profile_image',
    //   // status: true,
    //   // batch:  "",
    //   // pref_time: '11:00AM',

    //   // batch: student.batch_id ? student.batch_id : "",
    //   // courses: student.courses ? student.courses : "",
    // }
    // ];

    // setStudents(students);
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Student">
      <Container maxWidth="xl">
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "enquiry",
          "view"
        ) ? (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <Typography variant="h4">Enquiry Students</Typography>

              {privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "enquiry",
                "add"
              ) ? (
                <Button
                  onClick={handleNav}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Enquiry Student
                </Button>
              ) : (
                ""
              )}
            </Stack>

            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

              <Scrollbar>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ padding: "10px" }}
                >
                  <TextField
                    label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                  />
                  {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                  <CustomResetButton handleReset={handleReset}/>

                  {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
                </Stack>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <StudentListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={students.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            id,
                            name,
                            mobile_number,
                            gender,
                            father_name,
                            enquiry_time,
                            email,
                            image,
                            // status,
                            batch,
                            courses,
                            course,
                            others,
                            // referred_by,
                            enquiry_status,
                            enquiry_status_reason,
                            number,
                            decided_course_fee,
                            decided_registration_fee,
                            note,
                          } = row;
                          const isItemSelected = selected.indexOf(name) !== -1;
                          // let number = index + 1;
                          // let { course, others } = row;
                          // let titleToShow = others.length > 0 ? others[0].course_title : course[0].title;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox"> */}
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, name)
                              }
                            /> */}
                              {/* </TableCell> */}

                              <TableCell align="left" style={myStyle}>
                                {number}
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                            {enquiry_status_reason}
                          </TableCell> */}

                              {/* <TableCell align="left" style={myStyle}>
                            {name}
                          </TableCell> */}

                              <TableCell align="left">
                                <div
                                  className="on_click_text"
                                  // style={{
                                  //   cursor: "pointer",
                                  // }}
                                  onClick={() => handleOpenModal(row)}
                                >
                                  {capitalizeFirstLetter(name)}
                                </div>
                              </TableCell>

                              {enquiry_status_reason !== "" ? (
                                <TableCell align="left" style={myStyle}>
                                  {/* {enquiry_status_reason} */}

                                  {/* <Tooltip title="View">
                                <Iconify
                                  icon="carbon:view"
                                  width={20}
                                  height={20}
                                />
                              </Tooltip> */}

                                  <Tooltip
                                    title={capitalizeFirstLetter(
                                      enquiry_status_reason
                                    )}
                                    arrow
                                  >
                                    <div ref={myElementRef}>
                                      <Iconify
                                        icon="ep:view"
                                        width={20}
                                        height={20}
                                      />
                                    </div>
                                  </Tooltip>
                                </TableCell>
                              ) : (
                                <TableCell
                                  align="left"
                                  style={myStyle}
                                ></TableCell>
                              )}

                              {/* <TableCell align="left" style={myStyle}>
                            {name}
                          </TableCell> */}

                              {/* <TableCell align="left" style={myStyle}>
                            {father_name}
                          </TableCell> */}

                              <TableCell align="left" style={myStyle}>
                                {formatDateTimeWithOutZone(enquiry_time)}
                              </TableCell>

                              {/* <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography> */}
                              {/* <TableCell align="left">{email}</TableCell> */}
                              <TableCell align="left" style={myStyle}>
                                +92{mobile_number}
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {gender === 1 ? "Male" : "Female"}
                              </TableCell>

                              {/* <TableCell align="left">
                            {" "}
                            <Avatar alt={name} src={s3baseUrl + image} />
                          </TableCell> */}
                              <TableCell align="left">
                                {/* {courses.length > 0
                              ? courses
                                  .map((course, index) => {
                                    return course !== null
                                      ? course.title
                                      : "N/A1";
                                  })
                                  .join(", ")
                              : "N/A2"} */}

                                {/* {courses.length > 0
                                  ? courses
                                      .map((course, index) => {
                                        return course !== null
                                          ? capitalizeFirstLetter(course.title)
                                          : "";
                                      })
                                      .join(", ")
                                  : others.length > 0
                                  ? others
                                      .map((other, index) => {
                                        return other !== null
                                          ? other.course_title
                                          : "";
                                      })
                                      .join(", ")
                                  : ""} */}

                                {/* {titleToShow} */}
                                {course.title}
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                                {textEmptyToReturnHyphen(referred_by)}
                              </TableCell> */}

                              <TableCell align="left">
                                <Label
                                  // do_not_pick_call, wrong_number, number_close
                                  variant="outlined"
                                  // color={
                                  //   enquiry_status === false
                                  //     ? "error"
                                  //     : enquiry_status === "do_not_pick_call"
                                  //     ? "error"
                                  //     : "success"
                                  // }

                                  color={
                                    enquiry_status === 5
                                      ? "success"
                                      : enquiry_status === "false"
                                      ? "success"
                                      : enquiry_status === 1
                                      ? "info"
                                      : enquiry_status === 2
                                      ? "warning"
                                      : enquiry_status === 3
                                      ? "info"
                                      : "error"
                                  }
                                >
                                  {/* do not distrub -> 1
                              wrong_number -> 2
                              number_close -> 3
                              expire -> 4
                              active -> 5 */}
                                  {enquiry_status === 5
                                    ? "Active"
                                    : enquiry_status === "false"
                                    ? "Active"
                                    : enquiry_status === 1
                                    ? "Do Not Distrub"
                                    : enquiry_status === 2
                                    ? "Wrong Number"
                                    : enquiry_status === 3
                                    ? "Number Close"
                                    : "Expire"}
                                </Label>
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                            {others.length > 0
                              ? others
                                  .map((other, index) => {
                                    return other !== null ? other.title : "N/A";
                                  })
                                  .join(", ")
                              : "N/A"}
                          </TableCell> */}
                              {/* <TableCell align="left" style={myStyle}>
                            {batch.length > 0
                              ? batch
                                  .map((batch, index) => {
                                    return batch !== null ? batch.name : "N/A";
                                  })
                                  .join(", ")
                              : "N/A"}
                          </TableCell> */}

                              {/* <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === false && "error") || "success"}
                            >
                              {status ? "Active" : "Inactive"}
                            
                            </Label>
                          </TableCell> */}

                              {privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "enquiry",
                                "edit"
                              ) ||
                              privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "enquiry",
                                "delete"
                              ) ? (
                                <TableCell align="right">
                                  <StudentMoreMenu
                                    row={row}
                                    students={students}
                                    onStudentsUpdate={handleStudentsUpdate}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell align="right"></TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                            {/* <DataNotFound searchQuery={"Enquiry"} /> */}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: students.length, label: "All" },
                ]}
                component="div"
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : (
          <NoAccessData />
        )}
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterEnquiryStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}
          />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Enquiry Student Detail"
        componentToPassDown={
          // <>
          //   <h1>hello</h1>
          //   <h1>hello</h1>
          //   <h1>hello</h1>
          //   <h1>hello</h1>
          // </>
          <EnquiryStudentDetail studentID={selectedRowStudent.id} />
        }
      />
    </Page>
  );
}
