import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

DataNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function DataNotFound({ searchQuery = "", ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Data Not Found
      </Typography>
      <Typography variant="body2" align="center">
        Sorry, no results were found for your search:{" "}
        <strong>"{searchQuery}"</strong>. Please try again.
      </Typography>
    </Paper>
  );
}
