import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Card,
  Button,
  Container,
  TableBody,
  Table,
  TableContainer,
  TableRow,
  Paper,
  TableCell,
  Icon,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _detail_brand, _edit_brand, _list_brand } from "src/DAL/brand";
import {
  _detail_asset_category,
  _edit_asset_category,
  _list_asset_category,
} from "src/DAL/asset_category";
import { _list_vendor } from "src/DAL/vendor";
import {
  _assign_company_asset,
  _delete_company_asset,
  _delete_repair_history,
  _detail_company_asset,
  _edit_company_asset,
} from "src/DAL/company_asset";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useAppContext } from "src/hooks";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";
import { formatNormalDate } from "src/utils/formatTime";
import { repairDefaltImage } from "src/assets";
import DelPopup from "src/components/DelPopup";

const CompanyAssetRepairList = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const handleNavAdd = () => {
    navigate("/company-asset/add-repair-details-asset/" + id);
  };

  const handleNavEdit = (repair) => {
    navigate(
      `/company-asset/edit-repair-details-asset/${id}?repair_id=${repair._id}`
    );
  };

  const handleNavDelete = () => {
    // navigate("/company-asset/add-repair-details-asset/" + id);
  };

  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [students, setStudents] = useState([]);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteRow, setDeleteRow] = useState("");

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    active_status: true,
    assigned_status: true,
    brand_id: "",
    category_id: "",
    vendor_id: "",
    device_id: "",
    quantity: "",
    price: "",
    link: "",
    details: "",
    start_date_batch_select: "",
    end_date_batch_select: "",
    student_id: "",
    repair_history: [],
  });

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 500, // Set the font weight to 500 for bold text
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };

  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    fontSize: "1.3rem", // Adjust the font size as needed
    color: "#fff!important",
  };

  const myStyleHeader3 = {
    backgroundColor: "#0c2e60",
    color: "#fff",
  };

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleDeleteClick = (repair) => {
    setIsOpen(false);
    setOpenDelete(true);
    setDeleteRow(repair);
  };
  const handleDeleteOk = () => {
    handleClick(deleteRow);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };

  async function handleClick(row) {
    //console.log(row);

    const result = await _delete_repair_history({
      company_asset_id: id,
      repair_history_id: row._id,
    });

    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students._id !== row._id
      );
      setStudents(updatedStudents);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      company_asset_id: id,
      student_id: formInputs.student_id,
    };

    console.log("postData", postData);

    setIsLoading(true);

    // const result = await _add_company_asset(postData);
    const result = await _assign_company_asset(postData);
    console.log("result _edit_company_asset", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/company-asset");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_company_asset(id);
    console.log("_detail_company_asset _detail_company_asset", response);
    // enquiry_form

    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.company_asset.purchase_date) {
        defaultStartDate = new Date(response.company_asset.purchase_date);
        console.log("defaultStartDate", defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultStartDate", defaultStartDate);

      let defaultEndDate = new Date();
      console.log("defaultEndDate", defaultEndDate);
      if (response.company_asset.warranty_expire_date) {
        defaultEndDate = new Date(response.company_asset.warranty_expire_date);
        console.log("defaultEndDate", defaultEndDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultEndDate", defaultEndDate);

      let studentID = "";
      if (response.company_asset.assigned_history) {
        if (response.company_asset.assigned_history.length > 0) {
          let newObj = response.company_asset.assigned_history.reverse();
          studentID = newObj[0].person?._id;
        }
      }

      setStudents(response.company_asset.repair_history);
      setFormInputs({
        ...response.company_asset,
        title: response.company_asset.title,
        active_status: response.company_asset.active_status,
        assigned_status: response.company_asset.assigned_status,
        brand_id: response.company_asset.brand._id,
        category_id: response.company_asset.asset_category._id,
        vendor_id: response.company_asset.vendor._id,
        device_id: response.company_asset.device_id,
        quantity: response.company_asset.quantity,
        price: response.company_asset.price,
        link: response.company_asset.link,
        details: response.company_asset.details,

        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,

        end_date_batch: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,
        end_date_batch_select: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,

        student_id: studentID,
      });
      // setFormInputs(response.company_asset);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchStudentDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Asset Repair Details">
      <Container maxWidth="xl">
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "company_asset",
          "view"
        ) ? (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <div>
                <IconButton onClick={handleNav} sx={{ flex: 0 }}>
                  <Iconify icon="ep:back" />
                </IconButton>
                <Typography variant="h4">
                  {capitalizeFirstLetter(formInputs.title)} Repair Details
                </Typography>
              </div>

              {privilegesTypeCheckAndShowOrNot(
                get_user_profile,
                get_user_privilege_access,
                "company_asset",
                "add"
              ) ? (
                <Button
                  onClick={handleNavAdd}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Repair
                </Button>
              ) : (
                ""
              )}
            </Stack>
          </>
        ) : (
          ""
        )}
      </Container>

      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {students.length ? (
            <>
              {students.map((repair, index) => (
                <Card key={index}>
                  <div className="row p-3">
                    <div className="col-12 col-sm-8">
                      <TableContainer component={Paper}>
                        <Table
                          sx={{
                            minWidth: "100%",
                            // paddingBottom: "30px",
                            // marginTop: "30px",
                            // marginBottom: "30px",
                            border: "1px solid #f1f3f4", // Set the border style here
                            borderTop: "none",
                            borderBottom: "none",
                            border: "none",
                          }}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell align="left" style={myStyle2}>
                                Repair Date
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {repair.repair_date
                                  ? formatNormalDate(repair.repair_date)
                                  : " _ _ "}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left" style={myStyle2}>
                                Service Type
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {repair.service_type
                                  ? repair.service_type
                                  : " _ _ "}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left" style={myStyle2}>
                                Service Duration
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {repair.service_duration
                                  ? repair.service_duration
                                  : " _ _ "}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left" style={myStyle2}>
                                Service Cost
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {repair.service_cost
                                  ? repair.service_cost
                                  : " _ _ "}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left" style={myStyle2}>
                                Service Description
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {repair.service_description
                                  ? repair.service_description
                                  : " _ _ "}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="left" style={myStyle2}>
                                Vendor
                              </TableCell>
                              <TableCell align="left" style={myStyle}>
                                {repair.vendor ? repair.vendor.name : " _ _ "}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2} // Adjust the spacing between buttons
                        mt={2}
                        //   mb={2}
                      >
                        <Button
                          onClick={() => handleNavEdit(repair)}
                          variant="outlined"
                          startIcon={<Iconify icon="eva:edit-fill" />}
                          style={{ width: "120px", textAlign: "center" }} // Set a fixed width for the button
                        >
                          Edit
                        </Button>

                        <Button
                          // onClick={handleDeleteClick}
                          onClick={() => handleDeleteClick(repair)}
                          variant="outlined"
                          startIcon={<Iconify icon="eva:trash-2-outline" />}
                          style={{ width: "120px", textAlign: "center" }} // Set the same fixed width for the button
                        >
                          Delete
                        </Button>
                      </Stack>
                    </div>
                    <div
                      className="col-12 col-sm-4"
                      style={{
                        borderLeft: "1px solid #f1f3f4", // Set the border style here
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        //   spacing={2} // Adjust the spacing between buttons
                        mt={1}
                        mb={1}
                      >
                        <img
                          src={
                            repair.service_receipt_pic !== ""
                              ? s3baseUrl + repair.service_receipt_pic
                              : repairDefaltImage
                          }
                          style={{ height: "50vh", width: "50vh" }}
                        />
                      </Stack>
                      {/* <img src={s3baseUrl+repair.service_receipt_pic} style={{ height: "50vh", width: "50vh" }} /> */}
                    </div>
                  </div>
                </Card>
              ))}
            </>
          ) : (
            <div className="mt-5 pt-5">
              <DataNotFoundAll />
            </div>
          )}
        </Grid>
      </div>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to delete?"
        onOk={handleDeleteOk}
      />
    </Page>
  );
};

export default CompanyAssetRepairList;
