import { invokeApi } from "src/utils";


export const _list_company_asset= async (data) => {

    const requestObj = {
      path: `api/company_asset/list_company_asset`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _filter_company_assets= async (data) => {

    const requestObj = {
      path: `api/company_asset/filter_company_assets?page=0&limit=1000`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_company_asset = async (data) => {

    const requestObj = {
      path: `api/company_asset/add_company_asset`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

    export const _edit_company_asset = async (id, data) => {
    const requestObj = {
      path: `api/company_asset/edit_company_asset/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_repair_company_asset = async ( data) => {
    const requestObj = {
      path: `api/company_asset/repair_company_asset`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _edit_repair_company_asset = async ( data) => {
    const requestObj = {
      path: `api/company_asset/update_repair_history`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _delete_repair_history = async (data) => {
    const requestObj = {
      path: `api/company_asset/delete_repair_history`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _assign_company_asset = async (data) => {
    const requestObj = {
      path: `api/company_asset/assign_company_asset`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _update_company_asset_status = async (data) => {
    const requestObj = {
      path: `api/company_asset/update_company_asset_status`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _return_company_asset = async (data) => {
    const requestObj = {
      path: `api/company_asset/return_company_asset`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _detail_company_asset = async (id) => {
    const requestObj = {
      path: `api/company_asset/detail_company_asset/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };


 

  export const _delete_company_asset = async (id) => {
    const requestObj = {
      path: `api/company_asset/delete_company_asset/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

 

 