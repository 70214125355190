import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import { delStudent } from "src/DAL/student";
import {
  _delEnquiryStudent,
  _update_enquiry_status,
} from "src/DAL/enquirystudent";
import { CircularLoader } from "src/components";
import { _delete_time_preference } from "src/DAL/timepreference";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
// ----------------------------------------------------------------------

export default function StudentMoreMenu({ row, students, onStudentsUpdate }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteEnquery, setOpenDeleteEnquery] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleDeleteEnqueryClick = () => {
    setIsOpen(false);
    setOpenDeleteEnquery(true);
  };

  const handleDeleteOk = () => {
    handleClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };

  const handleDeleteEnqueryOk = () => {
    handleEnqueryDeleteClick(row);
    setOpenDeleteEnquery(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleClick(row) {
    //console.log(row);
    setIsLoading(true);
    const result = await _update_enquiry_status(row.id, {
      enquiry_status: "expire",
    });
    if (result.code === 200) {
      // const updated_students = students.filter(
      //   (students) => students.id !== row.id
      // );
      // onStudentsUpdate(updated_students);

      const updated_students = students.map((student) => {
        if (student.id === row.id) {
          // If the IDs don't match, update the 'enquiry_status' property to true
          return {
            ...student,
            enquiry_status: "true",
          };
        } else {
          // If the IDs match, return the original student object without changing anything
          return student;
        }
      });

      // Call the onStudentsUpdate function with the updated_students array
      onStudentsUpdate(updated_students);

      // navigate("/enquiry-students/");
      // window.location.reload();
      // navigate("/enquiry-students/add-student");

      // const updatedStudents = students.map((student) => {
      //   if (student._id === row.id) {
      //     // If the current student's _id matches the target _id, update the enquiry_status to true
      //     return { ...student, enquiry_status: !true };
      //   }
      //   return student;
      // });

      // onStudentsUpdate(updatedStudents);

      // // Assuming 'row' contains the '_id' of the student you want to update
      // const targetStudentId = row.id;

      // // Create a new array with updated status
      // const updatedStudents = students.map((student) => {
      //   if (student._id === targetStudentId) {
      //     // console.log('student',student);
      //     // console.log('student._id',student._id);
      //     // console.log('targetStudentId',targetStudentId);
      //     // If the current student's _id matches the target _id, update the status to true
      //     return {
      //       ...student,
      //       enquiry_status: false
      //     };
      //   } else {
      //     // If the _id doesn't match, return the student as is (no change)
      //     return student;
      //   }
      // });

      // Call the onStudentsUpdate function to update the state with the updatedStudents array
      // onStudentsUpdate(updatedStudents);

      setIsLoading(false);
      enqueueSnackbar("Expire Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  async function handleClickPhoneStatus(row, phone_status) {
    setIsOpen(false);
    //console.log(row);
    setIsLoading(true);
    const result = await _update_enquiry_status(row.id, {
      enquiry_status: phone_status,
    });
    if (result.code === 200) {
      const updated_students = students.map((student) => {
        if (student.id === row.id) {
          // If the IDs don't match, update the 'enquiry_status' property to true
          return {
            ...student,
            enquiry_status: phone_status,
          };
        } else {
          // If the IDs match, return the original student object without changing anything
          return student;
        }
      });

      // Call the onStudentsUpdate function with the updated_students array
      onStudentsUpdate(updated_students);

      setIsLoading(false);
      enqueueSnackbar("Status Change Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  async function handleEnqueryDeleteClick(row) {
    console.log(row);
    setIsLoading(true);
    const result = await _delete_time_preference(row._id);
    if (result.code === 200) {
      const updated_students = students.filter(
        (students) => students._id !== row._id
      );
      onStudentsUpdate(updated_students);
      setIsLoading(false);
      enqueueSnackbar("Deleted Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEnquery, setIsOpenEnquery] = useState(false);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "time_preference",
          "edit"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/time-preference/edit-time-preference/${row._id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "time_preference",
          "delete"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteEnqueryClick}
          >
            <ListItemIcon>
              {/* <Iconify icon="eva:trash-2-outline" width={24} height={24} /> */}
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
      </Menu>

      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm! Do Not Call"
        message="Are you sure you want to Expire this Student?"
        onOk={handleDeleteOk}
      />
      <DelPopup
        open={openDeleteEnquery}
        setOpen={setOpenDeleteEnquery}
        title="Confirm! Delete"
        message="Are you sure you want to Delete this Time Preference?"
        onOk={handleDeleteEnqueryOk}
      />
    </>
  );
}
