import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
// import listPlugin from "@fullcalendar/list";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
// import { Modal, Button } from "react-bootstrap"; // Import the Modal component
import interactionPlugin from "@fullcalendar/interaction";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// import { isSameDay } from "date-fns"; // Import isSameDay function
// import './google-calendar-style.css'; // Adjust the path as needed
// import "../../assets/css/google-calendar-style.css";
// import { Onefootball } from "../../assets";
// import { s3baseUrl } from "../../config/config";
import moment from "moment";
import { _get_attendance_for_start_date_end_date } from "src/DAL/attendance";
import { CircularLoader } from "src/components";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import CalendarDetail from "./CalendarDetail";
// import WeekCalendar from "./WeekCalendar";
// import DayCalendar from "./DayCalendar";
// import DetailPopUpModel from "../../components/GeneralComponents/DetailPopUpModel";

// import GoogleLoginAccess from "./GoogleLoginAccess";
// import GoogleLoginOAuth from "./GoogleLoginOAuth";

// import { GoogleOAuthProvider } from '@react-oauth/google';

const get_view_dates = (tool) => {
  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};

const CalendarView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [eventsMatches, setEventsMatches] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for controlling the modal
  const [selectedEvent, setSelectedEvent] = useState(null); // State to store the selected event data
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Track the current year
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [eventDetailData, setEventDetailData] = useState([]);
  const [popupState, setPopupState] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const [selectedTool, setSelectedTool] = useState(
    // userInfo.default_caleder_value
    "month"
  );

  const [showModalSecond, setShowModalSecond] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [currentYearResult, setCurrentYearResult] = useState(
    new Date().getFullYear()
  ); // Track the current year
  const [newYear, setNewYear] = useState(2024); // Track the current year

  //   const moment = require("moment-timezone");
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEvent(null);
  };

  function handleOpenModal(selected_row) {
    setOpenModal(true);
    setSelectedRow(selected_row);
  }

  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  // Define the eventClick callback function
  const handleEventClick = (eventClickInfo) => {
    // Access custom data from the event object
    const eventData = eventClickInfo.event.extendedProps;
    console.log("eventData", eventData);
    setSelectedEvent(eventData);
    setShowModal(true); // Open the modal
  };
  //   // Close the modal
  //   const handleCloseModal = () => {
  //     setShowModal(false);
  //     setSelectedEvent(null);
  //   };

  const handleDatesSet2 = () => {
    console.log("work");
  };
  const handleDatesSet = (dateInfo) => {
    const currentYear = dateInfo.view.currentStart.getFullYear();
    // setCurrentYear(currentYear); // Update the current year when user navigates
    // console.log('new_Year',new_Year);
    // if(currentYear !==newYear){
    //     fetchEventsByYear(newYear); // Fetch events for the new year
    //     setCurrentYear(newYear); // Update the current year when user navigates
    // }
    // setTestYear(currentYear);

    // console.log('currentYear',currentYear);
    // console.log('oldYear',oldYear);
    // console.log('newYear',newYear);
    setCurrentYearResult(currentYear);
    // setNewYear(currentYear+1);
    // console.log('oldYear -- After',oldYear);
    // console.log('newYear -- After',newYear);
    // setCurrentYear(newYear); // Update the current year when user navigates
  };

  const formattedTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");

    date.setHours(hours);
    date.setMinutes(minutes);

    let formatted = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });

    const hour = date.getHours();

    if (hour > 12) {
      formatted = formatted.replace(hour, hour - 12);
    }

    return `${formatted}`;
    // return `${formatted}  ${isPM ? 'PM' : 'AM'}`;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  // Create a function to sort events
function sortEvents(events) {
  // Sort events based on custom criteria
  events.sort((a, b) => {
      // Put "Present" events first
      if (a.title === "Present" && b.title !== "Present") {
          return -1; // "Present" comes before other events
      } else if (a.title !== "Present" && b.title === "Present") {
          return 1; // Other events come after "Present"
      } else {
          // For events other than "Present", maintain their original order
          return 0;
      }
  });

  return events;
}

  function FormatAllEvents(response) {
    const titles = ["Leave", "2_Absent", "1_Present", "Total"]; // Titles for duplication

    const newFormatedMatchesData = response.flatMap((match) =>
      titles.map((title, index) => {
        const titleCurrentMatch = `${title} ${
          index === 0
            ? match.leave_request_count
            : index === 1
            ? match.absent_count
            : index === 2
            ? match.present_count
            : index === 3
            ? match.total_attendance
            : 0
        }`;
        const dateCurrentMatch = formatDate(match.date); // Replace this with your actual date or use a variable

        return {
          ...match,
          title: titleCurrentMatch,
          date: dateCurrentMatch,
          // Add other properties as needed
        };
      })
    );
    //    setEventsMatches(
    //         [
    //         { title: "leave 1", date: "2024-01-01", location: "Venue A" },
    //         { title: "absent 1", date: "2024-01-01", location: "Venue A" },
    //         { title: "present 1", date: "2024-01-01", location: "Venue A" },
    //         { title: "total 1", date: "2024-01-01", location: "Venue A" },
    //       ])

    console.log(
      "events  eventsMatches newFormatedMatchesData",
      newFormatedMatchesData
    );
    setEventsMatches(newFormatedMatchesData);
  }

  const getAllEvents = async () => {
    setIsLoading(true);

    const originalStartDate = moment(currentDate.start_date);
    const originalEndDate = moment(currentDate.end_date);

    const formattedStartDate = originalStartDate.format("YYYY-MM-DD");
    const formattedEndDate = originalEndDate.format("YYYY-MM-DD");

    const response = await _get_attendance_for_start_date_end_date({
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    });

    console.log("response _get_attendance_for_start_date_end_date", response);

    if (response.code === 200) {
      FormatAllEvents(response.calender_response);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchEventsByYear = async (year) => {
    // setIsLoading(true);

    const response = await _match_filter_by_start_end_date({
      start_date: `${year}-01-01`,
      end_date: `${year + 1}-12-31`,
    });
    console.log("response", response);
    if (response.code === 200) {
      FormatAllEvents(response);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchEventsByDate = async (currentDateObject) => {
    //     const currentTimeZone = moment.tz.guess();
    // console.log('currentTimeZone ___currentTimeZone',currentTimeZone); // Output: "America/New_York" (example)
    console.log("selectedTool ___selectedTool", selectedTool); // Output: "America/New_York" (example)

    if (selectedTool !== "month") {
      //   setIsLoading(true);
    }

    let parsedStartDate = moment(currentDateObject.start_date);
    let parsedEndDate = moment(currentDateObject.end_date);

    if (selectedTool !== "month") {
      parsedEndDate = moment(currentDateObject.end_date).add(1, "day"); // Add 1 day to the end_date
    }

    const formattedStartDate = parsedStartDate.format("YYYY-MM-DD");
    const formattedEndDate = parsedEndDate.format("YYYY-MM-DD");

    console.log("formattedStartDate", formattedStartDate);
    console.log("formattedEndDate", formattedEndDate);

    // const response = await _match_filter_by_start_end_date({
    //   start_date: formattedStartDate,
    //   end_date: formattedEndDate,
    //   // end_date: '2023-08-18',
    // });

    // console.log("response", response);
    // if (response.code === 200) {
    //   FormatAllEvents(response);

    //   setIsLoading(false);
    // } else {
    //   enqueueSnackbar(response.message, { variant: "error" });
    //   setIsLoading(false);
    // }
  };

  //   useEffect(() => {
  //     fetchEventsByYear(currentYear); // Fetch events for the initial year
  //   }, []);

  useEffect(() => {
    // console.log("currentYearResult", currentYearResult);
    // fetchEventsByYear(currentYearResult);
  }, [currentYearResult]);

  //   useEffect(() => {

  //     fetchEventsByDate(currentDate);
  //   }, [currentDate]);
  const handleDateClick = (arg) => {
    setOpenModal(true);
    setSelectedRow(arg.dateStr);
  };

  
  const handleCellClick = (date) => {
    console.log("Cell clicked on date: ___date", date.event.startStr);
    console.log("Cell clicked on date: ___date", date);
    console.log("working");
    setOpenModal(true);
    setSelectedRow(date.event.startStr);
    // handleOpenModal(date);
    // date.startStr
    // Add your logic here
  };
  const handleCellClick1 = (info) => {
    console.log("Cell clicked:", info);
    console.log("____test:", info);

    let tool = "day";
    // let providedDate = new Date("Mon Aug 07 2023 00:00:00 GMT+0500 (Pakistan Standard Time)");
    let providedDate = new Date(info.date);

    console.log("tool __tool", tool);

    let dates = {
      start_date: moment(providedDate).startOf(tool).toDate(),
      end_date: moment(providedDate).endOf(tool).toDate(),
    };

    // let dates = get_dates(tool);
    console.log("tool", tool);
    console.log("tool dates", dates);
    setCurrentDate(dates);
    setSelectedTool(tool);

    // setSelectedDate(info.dateStr);
    // // if(eventsForSelectedDate.length > 0){
    // setShowModalSecond(true);
  };
  const handleCloseModalSecond = () => {
    setShowModalSecond(false);
    setSelectedDate(null);
  };

  function replaceWord(inputString) {
    // Use regular expression to replace "1_Present" with "Present"
    // and "2_Absent" with "Absent"
    return inputString.replace(/1_Present|2_Absent/g, function(match) {
        if (match === '1_Present') {
            return 'Present';
        } else if (match === '2_Absent') {
            return 'Absent';
        }
    });
}
function replaceWordWithColor(inputString) {
  // Convert the inputString to lowercase to perform case-insensitive search
  inputString = inputString.toLowerCase();
  
  // Check if the inputString contains "present"
  if (inputString.includes('present')) {
      return '#2fed2f'; // Return green if "Present" is found
  } else if (inputString.includes('absent')) {
    return 'red'; // Return green if "Present" is found
  } else if (inputString.includes('leave')) {
    return 'orange'; // Return green if "Present" is found
  }
    else {
      return 'blue'; // Return blue if "Present" is not found
  }
}

  const renderEventContent = (eventInfo) => {
    const eventStyle = {
      color: "#990f02",
      display: "flex",
      alignItems: "center",
    };

    const imgStyle = {
      width: "20px",
      height: "20px",
      marginTop: "2px",
      marginRight: "8px",
    };
console.log( "eventInfo.event.title   _eventInfo.event.title",  eventInfo.event.title);

let _color = "blue";
    return (
      // <div style={eventStyle}>
      //   <img
      //     src="https://livescore.dynamitelifestyle.co.uk/uploads/logo/logo.png"
      //     alt="Avatar"
      //     style={imgStyle}
      //   />
      //   {eventInfo.event.title}
      // </div>
      <div
        className="container-fluid d-flex align-items-start justify-content-start Calender-EventStyle "
        style={{ overflow: "hidden" }}
      >
        <Box
          component="span"
          sx={{
            position: "relative",
            display: "inline-flex",
            alignItems: "start",
          }}
        >
          <Box
            component="span"
            sx={{
              // bgcolor: replaceWordWithColor(eventInfo.event.title) ,
              bgcolor: replaceWordWithColor(eventInfo.event.title),
              width: 8,
              height: 8,
              borderRadius: "50%",
              marginRight: 1,
              // border: `3px solid #2fed2f`,
              // border: `3px solid ${replaceWordWithColor(eventInfo.event.title)}`,
              marginTop: "5px",
            }}
          />
        </Box>
        {  replaceWord(eventInfo.event.title) }
        {/* {
        eventInfo.event.title ==="1_Present"? "Present" :
        eventInfo.event.title ==="2_Absent"? "Absent" : eventInfo.event.title
      } */}

      </div>
    );
  };

  const eventsForSelectedDate = eventsMatches.filter(
    (event) => event.date === selectedDate
  );
  // const displayedEvents = eventsForSelectedDate.slice(0, 2); // Display only the first 2 events

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    // setUserInfo((old) => {
    //   return {
    //     ...old,
    //     default_caleder_value: tool,
    //   };
    // });
    console.log("tool __tool", tool);
    let dates = get_dates(tool);
    console.log("tool", tool);
    console.log("tool dates", dates);

    setCurrentDate(dates);
    setSelectedTool(tool);
  };

  const eventDetail = (event) => {
    if (selectedTool === "month") {
      const eventData = event.event.extendedProps;
      console.log("eventData", eventData);
      setSelectedEvent(eventData);
      setEventDetailData(eventData);
    } else {
      setEventDetailData(event);
      setSelectedEvent(event);
    }
    setPopupState(true);

    console.log("eventDetail __eventDetail call", event);
    console.log("event __eventevent", event);
  };

  const getCenteredText = () => {
    let text = "";
    if (selectedTool === "month") {
      text = moment(currentDate.start_date).add(1, "month").format("MMM YYYY");
    } else if (selectedTool === "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value === "next") {
      new_object = {
        start_date: moment(start_date)
          .add(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .add(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    } else {
      new_object = {
        start_date: moment(start_date)
          .subtract(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .subtract(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    }

    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    console.log("value handleChangeNextPrev", value);
    if (selectedTool === "month") {
      let classToClick = "fc-next-button";
      if (value === "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }

    hanldeChangeDates(value);
  };

  useEffect(() => {
    getAllEvents();
    // setEventsMatches([
    //   { title: "Event 1", date: "2024-01-01", location: "Venue A" },
    //   { title: "Event 2", date: "2024-01-02", location: "Venue B" },
    //   { title: "Event 3", date: "2024-02-03", location: "Venue C" },
    //   { title: "Event 4", date: "2024-02-04", location: "Venue D" },

    //   // Add more events as needed with additional data
    // ]);

    // {
    //     title: 'Event 1',
    //     start: '2024-01-19T10:00:00',
    //     end: '2024-01-19T12:00:00',
    //   },
    //   {
    //     title: 'Event 2',
    //     start: '2024-01-20T14:00:00',
    //     end: '2024-01-20T16:00:00',
    //   },
  }, [currentDate]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <div className="container">
      <div className="row mt-3">

      <Typography   variant="subtitle1">
          <div className="row">
            <div className="col-12 col-sm-4 order-1 order-sm-1"><h2>Calendar</h2></div>
            <div className="col-12 col-sm-4 mt-2  order-3 order-sm-2">
            <Typography  gutterBottom align="center" variant="subtitle1">
              <div className="btn-group toollbar-group-buttons">
                <button
                  className={`small-contained-button`}
                  onClick={() => {
                    handleChangeNextPrev("prev");
                  }}
                >
                  <ArrowBackIosIcon />
                </button>
                <h2 className="event-title-color">{getCenteredText()}</h2>
                <button
                  className={`small-contained-button`}
                  onClick={() => {
                    handleChangeNextPrev("next");
                  }}
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
              </Typography>
            </div>

            <div className="col-12 col-sm-4 order-2 order-sm-3">
         

            </div>
          </div>
        </Typography>
 
        
      </div>

      {/* <div className="row">
        <div className="col-6 order-2 col-md-4 full-calendar-toolbar">
          <div className="btn-group toollbar-group-buttons">
            <button
              className={`small-contained-button-calender`}
              onClick={() => {
                handleChangeNextPrev("prev");
              }}
            >
              <ArrowBackIosIcon />
            </button>
            <button
              className={`small-contained-button-calender`}
              onClick={() => {
                handleChangeNextPrev("next");
              }}
            >
              <ArrowForwardIosIcon />
            </button>
          </div>
        </div>
        <div className="col-12 col-md-4 order-1 order-md-2 mb-4 mb-md-0 text-center full-calendar-toolbar">
          <h2 className="event-title-color">{getCenteredText()}</h2>
        </div>
        <div className="col-6 col-md-4 order-3 text-center text-md-end">
          <div className="btn-group toollbar-group-buttons">
          
          </div>
        </div>
      </div> */}

      <div className="col-md-12 full-calendar full-calendar-new-flow order-4">
        {/* <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth" // Set the initial view to dayGridMonth
          events={eventsMatches}
          //   headerToolbar={{
          //     left: "prev,next today",
          //     center: "title",
          //     right: "dayGridMonth,timeGridWeek,timeGridDay",
          //   }}
          headerToolbar={{
            left: "prev,next",
            center: "",
            right: "",
          }}
          eventClick={eventDetail}
          //   eventColor="transparent"
          eventContent={renderEventContent}
          datesSet={handleDatesSet}
          dateClick={handleCellClick}
          dayMaxEvents={window.screen.width > 768 ? 4 : 3}
          weekends={true}
          fixedWeekCount={false}
        /> */}

        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            // listPlugin,
            interactionPlugin,
          ]}
          events={eventsMatches}
          headerToolbar={{
            left: "prev,next",
            center: "",
            right: "",
          }}
          eventClick={(arg) => handleCellClick(arg)}
          eventColor="transparent"
          eventContent={renderEventContent}
          datesSet={handleDatesSet}
          dateClick={handleDateClick}
          dayMaxEvents={window.screen.width > 768 ? 4 : 3}
          weekends={true}
          fixedWeekCount={false}
        />
      </div>
      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Detail"
        componentToPassDown={
<div className="mt-5">
{/* <StudentDetail studentID={selectedRow} /> */}
<CalendarDetail selectedRow={selectedRow} />
{/* <h1>hello</h1>
<h1>hello</h1>
<h1>hello</h1>
<h1>hello</h1> */}
</div>
          
    }
      />

    </div>
  );
};

export default CalendarView;
