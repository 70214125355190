import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  InputAdornment,
  Chip,
  Divider,
  Box,
  FormHelperText,
  Icon,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "src/utils/formatText";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import { listStudent } from "src/DAL/student";
import {
  _list_active_expense_category,
  _list_expense_category,
} from "src/DAL/expense_category";
import { _add_expense } from "src/DAL/expense";
import { useTheme } from "@mui/material/styles";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import { _get_customers, _list_active_customers } from "src/DAL/customer";
import { _add_top_up } from "src/DAL/expense_top_up";
const Input = styled("input")({
  display: "none",
});

const AddExpenseTopUp = () => {
  const theme = useTheme();
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [vendor, setVendor] = useState([]);
  const [students, setStudents] = useState([]);
  const [expenseCategory, ExpenseCategory] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: "" },
  ]);
  const [value, setValue] = useState("no"); // 'yes' or 'no'

  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");

  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);
  const [instructors, setInstructors] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: "true",
    registration_fee: 0,
    batch_discount: 0,

    title: "",
    amount: "",
    payment_method: "cash",
    attachment: "",
    notes: "",
    vendor_id: "",
    student_id: "",
    tax_type: "with_holding_tax",
    tax_amount: "",
    expense_category_id: "",
    link: "",
    other_link: "",
    transfer_by: "",
    transfer_to: "",
    cheque_number: "",
});

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
    console.log("time", time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: "" }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // let taxType = formInputs.tax_type;
    // let taxAmount = formInputs.tax_amount;
    // if (value === "no") {
    //   taxType = "with_holding_tax";
    //   taxAmount = 0;
    // }

 
    let PostData = {
      title: formInputs.title,
      amount: formInputs.amount,
      payment_method: formInputs.payment_method,
      date: formattedStartDate,
      attachment: pathOfUploadedFile,
      notes: formInputs.notes,
    //   cheque_number: formInputs.cheque_number,
      transfer_by: formInputs.transfer_by,
      transfer_to: formInputs.transfer_to,
    };

    if(formInputs.payment_method === "cheque"){
        PostData = {
            title: formInputs.title,
            amount: formInputs.amount,
            payment_method: formInputs.payment_method,
            date: formattedStartDate,
            attachment: pathOfUploadedFile,
            notes: formInputs.notes,
            cheque_number: formInputs.cheque_number,
            transfer_by: formInputs.transfer_by,
            transfer_to: formInputs.transfer_to,
      
          };
    }
    


    console.log("PostData ___PostData", PostData);
    setIsLoading(true);
    const result = await _add_top_up(PostData);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/top-up");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchActiveCustomers = async () => {
    setIsLoading(true);
    const response = await _list_active_customers();

    // list_asset_category

    console.log("response _get_customers", response);
    if (response.code === 200) {
      const customers = response.active_customers.map((customer, index) => ({
        ...customer,
        id: customer._id,
        number: index + 1,
      }));

      setStudents(customers);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActiveCustomers();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return ( 
    <Page title="Add Top Up">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">Add Top Up</h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Amount"
                  value={formInputs.amount}
                  onChange={handleChange}
                  name="amount"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}

                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <TextField
                  fullWidth
                  label="Payment Method"
                  select
                  value={formInputs.payment_method}
                  onChange={handleChange}
                  name="payment_method"
                  required
                >
                  <MenuItem value="cash">Cash</MenuItem>
                  <MenuItem value="cheque">Cheque Deposit</MenuItem>
                  <MenuItem value="online_transfer">Online Transfer</MenuItem>
                </TextField>
              </Stack> 

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {formInputs.payment_method === "cheque"?(
       <TextField
       fullWidth
       label="Cheque NO"
       value={formInputs.cheque_number}
       onChange={handleChange}
       name="cheque_number"
       required
    
       inputProps={{
         pattern: "[0-9]*", // Only allow numeric characters
       }}
       onKeyPress={(event) => {
         if (!/[0-9]/.test(event.key)) {
           event.preventDefault();
         }
       }}
     />

                ):("")}

                <TextField
                  fullWidth
                  label="Paid By"
                  select
                  value={formInputs.transfer_by}
                  onChange={handleChange}
                  name="transfer_by"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {students.map((customer) => (
                    <MenuItem key={customer.user_id} value={customer.user_id}>
                      {capitalizeFirstLetter(customer.first_name)}{" "}
                      {capitalizeFirstLetter(customer.last_name)}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Paid To"
                  select
                  value={formInputs.transfer_to}
                  onChange={handleChange}
                  name="transfer_to"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {students.map((customer) => (
                         <MenuItem key={customer.user_id} value={customer.user_id}>
                         {capitalizeFirstLetter(customer.first_name)}{" "}
                         {capitalizeFirstLetter(customer.last_name)}
                       </MenuItem>
                  ))}
                </TextField>

                {formInputs.payment_method !== "cheque"?(
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    label="Date *"
                    // format="YYYY-MM-DD"
                    // value={dayjs(formInputs2.duration2)}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
                ):("")}
              </Stack>

              {formInputs.payment_method === "cheque"?(
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    label="Date *"
                    // format="YYYY-MM-DD"
                    // value={dayjs(formInputs2.duration2)}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
                ):("")}

              <CustomImageUploader
                pathOfUploadedFile={pathOfUploadedFile}
                setPathOfUploadedFile={setPathOfUploadedFile}
                imageFileHeading={"Image File"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <TextField
                fullWidth
                label="Description"
                multiline
                rows={6} // Specify the number of rows you want
                value={formInputs.notes}
                onChange={handleChange}
                name="notes"
              />

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default AddExpenseTopUp;
