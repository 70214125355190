import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale

import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Accordion,
  AccordionSummary,
  Button,
  FormGroup,
  Checkbox,
  Divider,
  AccordionDetails,
  LinearProgress,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_bank } from "src/DAL/bank";
import { useContentSetting } from "src/hooks/ContentSettingState";
import { _add_role, _detail_role, _edit_role } from "src/DAL/role";
import { capitalizeFirstLetter } from "src/utils/formatText";
import {
  formatDateTimeWithOutZone,
  privilegesNameCheckAndUpperCase,
} from "src/utils";
import {
  getStaticPrivilegesObj,
  privilegesArrayConvertToObj,
  privilegesObjConvertToArray,
} from "src/utils/FormatPrivileges";
const RoleDetail = ({ selectedRowStudent }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const { id } = useParams();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  // const { userInfo, privilegeAccess } = useContentSetting();

  const [allPrevillagesObj, setAllPrevillagesObj] = useState(
    getStaticPrivilegesObj
  );

  const [Previvlages, setPrevivlages] = useState([]);

  const [buttonText, setButtonText] = useState(() =>
    Array.from({ length: Previvlages.length }, () => "Select All")
  );

  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    title: "",
    status: true,
    privilege: {},
  });

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSelectAll = (ParentIndex) => {
    const newPrevivlages = [...Previvlages];
    const allChecked = newPrevivlages[ParentIndex].privilege_items.every(
      (item) => item.privilege_access
    );
    newPrevivlages[ParentIndex].privilege_items.forEach(
      (item) => (item.privilege_access = !allChecked)
    );
    setPrevivlages(newPrevivlages);
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    }
  };

  const handleCheckboxChange = (ParentIndex, childIndex) => {
    const newPrevivlages = [...Previvlages];

    console.log("newPrevivlages", newPrevivlages);
    console.log("ParentIndex", ParentIndex);
    console.log("childIndex", childIndex);

    newPrevivlages[ParentIndex].privileges[childIndex].value =
      !newPrevivlages[ParentIndex].privileges[childIndex].value;
    setPrevivlages(newPrevivlages);
    const allChecked = newPrevivlages[ParentIndex].privileges.every(
      (item) => item.value
    );
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);
    // console.log("JSON.stringify(Previvlages) ___test   __Previvlages", JSON.stringify(Previvlages));
    console.log(
      "JSON.stringify(Previvlages) ___test   __Previvlages",
      Previvlages
    );

    // Convert Array to Obj
    let all_previllages = privilegesArrayConvertToObj(Previvlages);

    let postData = {
      title: formInputs.title,
      status: formInputs.status,
      privilege: all_previllages,
    };

    setIsLoading(true);
    const result = await _edit_role(id, postData);

    console.log("result _add_role", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/role");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // let privilegesArray = Object.entries(allPrevillages).map(([key, value]) => ({
    //   name: key,
    //   ...value
    // }));

    let privilegesArray = privilegesObjConvertToArray(allPrevillagesObj);
    setPrevivlages(privilegesArray);

    console.log("privilegesArray __privilegesArray __test", privilegesArray);
  }, [allPrevillagesObj]);

  async function fetchRoleDetail() {
    setIsLoading(true);
    const response = await _detail_role(selectedRowStudent.id);
    console.log("_detail_role _detail_role", response);

    if (response.code === 200) {
      setFormInputs({
        ...formInputs,
        title: capitalizeFirstLetter(response.role.title),
        status: response.role.status,
      });

      setAllPrevillagesObj(response.role.privilege);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchRoleDetail();
  }, []);

  // if (isLoading) {
  //   return <CircularLoader />;
  // }

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Edit Role">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "15px" }}
      >
        <div className="container  mt-3 mb-3">
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Title
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.title)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    <Label
                      variant="outlined"
                      color={
                        (selectedRowStudent.status === false && "error") ||
                        "success"
                      }
                    >
                      {selectedRowStudent.status ? "Active" : "Inactive"}
                    </Label>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(selectedRowStudent.createdAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(selectedRowStudent.updatedAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Grid>

      <div className="container col-11  mb-1">
        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <div className="col-12">
                <Divider
                  sx={{
                    width: "100%",
                    borderColor: "black",
                    color: "#068ce5",
                  }}
                >
                  <h5>Privileges</h5>
                </Divider>
              </div>

              <div className="col-12">
                <div className="row">
                  {Previvlages.map((prevaliges_item, ParentIndex) => {
                    return (
                      <div className="col-4" key={ParentIndex}>
                        <Accordion
                          className="accordian"
                          sx={{
                            boxShadow: "none",
                            borderBottom: "1px solid rgb(229, 229, 229)",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              {privilegesNameCheckAndUpperCase(
                                prevaliges_item.name
                              )}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/* <div className="text-end">
                            <Button
                              variant="text"
                              onClick={() => handleSelectAll(ParentIndex)}>
                              {buttonText[ParentIndex]}
                            </Button>
                          </div> */}
                            <FormGroup>
                              {prevaliges_item.privileges?.map(
                                (child, childIndex) => {
                                  return (
                                    <FormControlLabel
                                      key={childIndex}
                                      control={
                                        <Checkbox
                                          name={child.action}
                                          checked={child.value}
                                          disabled
                                          onChange={() =>
                                            handleCheckboxChange(
                                              ParentIndex,
                                              childIndex
                                            )
                                          }
                                        />
                                      }
                                      label={capitalizeFirstLetter(child.action)}
                                    />
                                  );
                                }
                              )}
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack> */}
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default RoleDetail;
