import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale

import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Accordion,
  AccordionSummary,
  Button,
  FormGroup,
  Checkbox,
  Divider,
  AccordionDetails,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_bank } from "src/DAL/bank";
import { useContentSetting } from "src/hooks/ContentSettingState";
import { _add_role, _detail_role, _edit_role } from "src/DAL/role";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { privilegesNameCheckAndUpperCase } from "src/utils";
import {
  getStaticPrivilegesObj,
  privilegesArrayConvertToObj,
  privilegesObjConvertToArray,
} from "src/utils/FormatPrivileges";
const Edit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const { id } = useParams();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  // const { userInfo, privilegeAccess } = useContentSetting();

  const [allPrevillagesObj, setAllPrevillagesObj] = useState(
    getStaticPrivilegesObj
  );

  const [Previvlages, setPrevivlages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [buttonText, setButtonText] = useState(() =>
    Array.from({ length: 24 }, () => "Select All")
  );

  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    title: "",
    status: true,
    privilege: {},
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSelectAll = (ParentIndex) => {
    const newPrevivlages = [...Previvlages];
    const allChecked = newPrevivlages[ParentIndex].privileges.every(
      (item) => item.value
    );
    newPrevivlages[ParentIndex].privileges.forEach(
      (item) => (item.value = !allChecked)
    );

    // newPrevivlages[ParentIndex].privileges[childIndex].value =
    //   !newPrevivlages[ParentIndex].privileges[childIndex].value;
    // setPrevivlages(newPrevivlages);
    // const allChecked = newPrevivlages[ParentIndex].privileges.every(
    //   (item) => item.value
    // );

    setPrevivlages(newPrevivlages);
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    }
  };

// Filtered privileges based on the search term
const filteredPrevivlages = Previvlages.filter(privilege =>
  privilege.name.toLowerCase().includes(searchTerm.toLowerCase())
);

  // Handle search term change
const handleSearchTermChange = (e) => {
  setSearchTerm(e.target.value);
};
  const handleCheckboxChange = (ParentIndex, childIndex) => {
    const newPrevivlages = [...Previvlages];

    console.log("newPrevivlages", newPrevivlages);
    console.log("ParentIndex", ParentIndex);
    console.log("childIndex", childIndex);

    newPrevivlages[ParentIndex].privileges[childIndex].value =
      !newPrevivlages[ParentIndex].privileges[childIndex].value;

    if (newPrevivlages[ParentIndex].privileges[childIndex].value === true) {
      newPrevivlages[ParentIndex].privileges[3].value = true;
    }

    if (newPrevivlages[ParentIndex].privileges[3].value === false) {
      newPrevivlages[ParentIndex].privileges[0].value = false;
      newPrevivlages[ParentIndex].privileges[1].value = false;
      newPrevivlages[ParentIndex].privileges[2].value = false;
      if (newPrevivlages[ParentIndex].privileges[4]) {
        newPrevivlages[ParentIndex].privileges[4].value = false;
      }
      if (newPrevivlages[ParentIndex].privileges[5]) {
        newPrevivlages[ParentIndex].privileges[5].value = false;
      }
      if (newPrevivlages[ParentIndex].privileges[6]) {
        newPrevivlages[ParentIndex].privileges[6].value = false;
      }
      if (newPrevivlages[ParentIndex].privileges[7]) {
        newPrevivlages[ParentIndex].privileges[7].value = false;
      }
    }

    setPrevivlages(newPrevivlages);
    const allChecked = newPrevivlages[ParentIndex].privileges.every(
      (item) => item.value
    );
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);
    // console.log("JSON.stringify(Previvlages) ___test   __Previvlages", JSON.stringify(Previvlages));
    console.log(
      "JSON.stringify(Previvlages) ___test   __Previvlages",
      Previvlages
    );

    // Convert Array to Obj
    let all_previllages = privilegesArrayConvertToObj(Previvlages);

    let postData = {
      title: formInputs.title,
      status: formInputs.status,
      privilege: all_previllages,
    };

    setIsLoading(true);
    const result = await _edit_role(id, postData);

    console.log("result _add_role", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
      navigate("/role");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // let privilegesArray = Object.entries(allPrevillages).map(([key, value]) => ({
    //   name: key,
    //   ...value
    // }));

    let privilegesArray = privilegesObjConvertToArray(allPrevillagesObj);
    setPrevivlages(privilegesArray);

    console.log("privilegesArray __privilegesArray __test", privilegesArray);
  }, [allPrevillagesObj]);

  async function fetchRoleDetail() {
    setIsLoading(true);
    const response = await _detail_role(id);
    console.log("_detail_role _detail_role", response);

    if (response.code === 200) {
      setFormInputs({
        ...formInputs,
        title: capitalizeFirstLetter(response.role.title),
        status: response.role.status,
      });

      // let allPrevillages = response.role.privilege;
      let allPrevillages = response.role.privilege;

      let defaultObj = getStaticPrivilegesObj();

      const defaultKeys = Object.keys(defaultObj);

      // let default = getStaticPrivilegesObj();

      // const defaultKeys = Object.keys(default);

      // Iterate through defaultKeys
      defaultKeys.forEach((key) => {
        // Check if the key is missing in allPrevillages
        if (!(key in allPrevillages)) {
          // Add the key and its value from default to allPrevillages
          allPrevillages[key] = defaultObj[key];
        }
      });

      // Create a new complete copy array
      const newCompleteCopyArray = { ...allPrevillages };

      console.log(
        "newCompleteCopyArray   ____newCompleteCopyArray",
        newCompleteCopyArray
      );
      // setAllPrevillagesObj(response.role.privilege);
      setAllPrevillagesObj(newCompleteCopyArray);

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchRoleDetail();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Edit Role">
      <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Role </h4>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Title"
                  value={formInputs.title}
                  onChange={handleChange}
                  name="title"
                  required
                />
                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </TextField>
              </Stack>

              <div className="col-12">
                <Divider
                  sx={{
                    width: "100%",
                    borderColor: "black",
                    color: "#068ce5",
                  }}
                >
                  <h5>Privileges</h5>
                </Divider>
              </div>
              {/* <div>
  <input
    type="text"
    placeholder="Search Title"
    value={searchTerm}
    onChange={handleSearchTermChange}
  />
</div> */}
 <div style={{marginTop:"-10px"}}>
  <TextField
    label="Search Title"
    type="text"
    placeholder="Search Title"
    value={searchTerm}
    onChange={handleSearchTermChange}
    variant="outlined"
    // fullWidth
    margin="normal"
  />
</div>
              <div className="container" style={{marginTop:"5px"}}>
                <div className="row">
                  <table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Privileges</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredPrevivlages.map((prevaliges_item, ParentIndex) => (
                        <tr key={ParentIndex} style={{ borderTop: '1px solid #aaaaaa44',borderBottom: '1px solid #aaaaaa44' }}>
                          <td>
                            {privilegesNameCheckAndUpperCase(
                              prevaliges_item.name
                            )}
                          </td>
                          <td>
                            {prevaliges_item.privileges?.map(
                              (child, childIndex) => (
                                <FormControlLabel
                                  key={childIndex}
                                  control={
                                    <Checkbox
                                      name={child.action}
                                      checked={child.value}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          ParentIndex,
                                          childIndex
                                        )
                                      }
                                    />
                                  }
                                  label={capitalizeFirstLetter(
                                    child.action === "update_status"
                                      ? "Update Status"
                                      : child.action === "move_students"
                                      ? "Move Students"
                                      : child.action === "other_options"
                                      ? "Other Options"
                                      : child.action === "expense_graph"
                                      ? "Expense Graph"
                                      : child.action === "expense_cards"
                                      ? "Expense Cards"
                                      : child.action === "other_cards"
                                      ? "Overview"
                                      : child.action === "attendance_graph"
                                      ? "All Active Batches"
                                      : child.action === "today_assignments"
                                      ? "Today Assignments"
                                      : child.action === "today_leave_requests"
                                      ? "Today Leave Requests"
                                      : child.action
                                  )}
                                />
                              )
                            )}
                             {/* <Button
                                variant="text"
                                onClick={() => handleSelectAll(ParentIndex)}
                              >
                                {buttonText[ParentIndex]}
                              </Button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
