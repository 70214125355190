import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { detailInstructor, editInstructor } from "src/DAL/instructor";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { _list_role } from "src/DAL/role";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: 1,
    identity_card_number: "",
    phone_number: "",
    status: "",
    education: "",
    image: "",
    role_id: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    deleteImage(image);
    setImage("");
    // console.log(image);
    setShow(false);
  }
  const deleteImage = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      //handleLoading(index, true);

      // console.log(res,"--data")
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "phone_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = new FormData();

    data.append("first_name", formInputs.first_name.trim());
    data.append("last_name", formInputs.last_name.trim());
    data.append("email", formInputs.email.trim());
    data.append("gender", formInputs.gender);
    data.append("identity_card_number", formInputs.identity_card_number);
    data.append("contact_number", formInputs.phone_number);
    data.append("education", formInputs.education);
    data.append("image", image ? image : "");
    data.append("status", formInputs.status);
    data.append("role", formInputs.role_id);

    // console.log(data.get("image"));
    const result = await editInstructor(id, data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/instructors");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchDetailInstructor() {
    const response = await detailInstructor(id);
    if (response.code === 200) {
      console.log('response detail ___detail',response);
      setFormInputs({
        first_name: capitalizeFirstLetter(response.instructor.first_name),
        last_name: capitalizeFirstLetter(response.instructor.last_name),
        email: response.instructor.email,
        gender: response.instructor.gender,
        identity_card_number: response.instructor.identity_card_number,
        phone_number: response.instructor.contact_number,
        status: response.instructor.status,
        education: response.instructor.education,
        image: response.instructor.image,
        role_id: response.instructor.role?response.instructor.role._id:"",
      });
      if (response.instructor.image) {
        setImage(response.instructor.image);
        setShow(true);
      }
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  const fetchActiveRoles = async () => {
    setIsLoading(true);
    const response = await _list_role();

    // list_brand

    console.log("response _list_brand", response);
    if (response.code === 200) {
      const roles = response.roles.map((role, index) => ({
        ...role,
        id: role._id,
        title: role.title,
        status: role.status,
        number: index + 1,
      }));

      setRoles(roles);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDetailInstructor();
    fetchActiveRoles();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Edit Instructor">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Edit Instructor </h4>
        </Grid>
         

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  value={formInputs.first_name}
                  onChange={handleChange}
                  name="first_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  value={formInputs.last_name}
                  onChange={handleChange}
                  name="last_name"
                  required
                  inputProps={{
                    pattern: "[A-Za-z]{3,}",
                    title:
                      "Please enter at least 3 letters with only alphabetical characters.",
                  }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email address"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  required
                  inputProps={{
                    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                    title: "Please enter a valid email address",
                  }}
                />

                <TextField
                  fullWidth
                  label="Role"
                  select
                  value={formInputs.role_id}
                  onChange={handleChange}
                  name="role_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {capitalizeFirstLetter(role.title)}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Gender"
                  select
                  required
                  value={formInputs.gender}
                  onChange={handleChange}
                  name="gender"
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </TextField>

                {/* <TextField
                  fullWidth
                  label="CNIC Number"
                  value={
                    formInputs.identity_card_number
                      ? formInputs.identity_card_number.replace(
                          /(\d{5})(\d{7})(\d{1})/,
                          "$1-$2-$3"
                        )
                      : ""
                  }
                  onChange={handleChange}
                  name="identity_card_number"
                  autoComplete="off"
                  // inputProps={{
                  //   pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                  //   title:
                  //     "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
                  // }}
                /> */}

                <TextField
                  fullWidth
                  label="CNIC Number"
                  value={formInputs.identity_card_number}
                  onChange={handleCNICChange}
                  name="identity_card_number"
                  autoComplete="off"
                  inputProps={{
                    pattern: "[0-9]{5}-[0-9]{7}-[0-9]{1}",
                    title:
                      "Please enter a valid cnic number in the format XXXXX-XXXXXXX-X",
                  }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {/* <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  onChange={handleChange}
                  name="phone_number"
                  required
                
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                /> */}
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.phone_number}
                  onChange={handleChangePhone}
                  name="phone_number"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                ></TextField>

                <TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.status}
                  onChange={handleChange}
                  name="status"
                  required
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              <TextField
                fullWidth
                label="Education"
                value={formInputs.education}
                onChange={handleChange}
                name="education"
              />
              {show === false ? (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFile}
                  />
                  {/* <Avatar alt={"image"} src={previewUrl} /> */}
                </Stack>
              ) : null}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <label htmlFor="image" className="form-label">
                    Image:
                  </label>
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    {previewUrl ? (
                      <img
                        alt="image"
                        src={previewUrl}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    ) : (
                      <img
                        alt="image"
                        src={s3baseUrl + formInputs.image}
                        width="150"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
