import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Icon,
  Badge,
} from "@mui/material";
//
import Iconify from "./Iconify";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { _get_user_roles } from "src/DAL/role";
import { useAppContext } from "src/hooks";
import {
  getStaticPrivilegesObj,
  privilegesNavSectionNameCheck,
  privilegesNavSectionNameCheckAsChildOptions,
} from "src/utils/FormatPrivileges";
import { _detail_customer_without_token } from "src/DAL/customer";

// ----------------------------------------------------------------------
const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: "red",
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    zIndex:0,
  },
}));

function NavItem({
  item,
  active,
  count,
  setCount,
  supportCount,
  setSupportCount,
  announcementCount,
  setAnnouncementCount,
}) {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);

  const {
    _set_user_privilege_access,
    _get_user_profile,
    _get_user_privilege_access,
    _set_user_profile,
    _get_contact_count,
    _set_contact_count,
  } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleMenuItemClick = (title) => {
    // Add your custom logic here based on the clicked menu item's title.
    console.log(`Clicked on the '${title}' menu item.`);
    fetchUserRoles();
  };

  const fetchUserRoles = async () => {
    // setIsLoading(true);
    const response = await _get_user_roles();
    console.log("response response response _get_user_roles", response);
    if (response.code === 200) {
      console.log("get_user_profile _zzz", get_user_profile);
      console.log("response.privileges _zzz", response.privileges);

      _set_contact_count(response.contact_count);
      setCount(response.contact_count);
      setSupportCount(response?.waiting_support_ticket_count);
      setAnnouncementCount(response?.unread_announcement_count);
      // supportCount,
      // setSupportCount
      console.log("count   ____test ___count", count);
 
      // user.role.privilege
      const user = {
        ...get_user_profile,
        privilege: response.privileges,
      };

      console.log("JSON.stringify(user)", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(user));

      _set_user_profile({
        ...get_user_profile,
        privilege: response.privileges,
      });

      _set_user_privilege_access(response.privileges);
      console.log("response.privileges _get_user_roles", response.privileges);

      // const batches = response.batch.map((batch, index) => ({
      //   id: batch._id,
      //   name: batch.name,
      //   number: index + 1,
      // }));
      // setBatches(batches);
      // setIsLoading(false);
    } else {
      // enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };
  const location = useLocation();
  // console.log(open, "locationlocationlocationlocation");
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            "&:hover": { color: "#0071cf" },
          }}
          // sx={{ color: "text.secondary", '&:hover': { color: '#0071cf' } }}
          // onClick={() => console.log('It works 1')}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          {/* <Box
            component={Icon}
            icon={open ? ArrowDownwardIcon : ArrowUpwardIcon}
            sx={{ width: 16, height: 16, ml: 1 }}
          /> */}
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              if (
                privilegesNavSectionNameCheckAsChildOptions( 
                  item,
                  get_user_profile,
                  get_user_privilege_access
                ) === true
              ) {
                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                    // onClick={() => console.log('It works 2')}
                    onClick={() => handleMenuItemClick("It works 2")}
                  >
                    <ListItemIconStyle>
                      {item.icon ? (
                        icon
                      ) : (
                        <Iconify
                          icon={"mdi:dot"}
                          width={12}
                          height={12}
                          sx={{
                            width: 24,
                            height: 24,
                            display: "flex",
                            borderRadius: "50%",
                            alignItems: "center",
                            justifyContent: "center",
                            // bgcolor: "text.disabled",
                            transition: (theme) =>
                              theme.transitions.create("transform"),
                            ...(isActiveSub && {
                              transform: "scale(2)",
                              // bgcolor: "primary.main",
                            }),
                          }}
                        />
                        // <Box
                        //   component="span"
                        //   sx={{
                        //     width: 4,
                        //     height: 4,
                        //     display: "flex",
                        //     borderRadius: "50%",
                        //     alignItems: "center",
                        //     justifyContent: "center",
                        //     bgcolor: "text.disabled",
                        //     transition: (theme) =>
                        //       theme.transitions.create("transform"),
                        //     ...(isActiveSub && {
                        //       transform: "scale(2)",
                        //       bgcolor: "primary.main",
                        //     }),
                        //   }}
                        // />
                      )}
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              } else {
                return null; // Return null for items you want to skip
              }
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
      // onClick={() => console.log('It works 3')}
      onClick={() => handleMenuItemClick("It works 3")}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
       
        <ListItemText disableTypography primary={title} />
      
      {info && info}
      <StyledBadge
        badgeContent={
          title === "Contact Us"
            ? count
            : title === "Support Tickets"
            ? supportCount
            : title === "Announcements"
            ? announcementCount
            : 0
        }
        max={99}
        color="secondary"
      >
      </StyledBadge>
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({
  count,
  setCount,
  supportCount,
  setSupportCount,
  announcementCount,
  setAnnouncementCount,
  navConfig,
  ...other
}) {
  const { pathname } = useLocation();

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  // let get_user_privilege_access = _get_user_privilege_access();
  let get_user_privilege_access = {};

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  // async function fetchCustomerDetail() {
  //   if(get_user_profile){
  //     if(get_user_profile.type===3){
  //       const response = await _detail_customer_without_token();
  //       console.log('response _detail_customer_without_token',response);
  //       if (response.code === 200) {
  //         get_user_privilege_access = response.customer.role.privilege;
  //         console.log('response response.customer.role.privilege _detail_customer_without_token',response.customer.role.privilege);
  //         setIsLoading(false);
  //       } else {
  //         setIsLoading(false);
  //         // enqueueSnackbar(response.message, { variant: "error" });
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   console.log('response get_user_profile _detail_customer_without_token',get_user_profile);
  //   // fetchCustomerDetail();
  // }, [get_user_privilege_access]);

  return (
    <Box {...other}>
      <List
        disablePadding
        // sx={{ p: 1, pt: 0.4 }}
      >
        {navConfig.map((item) => {
          if (
            privilegesNavSectionNameCheck(
              item,
              get_user_profile,
              get_user_privilege_access
            ) === true
          ) {
            return (
              <NavItem
                key={item.title}
                item={item}
                active={match}
                count={count}
                setCount={setCount}
                supportCount={supportCount}
                setSupportCount={setSupportCount}
                announcementCount={announcementCount}
                setAnnouncementCount={setAnnouncementCount}
                onClick={() => console.log("It works  ___count", count)}
              />
            );
          } else {
            return null; // Return null for items you want to skip
          }
        })}
      </List>
    </Box>
  );
}
