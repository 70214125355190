import React, { useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CalendarTableHeader from "./components/CalendarTableHeader";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularLoader, Iconify, Page } from "src/components";
import {
  _get_attendance,
  _get_attendance_for_specific_date,
} from "src/DAL/attendance";
import DataNotFound from "src/components/GeneralComponents/DataNotFound";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { capitalizeFirstLetter } from "src/utils/formatText";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSnackbar } from "notistack";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";
import AttendanceDataTable from "./AttendanceDataTable";
import DataNotFoundInTable from "./components/DataNotFoundInTable";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { isValidDate } from "src/utils/formatTime";

const get_view_dates = (dateFromURL) => {
  let newDate = new Date();

  if (isValidDate(dateFromURL) !== null) {
    newDate = new Date(dateFromURL);
  }

  console.log("newDate __current", newDate);

  let new_object = {
    // start_date: moment(newDate)
    //   .add(0, "month")
    //   .startOf("month")
    //   .toDate(),
    start_date: isValidDate(dateFromURL)
    ? moment(newDate).startOf("day").toDate()
    : moment(newDate).startOf("day").subtract(1, 'day').toDate(),
    end_date: moment(newDate).add(0, "month").endOf("month").toDate(),
  };
  // }

  return new_object;
};

const AttendanceBySingleDate = ({
  attendanceBySingleDateData,
  setAttendanceBySingleDateData,
  resetDataCall,
  date,
}) => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [batchName, setBatchName] = useState("Batch");
  const { enqueueSnackbar } = useSnackbar();
  const [year, setYear] = useState(2023);
  const [month, setMonth] = useState(10);
  const [day, setDay] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Track the current year
  const [currentDate, setCurrentDate] = useState(get_view_dates(date));
  const [selectedTool, setSelectedTool] = useState(
    // userInfo.default_caleder_value
    "day"
  );

  // const [date, setDate] = useState({
  //   year: currentDate.getFullYear(),
  //   month: currentDate.getMonth() + 1, // Months are zero-based, so add 1
  //   day: currentDate.getDate(),
  // });

  //   // Format the date as "MMMM, yyyy"
  //   let formattedDate = currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const getCenteredText = () => {
    let text = "";
    if (selectedTool === "month") {
      text = moment(currentDate.start_date).add(0, "month").format("MMM YYYY");
    } else if (selectedTool === "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Date is required.";
      enqueueSnackbar(error, { variant: "error" });

      return;
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    const new_object = {
      start_date: moment(formattedStartDate).toDate(),
      end_date: moment(formattedStartDate).toDate(),
    };

    setCurrentDate(new_object);
    setIsLoadingButton(true);
  };

  const handleReset = () => {
    // setStartDate("");
    // setSearchText("");
    // SearchStudents("");
    // fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
    setStartDate(null);
    navigate(`/attendance`);
    resetDataCall();
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date)
          .add(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .add(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    } else {
      new_object = {
        start_date: moment(start_date)
          .subtract(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .subtract(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    }

    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    console.log("work");
    console.log("value", value);
    console.log("month", month);

    // if (value == "prev") {
    //   setMonth(month - 1);
    //   fetchAttendance(month - 1);
    // } else {
    //   setMonth(month + 1);
    //   fetchAttendance(month + 1);
    // }

    // if (selectedTool == "month") {
    //   let classToClick = "fc-next-button";
    //   if (value == "prev") {
    //     classToClick = "fc-prev-button";
    //   }
    //   let click_element = document.getElementsByClassName(classToClick)[0];
    //   click_element.click();
    // }
    hanldeChangeDates(value);
  };

  const fetchAttendance = async (currentDateObject) => {
    let parsedStartDate = moment(currentDateObject.start_date);
    let parsedEndDate = moment(currentDateObject.end_date);
    // if (selectedTool !== "month") {
    //   parsedEndDate = moment(currentDateObject.end_date).add(1, "day"); // Add 1 day to the end_date
    // }
    const formattedStartDate = parsedStartDate.format("YYYY-MM-DD");
    const formattedEndDate = parsedEndDate.format("YYYY-MM-DD");

    setIsLoading(true);

    const response = await _get_attendance_for_specific_date({
      date: formattedStartDate,
    });

    console.log("response _get_attendance_for_specific_date", response);
    if (response.code === 200) {
      setAttendanceData(response.attendances);
      //   setAttendanceBySingleDateData(response.attendances);
      setIsLoading(false);
      setIsLoadingButton(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
      setIsLoadingButton(false);
    }
  };

  useEffect(() => {
    console.log("currentDate ___currentDate", currentDate);
    fetchAttendance(currentDate);
  }, [currentDate]);

  useEffect(() => {
    // console.log('currentDate ___currentDate',currentDate);
    // fetchAttendance(11);
  }, []);

  //   if (isLoading) {
  //     return <CircularLoader />;
  //   }

  return (
    <Page title="Attendance">
      <div className="   mb-2 mt-2  ">
        <Typography gutterBottom align="center" variant="subtitle1">
          <div className="row">
            <div className="col-12 col-sm-4 order-1 order-sm-1"></div>
            <div className="col-12 col-sm-4 mt-2  order-3 order-sm-2">
              {/* <div className="btn-group toollbar-group-buttons">
                <button
                  className={`small-contained-button`}
                  onClick={() => {
                    handleChangeNextPrev("prev");
                  }}
                >
                  <ArrowBackIosIcon />
                </button>
                <h2 className="event-title-color">{getCenteredText()}</h2>
                <button
                  className={`small-contained-button`}
                  onClick={() => {
                    handleChangeNextPrev("next");
                  }}
                >
                  <ArrowForwardIosIcon />
                </button>
              </div> */}
            </div>

            <div className="col-12 col-sm-4 order-2 order-sm-3">
               <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    encType="multipart/form-data"
                  > 
            <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          // sx={{ padding: "10px" }}
        >
          
                
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateChange}
                          label="Date"
                          format="MM-DD-YYYY"
                          sx={{
                            width: "100%",
                          }}
                        />
                      </LocalizationProvider>
                      <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        loading={isLoadingButton}
                        sx={{ width: "120px" }}
                      >
                        Filter
                      </LoadingButton>
               <CustomResetButton handleReset={handleReset} />
        </Stack>
        </form>

            </div>
          </div>
        </Typography>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack spacing={0} sx={{ marginBottom: 2 }}>
            <div className="mt-0" style={{ overflow: "auto" }}>
              {attendanceData.length > 0 ? (
                <AttendanceDataTable
                  attendanceDataFromAPI={attendanceData}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  currentDate={currentDate}
                  //   datarows={attendanceData}
                  //   year={year}
                  //   month={month}
                  //   day={day}
                  //   dateObj={currentDate.start_date}
                />
              ) : (
                <>
                  <hr />

                  <DataNotFoundInTable
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </>
              )}
            </div>
          </Stack>
        </Grid>
      </div>
    </Page>
  );
};

export default AttendanceBySingleDate;
