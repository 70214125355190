import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _detail_enquiry_student,
  _edit_enquiry_student,
} from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _detail_brand, _edit_brand, _list_brand } from "src/DAL/brand";
import {
  _detail_asset_category,
  _edit_asset_category,
  _list_asset_category,
} from "src/DAL/asset_category";
import { _list_vendor } from "src/DAL/vendor";
import {
  _assign_company_asset,
  _detail_company_asset,
  _edit_company_asset,
  _return_company_asset,
} from "src/DAL/company_asset";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomDateInput from "src/components/GeneralComponents/CustomDateInput";
import HistoryDataTable from "../List/components/HistoryDataTable";
import { _list_active_customers } from "src/DAL/customer";
import { listInstructor } from "src/DAL/instructor";

const AssignCompanyAsset = ({
  selectedRowStudent,
  ID,
  handleCloseEditDrawer,
  assignAssets,
  setAssignAssets,
}) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isClick, setIsClick] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [brands, setBrands] = useState([]);
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [students, setStudents] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [datePost, setDatePost] = useState("");
  const [assignedHistory, setAssignedHistory] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [studentID, setStudentID] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [customerID, setCustomerID] = useState("");

  const [selectedInstructor, setSelectedInstructor] = useState([]);
  const [instructorID, setInstructorID] = useState("");

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    title: "",
    description: "",
    active_status: true,
    assigned_status: true,
    brand_id: "",
    category_id: "",
    vendor_id: "",
    device_id: "",
    quantity: "",
    price: "",
    link: "",
    details: "",
    start_date_batch_select: "",
    end_date_batch_select: "",
    student_id: "",
    memberName: "",
    student_name: "",
  });

  const [selectedValue, setSelectedValue] = useState("student");

  const handleChangePerson = (event) => {
    setSelectedValue(event.target.value);
  };

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleSelectChangeCustomer = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setCustomerID("");
      setSelectedCustomer([]);
      // updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setCustomerID(newValue.user_id);
      setSelectedCustomer(newValue);
      // updateFormInputs(newValue);

      const selectedStudent = customers.find(
        (customer) => customer.id === newValue.id
      );

      console.log("selectedStudent   __selectedStudent", selectedStudent);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        memberName: selectedStudent?.first_name + " " + selectedStudent?.last_name,
        // memberName: selectedBatch.courses.map((course) => course._id),
      }));

    }
  };
  const handleSelectChangeInstructor = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setInstructorID("");
      setSelectedInstructor([]);
      // updateFormInputs([]);
    } else {
      console.log("newValue", newValue);
      setInstructorID(newValue.user_id);
      setSelectedInstructor(newValue);
      // updateFormInputs(newValue);

      const selectedStudent = instructors.find(
        (instructor) => instructor.id === newValue.id
      );

      console.log("selectedStudent   __selectedStudent", selectedStudent);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        memberName: selectedStudent?.first_name + " " + selectedStudent?.last_name,
        // memberName: selectedBatch.courses.map((course) => course._id),
      }));
    }
  };

  const handleSelectChange = (event, newValue) => {
    console.log("newValue __test", newValue);
    if (newValue === null) {
      console.log("newValue", newValue);
      setStudentID("");
      setSelected([]);
      // updateFormInputs([]);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        memberName: "",
        // memberName: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      console.log("newValue", newValue);
      setStudentID(newValue.user_id);
      setSelected(newValue);

      const selectedStudent = students.find(
        (student) => student.id === newValue.id
      );

      console.log("selectedStudent   __selectedStudent", selectedStudent);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        memberName: selectedStudent?.name,
        // memberName: selectedBatch.courses.map((course) => course._id),
      }));

      // updateFormInputs(newValue);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const selectedStudent = students.find((student) => student.id === value);

    console.log("selectedStudent   __selectedStudent", selectedStudent);
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      memberName: selectedStudent?.name,
      // memberName: selectedBatch.courses.map((course) => course._id),
    }));
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };
  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };

  const handleClick = () => {
    // Toggle the isLoading state
    setIsClick((prevClick) => !prevClick);

    // Add any other logic you want to execute on button click
  };

  const handleCheckboxChange = () => {
    setIsChecked((prevChecked) => !prevChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      company_asset_id: selectedRowStudent.id,
      // student_id: formInputs.student_id,
      person_id: studentID,
      date: datePost,
    };

    if(selectedValue==="management"){
      postData = {
        company_asset_id: selectedRowStudent.id,
        // student_id: formInputs.student_id,
        person_id: customerID,
        date: datePost,
      }
    }
    if(selectedValue==="instructor"){
      postData = {
        company_asset_id: selectedRowStudent.id,
        // student_id: formInputs.student_id,
        person_id: instructorID,
        date: datePost,
      }
    }

 console.log("postData   _____postData",postData);

    setIsLoading(true);

    // const result = await _add_company_asset(postData);
    const result = await _assign_company_asset(postData);
    console.log("result _assign_company_asset", result);

    if (result.code === 200) {
      const updatedAssignAssets = assignAssets.map((assign_asset) => {
        if (assign_asset.id === selectedRowStudent.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return {
            ...assign_asset,
            assigned_status: true,

            assigned_history: [
              {
                person: {
                  // _id: "65a4dfdc389955169b3016c7",
                  name: formInputs.memberName,
                  // cnic: "",
                },
                // assigned_status: true,
                // assigned_date: "2024-01-17T05:44:52.739Z",
                // returned_date: null,
                // _id: "65a769542f209d3684c978f2",
              },
            ],
          };
        } else {
          // For other objects, return them as they are
          return assign_asset;
        }
      });

      setAssignAssets(updatedAssignAssets);

      setIsLoading(false);
      handleCloseEditDrawer();
      enqueueSnackbar("Assign Successfully!", { variant: "success" });
      navigate("/company-asset");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      company_asset_id: selectedRowStudent.id,
      returned_date: datePost,
    };

    console.log("postData", postData);

    setIsLoading(true);

    // const result = await _add_company_asset(postData);
    const result = await _return_company_asset(postData);
    console.log("result _return_company_asset", result);

    if (result.code === 200) {
      const updatedAssignAssets = assignAssets.map((assign_asset) => {
        if (assign_asset.id === selectedRowStudent.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return {
            ...assign_asset,
            assigned_status: false,

            assigned_history: [],
          };
        } else {
          // For other objects, return them as they are
          return assign_asset;
        }
      });

      setAssignAssets(updatedAssignAssets);

      setIsLoading(false);
      handleCloseEditDrawer();
      enqueueSnackbar("Assign Successfully!", { variant: "success" });
      navigate("/company-asset");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_company_asset(selectedRowStudent.id);
    console.log("_detail_company_asset _detail_company_asset", response);
    // enquiry_form

    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.company_asset.purchase_date) {
        defaultStartDate = new Date(response.company_asset.purchase_date);
        console.log("defaultStartDate", defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultStartDate", defaultStartDate);

      let defaultEndDate = new Date();
      console.log("defaultEndDate", defaultEndDate);
      if (response.company_asset.warranty_expire_date) {
        defaultEndDate = new Date(response.company_asset.warranty_expire_date);
        console.log("defaultEndDate", defaultEndDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultEndDate", defaultEndDate);

      let studentID = "";
      let studentName = "";
      if (response.company_asset.assigned_history) {
        if (response.company_asset.assigned_history.length > 0) {
          let newObj = response.company_asset.assigned_history.reverse();
          studentID = newObj[0].person?.user_id;
          studentName = newObj[0].person?.name;
        }
      }

      setAssignedHistory(response.company_asset.assigned_history);
      setFormInputs({
        ...response.company_asset,
        title: response.company_asset.title,
        active_status: response.company_asset.active_status,
        assigned_status: response.company_asset.assigned_status,
        brand_id: response.company_asset.brand._id,
        category_id: response.company_asset.asset_category._id,
        vendor_id: response.company_asset.vendor._id,
        device_id: response.company_asset.device_id,
        quantity: response.company_asset.quantity,
        price: response.company_asset.price,
        link: response.company_asset.link,
        details: response.company_asset.details,

        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,

        end_date_batch: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,
        end_date_batch_select: defaultEndDate.toISOString().split("T")[0]
          ? defaultEndDate.toISOString().split("T")[0]
          : null,

        student_id: studentID,
        student_name: studentName,
      });
      // setFormInputs(response.company_asset);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await listStudent();
    console.log("response ___listStudent", response);
    if (response.code === 200) {
      const students = response.students.map((student, index) => ({
        ...student,
        id: student._id,
        name: student.name,
        number: index + 1,
      }));
      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchInstructors = async () => {
    const response = await listInstructor();
    console.log("response ___listInstructor",response);
    if (response.code === 200) {
      setIsLoading(false);

      const instructors = response.instructors.map((instructor) => ({
        ...instructor,
        id: instructor._id,
        first_name: instructor.first_name,
        last_name: instructor.last_name,
        email: instructor.email,
        image: instructor.image,
        status: instructor.status,
      }));

      setInstructors(instructors);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveCustomers = async () => {
    setIsLoading(true);
    const response = await _list_active_customers();

    // list_asset_category

    console.log("response _list_active_customers", response);
    if (response.code === 200) {
      const customers = response.active_customers.map((customer, index) => ({
        ...customer,
        id: customer._id,
        number: index + 1,
      }));

      setCustomers(customers);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchStudentDetail();
    fetchStudents();
    fetchInstructors();
    fetchActiveCustomers();
    console.log(
      "selectedRowStudent  ___selectedRowStudent",
      selectedRowStudent
    );
  }, []);

  // if (isLoading) {
  //   return <CircularLoader />;
  // }

  return (
    <Page title="Assign Asset">
      <div className="container px-4 mb-5 container_in_all_pages">
        {/* <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid> */}
        <Stack alignItems="end">
          <LoadingButton
            size="small"
            // type="submit"
            variant="contained"
            // loading={isLoading}
            sx={{ marginTop: "10px" }}
            // sx={{ width: "120px" }}
            onClick={handleClick}
          >
            history
          </LoadingButton>
        </Stack>
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            // marginBottom: "10px",
          }}
        >
 
          <p className="mt-2">Do you want to assign this asset?</p>

        </Grid> */}
        {!isClick ? (
          selectedRowStudent.assigned_status === false ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // marginBottom: "10px",
                }}
              >
                <p className="mt-2">Do you want to assign this asset?</p>
              </Grid>

              <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <Stack spacing={2}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Person
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={selectedValue}
                          onChange={handleChangePerson}
                        >
                          <FormControlLabel
                            value="student"
                            control={<Radio />}
                            label="Student"
                          />
                          <FormControlLabel
                            value="management"
                            control={<Radio />}
                            label="Management"
                          />
                          <FormControlLabel
                            value="instructor"
                            control={<Radio />}
                            label="Instructor"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Stack>

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      {selectedValue === "student" ? (
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={students}
                          getOptionLabel={(student) =>
                            student.name
                              ? capitalizeFirstLetter(student.name)
                              : ""
                          }
                          value={selected}
                          onChange={handleSelectChange}
                          // filterOptions={filterOptions} // Apply the custom filter function
                          renderInput={(params) => (
                            <TextField {...params} label="Student" required />
                          )}
                          renderOption={(props, student) => (
                            <li {...props}>
                              {capitalizeFirstLetter(student.name)}
                            </li>
                          )}
                        />
                      ) : (
                        ""
                      )}

                      {selectedValue === "management" ? (
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={customers}
                          getOptionLabel={(customer) =>
                            customer.first_name
                              ? capitalizeFirstLetter(customer.first_name) +
                                " " +
                                capitalizeFirstLetter(customer.last_name)
                              : ""
                          }
                          value={selectedCustomer}
                          onChange={handleSelectChangeCustomer}
                          // filterOptions={filterOptions} // Apply the custom filter function
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Management"
                            />
                          )}
                          renderOption={(props, customer) => (
                            <li {...props}>
                              {capitalizeFirstLetter(customer.first_name)}{" "}
                              {capitalizeFirstLetter(customer.last_name)}
                            </li>
                          )}
                        />
                      ) : (
                        ""
                      )}

                      {selectedValue === "instructor" ? (
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={instructors}
                          getOptionLabel={(instructor) =>
                            instructor.first_name
                              ? capitalizeFirstLetter(instructor.first_name) +
                                " " +
                                capitalizeFirstLetter(instructor.last_name)
                              : ""
                          }
                          value={selectedInstructor}
                          onChange={handleSelectChangeInstructor}
                          // filterOptions={filterOptions} // Apply the custom filter function
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Instructor"
                            />
                          )}
                          renderOption={(props, instructor) => (
                            <li {...props}>
                              {capitalizeFirstLetter(instructor.first_name)}{" "}
                              {capitalizeFirstLetter(instructor.last_name)}
                            </li>
                          )}
                        />
                      ) : (
                        ""
                      )}
                      {/* <TextField
                        fullWidth
                        label="Student"
                        select
                        value={formInputs.student_id}
                        onChange={handleChange}
                        name="student_id"
                        required
                        // SelectProps={{ multiple: true }}
                      >
                        {students.map((student) => (
                          <MenuItem key={student.id} value={student.id}>
                            {capitalizeFirstLetter(student.name)}
                          </MenuItem>
                        ))}
                      </TextField>  */}
                    </Stack>

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <CustomDateInput
                        datePost={datePost}
                        setDatePost={setDatePost}
                      />
                    </Stack>

                    <Stack alignItems="end">
                      <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                        sx={{ width: "120px" }}
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </form>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                sx={
                  {
                    // display: "flex",
                    // justifyContent: "space-between",
                    // alignItems: "center",
                    // marginBottom: "10px",
                  }
                }
              ></Grid>

              <h6 style={myStyle}>Currently Assigned: </h6>
              <h6 style={myStyle2}>{formInputs.student_name}</h6>
              <hr />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      // sx={{ marginTop: "10px" }}
                    />
                  }
                  label="Do you want to free this asset?"
                />
              </FormGroup>

              {isChecked ? (
                <Stack
                  sx={{ marginTop: "15px" }}
                  direction={{ xs: "column", sm: "column" }}
                  spacing={2}
                >
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit2}
                    encType="multipart/form-data"
                  >
                    <Stack spacing={2}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                      >
                        <CustomDateInput
                          datePost={datePost}
                          setDatePost={setDatePost}
                          labet="Return Date *"
                        />
                      </Stack>

                      <Stack alignItems="end">
                        <LoadingButton
                          size="medium"
                          type="submit"
                          variant="contained"
                          loading={isLoading}
                          sx={{ width: "70px" }}
                        >
                          Save
                        </LoadingButton>
                      </Stack>
                    </Stack>
                  </form>
                </Stack>
              ) : (
                ""
              )}
            </>
          )
        ) : (
          <HistoryDataTable selectedRow={assignedHistory} />
        )}
      </div>
    </Page>
  );
};

export default AssignCompanyAsset;
