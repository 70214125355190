import { invokeApi } from "src/utils";
export const _listStudentWithPagination = async (page,rowsPerPage) => {
  const requestObj = {
    path: `api/student/list_student?page=${page}&limit=${rowsPerPage}`,
    // path: `api/student/list_student`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _get_students = async (page,rowsPerPage) => {
  const requestObj = {
    path: `api/student/get_students?page=${page}&limit=${rowsPerPage}`,
    // path: `api/student/list_student`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const listStudent = async () => {
  const requestObj = {
    // path: `api/student/list_student?page=${page}&limit=${rowsPerPage}`,
    path: `api/student/list_student`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};


export const _list_freeze_student = async (data) => {
  const requestObj = {
    path: `api/student/list_freeze_student`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _assign_batch_multiple_students = async (data) => {
  const requestObj = {
    path: `api/batch/assign_batch_multiple_students`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _student_update_freeze_status = async (id, data) => {
  const requestObj = {
    path: `api/student/update_freeze_status/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _list_trash_students = async (data) => {
  const requestObj = {
    path: `api/student/list_trash_students`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _listStudentSearchWithPagination = async (searchText,page,rowsPerPage) => {
  const requestObj = {
    // path: `api/student/list_student?page=0&limit=100`,
    // path: `api/student/list_student?text=${searchText}&page=${page}&limit=${rowsPerPage}`,
    path: `api/student/list_student?text=${searchText}&page=${page}&limit=${rowsPerPage}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _get_students_with_search = async (data,page,rowsPerPage) => {
  const requestObj = {
    path: `api/student/get_students?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const listStudentSearch = async (searchText) => {
  const requestObj = {
    // path: `api/student/list_student?page=0&limit=100`,
    path: `api/student/list_student?text=${searchText}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const delStudent = async (id) => {
  const requestObj = {
    path: `api/student/delete_student/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _student_delete_in_trash = async (id) => {
  const requestObj = {
    path: `api/student/student_delete/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _restore_student = async (id) => {
  const requestObj = {
    path: `api/student/restore_student/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _student_move_to_trash = async (id) => {
  const requestObj = {
    path: `api/student/move_to_trash/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _remove_student_from_batch = async ( data) => {
  const requestObj = {
    path: `api/student/remove_student_from_batch/`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _move_freeze_student_to_trash = async (id) => {
  const requestObj = {
    path: `api/student/move_freeze_student_to_trash/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _student_delete= async (id) => {
  const requestObj = {
    path: `api/student/student_delete/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_student_in_course = async (data) => {
  const formDataArray = Array.from(data.entries());

  console.log('formDataArray',formDataArray);
  const requestObj = {
    path: `api/student/add_student_in_course`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addStudent = async (data) => {
  const formDataArray = Array.from(data.entries());

  console.log(formDataArray);
  const requestObj = {
    path: `api/student/add_student`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailStudent = async (id) => {
  const requestObj = {
    path: `api/student/detail_student/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editStudent = async (id, data) => {
  const requestObj = {
    path: `api/student/edit_student/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_student_fee = async (data) => {
  const requestObj = {
    path: `api/student/update_student_fee`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _filter_student = async (data) => {

  const requestObj = {
    path: `api/student/filter_student`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _list_student_fees_using_batch_id = async (data) => {

  const requestObj = {
    path: `api/student/list_student_fees`,
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_student_fee_from_batch = async (data) => {
  const requestObj = {
    path: `api/student/update_student_fee/`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _student_batches_for_admin = async (id) => {
  const requestObj = {
    path: `api/student/student_batches_for_admin/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _move_to_trash = async (id, data) => {
  const requestObj = {
    path: `api/student/move_to_trash/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};