import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

DataNotFountForDashboardjs.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default function DataNotFountForDashboardjs({ title = "",subTitle = "", ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
      <strong> {title} </strong> 
      </Typography>
      <Typography variant="body2" align="center">
        {subTitle}
      </Typography>
    </Paper>
  );
}
