import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import dayjs from "dayjs";
// import "dayjs/locale/en"; // Import the desired locale

import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  Accordion,
  AccordionSummary,
  Button,
  FormGroup,
  Checkbox,
  Divider,
  AccordionDetails,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_bank } from "src/DAL/bank";
import { useContentSetting } from "src/hooks/ContentSettingState";
import { _add_role } from "src/DAL/role";
import { privilegesNameCheckAndUpperCase } from "src/utils";
import { capitalizeFirstLetter } from "src/utils/formatText";
const Add = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  // const { userInfo, privilegeAccess } = useContentSetting();

  const allPrevillages = {
    instructor: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    course: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    batch: {
      add: false,
      edit: false,
      delete: false,
      view: true,
      revenue: false,
      update_status: false,
      move_students: false,
      other_options: false,
    },

    time_preference: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    enquiry: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    student: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    fee_voucher: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    attendance: {
      add: false,
      edit: false,
      delete: false,
      view: true,
      calendar: false,
    },
    asset_category: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    brand: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    vendor: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    company_asset: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    event: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    templet_configration: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    page: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    faq: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    testimonial: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    assignment: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    leave_request: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    expense_category: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    expense: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },
    support_ticket: {
      add: false,
      detail: false,
      delete: false,
      view: true,
    },
    announcement: {
      add: false,
      edit: false,
      delete: false,
      view: true,
    },

    dashboard: {
      expense_graph: true,
      expense_cards: true,
      other_cards: true,
      view: true,
      attendance_graph: true,
      today_assignments: true,
      today_leave_requests: true,
    },

  };

  const [Previvlages, setPrevivlages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // const [buttonText, setButtonText] = useState(() =>
  //   Array.from({ length: Previvlages.length }, () => "Select All")
  // );

  const [buttonText, setButtonText] = useState(() =>
    Array.from({ length: 24 }, () => "Select All")
  );

  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    title: "",
    status: true,
    privilege: {},
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  // Filtered privileges based on the search term
const filteredPrevivlages = Previvlages.filter(privilege =>
  privilege.name.toLowerCase().includes(searchTerm.toLowerCase())
);

  // Handle search term change
const handleSearchTermChange = (e) => {
  setSearchTerm(e.target.value);
};

  const handleSelectAll = (ParentIndex) => {
    const newPrevivlages = [...Previvlages];
    const allChecked = newPrevivlages[ParentIndex].privileges.every(
      (item) => item.value
    );
    newPrevivlages[ParentIndex].privileges.forEach(
      (item) => (item.value = !allChecked)
    );

    // newPrevivlages[ParentIndex].privileges[childIndex].value =
    //   !newPrevivlages[ParentIndex].privileges[childIndex].value;
    // setPrevivlages(newPrevivlages);
    // const allChecked = newPrevivlages[ParentIndex].privileges.every(
    //   (item) => item.value
    // );

    setPrevivlages(newPrevivlages);
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    }
  };

  const handleCheckboxChange = (ParentIndex, childIndex) => {
    const newPrevivlages = [...Previvlages];

    console.log("newPrevivlages", newPrevivlages);
    console.log("ParentIndex", ParentIndex);
    console.log("childIndex", childIndex);

    newPrevivlages[ParentIndex].privileges[childIndex].value =
      !newPrevivlages[ParentIndex].privileges[childIndex].value;

    if (newPrevivlages[ParentIndex].privileges[childIndex].value === true) {
      newPrevivlages[ParentIndex].privileges[3].value = true;
    }

    if (newPrevivlages[ParentIndex].privileges[3].value === false) {
      newPrevivlages[ParentIndex].privileges[0].value = false;
      newPrevivlages[ParentIndex].privileges[1].value = false;
      newPrevivlages[ParentIndex].privileges[2].value = false;
      if (newPrevivlages[ParentIndex].privileges[4]) {
        newPrevivlages[ParentIndex].privileges[4].value = false;
      }
      if (newPrevivlages[ParentIndex].privileges[5]) {
        newPrevivlages[ParentIndex].privileges[5].value = false;
      }
      if (newPrevivlages[ParentIndex].privileges[6]) {
        newPrevivlages[ParentIndex].privileges[6].value = false;
      }
      if (newPrevivlages[ParentIndex].privileges[7]) {
        newPrevivlages[ParentIndex].privileges[7].value = false;
      }
    }

    setPrevivlages(newPrevivlages);
    const allChecked = newPrevivlages[ParentIndex].privileges.every(
      (item) => item.value
    );
    if (allChecked) {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "DeSelect All",
      }));
    } else {
      setButtonText((prev) => ({
        ...prev,
        [ParentIndex]: "Select All",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);
    // console.log("JSON.stringify(Previvlages) ___test   __Previvlages", JSON.stringify(Previvlages));
    console.log(
      "JSON.stringify(Previvlages) ___test   __Previvlages",
      Previvlages
    );

    let allPrevillages = {};

    for (const privilege of Previvlages) {
      const { name, privileges } = privilege;
      allPrevillages[name] = {};
      for (const actionObj of privileges) {
        const { action, value } = actionObj;
        allPrevillages[name][action] = value;
      }
    }

    // console.log(JSON.stringify(allPrevillages, null, 2));
    // console.log(JSON.stringify(allPrevillages));

    // let allPrevillages = {};

    // for (const privilege of Previvlages) {
    //   const { name, privileges } = privilege;
    //   allPrevillages[name] = {};
    //   for (const actionObj of privileges) {
    //     const { action, value } = actionObj;
    //     allPrevillages[name][action] = value;
    //   }
    // }

    // console.log(JSON.stringify({ "all_previllages": allPrevillages }, null, 2));

    // formData.append("privilages", JSON.stringify(Previvlages));

    let postData = {
      title: formInputs.title,
      status: formInputs.status,
      // privilege: JSON.stringify(allPrevillages),
      privilege: allPrevillages,
    };

    console.log("postData  ____postData", postData);
    setIsLoading(true);
    const result = await _add_role(postData);

    console.log("result _add_role", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      navigate("/role");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // let privilegesArray = Object.entries(allPrevillages).map(([key, value]) => ({
    //   name: key,
    //   ...value
    // }));

    let privilegesArray = Object.entries(allPrevillages).map(
      ([name, privileges]) => ({
        name,
        privileges: Object.entries(privileges).map(([action, value]) => ({
          action,
          value,
        })),
      })
    );

    setPrevivlages(privilegesArray);
    console.log("privilegesArray __privilegesArray __test", privilegesArray);
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Role">
    <div className="container px-4 mb-5 container_in_all_pages">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={handleNav} sx={{ flex: 0 }}>
          <Iconify icon="ep:back" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h4 className="title_heading_in_all_pages_top_left"> Add Role </h4>
      </Grid>

      <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Title"
                value={formInputs.title}
                onChange={handleChange}
                name="title"
                required
              />
              <TextField
                fullWidth
                label="Status"
                select
                value={formInputs.status}
                onChange={handleChange}
                name="status"
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </TextField>
            </Stack>

            <div className="col-12">
              <Divider
                sx={{
                  width: "100%",
                  borderColor: "black",
                  color: "#068ce5",
                }}
              >
                <h5>Privileges</h5>
              </Divider>
            </div>

            <div style={{marginTop:"-10px"}}>
  <TextField
    label="Search Title"
    type="text"
    placeholder="Search Title"
    value={searchTerm}
    onChange={handleSearchTermChange}
    variant="outlined"
    // fullWidth
    margin="normal"
  />
</div>

<div className="container" style={{marginTop:"5px"}}>
              <div className="row">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Privileges</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPrevivlages.map((prevaliges_item, ParentIndex) => (
                      <tr key={ParentIndex} style={{ borderTop: '1px solid #aaaaaa44',borderBottom: '1px solid #aaaaaa44' }}>
                        <td>
                          {privilegesNameCheckAndUpperCase(
                            prevaliges_item.name
                          )}
                        </td>
                        <td>
                          {prevaliges_item.privileges?.map(
                            (child, childIndex) => (
                              <FormControlLabel
                                key={childIndex}
                                control={
                                  <Checkbox
                                    name={child.action}
                                    checked={child.value}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        ParentIndex,
                                        childIndex
                                      )
                                    }
                                  />
                                }
                                label={capitalizeFirstLetter(
                                  child.action === "update_status"
                                    ? "Update Status"
                                    : child.action === "move_students"
                                    ? "Move Students"
                                    : child.action === "other_options"
                                    ? "Other Options"
                                    : child.action === "expense_graph"
                                    ? "Expense Graph"
                                    : child.action === "expense_cards"
                                    ? "Expense Cards"
                                    : child.action === "other_cards"
                                    ? "Overview"
                                    : child.action === "attendance_graph"
                                    ? "All Active Batches"
                                    : child.action === "today_assignments"
                                    ? "Today Assignments"
                                    : child.action === "today_leave_requests"
                                    ? "Today Leave Requests"
                                    : child.action
                                )}
                              />
                            )
                          )}
                           {/* <Button
                                variant="text"
                                onClick={() => handleSelectAll(ParentIndex)}
                              >
                                {buttonText[ParentIndex]}
                              </Button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <Stack alignItems="end">
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "120px" }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </div>
  </Page>
  );
};

export default Add;
