import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { capitalizeAllFirstLetters, capitalizeFirstLetter } from "src/utils/formatText";

const Add = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: "" },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);
  const [instructors, setInstructors] = useState([]);
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: "true",
    registration_fee: 0,
    batch_discount: 0,
    batch_duration_months: "",
    batch_assignment_count: "",
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      courses: value,
    }));

    // if(value){

    // }
    fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
    console.log("time", time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: "" }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("startTime", startTime);
    if (!startTime) {
      error = "Start Time is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("endTime", endTime);
    if (!endTime) {
      error = "End Time is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Start Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("endDate", endDate);
    if (!endDate) {
      error = "End Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("startTime startTime startTime", startTime);
    console.log("endTime endTime endTime", endTime);

    // Validate the selected End Time format
    // Assuming endTime is a Moment object representing the selected end time
    const formattedStartTime = startTime.format("HH:mm");
    console.log("formattedStartTime", formattedStartTime);

    // Validate the selected End Time format
    if (!moment(formattedStartTime, "HH:mm", true).isValid()) {
      error = "Invalid Start Time Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
      // Handle the error, e.g., display an error message to the user
    }

    // Validate the selected End Time format
    // Assuming endTime is a Moment object representing the selected end time
    const formattedEndTime = endTime.format("HH:mm");
    console.log("formattedEndTime", formattedEndTime);

    // Validate the selected End Time format
    if (!moment(formattedEndTime, "HH:mm", true).isValid()) {
      error = "Invalid End Time Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
      // Handle the error, e.g., display an error message to the user
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Start Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // Validate the selected date format
    const formattedEndDate = endDate.format("YYYY-MM-DD");
    console.log("formattedEndDate", formattedEndDate);
    if (!moment(formattedEndDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid End Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    console.log("formInputs.courses", formInputs.courses);
    const data = new FormData();
    data.append("name", formInputs.name);
    data.append("status", formInputs.status);
    // data.append("courses", [formInputs.courses]);
    data.append("total_seats", formInputs.total_seats);

    // data.append("courses", JSON.stringify(formInputs.courses));
    data.append("course", formInputs.courses);

    data.append("start_date_batch", formattedStartDate);
    data.append("end_date_batch", formattedEndDate);
    data.append("start_time_class", formattedStartTime);
    data.append("end_time_class", formattedEndTime);
    
    // data.append("instructors", [formInputs.instructors]);
    data.append("instructors", JSON.stringify(formInputs.instructors));
    // data.append("registration_fee", formInputs.registration_fee);
    // data.append("batch_fee", additionalFields);
    data.append("batch_fee", JSON.stringify(additionalFields));
    // data.append("batch_discount", formInputs.batch_discount);
    // console.log(data.get("courses"));
    data.append("batch_duration_months", formInputs.batch_duration_months);
    data.append("batch_assignment_count", formInputs.batch_assignment_count);

    setIsLoading(true);
    const result = await addBatch(data);
    if (result.code === 200) {
      setIsLoading(false);
      navigate("/batches");
      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchActiveCourses = async () => {
    setIsLoading(true);
    const response = await listActiveCourse();
console.log('response __listActiveCourse',response);
    if (response.code === 200) {
      const courses = response.courses.map((course) => ({
        ...course,
        id: course._id,
        title: course.title,
        description: course.description,
        duration: course.duration,
        image: course.image,
        status: course.status,
      }));
      setCourses(courses);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchInstructors = async (data) => {
    // setIsLoading(true);
    const response = await _course_instructors({
      courses: [data],
    });
    console.log("response listInstructor _listInstructor", response);
    if (response.code === 200) {
      // setIsLoading(false);

      // const instructors = response.instructors.map((instructor) => ({
      //   id: instructor._id,
      //   first_name: instructor.first_name,
      //   last_name: instructor.last_name,
      //   email: instructor.email,
      //   image: instructor.image,
      //   status: instructor.status,
      // }));

      setInstructors(response.instructors);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (instructors.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        instructors: [instructors[0]._id],
      }));
    }
  }, [instructors]);

  useEffect(() => {
    fetchActiveCourses();
  }, []);

  useEffect(() => {
    if (courses.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        courses: [courses[0].id],
      }));

      fetchInstructors([courses[0].id]);
    }
  }, [courses]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Add Batch">
      <div className="container px-4 mb-5 container_in_all_pages">
      <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Batch </h4>
           
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  value={formInputs.name}
                  onChange={handleChange}
                  name="name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Total Seat"
                  value={formInputs.total_seats}
                  onChange={handleChange}
                  name="total_seats"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}

                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Registration Fee"
                  value={formInputs.registration_fee}
                  onChange={handleChange}
                  name="registration_fee"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                /> */}

                {/* <TextField
                  fullWidth
                  label="Discount"
                  value={formInputs.batch_discount}
                  onChange={handleChange}
                  name="batch_discount"
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                /> */}
              {/* </Stack> */}
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Status"
                select
                value={formInputs.status}
                onChange={handleChange}
                name="status"
                required
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </TextField>

              <TextField
                  fullWidth
                  label="Batch Duration Months"
                  value={formInputs.batch_duration_months}
                  onChange={handleChange}
                  name="batch_duration_months"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}

                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Courses"
                select
                value={formInputs.courses}
                onChange={handleChange2}
                name="courses"
                required
                SelectProps={{ multiple: false }}
              >
                {courses.map((course) => (
                  <MenuItem key={course.id} value={course.id}>
                    {capitalizeFirstLetter(course.title)}  (Fee: {course.fee})
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                  fullWidth
                  label="Batch Assignment Count"
                  value={formInputs.batch_assignment_count}
                  onChange={handleChange}
                  name="batch_assignment_count"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}

                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    // maxLength: 10, // Limit the input to 10 characters
                    // title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                        </Stack>
              <TextField
                fullWidth
                label="Instructors"
                select
                value={formInputs.instructors}
                onChange={handleChange3}
                name="instructors"
                required
                SelectProps={{ multiple: true }}
              >
                {instructors.map((instructor) => (
                  <MenuItem key={instructor._id} value={instructor._id}>
                    {capitalizeAllFirstLetters(instructor.name)}
                  </MenuItem>
                ))}
              </TextField>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DemoContainer components={['MobileTimePicker', 'MobileTimePicker']}> */}

                  {/* <MobileTimePicker
                    label={"Batch Start Time *"}
                    openTo="hours"
                    selected={startTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={endTime}
                    onChange={handleStartTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  /> */}
                  {/* <TimePicker
                    label={"Batch Start Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    openTo="hours"
                    selected={startTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={endTime}
                    onChange={handleStartTimeChange}
                    // ampm={true} // Display AM/PM in the field
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  /> */}
                  <TimePicker
                    label={"Batch Start Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    openTo="hours"
                    selected={startTime}
                    views={["hours", "minutes"]}
                    onChange={handleStartTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  <TimePicker
                    label={"Batch End Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    selected={endTime}
                    views={["hours", "minutes"]}
                    onChange={handleEndTimeChange}
                    format="h:mm A" // Use 12-hour clock format with "h" for 1-12 hours
                    sx={{
                      width: "100%",
                    }}
                  />

                  {/* <TimePicker
                    label={"Batch End Time *"}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    selected={endTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={endTime}
                    onChange={handleEndTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%",
                      // marginTop: "20px",
                    }}
                  /> */}

                  {/* <MobileTimePicker
                    label={"Batch End Time *"}
                    openTo="hours"
                    selected={endTime}
                    // ampm={false} // Use 24-hour clock format
                    views={["hours", "minutes"]}
                    // value={endTime}
                    onChange={handleEndTimeChange}
                    format="HH:mm" // Use 24-hour clock format
                    sx={{
                      width: "100%",
                      // marginTop: "20px",
                    }}
                  /> */}

                  {/* </DemoContainer> */}
                </LocalizationProvider>
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                sx={{ marginTop: 2 }}
              >
                {/* <div className="col-md-12 col-xs-12"> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // disabled
                    label="Start Date"
                    format="YYYY-MM-DD"
                    // value={formInputs?.date_of_birth}
                    value={dayjs(formInputs2.duration)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider> */}
                {/* </div> */}

                {/* <div className="col-md-12 col-xs-12"> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    label="Batch Start Date *"
                    // format="YYYY-MM-DD"
                    // value={dayjs(formInputs2.duration2)}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    label="Batch End Date *"
                    // format="YYYY-MM-DD"
                    // value={dayjs(formInputs2.duration2)}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
                {/* </div> */}
              </Stack>

              {additionalFields.map((field, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {/* Conditionally render the Remove button */}
                  {showRemoveButtons[index] && (
                    <IconButton
                      aria-label="Delete Field"
                      onClick={() => handleDeleteField(index)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <TextField
                    fullWidth
                    label={`Fee Title ${index + 1}`}
                    name="fee_title"
                    value={field.fee_title}
                    onChange={(e) => handleFieldChange(e, index)}
                    sx={{ marginRight: "10px" }}
                    required
                  />

                  <TextField
                    fullWidth
                    label={`Fee ${index + 1}`}
                    name="fee"
                    value={field.fee}
                    onChange={(e) => handleFieldChange(e, index)}
                    sx={{ marginRight: "10px" }}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R.S</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      // maxLength: 10, // Limit the input to 10 characters
                      // title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddField} // Your function to add a new field
                    sx={{ marginLeft: "10px" }}
                  >
                    Add
                  </Button>
                </div>
              ))}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Generate
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
