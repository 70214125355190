import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
  Checkbox,
  IconButton,
  Grid,
  MenuItem,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Box,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import {
  _assign_batch_multiple_students,
  listStudent,
  listStudentSearch,
} from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import { useSnackbar } from "notistack";
import moment from "moment";
import { _list_confirm_and_started_batch, listBatch } from "src/DAL/batch";
import { LoadingButton } from "@mui/lab";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "last_name", label: "Last Name", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },

  // { id: "batch", label: "Batch", alignRight: false },
  { id: "father_name", label: "Father Name", alignRight: false },
  // { id: "courses", label: "Courses", alignRight: false },
  { id: "contact_number", label: "Phone", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  // { id: "cnic", label: "CNIC", alignRight: false },
  { id: "time", label: "Time Preference", alignRight: false },
  { id: "gender", label: "Gender", alignRight: false },

  // {
  //   id: "registration_fee",
  //   label: "Reg Fee",
  //   alignRight: false,
  // },
  // { id: "total_fee", label: "Course Fee", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AssignBatchMultiStudent() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [batchesFee, setBatchesFee] = useState([]);
  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);
  const [batches, setBatches] = useState([]);
  const [trashStudentCount, setTrashStudentCount] = useState(0);
  const [freezeStudentCount, setFreezeStudentCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [registrationFeeChecked, setRegistrationFeeChecked] = useState(false);

 
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: "true",
    registration_fee: 0,
    batch_discount: 0,
    batch_fee_id: "",
    additional_discount: 0,
    additional_discount_reason: "",

    voucher_type: 0,
    voucher_status: 0,
    batch_id: [],
    batch_students_ids: [],

    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
    name: "",
    total_seats: "",
    status: "true",
    batch: "",
    registration_fee_discount: 0,
    registration_fee_discount_reason: "",
    fee_discount: "0",
    fee_discount_reason: "",
    discount: 0,
    fee_status: 2,
    courses: [],
    registration_fee: "",
  });

  const [registrationFeeDiscountChecked, setRegistrationFeeDiscountChecked] =
    useState(false);
  const [feeDiscountChecked, setFeeDiscountChecked] = useState(true);

  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);

  const [additionalFields, setAdditionalFields] = useState([
    // { fee_title: "", fee: "" },
  ]);

  const [discountChecked, setDiscountChecked] = useState(
    additionalFields.map(() => false)
  );

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }
  const handleCheckboxRegistrationFeeChange = (event) => {
    setRegistrationFeeChecked(event.target.checked);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleCheckboxRegistrationChange = (event) => {
    setRegistrationFeeDiscountChecked(event.target.checked);
  };
  const handleCheckboxFeeChange = (event) => {
    setFeeDiscountChecked(event.target.checked);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    if (startTime && endTime && startTime !== "") {
      // Parse the time strings using moment
      const startMoment = moment(startTime, "HH:mm");
      const endMoment = moment(endTime, "HH:mm");

      // Format the start time and end time with AM/PM
      const formattedStartTime = startMoment.format("hh:mm A");
      const formattedEndTime = endMoment.format("hh:mm A");

      const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

      return formattedTime;
    } else {
      return " _ _ ";
    }
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log("newSelected ___test", newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleBatchChange = (e) => {
    const { target } = e;
    console.log("target", target);

    let registrationFee = "";
    //   const filteredArray = batches
    // .filter(data => data.id === target.value )
    // .map(data => {
    //   // Modify or access the matching object as needed
    //   if(data.length > 0){
    //     setBatchesFee(data[0].batch_fee)
    //     console.log('data[0].batch_fee',data[0].batch_fee);
    //   }
    //   return data;
    // });

    const updatedBatches = batches.filter((batch) => batch.id === target.value);
    console.log("updatedBatches ____updatedBatches", updatedBatches);
    if (updatedBatches.length > 0) {
      registrationFee = updatedBatches[0].registration_fee;

      console.log("updatedBatches[0]", updatedBatches[0]);
      setBatchesFee(updatedBatches[0].batch_fee);
      console.log("updatedBatches[0].batch_fee", updatedBatches[0].batch_fee);

      const updatedBatchesFee = updatedBatches[0].batch_fee.map((feeItem) => ({
        ...feeItem,
        // fee: 0,
        fee_discount: feeItem.fee_discount ? feeItem.fee_discount : "0",
      }));
      console.log("updatedBatchesFee", updatedBatchesFee);

      setAdditionalFields(updatedBatchesFee);
      setDiscountChecked(updatedBatchesFee.map(() => false));

    }

    setFormInputs({
      ...formInputs,
      registration_fee: registrationFee,
      [target.name]: target.value,
    });
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    console.log("e __e", e);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
      //   batch_students_ids: [foundObject.students[0]._id],
      // batch_students_ids: [],
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNavBack = () => {
    navigate(-1);
  };
  const handleNav = () => {
    // navigate("/students/add-student");
    navigate(-1);
  };
  const handleAddFromEnquiry = () => {
    navigate("/students/add-FromEnquiry");
  };
  const handleMoveToTrash = () => {
    navigate("/trash/");
  };
  const handleMoveToFreeze = () => {
    navigate("/freeze-students");
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await listStudent();
    console.log("response ___listStudent", response);
    if (response.code === 200) {
      setTrashStudentCount(response.trash_student_count);
      setFreezeStudentCount(response.freeze_student_count);

      const students = response.students.map((student, index) => ({
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: capitalizeFirstLetter(student.father_name),
        address: student.address,
        reg_type: student.reg_type ? student.reg_type : "",
        contact_number: student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        total_fee: student.total_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        user_id: student.user_id ? student.user_id : "0",
        number: index + 1,
        time: formatTime(
          student.time_preference ? student.time_preference.start_time : "",
          student.time_preference ? student.time_preference.end_time : ""
        ),
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    const response = await listStudentSearch(searchText);
    if (response.code === 200) {
      const students = response.students.map((student, index) => ({
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: capitalizeFirstLetter(student.father_name),
        address: student.address,
        reg_type: student.reg_type ? student.reg_type : "",
        contact_number: student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        total_fee: student.total_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        user_id: student.user_id ? student.user_id : "0",
        number: index + 1,
        time: formatTime(
          student.time_preference ? student.time_preference.start_time : "",
          student.time_preference ? student.time_preference.end_time : ""
        ),
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf", typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    setSelected([]); // selected row of student reset
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: "" }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };
  
  const handleDiscountCheckboxChange = (index, isChecked) => {
    setDiscountChecked((prevChecked) => {
      const updatedChecked = [...prevChecked];
      updatedChecked[index] = isChecked;
      return updatedChecked;
    });
  };
  
  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("selected", selected);
    if (!(selected.length > 0)) {
      error = "Please! select at least one student.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    if (formInputs.fee_status === 3) {
      //////////////////// Free
      let postData = {
        students: selected,
        batch_id: formInputs.batch_id,
        fee_status: formInputs.fee_status,
        registration_fee_status: 2,
        student_fee: [],
      };

      if (registrationFeeChecked === true) {
        console.log("startDate", startDate);
        if (!startDate) {
          error = "Registration Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        console.log("formattedStartDate", formattedStartDate);
        if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        let registrationFeeDiscount = formInputs.registration_fee_discount;
        let registrationFeeDiscountReason =
          formInputs.registration_fee_discount_reason;

        if (typeof registrationFeeDiscount === "string") {
          registrationFeeDiscount = parseInt(registrationFeeDiscount, 10); // Use 10 as the radix for decimal numbers
        }

        if (!registrationFeeDiscountChecked) {
          registrationFeeDiscount = 0;
          registrationFeeDiscountReason = "";
        }

        postData = {
          students: selected,
          batch_id: formInputs.batch_id,
          fee_status: formInputs.fee_status,

          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          due_date_registration: formattedStartDate,
          registration_fee_status: 1,
          student_fee: [],
        };
      }

      setIsLoading(true);
      const result = await _assign_batch_multiple_students(postData);
      if (result.code === 200) {
        setIsLoading(false);
        navigate("/students");
        enqueueSnackbar("Assign Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      //////////////////// Not Free

      let registrationFeeDiscount = formInputs.registration_fee_discount;
      let registrationFeeDiscountReason =
        formInputs.registration_fee_discount_reason;

      let feeDiscount = formInputs.fee_discount;
      let feeDiscountReason = formInputs.fee_discount_reason;

      if (typeof registrationFeeDiscount === "string") {
        registrationFeeDiscount = parseInt(registrationFeeDiscount, 10); // Use 10 as the radix for decimal numbers
      }
      if (typeof feeDiscount === "string") {
        feeDiscount = parseInt(feeDiscount, 10); // Use 10 as the radix for decimal numbers
      }

      if (!registrationFeeDiscountChecked) {
        registrationFeeDiscount = 0;
        registrationFeeDiscountReason = "";
      }

      const updatedBatchesFeeNew = additionalFields.map((feeItem, index) => ({
        batch_fee_id: feeItem._id,
        fee_discount: discountChecked[index] ? feeItem.fee_discount : '0',
        fee_discount_reason: discountChecked[index]
          ? feeItem.fee_discount_reason || ""
          : "",
      }));

      console.log("updatedBatchesFeeNew", updatedBatchesFeeNew);

      let postData = {
        batch_id: formInputs.batch_id,
        students: selected,

        // registration_fee_discount: registrationFeeDiscount,
        // registration_fee_discount_reason: registrationFeeDiscountReason,
        // fee_discount: feeDiscount,
        // fee_discount_reason: feeDiscountReason,
        // due_date_registration: formattedStartDate,
        fee_status: formInputs.fee_status,
        registration_fee_status: 2,
        student_fee: updatedBatchesFeeNew,
      };

      if (formInputs.fee_status === 1) {
        console.log("endDate", endDate);
        if (!endDate) {
          error = "Fee Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedEndDate = endDate.format("YYYY-MM-DD");
        console.log("formattedEndDate", formattedEndDate);
        if (!moment(formattedEndDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Fee Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        postData = {
          batch_id: formInputs.batch_id,
          students: selected,

          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          // fee_discount: feeDiscount,
          // fee_discount_reason: feeDiscountReason,
          // due_date_registration: formattedStartDate,
          fee_status: formInputs.fee_status,

          // fee_status: formInputs.fee_status === 2 ? 2 : 1,
          registration_fee_status: 2,
          student_fee: updatedBatchesFeeNew,
          due_date_fee: formattedEndDate,
        };
      }

      if (formInputs.fee_status === 1 && registrationFeeChecked === true) {

        console.log("startDate", startDate);
        if (!startDate) {
          error = "Registration Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        console.log("formattedStartDate", formattedStartDate);
        if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        console.log("endDate", endDate);
        if (!endDate) {
          error = "Fee Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedEndDate = endDate.format("YYYY-MM-DD");
        console.log("formattedEndDate", formattedEndDate);
        if (!moment(formattedEndDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Fee Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        postData = {
          batch_id: formInputs.batch_id,
          students: selected,

          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          // fee_discount: feeDiscount,
          // fee_discount_reason: feeDiscountReason,
          due_date_registration: formattedStartDate,
          fee_status: formInputs.fee_status,

          // fee_status: formInputs.fee_status === 2 ? 2 : 1,
          registration_fee_status: 1,
          student_fee: updatedBatchesFeeNew,
          due_date_fee: formattedEndDate,
        };
      }

      if (formInputs.fee_status === 2 && registrationFeeChecked === true) {

        console.log("startDate", startDate);
        if (!startDate) {
          error = "Registration Due Date is required.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        // Validate the selected date format
        const formattedStartDate = startDate.format("YYYY-MM-DD");
        console.log("formattedStartDate", formattedStartDate);
        if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
          error = "Invalid Due Date Format.";
          enqueueSnackbar(error, { variant: "error" });
          return;
        }

        postData = {
          batch_id: formInputs.batch_id,
          students: selected,

          // registration_fee_discount: registrationFeeDiscount,
          // registration_fee_discount_reason: registrationFeeDiscountReason,
          // fee_discount: feeDiscount,
          // fee_discount_reason: feeDiscountReason,
          due_date_registration: formattedStartDate,
          fee_status: formInputs.fee_status,

          // fee_status: formInputs.fee_status === 2 ? 2 : 1,
          registration_fee_status: 1,
          student_fee: updatedBatchesFeeNew,
        };
      }


      console.log("postData __postData", postData);

      setIsLoading(true);
      const result = await _assign_batch_multiple_students(postData);
      if (result.code === 200) {
        setIsLoading(false);
        navigate("/students");
        enqueueSnackbar("Assign Successfully!", { variant: "success" });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await _list_confirm_and_started_batch();
    console.log("response listBatch _listBatch _test", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              ...batch,
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              students: batch.students,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              batch_fee: batch.batch_fee ? batch.batch_fee : [],
              number: index + 1,
              time: formatTime(batch.start_time_class, batch.end_time_class),
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      console.log("batches  _test", batches);
      console.log("batches[0]  _test", batches[0]);
      console.log("batches[0].batch_fee  _test", batches[0].batch_fee);
      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Assign Batch To Students">
      <Container maxWidth="xl">
      <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Assign Batch To Students </h4>
        </Grid>


        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
          mt={2}
        > */}
        {/* <IconButton onClick={handleNavBack} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4">Assign Students To Batch</Typography> */}
        {/* <div>
            <Button
              onClick={handleMoveToFreeze}
              variant="contained"
              color="info" // Set the color to "secondary" for red color
              startIcon={<Iconify icon="mdi:freeze-advisory" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              ( {freezeStudentCount} ) Freeze
            </Button>

            <Button
              onClick={handleMoveToTrash}
              variant="contained"
              color="error" // Set the color to "secondary" for red color
              startIcon={<Iconify icon="bi:trash" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              ( {trashStudentCount} ) Trash
            </Button>

            <Button
              onClick={handleAddFromEnquiry}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              Add From Equiry
            </Button>
            <Button
              onClick={handleNav}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Student
            </Button>
          </div> */}
        {/* </Stack> */}

        <div
        // sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
        >
          {/* <StudentListToolbar
            numSelected={selected.length} 
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={7}
              xl={7}
              sx={{ padding: "11px" }}
            >
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <Stack spacing={3}>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextField
                      fullWidth
                      label="Batch"
                      select
                      value={formInputs.batch_id}
                      onChange={handleBatchChange}
                      name="batch_id"
                      //   SelectProps={{ multiple: true }}
                      SelectProps={{
                        multiple: false,
                        // onClose: handleBatchClose, // Call your function here
                      }}
                      required
                    >
                      {batches.map((batch) => (
                        <MenuItem key={batch.id} value={batch.id}>
                          {capitalizeFirstLetter(batch.name)} ({batch.time})
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      fullWidth
                      label="Fee Status"
                      select
                      value={formInputs.fee_status}
                      onChange={handleChange}
                      name="fee_status"
                    >
                      <MenuItem value={3}>Free</MenuItem>
                      {/* <MenuItem value={2}>Installments</MenuItem> */}
                      <MenuItem value={2}>Monthly</MenuItem>
                      <MenuItem value={1}>All Paid</MenuItem>
                    </TextField>
                  </Stack> 


                  {formInputs.fee_status !== 3 &&
                    additionalFields.map((field, index) => (
                      <div key={index}>
                      <Box>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            fullWidth
                            label={`Fee Title ${index + 1}`}
                            name="fee_title"
                            value={capitalizeFirstLetter(field.fee_title)}
                            onChange={(e) => handleFieldChange(e, index)}
                            sx={{ marginRight: "10px" }}
                            disabled
                          />
                  
                          <TextField
                            fullWidth
                            label={`Fee ${index + 1}`}
                            name="fee"
                            value={"R.S " + field.fee}
                            onChange={(e) => handleFieldChange(e, index)}
                            sx={{ marginRight: "10px" }}
                            disabled
                            inputProps={{
                              pattern: "[0-9]*",
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                  
                  <div className="col-2"> 
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={discountChecked[index]}
                                  onChange={() =>
                                    handleDiscountCheckboxChange(index, !discountChecked[index])
                                  }
                                />
                              }
                              label={`Fee Discount ${index + 1}`}
                            />
                          </FormGroup>
                          </div>
                        </div>
                  
                        {discountChecked[index] && (
                          <div className="mt-3" style={{ display: "flex", alignItems: "center" }}>
                               <TextField
                                fullWidth
                                label={`Fee Discount ${index + 1}`}
                                name="fee_discount"
                                value={field.fee_discount}
                                onChange={(e) => handleFieldChange(e, index)}
                                sx={{ marginRight: "10px" }}
                                required
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      R.S
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  pattern: "[0-9]*", // Only allow numeric characters
                                  // maxLength: 10, // Limit the input to 10 characters
                                  // title: "Please enter a valid 10-digit phone number",
                                }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              
                            <TextField
                              fullWidth
                              label={`Fee Discount Reason ${index + 1}`}
                              name="fee_discount_reason"
                              value={field.fee_discount_reason}
                              onChange={(e) => handleFieldChange(e, index)}
                              sx={{ marginRight: "10px" }}
                              required
                            />
                          </div>
                        )}
                      </Box>
                    </div>
                       
                    ))}

           
                    {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
     
                      {formInputs.fee_status === 1 ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            selected={endDate}
                            onChange={handleEndDateChange}
                            label="Fee Due Date *"
                            // format="YYYY-MM-DD"
                            // value={dayjs(formInputs2.duration2)}
                            // minDate={dayjs()} // Set the minimum date to today
                            sx={{
                              width: "100%", // Set the width to 100% to take up the full available width
                              // marginTop: "20px",
                            }}
                          />
                        </LocalizationProvider>
                      ) : (
                        ""
                      )}
                    {/* </Stack> */}
                 

                  {formInputs.fee_status === 3 &&
                  registrationFeeChecked === true ? (
                    // <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    //   <LocalizationProvider dateAdapter={AdapterDayjs}>
                    //     <DatePicker
                    //       selected={startDate}
                    //       onChange={handleStartDateChange}
                    //       label="Registration Due Date *"
                    //       // format="YYYY-MM-DD"
                    //       // value={dayjs(formInputs2.duration2)}
                    //       minDate={dayjs()} // Set the minimum date to today
                    //       sx={{
                    //         width: "100%", // Set the width to 100% to take up the full available width
                    //         // marginTop: "20px",
                    //       }}
                    //     />
                    //   </LocalizationProvider>
                    //   <TextField
                    //     fullWidth
                    //     label="Registration Fee"
                    //     value={formInputs.registration_fee}
                    //     onChange={handleChange}
                    //     name="registration_fee"
                    //     disabled
                    //     InputProps={{
                    //       startAdornment: (
                    //         <InputAdornment position="start">
                    //           R.S
                    //         </InputAdornment>
                    //       ),
                    //     }}
                    //     inputProps={{
                    //       pattern: "[0-9]*", // Only allow numeric characters
                    //       // maxLength: 10, // Limit the input to 10 characters
                    //       // title: "Please enter a valid 10-digit phone number",
                    //     }}
                    //     onKeyPress={(event) => {
                    //       if (!/[0-9]/.test(event.key)) {
                    //         event.preventDefault();
                    //       }
                    //     }}
                    //   />

                    // </Stack>
                    <></>
                  ) : (
                    ""
                  )}

<Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {/* {formInputs.fee_status === 3 ? ( */}
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={registrationFeeChecked}
                              onChange={handleCheckboxRegistrationFeeChange}
                            />
                          }
                          label="Registration Fee"
                        />
                      </FormGroup>
                    {/* ) : (
                      ""
                    )} */}


                    {formInputs.fee_status === 3 &&
                    registrationFeeChecked === true ? (
                      // <FormGroup>
                      //   <FormControlLabel
                      //     control={
                      //       <Checkbox
                      //         checked={registrationFeeDiscountChecked}
                      //         onChange={handleCheckboxRegistrationChange}
                      //       />
                      //     }
                      //     label="Registration Fee Discount"
                      //   />
                      // </FormGroup>
                      <></>
                    ) : (
                      ""
                    )}
                    {formInputs.fee_status !== 3 ? (
                      // <FormGroup>
                      //   <FormControlLabel
                      //     control={
                      //       <Checkbox
                      //         checked={registrationFeeDiscountChecked}
                      //         onChange={handleCheckboxRegistrationChange}
                      //       />
                      //     }
                      //     label="Registration Fee Discount"
                      //     // disabled
                      //   />
                      // </FormGroup>
                      <></>
                    ) : (
                      ""
                    )}
                    {/* <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={feeDiscountChecked}
                            onChange={handleCheckboxFeeChange}
                          />
                        }
                        label="Fee Discount"
                        // disabled
                      />
                    </FormGroup> */}
                  </Stack>

                  {registrationFeeChecked ? (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateChange}
                          label="Registration Due Date *"
                          // format="YYYY-MM-DD"
                          // value={dayjs()}
                          // minDate={dayjs()} // Set the minimum date to today
                          sx={{
                            width: "100%", // Set the width to 100% to take up the full available width
                            // marginTop: "20px",
                          }}
                        />
                      </LocalizationProvider>
  ) : (
    ""
  )}

                  {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}> */}
                  {registrationFeeDiscountChecked &&
                  formInputs.fee_status === 3 &&
                  registrationFeeChecked === true ? (
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>

                      <TextField
                        fullWidth
                        label="Registration Fee Discount"
                        value={formInputs.registration_fee_discount}
                        onChange={handleChange}
                        name="registration_fee_discount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Stack>
                  ) : (
                    ""
                  )}

                  {registrationFeeDiscountChecked &&
                  formInputs.fee_status !== 3 ? (
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                      <TextField
                        fullWidth
                        label="Registration Fee"
                        value={formInputs.registration_fee}
                        onChange={handleChange}
                        name="registration_fee"
                        disabled
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Registration Fee Discount"
                        value={formInputs.registration_fee_discount}
                        onChange={handleChange}
                        name="registration_fee_discount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                     </Stack>
                  ) : (
                    ""
                  )}
                  {/* {feeDiscountChecked ? (
                      <TextField
                        fullWidth
                        label="Fee Discount"
                        value={formInputs.fee_discount}
                        onChange={handleChange}
                        name="fee_discount"
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              R.S
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          pattern: "[0-9]*", // Only allow numeric characters
                          // maxLength: 10, // Limit the input to 10 characters
                          // title: "Please enter a valid 10-digit phone number",
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    ) : (
                      ""
                    )} */}
                  {/* </Stack> */}

                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    {registrationFeeDiscountChecked &&
                    formInputs.fee_status === 3 &&
                    registrationFeeChecked === true ? (
                      <TextField
                        fullWidth
                        label="Registration Fee Discount Reason"
                        // multiline // Enable multiline
                        // rows={3} // Set the number of rows to 3
                        value={formInputs.registration_fee_discount_reason}
                        onChange={handleChange}
                        name="registration_fee_discount_reason"
                        required
                      />
                    ) : (
                      ""
                    )}

                    {registrationFeeDiscountChecked &&
                    formInputs.fee_status !== 3 ? (
                      <TextField
                        fullWidth
                        label="Registration Fee Discount Reason"
                        // multiline // Enable multiline
                        // rows={3} // Set the number of rows to 3
                        value={formInputs.registration_fee_discount_reason}
                        onChange={handleChange}
                        name="registration_fee_discount_reason"
                        required
                      />
                    ) : (
                      ""
                    )}
                    {formInputs.fee_status !== 3 ? (
                      // <TextField
                      //   fullWidth
                      //   label="Fee Discount Reason"
                      //   multiline // Enable multiline
                      //   rows={3} // Set the number of rows to 3
                      //   value={formInputs.fee_discount_reason}
                      //   onChange={handleChange}
                      //   name="fee_discount_reason"
                      //   required
                      // />
                      <></>
                    ) : (
                      ""
                    )}
                  </Stack>

                  <Stack alignItems="end">
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      loading={isLoading}
                      sx={{ width: "120px" }}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            </Grid>

            <hr />
            <TableContainer sx={{ minWidth: 800 }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="flex-end"
                sx={{ padding: "10px" }}
              >
                <TextField
                  label="Search"
                  value={searchText}
                  onChange={handleInputChange}
                />
                {/* <Button variant="contained" onClick={handleSearch}>
                  Search
                </Button> */}
                {/* <Button variant="contained" onClick={handleReset}>
                  Reset
                </Button> */}

<CustomResetButton handleReset={handleReset}/>

  

                {/* <Button
                onClick={handleOpenDrawerFilter}
                sx={{
                  spacing: 4,
                  borderRadius: "10px",
                  width: "100%",
                  p: 1.65,
                  mt: 1.2,
                  backgroundColor: "#f6f7f9",
                  display: "flex",
                  justifyContent: "center",
                  alignIitems: "center",
                  color: "black",
                }}
              >
                <span className="text-capitalize">Filters</span>
                <span className=" px-2">
                  {getIcon("material-symbols:filter-list")}
                </span>
              </Button> */}

                {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
              </Stack>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={students.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        name,
                        // last_name,
                        email,
                        // image,
                        status,
                        batch,
                        courses,
                        gender,
                        decided_fee,
                        total_fee,
                        registration_fee,
                        cnic,
                        number,
                        referred_by,
                        course_fee,
                        actual_fee,
                        father_name,
                        address,
                        reg_type,
                        contact_number,
                        time,
                      } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;
                      // let number = index + 1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {number}
                          </TableCell>

                          {/* <TableCell align="left" style={myStyle}>
                            {name}
                          </TableCell> */}
 
                            <TableCell align="left" style={myStyle}>
                             
                                <span>{capitalizeFirstLetter(name)}</span>
                          
                            </TableCell>
                 

                          {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}
                          {/* <TableCell align="left" style={myStyle}>
                            {last_name}
                          </TableCell> */}
                          {/* <TableCell align="left">{email}</TableCell> */}
                          {/* <TableCell align="left">
                            {" "}
                            <Avatar alt={first_name} src={s3baseUrl + image} />
                          </TableCell> */}

                          {/* <TableCell align="left" style={myStyle}>
                            {batch.length > 0
                              ? batch
                                  .map((batch, index) => {
                                    return batch !== null ? batch.name : "N/A";
                                  })
                                  .join(", ")
                              : "N/A"}
                          </TableCell> */}
                          <TableCell align="left" style={myStyle}>
                            {textEmptyToReturnHyphen(father_name)}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            +92{contact_number}
                          </TableCell>

                          <TableCell align="left">
                            {textEmptyToReturnHyphen(email)}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {time}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {gender === 1 ? "Male" : "Female"}
                          </TableCell>

                          {/* <TableCell align="left" style={myStyle}>
                            {registration_fee}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {total_fee}
                          </TableCell> */}

                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === false && "error") || "success"}
                            >
                              {status ? "Active" : "Inactive"}
                              {/* {/ {sentenceCase(status)} /} */}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            {/* <StudentMoreMenu
                              row={row}
                              students={students}
                              onStudentsUpdate={handleStudentsUpdate}
                              trashStudentCount={trashStudentCount}
                              setTrashStudentCount={setTrashStudentCount}
                            /> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[
              50,
              100,
              150,
              { value: students.length, label: "All" },
            ]}
            component="div"
            count={students.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />
    </Page>
  );
}
