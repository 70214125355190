import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom"; // Import RouterLink from React Router

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  Zoom,
  Tooltip,
  TableHead,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndtextEmptyToReturnHyphen,
  textEmptyToReturnHyphen,
  truncateString,
} from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";

const SimpleTable = ({ formInputs }) => {
  const navigate = useNavigate();
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [currency, setCurrency] = useState("Rs");

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const myStyle0 = {
    textTransform: "capitalize",
    textAlign: "center", // Align text in the center
    padding: "3px",
  };
  const myStyle = {
    textTransform: "capitalize",
    textAlign: "center", // Align text in the center
    padding: "6px",
  };
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    padding: "5px",
  };
  const myStyleHeaderCenter = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    textAlign: "center", // Align text in the center
  };
  const myStyle7 = {
    textTransform: "capitalize",
    textAlign: "center", // Align text in the center
  };
  const myStyleHeader2 = {
    // textTransform: "capitalize",
    fontWeight: 800,
    fontSize: "1.3rem", // Adjust the font size as needed
    margin: "1rem 0", // Add margin for spacing, adjust as needed
    textAlign: "center", // Align text in the center
  };

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }
  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await detailStudent(studentID);
    console.log("response response detailStudent detail __detail", response);
    if (response.code === 200) {
      setAdditionalFields(response.student.additional_numbers);

      // setCourses(response.student.courses);
      // const courseIds = response.student.courses.map((course) => course._id);

      // const batchIds = response.student.batch_id.map((batch) => batch._id);

      let batchIds = "";

      if (response.student.batch) {
        // batchIds = response.student.batch._id;
      }

      console.log(batchIds, "batchIdsbatchIds");

      // console.log(data.student.name);
      // const batchIdt = batchIds.join("");
      // const batchId = batchIds[0];
      let defaultDate = new Date();
      if (response.student.date_of_birth) {
        defaultDate = new Date(response.student.date_of_birth);
      }
      // console.log('defaultDate',defaultDate);
      //  if(response.student.date_of_birth ==="undefined"){
      //   console.log('defaultDate Date of birth is undefined  ');
      //  }else {

      //   console.log('defaultDate Date of birth is defined');
      //  }

      // const additionalFieldsFromBatch = response.student.decided_fee.map(
      //   (fields, index) => ({
      //     fee: fields.fee,
      //     month: fields.month,
      //     fee_status: fields.fee_status,
      //     // pending_fee: fields.pending_fee?fields.pending_fee:null,
      //     pending_fee: fields.pending_fee ? fields.pending_fee : 0,
      //     // pending_fee: fields.pending_fee,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      console.log("batchID _test", batchID);
      const filteredVoucherArray = response.student_vouchers.filter(
        (item) => item.batch?._id === batchID
      );
      const filteredBatchArray = response.student_batch.filter(
        (item) => item._id === batchID
      );
      let filteredBatchObj =
        filteredBatchArray.length > 0 ? filteredBatchArray[0] : [];
      console.log("filteredBatchArray _test", filteredBatchArray);
      console.log("filteredBatchObj _test", filteredBatchArray);

      setFormInputs({
        ...response.student,
        name: response.student.name,
        father_name: response.student.father_name,
        email: response.student.email,
        gender: response.student.gender,
        // student_id: response.student.student_id,
        address: response.student.address,
        contact_number: response.student.contact_number,
        fee_status: response.student.fee_status,
        status: response.student.status,
        education: response.student.education,
        cnic: response.student.cnic,
        referred_by: response.student.referred_by,
        address: response.student.address,

        latest_degree: response.student.latest_degree,
        degree_status: response.student.degree_status,
        completion_year: response.student.completion_year,
        institution_name: response.student.institution_name,
        semester: response.student.semester,

        time_preferences: response.student.time_preference
          ? response.student.time_preference._id
          : "",

        date_of_birth: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        dob: defaultDate.toISOString().split("T")[0]
          ? defaultDate.toISOString().split("T")[0]
          : null,
        // dob: defaultDate.toISOString().split("T")[0],

        discount: response.student.discount,
        // fee_status:
        //   response.student_batch.fee_status === 1
        //     ? "All Paid"
        //     : response.student_batch.fee_status === 2
        //     ? "Monthly"
        //     : response.student_batch.fee_status === 3
        //     ? "Is Free"
        //     : "Pending",
        fee_status: filteredBatchObj
          ? filteredBatchObj.student_info.fee_status
          : 0,

        registration_voucher: filteredBatchObj
          ? filteredBatchObj.student_info.registration_voucher
          : null,

        batch_fee: filteredBatchObj
          ? filteredBatchObj.student_info.batch_fee
          : [],
        batch_name: filteredBatchObj ? filteredBatchObj.name : "",

        // batch_fee: response.student_batch.batch_fee,
        // student_vouchers: response.student_vouchers,
        student_vouchers: filteredVoucherArray,

        registration_fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount
          : 0,
        registration_fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.registration_fee_discount_reason
          : " _ _ ",
        fee_discount: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount
          : 0,
        fee_discount_reason: filteredBatchObj
          ? filteredBatchObj.student_info.fee_discount_reason
          : " _ _ ",

        student_status: filteredBatchObj
          ? filteredBatchObj.student_info.student_status
          : 0,

        createdAt: response.student.createdAt,
        updatedAt: response.student.updatedAt,
      });

      // ["is_free", "installments", "all_paid"]= [3,2,1]

      // if (response.student.profile_image) {
      //   setImage(response.student.profile_image);
      //   setShow(true);
      // }
      //   if (batchIds && batchIds.length > 0) {
      //     fetchActiveBatches(batchIds);
      //   } else {
      //     fetchActiveBatches();
      //   }

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    // fetchStudentDetail();
    console.log("get_user_profile   ___get_user_profile", get_user_profile);
    console.log(
      "get_user_privilege_access   ___get_user_profile",
      get_user_privilege_access
    );
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }
  const centerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh', // Optional to center vertically in the viewport
  };
  return (
    <Page title="Batch Student Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <TableContainer component={Paper}>
          <div className="container">
            <div className="row ">
              <div className="col-12">
                <Table
                  sx={{
                    minWidth: "100%",
                    // paddingBottom: "30px",
                    // marginTop: "30px",
                    // marginBottom: "30px",
                    border: "1px solid #f1f3f4", // Set the border style here
                  }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Student Count
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.student_count}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Total Male Students
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.male_students}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Total Female Students
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.female_students}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Total Seats
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.total_seats}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align="left" style={myStyleHeader}>
                        Available Seats
                      </TableCell>
                      <TableCell align="left" style={myStyle}>
                        {formInputs.avaliable_seats}
                      </TableCell>
                    </TableRow>

                    {privilegesTypeCheckAndShowOrNot(
                  get_user_profile,
                  get_user_privilege_access,
                  "batch",
                  "admin"
                ) ? (
                      <TableRow>
                        <TableCell align="left" style={myStyleHeader}>
                          Status
                        </TableCell>
                        <TableCell align="left" style={myStyle0}>
                          <Label
                            variant="outlined"
                            color={
                              (formInputs.status === false && "error") ||
                              "success"
                            }
                          >
                            {formInputs.status ? "Active" : "Inactive"}
                            {/* {/ {sentenceCase(status)} /} */}
                          </Label>{" "}
                          <br />
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </TableContainer>
      </Grid>
    </Page>
  );
};

export default SimpleTable;
