// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Batches",
    icon: getIcon("ph:student-bold"),
    // openIcon: getIcon("material-symbols:fitbit-arrow-downward"),
    // closeIcon: getIcon("material-symbols:fitbit-arrow-upward"),
    children: [
      {
        title: "Batches",
        path: "/batches",
      },
    ],
  },
  {
    title: "Courses",
    path: "/courses",
    icon: getIcon("fe:book"),
  },
  {
    title: "Instructors",
    path: "/instructors",
    icon: getIcon("mdi:teacher"),
  },
  {
    title: "Students",
    icon: getIcon("fe:users"),
    children: [
      {
        title: "Students",
        path: "/students",
      },
      // {
      //   title: "Unassigned Students",
      //   path: "/unassigned-students",
      // },
      {
        title: "Trash",
        path: "/trash",
      },
      {
        title: "Time Preference",
        path: "/time-preference",
      },
    ],
  },
  {
    title: "Enquiry Students",
    path: "/enquiry-students",
    icon: getIcon("fe:users"),
  },

  {
    title: "Fee Vouchers",
    icon: getIcon("nimbus:money"),

    children: [
      {
        title: "Vouchers",
        path: "/vouchers/list-voucher",
      },
      {
        title: "Generate Voucher",
        path: "/vouchers/add-voucher",
      },
      {
        title: "Custom Voucher",
        path: "/vouchers/custom-voucher",
      },
    ],
  },
  // {
  //   title: "Attendance",
  //   path: "/attendance",
  //   icon: getIcon("material-symbols:assignment-outline"),
  // },
  {
    title: "Attendance",
    icon: getIcon("material-symbols:assignment-outline"),

    children: [
      {
        title: "Attendance",
        path: "/attendance",
      },
      {
        title: "Calendar",
        path: "/calendar-view",
      },
    ],
  },
  {
    title: "Leave Requests",
    path: "/leave-requests",
    icon: getIcon("pepicons-pop:leave"),
  },
  {
    title: "Assignments",
    path: "/assignments",
    icon: getIcon("mdi:paper-edit-outline"),
  },
  {
    title: "Assets",
    icon: getIcon("carbon:category"),
    children: [
      {
        title: "All Assets",
        path: "/company-asset",
      },
      {
        title: "Assets Categories",
        path: "/asset-category",
      },

      {
        title: "Vendor",
        path: "/vendor",
      },
      {
        title: "Brand",
        path: "/brand",
      },
    ],
  },
  {
    title: "Expenses",
    icon: getIcon("arcticons:expense-register"),
    children: [
      {
        title: "Top Up",
        path: "/top-up",
      },
      {
        title: "Expenses",
        path: "/expenses",
      },
      {
        title: "Expense Category",
        path: "/expense-category",
      },
    ],
  },
  {
    title: "Events",
    path: "/event",
    icon: getIcon("carbon:event"),
  },
  {
    title: "Role",
    path: "/role",
    icon: getIcon("eos-icons:role-binding"),
  },
  {
    title: "Managements",
    path: "/managements",
    icon: getIcon("raphael:customer"),
  },
  {
    title: "Announcements",
    path: "/announcements",
    icon: getIcon("mdi:announcement-outline"),
  },
  {
    title: "Support Tickets",
    path: "/support-tickets",
    icon: getIcon("material-symbols:support-agent"),
  },
  {
    title: "Contact Us",
    path: "/contactlist",
    icon: getIcon("fe:phone"),
  },

  {
    title: "Manage Website",
    icon: getIcon("material-symbols:settings"),
    children: [
      {
        title: "Pages",
        path: "/pages",
      },
      {
        title: "Menu",
        path: "/menu",
      },
      {
        title: "Website Settings",
        path: "/settings",
      },
    ],
  },

  // {
  //   title: "",
  //   path: "",
  // },
];

export default navConfig;
