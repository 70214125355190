import { filter } from "lodash";

import { useState, useEffect } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  Box,
  Tabs,
  Tab,
  Badge,
  CircularProgress,
  Tooltip,
  Zoom,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
  ListToolbar,
} from "src/components";
// import StudentListHead from "./components/StudentListHead";
// import StudentListToolbar from "./components/StudentListToolbar";
// import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

// import { listStudent } from "src/DAL/student";
import { listContact } from "src/DAL/contact";
import { UserListToolbar } from "src/pages/users";
import { useSnackbar } from "notistack";
import CustomResetButton from "src/components/GeneralComponents/CustomResetButton";
import CustomTabPanel from "src/components/GeneralComponents/CustomTabPanel";
import { styled } from "@mui/material/styles";
import { capitalizeFirstLetter, truncateString } from "src/utils/formatText";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import ContactDetail from "../ContactDetail";
import StudentMoreMenu from "./StudentMoreMenu";
// ----------------------------------------------------------------------
const MAX_CHARACTERS = 60;


// ----------------------------------------------------------------------

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: -8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, query) {
  if (query) {
    return filter(array, (_user) =>
      (_user.first_name + " " + _user.last_name)
        .toLowerCase()
        .includes(query.toLowerCase())
    );
  } else {
    return [];
  }
}

export default function ContactTable({ newContactCount, setNewContactCount, type }) {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("first_name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [contact, setContact] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [showFullMessage, setShowFullMessage] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [expandedRows, setExpandedRows] = useState([]);
  // const toggleMessageVisibility = () => {
  //   setShowFullMessage(!showFullMessage);
  // };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  const moment = require("moment");
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleMessageVisibility = (rowId) => {
    console.log(rowId, "idddd");
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(rowId)) {
        return prevExpandedRows.filter((id) => id !== rowId);
      } else {
        return [...prevExpandedRows, rowId];
      }
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // const truncatedMessage = (message) => {
  //   if (!message) {
  //     return ""; // or any default value you prefer when message is undefined or null
  //   }

  //   return showFullMessage ? message : message.slice(0, MAX_CHARACTERS);
  // };
  const truncatedMessage = (message) => {
    if (!message) {
      return ""; // or any default value you prefer when message is undefined or null
    }

    if (showFullMessage) {
      return message;
    }

    if (message.length > MAX_CHARACTERS) {
      return message.slice(0, MAX_CHARACTERS) + "...";
    }

    return message;
  };
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderMessage = (message) => {
    // if (expanded) {
    //   return message;
    // } else {
    //   // Get the first two lines of the message
    //   const firstTwoLines = message.split("\n").slice(0, 2).join("\n");
    //   console.log(firstTwoLines, "two lines");
    //   return firstTwoLines;
    // }
    const lines = message.split("\n");
    let renderedMessage = "";

    if (lines.length <= 2) {
      renderedMessage = message;
    } else {
      for (let i = 0; i < 2; i++) {
        renderedMessage += lines[i] + "\n";
      }
      renderedMessage = renderedMessage.trim(); // Remove trailing newline character
    }
    console.log(renderedMessage, "two lines");
    return renderedMessage;
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = contact.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(contact.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }

    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contact.length) : 0;

  const filteredContacts =
    filterName == "" ? [...contact] : applySortFilter(contact, filterName);

  const isUserNotFound = filteredContacts.length === 0;

  const SearchStudents = async (searchText) => {
    let response = [];
    // setIsLoading(true);
    let search_text = searchText;

    if (!search_text || search_text === "" || search_text === null) {
      search_text = "";
      response = await listContact();
    } else {
      response = await listContact({
        subject: search_text,
      });
    }

    // const response = await listStudentSearch(searchText);
    console.log("response  response  response _listContact Search", response);

    
    const unreadContacts = response.contacts.filter(
        (contact) => contact?.read_status === false
      );
      const readContacts = response.contacts.filter(
        (contact) => contact?.read_status === true
      );

    if (response.code === 200) {
      // setStudents(students.reverse());
      if(type===2){
        setContact(unreadContacts);
      }else{
        setContact(readContacts);
      }
      setNewContactCount(response.new_contact_count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchContact();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      setIsLoading(true);
      SearchStudents(searchText);
    }
  };
  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchContact();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  function returnFormattedDateOrTimeFromDate(dateFromApi, type) {
    const dateString = dateFromApi;
    const parsedDate = moment(dateString);

    if (type === "time" || type === "Time") {
      // Format time as "02:53 PM"
      return parsedDate.format("hh:mm A");
    } else {
      // Format date as "August 24, 2023"
      return parsedDate.format("MMMM D, YYYY");
    }
  }

  const fetchContact = async () => {
    setIsLoading(true);
    const response = await listContact();
    console.log("response  response  response _listContact", response);
    if (response.code === 200) {

      const unreadContacts = response.contacts.filter(
        (contact) => contact?.read_status === false
      );
      const readContacts = response.contacts.filter(
        (contact) => contact?.read_status === true
      );

      console.log("unreadContacts   ____TEST",unreadContacts);
      console.log("readContacts   ____TEST",readContacts);

      if(type===2){
        setContact(unreadContacts);
      }else{
        setContact(readContacts);
      }
      setNewContactCount(response.new_contact_count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchContact();
  }, []);
  if (isLoading) {
    return (
      <div className="container">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45vh", // Optional: Set the height to 100% of the viewport height
          }}
        >
          <CircularProgress size="3rem" />
        </Box>
      </div>
    );
  }
  return (
    <Page title="Contact">
      <TableContainer sx={{ minWidth: 800 }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ padding: "10px" }}
        >
          <TextField
            label="Search"
            value={searchText}
            onChange={handleInputChange}
          />
          {/* <Button variant="contained" onClick={handleSearch}>
              Search
            </Button> */}

          {/* <Button variant="contained" onClick={handleReset}>
                Reset
              </Button> */}
          <CustomResetButton handleReset={handleReset} />
        </Stack>
        <Table>
          {/* <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={students.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                /> */}

          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              {/* <TableCell align="left">Email</TableCell> */}
              <TableCell align="left">Phone#</TableCell>
              <TableCell align="left">Courses</TableCell>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Time</TableCell>

              <TableCell align="left">Message</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredContacts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const {
                  _id,
                  first_name,
                  name,
                  last_name,
                  email,
                  phone,
                  course,
                  message,
                  createdAt,
                } = row;
                const isItemSelected = selected.indexOf(name) !== -1;

                return (
                  <TableRow
                    hover
                    key={_id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell align="left" style={myStyle}>
                      {index + 1}
                    </TableCell>

{/* 
                    <TableCell align="left" style={myStyle}>
                      <div
                        className="on_click_text"
                        // style={{
                        //   cursor: "pointer",
                        // }}
                        onClick={() => handleOpenModal(row)}
                      >
                        {capitalizeFirstLetter(name)}
                      </div>
                    </TableCell> */}

                    <Tooltip
                          TransitionComponent={Zoom}
                          title={
                            name.length > 20
                              ? capitalizeFirstLetter(name)
                              : ""
                          }
                          arrow
                        >
                          <TableCell
                            align="left"
                            className="cell_onclick_effect"
                          >
                            <div
                        className="on_click_text"
                        // style={{
                        //   cursor: "pointer",
                        // }}
                        onClick={() => handleOpenModal(row)}
                      > 
                            {capitalizeFirstLetter(
                              truncateString(name, 20)
                            )}
                            </div>
                          </TableCell>
                        </Tooltip>

                    {/* <TableCell align="left">{email}</TableCell> */}
                    <TableCell align="left">0{phone}</TableCell>
                    <TableCell align="left" style={myStyle}>
                      {course !== null ? course.title : ""}
                    </TableCell>

                    <TableCell align="left" style={myStyle}>
                      {returnFormattedDateOrTimeFromDate(createdAt, "date")}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {returnFormattedDateOrTimeFromDate(createdAt, "time")}
                    </TableCell>

                    <Tooltip
                          TransitionComponent={Zoom}
                          title={
                            row.message?.length > 20
                              ? capitalizeFirstLetter(row.message)
                              : ""
                          }
                          arrow
                        >
                          <TableCell
                            align="left"
                            // className="cell_onclick_effect"
                          >
                         
                            {capitalizeFirstLetter(
                              truncateString(row.message, 20)
                            )}
                    
                          </TableCell>
                        </Tooltip>

                    {/* <TableCell style={{ width: "30%" }} align="left">
                      {expandedRows.includes(row._id) || showFullMessage
                        ? row.message
                        : truncatedMessage(row.message)}
                      {!showFullMessage &&
                        row.message?.length > MAX_CHARACTERS && (
                          <span
                            onClick={() => toggleMessageVisibility(row._id)}
                            style={{ cursor: "pointer", color: "blue" }}
                          >
                            {expandedRows.includes(row._id)
                              ? "... Show less"
                              : "... Show more"}
                          </span>
                        )}
                    </TableCell> */}

                    <TableCell align="right">
                                  <StudentMoreMenu
                                    row={row}
                                    students={contact}
                                    // handleOpenEditDrawer={handleOpenEditDrawer}
                                    onStudentsUpdate={setContact}
                                  
                                    
                                  />
                                </TableCell>

                    {/* <TableCell style={{ width: "30%" }} align="left">
                          {truncatedMessage(message)}
                          {!showFullMessage &&
                            (message?.length ?? 0) > MAX_CHARACTERS && (
                              <span
                                onClick={toggleMessageVisibility}
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                              >
                                ... Show more
                              </span>
                            )}
                         
                        </TableCell> */}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>

          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* </Scrollbar> */}

      <TablePagination
        rowsPerPageOptions={[50, 100, 150]}
        component="div"
        count={contact.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

<CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        // pageTitle="Batch Student Detail"
        // pageTitle={`Batch Student ( ${selectedRowStudent.name} ) Detail `}
        pageTitle={`${capitalizeFirstLetter(selectedRowStudent.name)} ( Detail ) `}
        componentToPassDown={
          <ContactDetail   type={type} newContactCount={newContactCount} setNewContactCount={setNewContactCount}  contact={contact} setContact={setContact} studentID={selectedRowStudent._id}/>
        }
      />

    </Page>
  );
}
