import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Table,
  LinearProgress,
  TableHead,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Label, Page } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { detailStudent, editStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { _detail_enquiry_student } from "src/DAL/enquirystudent";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndtextEmptyToReturnHyphen,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import {
  formatDateWithDay,
  formatNormalDate,
  formatTime24Hours,
} from "src/utils/formatTime";
import { _detail_company_asset } from "src/DAL/company_asset";
const CompanyAssetDetail = ({ selectedRow }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [image, setImage] = useState();
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batchesId, setBatchesId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);

  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);
  const [selectedRowStudent, setselectedRowStudent] = useState({
    _id: "",
    title: "",
    device_id: "",
    details: "",
    image: "",
    price: 0,
    quantity: 1,
    active_status: false,
    assigned_status: true,
    purchase_date: "",
    warranty_expire_date: "",
    warranty_card_image: "",
    receipt_image: "",
    link: "ok",
    vendor: {
      _id: "",
      name: "",
    },
    brand: {
      _id: "",
      title: "",
    },
    asset_category: {
      _id: "6596891fa7ded08fdbd0693",
      title: "",
      assignable_status: true,
    },
    assigned_history: [
      {
        student: {
          _id: "",
          name: "",
          cnic: "",
        },
        assigned_status: true,
        assigned_date: "",
        returned_date: null,
        _id: "",
      },
    ],
    repair_history: [
      {
        vendor: {
          _id: "",
          name: "",
        },
        repair_date: "",
        service_type: "",
        service_description: "",
        service_cost: 0,
        service_duration: 0,
        service_receipt_pic: "",
        _id: "",
      },
      {
        vendor: {
          _id: "",
          name: "",
        },
        repair_date: "",
        service_type: "",
        service_description: "",
        service_cost: 1,
        service_duration: 1,
        service_receipt_pic: "",
        _id: "",
      },
    ],
    createdAt: "",
    updatedAt: "",
    id: "",
    vendor_name: "",
    brand_title: "",
    asset_category_title: "",
    number: 3,
  });
  const paymentMethodLabels = {
    by_hand: "Pay by Hand",
    online_cash: "Pay Online in Cash",
    cheque: "Pay by Cheque",
  };

  const myStyle = {
    textTransform: "capitalize",
  };
  const myStyle2 = {
    textTransform: "capitalize",
    fontWeight: 600, // Set the font weight to 500 for bold text
  };
  const myStyleHeader = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
  };

  const myStyleHeader2 = {
    textTransform: "capitalize",
    fontWeight: 700, // Set the font weight to 500 for bold text
    fontSize: "1.3rem", // Adjust the font size as needed
    color: "#fff!important",
  };

  const myStyleHeader3 = {
    backgroundColor: "#0c2e60",
    color: "#fff",
  };

  const [formInputs, setFormInputs] = useState({
    name: "",
    father_name: "",
    email: "",
    gender: "",
    courses: [],
    course: [],
    contact_number: "",
    status: "",
    education: "",
    photo: "",
    batches: [],
    dob: "",
    course_fee: "",
    cnic: "",
    referred_by: "",
    address: "",
    reg_type: "regular",
    registration_fee: "",
    decided_fee: "",
    wallet_amount: 0,

    student_id: null,

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",

    friend_number: "",
    family_number: "",
    whatsapp_number: "",
    time_preferences: {
      _id: "0",
      type: "",
      start_time: "00:00",
      end_time: "00:00",
    },

    discount: 0,
    fee_status: "all_paid",
    batch_name: "",
    student_batches: [],
  });


 


  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  function handleFile(e) {
    // console.log(e.target.files);
    const file = e.target.files[0];
    // console.log(file);
    setImage(file);
    // console.log(image);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setImage("");
    // console.log(image);
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      // setCourses(selectedBatch.courses);
      console.log("selectedBatch", selectedBatch);

      // const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
      //   (batch, index) => ({
      //     // fee: batch.fee,
      //     fee: 0,
      //     month: batch.month,
      //     fee_status: "pending",
      //     pending_fee: 0,
      //   })
      // );

      // setAdditionalFieldsCourse(additionalFieldsFromBatch);

      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        // courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleAddFieldCourse = () => {
    // Add a new field as an object with default values
    if (additionalFieldsCourse.length < 1) {
      setAdditionalFieldsCourse([
        ...additionalFieldsCourse,
        { number: "", type: "June" },
      ]);

      // Update the state for visibility of Remove buttons
      setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
    }
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_company_asset(selectedRow.id);
    console.log("_detail_company_asset _detail_company_asset", response);
    // enquiry_form

    if (response.code === 200) {
      // let defaultStartDate = new Date();
      // console.log("defaultStartDate", defaultStartDate);
      // if (response.company_asset.purchase_date) {
      //   defaultStartDate = new Date(response.company_asset.purchase_date);
      //   console.log("defaultStartDate", defaultStartDate);
      // }
      // // console.log('defaultDate response',response);
      // // let defaultDate =  response.batch.start_date_batch;
      // console.log("defaultStartDate", defaultStartDate);

      // let defaultEndDate = new Date();
      // console.log("defaultEndDate", defaultEndDate);
      // if (response.company_asset.warranty_expire_date) {
      //   defaultEndDate = new Date(response.company_asset.warranty_expire_date);
      //   console.log("defaultEndDate", defaultEndDate);
      // }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      // console.log("defaultEndDate", defaultEndDate);

      // let studentID = "";
      // if (response.company_asset.assigned_history) {
      //   if (response.company_asset.assigned_history.length > 0) {
      //     let newObj = response.company_asset.assigned_history.reverse();
      //     studentID = newObj[0].student._id;
      //   }
      // }

      if (response.company_asset.status_image) {
        setImage(response.company_asset.status_image);
        setShow(true);
      }

      setselectedRowStudent({
        ...response.company_asset,
        image: response.company_asset.status_image?response.company_asset.status_image:"",
        title: response.company_asset.title,
        active_status: response.company_asset.active_status,
        assigned_status: response.company_asset.assigned_status,
        brand_id: response.company_asset.brand._id,
        category_id: response.company_asset.asset_category._id,
        vendor_id: response.company_asset.vendor._id,
        device_id: response.company_asset.device_id,
        quantity: response.company_asset.quantity,
        price: response.company_asset.price,
        link: response.company_asset.link,
        details: response.company_asset.details,

        // student_id: studentID,
      });
      // setFormInputs(response.company_asset);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchStudentDetail();
    // console.log(
    //   "selectedRowStudent _test ___selectedRowStudent",
    //   selectedRowStudent
    // );
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <Page title="Detail">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "30px" }}
      >
        <div className="container ">
        {show === true ? (
            <Stack
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <label htmlFor="image" className="form-label">
                {/* Image: */}
              </label>
              <Box
                position="relative"
                display="inline-block"
                border="2px solid #ccc"
                p={1}
              >
                {previewUrl ? (
                  <img
                    alt="image"
                    src={previewUrl}
                    width="150"
                    style={{ maxWidth: "100%" }}
                  />
                ) : (
                  <img
                    alt="image"
                    src={s3baseUrl + selectedRowStudent.image}
                    width="150"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                {/* <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton> */}
              </Box>
            </Stack>
          ) : null}

          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: "100%",
                // paddingBottom: "30px",
                marginTop: "30px",
                // marginBottom: "30px",
                border: "1px solid #f1f3f4", // Set the border style here
              }}
              aria-label="simple table"
            >
              <TableBody> 
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Title
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.title)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Device ID
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.device_id)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Details
                  </TableCell>
                  {/* <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.payment_method)}
                  </TableCell> */}

                  <TableCell align="left">
                    {textEmptyToReturnHyphen(selectedRowStudent.details)}
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Price
                  </TableCell>
                  <TableCell align="left">{selectedRowStudent.price}</TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Quantity
                  </TableCell>
                  <TableCell align="left">
                    {selectedRowStudent.quantity}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Link
                  </TableCell>
                  <TableCell align="left">{selectedRowStudent.link}</TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Purchase Date
                  </TableCell>
                  <TableCell align="left">
                    {formatDateWithDay(selectedRowStudent.purchase_date)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Warranty Expire Date
                  </TableCell>
                  <TableCell align="left">
                    {formatDateWithDay(selectedRowStudent.warranty_expire_date)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Vendor
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.vendor.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Brand
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.brand.title)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Assets Category
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(
                      selectedRowStudent.asset_category.title
                    )}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Student
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.student.name)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow> */}

                {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Expense Category
                  </TableCell>
                  <TableCell align="left">
                    {capitalizeFirstLetter(
                      selectedRowStudent.expense_category.title
                    )}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow> */}

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>

                  <TableCell align="left">
                    {/* <Label
                      variant="outlined"
                      color={
                        (selectedRowStudent.active_status === true &&
                          "success") ||
                        (selectedRowStudent.active_status === false && "error")
                      }
                    >
                      {selectedRowStudent.active_status === true && "Active"}
                      {selectedRowStudent.active_status === false && "Inactive"}
                    </Label> */}



                    <Label
                                  variant="outlined"
                                  color={
                                    selectedRowStudent.asset_status === 1
                                      ? "success"
                                      : selectedRowStudent.asset_status === 2
                                      ? "error"
                                      : selectedRowStudent.asset_status === 3
                                      ? "warning"
                                      : selectedRowStudent.asset_status === 4
                                      ? "info"
                                      : "default"
                                  }
                                >
                                  {selectedRowStudent.asset_status === 1
                                    ? "Active"
                                    : selectedRowStudent.asset_status === 2
                                    ? "Inactive"
                                    : selectedRowStudent.asset_status === 3
                                    ? "Maintenance"
                                    : selectedRowStudent.asset_status === 4
                                    ? "Sold"
                                    : "Unknown"}
                                </Label>
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {selectedRowStudent.asset_status !== 1 ? (
                  <TableRow>
                    <TableCell align="left" style={myStyleHeader}>
                      Status Note
                    </TableCell>
                    {/* <TableCell align="left">
                    {capitalizeFirstLetter(selectedRowStudent.payment_method)}
                  </TableCell> */}

                    <TableCell align="left">
                      {capitalizeFirstLetterAndtextEmptyToReturnHyphen(
                        selectedRowStudent.status_note
                      )}
                    </TableCell>

                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Assigned Status
                  </TableCell>

                  <TableCell align="left">
                    <Label
                      variant="outlined"
                      color={
                        selectedRowStudent.assigned_status === true
                          ? "warning"
                          : "info"
                      }
                    >
                      {selectedRowStudent.assigned_status === true
                        ? "Assigned"
                        : "Free"}
                    </Label>
                  </TableCell>

                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(selectedRowStudent.createdAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Updated At
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formatDateTimeWithOutZone(selectedRowStudent.updatedAt)}
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {" "}
                  </TableCell>
                </TableRow>

                {selectedRowStudent.assigned_status ? (
                  <TableRow>
                    <TableCell align="left" style={myStyleHeader}>
                      Assign To
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {selectedRowStudent.assigned_history.length > 0
                        ? [...selectedRowStudent.assigned_history]
                            .reverse()
                            .map((historyItem, index) => (
                              <p key={historyItem._id}>
                                {index === 0
                                  ? `${historyItem.person?.name}`
                                  : ""}
                              </p>
                            ))
                        : "Not Assigned"}
                    </TableCell>
                    <TableCell align="left" style={myStyle}>
                      {" "}
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>

            {selectedRowStudent.assigned_history.length ? (
              <>
                <Table
                  sx={{
                    minWidth: "100%",
                    // paddingBottom: "30px",
                    // marginTop: "30px",
                    // marginBottom: "30px",
                    border: "1px solid #f1f3f4", // Set the border style here
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                  aria-label="simple table"
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" style={myStyleHeader2}>
                        Assigned History
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table
                  sx={{
                    minWidth: "100%",
                    // paddingBottom: "30px",
                    // marginTop: "30px",
                    // marginBottom: "30px",
                    border: "1px solid #f1f3f4", // Set the border style here
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={myStyleHeader}>Name</TableCell>
                      <TableCell style={myStyleHeader}>Assigned Date</TableCell>
                      <TableCell style={myStyleHeader}>Returned Date</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {selectedRowStudent.assigned_history.map(
                      (historyItem, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" style={myStyle2}>
                            #{index + 1} {historyItem.person?.name}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {historyItem.assigned_date
                              ? formatDateWithDay(historyItem.assigned_date)
                              : " _ _ "}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {historyItem.returned_date
                              ? formatDateWithDay(historyItem.returned_date)
                              : " _ _ "}
                          </TableCell>
                        </TableRow>
                      )
                    )}

                    {/* <TableRow>
                  <TableCell align="left" style={myStyleHeader}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={myStyle}>
                    {formInputs.status ? "Active" : "Inactive"}
                  </TableCell>
                </TableRow> */}
                  </TableBody>
                </Table>
              </>
            ) : null}
          </TableContainer>
        </div>
      </Grid>
    </Page>
  );
};

export default CompanyAssetDetail;
