import { invokeApi } from "src/utils";

export const _list_attendance = async (data) => {
    const requestObj = {
      path: `api/attendance/list_attendance`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _upload_attendance = async (data) => {
    const requestObj = {
      path: `api/attendance/upload_attendance`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _get_attendance = async (data) => {
    const requestObj = {
      path: `api/attendance/get_attendance`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _get_attendance_for_start_date_end_date = async (data) => {
    const requestObj = {
      path: `api/attendance/get_attendance_for_start_date_end_date`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _get_attendance_for_specific_date = async (data) => {
    const requestObj = {
      path: `api/attendance/get_attendance_for_specific_date`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _detail_attendance= async (id) => {
    const requestObj = {
      // path: `api/collect_fee/detail_collect_fee/${id}`,
      path: `api/attendance/detail_attendance/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };

  export const _delete_attendance = async (data) => {
    const requestObj = {
      path: `api/attendance/delete_attendance`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_attendance = async (data) => {
    const requestObj = {
      path: `api/attendance/add_attendance`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _student_attendance = async (data) => {
    const requestObj = {
      path: `api/attendance/student_attendance`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };