import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch, listBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";
import { _add_fee_voucher } from "src/DAL/voucher";
import { capitalizeFirstLetter } from "src/utils/formatText";
const Add = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([false]);
  const [instructors, setInstructors] = useState([]);
  const [batches, setBatches] = useState([]);
  const [batcheFees, setbatcheFees] = useState([]);

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: 100,
    status: "true",
    registration_fee: 0,
    batch_discount: 0,
    batch_id: "",
    batch_fee_id: "",
    additional_discount: 0,
    additional_discount_reason: "",
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
  });

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [feeDiscountChecked, setFeeDiscountChecked] = useState(false);

  const myStyle = {
    textTransform: "capitalize",
  };

  const handleCheckboxFeeChange = (event) => {
    setFeeDiscountChecked(event.target.checked);
  };

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const urlParams = new URLSearchParams(window.location.search);
  let batchID = "";
  let feeID = "";

  if (urlParams.get("batch_id") != null) {
    batchID = urlParams.get("batch_id");
  }

  if (urlParams.get("fee_id") != null) {
    feeID = urlParams.get("fee_id");
  }
  // else {
  //   navigate("/vouchers/");
  // }

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
    });

    // Use Array.prototype.find to locate the desired object
    let foundObject = batches.find((obj) => obj.id === target.value);

    if (foundObject) {
      setbatcheFees(foundObject.batch_fee);
    } else {
      setbatcheFees([]);
    }

    // const { name, value } = e.target;
    // console.log("name", name);
    // console.log("value", value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   // [name]: value,
    //   courses: value,
    // }));

    // if(value){

    // }
    // fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    console.log("startDate", startDate);
    if (!startDate) {
      error = "Due Date is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    // Validate the selected date format
    const formattedStartDate = startDate.format("YYYY-MM-DD");
    console.log("formattedStartDate", formattedStartDate);
    if (!moment(formattedStartDate, "YYYY-MM-DD", true).isValid()) {
      error = "Invalid Due Date Format.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }

    let feeDiscount = formInputs.additional_discount;
    let feeDiscountReason = formInputs.additional_discount_reason;

    if (typeof feeDiscount === "string") {
      feeDiscount = parseInt(feeDiscount, 10); // Use 10 as the radix for decimal numbers
    }

    if (!feeDiscountChecked) {
      feeDiscount = 0;
      feeDiscountReason = "";
    }

    let postData = {
      batch_id: formInputs.batch_id,
      batch_fee_id: formInputs.batch_fee_id,
      due_date: formattedStartDate,
      additional_discount: feeDiscount,
      additional_discount_reason: feeDiscountReason,
    };
    console.log("postData _test", postData);

    setIsLoading(true);
    const result = await _add_fee_voucher(postData);
    if (result.code === 200) {
      setIsLoading(false);
      // navigate("/vouchers/list-voucher");
      // navigate(-1);

      setStartDate("");
      setFeeDiscountChecked(false);
      setFormInputs({
        ...formInputs,

        additional_discount: 0,
        additional_discount_reason: "",
      });

      enqueueSnackbar("Added Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await listBatch();
    console.log("response listBatch", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              batch_fee: batch.batch_fee ? batch.batch_fee : [],
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      console.log("batches  _test", batches);

      setBatches(batches);
      setIsLoading(false);

      if (batchID !== "") {
        // Use Array.prototype.find to locate the desired object
        let foundObject = batches.find((obj) => obj.id === batchID);
        console.log(" __foundObject", foundObject);
        if (foundObject) {
          setbatcheFees(foundObject.batch_fee);
        } else {
          setbatcheFees([]);
        }

        setFormInputs({
          ...formInputs,
          batch_id: batchID,
          batch_fee_id: feeID !== "" ? feeID : "",
        });
      }

      //       value={formInputs.batch_id}

      //       value={formInputs.batch_fee_id}

      //       let batchID = "";
      //  let feeID = "";
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBatches();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Generate Voucher">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            marginLeft:"-10px"
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Generate Voucher </h4>
        </Grid>


        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Batch"
                  select
                  value={formInputs.batch_id}
                  onChange={handleChange2}
                  name="batch_id"
                  SelectProps={{ multiple: false }}
                  required
                  style={myStyle}
                >
                  {batches.map((batch) => (
                    <MenuItem key={batch.id} value={batch.id} style={myStyle}>
                      {batch.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  label="Batch Fee"
                  select
                  value={formInputs.batch_fee_id}
                  onChange={handleChange}
                  name="batch_fee_id"
                  SelectProps={{ multiple: false }}
                  required
                >
                  {batcheFees.map((batcheFee) => (
                    <MenuItem key={batcheFee._id} value={batcheFee._id}>
                      {capitalizeFirstLetter(batcheFee.fee_title)} (
                      {batcheFee.fee} RS)
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    label="Due Date *"
                    // format="YYYY-MM-DD"
                    format="MM-DD-YYYY"
                    // value={dayjs(formInputs2.duration2)}
                    // minDate={dayjs()} // Set the minimum date to today
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider>
                {feeDiscountChecked ? (
                  <TextField
                    fullWidth
                    label="Additional Discount"
                    value={formInputs.additional_discount}
                    onChange={handleChange}
                    name="additional_discount"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R.S</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      // maxLength: 10, // Limit the input to 10 characters
                      // title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={feeDiscountChecked}
                        onChange={handleCheckboxFeeChange}
                      />
                    }
                    label="Additional Fee Discount"
                  />
                </FormGroup>
              </Stack>

              {feeDiscountChecked ? (
                <TextField
                  fullWidth
                  label="Additional Discount Reason"
                  value={formInputs.additional_discount_reason}
                  onChange={handleChange}
                  name="additional_discount_reason"
                  multiline // Enable multiline
                  rows={3} // Set the number of rows to 3
                  required
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              ) : (
                ""
              )}
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Generate
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
