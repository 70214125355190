import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Grid,
  InputAdornment,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Iconify } from "src/components";
import { CircularLoader, Page } from "src/components";
import { detailBatch, editBatch } from "src/DAL/batch";
import { _course_instructors, listActiveCourse } from "src/DAL/course";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "src/utils/formatText";
import { listStudent } from "src/DAL/student";
import {
  _list_active_expense_category,
  _list_expense_category,
} from "src/DAL/expense_category";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import { _detail_expense, _update_expense } from "src/DAL/expense";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import { _list_active_customers } from "src/DAL/customer";
import { _detail_top_up, _edit_top_up } from "src/DAL/expense_top_up";

const EditExpenseTopUp = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [pathOfUploadedFile, setPathOfUploadedFile] = useState("");

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const [instructors, setInstructors] = useState([]);

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [vendor, setVendor] = useState([]);
  const [students, setStudents] = useState([]);
  const [expenseCategory, ExpenseCategory] = useState([]);
  const [value, setValue] = useState("no"); // 'yes' or 'no'
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "true",
    order: "",
    courses: "",
    start_date_batch_select: "",
    end_date_batch_select: "",

    start_time_class: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),

    end_time_class: dayjs(new Date()),
    format_end_time: dayjs(dayjs(new Date())).format("HH:mm"),

    registration_fee: 0,
    batch_discount: 0,
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
    batch_status: 1,

    title: "",
    amount: "",
    payment_method: "by_hand",
    attachment: "",
    notes: "",
    vendor_id: "",
    student_id: "",
    tax_type: "",
    tax_amount: "",
    expense_category_id: "",
    link: "",
    other_link: "",
    transfer_by: "",
    transfer_to: "",
    cheque_number: "",
    active_status:true,
  });

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };
  // const handleTimeChange = (time) => {
  //   const formattedTime = dayjs(time).format("HH:mm");
  //   setFormInputs((values) => ({
  //     ...values,
  //     format_time: formattedTime,
  //     time: time,
  //   }));
  // };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const handleEndTimeChange = (time) => {
    console.log("time", time);
    const formattedTime = dayjs(time).format("HH:mm");
    console.log("time formattedTime", formattedTime);
    setInputs((values) => ({
      ...values,
      format_end_time: formattedTime,
      end_time: time,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      courses: value,
    }));

    fetchInstructors(value);

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_start_date_batch_select: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration: date }));
  // };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   const formattedDate = dayjs(date).format("MM/DD/YYYY");
  //   setFormInputs((values) => ({ ...values, start_date_batch_select: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };

  // const handleDateChange2 = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration2: date }));
  // };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  // const handleEndTimeChange = (time) => {
  //   setEndTime(time);
  // };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log("startDate", start_date_batch_select);
    // if (!start_date_batch_select) {
    //   error = "Date is required.";
    //   enqueueSnackbar(error, { variant: "error" });
    //   return;
    // }

    // // Validate the selected date format
    // const formattedStartDate = startDate.format("YYYY-MM-DD");
    // console.log("formattedStartDate", formattedStartDate);
    // if (!moment(start_date_batch_select , true).isValid()) {
    //   error = "Invalid Date Format.";
    //   enqueueSnackbar(error, { variant: "error" });
    //   return;
    // }

    // let taxType = formInputs.tax_type;
    // let taxAmount = formInputs.tax_amount;
    // if (value === "no") {
    //   taxType = "with_holding_tax";
    //   taxAmount = 0;
    // }

    let PostData = {
      title: formInputs.title,
      amount: formInputs.amount,
      payment_method: formInputs.payment_method,
      date: formInputs.start_date_batch_select,
      attachment: pathOfUploadedFile,
      notes: formInputs.notes,
    //   cheque_number: formInputs.cheque_number,
      transfer_by: formInputs.transfer_by,
      transfer_to: formInputs.transfer_to,
      active_status : formInputs.active_status,
    };

    if(formInputs.payment_method === "cheque"){
      PostData = {
          title: formInputs.title,
          amount: formInputs.amount,
          payment_method: formInputs.payment_method,
          date: formInputs.start_date_batch_select,
          attachment: pathOfUploadedFile,
          notes: formInputs.notes,
          cheque_number: formInputs.cheque_number,
          transfer_by: formInputs.transfer_by,
          transfer_to: formInputs.transfer_to,
          active_status : formInputs.active_status,
        };
  }

  console.log("PostData   __PostData",PostData);
    setIsLoading(true);

    const result = await _edit_top_up(id, PostData);
    if (result.code === 200) { 
      setIsLoading(false);
      navigate("/top-up");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await _detail_top_up(id);
    console.log("response _detail_top_up _detail_top_up", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.top_up.date) {
        defaultStartDate = new Date(response.top_up.date);
        console.log("defaultStartDate", defaultStartDate);
      }
      // console.log('defaultDate response',response);
      // let defaultDate =  response.batch.start_date_batch;
      console.log("defaultStartDate", defaultStartDate);

      // if (response.expense.tax_amount > 0) {
      //   setValue("yes");
      // }

      setPathOfUploadedFile(response.top_up.attachment);

      setFormInputs({
        ...formInputs,
        ...response.top_up,
        cheque_number: response.top_up.cheque_number,
      
        title: capitalizeFirstLetter(response.top_up.title),
        transfer_by: response.top_up.transfer_by.user_id,
        transfer_to: response.top_up.transfer_to.user_id,
 
        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fetchActiveCustomers = async () => {
    setIsLoading(true);
    const response = await _list_active_customers();

    // list_asset_category

    console.log("response _get_customers", response);
    if (response.code === 200) {
      const customers = response.active_customers.map((customer, index) => ({
        ...customer,
        id: customer._id,
        number: index + 1,
      }));

      setStudents(customers);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  // useEffect(() => {
  //   if (instructors.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       instructors: [instructors[0]._id],
  //     }));
  //   }
  // }, [instructors]);

  useEffect(() => {
    fetchDetailBatch();
    fetchActiveCustomers();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Top Up">
    <div className="container px-4 mb-5 container_in_all_pages">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={handleNav} sx={{ flex: 0 }}>
          <Iconify icon="ep:back" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h4 className="title_heading_in_all_pages_top_left">Edit Top Up</h4>
      </Grid>

      <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                label="Title"
                value={formInputs.title}
                onChange={handleChange}
                name="title"
                required
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              <TextField
                fullWidth
                label="Amount"
                value={formInputs.amount}
                onChange={handleChange}
                name="amount"
                required
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}

                inputProps={{
                  pattern: "[0-9]*", // Only allow numeric characters
                  // maxLength: 10, // Limit the input to 10 characters
                  // title: "Please enter a valid 10-digit phone number",
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <TextField
                fullWidth
                label="Payment Method"
                select
                value={formInputs.payment_method}
                onChange={handleChange}
                name="payment_method"
                required
              >
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="cheque">Cheque Deposit</MenuItem>
                <MenuItem value="online_transfer">Online Transfer</MenuItem>
              </TextField>
            </Stack> 

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {formInputs.payment_method === "cheque"?(
     <TextField
     fullWidth
     label="Cheque NO"
     value={formInputs.cheque_number}
     onChange={handleChange}
     name="cheque_number"
     required
  
     inputProps={{
       pattern: "[0-9]*", // Only allow numeric characters
     }}
     onKeyPress={(event) => {
       if (!/[0-9]/.test(event.key)) {
         event.preventDefault();
       }
     }}
   />

              ):("")}

              <TextField
                fullWidth
                label="Paid By"
                select
                value={formInputs.transfer_by}
                onChange={handleChange}
                name="transfer_by"
                required
                // SelectProps={{ multiple: true }}
              >
                {students.map((customer) => (
                  <MenuItem key={customer.user_id} value={customer.user_id}>
                    {capitalizeFirstLetter(customer.first_name)}{" "}
                    {capitalizeFirstLetter(customer.last_name)}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                label="Paid To"
                select
                value={formInputs.transfer_to}
                onChange={handleChange}
                name="transfer_to"
                required
                // SelectProps={{ multiple: true }}
              >
                {students.map((customer) => (
                       <MenuItem key={customer.user_id} value={customer.user_id}>
                       {capitalizeFirstLetter(customer.first_name)}{" "}
                       {capitalizeFirstLetter(customer.last_name)}
                     </MenuItem>
                ))}
              </TextField>

              {formInputs.payment_method !== "cheque"?(
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date *"
                value={dayjs(formInputs.start_date_batch)} // Convert the default date to a Day.js object
                onChange={handleDateChange}
                // maxDate={dayjs()} // Set the maximum date to today's date
                // renderInput={(params) => <TextField {...params} />}
                sx={{
                  width: "100%", // Set the width to 100% to take up the full available width
                }}
              />
            </LocalizationProvider>
              ):("")}
            </Stack>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            {formInputs.payment_method === "cheque"?(
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date *"
          value={dayjs(formInputs.start_date_batch)} // Convert the default date to a Day.js object
          onChange={handleDateChange}
          // maxDate={dayjs()} // Set the maximum date to today's date
          // renderInput={(params) => <TextField {...params} />}
          sx={{
            width: "100%", // Set the width to 100% to take up the full available width
          }}
        />
      </LocalizationProvider>
              ):("")}

<TextField
                  fullWidth
                  label="Status"
                  select
                  value={formInputs.active_status}
                  onChange={handleChange}
                  name="active_status"
                  // required
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </TextField>
    </Stack>

            <CustomImageUploader
              pathOfUploadedFile={pathOfUploadedFile}
              setPathOfUploadedFile={setPathOfUploadedFile}
              imageFileHeading={"Image File"}
              imageFileSubHeading={"Image Size: 1MB or below :"}
              imageFileLimitSizeUpload={1}
              imageFileButtonText={"Upload"}
            />

            <TextField
              fullWidth
              label="Description"
              multiline
              rows={6} // Specify the number of rows you want
              value={formInputs.notes}
              onChange={handleChange}
              name="notes"
            />

            <Stack alignItems="end">
              <LoadingButton
                size="medium"
                type="submit"
                variant="contained"
                loading={isLoading}
                sx={{ width: "120px" }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Grid>
    </div>
  </Page>
  );
};

export default EditExpenseTopUp;
