import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Divider,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";

// mock

import TableHead from "@mui/material/TableHead";

//
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent } from "src/DAL/student";
import { _detail_collect_fee, _list_collect_fee } from "src/DAL/collect_fee";
import { _batch_expected_revenue, detailBatch } from "src/DAL/batch";

// import BatchListHead from "./components/BatchListHead";
// import BatchListToolbar from "./components/BatchListToolbar";
// import BatchMoreMenu from "./components/BatchMoreMenu";
import StudentMoreMenu from "./components/StudentMoreMenu";
import moment from "moment";
import { useSnackbar } from "notistack";
import DataNotFound from "src/components/GeneralComponents/DataNotFound";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import { StudentDetail } from "src/pages";
// import BatchStudentDetail from "./BatchStudentDetail";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import { formatDateTimeWithOutZone } from "src/utils";
import {
  _assignment_students,
  _list_approved_submitted_unsubmitted_assignments,
} from "src/DAL/assignment";
import BatchStudentDetail from "src/pages/Batches/List/BatchStudentDetail";
import CustomTabPanel from "src/components/GeneralComponents/CustomTabPanel";
import AssignmentStudentsTable from "./components/AssignmentStudentsTable";
import AssignmentTable from "./components/AssignmentTable";

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AssignmentStudents() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const myElementRef = useRef(null);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  
  const [students, setStudents] = useState([]);
  const [unsubmittedAssignmentStudents, setUnsubmittedAssignmentStudents] =
  useState([]);
  const [submittedAssignmentStudents, setSubmittedAssignmentStudents] =
    useState([]);
  const [approvedAssignmentStudents, setApprovedAssignmentStudents] = useState(
    []
    );
    const [assignmentData, setAssignmentData] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    
    const [assignmentAllData, setAssignmentAllData] = useState([]);
    
    const [searchText, setSearchText] = useState("");
    
    const [studentPendingCount, setStudentPendingCount] = useState(0);
    const [studentSubmitCount, setStudentSubmitCount] = useState(0);
    const [studentApproveCount, setStudentApproveCount] = useState(0);

  const [firstRowData, setFirstRowData] = useState([]);
  const [lastRowData, setLastRowData] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [totalPaidFee, setTotalPaidFee] = useState("0");
  const [dueFee, setDueFee] = useState("0");
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "",
    order: "",
    courses: [],
    predicted_revenue: [],
    collected_revenue: [],
  });

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const { id } = useParams();
  console.log("_ID _ID _ID", id);
  let batch_ID = id;
  const handleNav = () => {
    navigate(-1);
  };

  const handleNavigate = (_id) => {
    navigate(`/batches/move-student-to-batch/${_id}?batch_id=${id}`);
  };

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
    fetchDetailAssignmentStudents();
  }

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    const endMoment = moment(endTime, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedEndTime = endMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

    return formattedTime;
  }

  const fetchDetailAssignmentStudents = async () => {
    setIsLoading(true);

    const response = await _list_approved_submitted_unsubmitted_assignments(id);
    console.log(
      "response _list_approved_submitted_unsubmitted_assignments",
      response
    );
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      const unsubmittedStudents = response.unsubmitted_assignment_students.map(
        (student, index) => ({
          ...student, // Spread the original student object
          name: capitalizeFirstLetter(student.name),
          father_name: capitalizeFirstLetter(student.father_name),
          id: student._id, // Override the id property
          number: index + 1, // Override the number property
        })
      );

      const submittedStudents = response.submitted_assignment_students.map(
        (student, index) => ({
          ...student, // Spread the original student object
          name: capitalizeFirstLetter(student.name),
          father_name: capitalizeFirstLetter(student.father_name),
          id: student._id, // Override the id property
          number: index + 1, // Override the number property
        })
      );

      const approvedStudents = response.approved_assignment_students.map(
        (student, index) => ({
          ...student, // Spread the original student object
          name: capitalizeFirstLetter(student.name),
          father_name: capitalizeFirstLetter(student.father_name),
          id: student._id, // Override the id property
          number: index + 1, // Override the number property
        })
      );

      console.log("approvedStudents",approvedStudents);
      setUnsubmittedAssignmentStudents(unsubmittedStudents);
      setSubmittedAssignmentStudents(submittedStudents);
      setApprovedAssignmentStudents(approvedStudents);
      setAssignmentData(response.assignment);
      setAssignmentAllData(response);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailAssignmentStudents();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Assignment Students">
      <Container maxWidth="xl">
        <div style={{ padding: "0 5px" }}>
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </div>

        {/* <div style={{ padding: "0 15px" }}>
          <span style={{ textAlign: "center", fontWeight: "600" }}>
            {capitalizeFirstLetter(assignmentData?.title)}
          </span>
        </div> */}

        <Divider sx={{ fontWeight: 500 }}>Assignment</Divider>
        <AssignmentTable assignmentData={assignmentData} />

        {/* <Divider sx={{ fontWeight: 500 }}>Students</Divider> */}

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label={`Unsubmitted Assignment Students (${studentPendingCount})`} {...a11yProps(0)} />
              <Tab label={`Submitted Assignment Students (${studentSubmitCount})`} {...a11yProps(1)} />
              <Tab label={`Approved Assignment Students (${studentApproveCount})`} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AssignmentStudentsTable
              // students={unsubmittedAssignmentStudents}
              // setStudents={setUnsubmittedAssignmentStudents}
              students={assignmentAllData}
              setStudents={setAssignmentAllData}
              assignmentData={assignmentData}
              batchID={id}
              assignment_status={1}
              assignment_position={1}
              setStudentPendingCount={setStudentPendingCount}
              setStudentSubmitCount={setStudentSubmitCount}
              setStudentApproveCount={setStudentApproveCount}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <AssignmentStudentsTable
              students={assignmentAllData}
              setStudents={setAssignmentAllData}
              assignmentData={assignmentData}
              batchID={id}
              assignment_status={2}
              assignment_position={2}
              setStudentPendingCount={setStudentPendingCount}
              setStudentSubmitCount={setStudentSubmitCount}
              setStudentApproveCount={setStudentApproveCount}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <AssignmentStudentsTable
              students={assignmentAllData}
              setStudents={setAssignmentAllData}
              assignmentData={assignmentData}
              batchID={id}
              assignment_status={3}
              assignment_position={3}
              setStudentPendingCount={setStudentPendingCount}
              setStudentSubmitCount={setStudentSubmitCount}
              setStudentApproveCount={setStudentApproveCount}
            />
          </CustomTabPanel>
        </Box>
      </Container>
    </Page>
  );
}
