import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page, CircularLoader } from "src/components";

import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";
import { delImage } from "src/DAL/delfiles";
import { addImage, addImageWithSize } from "src/DAL/uploadfiles";
import { editSettings, listSettings } from "src/DAL/settings";
import CustomImageUploaderInWebSetting from "src/components/GeneralComponents/CustomImageUploaderInWebSetting";

const Settings = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate("/dashboard");
  };

  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [topCistLogo, setTopCistLogo] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [show, setShow] = useState(false);
  const [footerCistLogo, setFooterCistLogo] = useState();
  const [previewUrl1, setPreviewUrl1] = useState();
  const [show1, setShow1] = useState(false);

  const [headerPhoneIcon, setHeaderPhoneIcon] = useState();
  const [previewUrl2, setPreviewUrl2] = useState();
  const [show2, setShow2] = useState(false);

  const [headerMailIcon, setHeaderMailIcon] = useState();
  const [previewUrl3, setPreviewUrl3] = useState();
  const [show3, setShow3] = useState(false);

  const [headerNavigationIcon, setHeaderNavigationIcon] = useState();
  const [previewUrl4, setPreviewUrl4] = useState();
  const [show4, setShow4] = useState(false);

  const [headerFacebookIcon, setHeaderFacebookIcon] = useState();
  const [previewUrl5, setPreviewUrl5] = useState();
  const [show5, setShow5] = useState(false);

  const [headerTwitterIcon, setHeaderTwitterIcon] = useState();
  const [previewUrl6, setPreviewUrl6] = useState();
  const [show6, setShow6] = useState(false);

  const [headerLinkedInIcon, setHeaderLinkedInIcon] = useState();
  const [previewUrl7, setPreviewUrl7] = useState();
  const [show7, setShow7] = useState(false);

  const [footerFacebookLogo, setFooterFacebookLogo] = useState();
  const [previewUrl8, setPreviewUrl8] = useState();
  const [show8, setShow8] = useState(false);

  const [footerLinkedInLogo, setFooterLinkedInLogo] = useState();
  const [previewUrl9, setPreviewUrl9] = useState();
  const [show9, setShow9] = useState(false);

  const [footerInstagramLogo, setFooterInstagramLogo] = useState();
  const [previewUrl10, setPreviewUrl10] = useState();
  const [show10, setShow10] = useState(false);

  const [pathOfUploadedFileFavicon, setPathOfUploadedFileFavicon] =
    useState("");
  const [pathOfUploadedFileTopDditLogo, setPathOfUploadedFileTopDditLogo] =
    useState("");
  const [
    pathOfUploadedFileFooterDditLogo,
    setPathOfUploadedFileFooterDditLogo,
  ] = useState("");
  const [
    pathOfUploadedFileFooterCallLogo,
    setPathOfUploadedFileFooterCallLogo,
  ] = useState("");
  const [
    pathOfUploadedFileFooterAddressLogo,
    setPathOfUploadedFileFooterAddressLogo,
  ] = useState("");
  const [
    pathOfUploadedFileFooterMailLogo,
    setPathOfUploadedFileFooterMailLogo,
  ] = useState("");

  const [formInputs, setFormInputs] = useState({
    _id: "",
    header_phone_icon: "",
    header_phone_number: "",
    header_mail_icon: "",
    header_mail_address: "",
    header_navigation_icon: "",
    header_navigation_address: "",
    header_facbook_icon: "",
    header_facbook_link: "",
    header_twitter_icon: "",
    header_twitter_link: "",
    header_linkedIn_icon: "",
    header_linkedIn_link: "",
    top_cist_logo: "",
    top_cist_link: "",
    top_home_text: "",
    top_home_link: "",
    top_about_text: "",
    top_about_link: "",
    top_courses_text: "",
    top_courses_link: "",
    top_contactUs_text: "",
    top_contactUs_link: "",
    top_apply_course_button: "",
    footer_cist_logo: "",
    footer_cist_logo_link: "",
    footer_cist_text: "",
    footer_quick_link: "",
    footer_courses_text: "",
    footer_courses_link: "",
    footer_aboutUs_text: "",
    footer_aboutUs_link: "",
    footer_termsConditions_text: "",
    footer_termsConditions_link: "",
    footer_helpCenter_text: "",
    footer_support_text: "",
    footer_support_link: "",
    footer_getHelp_text: "",
    footer_getHelp_link: "",
    footer_privacyPolicy_text: "",
    footer_privacyPolicy_link: "",
    footer_contact_info_text: "",
    footer_call_text: "",
    footer_call_number: "",
    footer_address_text: "",
    footer_full_address: "",
    footer_mail_text: "",
    footer_mail_address: "",
    footer_facebook_logo: "",
    footer_facebook_link: "",
    footer_linkedIn_logo: "",
    footer_linkedIn_link: "",
    footer_instagram_logo: "",
    footer_instagram_link: "",
    copyRight_text: "",
    copyRight_metalogix_title: "",
    copyRight_metalogix_title_link: "",

    footer_ddit_logo_link: "",
    top_ddit_link: "",
    footer_ddit_logo_description: "",
  });
  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  function handleTopCistLogo(e) {
    const file = e.target.files[0];
    uploadImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  const uploadImage = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setTopCistLogo(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteTopCistLogo() {
    handleForm(topCistLogo);
    setTopCistLogo("");

    setShow(false);
  }
  const handleForm = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  //////
  function handleFooterCistLogo(e) {
    const file = e.target.files[0];
    uploadFooterImage(file);
    setPreviewUrl1(URL.createObjectURL(file));
    setShow1(true);
  }
  const uploadFooterImage = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setFooterCistLogo(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  function handleDeleteFooterCistLogo() {
    handleFormFooter(footerCistLogo);
    setFooterCistLogo("");
    setShow1(false);
  }
  const handleFormFooter = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  ///////////////

  function handleHeaderPhoneIcon(e) {
    const file = e.target.files[0];
    uploadHeaderPhoneIcon(file);
    setPreviewUrl2(URL.createObjectURL(file));
    setShow2(true);
  }
  const uploadHeaderPhoneIcon = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setHeaderPhoneIcon(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteHeaderPhoneIcon() {
    handleFormHeaderPhoneIcon(headerPhoneIcon);
    setHeaderPhoneIcon("");

    setShow2(false);
  }
  const handleFormHeaderPhoneIcon = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  /////
  function handleHeaderMailIcon(e) {
    const file = e.target.files[0];
    uploadHeaderMailIcon(file);
    setPreviewUrl3(URL.createObjectURL(file));
    setShow3(true);
  }
  const uploadHeaderMailIcon = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setHeaderMailIcon(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteHeaderMailIcon() {
    handleFormHeaderMailIcon(headerMailIcon);
    setHeaderMailIcon("");

    setShow3(false);
  }
  const handleFormHeaderMailIcon = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  /////////
  ///////
  /////
  function handleHeaderNavigationIcon(e) {
    const file = e.target.files[0];
    uploadHeaderNavigationIcon(file);
    setPreviewUrl4(URL.createObjectURL(file));
    setShow4(true);
  }
  const uploadHeaderNavigationIcon = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setHeaderNavigationIcon(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteHeaderNavigationIcon() {
    handleFormHeaderNavigationIcon(headerNavigationIcon);
    setHeaderNavigationIcon("");

    setShow4(false);
  }
  const handleFormHeaderNavigationIcon = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  /////
  function handleHeaderFacebookIcon(e) {
    const file = e.target.files[0];
    uploadHeaderFacebookIcon(file);
    setPreviewUrl5(URL.createObjectURL(file));
    setShow5(true);
  }
  const uploadHeaderFacebookIcon = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setHeaderFacebookIcon(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteHeaderFacebookIcon() {
    handleFormHeaderFacebookIcon(headerFacebookIcon);
    setHeaderFacebookIcon("");

    setShow5(false);
  }
  const handleFormHeaderFacebookIcon = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  /////
  function handleHeaderTwitterIcon(e) {
    const file = e.target.files[0];
    uploadHeaderTwitterIcon(file);
    setPreviewUrl6(URL.createObjectURL(file));
    setShow6(true);
  }
  const uploadHeaderTwitterIcon = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setHeaderTwitterIcon(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteHeaderTwitterIcon() {
    handleFormHeaderTwitterIcon(headerTwitterIcon);
    setHeaderTwitterIcon("");

    setShow6(false);
  }
  const handleFormHeaderTwitterIcon = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  /////
  function handleHeaderLinkedIncon(e) {
    const file = e.target.files[0];
    uploadHeaderLinkedIncon(file);
    setPreviewUrl7(URL.createObjectURL(file));
    setShow7(true);
  }
  const uploadHeaderLinkedIncon = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setHeaderLinkedInIcon(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteHeaderLinkedIncon() {
    handleFormHeaderLinkedIncon(headerLinkedInIcon);
    setHeaderLinkedInIcon("");

    setShow7(false);
  }
  const handleFormHeaderLinkedIncon = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  /////
  function handleFooterFacebookLogo(e) {
    const file = e.target.files[0];
    uploadFooterFacebookLogo(file);
    setPreviewUrl8(URL.createObjectURL(file));
    setShow8(true);
  }
  const uploadFooterFacebookLogo = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setFooterFacebookLogo(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteFooterFacebookLogo() {
    handleFormFooterFacebookLogo(footerFacebookLogo);
    setFooterFacebookLogo("");

    setShow8(false);
  }
  const handleFormFooterFacebookLogo = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  /////
  function handleFooterLinkedInLogo(e) {
    const file = e.target.files[0];
    uploadFooterLinkedInLogo(file);
    setPreviewUrl9(URL.createObjectURL(file));
    setShow9(true);
  }
  const uploadFooterLinkedInLogo = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setFooterLinkedInLogo(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteFooterLinkedInLogo() {
    handleFormFooterLinkedInLogo(footerLinkedInLogo);
    setFooterLinkedInLogo("");

    setShow9(false);
  }
  const handleFormFooterLinkedInLogo = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };
  /////
  function handleFooterInstagramLogo(e) {
    const file = e.target.files[0];
    uploadFooterInstagramLogo(file);
    setPreviewUrl10(URL.createObjectURL(file));
    setShow10(true);
  }
  const uploadFooterInstagramLogo = async (img) => {
    const data = new FormData();
    data.append("file", img);
    const result = await addImage(data);
    if (result.code === 200) {
      console.log("Uploaded");
      console.log(result.path);

      setFooterInstagramLogo(result.path);
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  function handleDeleteFooterInstagramLogo() {
    handleFormFooterInstagramLogo(footerInstagramLogo);
    setFooterInstagramLogo("");

    setShow10(false);
  }
  const handleFormFooterInstagramLogo = async (img) => {
    const data = new FormData();
    data.append("path", img);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
    } else {
      console.log(`Error: ${result.status}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
 

    const data = {
      favicon: pathOfUploadedFileFavicon,
      top_ddit_logo: pathOfUploadedFileTopDditLogo,
      top_ddit_link: formInputs.top_ddit_link,
      footer_ddit_logo: pathOfUploadedFileFooterDditLogo,
      footer_ddit_logo_link: formInputs.footer_ddit_logo_link,
      footer_ddit_logo_description: formInputs.footer_ddit_logo_description,
      footer_contact_info_text: formInputs.footer_contact_info_text,
      footer_call_logo: pathOfUploadedFileFooterCallLogo,
      footer_call_number: formInputs.footer_call_number,
      footer_address_logo: pathOfUploadedFileFooterAddressLogo,
      footer_full_address: formInputs.footer_full_address,
      footer_mail_logo: pathOfUploadedFileFooterMailLogo,
      footer_mail_address: formInputs.footer_mail_address,
      footer_facebook_logo: footerFacebookLogo ? footerFacebookLogo : "",
      footer_facebook_link: formInputs.footer_facebook_link,
      footer_linkedIn_logo: footerLinkedInLogo ? footerLinkedInLogo : "",
      footer_linkedIn_link: formInputs.footer_linkedIn_link,
      footer_instagram_logo: footerInstagramLogo ? footerInstagramLogo : "",
      footer_instagram_link: formInputs.footer_instagram_link,
      copyRight_text: formInputs.copyRight_text,
    };
    console.log("data  _____data",data);
    setIsLoading(true);
    const result = await editSettings(formInputs._id, data);
    if (result.code === 200) {
      setIsLoading(false);
      // navigate(-1);
      // navigate("/dashboard");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getSiteSettings = async () => {
    const response = await listSettings();
    if (response.code === 200) {
      setIsLoading(false);
      setFormInputs({
        _id: response.website_setting._id,
        header_phone_icon: response.website_setting.header_phone_icon,
        header_phone_number: response.website_setting.header_phone_number,
        header_mail_icon: response.website_setting.header_mail_icon,
        header_mail_address: response.website_setting.header_mail_address,
        header_navigation_icon: response.website_setting.header_navigation_icon,
        header_navigation_address:
          response.website_setting.header_navigation_address,
        header_facbook_icon: response.website_setting.header_facbook_icon,
        header_facbook_link: response.website_setting.header_facbook_link,
        header_twitter_icon: response.website_setting.header_twitter_icon,
        header_twitter_link: response.website_setting.header_twitter_link,
        header_linkedIn_icon: response.website_setting.header_linkedIn_icon,
        header_linkedIn_link: response.website_setting.header_linkedIn_link,

        top_cist_logo: response.website_setting.top_cist_logo,
        top_cist_link: response.website_setting.top_cist_link,
        top_home_text: response.website_setting.top_home_text,
        top_home_link: response.website_setting.top_home_link,
        top_about_text: response.website_setting.top_about_text,
        top_about_link: response.website_setting.top_about_link,
        top_courses_text: response.website_setting.top_courses_text,
        top_courses_link: response.website_setting.top_courses_link,
        top_contactUs_text: response.website_setting.top_contactUs_text,
        top_contactUs_link: response.website_setting.top_contactUs_link,
        top_apply_course_button:
          response.website_setting.top_apply_course_button,
        footer_cist_logo: response.website_setting.footer_cist_logo,
        footer_cist_logo_link: response.website_setting.footer_cist_logo_link,
        footer_cist_text: response.website_setting.footer_cist_text,
        footer_quick_link: response.website_setting.footer_quick_link,
        footer_courses_text: response.website_setting.footer_courses_text,
        footer_courses_link: response.website_setting.footer_courses_link,
        footer_aboutUs_text: response.website_setting.footer_aboutUs_text,
        footer_aboutUs_link: response.website_setting.footer_aboutUs_link,
        footer_termsConditions_text:
          response.website_setting.footer_termsConditions_text,
        footer_termsConditions_link:
          response.website_setting.footer_termsConditions_link,
        footer_helpCenter_text: response.website_setting.footer_helpCenter_text,
        footer_support_text: response.website_setting.footer_support_text,
        footer_support_link: response.website_setting.footer_support_link,
        footer_getHelp_text: response.website_setting.footer_getHelp_text,
        footer_getHelp_link: response.website_setting.footer_getHelp_link,
        footer_privacyPolicy_text:
          response.website_setting.footer_privacyPolicy_text,
        footer_privacyPolicy_link:
          response.website_setting.footer_privacyPolicy_link,
        footer_contact_info_text:
          response.website_setting.footer_contact_info_text,
        footer_call_text: response.website_setting.footer_call_text,
        footer_call_number: response.website_setting.footer_call_number,
        footer_address_text: response.website_setting.footer_address_text,
        footer_full_address: response.website_setting.footer_full_address,
        footer_mail_text: response.website_setting.footer_mail_text,
        footer_mail_address: response.website_setting.footer_mail_address,
        footer_facebook_logo: response.website_setting.footer_facebook_logo,
        footer_facebook_link: response.website_setting.footer_facebook_link,
        footer_linkedIn_logo: response.website_setting.footer_linkedIn_logo,
        footer_linkedIn_link: response.website_setting.footer_linkedIn_link,
        footer_instagram_logo: response.website_setting.footer_instagram_logo,
        footer_instagram_link: response.website_setting.footer_instagram_link,
        copyRight_text: response.website_setting.copyRight_text,
        copyRight_metalogix_title:
          response.website_setting.copyRight_metalogix_title,
        copyRight_metalogix_title_link:
          response.website_setting.copyRight_metalogix_title_link,

        // New Fields

        footer_ddit_logo_link: response.website_setting.footer_ddit_logo_link,
        top_ddit_link: response.website_setting.top_ddit_link,
        footer_ddit_logo_description:
          response.website_setting.footer_ddit_logo_description,
      });
      // New Fields
      if (response.website_setting.favicon) {
        setPathOfUploadedFileFavicon(response.website_setting.favicon);
      }
      if (response.website_setting.top_ddit_logo) {
        setPathOfUploadedFileTopDditLogo(
          response.website_setting.top_ddit_logo
        );
      }
      if (response.website_setting.footer_ddit_logo) {
        setPathOfUploadedFileFooterDditLogo(
          response.website_setting.footer_ddit_logo
        );
      }
      if (response.website_setting.footer_call_logo) {
        setPathOfUploadedFileFooterCallLogo(
          response.website_setting.footer_call_logo
        );
      }
      if (response.website_setting.footer_address_logo) {
        setPathOfUploadedFileFooterAddressLogo(
          response.website_setting.footer_address_logo
        );
      }
      if (response.website_setting.footer_mail_logo) {
        setPathOfUploadedFileFooterMailLogo(
          response.website_setting.footer_mail_logo
        );
      }

      // Old Fields
      if (response.website_setting.top_cist_logo) {
        setTopCistLogo(response.website_setting.top_cist_logo);
        setShow(true);
      }
      if (response.website_setting.footer_cist_logo) {
        setFooterCistLogo(response.website_setting.footer_cist_logo);
        setShow1(true);
      }
      if (response.website_setting.header_phone_icon) {
        setHeaderPhoneIcon(response.website_setting.header_phone_icon);
        setShow2(true);
      }

      if (response.website_setting.header_mail_icon) {
        setHeaderMailIcon(response.website_setting.header_mail_icon);
        setShow3(true);
      }

      if (response.website_setting.header_navigation_icon) {
        setHeaderNavigationIcon(
          response.website_setting.header_navigation_icon
        );
        setShow4(true);
      }
      if (response.website_setting.header_facbook_icon) {
        setHeaderFacebookIcon(response.website_setting.header_facbook_icon);
        setShow5(true);
      }
      if (response.website_setting.header_twitter_icon) {
        setHeaderTwitterIcon(response.website_setting.header_twitter_icon);
        setShow6(true);
      }
      if (response.website_setting.header_linkedIn_icon) {
        setHeaderLinkedInIcon(response.website_setting.header_linkedIn_icon);
        setShow7(true);
      }
      if (response.website_setting.footer_facebook_logo) {
        setFooterFacebookLogo(response.website_setting.footer_facebook_logo);
        setShow8(true);
      }
      if (response.website_setting.footer_linkedIn_logo) {
        setFooterLinkedInLogo(response.website_setting.footer_linkedIn_logo);
        setShow9(true);
      }
      if (response.website_setting.footer_instagram_logo) {
        setFooterInstagramLogo(response.website_setting.footer_instagram_logo);
        setShow10(true);
      }
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getSiteSettings();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Website Settings">
      <div className="container px-4 mb-5 container_in_all_pages">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <IconButton onClick={handleNav} sx={{ flex: 0 }}>
          <Iconify icon="ep:back" />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={7}
        xl={7}
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <h4 className="title_heading_in_all_pages_top_left"> Website Settings</h4>
      </Grid>
 

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Footer Contact Info Text"
                  value={formInputs.footer_contact_info_text}
                  onChange={handleChange}
                  name="footer_contact_info_text"
                  required
                />
                
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Footer Call Number"
                  value={formInputs.footer_call_number}
                  onChange={handleChange}
                  name="footer_call_number"
                  required
                  // inputProps={{
                  //   pattern: "[+992]+[0-9]{6}",
                  //   title:
                  //     "Please enter a valid phone number in the format +992XXXXXX",
                  // }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
               
                <TextField
                  fullWidth
                  label="Footer Full Address"
                  value={formInputs.footer_full_address}
                  onChange={handleChange}
                  name="footer_full_address"
                  required
                  multiline
                  rows={2}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                 
                <TextField
                  fullWidth
                  label="Footer Mail Address"
                  value={formInputs.footer_mail_address}
                  onChange={handleChange}
                  name="footer_mail_address"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {show8 === false ? (
                  <Stack direction="row" spacing={2}>
                    <label
                      htmlFor="image"
                      className="form-label"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Footer Facebook Logo*
                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                        variant="outlined"
                        size="large"
                        style={{ marginLeft: "15px" }}
                      >
                        Upload
                      </Button>
                    </label>
                    <span className="upload-button mt-2 ms-4">
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleFooterFacebookLogo}
                        style={{ display: "none" }}
                        // required
                      />
                    </span>
                  </Stack>
                ) : null}

                {show8 === true ? (
                  <Stack direction="row" spacing={2}>
                    <label htmlFor="image" className="form-label">
                      Footer Facebook Logo*
                    </label>
                    <Stack
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {" "}
                      <Box
                        position="relative"
                        display="inline-block"
                        border="2px solid #ccc"
                        p={1}
                      >
                        {previewUrl8 ? (
                          <img
                            alt="image"
                            src={previewUrl8}
                            width="100"
                            style={{ maxWidth: "50%" }}
                          />
                        ) : (
                          <img
                            alt="image"
                            src={s3baseUrl + formInputs.footer_facebook_logo}
                            width="100"
                            style={{ maxWidth: "50%" }}
                          />
                        )}
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteFooterFacebookLogo()}
                          style={{ position: "absolute", top: 0, right: 0 }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}

                <TextField
                  fullWidth
                  label="Footer Facebook Link"
                  value={formInputs.footer_facebook_link}
                  onChange={handleChange}
                  name="footer_facebook_link"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {show9 === false ? (
                  <Stack direction="row" spacing={2}>
                    <label
                      htmlFor="image"
                      className="form-label"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Footer LinkedIn Logo*
                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                        variant="outlined"
                        size="large"
                        style={{ marginLeft: "15px" }}
                      >
                        Upload
                      </Button>
                    </label>
                    <span className="upload-button mt-2 ms-4">
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleFooterLinkedInLogo}
                        style={{ display: "none" }}
                        // required
                      />
                    </span>
                  </Stack>
                ) : null}

                {show9 === true ? (
                  <Stack direction="row" spacing={2}>
                    <label htmlFor="image" className="form-label">
                      Footer LinkedIn Logo*
                    </label>
                    <Stack
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {" "}
                      <Box
                        position="relative"
                        display="inline-block"
                        border="2px solid #ccc"
                        p={1}
                      >
                        {previewUrl9 ? (
                          <img
                            alt="image"
                            src={previewUrl9}
                            width="100"
                            style={{ maxWidth: "50%" }}
                          />
                        ) : (
                          <img
                            alt="image"
                            src={s3baseUrl + formInputs.footer_linkedIn_logo}
                            width="100"
                            style={{ maxWidth: "50%" }}
                          />
                        )}
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteFooterLinkedInLogo()}
                          style={{ position: "absolute", top: 0, right: 0 }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}

                <TextField
                  fullWidth
                  label="Footer LinkedIn Link"
                  value={formInputs.footer_linkedIn_link}
                  onChange={handleChange}
                  name="footer_linkedIn_link"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                {show10 === false ? (
                  <Stack direction="row" spacing={2}>
                    <label
                      htmlFor="image"
                      className="form-label"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Footer Instagram Logo*
                      <Button
                        className="small-contained-button"
                        startIcon={<FileUploadIcon />}
                        component="span"
                        variant="outlined"
                        size="large"
                        style={{ marginLeft: "15px" }}
                      >
                        Upload
                      </Button>
                    </label>
                    <span className="upload-button mt-2 ms-4">
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleFooterInstagramLogo}
                        style={{ display: "none" }}
                        // required
                      />
                    </span>
                  </Stack>
                ) : null}

                {show10 === true ? (
                  <Stack direction="row" spacing={2}>
                    <label htmlFor="image" className="form-label">
                      Footer Instagram Logo*
                    </label>
                    <Stack
                      position="relative"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {" "}
                      <Box
                        position="relative"
                        display="inline-block"
                        border="2px solid #ccc"
                        p={1}
                      >
                        {previewUrl10 ? (
                          <img
                            alt="image"
                            src={previewUrl10}
                            width="100"
                            style={{ maxWidth: "50%" }}
                          />
                        ) : (
                          <img
                            alt="image"
                            src={s3baseUrl + formInputs.footer_instagram_logo}
                            width="100"
                            style={{ maxWidth: "50%" }}
                          />
                        )}
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteFooterInstagramLogo()}
                          style={{ position: "absolute", top: 0, right: 0 }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}

                <TextField
                  fullWidth
                  label="Footer Instagram Link"
                  value={formInputs.footer_instagram_link}
                  onChange={handleChange}
                  name="footer_instagram_link"
                  required
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="CopyRight Text"
                  value={formInputs.copyRight_text}
                  onChange={handleChange}
                  name="copyRight_text"
                  required
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Top DDIT Logo Link"
                  value={formInputs.top_ddit_link}
                  onChange={handleChange}
                  name="top_ddit_link"
                  required
                />

                <TextField
                  fullWidth
                  label="Footer DDIT Logo Link"
                  value={formInputs.footer_ddit_logo_link}
                  onChange={handleChange}
                  name="footer_ddit_logo_link"
                  required
                />
              </Stack>

              <TextField
                fullWidth
                label="Footer DDIT Logo Description"
                value={formInputs.footer_ddit_logo_description}
                onChange={handleChange}
                name="footer_ddit_logo_description"
                required
              />

              <div className="row">
                <div className="col-12 col-sm-6 mb-1">
                  <CustomImageUploaderInWebSetting
                    pathOfUploadedFile={pathOfUploadedFileFavicon}
                    setPathOfUploadedFile={setPathOfUploadedFileFavicon}
                    imageFileHeading={`Favicon *`}
                    // imageFileSubHeading={"Image Size: 1MB or below :"}
                    imageFileLimitSizeUpload={2}
                    imageFileButtonText={`Upload`}
                    imageID={1}
                  />
                </div>
                <div className="col-12 col-sm-6 mb-1">
                  <CustomImageUploaderInWebSetting
                    pathOfUploadedFile={pathOfUploadedFileTopDditLogo}
                    setPathOfUploadedFile={setPathOfUploadedFileTopDditLogo}
                    imageFileHeading={`Top Ddit Logo *`}
                    // imageFileSubHeading={"Image Size: 1MB or below :"}
                    imageFileLimitSizeUpload={2}
                    imageFileButtonText={`Upload`}
                    imageID={2}
                  />
                </div>

                <div className="col-12 col-sm-6 mb-1">
                  <CustomImageUploaderInWebSetting
                    pathOfUploadedFile={pathOfUploadedFileFooterDditLogo}
                    setPathOfUploadedFile={setPathOfUploadedFileFooterDditLogo}
                    imageFileHeading={`Footer Ddit Logo *`}
                    // imageFileSubHeading={"Image Size: 1MB or below :"}
                    imageFileLimitSizeUpload={2}
                    imageFileButtonText={`Upload`}
                    imageID={3}
                  />
                </div>

                <div className="col-12 col-sm-6 mb-1">
                  <CustomImageUploaderInWebSetting
                    pathOfUploadedFile={pathOfUploadedFileFooterCallLogo}
                    setPathOfUploadedFile={setPathOfUploadedFileFooterCallLogo}
                    imageFileHeading={`Footer Call Logo *`}
                    // imageFileSubHeading={"Image Size: 1MB or below :"}
                    imageFileLimitSizeUpload={2}
                    imageFileButtonText={`Upload`}
                    imageID={4}
                  />
                </div>

                <div className="col-12 col-sm-6 mb-1">
                  <CustomImageUploaderInWebSetting
                    pathOfUploadedFile={pathOfUploadedFileFooterAddressLogo}
                    setPathOfUploadedFile={
                      setPathOfUploadedFileFooterAddressLogo
                    }
                    imageFileHeading={`Footer Address Logo *`}
                    // imageFileSubHeading={"Image Size: 1MB or below :"}
                    imageFileLimitSizeUpload={2}
                    imageFileButtonText={`Upload`}
                    imageID={5}
                  />
                </div>

                <div className="col-12 col-sm-6 mb-1">
                  <CustomImageUploaderInWebSetting
                    pathOfUploadedFile={pathOfUploadedFileFooterMailLogo}
                    setPathOfUploadedFile={setPathOfUploadedFileFooterMailLogo}
                    imageFileHeading={`Footer Mail Logo *`}
                    // imageFileSubHeading={"Image Size: 1MB or below :"}
                    imageFileLimitSizeUpload={2}
                    imageFileButtonText={`Upload`}
                    imageID={6}
                  />
                </div>
              </div>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Settings;
