import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
  IconButton,
  Grid,
  Zoom,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import {
  _list_trash_students,
  listStudent,
  listStudentSearch,
} from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndtextEmptyToReturnHyphen,
  textEmptyToReturnHyphen,
  truncateString,
} from "src/utils/formatText";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { useAppContext } from "src/hooks";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import TrashStudentDetail from "./TrashStudentDetail";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  // { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "father_name", label: "Father Name", alignRight: false },
  { id: "trash_reason", label: "Trash Reason", alignRight: false },
  { id: "contact_number", label: "Phone", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  // { id: "last_name", label: "Last Name", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },

  // { id: "batch", label: "Batch", alignRight: false },
  // { id: "courses", label: "Courses", alignRight: false },
  // { id: "cnic", label: "CNIC", alignRight: false },
  { id: "gender", label: "Gender", alignRight: false },

  // {
  //   id: "registration_fee",
  //   label: "Reg Fee",
  //   alignRight: false,
  // },
  // { id: "decided_fee", label: "Course Fee", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Students() {
  const myStyle = {
    textTransform: "capitalize",
  };

  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);

  const [trashStudentCount, setTashStudentCount] = useState(0);
  const [totalPendingFee, setTotalPendingFee] = useState(0);
  const [totalRefundedFee, setTotalRefundedFee] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);
  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();
  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    // navigate("/students/add-student");
    navigate(-1);
  };
  const handleAddFromEnquiry = () => {
    navigate("/students/add-FromEnquiry");
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _list_trash_students();
    console.log("response _list_trash_students", response);
    if (response.code === 200) {
      setTashStudentCount(response.trash_student_count);
      setTotalPendingFee(response.total_pending_fee);
      setTotalRefundedFee(response.total_refunded_fee);

      const students = response.students.map((student, index) => ({
        ...student,
        trash_reason: student.trash_reason ? student.trash_reason : "",
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: student.father_name,
        address: student.address,
        reg_type: student.reg_type ? student.reg_type : "",
        contact_number: student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.total_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        number: index + 1,
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    const response = await listStudentSearch(searchText);
    if (response.code === 200) {
      const students = response.students.map((student, index) => ({
        ...student,
        trash_reason: student.trash_reason ? student.trash_reason : "",
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: student.father_name,
        address: student.address,
        reg_type: student.reg_type ? student.reg_type : "",
        contact_number: student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.total_fee,
        cnic: student.cnic,
        registration_fee: student.registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        number: index + 1,
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf", typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Trash">
      <Container maxWidth="xl">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            marginLeft:"-10px"
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Trash </h4>
        </Grid>

        {/* <div
          className="d-flex"
          style={{
            flexDirection: "row",
            alignContent: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

        
          <Typography variant="h4">Trash</Typography>

          <div>
            
          </div>
         
        </div> */}

        {/* </Stack> */}

        <Card
          sx={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            mt: 3,
            mb: 6,
          }}
        >
          {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={students.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        name,
                        // last_name,
                        email,
                        // image,
                        status,
                        // batch,
                        courses,
                        gender,
                        decided_fee,
                        registration_fee,
                        cnic,
                        number,
                        referred_by,
                        course_fee,
                        actual_fee,
                        father_name,
                        address,
                        reg_type,
                        contact_number,
                        trash_reason,
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      // let number = index + 1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox"> */}
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                          {/* </TableCell> */}

                          <TableCell align="left" style={myStyle}>
                            {/* {number} */}
                            {index + 1}
                          </TableCell>

                          {/* <TableCell align="left">
                            {capitalizeFirstLetter(name)}
                          </TableCell> */}

                          <TableCell align="left" style={myStyle}>
                            <div
                              className="on_click_text"
                              onClick={() => handleOpenModal(row)}
                            >
                              {capitalizeFirstLetter(name)}
                            </div>
                          </TableCell>

                          <TableCell align="left">
                            {textEmptyToReturnHyphen(father_name)}
                          </TableCell>

                          {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}
                          {/* <TableCell align="left" style={myStyle}>
                            {last_name}
                          </TableCell> */}
                          {/* <TableCell align="left">{email}</TableCell> */}
                          {/* <TableCell align="left">
                            {" "}
                            <Avatar alt={first_name} src={s3baseUrl + image} />
                          </TableCell> */}

                          {/* <TableCell align="left" style={myStyle}>
                            {batch.length > 0
                              ? batch
                                  .map((batch, index) => {
                                    return batch !== null ? batch.name : "N/A";
                                  })
                                  .join(", ")
                              : "N/A"}
                          </TableCell> */}

                          <Tooltip
                            TransitionComponent={Zoom}
                            title={
                              trash_reason.length > 20
                                ? capitalizeFirstLetter(trash_reason)
                                : ""
                            }
                            arrow
                          >
                            <TableCell
                              align="left"
                              className="cell_onclick_effect"
                            >
                              {capitalizeFirstLetterAndtextEmptyToReturnHyphen(
                                truncateString(trash_reason, 20)
                              )}
                            </TableCell>
                          </Tooltip>

                          {/* <TableCell align="left">
                                {textEmptyToReturnHyphen(trash_reason)}
                              </TableCell> */}

                          <TableCell align="left" style={myStyle}>
                            +92{contact_number}
                          </TableCell>

                          <TableCell align="left">
                            {textEmptyToReturnHyphen(email)}
                          </TableCell>

                          {/* <TableCell align="left" style={myStyle}>
                            {cnic}
                          </TableCell> */}

                          <TableCell align="left" style={myStyle}>
                            {gender === 1 ? "Male" : "Female"}
                          </TableCell>

                          {/* <TableCell align="left" style={myStyle}>
                            {registration_fee}
                          </TableCell> */}

                          {/* <TableCell align="left" style={myStyle}>
                            {decided_fee}
                          </TableCell> */}

                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === 3 && "error") || "success"}
                            >
                              {status === 3 ? "Trash" : "Active"}
                              {/* {/ {sentenceCase(status)} /} */}
                            </Label>
                          </TableCell>

                          {privilegesTypeCheckAndShowOrNot(
                            get_user_profile,
                            get_user_privilege_access,
                            "student",
                            "edit"
                          ) ||
                          privilegesTypeCheckAndShowOrNot(
                            get_user_profile,
                            get_user_privilege_access,
                            "student",
                            "delete"
                          ) ? (
                            <TableCell align="right">
                              <StudentMoreMenu
                                row={row}
                                students={students}
                                onStudentsUpdate={handleStudentsUpdate}
                              />
                            </TableCell>
                          ) : (
                            <TableCell align="right"></TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={10} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[
              50,
              100,
              150,
              { value: students.length, label: "All" },
            ]}
            component="div"
            count={students.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />
      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Trash Student Detail"
        componentToPassDown={
          <div className="mt-5">
            <TrashStudentDetail selectedRowStudent={selectedRowStudent} />
          </div>
        }
      />
    </Page>
  );
}
