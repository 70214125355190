import { invokeApi } from "src/utils";
export const listTestimonial = async (id) => {
  const requestObj = {
    path: `api/testimonial/page_testimonial/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const delTestimonial = async (id) => {
  const requestObj = {
    path: `api/testimonial/delete_testimonial/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};
export const addTestimonial = async (data) => {
  const formDataArray = Array.from(data.entries());

  console.log(formDataArray);
  const requestObj = {
    path: `api/testimonial/add_testimonial`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailTestimonial = async (id) => {
  const requestObj = {
    path: `api/testimonial/detail_testimonial/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editTestimonial = async (id, data) => {
  const requestObj = {
    path: `api/testimonial/edit_testimonial/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
