import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
  Grid,
  IconButton,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent, listStudentSearch } from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import { _detail_attendance, _list_attendance, _student_attendance } from "src/DAL/attendance";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import AttendanceSingleModalDetail from "./AttendanceSingleModalDetail";
import { isValidDate } from "src/utils/formatTime";
import DataNotFoundAll from "src/components/GeneralComponents/DataNotFoundAll";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  // { id: "name", label: "Student", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "check_in", label: "Check In (min)", alignRight: false },
  { id: "check_out", label: "Check Out (min)", alignRight: false },
  { id: "absent", label: "Status", alignRight: false },

  // { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function StudentAttendanceAllBatches() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const moment = require("moment");
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [studentName, setStudentName] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  let date = "";

  if (urlParams.get("date") != null) {
    date = urlParams.get("date");
  } else {
    // navigate("/batches/");
  }

  const handleNav = () => {
    if (isValidDate(date) !== null) {
    //   navigate(`/attendance?date=${date}`);
      navigate(-1);
    } else {
    //   navigate(`/attendance`);
      navigate(-1);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");
  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  const [trashStudentCount, setTrashStudentCount] = useState(0);
  const { id } = useParams();

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _student_attendance({
        "student_id": id
    });
    console.log("response _student_attendance", response);
    if (response.code === 200) {
    
if(response.attendance.length > 0){
    setStudentName(response.attendance[0].student.name);
}
  
    //   setStudentName(groupedData[0].student.name);

    //   const updatedAttendance = groupedData[0].attendance.map(
    //     (attendanceItem) => ({
    //       ...attendanceItem,
    //       student: groupedData[0].student,
    //       name: groupedData[0].student.name,
    //       createdAt: groupedData[0].createdAt,
    //       updatedAt: groupedData[0].updatedAt,
    //     })
    //   );

      // setStudentName(response.attendance.student.name);

      // const updatedAttendance = response.attendance.attendance.map(attendanceItem => ({
      //   ...attendanceItem,
      //   student: response.attendance.student,
      //   name:response.attendance.student.name,
      //   createdAt:response.attendance.createdAt,
      //   updatedAt:response.attendance.updatedAt,
      // }));

    //   console.log(updatedAttendance);

    //   setStudents([]);
      setStudents(response.attendance);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    // const response = await listStudentSearch(searchText);
    const response = await _detail_attendance(id);
    if (response.code === 200) {
    //   setStudentName(response.attendance.student.name);

    //   const updatedAttendance = response.attendance.attendance.map(
    //     (attendanceItem) => ({
    //       ...attendanceItem,
    //       student: response.attendance.student,
    //       name: response.attendance.student.name,
    //       createdAt: response.attendance.createdAt,
    //       updatedAt: response.attendance.updatedAt,
    //     })
    //   );

    //   console.log(updatedAttendance);

      setStudents(response.attendance);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  function formatTime(Time) {
    // Parse the time strings using moment
    const startMoment = moment(Time, "HH:mm");

    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");

    const formattedTime = `${formattedStartTime}`;

    return formattedTime;
  }
  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf", typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };
  function formatDate(inputDate) {
    // Parse the input date string
    const parsedDate = moment(inputDate);

    // Format the date as "Tue, Sep 05, 2023"
    const formattedDate = parsedDate.format("ddd, MMM DD, YYYY");

    return formattedDate;
  }
  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Attendance">
      <Container maxWidth="xl">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left">
            {" "}
            {capitalizeFirstLetter(studentName)} (Attendance){" "}
          </h4>
        </Grid>

      
        
       { students.length > 0 ? 
             students.map((Student, Index) => {
                      const { attendance, batch, student } = Student;
     

                      return (
                        <Card
                        sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 5 }}
                        key={Index}
                      >
                        {/* <StudentListToolbar
                          numSelected={selected.length}
                          filterName={filterName}
                          onFilterName={handleFilterByName}
                        /> */}
              
                        <Scrollbar>

                        <h5 className="p-3" >{capitalizeFirstLetter(batch.name)}</h5>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={students.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {
                //   filteredStudents
                //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                attendance.map((row, index) => {
                      const { check_in, check_out, batch, date, is_attendance_updated } = row;
                    //   const isItemSelected = selected.indexOf(name) !== -1;
                      // let number = index + 1;

                      return (
                        <TableRow
                          // hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                        //   selected={isItemSelected}
                        //   aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {index + 1}
                          </TableCell>
                          {/* <TableCell align="left" style={myStyle}>
                            {name}
                          </TableCell> */}

                          <TableCell align="left"  style={myStyle}>
                            {/* <div
                              className="on_click_text"
                              // style={{
                              //   cursor: "pointer",
                              // }}
                              onClick={() => handleOpenModal(row)}
                            > */}
                              {formatDate(date)}
                            {/* </div> */}
                          </TableCell>

                          {/* <TableCell align="left" style={myStyle}>
                            {formatDate(date)} 
                          </TableCell> */}

                          <TableCell align="left" style={myStyle}>
                            {check_in === "" ? " _ _ " : formatTime(check_in)}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {check_out === "" ? " _ _ " : formatTime(check_out)}
                          </TableCell>
                          {/* <TableCell align="left" style={myStyle}>
                            {absent}
                          </TableCell> */}

                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(batch.absent === true && "error") || "success"}
                            >
                              {batch.absent ? "Absent" : "Present"}
      
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            {/* <StudentMoreMenu
                              row={row}
                              students={students}
                              onStudentsUpdate={handleStudentsUpdate}
                              // trashStudentCount={trashStudentCount}
                              // setTrashStudentCount={setTrashStudentCount}
                            /> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
              </Scrollbar>
              </Card>
                );
            })
        
        :( 
        <Card
            sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 5 }}
          >
 <div className="mt-5 mb-5">
                  <DataNotFoundAll />
                </div>
          </Card>
          )}
    

          {/* <TablePagination
            rowsPerPageOptions={[
              50,
              100,
              150,
              { value: students.length, label: "All" },
            ]}
            component="div"
            count={students.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
      
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Student Attendance Detail"
        componentToPassDown={
          // <>
          //   <h1>hello</h1>
          //   <h1>hello</h1>
          //   <h1>hello</h1>
          //   <h1>hello</h1>
          // </>
          <AttendanceSingleModalDetail
            selectedRowStudent={selectedRowStudent}
          />
        }
      />
    </Page>
  );
}
