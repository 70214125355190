import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  LinearProgress,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import {
  _move_batch_students,
  _move_student_from_batch,
  _update_batch_status,
  addBatch,
  listBatch,
} from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import {
  _detail_enquiry_student,
  _update_enquiry_status,
} from "src/DAL/enquirystudent";
import { capitalizeFirstLetter } from "src/utils/formatText";

const EnquiryUpdateStatus = ({
  row,
  Students,
  onStudentsUpdate,
  handleCloseModal,
}) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchTitle, setBatchTitle] = useState("");
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "true",
    batch: "",
    courses: [],
    batch_status: 1,
    enquiry_status: 5,
    enquiry_status_reason: "",
  });

  const { id } = useParams();

  let _ID = id;

  console.log("_ID _ID _ID", _ID);

  const urlParams = new URLSearchParams(window.location.search);

  let batch_id = "batch_id";
  let student_id = _ID;

  if (urlParams.get("batch_id") != null) {
    batch_id = urlParams.get("batch_id");
  } else {
    // navigate("/batches/");
  }
  console.log("batch_id", batch_id);
  console.log("student_id", student_id);

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      //   courses: [value],
    }));

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    setIsLoading(true);
    const result = await _update_enquiry_status(row.id, {
      enquiry_status: formInputs.enquiry_status,
      enquiry_status_reason: formInputs.enquiry_status_reason,
    });

    if (result.code === 200) {
      setIsLoading(false);
      navigate("/enquiry-students");
      //   navigate(-1);
      handleCloseModal();

      const updatedStudents = Students.map((studentRow) => {
        if (studentRow.id === row.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return { ...studentRow, 
            enquiry_status: formInputs.enquiry_status,
            enquiry_status_reason: formInputs.enquiry_status_reason,
        };
        } else {
          // For other objects, return them as they are
          return studentRow;
        }
      });

      onStudentsUpdate(updatedStudents);
 
      enqueueSnackbar("Update Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //   const fetchActiveCourses = async () => {
  //     const response = await listActiveCourse();

  //     if (response.code === 200) {
  //       const courses = response.courses.map((course) => ({
  //         id: course._id,
  //         title: course.title,
  //         description: course.description,
  //         duration: course.duration,
  //         image: course.image,
  //         status: course.status,
  //       }));
  //       setCourses(courses);
  //     } else {
  //       enqueueSnackbar(response.message, { variant: "error" });
  //     }
  //   };

  //   useEffect(() => {
  //     fetchActiveCourses();
  //   }, []);

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await listBatch();
    console.log("response __listBatch", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if batch._id is equal to the _ID you want to skip
          if (batch._id === row.id) {
            setBatchTitle(batch.name);
            return null; // Skip this iteration and move to the next element
          }

          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  async function fetchStudentDetail() {
    setIsLoading(true);
    const response = await _detail_enquiry_student(row.id);
    console.log("_detail_enquiry_student _detail_enquiry_student", response);
    // enquiry_form

    if (response.code === 200) {
      setBatchTitle(capitalizeFirstLetter(response.enquiry_form.name));

    //   name: "",
    //   total_seats: "",
    //   status: "true",
    //   batch: "",
    //   courses: [],
    //   batch_status: 1,
    //   enquiry_status: 5,
    //   enquiry_status_reason: "",

      setFormInputs({
        ...formInputs,
        enquiry_status:response.enquiry_form.enquiry_status,
        enquiry_status_reason:response.enquiry_form.enquiry_status_reason,
      });

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // fetchBatches();
    fetchStudentDetail();
  }, []);


  // if (isLoading) {
  //   return <CircularLoader />;
  // }

  if (isLoading) {
    return (
      <div className="mt-5">
       <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
      </div>
    );
  }

  return (
    <Page title="Move Batch Students">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        wrap="wrap"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Update Enquiry Status
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3} sx={{ padding: "5px" }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  disabled
                  fullWidth
                  label="Student Name"
                  value={batchTitle}
                  onChange={handleChange}
                  name="name"

                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>

              <TextField
                fullWidth
                label="Enquiry Status"
                select
                value={formInputs.enquiry_status}
                onChange={handleChange}
                name="enquiry_status"
                required
              >
                <MenuItem value={1}>Do Not Distrub</MenuItem>
                <MenuItem value={2}>Wrong Number </MenuItem>
                <MenuItem value={3}>Number Close</MenuItem>
                <MenuItem value={4}>Expire</MenuItem>
                <MenuItem value={5}>Active</MenuItem>
              </TextField>

              <TextField
                fullWidth
                label="Enquiry Status Reason"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.enquiry_status_reason}
                onChange={handleChange}
                name="enquiry_status_reason"

                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />

              {/* <Stack alignItems="left">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "warning.main" }}
                >
                  Please note that if you modify the student status, the fee
                  cannot be modified.
                </Typography>
              </Stack> */}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default EnquiryUpdateStatus;
