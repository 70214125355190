import { filter } from "lodash";

import { useState, useEffect, useRef } from "react";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { listStudent, listStudentSearch } from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import { _list_course_students } from "src/DAL/course_student";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "last_name", label: "Last Name", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },

  // { id: "batch", label: "Batch", alignRight: false },
  { id: "course", label: "Course", alignRight: false },
  // { id: "courses", label: "Courses", alignRight: false },
  { id: "contact_number", label: "Phone", alignRight: false },
  { id: "cnic", label: "CNIC", alignRight: false },
  { id: "gender", label: "Gender", alignRight: false },

  {
    id: "registration_fee",
    label: "Reg Fee",
    alignRight: false,
  },
  { id: "decided_fee", label: "Course Fee", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Students() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(" ");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);

  const [trashStudentCount, setTrashStudentCount] = useState(0);

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(students.length);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - students.length) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredStudents.length === 0;

  const handleNav = () => {
    navigate("/students/add-student");
  };
  const handleAddFromEnquiry = () => {
    navigate("/students/add-FromEnquiry");
  };
  const handleMoveToTrash = () => {
    navigate("/trash/");
  };

  const fetchStudents = async () => {
    setIsLoading(true);
    const response = await _list_course_students({
      "text":""
  });
    console.log('response _list_course_students',response);
    if (response.code === 200) {

      // setTrashStudentCount(response.trash_student_count);

      const students = response.students.map((student, index) => ({
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: student.father_name,
        address: student.address,
        reg_type: student.reg_type,
        contact_number:  student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        course: student.course ? student.course.title : "",
        // courses: student.courses ? student.courses : "",
        number: index + 1,
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
 
  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    const response = await _list_course_students({
    "text":searchText
});
    if (response.code === 200) {
      const students = response.students.map((student, index) => ({
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: student.father_name,
        address: student.address,
        reg_type: student.reg_type,
        contact_number:  student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        cnic: student.cnic,
        registration_fee: student.registration_fee,
        batch: student.batch ? student.batch : "",
 
        course: student.course ? student.course.title : "",
        // courses: student.courses ? student.courses : "",
        number: index + 1,
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf",typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  useEffect(() => {
    fetchStudents();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Student">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
          mt={2}
        >
          <Typography variant="h4">Course Students</Typography>
          <div>
          {/* <Button
              onClick={handleMoveToTrash}
              variant="contained"
              color="error" // Set the color to "secondary" for red color
              startIcon={<Iconify icon="bi:trash" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              ( {trashStudentCount} ) Trash
            </Button> */}

            {/* <Button
              onClick={handleAddFromEnquiry}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              Add From Equiry
            </Button> */}
            {/* <Button
              onClick={handleNav}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Student
            </Button> */}
          </div>
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
        >
          {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

          <Scrollbar>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="flex-end"
              sx={{ padding: "10px" }}
            >
              <TextField
                label="Search"
                value={searchText}
                onChange={handleInputChange}
              />
              {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
              <Button variant="contained" onClick={handleReset}>
                Reset
              </Button>

              {/* <Button
                onClick={handleOpenDrawerFilter}
                sx={{
                  spacing: 4,
                  borderRadius: "10px",
                  width: "100%",
                  p: 1.65,
                  mt: 1.2,
                  backgroundColor: "#f6f7f9",
                  display: "flex",
                  justifyContent: "center",
                  alignIitems: "center",
                  color: "black",
                }}
              >
                <span className="text-capitalize">Filters</span>
                <span className=" px-2">
                  {getIcon("material-symbols:filter-list")}
                </span>
              </Button> */}

              {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
            </Stack>

            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={students.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        name,
                        // last_name,
                        email,
                        // image,
                        status,
                        batch,
                        // courses,
                        course,
                        gender,
                        decided_fee,
                        registration_fee,
                        cnic,
                        number,
                        referred_by,
                        course_fee,
                        actual_fee,
                        father_name,
                        address,
                        reg_type,
                        contact_number,
                         
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      // let number = index + 1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, first_name)
                              }
                            /> */}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {number}
                          </TableCell>

                          {/* <TableCell align="left" style={myStyle}>
                            {name}
                          </TableCell> */}

                          <Tooltip
                            title={
                              <div>
                                {/* Batch =  {batch.name}  <br /> */}
                                Father Name = {father_name} <br />
                                Referred By = {referred_by} <br />
                                Email = {email} <br />
                                Address = {address} <br />
                                Registration Type = {reg_type} <br />
                                Actual Registration Fee = {course_fee} <br />
                                Actual Course Fee= {actual_fee} <br />
                              </div>
                            }
                            arrow
                          >
                            <TableCell align="left" style={myStyle}>
  {reg_type.toLowerCase() === "scholarship" ? (
    <Badge
      badgeContent="Scholarship"
      color="success"
      style={{
        margin: "15px",
        padding: "10px",
        position: "relative",
        top: "0px",
        right: "25px",
      }}
      classes={{
        badge: "custom-badge",
      }}
    >
      {name} 
    </Badge>
  ) : (
    <span>{name}</span>
  )}
</TableCell>

                          </Tooltip>

                          {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}
                          {/* <TableCell align="left" style={myStyle}>
                            {last_name}
                          </TableCell> */}
                          {/* <TableCell align="left">{email}</TableCell> */}
                          {/* <TableCell align="left">
                            {" "}
                            <Avatar alt={first_name} src={s3baseUrl + image} />
                          </TableCell> */}

                          {/* <TableCell align="left" style={myStyle}>
                            {batch.length > 0
                              ? batch
                                  .map((batch, index) => {
                                    return batch !== null ? batch.name : "N/A";
                                  })
                                  .join(", ")
                              : "N/A"}
                          </TableCell> */}
                          

                          <TableCell align="left" style={myStyle}>
                            {course}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {contact_number}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {cnic}
                          </TableCell>
                          <TableCell align="left" style={myStyle}>
                            {gender}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {registration_fee}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {decided_fee}
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={(status === false && "error") || "success"}
                            >
                              {status ? "Active" : "Inactive"}
                              {/* {/ {sentenceCase(status)} /} */}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <StudentMoreMenu
                              row={row}
                              students={students}
                              onStudentsUpdate={handleStudentsUpdate}
                              trashStudentCount={trashStudentCount}
                              setTrashStudentCount={setTrashStudentCount}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[
              50,
              100,
              150,
              { value: students.length, label: "All" },
            ]}
            component="div"
            count={students.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />
    </Page>
  );
}
