import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  Button,
  InputAdornment,
  Chip,
  Divider,
  Box,
  FormHelperText,
  Icon,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addBatch } from "src/DAL/batch";

import { _course_instructors, listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "src/utils/formatText";
import { _list_active_vendor, _list_vendor } from "src/DAL/vendor";
import { listStudent } from "src/DAL/student";
import {
  _list_active_expense_category,
  _list_expense_category,
} from "src/DAL/expense_category";
import { _add_expense } from "src/DAL/expense";
import { useTheme } from "@mui/material/styles";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { addImage } from "src/DAL/uploadfiles";
import { delImage } from "src/DAL/delfiles";
import { s3baseUrl } from "src/config/config";
const Input = styled("input")({
  display: "none",
});

// ----------------------------------------------------------------------

export default function CustomImageUploaderOnlyWithPath({
  pathOfImageFile,
  imageFileHeading,
  imageFileSubHeading,
  imageFileLimitSizeUpload,
  imageFileButtonText,
  imageID,
  setAdditionalFields,
}) {
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [previews, setPreviews] = useState();
  const [acceptableFile, setAcceptableFile] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");
  const [pathOfUploadedFile, setPathOfUploadedFile] = useState(pathOfImageFile);
 
  const [isLoading, setIsLoading] = useState(false);

  const fileChangedHandler = (event) => {
    const selectedFile = event.target.files[0];
    console.log("selectedFile  ___selectedFile", selectedFile);

    if (selectedFile) {
      if (selectedFile.size <=  imageFileLimitSizeUpload * 1024 * 1024) {
        // If the file is 1MB or smaller
        setImage(selectedFile);
        setPreviews(URL.createObjectURL(selectedFile));
        setPreviewUrl(URL.createObjectURL(selectedFile));
        setShow(true);
        setAcceptableFile("true");
        setMessage("Acceptable File");
        setColor("success");

        uploadFileFromUser(selectedFile);
      } else {
        // If the file is larger than 5MB
        setAcceptableFile("false");
        setMessage("Error: The file size exceeds the maximum allowed limit.");
        setColor("danger");
        setPathOfUploadedFile("");

        setAdditionalFields((prevFields) => {
            const updatedFields = [...prevFields];
            updatedFields[imageID]["profile_image"] = "";
            return updatedFields;
          });

      }
    }
  };

  const uploadFileFromUser = async (selected_file) => {
    const data = new FormData();
    data.append("file", selected_file);

    setIsLoading(true);
    const response = await addImage(data);

    console.log("response addImage ___addImage test", response);
    if (response.code === 200) {
      setIsLoading(false);
      setPathOfUploadedFile(response.path);

      setAdditionalFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[imageID]["profile_image"] = response.path;
        return updatedFields;
      });

    } else {
      setPathOfUploadedFile("");
      setAdditionalFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[imageID]["profile_image"] = "";
        return updatedFields;
      });
      setIsLoading(false);
      console.log(`Error: ${response.status}`);
    }
  };

  const imageDelete = async (img) => {
    // console.log("hello");
    const data = new FormData();
    data.append("path", img);
    setIsLoading(true);
    const result = await delImage(data);
    if (result.code === 200) {
      console.log("Deleted");
      setImage("");
      setPreviewUrl("");
      setPreviews("");
      setShow(false);
      setAcceptableFile("");
      setPathOfUploadedFile("");
      setAdditionalFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[imageID]["profile_image"] = "";
        return updatedFields;
      });
      setIsLoading(false);
    } else {
      // setIsLoadingButton(false);
      console.log(`Error: ${result.status}`);
      setImage("");
      setPreviewUrl("");
      setPreviews("");
      setShow(false);
      setAcceptableFile("");
      setPathOfUploadedFile("");
      setAdditionalFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[imageID]["profile_image"] = "";
        return updatedFields;
      });
      setIsLoading(false);
    }
  };

  function handleDeleteImage() {
    imageDelete(pathOfUploadedFile);
  }

  useEffect(() => {
    if (
      pathOfUploadedFile &&
      pathOfUploadedFile !== null &&
      pathOfUploadedFile !== ""
    ) {
      setAcceptableFile("true");
      setShow(true);
      setPreviewUrl(s3baseUrl + pathOfUploadedFile);
    }
    console.log(
      "pathOfUploadedFile     _____pathOfUploadedFile",
      pathOfUploadedFile
    );
  }, [pathOfUploadedFile]);

  return (
    <>
      <div className="row">
        <div className="col-5">
          <p className="assignment_sub_heading_in_add_edit">
            {imageFileHeading}
          </p>
          {/* <FormHelperText className="pt-0">
                   File Size(5 mb ) ("zip", "rar")
                  </FormHelperText> */}

          <FormHelperText className="pt-0">
            {imageFileSubHeading}
          </FormHelperText>
        </div>

        <div className={`col-4 mt-0`}>
          {/* <img src={previews} height={50} /> */}
          {acceptableFile === "true" ? (
            <>
              {/* <Icon
                          icon={
                            "streamline:interface-validation-check-circle-checkmark-addition-circle-success-check-validation-add-form"
                          }
                          width={40}
                          height={40}
                          style={{ color: "green" }} // Set the text color to green
                        />
                        <span style={{ color: "green", paddingLeft: "5px" }}>
                          {message}
                        </span> */}

              {show === true ? (
                <Stack
                  position="relative"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  {/* <label htmlFor="image" className="form-label">
                    Image:
                  </label> */}
                  <Box
                    position="relative"
                    display="inline-block"
                    border="2px solid #ccc"
                    p={1}
                  >
                    <img
                      alt="image"
                      src={previewUrl}
                      width="150"
                      style={{ maxWidth: "100%" }}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteImage()}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Stack>
              ) : null}
            </>
          ) : acceptableFile === "false" ? (
            <>
              <Icon
                icon={"system-uicons:cross-circle"}
                width={40}
                height={40}
                style={{ color: "red" }} // Set the text color to red
              />
              <span style={{ color: "red", paddingLeft: "5px" }}>
                {message}
              </span>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="col-3 text-end pt-2">
          <label htmlFor={`contained-button-file${imageID}`}>
            <Input
              accept="image/*"
              // accept=".rar,.zip,.pdf,.doc,.docx"
              //   accept=".csv"
              id={`contained-button-file${imageID}`}
              multiple
              type="file"
              name="image"
              onChange={fileChangedHandler}
            />

{/* <Button
                  // size="medium"
                  // type="submit"
                  variant="outlined"
                  loading={isLoading}
                  color={
                    acceptableFile === "true"
                      ? "success"
                      : acceptableFile === "false"
                      ? "error"
                      : "info"
                  } 
                  startIcon={
                    isLoading ? <CircularProgress size={20} /> : <FileUploadIcon />
                  }
                  disabled={isLoading} // Disable the button when loading
                  // sx={{ width: "120px" }}
                >
                {isLoading ? "Loading..." : imageFileButtonText }
                </Button> */}

            <Button
              id={`contained-button-file${imageID}`}
              className="small-contained-button"
              // variant="contained"
              color={
                acceptableFile === "true"
                  ? "success"
                  : acceptableFile === "false"
                  ? "error"
                  : "info"
              } 
              startIcon={
                isLoading ? <CircularProgress size={20} /> : <FileUploadIcon />
              }
              component="span"
              disabled={isLoading} // Disable the button when loading
            >
              {isLoading ? "Loading..." : imageFileButtonText }
            </Button>
          </label>
        </div>
      </div>
    </>
  );
}
