import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  InputLabel,
  Select,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import {
  _add_student_in_course,
  _move_to_trash,
  addStudent,
} from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import { listCourse } from "src/DAL/course";
import { _active_time_preference } from "src/DAL/timepreference";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/formatText";
import CustomPasswordField from "src/components/GeneralComponents/CustomPasswordField";
const MoveToTrashPage = ({ 
    studentRow, 
    onStudentsUpdate, 
    students, 
    setTrashStudentCount, 
    trashStudentCount, 
    handleCloseModal
}) => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  const [additionalFields, setAdditionalFields] = useState([]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const [timePreferences, setTimePreferences] = useState([]);

  const [additionalFieldsCourse, setAdditionalFieldsCourse] = useState([]);
  const [showRemoveButtonsCourse, setShowRemoveButtonsCourse] = useState([
    true,
  ]);

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);
  const [password, setPassword] = useState("ddit_tech");
  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    gender: 1,
    courses: [],
    phone: "",

    status: 1,
    education: "",
    image: "",
    reg_type: "regular",
    course_fee: 0,
    registration_fee: 0,
    decided_fee: 0,
    actual_fee: 0,
    cnic: "",
    referred_by: "",
    registration_fee: "",
    address: "",
    batches: [],
    // course: [],
    course: "",
    wallet_amount: 0,
    student_id: null,

    friend_number: "",
    family_number: "",
    whatsapp_number: "",

    latest_degree: "",
    degree_status: "complete",
    completion_year: "",
    institution_name: "",
    semester: "",
    time_preferences: "",
    entrance_type: "in_batch",

    discount: 0,
    fee_status: "all_paid",
    course_id: "",
    note: "",
    trash_reason: "",
  });

  const myStyle = {
    textTransform: "capitalize",
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  // const handleChange2 = (event) => {
  //   const selectedCourses = event.target.value;
  //   setFormInputs((prevInputs) => ({
  //     ...prevInputs,
  //     courses: selectedCourses,
  //   }));
  // };

  // const handleAddFieldCourse = () => {
  //   // Add a new field as an object with default values

  //     setAdditionalFieldsCourse([
  //       ...additionalFieldsCourse,
  //       { number: "", type: "June" },
  //     ]);

  //     // Update the state for visibility of Remove buttons
  //     setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);

  // };

  function formatTime(startTime) {
    // Parse the time strings using moment
    const startMoment = moment(startTime, "HH:mm");
    // Format the start time and end time with AM/PM
    const formattedStartTime = startMoment.format("hh:mm A");
    const formattedTime = `${formattedStartTime}`;
    return formattedTime;
  }

  const handleAddFieldCourse = (count) => {
    // Create an array of fields based on the count
    console.log("formInputs.decided_fee __test", formInputs.decided_fee);
    let decided_fee_from_batch = 0;
    if (formInputs.actual_fee > 0) {
      decided_fee_from_batch = formInputs.actual_fee / count;
    }

    const newFields = Array.from({ length: count }, () => ({
      fee: decided_fee_from_batch,
      month: "June",
    }));

    // // Add the new fields to the existing additionalFieldsCourse array
    // setAdditionalFieldsCourse([...additionalFieldsCourse, ...newFields]);

    // Replace the existing additionalFieldsCourse array with the newFields
    setAdditionalFieldsCourse(newFields);
    console.log("newFields", newFields);
    // Update the state for visibility of Remove buttons
    setShowRemoveButtonsCourse(Array(count).fill(true));

    // // Update the state for visibility of Remove buttons
    // setShowRemoveButtonsCourse([...showRemoveButtonsCourse, true]);
  };

  const handleFieldChangeCourse = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsCourse((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteFieldCourse = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFieldsCourse];
    const updatedShowRemoveButtons = [...showRemoveButtonsCourse];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFieldsCourse(updatedFields);
    setShowRemoveButtonsCourse(updatedShowRemoveButtons);
  };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([
      ...additionalFields,
      { number: "", type: "personal" },
    ]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };
  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;

    if (value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", {
        variant: "error",
      });
      return; // Do not update state if the condition is not met
    }

    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleCourseChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    // Find the course object with the specified _id
    const course = courses.find((item) => item.id === value);

    // const course = courses.find((item) => {
    //   console.log('item', item);
    //   return item._id === '64a45cd7e0ad1892dd66cd1c';
    // });

    const fee = course ? course.fee : null;

    console.log("course", course);
    console.log("fee", fee); // Output: 24000

    if (fee !== null) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        actual_fee: fee,
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleChange2 = (e, newValue) => {
    // const { name, value } = e.target;
    // console.log('name', name);
    // console.log('value', value);
    console.log("newValue", newValue);

    // const selectedCourse = courses.find((course) => course._id === value);
    let fee = null;

    if (newValue.fee) {
      fee = newValue.fee;
    }

    if (fee !== null) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        // [name]: value,
        actual_fee: fee,
        course: newValue ? newValue._id : "",
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        // [name]: value,
        course: newValue ? newValue._id : "",
      }));
    }

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   course: newValue ? newValue._id : "",
    // }));
  };

  const handleCNICChange = (e) => {
    const { name, value } = e.target;
    console.log("here value", value);

    // Remove all non-numeric characters
    const numericValue = value.replace(/[^0-9]/g, "").slice(0, 13); // Limit input to 13 characters

    // Insert hyphens at the appropriate positions
    let formattedCNIC = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 5 || i === 12) {
        formattedCNIC += "-";
      }
      formattedCNIC += numericValue[i];
    }

    setFormInputs({ ...formInputs, [name]: formattedCNIC });
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;

    // Check if the first digit is 3
    if (name === "phone_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", {
        variant: "error",
      });
      return; // Do not update state if the condition is not met
    }

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("name", name);
    console.log("value", value);

    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);
      let fee = null;
      console.log("selectedBatch", selectedBatch);

      const additionalFieldsFromBatch = selectedBatch.accumulative_revenue.map(
        (batch, index) => ({
          // fee: batch.fee,
          fee: 0,
          month: batch.month,
        })
      );

      setAdditionalFieldsCourse(additionalFieldsFromBatch);

      if (fee !== null) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
          actual_fee: fee,
        }));
      } else {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
        }));
      }
    } else if (name === "course") {
      // Find the selected batch object
      const selectedCourse = courses.find((course) => course._id === value);
      let fee = null;

      if (selectedCourse.fee) {
        fee = selectedCourse.fee;
      }

      if (fee !== null) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
          actual_fee: fee,
        }));
      } else {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          [name]: value,
        }));
      }
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    console.log("formInputs", formInputs);
    let error = "";

    // studentRow,handleCloseModal

    // formInputs.trash_reason


    const trimmedTrashReason = formInputs.trash_reason.trim();

    if (trimmedTrashReason === "") {
      error = "Trash Reason is required.";
      enqueueSnackbar(error, { variant: "error" });
      return;
    }
    setIsLoading(true);
    const result = await _move_to_trash(studentRow.id, {
      // trash_reason: formInputs.trash_reason,
      trash_reason: trimmedTrashReason,
    });

    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students.id !== studentRow.id
      );
      onStudentsUpdate(updatedStudents);

      setIsLoading(false);
      navigate("/students");
      handleCloseModal();
      enqueueSnackbar("Move To Trash Successfully!", { variant: "success" });
      setTrashStudentCount(trashStudentCount + 1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  //   if (isLoading) {
  //     return <CircularLoader />;
  //   }

  return (
    <Page title="Move To Trash Student">
      <div className="container px-4 mb-5 mt-5 pt-3">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={1}>
              <TextField
                fullWidth
                label="Trash Reason"
                multiline // Enable multiline
                rows={3} // Set the number of rows to 3
                value={formInputs.trash_reason}
                onChange={handleChange}
                name="trash_reason"
                required
                // inputProps={{
                //   pattern: "[A-Za-z]{3,}",
                //   title:
                //     "Please enter at least 3 letters with only alphabetical characters.",
                // }}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // marginTop: "10px",
                  // marginBottom: "10px",
                }}
              >
                <h6 className="mt-1">
                  Are you sure you want to Move Student ({capitalizeFirstLetter(studentRow.name)}) to Trash?
                </h6>
              </Grid>
              <Stack alignItems="center">
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <LoadingButton
                    size="medium"
                    variant="contained"
                    // loading={isLoading}
                    sx={{ width: "120px" }}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </LoadingButton>

                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{
                      width: "120px",
                      backgroundColor: "red",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "red",
                        opacity: 0.7,
                        color: "white",
                      },
                    }}
                    //   sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
                  >
                    Agree
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default MoveToTrashPage;
