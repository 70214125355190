import { invokeApi } from "src/utils";


export const _list_event= async (data) => {

    const requestObj = {
      path: `api/event/list_event`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const _add_event = async (data) => {

    const requestObj = {
      path: `api/event/add_event`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

    export const _edit_event = async (id, data) => {
    const requestObj = {
      path: `api/event/edit_event/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
  export const _detail_event = async (id) => {
    const requestObj = {
      path: `api/event/detail_event/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

    export const _delete_event = async (id) => {
    const requestObj = {
      path: `api/event/delete_event/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };
