import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import {
  _restore_student,
  _student_delete_in_trash,
  _student_move_to_trash,
  delStudent,
} from "src/DAL/student";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
// ----------------------------------------------------------------------

// ... (existing imports)

export default function StudentMoreMenu({ row, students, onStudentsUpdate }) {
  const [openDelete, setOpenDelete] = useState(false);
  const [openRestore, setOpenRestore] = useState(false);
  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };

  const handleRestoreClick = () => {
    setIsOpen(false);
    setOpenRestore(true);
  };

  const handleDeleteOk = async () => {
    await handleAction(row, _student_delete_in_trash, "Deleted Successfully!");
    setOpenDelete(false);
  };

  const handleRestoreOk = async () => {
    await handleAction(row, _restore_student, "Restored Successfully!");
    setOpenRestore(false);
  };

  async function handleAction(row, actionFunction, successMessage) {
    const result = await actionFunction(row.id);
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (student) => student.id !== row.id
      );
      onStudentsUpdate(updatedStudents);
      enqueueSnackbar(successMessage, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {/* ... (existing menu items) */}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "student",
          "edit"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleRestoreClick}
          >
            <ListItemIcon>
              <Iconify icon="fa-solid:trash-restore" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Restore"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {/* ... (existing menu items) */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/edit-student/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/assign-batch/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fa-solid:trash-restore" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Assign Batch"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/edit-student/${row.id}`}
          sx={{ color: "text.secondary" }}
        > */}
        {/* <MenuItem sx={{ color: "text.secondary" }} onClick={handleRestoreClick}> 
          <ListItemIcon>
            <Iconify icon="fa-solid:trash-restore" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Assign Batch"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "student",
          "delete"
        ) ? (
          <MenuItem
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
            onClick={handleDeleteClick}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {/* <MenuItem
          component={RouterLink}
          to={`/students/update-student-fee/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:phone-update-checkmark-20-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Update Fee"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to Delete this student?"
        onOk={handleDeleteOk}
      />
      <DelPopup
        open={openRestore}
        setOpen={setOpenRestore}
        title="Confirm Restore"
        message="Are you sure you want to Restore this student?"
        onOk={handleRestoreOk}
      />
    </>
  );
}
