import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import Autocomplete from "@mui/material/Autocomplete";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent, listStudent } from "src/DAL/student";

import { listActiveBatch } from "src/DAL/batch";
import {
  _add_collect_fee,
  _add_refund_fee,
  _add_transaction,
  _detail_transaction,
  _list_payments,
  _pay_registration_fee,
  _pay_tuition_fee,
  _refund_registration_fee,
  _refund_tution_fee,
  _update_transaction,
} from "src/DAL/collect_fee";
import PrintInvoiceByID from "../PrintInvoice/PrintInvoiceByID";
import { _active_person_list, _list_person } from "src/DAL/person";
import { _active_bank_list } from "src/DAL/bank";
const Edit = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState("disabled");
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [students, setStudents] = useState([]);
  const [persons, setPersons] = useState([]);
  const [banks, setBanks] = useState([]);

  const [additionalFieldsFee, setAdditionalFieldsFee] = useState([]);

  const { id } = useParams();
  let transactionID = id;

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs().add(1, "month"), // Add 1 month to the current date
  });

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    father_name: "",
    email: "",
    gender: "male",
    courses: [],
    phone: "",
    fee_status: "registration",
    status: "true",
    education: "",
    image: "",
    address: "",
    reg_type: "",
    course_fee: "",
    registration_fee: "",
    decided_fee: [],
    actual_fee: "",
    paid_fee: "0",
    refund_fee: "0",
    due_fee: "",
    duration: "1",
    fee_type: "paid",
    mode: "person",
    person_id: "",
    bank_id: "",
    batches: [],
    wallet_fee: "0",
    wallet_amount: "0",
    receipts: "",
    month: "June",
    batch_name: "",
  });

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value, "kkkk");
    if (name === "batches") {
      // Find the selected batch object
      const selectedBatch = batches.find((batch) => batch.id === value);

      // Update the courses dropdown with the selected batch's courses array
      setCourses(selectedBatch.courses);
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
        courses: selectedBatch.courses.map((course) => course._id),
      }));
    } else {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
    }
  };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   const formattedDate = dayjs(date).format("MM/DD/YYYY");
  //   setFormInputs((values) => ({ ...values, dob: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleFieldChangeFee = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFieldsFee((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = "";

    if (typeof selected.id === "undefined") {
      error = "Please! Select A Student";
      enqueueSnackbar(error, { variant: "error" });
    } else {
      let paidFee = parseInt(formInputs.paid_fee, 10);
      let fee_status = formInputs.fee_status;
      let fee_type = formInputs.fee_type;
      let start_date = dayjs(formInputs2.duration).format("YYYY-MM-DD");
      let end_date = dayjs(formInputs2.duration2).format("YYYY-MM-DD");
      let studentID = selected.id;
      let paymentID = selected.payment_id;
      let mode = formInputs2.mode;
      let refund_fee = parseInt(formInputs.refund_fee, 10);

      // let decided_fee = parseInt(formInputs.decided_fee, 10);

      if (!isNaN(paidFee) && paidFee > 0 && fee_status === "registration") {
        ///////////////////////////////// Registration Fee

        let postData = [];
        let personID = formInputs.person_id;
        let bankID = formInputs.bank_id;

        if (Array.isArray(personID)) {
          personID = personID.join(", "); // You can choose any separator you like
        }
        if (Array.isArray(bankID)) {
          bankID = bankID.join(", "); // You can choose any separator you like
        }

        if (formInputs.mode === "person") {
          postData = {
            // student_id: paymentID,
            type: "registration",
            paid_fee: parseInt(formInputs.paid_fee, 10),
            date: start_date,
            person: personID,
          };
        } else if (formInputs.mode === "bank") {
          postData = {
            // student_id: paymentID,
            type: "registration",
            paid_fee: parseInt(formInputs.paid_fee, 10),
            date: start_date,
            bank: bankID,
            bank_receipt_number: formInputs.receipts,
          };
        } else if (formInputs.mode === "wallet") {
          postData = {
            // student_id: paymentID,
            type: "registration",
            paid_fee: parseInt(formInputs.paid_fee, 10),
            date: start_date,
            wallet_id: studentID,
            // wallet_amount:formInputs.wallet_amount,
          };
        }

        console.log("postData", postData);
        setIsLoading(true);
        setIsButtonLoading(true);
        const result = await _update_transaction(transactionID, postData);
        console.log("result result result _update_transaction", result);
        if (result.code === 200) {
          navigate("/transaction");
          enqueueSnackbar("Transaction Successfully!", {
            variant: "success",
          });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      }
      ///////////////////////////////// tuition Fee
      else if (fee_status === "tuition") {
        console.log("additionalFieldsFee", additionalFieldsFee);

        // const transformedArray = additionalFieldsFee.map((item) => ({
        //   month: item.month,
        //   fee: item.fee,
        //   fee_status: item.fee_status,
        // }));

        let postData = [];
        let personID = formInputs.person_id;
        let bankID = formInputs.bank_id;

        if (Array.isArray(personID)) {
          personID = personID.join(", "); // You can choose any separator you like
        }
        if (Array.isArray(bankID)) {
          bankID = bankID.join(", "); // You can choose any separator you like
        }

        if (formInputs.mode === "person") {
          postData = {
            // student_id: paymentID,
            type: "tuition",
            paid_fee: parseInt(formInputs.paid_fee, 10),
            month: formInputs.month,
            date: start_date,
            person: personID,
          };
        } else if (formInputs.mode === "bank") {
          postData = {
            // student_id: paymentID,
            type: "tuition",
            paid_fee: parseInt(formInputs.paid_fee, 10),
            date: start_date,
            month: formInputs.month,
            bank: bankID,
            bank_receipt_number: formInputs.receipts,
          };
        } else if (formInputs.mode === "wallet") {
          postData = {
            // student_id: paymentID,
            type: "tuition",
            paid_fee: parseInt(formInputs.paid_fee, 10),
            date: start_date,
            month: formInputs.month,
            wallet_id: studentID,
            // wallet_amount:formInputs.wallet_amount,
          };
        }

        console.log("postData", postData);
        setIsLoading(true);
        setIsButtonLoading(true);
        const result = await _update_transaction(transactionID, postData);
        console.log("result result result _update_transaction", result);
        if (result.code === 200) {
          navigate("/transaction");
          enqueueSnackbar("Transaction Successfully!", {
            variant: "success",
          });
          setIsLoading(false);
          setIsButtonLoading(false);
        } else {
          enqueueSnackbar(result.message, { variant: "error" });
          setIsLoading(false);
          setIsButtonLoading(false);
        }
      } else {
        // Handle the case when paidFee is not a valid number
        enqueueSnackbar("Invalid paid fee value", { variant: "error" });
      }
    }
  };

  // useEffect(() => {
  //   if (courses.length > 0) {
  //     setFormInputs((prevInputs) => ({
  //       ...prevInputs,
  //       courses: [courses[0]._id],
  //     }));
  //   }
  // }, [courses]);
  useEffect(() => {
    if (batches.length > 0) {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        batches: [batches[0].id],
      }));
    }
  }, [batches]);

  const fetchActiveBatches = async () => {
    const response = await listActiveBatch();

    if (response.code === 200) {
      const batches = response.batches.map((batch) => ({
        id: batch._id,
        name: batch.name,
        student_count: batch.student_count,
        status: batch.status,
        courses: batch.courses,
      }));
      setCourses(batches[0].courses);
      setBatches(batches);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchTranseactionDetails = async (student_data) => {
    setIsLoading(true);

    const response = await _detail_transaction(transactionID);
    console.log("response response response _detail_transaction", response);

    if (response.code === 200) {
      console.log("student_data _detail_transaction", student_data);
      student_data.map((student) => {
        if (student.id === response.transaction.student._id) {
          console.log("student inside _detail_transaction",student);

          setSelected(student);
          updateFormInputs(student);

          let modeFromDetail = "wallet";
          let personID = "";
          let bankID = "";

          if(response.transaction.person){
            modeFromDetail = "person";
            personID = response.transaction.person._id;
          }else if(response.transaction.bank){
            modeFromDetail = "bank";
            bankID = response.transaction.bank._id;
          }
        
          setFormInputs((prevInputs) => ({
            ...prevInputs,
            fee_status: response.transaction.type,
            month: response.transaction.month,
            paid_fee: response.transaction.paid_fee,
            receipts: response.transaction.bank_receipt_number?response.transaction.bank_receipt_number:"",
            mode: modeFromDetail,
            person_id: personID,
            bank_id: bankID,
          }));

        }
      });

      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const fetchStudents = async () => {
    setIsLoading(true);
    // const response = await listStudent();
    const response = await listStudent();
    console.log("response response response _listStudent", response);

    // const response = await _list_payments({
    //   text: "",
    // });

    console.log("response response response _list_payments", response);
    if (response.code === 200) {
      const students = response.students.map((payment, index) => {
        const newFeeObject = {
          month: "registration",
          fee: payment.total_registration_fee,
          fee_status: payment.registration_fee_status,
          _id: "",
        };

        // Create a copy of decided_fee and add the newFeeObject to it
        let new_decided_fee = [...payment.decided_fee]; // Copy the array
        new_decided_fee.unshift(newFeeObject); // Add the newFeeObject to the beginning

        return {
          id: payment._id,
          name: payment.name ? payment.name : "",
          student_cnic: payment.cnic ? payment.cnic : "",
          mobile: payment.contact_number ? payment.contact_number : "",
          batch: payment.batch,
          father_name: payment.father_name,
          gender: payment.gender,
          payment_id: payment._id,
          number: index + 1,
          payments: new_decided_fee, // Use the modified array
          wallet_fee: payment.wallet_amount ? payment.wallet_amount : 0,
        };
      });

      setStudents(students);
      fetchTranseactionDetails(students);
      // console.log("students students students", students);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  // const fetchStudents = async () => {
  //   setIsLoading(true);
  //   // const response = await listStudent();
  //   const response = await _list_payments({
  //     text: "",
  //   });

  //   console.log("response response response _list_payments", response);
  //   if (response.code === 200) {
  //     const students = response.payments.map((payment, index) => ({
  //       // id: collect_fee._id,
  //       id: payment.student._id,
  //       name: payment.student.name ? payment.student.name : "",
  //       student_cnic: payment.student.cnic ? payment.student.cnic : "",
  //       mobile: payment.student.contact_number
  //         ? payment.student.contact_number
  //         : "",
  //       // courses: payment.student.courses,
  //       batch: payment.student.batch,
  //       // student_agreed_fee: payment.student.registration_fee,
  //       father_name: payment.student.father_name,
  //       // total_registration_fee: payment.student.total_registration_fee,
  //       // total_paid_registration_fee: payment.student.total_paid_registration_fee,
  //       // total_pending_registration_fee: payment.student.total_pending_registration_fee,
  //       // total_fee: payment.student.total_fee,
  //       // total_paid_fee: payment.student.total_paid_fee,
  //       // total_pending_fee: payment.student.total_pending_fee,
  //       gender: payment.student.gender,
  //       payment_id: payment._id,
  //       number: index + 1,
  //       payments:payment.payments,
  //       wallet_fee:payment.student.wallet_amount?payment.student.wallet_amount:0,
  //     }));

  //     setStudents(students);
  //     console.log("students students students", students);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(response.message, { variant: "error" });
  //     setIsLoading(false);
  //   }
  // };
  const fetchActivePersons = async () => {
    setIsLoading(true);
    const response = await _active_person_list();
    console.log("response _active_person_list", response);
    if (response.code === 200) {
      const personsData = response.persons.map((person, index) => ({
        id: person._id,
        name: person.name,
        phone_number: person.phone_number,
        email: person.email,
        address: person.address,
        status: person.status,
        number: index + 1,
      }));
      setPersons(personsData);
      if (personsData.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          person_id: [personsData[0].id],
        }));
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const fetchActiveBanks = async () => {
    setIsLoading(true);
    const response = await _active_bank_list();
    console.log("response _active_bank_list", response);
    if (response.code === 200) {
      const banksData = response.banks.map((bank, index) => ({
        id: bank._id,
        name: bank.name,
        branch_code: bank.branch_code,
        address: bank.address,
        telephone_number: bank.telephone_number,
        status: bank.status,
        number: index + 1,
      }));
      setBanks(banksData);
      if (banksData.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          bank_id: [banksData[0].id],
        }));
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const updateFormInputs = (selectedValues) => {
    // Create a copy of the existing formInputs state
    const updatedFormInputs = { ...formInputs };

    // Update the desired properties with the selected values
    updatedFormInputs.first_name = selectedValues.first_name;
    updatedFormInputs.father_name = selectedValues.father_name;
    updatedFormInputs.email = selectedValues.email;

    updatedFormInputs.gender = selectedValues.gender;
    updatedFormInputs.courses = selectedValues.courses;
    updatedFormInputs.phone = selectedValues.phone;
    // updatedFormInputs.fee_status = selectedValues.fee_status;
    updatedFormInputs.status = selectedValues.status;
    updatedFormInputs.reg_type = selectedValues.reg_type;
    updatedFormInputs.course_fee = selectedValues.course_fee;
    updatedFormInputs.registration_fee = selectedValues.registration_fee;
    updatedFormInputs.decided_fee = selectedValues.decided_fee;
    updatedFormInputs.actual_fee = selectedValues.actual_fee;

    updatedFormInputs.wallet_fee = selectedValues.wallet_fee;

    // updatedFormInputs.paid_fee = selectedValues.paid_fee;
    updatedFormInputs.batches = selectedValues.batches;
    updatedFormInputs.batch_name = selectedValues.batch.name;
    // updatedFormInputs.batch = selectedValues.batch;
    // updatedFormInputs.date_of_birth = selectedValues.date_of_birth;
    // updatedFormInputs.contact_number = "0" + selectedValues.contact_number;
    // updatedFormInputs.phone_number = selectedValues.contact_number;
    // updatedFormInputs.phone_number =
    //   "0" + selectedValues.contact_number.toString();
    // ... Update other properties as needed

    // Set the updated formInputs state
    setAdditionalFieldsFee(selectedValues.payments);
    setFormInputs(updatedFormInputs);
  };

  const handleSelectChange = (event, newValue) => {
    // if (newValue === null) {
    //   console.log("newValue", newValue);
    //   setSelected([]);
    //   updateFormInputs([]);
    //   setAdditionalFieldsFee([]);
    // } else {
    //   console.log("newValue", newValue);
    //   setSelected(newValue);
    //   updateFormInputs(newValue);
    // }
  };

  useEffect(() => {
    // fetchActiveBatches();
  }, []);

  useEffect(() => {
    fetchActivePersons();
    fetchStudents();
    fetchActiveBanks();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Edit transaction">
      <Grid
        // container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px", padding: "0 35px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit transaction
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <Stack spacing={2} sx={{ marginBottom: 2 }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={students}
              getOptionLabel={(student) => (student.name ? student.name : "")}
              value={selected}
              onChange={handleSelectChange}
              renderInput={(params) => (
                <TextField {...params} label="Students *" />
              )}
              disabled
            />
          </Stack>

          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            {/* <Stack sx={{ marginBottom: 2 }}> */}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ marginBottom: 2 }}
            >
              {/* <TextField
                fullWidth
                label="Fee Type"
                select
                // required
                value={formInputs.fee_type}
                onChange={handleChange}
                name="fee_type"
              >
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="refund">Refund Fee</MenuItem>
              </TextField> */}

              <TextField
                // sx={{ marginBottom: 2 }}
                fullWidth
                label="Fee Status"
                select
                // required
                value={formInputs.fee_status}
                onChange={handleChange}
                name="fee_status"
              >
                <MenuItem value="registration">Registration Fee</MenuItem>
                <MenuItem value="tuition">Tuition Fee</MenuItem>
              </TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              // sx={{ marginBottom: 2 }}
            >
              {formInputs.mode === "person" ? (
                <TextField
                  fullWidth
                  label="Person"
                  select
                  value={formInputs.person_id}
                  onChange={handleChange}
                  name="person_id"
                  // required
                  // SelectProps={{ multiple: true }}
                >
                  {persons.map((person) => (
                    <MenuItem key={person.id} value={person.id}>
                      {person.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : formInputs.mode === "wallet" ? (
                <TextField
                  fullWidth
                  label="Wallet"
                  value={formInputs.wallet_fee}
                  onChange={handleChange}
                  name="wallet_fee"
                  type="number"
                  disabled
                />
              ) : formInputs.mode === "bank" ? (
                <TextField
                  fullWidth
                  label="Bank"
                  select
                  value={formInputs.bank_id}
                  onChange={handleChange}
                  name="bank_id"
                  // required
                  // SelectProps={{ multiple: true }}
                >
                  {banks.map((bank) => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                ""
              )}

              <TextField
                // sx={{ marginBottom: 2 }}
                fullWidth
                label="Mode"
                select
                // required
                value={formInputs.mode}
                onChange={handleChange}
                name="mode"
              >
                <MenuItem value="person">Person</MenuItem>
                <MenuItem value="wallet">Wallet</MenuItem>
                <MenuItem value="bank">Bank</MenuItem>
              </TextField>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ marginTop: 2 }}
            >
              <TextField
                key="paid-fee-input"
                fullWidth
                label="Paid fee"
                value={formInputs.paid_fee}
                onChange={handleChange}
                name="paid_fee"
                required
              />

              {formInputs.mode === "bank" ? (
                <TextField
                  fullWidth
                  label="Receipts"
                  value={formInputs.receipts}
                  onChange={handleChange}
                  name="receipts"
                  required
                />
              ) : (
                formInputs.mode === "wallet" &&
                //   formInputs.fee_status === "tuition" ? (
                //   <TextField
                //     fullWidth
                //     label="Wallet Amount"
                //     value={formInputs.wallet_amount}
                //     onChange={handleChange}
                //     name="wallet_amount"
                //     required
                //   />
                // ) :

                ""
              )}
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ marginTop: 2 }}
            >
              {/* <div className="col-md-12 col-xs-12"> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  // disabled
                  label="Date"
                  format="YYYY-MM-DD"
                  // value={formInputs?.date_of_birth}
                  value={dayjs(formInputs2.duration)} // Convert the default date to a Day.js object
                  onChange={handleDateChange}
                  // maxDate={dayjs()} // Set the maximum date to today's date
                  // renderInput={(params) => <TextField {...params} />}
                  sx={{
                    width: "100%", // Set the width to 100% to take up the full available width
                    // marginTop: "20px",
                  }}
                />
              </LocalizationProvider>
              {/* </div> */}

              {/* <div className="col-md-12 col-xs-12"> */}
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // disabled
                    label="End Date"
                    format="YYYY-MM-DD"
                    // value={formInputs?.date_of_birth}
                    value={dayjs(formInputs2.duration2)} // Convert the default date to a Day.js object
                    onChange={handleDateChange2}
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                      // marginTop: "20px",
                    }}
                  />
                </LocalizationProvider> */}
              {/* </div> */}

              {formInputs.fee_status === "tuition" ? (
                <TextField
                  fullWidth
                  label="Month"
                  select
                  name="month"
                  value={formInputs.month}
                  onChange={handleChange}
                  // disabled
                >
                  <MenuItem value="January">January</MenuItem>
                  <MenuItem value="February">February</MenuItem>
                  <MenuItem value="March">March</MenuItem>
                  <MenuItem value="April">April</MenuItem>
                  <MenuItem value="May">May</MenuItem>
                  <MenuItem value="June">June</MenuItem>
                  <MenuItem value="July">July</MenuItem>
                  <MenuItem value="August">August</MenuItem>
                  <MenuItem value="September">September</MenuItem>
                  <MenuItem value="October">October</MenuItem>
                  <MenuItem value="November">November</MenuItem>
                  <MenuItem value="December">December</MenuItem>
                </TextField>
              ) : (
                ""
              )}
            </Stack>

            <Stack alignItems="end" sx={{ marginBottom: 2, marginTop: 2 }}>
              <LoadingButton
                key="add-button"
                size="medium"
                type="submit"
                variant="contained"
                loading={isButtonLoading}
                sx={{ width: "120px" }}
                disabled={typeof selected.id === "undefined" || isButtonLoading}
              >
                Save
              </LoadingButton>
            </Stack>
          </form>

          {/* batch */}
          <TextField
            fullWidth
            label="Batch Name"
            value={formInputs.batch_name}
            onChange={handleChange}
            name="batch_name"
            disabled
          />
          {
            // formInputs.fee_status === "tuition" &&
            additionalFieldsFee.map((field, index) => (
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                key={index}
                sx={{ marginTop: 2 }}
              >
                {field && (
                  <>
                    <TextField
                      fullWidth
                      label={`Fee ${index + 1}`}
                      name="fee"
                      value={field.fee}
                      onChange={(e) => handleFieldChangeFee(e, index)}
                      // sx={{ marginRight: "10px" }}
                      disabled
                    />

                    <TextField
                      fullWidth
                      label={field.month === "registration" ? "Type" : "Month"}
                      select
                      name="month"
                      value={field.month}
                      onChange={(e) => handleFieldChangeFee(e, index)}
                      disabled
                    >
                      <MenuItem value="January">January</MenuItem>
                      <MenuItem value="February">February</MenuItem>
                      <MenuItem value="March">March</MenuItem>
                      <MenuItem value="April">April</MenuItem>
                      <MenuItem value="May">May</MenuItem>
                      <MenuItem value="June">June</MenuItem>
                      <MenuItem value="July">July</MenuItem>
                      <MenuItem value="August">August</MenuItem>
                      <MenuItem value="September">September</MenuItem>
                      <MenuItem value="October">October</MenuItem>
                      <MenuItem value="November">November</MenuItem>
                      <MenuItem value="December">December</MenuItem>
                      <MenuItem value="registration">Registration</MenuItem>
                    </TextField>

                    <TextField
                      fullWidth
                      label="Fee Status"
                      select
                      name="fee_status"
                      value={field.fee_status}
                      onChange={(e) => handleFieldChangeFee(e, index)}
                      disabled
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="paid">Paid</MenuItem>
                      <MenuItem value="refund">Refund</MenuItem>
                    </TextField>

                    {/* <TextField
                        fullWidth
                        label="Fee Type"
                        select
                        name="type"
                        value={field.type}
                        onChange={(e) => handleFieldChangeFee(e, index)}
                        disabled
                      >
                        <MenuItem value="registration">Registration</MenuItem>
                        <MenuItem value="tuition">Tuition</MenuItem>
                      </TextField> */}
                  </>
                )}
              </Stack>
            ))
          }

          {typeof selected.id !== "undefined" ? (
            <>
              {/* <PrintInvoiceByID
                // ID={setStudents}
                ID={selected.id}
                // ID='64aba79670f8060debb5d19a'
              /> */}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
