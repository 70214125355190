import React from "react";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Iconify } from "src/components";
import { CircularLoader, Page } from "src/components";
import { detailBatch, editBatch, listBatch } from "src/DAL/batch";
import { _course_instructors, listActiveCourse } from "src/DAL/course";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import { listInstructor } from "src/DAL/instructor";
import { _detail_fee_voucher, _edit_fee_voucher } from "src/DAL/voucher";
import { capitalizeFirstLetter } from "src/utils/formatText";

const Edit = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });
  const [additionalFields, setAdditionalFields] = useState([
    { fee_title: "", fee: 0 },
  ]);
  const [showRemoveButtons, setShowRemoveButtons] = useState([true]);
  const [instructors, setInstructors] = useState([]);

  const [batches, setBatches] = useState([]);
  const [batcheFees, setbatcheFees] = useState([]);

  const [inputs, setInputs] = useState({
    division_id: "",
    teamA_id: "",
    teamB_id: "",
    date: dayjs(new Date()),
    time: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),
    format_date: dayjs(dayjs(new Date())).format("YYYY-MM-DD"),
    location: "",
    status: true,
  });

  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "",
    order: "",
    courses: "",
    start_date_batch_select: "",
    end_date_batch_select: "",

    start_time_class: dayjs(new Date()),
    format_time: dayjs(dayjs(new Date())).format("HH:mm"),

    end_time_class: dayjs(new Date()),
    format_end_time: dayjs(dayjs(new Date())).format("HH:mm"),

    registration_fee: 0,
    batch_discount: 0,
    courses: [], // Initialize as an empty array
    instructors: [], // Initialize as an empty array
    batch_status: 0,

    batch_id: "",
    batch_fee_id: "",
    additional_discount: 0,
    additional_discount_reason: "",
  });

  const [feeDiscountChecked, setFeeDiscountChecked] = useState(false);

  const handleCheckboxFeeChange = (event) => {
    setFeeDiscountChecked(event.target.checked);
  };

  const myStyle = {
    textTransform: "capitalize",
  };

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };

  // const handleTimeChange = (time) => {
  //   const formattedTime = dayjs(time).format("HH:mm");
  //   setFormInputs((values) => ({
  //     ...values,
  //     format_time: formattedTime,
  //     time: time,
  //   }));
  // };

  const handleTimeChange = (time) => {
    const formattedTime = dayjs(time).format("HH:mm");
    setInputs((values) => ({
      ...values,
      format_time: formattedTime,
      time: time,
    }));
  };

  const handleEndTimeChange = (time) => {
    console.log("time", time);
    const formattedTime = dayjs(time).format("HH:mm");
    console.log("time formattedTime", formattedTime);
    setInputs((values) => ({
      ...values,
      format_end_time: formattedTime,
      end_time: time,
    }));
  };

  const handleChange2 = (e) => {
    const { target } = e;
    console.log("target __test", target);
    setFormInputs({
      ...formInputs,
      [target.name]: target.value,
    });

    // Use Array.prototype.find to locate the desired object
    let foundObject = batches.find((obj) => obj.id === target.value);

    if (foundObject) {
      setbatcheFees(foundObject.batch_fee);
    } else {
      setbatcheFees([]);
    }
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      instructors: value,
    }));
  };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_start_date_batch_select: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration: date }));
  // };

  // const handleDateChange = (date) => {
  //   // const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // const formattedDate = dayjs(date).format("DD/MM/YYYY");
  //   const formattedDate = dayjs(date).format("MM/DD/YYYY");
  //   setFormInputs((values) => ({ ...values, start_date_batch_select: formattedDate }));
  // };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      start_date_batch_select: formattedDate,
    }));
  };
  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setFormInputs((values) => ({
      ...values,
      end_date_batch_select: formattedDate,
    }));
  };

  // const handleDateChange2 = (date) => {
  //   const formattedDate = dayjs(date).format("YYYY-MM-DD");
  //   // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
  //   // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
  //   setFormInputs2((values) => ({ ...values, duration2: date }));
  // };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  // const handleEndTimeChange = (time) => {
  //   setEndTime(time);
  // };

  const handleAddField = () => {
    // Add a new field as an object with default values
    setAdditionalFields([...additionalFields, { fee_title: "", fee: 0 }]);

    // Update the state for visibility of Remove buttons
    setShowRemoveButtons([...showRemoveButtons, true]);
  };

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    setAdditionalFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index][name] = value;
      return updatedFields;
    });
  };

  const handleDeleteField = (index) => {
    // Create a copy of the current state
    const updatedFields = [...additionalFields];
    const updatedShowRemoveButtons = [...showRemoveButtons];

    // Remove the field at the specified index
    updatedFields.splice(index, 1);
    updatedShowRemoveButtons.splice(index, 1);

    // Update the state to reflect the removed field and Remove button visibility
    setAdditionalFields(updatedFields);
    setShowRemoveButtons(updatedShowRemoveButtons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formInputs", formInputs);
    // console.log("formInputs2", formInputs2);
    // console.log("inputs", inputs);

    setIsLoading(true);
    console.log(
      "formInputs.start_date_batch_select",
      formInputs.start_date_batch_select
    );
    console.log(
      "formInputs.start_date_batch_select typeof",
      typeof formInputs.start_date_batch_select
    );

    let post_date = formInputs.start_date_batch_select;
    if (post_date === "Invalid Date") {
      const new_data = new Date();

      // Get the year, month, and day components
      const year = new_data.getFullYear();
      const month = String(new_data.getMonth() + 1).padStart(2, "0"); // Month is 0-based, so we add 1 and format to two digits.
      const day = String(new_data.getDate()).padStart(2, "0"); // Format to two digits.

      // Format the date as 'yyyy-mm-dd'
      const formattedDate = `${year}-${month}-${day}`;

      post_date = formattedDate;
      console.log("formInputs.start_date_batch_select post_data", post_date);
    }

    let feeDiscount = formInputs.additional_discount;
    let feeDiscountReason = formInputs.additional_discount_reason;

    if (typeof feeDiscount === "string") {
      feeDiscount = parseInt(feeDiscount, 10); // Use 10 as the radix for decimal numbers
    }

    if (!feeDiscountChecked) {
      feeDiscount = 0;
      feeDiscountReason = "";
    }

    let postData = {
      // batch_id: formInputs.batch_id,
      // batch_fee_id: formInputs.batch_fee_id,
      due_date: post_date,
      additional_discount: feeDiscount,
      additional_discount_reason: feeDiscountReason,
    };

    const result = await _edit_fee_voucher(id, postData);
    if (result.code === 200) {
      setIsLoading(false);
      // navigate("/vouchers");
      navigate("/vouchers/list-voucher");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  
  const fetchDetailBatch = async () => {
    setIsLoading(true);
    const response = await _detail_fee_voucher(id);
    console.log("response _detail_fee_voucher __detail_fee_voucher", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      let defaultStartDate = new Date();
      console.log("defaultStartDate", defaultStartDate);
      if (response.fee_voucher.due_date) {
        defaultStartDate = new Date(response.fee_voucher.due_date);
        console.log("defaultStartDate", defaultStartDate);
      }

      console.log("defaultStartDate", defaultStartDate);

      // Use Array.prototype.find to locate the desired object
      let foundObject = batches.find(
        (obj) => obj.id === response.fee_voucher.batch._id
      );

      if (foundObject) {
        setbatcheFees(foundObject.batch_fee);
      } else {
        setbatcheFees([]);
      }

      if(response.fee_voucher.additional_discount > 0){
        setFeeDiscountChecked(true)
      }
      setFormInputs({
        batch_id: response.fee_voucher.batch._id,
        batch_fee_id: response.fee_voucher.fee_id,

        additional_discount: response.fee_voucher.additional_discount,
        additional_discount_reason:
          response.fee_voucher.additional_discount_reason,

        // time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),
        // format_time: formattedTime,

        start_date_batch: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
        start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
          ? defaultStartDate.toISOString().split("T")[0]
          : null,
      });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await listBatch();
    console.log("response listBatch", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              batch_fee: batch.batch_fee ? batch.batch_fee : [],
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      // if (batches.length > 0) {
      //   setbatcheFees(batches[0].batch_fee);
      //   console.log('batches[0].batch_fee  _test _test 2',batches[0].batch_fee);
      // }
      console.log("batches  _test", batches);
      console.log("batches[0]  _test", batches[0]);
      console.log("batches[0].batch_fee  _test", batches[0].batch_fee);
      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  useEffect(() => {
    fetchDetailBatch();
  }, []);

  useEffect(() => {
    // Use Array.prototype.find to locate the desired object
    let foundObject = batches.find((obj) => obj.id === formInputs.batch_id);

    if (foundObject) {
      setbatcheFees(foundObject.batch_fee);
    } else {
      setbatcheFees([]);
    }
    console.log("formInputs _formInputs", formInputs);
  }, [formInputs]);

  if (isLoading) {
    return <CircularLoader />;
  }

  return (
    <Page title="Edit Voucher">
      <div className="container col-10">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit Voucher
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Batch"
                  select
                  value={formInputs.batch_id}
                  onChange={handleChange2}
                  name="batch_id"
                  SelectProps={{ multiple: false }}
                  disabled
                  // style={myStyle}
                >
                  {batches.map((batch) => (
                    <MenuItem key={batch.id} value={batch.id}>
                      {capitalizeFirstLetter(batch.name)}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <TextField
                  fullWidth
                  label="Batch Fee"
                  select
                  value={formInputs.batch_fee_id}
                  onChange={handleChange}
                  name="batch_fee_id"
                  SelectProps={{ multiple: false }}
                  disabled
                >
                  {batcheFees.map((batcheFee) => (
                    <MenuItem key={batcheFee._id} value={batcheFee._id}>
                      {batcheFee.fee_title} ({batcheFee.fee})
                    </MenuItem>
                  ))}
                </TextField> */}
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Due Date *"
                    format="YYYY-MM-DD"
                    // value={formInputs?.start_date_batch}
                    value={dayjs(formInputs.start_date_batch)} // Convert the default date to a Day.js object
                    onChange={handleDateChange}
                    // minDate={dayjs()} // Set the minimum date to today
                    // maxDate={dayjs()} // Set the maximum date to today's date
                    // renderInput={(params) => <TextField {...params} />}
                    sx={{
                      width: "100%", // Set the width to 100% to take up the full available width
                    }}
                  />
                </LocalizationProvider>
                {feeDiscountChecked ? (
                  <TextField
                    fullWidth
                    label="Additional Discount"
                    value={formInputs.additional_discount}
                    onChange={handleChange}
                    name="additional_discount"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R.S</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      pattern: "[0-9]*", // Only allow numeric characters
                      // maxLength: 10, // Limit the input to 10 characters
                      // title: "Please enter a valid 10-digit phone number",
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={feeDiscountChecked}
                        onChange={handleCheckboxFeeChange}
                      />
                    }
                    label="Additional Fee Discount"
                  />
                </FormGroup>
              </Stack>

              {feeDiscountChecked ? (
                <TextField
                  fullWidth
                  label="Additional Discount Reason"
                  value={formInputs.additional_discount_reason}
                  onChange={handleChange}
                  name="additional_discount_reason"
                  multiline // Enable multiline
                  rows={3} // Set the number of rows to 3
                  required
                  // required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              ) : (
                ""
              )}
              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Edit;
