// @mui
import PropTypes from "prop-types";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { Box, Card, Paper, Tooltip, Typography, Zoom } from "@mui/material";
// utils
import { fShortenNumber } from "src/utils";
// components
import Iconify from "../../../components/Iconify";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterAndtextEmptyToReturnHyphen,
  truncateString,
} from "src/utils/formatText";
import { formatDateWithOutDay, formatTime24Hours } from "src/utils/formatTime";
// import { useTheme } from '@emotion/react';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

SummaryCard6.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title1: PropTypes.string.isRequired,
  count1: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function SummaryCard6({
  title1,
  count1,
  cardBGColor,
  cardTextColor,
  row,
  index,
  iconBGColor,
  iconTextColor,

  icon,
  color = "primary",
  sx,
  ...other
}) {
  const theme = useTheme(); // Use useTheme hook to access the theme

  console.log("row   _____row_____", row);
  return (
    <div className="border-card1">
      {/* <Paper elevation={1}  > */}
      <Card
        sx={{
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'space-between', // To push the IconWrapperStyle to the right
          //   py: 1,
          //   boxShadow: 1,
          textAlign: "center",
          color: cardTextColor,
          bgcolor: cardBGColor,
          borderRadius: 0,
          mb: 1,
          ...sx,
          padding: "15px",
          paddingTop: "0px",
        }}
        {...other}
        className="shadowBox-0"
      >
        <div
          className="flex-container"
          style={{
            marginLeft: "-12px",
            marginTop: "5px",
            marginBottom: " 5px",
          }}
        >
          <Tooltip
            TransitionComponent={Zoom}
            title={
              row?.name.length > 45 ? capitalizeFirstLetter(row?.name) : ""
            }
            arrow
          >
            <div className="left-div">
              {" "}
              <Typography
                variant="p"
                sx={{
                  textAlign: "start",
                  fontWeight: 600,
                  textDecoration: "none !important",
                  color: "#666",
                }}
              >
                {/* {capitalizeFirstLetter(row?.name)} */}
                {capitalizeFirstLetterAndtextEmptyToReturnHyphen(
                  truncateString(row?.name, 45)
                )}
              </Typography>
            </div>
          </Tooltip>

          <div className="center-container">
            <div className="center-div"></div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-4 border-card"
            style={{
              textDecoration: "none !important",
              // color: "#666",
              padding: "5px",
              //  paddingLeft: "7px",
            }}
          >
            Students
            <br />
            <div className="batch_info_sub_text">{row.student_count}</div>
          </div>
          <div className="col-4  border-card-0">
            <div
              className="row border-card "
              style={{ padding: "3px", paddingLeft: "7px" }}
            >
              {formatDateWithOutDay(row.start_date_batch)}
            </div>
            <div
              className="row border-card"
              style={{ padding: "3px", paddingLeft: "14px" }}
            >
              {formatTime24Hours(row.start_time_class)}
            </div>
          </div>
          <div className="col-4 border-card-0">
            <div
              className="row border-card"
              style={{ padding: "3px", paddingLeft: "7px" }}
            >
              {formatDateWithOutDay(row.end_date_batch)}
            </div>
            <div
              className="row border-card"
              style={{ padding: "3px", paddingLeft: "14px" }}
            >
              {formatTime24Hours(row.end_time_class)}
            </div>
          </div>
        </div>

        {/* row End */}
      </Card>
      {/* </Paper> */}
    </div>
  );
}
