import { filter } from "lodash";
import React from "react";
import { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Icon,
  Tooltip,
  Badge,
  Checkbox,
  IconButton,
  AppBar,
  Toolbar,
} from "@mui/material";
// components
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
  CircularLoader,
} from "src/components";
import StudentListHead from "./components/StudentListHead";
import StudentListToolbar from "./components/StudentListToolbar";
import StudentMoreMenu from "./components/StudentMoreMenu";
// mock

//
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "src/config/config";

import { _get_students, _listStudentSearchWithPagination, _listStudentWithPagination, listStudent, listStudentSearch } from "src/DAL/student";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import FilterStudent from "./components/FilterStudent";
import { useSnackbar } from "notistack";
import moment from "moment";
import StudentDetail from "./StudentDetail";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import {
  capitalizeFirstLetter,
  textEmptyToReturnHyphen,
} from "src/utils/formatText";
import { useAppContext } from "src/hooks";
import NoAccessData from "src/components/GeneralComponents/NoAccessData";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";

const style = {
  width: "100%",
  minWidth: "100%",
  height: "100%",
  maxHeight: "89vh", // Set a maximum height for the modal
  overflowY: "auto", // Enable vertical scrolling
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};
const style2 = {
  width: "70%",
  height: "90vh",
  minWidth: "70%",
  // maxHeight: "80vh", // Set a maximum height for the modal

  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 0,
  overflow: "hidden", // Hide vertical scroll
};
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: "number", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "last_name", label: "Last Name", alignRight: false },
  // { id: "email", label: "Email", alignRight: false },
  // { id: "image", label: "Image", alignRight: false },

  // { id: "batch", label: "Batch", alignRight: false },
  { id: "father_name", label: "Father Name", alignRight: false },
  // { id: "courses", label: "Courses", alignRight: false },
  { id: "contact_number", label: "Phone", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  // { id: "cnic", label: "CNIC", alignRight: false },
  { id: "time", label: "Time Preference", alignRight: false },
  { id: "gender", label: "Gender", alignRight: false },

  // {
  //   id: "registration_fee",
  //   label: "Reg Fee",
  //   alignRight: false,
  // },
  // { id: "total_fee", label: "Course Fee", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const getIcon = (name) => <Icon icon={name} width={14} height={14} />;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.batch[0].name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.courses[0].title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UnassignedStudents() {
  const myStyle = {
    textTransform: "capitalize",
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState("asc");
  
  const [selected, setSelected] = useState([]);
  
  const [orderBy, setOrderBy] = useState(" ");
  
  const [filterName, setFilterName] = useState("");
  
 
  const [reset, setReset] = useState(0);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [rowsPerPageDefault, setRowsPerPageDefault] = useState(50);
  const [count, setCount] = useState(0);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [searchText, setSearchText] = useState("");

  const [drawerState, setDrawerState] = useState(false);
  const [formType, setFormType] = useState("");
  const myElementRef = useRef(null);

  const [trashStudentCount, setTrashStudentCount] = useState(0);
  const [freezeStudentCount, setFreezeStudentCount] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [selectedRowStudent, setselectedRowStudent] = useState("");

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const handleCloseModal = () => setOpenModal(false);

  function handleOpenModal(selectedRow) {
    setOpenModal(true);
    setselectedRowStudent(selectedRow);
  }

  function handleStudentsUpdate(updatedStudents) {
    setStudents(updatedStudents);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  }

  function formatTime(startTime, endTime) {
    if (startTime && endTime && startTime !== "") {
      // Parse the time strings using moment
      const startMoment = moment(startTime, "HH:mm");
      const endMoment = moment(endTime, "HH:mm");

      // Format the start time and end time with AM/PM
      const formattedStartTime = startMoment.format("hh:mm A");
      const formattedEndTime = endMoment.format("hh:mm A");

      const formattedTime = `${formattedStartTime} to ${formattedEndTime}`;

      return formattedTime;
    } else {
      return " _ _ ";
    }
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log("newSelected ___test", newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    if (value === -1) {
      // Show all rows
      setRowsPerPage(count);
      setRowsPerPageDefault(count);
    } else {
      // Show the selected number of rows
      setRowsPerPage(parseInt(value, 10));
      setRowsPerPageDefault(parseInt(value, 10));
      setPage(0);
    }
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const filteredStudents = applySortFilter(
    students,
    getComparator(order, orderBy),
    filterName
  );

  // const isUserNotFound = filteredStudents.length === 0;
  const isUserNotFound = count === 0;

  const handleNav = () => {
    navigate("/students/add-student");
  };
  const handleAddFromEnquiry = () => {
    navigate("/students/add-FromEnquiry");
  };
  const handleMoveToTrash = () => {
    navigate("/trash/");
  };
  const handleMoveToFreeze = () => {
    navigate("/freeze-students");
  };
  const handleAssignMultiStudents = () => {
    navigate("/students/assign-multi-students");
  };

  const fetchStudents = async () => {

  console.log("page    ____Test",page);
  console.log("rowsPerPage    ____Test",rowsPerPage);

  // setRowsPerPage(rowsPerPageDefault);
 
    setIsLoading(true);
    const response = await _get_students(page,rowsPerPage);
    console.log("response ____get_students", response);
    if (response.code === 200) {
    //   setTrashStudentCount(response.trash_student_count);
    //   setFreezeStudentCount(response.freeze_student_count);

    //   setCount(response.count);
       
    //   if(rowsPerPage!==50&&rowsPerPage!==100&&rowsPerPage!==150&&rowsPerPage!==response.count){
    //     // setRowsPerPage(response.count);
    //     setRowsPerPage(50);
    //     setPage(0);
    //   }
      const students = response.unassign_batch_students.map((student, index) => ({
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: capitalizeFirstLetter(student.father_name),
        address: student.address,
        reg_type: student.reg_type ? student.reg_type : "",
        contact_number: student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        total_fee: student.total_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        user_id: student.user_id ? student.user_id : "0",
        number: index + 1,

        time: formatTime(
          student.time_preference ? student.time_preference.start_time : "",
          student.time_preference ? student.time_preference.end_time : ""
        ),
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const SearchStudents = async (searchText) => {
    // const response = await listStudent();
    // setIsLoading(true);
    // setPage(0);
        // setRowsPerPage(2); 
        
  console.log("page    ____Test",page);
  console.log("rowsPerPage    ____Test",rowsPerPage);

 
 
 

    const response = await _listStudentSearchWithPagination(searchText,page,rowsPerPage);
    console.log("response _listStudentSearchWithPagination",response);
    console.log("page _listStudentSearchWithPagination",page);
    console.log("searchText _listStudentSearchWithPagination",searchText);
    console.log("rowsPerPage _listStudentSearchWithPagination",rowsPerPage);
    console.log("count _listStudentSearchWithPagination",count);
    if (response.code === 200) {
    //  setPage(1);
        // setRowsPerPage(2);

      setCount(response.count);
      setTrashStudentCount(response.trash_student_count);
      // if(rowsPerPage > response.count){
      //   // setPage(1);
      //   setRowsPerPage(response.count);
      // }

      const students = response.students.map((student, index) => ({
        id: student._id,
        name: student.name,
        // last_name: student.last_name,
        email: student.email,

        referred_by: student.referred_by,
        course_fee: student.course_fee,
        actual_fee: student.actual_fee,
        father_name: capitalizeFirstLetter(student.father_name),
        address: student.address,
        reg_type: student.reg_type ? student.reg_type : "",
        contact_number: student.contact_number,

        // image: student.profile_image,
        status: student.status,
        gender: student.gender,
        decided_fee: student.decided_fee,
        total_fee: student.total_fee,
        cnic: student.cnic,
        registration_fee: student.total_registration_fee,
        batch: student.batch ? student.batch : "",
        courses: student.courses ? student.courses : "",
        user_id: student.user_id ? student.user_id : "0",
        number: index + 1,
        time: formatTime(
          student.time_preference ? student.time_preference.start_time : "",
          student.time_preference ? student.time_preference.end_time : ""
        ),
      }));

      setStudents(students);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      // Handle empty search text
      console.log("Search text is empty");
      setIsLoading(true);
      fetchStudents();
      // Perform actions specific to empty search text
      // For example, display a message or clear the search results
    } else {
      // Perform search logic with the searchText
      setIsLoading(true);
      // Perform search logic with the searchText
      console.log("Search:", searchText);
      console.log("Search: typeOf", typeof searchText);
      SearchStudents(searchText);
    }
  };

  const handleReset = () => {
    setSearchText("");
    // SearchStudents("");
    fetchStudents();
    // Reset any search results or filters
    console.log("Reset");
  };

  // Define the debounce function
  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  // Create the debounced version of SearchStudents outside the component
  const debouncedSearchStudents = debounce(SearchStudents, 1000); // 1 second (1000ms) delay

  const handleInputChange = (event) => {
    setPage(0);
    setSearchText(event.target.value);
    debouncedSearchStudents(event.target.value); // Call the debounced version of SearchStudents
  };

  // const handleInputChange = (event) => {
  //   setSearchText(event.target.value);
  // };

  const handleOpenDrawerFilter = () => {
    setDrawerState(true);
    setFormType("Filters");
  };

  const handleOpenEditDrawer = () => {};
  const handleCloseEditDrawer = () => {
    setDrawerState(false);
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  useEffect(() => {
  
    // if(searchText.trim() === ""){
    //   fetchStudents();
    // } else if(searchText.trim() !== ""){
    //   SearchStudents(searchText);
    // }
 
  }, [page, rowsPerPage, reset]);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Unassigned Students">
      <Container maxWidth="xl">
        {/* view */}

          {privilegesTypeCheckAndShowOrNot(
            get_user_profile,
            get_user_privilege_access,
            "student",
            "view"
          ) ?(

          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4.5}
              mt={2}
            >
              <Typography variant="h4">Unassigned Students</Typography>
              <div>
              {privilegesTypeCheckAndShowOrNot(
            get_user_profile,
            get_user_privilege_access,
            "student",
            "delete"
          ) ?(
            <>  </>
        //        <Button
        //     onClick={handleMoveToTrash}
        //     variant="contained"
        //     color="error" // Set the color to "secondary" for red color
        //     startIcon={<Iconify icon="bi:trash" />}
        //     style={{ marginLeft: "10px", marginRight: "10px" }}
        //   >
        //     ( {trashStudentCount} ) Trash
        //   </Button>
          ):("")}

          {privilegesTypeCheckAndShowOrNot(
            get_user_profile,
            get_user_privilege_access,
            "student",
            "edit"
          ) ?(
            <>
                {/* <Button
                  onClick={handleAssignMultiStudents}
                  variant="contained"
                  color="success" // Set the color to "secondary" for red color
                  startIcon={<Iconify icon="icons8:student" />}
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    color: "white",
                  }}
                >
                  Assign Batch
                </Button> */}
                </>
          ):("")}
                {/* <Button
              onClick={handleMoveToFreeze}
              variant="contained"
              color="info" // Set the color to "secondary" for red color
              startIcon={<Iconify icon="mdi:freeze-advisory" />}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              ( {freezeStudentCount} ) Freeze
            </Button> */}

              

 
{privilegesTypeCheckAndShowOrNot(
            get_user_profile,
            get_user_privilege_access,
            "student",
            "add"
          ) ?(
                  <>
                    <Button
                      onClick={handleAddFromEnquiry}
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      Add From Equiry
                    </Button>
                    <Button
                      onClick={handleNav}
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      New Student
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Stack>

            <Card
              sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 6 }}
            >
              {/* <StudentListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          /> */}

              <Scrollbar>
                {/* <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{ padding: "10px" }}
                > */}
                  {/* <TextField
                    label="Search"
                    value={searchText}
                    onChange={handleInputChange}
                  /> */}
                  {/* <Button variant="contained" onClick={handleSearch}>
                Search
              </Button> */}
                  {/* <Button variant="contained" onClick={handleReset}>
                    Reset
                  </Button> */}

                  {/* <Button variant="contained" onClick={handleOpenDrawerFilter}>
                <span className="text-capitalize">Filters &nbsp;</span>
                {<Iconify icon="material-symbols:filter-list" />}
              </Button> */}
                {/* </Stack> */}

                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <StudentListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={count}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {
                      // filteredStudents
                      //   .slice(
                      //     page * rowsPerPage,
                      //     page * rowsPerPage + rowsPerPage
                      //   )
                      students.map((row, index) => {
                          const {
                            id,
                            name,
                            // last_name,
                            email,
                            // image,
                            status,
                            batch,
                            courses,
                            gender,
                            decided_fee,
                            total_fee,
                            registration_fee,
                            cnic,
                            number,
                            referred_by,
                            course_fee,
                            actual_fee,
                            father_name,
                            address,
                            reg_type,
                            contact_number,
                            time,
                          } = row;
                          const isItemSelected = selected.indexOf(id) !== -1;
                          // let number = index + 1;

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {/* <Checkbox
                              checked={isItemSelected}
                              onChange={(event) =>
                                handleClick(event, id)
                              }
                            /> */}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {/* {number} */}
                                {index+1+(page * rowsPerPage)}
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                            {name}
                          </TableCell> */}

                              <TableCell align="left">
                                <div
                                  className="on_click_text"
                                  // style={{
                                  //   cursor: "pointer",
                                  // }}
                                  onClick={() => handleOpenModal(row.id)}
                                >
                                  {capitalizeFirstLetter(name)}
                                </div>
                              </TableCell>

                              {/* <Typography variant="subtitle2" noWrap>
                              {first_name}
                            </Typography> */}
                              {/* <TableCell align="left" style={myStyle}>
                            {last_name}
                          </TableCell> */}
                              {/* <TableCell align="left">{email}</TableCell> */}
                              {/* <TableCell align="left">
                            {" "}
                            <Avatar alt={first_name} src={s3baseUrl + image} />
                          </TableCell> */}

                              {/* <TableCell align="left" style={myStyle}>
                            {batch.length > 0
                              ? batch
                                  .map((batch, index) => {
                                    return batch !== null ? batch.name : "N/A";
                                  })
                                  .join(", ")
                              : "N/A"}
                          </TableCell> */}
                              <TableCell align="left">
                                {textEmptyToReturnHyphen(father_name)}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                +92{contact_number}
                              </TableCell>

                              <TableCell align="left">
                                {textEmptyToReturnHyphen(email)}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {time}
                              </TableCell>

                              <TableCell align="left" style={myStyle}>
                                {gender === 1 ? "Male" : "Female"}
                              </TableCell>

                              {/* <TableCell align="left" style={myStyle}>
                            {registration_fee}
                          </TableCell>

                          <TableCell align="left" style={myStyle}>
                            {total_fee}
                          </TableCell> */}

                              <TableCell align="left">
                                <Label
                                  variant="outlined"
                                  color={
                                    (status === 2 && "error") ||
                                    (status === 1 && "success") ||
                                    "error"
                                  }
                                >
                                  {status === 2
                                    ? "Inactive"
                                    : status === 1
                                    ? "Active"
                                    : status === 3
                                    ? "Trash"
                                    : "Not Set"}
                                  {/* {/ {sentenceCase(status)} /} */}
                                </Label>
                              </TableCell>
                              {privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "student",
                                "edit"
                              ) ||
                              privilegesTypeCheckAndShowOrNot(
                                get_user_profile,
                                get_user_privilege_access,
                                "student",
                                "delete"
                              ) ? (
                              <TableCell align="right">
                                <StudentMoreMenu
                                  row={row}
                                  students={students}
                                  onStudentsUpdate={handleStudentsUpdate}
                                  trashStudentCount={trashStudentCount}
                                  setTrashStudentCount={setTrashStudentCount}
                                />
                              </TableCell>
                                ) : (
                                  <TableCell align="right"></TableCell>
                                )} 
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={10} />
                        </TableRow>
                      )}
                    </TableBody>

                    {students.length <=0 && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  150,
                  { value: students.length, label: "All" },
                ]}
                component="div"
                // count={students.length}
                count={students.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : (
          <NoAccessData />
        )}
      </Container>

      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          // <h1>hello</h1>
          <FilterStudent
            onCloseDrawer={handleCloseEditDrawer}
            isOpenDrawer={drawerState}
            students={students}
            setStudents={setStudents}

            // filterState={filterState}
            // setFilterState={setFilterState}
            // taskListData={taskListData}
            // setTaskListData={setTaskListData}
            // messageText={messageText}
            // setMessageText={setMessageText}
            // setDrawerState={setDrawerState}
          />
        }
      />
      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle="Student Detail"
        componentToPassDown={<StudentDetail studentID={selectedRowStudent} />}
      />
    </Page>
  );
}
