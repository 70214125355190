import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { addStudent } from "src/DAL/student";
import { listActiveBatch } from "src/DAL/batch";
import { _add_enquiry_form } from "src/DAL/enquirystudent";
import { listCourse } from "src/DAL/course";
import { _add_brand, _list_brand } from "src/DAL/brand";
import {
  _add_asset_category,
  _list_asset_category,
} from "src/DAL/asset_category";
import { _list_vendor } from "src/DAL/vendor";
import moment from "moment";
import { _add_company_asset } from "src/DAL/company_asset";
import { _list_role } from "src/DAL/role";
import { capitalizeFirstLetter } from "src/utils/formatText";
import { _signup_customer } from "src/DAL/customer";
import CustomPasswordField from "src/components/GeneralComponents/CustomPasswordField";

const Add = () => {
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState();
  const [show, setShow] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [roles, setRoles] = useState([]);
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [password, setPassword] = useState("ddit_tech");

  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [formInputs, setFormInputs] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    post_code: "",
    role: "",
  });
  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  function handleFile(e) {
    const file = e.target.files[0];

    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setShow(true);
  }
  function handleDeleteImage() {
    setShow(false);
  }

  const handleChangeWithCapitalize = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: capitalizeFirstLetter(value),
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleChangePhone = (e) => {
    const { name, value } = e.target;
  
    // Check if the first digit is 3
    if (name === "contact_number" && value.length > 0 && value[0] !== "3") {
      enqueueSnackbar("Contact Number must start with the Digit 3 ", { variant: "error" });
      return; // Do not update state if the condition is not met
    }
  
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  
  const handleDateChange = (date) => {
    // const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // const formattedDate = dayjs(date).format("DD/MM/YYYY");
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormInputs((values) => ({ ...values, dob: formattedDate }));
  };
  const handleChange2 = (event, newValue) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      course: newValue ? newValue._id : "",
    }));
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    let error = "";
    console.log("formInputs", formInputs);

    let postData = {
      email: formInputs.email,
      password: password,
      first_name: formInputs.first_name,
      last_name: formInputs.last_name,
      contact_number: formInputs.contact_number,
      post_code: formInputs.post_code,
      role: formInputs.role_id,
    };
    console.log("postData", postData);

    setIsLoading(true);
    const result = await _signup_customer(postData);
    console.log("result _signup_customer  _add __test", result);

    if (result.code === 200) {
      setIsLoading(false);
      enqueueSnackbar("Added Successfully!", { variant: "success" });
      navigate("/managements");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchActiveRoles = async () => {
    setIsLoading(true);
    const response = await _list_role();

    // list_brand

    console.log("response _list_brand", response);
    if (response.code === 200) {
      const roles = response.roles.map((role, index) => ({
        ...role,
        id: role._id,
        title: role.title,
        status: role.status,
        number: index + 1,
      }));
      // setStudents(students.reverse());
      if (roles.length > 0) {
        setFormInputs((prevInputs) => ({
          ...prevInputs,
          role_id: roles[0].id,
        }));
      }

      setRoles(roles);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchActiveRoles();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Add Management">
        <div className="container px-4 mb-5 container_in_all_pages">
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <h4 className="title_heading_in_all_pages_top_left"> Add Management </h4>
        </Grid>


        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="First Name"
                  value={formInputs.first_name}
                  onChange={handleChangeWithCapitalize}
                  name="first_name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  value={formInputs.last_name}
                  onChange={handleChangeWithCapitalize}
                  name="last_name"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="Email"
                  label="Email address"
                  value={formInputs.email}
                  onChange={handleChange}
                  name="email"
                  autoComplete="off"
                  required
                  inputProps={{
                    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
                    title: "Please enter a valid email address",
                  }}
                />

                <CustomPasswordField
                  passwordVariable={password}
                  setPasswordVariable={setPassword}
                  passwordLabel="Password"
                  Required={true}
                />

                {/* <TextField
                  fullWidth
                  label="Password"
                  value={formInputs.password}
                  onChange={handleChange}
                  name="password"
                  required
                  autoComplete="new-password"
                  //   autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Iconify
                            icon={
                              showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formInputs.contact_number}
                  required
                  onChange={handleChangePhone}
                  name="contact_number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+92</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    pattern: "[0-9]*", // Only allow numeric characters
                    maxLength: 10, // Limit the input to 10 characters
                    title: "Please enter a valid 10-digit phone number",
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Address"
                  value={formInputs.post_code}
                  onChange={handleChangeWithCapitalize}
                  name="post_code"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                  // inputProps={{
                  //   pattern: "[0-9]*", // Only allow numeric characters
                  //   // maxLength: 10, // Limit the input to 10 characters
                  //   title: "Please enter a valid Post Code",
                  // }}
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Role"
                  select
                  value={formInputs.role_id}
                  onChange={handleChange}
                  name="role_id"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {capitalizeFirstLetter(role.title)}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Add
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default Add;
