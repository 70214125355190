import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  AddUser,
  Dashboard,
  ForgotPassword,
  Login,
  Page404,
  Register,
  Settings,
  Users,
  Instructors,
  AddInstructor,
  EditInstructor,
  Courses,
  AddCourse,
  EditCourse,
  Batches,
  AddBatch,
  EditBatch,
  Students,
  AddStudent,
  EditStudent,
  Pages,
  AddPage,
  EditPage,
  Faqs,
  AddFaq,
  EditFaq,
  Testimonials,
  AddTestimonial,
  EditTestimonial,
  EditProfile,
  Profile,
  ContactList,
  Section,
  AddSection,
  EditSection,
  UpdatePage,
  EnquiryStudents,
  AddEnquiryStudent,
  EditEnquiryStudent,
  CollectFee,
  AddInvoiceFee,
  InvoiceFeeList,
  EditInvoiceFee,
  AddFromEnquiry,
  PrintInvoice,
  MoveBatchStudents,
  Trash,
  AddAttendance,
  TimePreferenceList,
  AddTimePreference,
  EditTimePreference,
  CourseStudents,
  FreezeStudents,
  AssetCategoryList,
  AddAssetCategory,
  EditAssetCategory,
  BrandList,
  AddBrand,
  EditBrand,
  VendorList,
  AddVendor,
  EditVendor,
  BankList,
  AddBank,
  EditBank,
  PersonList,
  AddPerson,
  EditPerson,
  TransactionList,
  AddTransaction,
  EditTransaction,
  PrintTransaction,
  CompanyAssetList,
  AddCompanyAsset,
  EditCompanyAsset,
  EventList,
  EditEvent,
  AddEvent,
  InstructorDetail,
  CourseDetail,
  StudentDetail,
  VoucherList,
  AddVoucher,
  EditVoucher,
  RoleList,
  AddRole,
  EditRole,
  CustomerList,
  AddCustomer,
  EditCustomer,
 
} from "./pages";
import Contact from "./pages/Contact-Us/Contact";
import DetailBatch from "./pages/Batches/List/DetailBatch";
import MoveStudent from "./pages/Batches/MoveStudent/MoveStudent";
import UpdateStudentFee from "./pages/Students/UpdateStudentFee/UpdateStudentFee";
import Attendance from "./pages/Attendance/List/Attendance";
import StudentAttendanceDetail from "./pages/Attendance/List/StudentAttendanceDetail";
import StudentAttendanceDelete from "./pages/Attendance/List/StudentAttendanceDelete";
import UploadAttendance from "./pages/Attendance/List/UploadAttendance";
import FreezeBatch from "./pages/Batches/List/FreezeBatch";
import MoveCourseStudent from "./pages/CourseStudents/MoveCourseStudent/MoveCourseStudent";
import UnFreezeStudent from "./pages/FreezeStudents/UnFreezeStudent/UnFreezeStudent";
import FreezeFromStudent from "./pages/Students/FreezeFromStudent/FreezeFromStudent";
import AssignBatch from "./pages/Students/AssignBatch/AssignBatch";
import Wallet from "./pages/Students/Wallet/Wallet";
import TransactionDetails from "./pages/Transaction/TransactionDetails/TransactionDetails";
import StudentTransaction from "./pages/Transaction/List/StudentTransaction";
import RefundTransaction from "./pages/Transaction/RefundTransaction/RefundTransaction";
import AssignCompanyAsset from "./pages/CompanyAsset/AssignCompanyAsset/AssignCompanyAsset";
import AssignBatchMultiStudent from "./pages/Students/List/AssignBatchMultiStudent";
import FilterVouchers from "./pages/Vouchers/List/FilterVouchers";
import BatchStudentList from "./pages/Batches/List/BatchStudentList";
import ChangePassword from "./pages/Profile/ChangePassword/ChangePassword";
import LoginAsCustomer from "./pages/LoginAsCustomer/LoginAsCustomer";
import LoginAsInstructor from "./pages/LoginAsInstructor/LoginAsInstructor";
import CustomVoucher from "./pages/Vouchers/List/CustomVoucher";
import Calendar from "./pages/Attendance/List/Calendar";
import BatchStudentMove from "./pages/Batches/BatchStudentMove/BatchStudentMove";
import GenerateInvoice from "./pages/Vouchers/GenerateInvoice/GenerateInvoice";
import AssignmentList from "./pages/Assignments/List/AssignmentList";
import AddAssignment from "./pages/Assignments/Add/AddAssignment";
import AddLeaveRequest from "./pages/LeaveRequest/Add/AddLeaveRequest";
import LeaveRequestList from "./pages/LeaveRequest/List/LeaveRequestList";
import EditLeaveRequest from "./pages/LeaveRequest/Edit/EditLeaveRequest";
import AssignmentStudents from "./pages/Assignments/List/AssignmentStudents";
import EditAssignment from "./pages/Assignments/Edit/EditAssignment";
import BatchStudentFeeEdit from "./pages/Batches/BatchStudentFeeEdit/BatchStudentFeeEdit";
import ExpenseCategoryList from "./pages/ExpenseCategory/List/ExpenseCategoryList";
import EditExpenseCategory from "./pages/ExpenseCategory/Edit/EditExpenseCategory";
import AddExpenseCategory from "./pages/ExpenseCategory/Add/AddExpenseCategory";
import ExpenseList from "./pages/Expenses/List/ExpenseList";
import AddExpense from "./pages/Expenses/Add/AddExpense";
import EditExpense from "./pages/Expenses/Edit/EditExpense";
import ResetPassword from "./pages/Profile/ResetPassword/ResetPassword";
import ExpenseTopUpList from "./pages/ExpenseTopUp/list/ExpenseTopUpList";
import AddExpenseTopUp from "./pages/ExpenseTopUp/add/AddExpenseTopUp";
import EditExpenseTopUp from "./pages/ExpenseTopUp/edit/EditExpenseTopUp";
import CompanyAssetRepairList from "./pages/CompanyAssetRepair/list/CompanyAssetRepairList";
import AddCompanyAssetRepair from "./pages/CompanyAssetRepair/add/AddCompanyAssetRepair";
import EditCompanyAssetRepair from "./pages/CompanyAssetRepair/edit/EditCompanyAssetRepair";
import MoveBatchMultiStudent from "./pages/Batches/List/MoveBatchMultiStudent";
import UnassignedStudents from "./pages/Students/List/UnassignedStudents";
import StudentAttendanceAllBatches from "./pages/Attendance/List/StudentAttendanceAllBatches";
import CalendarView from "./pages/Attendance/List/CalendarView";
import Dashboard2 from "./pages/dashboard/Dashboard2";
import SupportTicketsList from "./pages/SupportTickets/List/SupportTicketsList";
import AddSupportTicket from "./pages/SupportTickets/Add/AddSupportTicket";
import EditSupportTicket from "./pages/SupportTickets/Edit/EditSupportTicket";
import AnnouncementList from "./pages/Announcements/List/AnnouncementList";
import AddAnnouncement from "./pages/Announcements/Add/AddAnnouncement";
import EditAnnouncement from "./pages/Announcements/Edit/EditAnnouncement";
import { useState } from "react";
import ListSlider from "./pages/Sliders/List/ListSlider";
import AddSlider from "./pages/Sliders/Add/AddSlider";
import EditSlider from "./pages/Sliders/Edit/EditSlider";
import CourseOutline from "./pages/Courses/List/CourseOutline";
import CourseReview from "./pages/Courses/List/CourseReview";
import CourseOverview from "./pages/Courses/List/CourseOverview";
import ListTab from "./pages/Tabs/List/ListTab";
import AddTab from "./pages/Tabs/Add/AddTab";
import EditTab from "./pages/Tabs/Edit/EditTab";
import AddCourseRating from "./pages/CourseRating/Add/AddCourseRating";
import EditCourseRating from "./pages/CourseRating/Edit/EditCourseRating";
import ListCourseRating from "./pages/CourseRating/List/ListCourseRating";
import ListWebsiteCourseBenefits from "./pages/WebsiteCourseBenefits/List/ListWebsiteCourseBenefits";
import AddWebsiteCourseBenefits from "./pages/WebsiteCourseBenefits/Add/AddWebsiteCourseBenefits";
import EditWebsiteCourseBenefits from "./pages/WebsiteCourseBenefits/Edit/EditWebsiteCourseBenefits";
import ListMenu from "./pages/Menu/List/ListMenu";
import AddMenu from "./pages/Menu/Add/AddMenu";
import EditMenu from "./pages/Menu/Edit/EditMenu";

// ----------------------------------------------------------------------

export default function Router() {
  const [supportCount, setSupportCount] = useState(0);
  const [announcementCount, setAnnouncementCount] = useState(0);

  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout 
      supportCount={supportCount}  
      setSupportCount={setSupportCount}
      announcementCount={announcementCount}  
      setAnnouncementCount={setAnnouncementCount}
      
      />, 
      children: [
        { path: "dashboard", element: <Dashboard2 /> },
        { path: "dashboard2", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "users/add-user", element: <AddUser /> },
        /////////////
        { path: "freeze-batches", element: <FreezeBatch /> },
        { path: "batches", element: <Batches /> },
        { path: "batches/add-batch", element: <AddBatch /> },
        {
          path: "batches/edit-batch/:id",
          element: <EditBatch />,
        },
        {
          path: "batches/detail-batch/:id",
          element: <DetailBatch />,
        },
        {
          path: "batches/batch-students/:id",
          element: <BatchStudentList />,
        },
        {
          path: "batches/batch-student-move/:id",
          element: <MoveStudent />,
        },
        {
          path: "batches/move-batch-students/:id",
          element: <MoveBatchMultiStudent />,
        },
        {
          path: "batches/move-batch/:id",
          element: <BatchStudentMove />,
        },
        {
          path: "batches/move-student-to-batch/:id",
          element: <MoveStudent />,
        },
        {
          path: "batches/edit-fee/:id",
          element: <BatchStudentFeeEdit />,
        },
        ////////////
        { path: "courses", element: <Courses /> },
        { path: "courses/add-course", element: <AddCourse /> },
        {
          path: "courses/edit-course/:id",
          element: <EditCourse />,
        },
        {
          path: "courses/outline-course/:id",
          element: <CourseOutline />,
        },
        {
          path: "courses/review-course/:id",
          element: <CourseReview />,
        },
        {
          path: "courses/overview-course/:id",
          element: <CourseOverview />,
        },
        {
          path: "courses/detail-course/:id",
          element: <CourseDetail />,
        },
        ///////////////instructors
        { path: "instructors", element: <Instructors /> },
        { path: "instructors/add-instructor", element: <AddInstructor /> },
        {
          path: "instructors/edit-instructor/:id",
          element: <EditInstructor />,
        },
        {
          path: "instructors/detail-instructor/:id",
          element: <InstructorDetail />,
        },
        ///////////////TimePreference
        { path: "time-preference", element: <TimePreferenceList /> },
        {
          path: "time-preference/add-time-preference",
          element: <AddTimePreference />,
        },
        {
          path: "time-preference/edit-time-preference/:id",
          element: <EditTimePreference />,
        },
        /////////////////Students
        { path: "students", element: <Students /> },
        { path: "unassigned-students", element: <UnassignedStudents /> },
        { path: "students/add-student", element: <AddStudent /> },
        { path: "students/add-FromEnquiry", element: <AddFromEnquiry /> },
        {
          path: "students/assign-multi-students",
          element: <AssignBatchMultiStudent />,
        },
        {
          path: "students/assign-batch/:id",
          element: <AssignBatch />,
        },
        {
          path: "students/wallet/:id",
          element: <Wallet />,
        },

        { path: "trash", element: <Trash /> },

        {
          path: "students/edit-student/:id",
          element: <EditStudent />,
        },
        {
          path: "students/detail-student/:id",
          element: <StudentDetail />,
        },

        {
          path: "students/update-student-fee/:id",
          element: <UpdateStudentFee />,
        },
        {
          path: "students/freeze-student/:id",
          element: <FreezeFromStudent />,
        },

        /////////////////Course Students
        { path: "course-students", element: <CourseStudents /> },
        {
          path: "course-students/move-student/:id",
          element: <MoveCourseStudent />,
        },

        /////////////////Freeze Students
        { path: "freeze-students", element: <FreezeStudents /> },
        {
          path: "freeze-students/unfreeze-student/:id",
          element: <UnFreezeStudent />,
        },
        ///////////////// Attendance
        { path: "attendance", element: <Attendance /> },

        { path: "calendar-view", element: <CalendarView /> },

        {
          path: "attendance/detail/:id",
          element: <StudentAttendanceDetail />,
        },
        {
          path: "attendance/detail-batches/:id",
          element: <StudentAttendanceAllBatches />,
        },
        {
          path: "attendance/calendar/:id",
          element: <Calendar />,
        },

        {
          path: "attendance/delete/:id",
          element: <StudentAttendanceDelete />,
        },
        {
          path: "attendance/add-attendance",
          element: <AddAttendance />,
        },
        {
          path: "attendance/upload-attendance",
          element: <UploadAttendance />,
        },

           ///////////////// Assignments
           { path: "assignments", element: <AssignmentList /> },
           {
            path: "assignments/add-assignment",
            element: <AddAssignment />,
          },
          {
            path: "assignments/edit-assignment/:id",
            element: <EditAssignment />,
          },
          {
            path: "assignments/assignment-students/:id",
            element: <AssignmentStudents />,
          },


              ///////////////// Leave Request
              { path: "leave-requests", element: <LeaveRequestList /> },
              {
               path: "leave-requests/add-leave",
               element: <AddLeaveRequest />,
             },
             {
              path: "leave-requests/edit-leave/:id",
              element: <EditLeaveRequest />,
            },

                ///////////////// Expense Top Up
                { path: "top-up", element: <ExpenseTopUpList /> },
                {
                 path: "top-up/add-top-up",
                 element: <AddExpenseTopUp/>,
               },
               {
                path: "top-up/edit-top-up/:id",
                element: <EditExpenseTopUp />,
              },

              ///////////////// Expense Category
              { path: "expense-category", element: <ExpenseCategoryList /> },
              {
               path: "expense-category/add-category",
               element: <AddExpenseCategory />,
             },
             {
              path: "expense-category/edit-category/:id",
              element: <EditExpenseCategory />,
            },

              ///////////////// Expenses
              { path: "expenses", element: <ExpenseList /> },
              {
               path: "expenses/add-expense",
               element: <AddExpense/>,
             },
             {
              path: "expenses/edit-expense/:id",
              element: <EditExpense />,
            },

        ///////////////// Enquiry
        { path: "enquiry-students", element: <EnquiryStudents /> },
        {
          path: "enquiry-students/add-student",
          element: <AddEnquiryStudent />,
        },
        {
          path: "enquiry-students/edit-student/:id",
          element: <EditEnquiryStudent />,
        },

           ///////////////// Role
           { path: "role", element: <RoleList /> },
           {
             path: "role/add-role",
             element: <AddRole />,
           },
           {
             path: "role/edit-role/:id",
             element: <EditRole />,
           },

        ///////////////// Bank
        { path: "banks", element: <BankList /> },
        {
          path: "banks/add-bank",
          element: <AddBank />,
        },
        {
          path: "banks/edit-bank/:id",
          element: <EditBank />,
        },

        ///////////////// Person
        { path: "persons", element: <PersonList /> },
        {
          path: "persons/add-person",
          element: <AddPerson />,
        },
        {
          path: "persons/edit-person/:id",
          element: <EditPerson />,
        },

        ///////////////// Asset Category
        { path: "asset-category", element: <AssetCategoryList /> },
        {
          path: "asset-category/add-category",
          element: <AddAssetCategory />,
        },
        {
          path: "asset-category/edit-category/:id",
          element: <EditAssetCategory />,
        },
        ///////////////// Company Asset
        { path: "company-asset", element: <CompanyAssetList /> },
        {
          path: "company-asset/add-asset",
          element: <AddCompanyAsset />,
        },
        {
          path: "company-asset/edit-asset/:id",
          element: <EditCompanyAsset />,
        },
        {
          path: "company-asset/assign-asset/:id",
          element: <AssignCompanyAsset />,
        },

        {
          path: "company-asset/repair-details-asset/:id",
          element: <CompanyAssetRepairList />,
        },
        {
          path: "company-asset/add-repair-details-asset/:id",
          element: <AddCompanyAssetRepair />,
        },
        {
          path: "company-asset/edit-repair-details-asset/:id",
          element: <EditCompanyAssetRepair />,
        },

        ///////////////// Asset Category
        { path: "asset-category", element: <AssetCategoryList /> },
        {
          path: "asset-category/add-category",
          element: <AddAssetCategory />,
        },
        {
          path: "asset-category/edit-category/:id",
          element: <EditAssetCategory />,
        },
        ///////////////// Event
        { path: "event", element: <EventList /> },
        {
          path: "event/add-event",
          element: <AddEvent />,
        },
        {
          path: "event/edit-event/:id",
          element: <EditEvent />,
        },

        ///////////////// Announcement
        { path: "announcements", element: <AnnouncementList 
        announcementCount={announcementCount}  
        setAnnouncementCount={setAnnouncementCount}
        /> },
        {
          path: "announcements/add-announcement",
          element: <AddAnnouncement />,
        },
        {
          path: "announcements/edit-announcement/:id",
          element: <EditAnnouncement
          supportCount={supportCount}
          setSupportCount={setSupportCount}
          />,
        },

        ///////////////// SupportTickets
        { path: "support-tickets", element: <SupportTicketsList supportCount={supportCount}  setSupportCount={setSupportCount}  /> },
        {
          path: "support-tickets/add-ticket",
          element: <AddSupportTicket />,
        },
        {
          path: "support-tickets/edit-ticket/:id",
          element: <EditSupportTicket supportCount={supportCount}  setSupportCount={setSupportCount}  />,
        },

        ///////////////// Brand
        { path: "brand", element: <BrandList /> },
        {
          path: "brand/add-brand",
          element: <AddBrand />,
        },
        {
          path: "brand/edit-brand/:id",
          element: <EditBrand />,
        },

        ///////////////// Vendor
        { path: "vendor", element: <VendorList /> },
        {
          path: "vendor/add-vendor",
          element: <AddVendor />,
        },
        {
          path: "vendor/edit-vendor/:id",
          element: <EditVendor />,
        },


          ///////////////// managements
          { path: "managements", element: <CustomerList /> },
          {
            path: "managements/add-management",
            element: <AddCustomer />,
          },
          {
            path: "managements/edit-management/:id",
            element: <EditCustomer />,
          },
          
          
        ///////////////// invoice Fee
        { path: "invoice-fee/", element: <InvoiceFeeList /> },
        { path: "invoice-fee/add-invoice", element: <AddInvoiceFee /> },
        {
          path: "invoice-fee/edit-invoice/:id",
          element: <EditInvoiceFee />,
        },

        {
          path: "invoice-fee/print-invoice/:id",
          element: <PrintInvoice />,
        },

        ///////////////// Transaction
        { path: "transaction/", element: <TransactionList /> },
        { path: "transaction/add-transaction", element: <AddTransaction /> },
        {
          path: "transaction/edit-transaction/:id",
          element: <EditTransaction />,
        },
        {
          path: "transaction/student-transaction/:id",
          element: <StudentTransaction />,
        },
        {
          path: "transaction/detail-transaction/:id",
          element: <TransactionDetails />,
        },
        {
          path: "transaction/refund-transaction/:id",
          element: <RefundTransaction />,
        },

        {
          path: "transaction/print-transaction/:id",
          element: <PrintTransaction />,
        },

        ///////////////// Vouchers
        // { path: "vouchers/", element: <VoucherList /> },
        { path: "vouchers/list-voucher", element: <FilterVouchers /> },
        { path: "vouchers/add-voucher", element: <AddVoucher /> },
        { path: "vouchers/custom-voucher", element: <CustomVoucher /> },
        {
          path: "vouchers/edit-voucher/:id",
          element: <EditVoucher />,
        },
        {
          path: "vouchers/generate-invoice/:id",
          element: <GenerateInvoice />,
        },
        // {
        //   path: "vouchers/detail-voucher/:id",
        //   element: <VoucherDetails />,
        // },

        ///////////////// Collect Fee
        //  { path: "collect-fee", element: <CollectFee /> },

        //////////
        { path: "contact", element: <Contact /> },
        { path: "contactlist", element: <ContactList /> },

        ///////////////
        { path: "pages", element: <Pages /> },
        { path: "pages/add-page", element: <AddPage /> },
        {
          path: "pages/edit-page/:id",
          element: <EditPage />,
        },
        //////////

        ///////////////
        { path: "menu", element: <ListMenu /> },
        { path: "menu/add-menu", element: <AddMenu/> },
        {
          path: "menu/edit-menu/:id",
          element: <EditMenu />,
        },
        //////////

        { path: "testimonials/:id", element: <Testimonials /> },
        { path: "testimonials/add-testimonial", element: <AddTestimonial /> },

        {
          path: "testimonials/edit-testimonial/:id",
          element: <EditTestimonial />,
        },

        { path: "sliders/:id", element: <ListSlider /> },
        { path: "sliders/add-slider/:id", element: <AddSlider /> },
        {
          path: "sliders/edit-slider/:id",
          element: <EditSlider />,
        },

        
        { path: "tabs/:id", element: <ListTab /> },
        { path: "tabs/add-tab/:id", element: <AddTab /> },
        {
          path: "tabs/edit-tab/:id",
          element: <EditTab />,
        },

        { path: "course-rating/:id", element: <ListCourseRating /> },
        { path: "course-rating/add-course-rating/:id", element: <AddCourseRating /> },
        {
          path: "course-rating/edit-course-rating/:id",
          element: <EditCourseRating />,
        },
        
        { path: "website-course-benefits/:id", element: <ListWebsiteCourseBenefits /> },
        { path: "website-course-benefits/add-course-benefits/:id", element: <AddWebsiteCourseBenefits /> },
        {
          path: "website-course-benefits/edit-course-benefits/:id",
          element: <EditWebsiteCourseBenefits />,
        },

        ////////
        { path: "faqs/:id", element: <Faqs /> },
        { path: "faqs/add-faq/:id", element: <AddFaq /> },
        {
          path: "faqs/edit-faq/:id",
          element: <EditFaq />,
        },
        /////////// Profile Change Password
        { path: "profile", element: <Profile /> },
        { path: "profile/edit-profile", element: <EditProfile /> },
        { path: "profile/change-password", element: <ChangePassword /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "reset-password/:id", element: <ResetPassword /> },

        //////
        { path: "settings", element: <Settings /> },
        //////////
        { path: "section", element: <Section /> },

        { path: "section/add-section", element: <AddSection /> },
        {
          path: "section/edit-section/:id",
          element: <EditSection />,
        },

        { path: "updatepage/:id", element: <UpdatePage /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "login-management", element: <LoginAsCustomer /> },
        { path: "login-instructor", element: <LoginAsInstructor /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
