import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Input,
  IconButton,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { CircularLoader, Page } from "src/components";
import { Iconify } from "src/components";
import { s3baseUrl } from "src/config/config";

import { detailPage, editPage } from "src/DAL/page";
import { listActiveTemplate } from "src/DAL/template";
import CustomImageUploader from "src/components/GeneralComponents/CustomImageUploader";
import CustomImageUploader1 from "src/components/GeneralComponents/CustomImageUploader1";
import { _active_menus } from "src/DAL/menu";
const Edit = () => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(-1);
  };
  const [choose_template, setChoose_template] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [imageBrandLogo, setImageBrandLogo] = useState("");
  const [imageFooterLogo, setImageFooterLogo] = useState("");
  const [imageFaviconLogo, setImageFaviconLogo] = useState("");
  const [menus, setMenus] = useState([]);
  const [formInputs, setFormInputs] = useState({
    page_title: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",

    redirect_user: "",
    order: "",
    choose_template: [],
    // show_testimonial: "",

    // faqs: "",
    // page_status: "",

    show_testimonial: true,
    faqs: true,
    page_status: true,
    is_slider: true,
    is_website_course_benefits: true,
    is_tabs: true,
    is_course_rating: true,
    header_menu: "",
    footer_menu: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // const data = new FormData();

    // // console.log(brand_logo, "sdjkfhsdjkhsjkfhsjdkfhsdjk");
    // data.append("page_title", formInputs.page_title);
    // data.append("meta_title", formInputs.meta_title);
    // data.append("meta_keywords", formInputs.meta_keywords);
    // data.append("meta_description", formInputs.meta_description);

    // data.append("redirect_user", formInputs.redirect_user);
    // data.append(
    //   "choose_template",
    //   JSON.stringify(
    //     Array.isArray(formInputs.choose_template)
    //       ? formInputs.choose_template
    //       : [formInputs.choose_template]
    //   )
    // );
    // data.append("show_testimonial", formInputs.show_testimonial);

    // data.append("faqs", formInputs.faqs);
    // data.append("page_status", formInputs.page_status);
    // const formDataArray = Array.from(data.entries());

    let PostData = {
      page_title: formInputs.page_title,
      meta_title: formInputs.meta_title,
      meta_description: formInputs.meta_description,
      meta_keywords: formInputs.meta_keywords,
      // choose_template: JSON.stringify(
      //   Array.isArray(formInputs.choose_template)
      //     ? formInputs.choose_template
      //     : [formInputs.choose_template]
      // ),
      // choose_template: formInputs.choose_template,

      // choose_template:Array.isArray(formInputs.choose_template)?formInputs.choose_template:[formInputs.choose_template],
      // is_testimonials: formInputs.show_testimonial,
      is_faqs: formInputs.faqs,

      is_slider: formInputs.is_slider,
      is_website_course_benefits: formInputs.is_website_course_benefits,

      is_tabs: formInputs.is_tabs,
      is_course_rating: formInputs.is_course_rating,

      header_menu: formInputs.header_menu,
      footer_menu: formInputs.footer_menu,

      // brand_logo: imageBrandLogo,
      // footer_logo: imageFooterLogo,
      // favicon: imageFaviconLogo,
      page_status: formInputs.page_status,
      order: formInputs.order,
    };
    console.log("PostData   ____PostData", PostData);

    const result = await editPage(id, PostData);
    if (result.code === 200) {
      setIsLoading(false);
      // navigate("/pages");
      enqueueSnackbar("Edited Successfully!", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchMenus = async () => {
    // setIsLoading(true);
    const response = await _active_menus();
    console.log("response   _____response", response);
    if (response.code === 200) {
      const menus = response.menus.map((menu) => ({
        ...menu,
        id: menu._id,
        // name: tab.name,
        // title: tab.title,
        // status: tab.status,
      }));

      setMenus(menus);
      // setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  async function getDetailPage() {
    setIsLoading(true);
    const response = await detailPage(id);
    console.log("response  _detail", response);
    if (response.code === 200) {
      // setPageslug(response.Page.page_title_slug);
      const templateIds = response.page.choose_template.map(
        (choose_template) => choose_template._id
      );
      // console.log(data.instructor.first_name);

      // setImageBrandLogo(response.page.brand_logo);
      // setImageFooterLogo(response.page.footer_logo);
      // setImageFaviconLogo(response.page.favicon);

      setFormInputs({
        ...formInputs,
        ...response.page,
        page_title: response.page.page_title,
        meta_title: response.page.meta_title,
        meta_keywords: response.page.meta_keywords,
        meta_description: response.page.meta_description,
        choose_template: templateIds,
        page_status: response.page.page_status,

        faqs: response.page.is_faqs,
        // show_testimonial: response.page.is_testimonials,

        is_slider: response.page.is_slider,
        is_website_course_benefits: response.page.is_website_course_benefits,

        is_tabs: response.page.is_tabs,
        is_course_rating: response.page.is_course_rating,

        // redirect_user: response.page.redirect_user,
      });
      // console.log(
      //   data.Page.use_general_social_media_links,
      //   "data.Page.use_general_social_media_linksdata.Page.use_general_social_media_links"
      // );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  const fetchActiveTemplates = async () => {
    const response = await listActiveTemplate();

    if (response.code === 200) {
      const templetes = response.templates.map((templete) => ({
        id: templete._id,
        title: templete.template_name,
      }));
      setChoose_template(templetes);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getDetailPage();
    fetchActiveTemplates();
    fetchMenus();
  }, []);

  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Edit Page">
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ marginBottom: "40px", marginTop: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton>

          <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Edit Page
          </Typography>
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Page Title"
                  value={formInputs.page_title}
                  onChange={handleChange}
                  name="page_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />

                <TextField
                  fullWidth
                  label="Meta Title"
                  value={formInputs.meta_title}
                  onChange={handleChange}
                  name="meta_title"
                  required
                  // inputProps={{
                  //   pattern: "[A-Za-z]{3,}",
                  //   title:
                  //     "Please enter at least 3 letters with only alphabetical characters.",
                  // }}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Meta Keywords"
                  value={formInputs.meta_keywords}
                  onChange={handleChange}
                  name="meta_keywords"
                  autoComplete="off"
                  required
                />
                {/* <TextField
                  fullWidth
                  label="Redirect User"
                  value={formInputs.redirect_user}
                  onChange={handleChange}
                  name="redirect_user"
                  autoComplete="off"
                  required
                /> */}
              </Stack>
              <TextField
                fullWidth
                label="Meta Description"
                value={formInputs.meta_description}
                onChange={handleChange}
                name="meta_description"
                autoComplete="off"
                required
                multiline
                rows={2}
              />

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Choose Template"
                  select
                  value={formInputs.choose_template}
                  onChange={handleChange}
                  name="choose_template"
                  required
                  disabled
                  // SelectProps={{ multiple: true }}
                >
                  {choose_template.map((choose_template) => (
                    <MenuItem
                      key={choose_template.id}
                      value={choose_template.id}
                    >
                      {choose_template.title}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Order"
                  value={formInputs.order}
                  onChange={handleChange}
                  name="order"
                  required
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Header Menu"
                  select
                  value={formInputs.header_menu}
                  onChange={handleChange}
                  name="header_menu"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {menus.map((menu) => (
                    <MenuItem key={menu._id} value={menu._id}>
                      {menu.menu_title}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Footer Menu"
                  select
                  value={formInputs.footer_menu}
                  onChange={handleChange}
                  name="footer_menu"
                  required
                  // SelectProps={{ multiple: true }}
                >
                  {menus.map((menu) => (
                    <MenuItem key={menu._id} value={menu._id}>
                      {menu.menu_title}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>

              {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Show Testimonial"
                  select
                  required
                  value={formInputs.show_testimonial}
                  onChange={handleChange}
                  name="show_testimonial"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Stack> */}

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="FAQS"
                  select
                  required
                  value={formInputs.faqs}
                  onChange={handleChange}
                  name="faqs"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Page Status"
                  select
                  required
                  value={formInputs.page_status}
                  onChange={handleChange}
                  name="page_status"
                >
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">Inactive</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Sliders"
                  select
                  required
                  value={formInputs.is_slider}
                  onChange={handleChange}
                  name="is_slider"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Website Courses Benefits"
                  select
                  required
                  value={formInputs.is_website_course_benefits}
                  onChange={handleChange}
                  name="is_website_course_benefits"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  label="Tabs"
                  select
                  required
                  value={formInputs.is_tabs}
                  onChange={handleChange}
                  name="is_tabs"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Course Ratings"
                  select
                  required
                  value={formInputs.is_course_rating}
                  onChange={handleChange}
                  name="is_course_rating"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </TextField>
              </Stack>

              {/* <CustomImageUploader
                pathOfUploadedFile={imageBrandLogo}
                setPathOfUploadedFile={setImageBrandLogo}
                // imageFileHeading={"Image File"}
                imageFileHeading={"Upload Brand Logo"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
              />

              <CustomImageUploader1
                pathOfUploadedFile={imageFooterLogo}
                setPathOfUploadedFile={setImageFooterLogo}
                // imageFileHeading={"Image File"}
                imageFileHeading={"Upload Footer Logo"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
                imageID={1}
              />

              <CustomImageUploader1
                pathOfUploadedFile={imageFaviconLogo}
                setPathOfUploadedFile={setImageFaviconLogo}
                // imageFileHeading={"Image File"}
                imageFileHeading={"Upload Favicon Logo"}
                imageFileSubHeading={"Image Size: 1MB or below :"}
                imageFileLimitSizeUpload={1}
                imageFileButtonText={"Upload"}
                imageID={2}
              /> */}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Edit;
