// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import SummaryCard from "./components/SummaryCard";
import { CircularLoader, Page } from "src/components";
//hooks
// import { useAppContext } from "src/hooks";
import { useEffect, useState } from "react";
import { dashboard } from "src/DAL/dashboard";
import { Link } from "react-router-dom";
import CustomBasicPie from "src/components/GeneralComponents/CustomBasicPie";

// ----------------------------------------------------------------------

export default function Dashboard() {
  const theme = useTheme();
  // const { _get_user_profile } = useAppContext();
  const [dashboardData, setDashboardData] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const DASHBOARD_DATA3 = [
    {
      color: "success",
      title: "Contact Count",
      count: 0,
      icon: "ph:phone-fill",
    },
 
  ];

  const DASHBOARD_DATA2 = [
    {
      color: "success",
      title: "Batches",
      count: 0,
      icon: "ph:student",
    },
    {
      color: "info",
      title: "Courses",
      count: 0,
      icon: "fe:book",
    },
  ];


  const DASHBOARD_DATA = [
    // {
    //   color: "success",
    //   title: "Batches",
    //   count: 0,
    //   icon: "ph:student",
    // },
    // {
    //   color: "info",
    //   title: "Courses",
    //   count: 0,
    //   icon: "fe:book",
    // },
    {
      color: "secondary",
      title: "Instructors",
      count: 0,
      icon: "mdi:teacher",
    },
    {
      color: "primary",
      title: "Students",
      count: 0,
      icon: "fe:users",
    },
    {
      color: "warning",
      title: "Assignments",
      count: 0,
      icon: "mdi:paper-edit-outline",
    },
    {
      color: "info",
      title: "Vouchers",
      count: 0,
      icon: "nimbus:money",
    },
  ];

  const updatedData = DASHBOARD_DATA.map((item) => {
    if (item.title === "Students") {
      return {
        ...item,
        count: dashboardData.total_students,
      };
    } else if (item.title === "Batches") {
      return {
        ...item,
        count: dashboardData.total_batches,
      };
    } else if (item.title === "Courses") {
      return {
        ...item,
        count: dashboardData.total_courses,
      };
    } else if (item.title === "Instructors") {
      return {
        ...item,
        count: dashboardData.total_instructors,
      };
    } else if (item.title === "Assignments") {
      return {
        ...item,
        count: dashboardData.total_assignments,
      };
    } else if (item.title === "Vouchers") {
      return {
        ...item,
        count: dashboardData.total_fee_vouchers,
      };
    }

    return item;
  });


  const updatedData2 = DASHBOARD_DATA2.map((item) => {
    if (item.title === "Students") {
      return {
        ...item,
        count: dashboardData.total_students,
      };
    } else if (item.title === "Batches") {
      return {
        ...item,
        count: dashboardData.total_batches,
      };
    } else if (item.title === "Courses") {
      return {
        ...item,
        count: dashboardData.total_courses,
      };
    } else if (item.title === "Instructors") {
      return {
        ...item,
        count: dashboardData.total_instructors,
      };
    } else if (item.title === "Assignments") {
      return {
        ...item,
        count: dashboardData.total_assignments,
      };
    } else if (item.title === "Vouchers") {
      return {
        ...item,
        count: dashboardData.total_fee_vouchers,
      };
    }

    return item;
  });


  const updatedData3 = DASHBOARD_DATA3.map((item) => {
    if (item.title === "Contact Count") {
      return {
        ...item,
        count: dashboardData.total_contact_count,
      };
    }  

    return item;
  });
  
  const fetchDashboard = async () => {
    const resp = await dashboard();
    if (resp.code === 200) {
      setIsLoading(false);
      setDashboardData(resp.dashboard);
    } else {
      setIsLoading(false);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);
  if (isLoading) {
    return <CircularLoader />;
  }
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl" sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Hi, Welcome back
        </Typography>


        <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
  <Grid item xs={12} sm={12} md={6} lg={6}>
    <Grid container spacing={3}>
      {updatedData2.map((item, i) => (
        <Grid key={i} item xs={12} sm={6} md={6}>
          <SummaryCard
            color={item.color}
            title={item.title}
            count={item.count}
            icon={item.icon}
          />
        </Grid>
      ))}
    </Grid>
  </Grid>

  <Grid item xs={12} sm={12} md={6} lg={6}>
    <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100vh', // Optional: Adjust the height to your preference
  }}
> 
    <CustomBasicPie
      firstLabelValue={dashboardData.total_pending_fee_vouchers}
      firstLabelText="Pending Vouchers"
      secondLabelValue={dashboardData.total_pending_clearance_fee_vouchers}
      secondLabelText="Clearance Vouchers"
      thirdLabelValue={dashboardData.total_paid_fee_vouchers}
      thirdLabelText="Paid Vouchers"
    />
    </div> 
  </Grid>
</Grid>

 
        <Grid container spacing={3} sx={{ mb: 3 }}>
          {updatedData.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={3}>
              {item.title === "Students" ? (
                // <Link to="/students">
                <SummaryCard
                  color={item.color}
                  title={item.title}
                  count={item.count}
                  icon={item.icon}
                />
              ) : (
                // </Link>
                <SummaryCard
                  color={item.color}
                  title={item.title}
                  count={item.count}
                  icon={item.icon}
                />
              )}
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
    <Grid container spacing={3}>
      {updatedData3.map((item, i) => (
        <Grid key={i} item xs={12} sm={3} md={3}>
          <SummaryCard
            color={item.color}
            title={item.title}
            count={item.count}
            icon={item.icon}
          />
        </Grid>
      ))}
    </Grid>
  </Grid>
      </Container>
    </Page>
  );
}
