import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../../components/Iconify";
import DelPopup from "src/components/DelPopup";

import { useSnackbar } from "notistack";
import {
  _student_delete,
  _student_move_to_trash,
  delStudent,
} from "src/DAL/student";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import StudentDetail from "../StudentDetail";
import { useAppContext } from "src/hooks";
import { privilegesTypeCheckAndShowOrNot } from "src/utils/FormatPrivileges";
import { studentPortalUrl } from "src/config/config";
import CustomModal from "src/components/GeneralComponents/CustomModal";
import MoveToTrashPage from "../MoveToTrashPage";
import { capitalizeFirstLetter } from "src/utils/formatText";
// ----------------------------------------------------------------------

const style = {
  width: "70%",
  minWidth: "70%",
  maxHeight: "80vh", // Set a maximum height for the modal
  overflowY: "auto", // Enable vertical scrolling
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function StudentMoreMenu({
  row,
  students,
  onStudentsUpdate,
  trashStudentCount,
  setTrashStudentCount,
}) {
  const [openTrash, setOpenTrash] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(false);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const { _get_user_profile, _get_user_privilege_access } = useAppContext();

  let get_user_profile = _get_user_profile();
  let get_user_privilege_access = _get_user_privilege_access();

  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteClick = () => {
    setIsOpen(false);
    setOpenDelete(true);
  };
  const handleTrashClick = () => {
    handleDeleteOk;
    setIsOpen(false);
    setOpenTrash(true);
  };
  const handleDeleteOk = () => {
    handleDeleteConfirmClick(row);
    setOpenDelete(false); // Call the onDelete function when the user confirms the delete action
  };
  const handleTrashOk = () => {
    handleClick(row);
    setOpenTrash(false); // Call the onDelete function when the user confirms the delete action
  };
  async function handleDeleteConfirmClick(row) {
    //console.log(row);
    // const result = await delStudent(row.id);
    const result = await _student_delete(row.id);
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students.id !== row.id
      );
      onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Delete Successfully!", { variant: "success" });
      // setTrashStudentCount(trashStudentCount+1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  async function handleClick(row) {
    //console.log(row);
    // const result = await delStudent(row.id);
    const result = await _student_move_to_trash(row.id);
    if (result.code === 200) {
      const updatedStudents = students.filter(
        (students) => students.id !== row.id
      );
      onStudentsUpdate(updatedStudents);
      enqueueSnackbar("Move To Trash Successfully!", { variant: "success" });
      setTrashStudentCount(trashStudentCount + 1);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  }
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "student",
          "edit"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/students/edit-student/${row.id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {/* <MenuItem
          component={RouterLink}
          to={`/students/detail-student/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="bx:detail" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Detail"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "student",
          "delete"
        ) ? (
          <>
            {/* <MenuItem
              sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
              onClick={handleTrashClick}
            >
              <ListItemIcon>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Move To Trash"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem> */}

            <MenuItem
              sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
              onClick={handleOpenModal}
            >
              <ListItemIcon>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Move To Trash"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>

            <MenuItem
              sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
              onClick={handleDeleteClick}
            >
              <ListItemIcon>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Delete"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </>
        ) : (
          ""
        )}
        {/* <MenuItem
          component={RouterLink}
          to={`/students/freeze-student/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="mdi:freeze-advisory" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Freeze Student"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "student",
          "edit"
        ) ? (
          <>
            <MenuItem
              component="a"
              href={`${studentPortalUrl}login-admin/${row.user_id}`}
              target="_blank" // This opens the link in a new tab/window
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify icon="ri:admin-fill" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Login As Student"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
            {/* <MenuItem
          component={RouterLink}
          to={`/transaction/student-transaction/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:history-24-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Payment History"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

            <MenuItem
              component={RouterLink}
              to={`/students/assign-batch/${row.id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify icon="icons8:student" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Assign Batch"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>

            <MenuItem
              component={RouterLink}
              to={`/attendance/detail-batches/${row.id}`}
              sx={{ color: "text.secondary" }}
            >
              <ListItemIcon>
                <Iconify icon="material-symbols:assignment-outline" width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary="Attendance"
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          </>
        ) : (
          ""
        )}

        {privilegesTypeCheckAndShowOrNot(
          get_user_profile,
          get_user_privilege_access,
          "student",
          "admin"
        ) ? (
          <MenuItem
            component={RouterLink}
            to={`/reset-password/${row.user_id}`}
            sx={{ color: "text.secondary", "&:hover": { color: "#0071cf" } }}
          >
            <ListItemIcon>
              <Iconify icon="mdi:password-reset" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Reset Password"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ) : (
          ""
        )}
        {/* <MenuItem
          component={RouterLink}
          to={`/students/wallet/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="game-icons:wallet" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Wallet"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}

        {/* <MenuItem
          component={RouterLink}
          to={`/students/update-student-fee/${row.id}`}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="fluent:phone-update-checkmark-20-regular" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Update Fee"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
      <DelPopup
        open={openTrash}
        setOpen={setOpenTrash}
        title="Confirm Trash"
        message="Are you sure you want to Move This Student to Trash?"
        onOk={handleTrashOk}
      />
      <DelPopup
        open={openDelete}
        setOpen={setOpenDelete}
        title="Confirm Delete"
        message="Are you sure you want to Delete This Student?"
        onOk={handleDeleteOk}
      />

      <CustomModal
        isOpenModal={openModal}
        handleCloseModal={handleCloseModal}
        pageTitle={`Move Student (${capitalizeFirstLetter(row.name)}) To Trash`}
        componentToPassDown={
          <MoveToTrashPage
            studentRow={row}
            handleCloseModal={handleCloseModal}
            students={students}
            onStudentsUpdate={onStudentsUpdate}
            trashStudentCount={trashStudentCount}
            setTrashStudentCount={setTrashStudentCount}
          />
        }
      />
    </>
  );
}