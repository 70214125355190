import { invokeApi } from "src/utils";

export const _list_top_up = async (data) => {
    const requestObj = {
      path: `api/top_up/list_top_up`,
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _add_top_up = async (data) => {
    const requestObj = {
      path: `api/top_up/add_top_up`, 
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };


  export const _delete_top_up = async (id) => {
    const requestObj = {
      path: `api/top_up/delete_top_up/` + id,
      method: "DELETE",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
  
    return invokeApi(requestObj);
  };


export const _detail_top_up = async (id) => {
    const requestObj = {
      path: `api/top_up/detail_top_up/` + id,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const _edit_top_up = async (id, data) => {
    const requestObj = {
      path: `api/top_up/edit_top_up/` + id,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
