import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

// material
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  Box,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
// component
import { Page } from "src/components";
import { Iconify } from "src/components";
import {
  _move_batch_students,
  _move_student_from_batch,
  _update_batch_status,
  addBatch,
  listBatch,
} from "src/DAL/batch";

import { listActiveCourse } from "src/DAL/course";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { _approve_fee_voucher, _detail_fee_voucher, _refund_fee_voucher } from "src/DAL/voucher";
import { capitalizeFirstLetter } from "src/utils/formatText";

const RefundFeeVoucher = ({
  Row,
  Students,
  onStudentsUpdate,
  handleCloseModal,
}) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [batches, setBatches] = useState([]);
  const [batchTitle, setBatchTitle] = useState("");
  const [row, setRow] = useState(Row);
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    name: "",
    total_seats: "",
    status: "true",
    batch: "",
    courses: [],
    voucher_status: 2,
    paid_amount: "",
    paid_amount_all: "",
  });

  const { id } = useParams();

  let _ID = id;

  console.log("_ID _ID _ID", _ID);

  console.log("row ____test", row);
  const urlParams = new URLSearchParams(window.location.search);

  let batch_id = "batch_id";
  let student_id = _ID;

  if (urlParams.get("batch_id") != null) {
    batch_id = urlParams.get("batch_id");
  } else {
    // navigate("/batches/");
  }
  console.log("batch_id", batch_id);
  console.log("student_id", student_id);

  const [formInputs2, setFormInputs2] = useState({
    duration: dayjs(),
    duration2: dayjs(),
  });

  const handleNav = () => {
    navigate(-1);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: capitalizeFirstLetter(target.value) });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("value", value);

    setFormInputs((prevInputs) => ({
      ...prevInputs,
      // [name]: value,
      //   courses: [value],
    }));

    // setFormInputs((prevInputs) => ({
    //   ...prevInputs,
    //   courses: newValue ? newValue._id : "",
    // }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };
  const handleEndTimeChange = (time) => {
    setEndTime(time);
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration: date }));
  };

  const handleDateChange2 = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    // setFormInputs2((values) => ({ ...values, duration_dob2: formattedDate }));
    // setFormInputs2((values) => ({ ...values, duration2: formattedDate }));
    setFormInputs2((values) => ({ ...values, duration2: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = "";

    setIsLoading(true);
 
    const result = await _refund_fee_voucher(row.id, {
        voucher_refund_reason: formInputs.paid_amount,
    });

    if (result.code === 200) {

      setIsLoading(false);
    
      handleCloseModal();

      const updatedVoucher = Students.map((voucher) => {
        if (voucher.id === row.id) {
          // If the batch id matches, create a new object with batch_status set to 1
          return { ...voucher, voucher_status: result.fee_voucher.voucher_status };
        } else {
          // For other objects, return them as they are
          return voucher;
        }
      });

      onStudentsUpdate(updatedVoucher);

   // navigate("/vouchers");
   navigate("/vouchers/list-voucher");
   //   navigate(-1);

      // const updatedBatches = Batches.filter((batch) => batch.id === row.id);
      // onBatchesUpdate(updatedBatches);

      // enqueueSnackbar(`${type} Successfully!`, { variant: "success" });

      enqueueSnackbar("Refund Successfully!", { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  function calculateOverallFee(
    fee,
    additional_discount,
    registration_fee_discount
  ) {
    let overallFee = fee - additional_discount - registration_fee_discount;
    // Perform your calculation here
    if (overallFee < 0) {
      if (additional_discount > registration_fee_discount) {
        if (fee - additional_discount > 0) {
          overallFee = fee - additional_discount;
        } else if (fee - registration_fee_discount > 0) {
          overallFee = fee - registration_fee_discount;
        } else {
          overallFee = fee;
        }
      } else if (additional_discount < registration_fee_discount) {
        if (fee - registration_fee_discount > 0) {
          overallFee = fee - registration_fee_discount;
        } else if (fee - additional_discount > 0) {
          overallFee = fee - additional_discount;
        } else {
          overallFee = fee;
        }
      }
    }

    return overallFee;
  }

  //   const fetchActiveCourses = async () => {
  //     const response = await listActiveCourse();

  //     if (response.code === 200) {
  //       const courses = response.courses.map((course) => ({
  //         id: course._id,
  //         title: course.title,
  //         description: course.description,
  //         duration: course.duration,
  //         image: course.image,
  //         status: course.status,
  //       }));
  //       setCourses(courses);
  //     } else {
  //       enqueueSnackbar(response.message, { variant: "error" });
  //     }
  //   };

  //   useEffect(() => {
  //     fetchActiveCourses();
  //   }, []);

  const fetchBatches = async () => {
    setIsLoading(true);
    const response = await listBatch();
    console.log("response __listBatch", response);
    if (response.code === 200) {
      const batches = response.batch
        .map((batch, index) => {
          // Check if batch._id is equal to the _ID you want to skip
          if (batch._id === row.id) {
            setBatchTitle(batch.name);
            return null; // Skip this iteration and move to the next element
          }

          // Check if status is true, include it in the resulting array; otherwise, skip
          if (batch.status === true) {
            return {
              id: batch._id,
              name: batch.name,
              available_seats:
                batch.available_seats <= 0 ? 0 : batch.available_seats,
              total_seats: batch.total_seats,
              total_male_students: batch.total_male_students,
              total_female_students: batch.total_female_students,
              student_count: batch.student_count,
              status: batch.status,
              courses: batch.courses ? batch.courses : "",
              // other properties...
              number: index + 1,
            };
          }

          return null; // Skip this iteration and move to the next element
        })
        .filter(Boolean); // Filter out any null values (skipped elements) from the array

      setBatches(batches);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const fetchDetailVoucher = async () => {
    setIsLoading(true);
    const response = await _detail_fee_voucher(row.id);
    console.log("response _detail_fee_voucher __detail_fee_voucher", response);
    // setCourses(data.batch.courses);
    if (response.code === 200) {
      setRow({
        ...row,
        ...response.fee_voucher,
      });
      

      const calculateTotalPaidAmount = (paidAmountArray) => {
        return paidAmountArray.reduce((total, payment) => total + payment.amount, 0);
      };
      
      // Assuming response.fee_voucher.paid_amount is the array you provided
      const totalPaidAmount = calculateTotalPaidAmount(response.fee_voucher.paid_amount);
      // setFormInputs((values) => ({ ...values, paid_amount: response.fee_voucher.paid_amount }));
      setFormInputs((values) => ({ ...values, paid_amount_all: totalPaidAmount }));


      // let defaultStartDate = new Date();
      // console.log("defaultStartDate", defaultStartDate);
      // if (response.fee_voucher.due_date) {
      //   defaultStartDate = new Date(response.fee_voucher.due_date);
      //   console.log("defaultStartDate", defaultStartDate);
      // }

      // console.log("defaultStartDate", defaultStartDate);

      // // Use Array.prototype.find to locate the desired object
      // let foundObject = batches.find(
      //   (obj) => obj.id === response.fee_voucher.batch._id
      // );

      // if (foundObject) {
      //   setbatcheFees(foundObject.batch_fee);
      // } else {
      //   setbatcheFees([]);
      // }

      // if(response.fee_voucher.additional_discount > 0){
      //   setFeeDiscountChecked(true)
      // }
      // setFormInputs({
      //   batch_id: response.fee_voucher.batch._id,
      //   batch_fee_id: response.fee_voucher.fee_id,

      //   additional_discount: response.fee_voucher.additional_discount,
      //   additional_discount_reason:
      //     response.fee_voucher.additional_discount_reason,

      //   // time: dayjs(new Date(dayjs(data.time, "h:mm A").toDate())),
      //   // format_time: formattedTime,

      //   start_date_batch: defaultStartDate.toISOString().split("T")[0]
      //     ? defaultStartDate.toISOString().split("T")[0]
      //     : null,
      //   start_date_batch_select: defaultStartDate.toISOString().split("T")[0]
      //     ? defaultStartDate.toISOString().split("T")[0]
      //     : null,
      // });
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    fetchDetailVoucher();
  }, []);

  //   useEffect(() => {
  //     if (courses.length > 0) {
  //       setFormInputs((prevInputs) => ({
  //         ...prevInputs,
  //         courses: [courses[0].id],
  //       }));
  //     }
  //   }, [courses]);

  if (isLoading) {
    return (
      <> 
      <div className="mt-5">
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      </div>
      <Page title="Approve Fee Voucher">
      <div className="container-fluid col-11" style={{ marginTop: 30 }}>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginBottom: "40px",
          }}
        >
          {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}

          {/* <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Update Batch Status
          </Typography> */}
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3} sx={{ padding: "5px" }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {/* <TextField
                  disabled
                  fullWidth
                  label="Student Name"
                  value={capitalizeFirstLetter(row.student.name)}
                  onChange={handleChange}
                  name="name"
                /> */}
                  <Skeleton width="100%" height={60}/>
              <Skeleton width="100%"  height={60}/>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Skeleton width="100%"  height={60}/>
              <Skeleton width="100%"  height={60}/>
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Skeleton width="100%"  height={60}/>
                <Typography
                  variant="body1"
                  style={{ marginTop: 15, color: "#aaa" }}
                >
                  -
                </Typography>
                {row.voucher_type === 1 ? (
                  <Skeleton width="100%"  height={60}/>
                ) : (
                  <Skeleton width="100%"  height={60}/>
                )}

                <Typography
                  variant="body1"
                  style={{ marginTop: 15, color: "#aaa" }}
                >
                  -
                </Typography>

                <Skeleton width="100%"  height={60}/>

                <Typography
                  variant="body1"
                  style={{ marginTop: 15, color: "#aaa" }}
                >
                  =
                </Typography>
                <Skeleton width="100%" height={60}/>
              </Stack>

              {/* <TextField
                fullWidth
                label="Voucher Status"
                select
                value={formInputs.voucher_status}
                onChange={handleChange}
                name="voucher_status"
                required
              > 
    
                <MenuItem value={2}>Paid</MenuItem>
 
              </TextField> */}
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Skeleton width="100%" height={60}/>
              <Skeleton width="100%" height={60}/>
              </Stack>

              <Stack alignItems="left">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "warning.main" }}
                >
                    <Skeleton width="70%" height={20}/>
                </Typography>
              </Stack>

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
      {/* <Skeleton variant="rounded" width="50% "height={200} /> */}
      </>
    );
  }

  return (
    <Page title="Refund Fee Voucher">
      <div className="container-fluid col-11" style={{ marginTop: 90 }}>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={7}
          xl={7}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // marginBottom: "40px",
          }}
        >
          {/* <IconButton onClick={handleNav} sx={{ flex: 0 }}>
            <Iconify icon="ep:back" />
          </IconButton> */}

          {/* <Typography variant="h4" align="center" sx={{ flex: 1 }}>
            Update Batch Status
          </Typography> */}
        </Grid>

        <Grid item xs={12} sm={10} md={8} lg={7} xl={7}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Stack spacing={3} sx={{ padding: "5px" }}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  disabled
                  fullWidth
                  label="Student Name"
                  value={capitalizeFirstLetter(row.student.name)}
                  onChange={handleChange}
                  name="name"
                />
                <TextField
                  disabled
                  fullWidth
                  label="Batch Name"
                  value={capitalizeFirstLetter(row.batch.name)}
                  onChange={handleChange}
                  name="name"
                />
                <TextField
                  disabled
                  fullWidth
                  label="Student Contact"
                  value={"+92" + row.student.contact_number}
                  onChange={handleChange}
                  name="name"
                />
                <TextField
                  disabled
                  fullWidth
                  label="Fee Title"
                  value={capitalizeFirstLetter(row.voucher_type===2?row.fee_title:"Registration")}
                  onChange={handleChange}
                  name="name"
                />

              </Stack>
              

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>

                <TextField
                  disabled
                  fullWidth
                  label="Total Fee"
                  // value={row.fee}
                  value={calculateOverallFee(
                    row.fee,
                    row.additional_discount,
                    row.voucher_type === 1
                      // ? row.student.registration_fee_discount
                      ? 0
                      : row.fee_discount
                  )}
                  onChange={handleChange}
                  name="over_all"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  // inputProps={{
                  //   pattern: "[0-9]*", // Only allow numeric characters
                  //   maxLength: 10, // Limit the input to 10 characters
                  //   title: "Please enter a valid 10-digit phone number",
                  // }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
    <TextField
                  fullWidth
                  label="Total Paid Amount"
                  value={formInputs.paid_amount_all}
                  disabled
                  onChange={handleChange}
                  name="paid_amount_all"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  // inputProps={{
                  //   pattern: "[0-9]*", // Only allow numeric characters
                  //   maxLength: 10, // Limit the input to 10 characters
                  //   title: "Please enter a valid 10-digit phone number",
                  // }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

                <TextField
                  fullWidth
                  label="Total Pending Fee"
                  value={row?.pending_fee}
                  // required
                  // onChange={handleChange}
                  name="pending_fee"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R.S</InputAdornment>
                    ),
                  }}
                  disabled
                  // inputProps={{
                  //   pattern: "[0-9]*", // Only allow numeric characters
                  //   maxLength: 10, // Limit the input to 10 characters
                  //   title: "Please enter a valid 10-digit phone number",
                  // }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />

              </Stack>

              
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
           
                <TextField
                  fullWidth
                  label="Refund Reason"
                  value={formInputs.paid_amount}
                  onChange={handleChange}
                  name="paid_amount"
                  required
                />

              </Stack>

              {/* <Stack alignItems="left">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "warning.main" }}
                >
                  Please note that if you modify the voucher status, the fee
                  cannot be modified.
                </Typography>
              </Stack> */}

              <Stack alignItems="end">
                <LoadingButton
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  sx={{ width: "120px" }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Grid>
      </div>
    </Page>
  );
};

export default RefundFeeVoucher;
