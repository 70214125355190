import { invokeApi } from "src/utils";
export const dashboard = async () => {
  const requestObj = {
    path: `api/admin/dash_board`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
}; 
export const _admin_dashboard = async (data) => {
  const requestObj = {
    path: `api/admin/admin_dashboard`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_batch_monthly_attendance = async (data) => {
  const requestObj = {
    path: `api/attendance/get_batch_monthly_attendance`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_dashboard_monthly_attendance = async (data) => {
  const requestObj = {
    path: `api/attendance/get_dashboard_monthly_attendance`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_assignment_for_specific_date_for_dashboard = async (data) => {
  const requestObj = {
    path: `api/assignment/get_assignment_for_specific_date`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const batchCount = async () => {
//   const requestObj = {
//     path: `api/batch/count_batches`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const courseCount = async () => {
//   const requestObj = {
//     path: `api/course/count_courses`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
// export const instructorCount = async () => {
//   const requestObj = {
//     path: `api/instructor/count_instructors`,
//     method: "GET",
//     headers: {
//       "x-sh-auth": localStorage.getItem("token"),
//     },
//   };

//   return invokeApi(requestObj);
// };
