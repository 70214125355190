import { invokeApi } from "src/utils";
export const _website_page_slider = async (id) => {
  const requestObj = {
    path: `api/slider/website_page_slider/` + id +`?page=0&limit=9999`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _delete_slider = async (id) => {
  const requestObj = {
    path: `api/slider/delete_slider/` + id,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };

  return invokeApi(requestObj);
};

export const _add_slider = async (data) => {
 
  const requestObj = {
    path: `api/slider/add_slider`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",

      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _detail_slider = async (id) => {
  const requestObj = {
    path: `api/slider/detail_slider/` + id,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_slider = async (id, data) => {
  const requestObj = {
    path: `api/slider/edit_slider/` + id,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
